/* eslint-disable no-case-declarations */
import ACTION_TYPES from './actionTypes';
import { initialFilterData, initialState, filterKeys } from './constants';

export default (state = initialState, action) => {
  switch (action.type) {
    case ACTION_TYPES.PACKAGES_REQUEST:
      return {
        ...state,
        status: 'loading'
      };
    case ACTION_TYPES.PACKAGES_SUCCESS:
      const { departures, results, searcher, sorts } = action.payload;
      return {
        ...state,
        departures,
        results,
        searcher,
        sorts,
        status: 'ready'
        // data: action.payload
      };
    case ACTION_TYPES.PACKAGES_FAILURE:
      return {
        ...state,
        loading: false,
        status: 'failure',
        error: action.payload
      };
    case ACTION_TYPES.PACKAGES_SET_ACTIVE_STEP: {
      return {
        ...state,
        activeStep: action.payload
      };
    }
    case ACTION_TYPES.PACKAGES_UPDATE_FILTER_DATA: {
      const newFilters = {
        ...state.filterData,
        [action.payload.key]: action.payload.value
      };

      // When we select a filter type of when, prepopulate the contact's form date
      // with the startValue of the range selected
      if (action.payload.key === filterKeys.WHEN) {
        return action.payload.value
          ? {
              ...state,
              filterData: newFilters,
              filteredDate: action.payload.value.option
                ? action.payload.value.option.from
                : null
            }
          : {
              ...state,
              filterData: newFilters,
              filteredDate: null
            };
      }
      return {
        ...state,
        filterData: newFilters
      };
    }
    case ACTION_TYPES.PACKAGES_UPDATE_RESULTS:
      return {
        ...state,
        filtered: action.payload
      };

    case ACTION_TYPES.PACKAGES_RESET_FILTER:
      return {
        ...state,
        filterData: initialFilterData
      };
    case ACTION_TYPES.PACKAGES_SUBMIT_FORM_REQUEST:
      return {
        ...state,
        loading: true
      };
    case ACTION_TYPES.PACKAGES_ONCHANGE_DEPARTURE_DATE_PRICE: {
      return {
        ...state,
        departureDatePrice: action.payload
      };
    }

    case ACTION_TYPES.PACKAGES_SUBMIT_FORM_SUCCESS: {
      return {
        ...state,
        loading: false,
        firstName: action.payload
      };
    }

    case ACTION_TYPES.PACKAGES_SUBMIT_FORM_FAILURE: {
      return {
        ...state,
        loading: false,
        error: action.payload
      };
    }

    case ACTION_TYPES.PACKAGES_TOGGLE_MODAL: {
      return {
        ...state,
        showModal: action.payload
      };
    }
    case ACTION_TYPES.PACKAGES_RESET_STATE:
      return initialState;

    case ACTION_TYPES.PACKAGES_GET_INFO_SUCCESS: {
      return {
        ...state,
        packageItemData: action.payload
      };
    }
    case ACTION_TYPES.PACKAGES_GET_INFO_FAILURE: {
      return {
        ...state,
        error: action.payload
      };
    }

    case ACTION_TYPES.PACKAGES_GET_UPSELLING_INFO_SUCCESS: {
      return {
        ...state,
        upSelling: action.payload
      };
    }
    case ACTION_TYPES.PACKAGES_GET_UPSELLING_INFO_FAILURE: {
      return {
        ...state,
        error: action.payload
      };
    }
    case ACTION_TYPES.PACKAGES_CLEAR_ERRORS: {
      return {
        ...state,
        error: null
      };
    }

    case ACTION_TYPES.PACKAGES_LOAD_FILTERS:
      return {
        ...state,
        filterData: { ...initialFilterData, ...action.payload }
      };

    default:
      return state;
  }
};
