import React from 'react';
import { useFormContext, RegisterOptions } from 'react-hook-form';

import { FormBox, Label, InputHolder, ErrorText } from '../Common';

interface Props {
  name: string;
  options: {
    id: string;
    name: string;
  }[];
  placeholder?: string;
  label: string;
  errorMessage?: string;
  registerOptions?: RegisterOptions;
}

export const FormSelect = ({
  name,
  label,
  placeholder,
  options,
  registerOptions,
  errorMessage = 'Selecciona un valor.'
}: Props) => {
  const {
    register,
    formState: { errors }
  } = useFormContext();

  const isMandatory = registerOptions?.required;
  const error = Boolean(errors[name]);

  return (
    <FormBox>
      <Label>
        {label} {isMandatory && <span>*</span>}
      </Label>
      <InputHolder isError={Boolean(error)}>
        <select placeholder={placeholder} {...register(name, registerOptions)}>
          <option value="">{''}</option>
          {options.map((option, index) => (
            <option key={`${option.id}-${index}`} value={option.id}>
              {option.name}
            </option>
          ))}
        </select>
      </InputHolder>
      {error && <ErrorText>{errorMessage}</ErrorText>}
    </FormBox>
  );
};
