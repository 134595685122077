/**
 *
 * CardReview
 *
 */

import React from 'react';
import styled from 'styled-components';
import useBreakpoints from 'Utils/hooks/useBreakpoints';

interface CardReviewProps {
  rating: string | number;
  ratingText: string;
  author: string;
  reviews: string;
  created: string;
}
const CardReview = ({
  rating,
  ratingText,
  author,
  created,
  reviews,
  ...rest
}: CardReviewProps) => {
  const { largeDown } = useBreakpoints();
  return (
    <Container {...rest} largeDown={largeDown}>
      <Header>
        <div className='content-rating-text'>
        <div className="content-author">
        <Author color="orange">
          {author}
        </Author>
        <Date >
            {created}
        </Date>
        </div>
          <Content largeDown={largeDown}>
            <Review>"{reviews}"</Review>
          </Content>
      </div>
      <AuthorContainer>
        <RatingText>
        {ratingText}
        </RatingText>
        <RatingBox color="orange">
          {rating}
        </RatingBox>
      </AuthorContainer>
    </Header>
  </Container>
)
};


const Container = styled.div<{largeDown: boolean}>`
  box-shadow: ${({ theme }) => theme.shadows.one};
  background-color: ${({ theme }) => theme.palette.grays.white};
  padding: ${({ theme }) => `${theme.spacing.two} ${theme.spacing.four}`};
  border-radius: 15px;
  border: 1px solid rgba(40, 40, 40, 0.10);
  width: ${props=>(props.largeDown === true ? '360px' : '100%')};
  .content-rating-text{
    display:flex;
    flex-direction:column;
    justify-content:center;
    align-items:flex-start;
  }
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  & > div {
    display: flex;
    align-items: center;
  }
`;

const AuthorContainer = styled.div`
display: flex;
flex-direction: column;
align-items: center;
  span:last-child {
    margin-left: ${({ theme }) => theme.spacing.two};
  }
  ${({ theme }) => theme.mediaQueries.mobile} {
    flex-direction: column;
    &:last-child {
      margin-left: 0;
    }
  }
`;

const Content = styled.div<{largeDown: boolean}>`
  margin-top: ${({ theme }) => theme.spacing.four};
  width: ${props=>(props.largeDown === true ? '260px' : '100%')};
`;

const RatingBox = styled.div`
display: flex;
color: #FB8400;
font-family: Itau Display Pro;
font-size: 16px;
font-style: normal;
font-weight: 800;
line-height: 24px;
width: 46px;
padding: 10px;
justify-content: center;
align-items: center;
gap: 4px;
border-radius: 10px;
border: 1px solid #FB8400;
`;

const RatingText = styled.span`
  color: #FB8400;
  font-family: Itau Display Pro;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  width: 75px;
  text-align: center;
`;

const Author = styled.span`
  color: #282828;
  font-family: Itau Display Pro;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px;
`;

const Date = styled.span`
color: #282828;
font-family: Itau Display Pro;
font-size: 14px;
font-style: normal;
font-weight: 300;
line-height: 22px;
margin-left: 10px;
opacity: 0.5;
`;

const Review = styled.span`
  color: #282828;
  font-family: Itau Display Pro;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  opacity: 0.5;
  width: 120px;
`;

export default CardReview;
