/**
 *
 * BlogCarouselItemCategory
 *
 */

import React from 'react';
import styled from 'styled-components';
import cs from 'classnames';
import slugify from 'slugify';
import BlogItemCategory from '../BlogItemCategory';
import { routes } from 'Constants';
import { BlogCategory } from 'Services/Blog/types';
import '@brainhubeu/react-carousel/lib/style.css';
import Carousel from '@brainhubeu/react-carousel';
import Icon from 'Components/Icon';
import { IUseBreakpoints } from 'Constants/types';
import useBreakpoints from 'Utils/hooks/useBreakpoints';
import { firstLetterLowerCase } from 'Utils';

const ArrowIconHolder = styled.div`
  width: 40px;
  height: 40px;
`;

const getUrl = (categoryName: string, categoryType: string) =>
  routes.blogCategory
    .replace(':category', slugify(categoryName).toLowerCase())
    .replace(':catType', categoryType);

interface BlogCarouselItemCategoryProps {
  className?: string;
  categories: BlogCategory[];
  selectedCategory: string;
}
const BlogCarouselItemCategory = ({
  className,
  categories,
  selectedCategory
}: BlogCarouselItemCategoryProps) => {
  const breakpoints = useBreakpoints();

  return (
    <div className={cs(className)}>
      <Carousel
        slidesPerPage={returnCarrouselItems(breakpoints)}
        arrows
        infinite
        arrowLeft={
          <ArrowIconHolder>
            <Icon name="mv-arrow-left" color="white" size={20} />
          </ArrowIconHolder>
        }
        arrowRight={
          <ArrowIconHolder>
            <Icon name="mv-arrow-right" color="white" size={20} />
          </ArrowIconHolder>
        }
        addArrowClickHandler>
        {categories.map(item => {
          const selected =
            selectedCategory &&
            slugify(item.name).toLocaleLowerCase() === selectedCategory;
          const url = selected
            ? routes.blog.replace(
                ':catType',
                firstLetterLowerCase(item.categoryType)
              )
            : getUrl(item.name, firstLetterLowerCase(item.categoryType));
          return (
            <BlogItemCategory
              url={url}
              key={item.name}
              name={item.name}
              selected={selected}
              imageUrl={item.photo.publicUrl}
            />
          );
        })}
      </Carousel>
    </div>
  );
};

export const returnCarrouselItems = (breakpoints: IUseBreakpoints) => {
  if (breakpoints.smallDown) {
    return 1;
  } else if (breakpoints.mediumDown) {
    return 2;
  } else if (breakpoints.largeDown) {
    return 3;
  } else {
    return 4;
  }
};

export default styled(BlogCarouselItemCategory)`
  margin: 0 auto;
  max-width: 1140px;
`;
