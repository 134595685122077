import React from 'react';
import styled from 'styled-components';
import Heading from 'Components/Heading';
import Icon from 'Components/Icon';
import AirAvailabilityCard from 'Features/flights/components/AirAvailabilityCard';
import { IUseBreakpoints } from 'Constants/types';
import { IFlightQuotation, PaxType } from 'Services/Quotations/types';
import CostCard, {
  ICostCardOnChangePayload
} from 'Features/quotations/components/CostCard';
import MobileAirAvailabilityCard from 'Features/flights/components/MobileAirAvailabilityCard';

const Container = styled.div`
  border-radius: ${({ theme }) => theme.borderRadius.default};
  background-color: ${({ theme }) => theme.colors.white};
  padding: ${({ theme }) => theme.spacing.four};
`;

const TopBar = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin-bottom: ${({ theme }) => theme.spacing.four};
`;

const List = styled.div`
  & > div:not(:last-child) {
    margin-bottom: ${({ theme }) => theme.spacing.four};
  }
`;

const ServiceLine = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  background: ${({ theme }) => theme.colors.white};
  box-shadow: ${({ theme }) => theme.shadows.two};
  border-radius: ${({ theme }) => theme.borderRadius.default};
  margin: ${({ theme }) => theme.spacing.four};
  padding: ${({ theme }) => theme.spacing.four};
`;

const AirBoxAndIconsHolder = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.medium}px) {
    flex-direction: column;
  }
`;

const ButtonHolder = styled.div`
  display: flex;
  margin-left: 20px;
  justify-content: center;
  align-items: center;

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.medium}px) {
    width: 100%;
    margin-left: 0;
    flex-direction: row-reverse;
    justify-content: flex-start;
  }
`;

const IconButton = styled.div`
  background-color: ${({ theme }) => theme.colors.important};
  border-radius: ${({ theme }) => theme.borderRadius.default};
  cursor: pointer;
  width: 52px;
  height: 42px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const CostCardHolder = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.medium}px) {
    width: 90%;
    justify-content: center;
    margin: ${({ theme }) => theme.spacing.six} auto;
  }
`;

const CostCardItemBox = styled.div`
  margin: ${({ theme }) => theme.spacing.one};
`;

const AirItemBox = styled.div`
  width: 100%;
  @media screen and (max-width: ${({ theme }) => theme.breakpoints.medium}px) {
    width: 90%;
    margin: 0 auto;
  }
`;

interface QuotationAirProps {
  data: IFlightQuotation[];
  mainQuotationId: number;
  breakpoints: IUseBreakpoints;
  onDelete: (id: number, flightId: number) => () => void;
  onUpdateCost: (
    flightId: number,
    passengerId: number
  ) => (data: ICostCardOnChangePayload) => void;
}

const QuotationAir = ({
  data,
  onDelete,
  onUpdateCost,
  breakpoints,
  mainQuotationId
}: QuotationAirProps) => (
  <Container>
    <TopBar>
      <Heading type="h3" color="orange">
        Vuelos
      </Heading>
    </TopBar>
    <List>
      {data
        .sort((a, b) => a.id - b.id)
        .map(item => (
          <ServiceLine key={item.flightUI.flightObject.id}>
            <AirBoxAndIconsHolder>
              <AirItemBox>
                {breakpoints.mediumDown ? (
                  <MobileAirAvailabilityCard
                    className="thisIsNeeded"
                    generalSegment={item.flightUI.flightObject.generalSegments}
                    flightPrice={item.flightUI.flightObject.flightPrice}
                    localPrice={item.flightUI.flightObject.localPrice}
                    onSelect={null}
                    showPrice={() => {}}
                    // showFlight
                    // id={item.id}
                    //TODO
                    hasFlex={false}
                  />
                ) : (
                  <AirAvailabilityCard
                    //TODO: fix, if you do not provide classname TS returns a  error
                    className="thisIsNeeded"
                    id={item.flightUI.flightObject.id}
                    generalSegment={item.flightUI.flightObject.generalSegments}
                    flightPrice={item.flightUI.flightObject.flightPrice}
                    localPrice={item.flightUI.flightObject.localPrice}
                    initialDate={item.flightUI.initialDate}
                    endDate={item.flightUI.endDate}
                    pending={false}
                    routesData={item.flightUI.routesData}
                    onClick={() => {}}
                    flightObject={item.flightUI.flightObject}
                    isQuotation
                    toggleShowModal={() => {}}
                    //@ts-ignore
                    readOnlySelectedSegmets={item.flightUI.selectedSegment}
                  />
                )}
              </AirItemBox>
              <ButtonHolder>
                <IconButton onClick={onDelete(mainQuotationId, item.id)}>
                  <Icon color="white" name="trash" size={24} />
                </IconButton>
              </ButtonHolder>
            </AirBoxAndIconsHolder>
            <CostCardHolder>
              {item.passengers
                .sort((a, b) => a.id - b.id)
                .map(passenger => (
                  <CostCardItemBox key={passenger.id}>
                    <CostCard
                      cost={passenger.costByPax}
                      markup={passenger.markup}
                      passengerQty={passenger.total}
                      onChange={onUpdateCost(item.id, passenger.id)}
                      pvpeValue={
                        !!passenger.priceForEdit && passenger.priceForEdit
                      }
                      pvpeCheckbox={!!passenger.priceForEdit}
                      calculatesPVP
                      showTotalCost>
                      <CostCard.Line label="Pax Type/Qty">
                        <CostCard.Text
                          value={`${PaxType[passenger.paxType]} x${
                            passenger.total
                          }`}
                        />
                      </CostCard.Line>
                    </CostCard>
                  </CostCardItemBox>
                ))}
            </CostCardHolder>
          </ServiceLine>
        ))}
    </List>
  </Container>
);

export default QuotationAir;
