/**
 *
 * FlightSearchDialog
 *
 */

import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import cs from 'classnames';
import styled from 'styled-components';
import style from './style';
import Button from 'Components/Button';
import Tabs from 'Components/Tabs';
import Card from 'Components/Card';
import Icon from 'Components/Icon';
import MobileRoundTrip from '../MobileRoundTrip';
import MobileOneWay from '../MobileOneWay';
import RoundTrip from 'Features/flights/components/RoundTrip';
import OneWay from '../OneWay';
// import MultiDestination from '../Multidestination';
// import MobileMultiDestination from '../MobileMultiDestination';
import MobileFlightSearchCompact from '../MobileFlightSearchCompact';
import Modal from 'Components/Modal';
import SearchModalContainer from 'Components/SearchModalContainer';
import useBreakpoints from '../../../../Utils/hooks/useBreakpoints';
import { flightSearchTypes, airSearchTypes } from 'Constants';
import FlightSearchCompact from '../FlightSearchCompact';
import magnifier from 'Assets/magnifier.svg';
import { relative } from 'path';

// TODO Refactor this component, most of this style is not used,
// we should just decide wich component to render, delegate styling to
// Component itself,
const FlightSearchDialog = ({
  className,
  type,
  detailMode,
  isReducerUnused,
  actions
}) => {
  const isMediumDown = useBreakpoints().mediumDown;
  const [fixedHolder, setFixedHolder] = useState(false);

  const updateHolder = () => {
    setFixedHolder(!(window.scrollY < 260));
  };

  useEffect(() => {
    window.addEventListener('scroll', updateHolder);

    return () => {
      window.removeEventListener('scroll', updateHolder);
    };
  }, []);

  const { doSearch, toggleDetailModeOff } = actions;

  if (detailMode) {
    return isMediumDown ? (
      <MobileFlightSearchCompact />
    ) : (
      <FlightSearchCompact />
    );
  }

  if (isMediumDown) {
    return isReducerUnused ? (
      <MobileSearchContainer>
        <div style={{ position: relative }}>
          <Tabs items={flightSearchTypes} selected={type} isMobile />
        </div>
        <SearchCard>
          {RenderSearch(type, true)}
          <NewSearchButton onClick={doSearch}> Buscar</NewSearchButton>
        </SearchCard>
      </MobileSearchContainer>
    ) : (
      <Modal show transparent onClickOverlay={toggleDetailModeOff}>
        <SearchModalContainer close={toggleDetailModeOff}>
          <MobileSearchContainer>
            <Tabs items={flightSearchTypes} selected={type} isMobile />
            <MobileSearchContainer></MobileSearchContainer>
            {RenderSearch(type, true)}
            <div className="holder-mobile-search-btn">
              <Button weight="semi-bold" fullWidth onClick={doSearch}>
                <ButtonContent>
                  <Icon color="white" name="mv-lense" /> BUSCAR
                </ButtonContent>
              </Button>
            </div>
          </MobileSearchContainer>
        </SearchModalContainer>
      </Modal>
    );
  }

  return (
    <div
      // TODO arreglar todo este relajo de clases aca
      className={cs(className, {
        'detailed-mode': detailMode && !isMediumDown
      })}>
      {!isMediumDown && (
        <div
          id="air-search-holder"
          className={cs(
            { detailMode: detailMode },
            {
              'fixed-holder':
                fixedHolder === true && !detailMode && isReducerUnused
            },
            { noDetailedInReducerUsed: !detailMode && !isReducerUnused }
          )}>
          {detailMode ? (
            <FlightSearchCompact />
          ) : (
            <DesktopSearchContainer>
              <Tabs items={flightSearchTypes} selected={type} />
              <CardBlock>{RenderSearch(type)}</CardBlock>
              <SearchButtonHolder>
                <SearchButton onClick={doSearch}>
                  <img src={magnifier} alt="search" /> <span>Buscar</span>
                </SearchButton>
              </SearchButtonHolder>
            </DesktopSearchContainer>
          )}
        </div>
      )}
    </div>
  );
};

const NewSearchButton = styled.button`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 10px 30px;
  gap: 10px;
  height: 40px;
  background: #fb8400;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
  font-family: 'Itau Display Pro';
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 28px;
  text-align: center;
  border: 0;
  width: 100%;
  color: #ffffff;
`;

const SearchCard = styled.div`
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: flex-start;
  padding: 25px;
  width: calc(100% - 40px);
  gap: 12px;

  left: 20px;
  background: #ffffff;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
`;

const RenderSearch = (filterType, mobile) => {
  switch (filterType) {
    case airSearchTypes.roundTrip:
      return mobile ? <MobileRoundTrip /> : <RoundTrip />;
    case airSearchTypes.oneWay:
      return mobile ? <MobileOneWay /> : <OneWay />;
    // case airSearchTypes.multi:
    //   return mobile ? (
    //     <MobileMultiDestination  />
    //   ) : (
    //     <MultiDestination  />
    //   );
    default:
  }
};

FlightSearchDialog.propTypes = {
  className: PropTypes.string.isRequired,
  detailMode: PropTypes.bool,
  isReducerUnused: PropTypes.bool,
  type: PropTypes.oneOf(Object.values(airSearchTypes)),
  actions: PropTypes.shape({
    toggleDetailModeOff: PropTypes.func.isRequired,
    doSearch: PropTypes.func.isRequired
  })
};

export default style(FlightSearchDialog);

const DesktopSearchContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: ${({ theme }) => theme.spacing.four};
  width: 100%;
`;

const MobileSearchContainer = styled.div`
  display: block;
  position: relative;
  padding: 13px;
  width: 100%;
  margin-top:149px;
`;

const CardBlock = styled(Card)`
  width: 100%;
  box-shadow: 0px 20px 20px rgba(0, 0, 0, 0.25);
  border-radius: 12px;
`;

const ButtonContent = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  .icon {
    margin-right: ${({ theme }) => theme.spacing.one};
  }
`;

const SearchButtonHolder = styled.div`
  z-index: 1;
  width: 100%;
  display: flex;
  flex-direction: row-reverse;
  padding-right: 12px;
`;

const SearchButton = styled.button`
  background: #fb8400;
  box-shadow: 0px 20px 20px rgba(0, 0, 0, 0.25);
  border-radius: 0px 0px 14px 14px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 4px 16px;
  gap: 12px;

  min-width: 134px;
  height: 44px;
  border: 0;

  span {
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 32px;
    color: white;
  }
`;
