/**
 *
 * DesktopHotelSearch
 *
 */

// Vendors
import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

// Components
import DestinationHotel from '../DestinationHotel';
import PaxDetailHotel from '../PaxDetailHotel';
import Button from 'Components/Button';
import Checkbox from 'Components/NewCheckbox';
import NewText from 'Components/NewText';

// Utils
import NewCalendar from 'Components/NewCalendar';
import { isoToday, getDaysInISORange } from 'Utils/dates';
import { CheckboxContainer } from 'Features/flights/components/RoundTrip/RoundTrip';

const DesktopHotelSearch = ({
  checkIn,
  checkOut,
  checkInMessages,
  checkOutMessages,
  actions,
  showMap
}) => {
  const nights = getDaysInISORange(checkOut, checkIn);
  return (
    <Wrapper>
      <Container>
        <TopRow>
          <DestinationHotel />
          <NewCalendar
            startDate={checkIn}
            endDate={checkOut}
            startLabel="Fechas"
            // endLabel={`${nights} ${nights > 1 ? 'noches' : 'noche'}`}
            onChangeStart={actions.setCheckIn}
            onChangeEnd={actions.setCheckOut}
            color={{ primaryColor: 'orange', lightColor: 'orangeLight' }}
            startError={{
              message: checkInMessages.length > 0 && checkInMessages[0]
            }}
            endError={{
              message: checkOutMessages.lenggth > 0 && checkOutMessages[0]
            }}
            showLabels
            minDate={isoToday}
            isRange
          />
          <PaxDetailHotel />
        </TopRow>
        <CheckboxContainer>
          <Checkbox
            name="show-map"
            onChange={actions.toggleMap}
            checked={showMap}
            color="orange"
            addMargin
          />
          <NewText type="small">Ver en el mapa</NewText>
        </CheckboxContainer>
      </Container>
      <SearchButton
        size="XL"
        iconLeft="mv-lense"
        squareBottom
        onClick={actions.doSearch}>
        Buscar
      </SearchButton>
    </Wrapper>
  );
};

DesktopHotelSearch.propTypes = {
  checkIn: PropTypes.string,
  checkOut: PropTypes.string,
  actions: PropTypes.shape({
    setCheckIn: PropTypes.func.isRequired,
    setCheckOut: PropTypes.func.isRequired,
    doSearch: PropTypes.func.isRequired,
    toggleMap: PropTypes.func.isRequired
  }),
  checkOutMessages: PropTypes.array,
  checkInMessages: PropTypes.array,
  doSearch: PropTypes.func,
  showMap: PropTypes.bool
};

export default DesktopHotelSearch;

const SearchButton = styled(Button)`
  position: absolute;
  top: 100%;
  right: 16px;
  font-family: 'Itau Display Pro';
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 32px;
  /* or 133% */

  /* White */

  color: #ffffff;
`;

const TopRow = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  gap: 8px;
  position: relative;
  & > div {
    width: 100%;
  }
  & > div:first-child {
    margin-right: ${({ theme }) => theme.spacing.one};
  }
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding: 8px 16px;
  background-color: white;
  box-shadow: 0px 20px 20px 0px rgba(0, 0, 0, 0.25);
  border-radius: 12px;
  width: 100%;
  position: relative;

  .mv-buscador-line-details {
    display: flex;

    & > div {
      margin: 5px;
      width: 50%;

      &:first-child {
        margin-left: 0;
      }

      &:last-child {
        margin-left: 0;
      }
    }
  }
`;

const Wrapper = styled.div`
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
  margin-bottom: ${({ theme }) => theme.spacing.four};
  width: 100%;
  margin-top: 146px;
`;
