import React from 'react';
import styled from 'styled-components';
import { breakpoints } from 'Constants';


const GalleryContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 8px;
  margin-bottom: 16px;

  @media (max-width: ${breakpoints.small}px) {
    overflow-x: auto;
    flex-wrap: nowrap;
    width: 100%;
    justify-content: left;
  }
`;

const Image = styled.img`\
  width: calc(25% - 8px); // 4 images per row with 8px gap
  border-radius: 8px;

  @media (max-width: ${breakpoints.small}px) {
    width: 80%;
    flex: 0 0 auto; 
  }
`;

export const ImageGallery = ({ imageUrls }) => (
  <GalleryContainer>
    {imageUrls.map((url, index) => (
      <Image key={index} src={url} alt={`Imagen ${index + 1}`} />
    ))}
  </GalleryContainer>
);

export default ImageGallery;
