/**
 *
 * FlightItineraryDetails
 *
 */

import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import cs from 'classnames';
import AirlineLogo from '../AirLineLogo';
import Text from 'Components/Text';
import Icon from 'Components/Icon';
import Button from 'Components/Button';
import { getCabinName, isoDateToFullDate } from '../../../../Utils';
import warningImg from '../../../../Assets/images/warning.png';
import * as COLORS from 'Constants/colors';
import { excludeAirlineBaggage } from 'Constants';

// TODO Refactor
const FlightItineraryDetailsSegmentsBlock = ({
  segment,
  showWaitBar = false,
  scales,
  parentIndex
}) => (
  <div>
    <div className="tramo-header row">
      <div className="col-8">
        <Text size="large" context="arena">
          {' '}
          Tramo {parentIndex + 1} - Vuelo{' '}
          {segment.flightInformation.airline.code}
          {segment.flightInformation.flightNumber}
        </Text>
      </div>
      <div className="col-4">
        <Text className="AirLineName">
          {segment.flightInformation.airline.name}
        </Text>{' '}
        <AirlineLogo code={segment.flightInformation.airline.code} />
      </div>
    </div>
    <div className="Divider"></div>
    <div className="row" style={{ paddingLeft: '24px', paddingRight: '24px' }}>
      <div className="col-4 Box">
        <div>
          <Text className="NoWrapText">
            {isoDateToFullDate(segment.departureData.flightDate)}
          </Text>
        </div>
        <div>
          <Text size="xlarge" weight="bold">
            {segment.departureData.flightTime}
          </Text>
        </div>
        <div>
          <Text size="large" className="CityCode">
            {segment.departureData.airport.code}
          </Text>
        </div>
        <div>
          <Text weight="bold" className="CityName Box">
            {segment.departureData.airport.city.name}
          </Text>
        </div>
        <div>
          <Text weight="bold" className="AirPortName Box">
            {segment.departureData.airport.name}
          </Text>
        </div>
      </div>
      <div className="col-4 Box">
        <div>
          <Text className="Duration">Duraci&oacute;n</Text>
        </div>
        <div className="Divider" style={{ width: '63px' }}></div>
        <div>
          <Text weight="bold" className="DurationTime">
            {segment.duration.totalHours}h {segment.duration.totalMinutes}m
          </Text>
        </div>
      </div>
      <div className="col-4 Box">
        <div>
          <Text className="NoWrapText">
            {isoDateToFullDate(segment.arrivalData.flightDate)}
          </Text>
        </div>
        <div>
          <Text size="xlarge" weight="bold">
            {segment.arrivalData.flightTime}
          </Text>
        </div>
        <div>
          <Text size="large" className="CityCode">
            {segment.arrivalData.airport.code}
          </Text>
        </div>
        <div>
          <Text weight="bold" className="CityName Box">
            {segment.arrivalData.airport.city.name}
          </Text>
        </div>
        <div>
          <Text weight="bold" className="AirPortName Box">
            {segment.arrivalData.airport.name}
          </Text>
        </div>
      </div>
    </div>
    <div className="row Box">
      <Text weight="bold">Cabina: {getCabinName(segment.cabin.code)}</Text>
    </div>
    {showWaitBar && (
      <div className="tramo-footer Box">
        <Text className="WaitLabel">
          Espera de {scales[parentIndex].duration.totalHours}h{' '}
          {scales[parentIndex].duration.totalMinutes}min en{' '}
          {scales[parentIndex].airport.city.name}
        </Text>
        <Text weight="bold">
          {scales[parentIndex].changeInfo.changeOfPlane &&
            !scales[parentIndex].changeInfo.changeOfAirport &&
            '(Cambio de avión)'}
        </Text>
        <Text
          weight="bold"
          context="information"
          className="AirPortChangeLabel">
          {scales[parentIndex].changeInfo.changeOfAirport &&
            '(Cambio de aeropuerto)'}
          {scales[parentIndex].changeInfo.changeOfAirport && (
            <img src={warningImg} alt="warning" />
          )}
        </Text>
      </div>
    )}
  </div>
);

FlightItineraryDetailsSegmentsBlock.propTypes = {
  segment: PropTypes.object,
  showWaitBar: PropTypes.bool,
  scales: PropTypes.array,
  parentIndex: PropTypes.number
};

const MobileFlightItineraryDetails = ({
  className,
  segments,
  duration,
  direction = 1,
  scales,
  baggage,
  closeModal = () => {},
  noSelect = false
}) => {
  const noShowWaitBarIndex = segments && segments.length - 1;

  const getCityNameFrom = () =>
    segments && segments[0].departureData.airport.city.name;
  const getCityCodeFrom = () =>
    segments && segments[0].departureData.airport.city.code;
  const getCityNameTo = () =>
    segments && segments[segments.length - 1].arrivalData.airport.city.name;
  const getCityCodeTo = () =>
    segments && segments[segments.length - 1].arrivalData.airport.city.code;
  const airlineCode = segments[0].flightInformation.airline.code;

  return (
    <div className={cs(className, 'animated fadeIn')}>
      <div className="TopBar">
        <span className="TopBarLabel">Detalle del vuelo</span>
        <span className="TopBarClose" onClick={() => closeModal()}>
          <Icon name="mv-cross" />
        </span>
      </div>
      <div className="Header row">
        <div className="col-3">
          <Text className="LabelDirection" context="arena">
            {direction === 1 ? 'IDA' : 'VUELTA'}
          </Text>
        </div>
        <div className="col-9 OverflowEllipsis">
          <Text className="HeaderLabel">
            {getCityNameFrom() + ' (' + getCityCodeFrom() + ')'}
          </Text>
          {direction === 1 ? (
            <Icon name="mv-plane-ida" color="white" className="icon-margin" />
          ) : (
            <Icon
              name="mv-plane-vuelta"
              color="white"
              className="icon-margin"
            />
          )}
          <Text className="HeaderLabel">
            {getCityNameTo() + ' (' + getCityCodeTo() + ')'}
          </Text>
        </div>
      </div>
      <div style={{ height: window.screen.height - 181 }}>
        {segments && (
          <div className="segments-holder">
            {segments.map((segment, index) => (
              <FlightItineraryDetailsSegmentsBlock
                key={index}
                scales={scales}
                segment={segment}
                parentIndex={index}
                showWaitBar={!(index === noShowWaitBarIndex)}
              />
            ))}
          </div>
        )}
        <div className="baggage-detail-holder">
          <div className="baggage-detail-item-holder carryon">
            <Icon name="mv-backpack" />
            <div className="description-holder">
              <Text weight="bold" context="important">
                Incluye equipaje de cabina.
              </Text>
              <Text weight="regular" context="default">
                Debe entrar bajo el asiento delantero.
              </Text>
            </div>
          </div>
          <div className="baggage-detail-item-holder carryon">
            <Icon name="mv-hand-bag" />
            <div className="description-holder">
              {!baggage.total && excludeAirlineBaggage.includes(airlineCode) ? (
                <Text weight="bold">No incluye equipaje de cabina</Text>
              ) : (
                <div>
                  <Text weight="bold" context="important">
                    Incluye equipaje de cabina
                  </Text>{' '}
                  <br />
                  <Text weight="regular" context="default">
                    Debe entrar en el compartimiento superior del avión.
                  </Text>
                </div>
              )}
            </div>
          </div>
          {baggage && baggage.total ? (
            <div className="baggage-detail-item-holder maleta">
              <Icon name="mv-briefcase" />
              <div className="description-holder">
                <Text weight="bold" context="important">
                  Incluye equipaje para despachar.
                </Text>
                <Text weight="regular" context="default">
                  Se despacha en el aeropuerto durante el Check-in.
                </Text>
                {baggage.baggageType && baggage.baggageType === 2 && (
                  <Text weight="bold">
                    {baggage.total}
                    {baggage.total > 1 ? ' maletas' : ' maleta'} por adulto
                  </Text>
                )}
                {baggage.baggageType && baggage.baggageType === 1 && (
                  <Text weight="bold">
                    {baggage.total}
                    {baggage.total > 1 ? ' Kilos' : ' kilo'} por adulto
                  </Text>
                )}
              </div>
            </div>
          ) : null}
        </div>
        {duration && (
          <div className="duration-detail-holder">
            Duraci&oacute;n total &nbsp;
            {duration.totalHours ? `${duration.totalHours}h` : ''}{' '}
            {duration.totalHours ? `${duration.totalMinutes}mins` : ''}
          </div>
        )}
        {noSelect && (
          <div className="select-button-holder">
            <Button>Seleccionar</Button>
          </div>
        )}
      </div>
    </div>
  );
};

MobileFlightItineraryDetails.propTypes = {
  className: PropTypes.string.isRequired,
  segments: PropTypes.array,
  scales: PropTypes.array,
  duration: PropTypes.object,
  direction: PropTypes.number.isRequired,
  baggage: PropTypes.object,
  noSelect: PropTypes.bool,
  closeModal: PropTypes.func.isRequired
};

export default styled(MobileFlightItineraryDetails)`
  background-color: ${COLORS.white};
  width: 100%;
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;

  .segments-holder {
    margin: 0 10px;

    @media screen and (max-width: 578px) {
      margin: 0;
    }
  }

  .OverflowEllipsis {
    display: flex;
    align-items: center;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;

    .icon-margin {
      margin-right: 4px;
      margin-left: 4px;
    }
  }

  .NoWrapText {
    white-space: nowrap;
  }

  .IconStyle {
    .icon {
      color: ${COLORS.white};
      font-size: 18px;
      position: relative;
      top: 5px;
      margin-right: 10px;
      margin-left: 8px;
    }
  }

  .HeaderLabel {
    font-family: Montserrat, Arial, sans-serif;
    font-size: 12px;
    font-weight: 500;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.17;
    letter-spacing: 0.12px;
    text-align: left;
    color: ${COLORS.white};
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .LabelDirection {
    font-family: Montserrat, Arial, sans-serif;
    font-size: 16px;
    font-weight: 600;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.06;
    letter-spacing: 0.16px;
    text-align: left;
    white-space: nowrap;
  }

  .Header {
    height: 41px;
    box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.35);
    background-color: ${COLORS.violeta};
    padding-left: 13px;
    padding-right: 13px;
    display: flex;
    align-items: center;
  }

  .TopBarClose {
    display: flex;
    align-items: center;
  }

  .TopBar {
    width: 100%;
    height: 62px;
    background-color: ${COLORS.white};
    padding-left: 13px;
    padding-right: 13px;
    display: flex;
    text-align: center;
  }

  .TopBarLabel {
    font-family: Montserrat, Arial, sans-serif;
    font-size: 18px;
    font-weight: normal;
    font-style: italic;
    font-stretch: normal;
    line-height: 0.56;
    letter-spacing: 0.18px;
    text-align: left;
    color: ${COLORS.middleGrey};
    margin: auto;
  }

  .AirPortChangeLabel {
    font-family: Montserrat, Arial, sans-serif;
    font-size: 14px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.29;
    letter-spacing: 0.14px;
    text-align: center;
    color: ${COLORS.lightGrey};
  }

  .DurationTime {
    font-family: Montserrat, Arial, sans-serif;
    font-size: 14px;
    font-weight: 600;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.21;
    letter-spacing: 0.14px;
    text-align: left;
    color: #7b7b7b;
  }

  .Duration {
    font-family: Montserrat, Arial, sans-serif;
    font-size: 12px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.42;
    letter-spacing: 0.12px;
    text-align: left;
    color: #7b7b7b;
  }

  .Divider {
    height: 1px;
    width: 100%;
    background-color: #7b7b7b;
  }

  .Box {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .WaitLabel {
    font-family: Montserrat, Arial, sans-serif;
    font-size: 14px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.14;
    letter-spacing: 0.14px;
    text-align: center;
    color: #7b7b7b;
  }

  .CityCode {
    font-family: Montserrat, Arial, sans-serif;
    font-size: 16px;
    font-weight: 600;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.06;
    letter-spacing: 0.16px;
    text-align: left;
    color: #7b7b7b;
  }

  .AirPortName {
    font-family: Montserrat, Arial, sans-serif;
    font-size: 14px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.14;
    letter-spacing: 0.14px;
    text-align: center;
    color: #7b7b7b;
  }

  .CityName {
    font-family: Montserrat, Arial, sans-serif;
    font-size: 14px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.14;
    letter-spacing: 0.14px;
    text-align: center;
    color: #7b7b7b;
  }

  .AirLineName {
    white-space: unset !important;
    word-spacing: 9999px;
    font-family: Montserrat, Arial, sans-serif;
    font-size: 12px;
    font-weight: 600;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.08;
    letter-spacing: 0.12px;
    text-align: left;
    color: #7b7e7c;
  }

  .select-button-holder {
    padding-top: 7px;
    padding-bottom: 17px;
    text-align: center;
  }

  .baggage-detail-holder {
    padding-left: 25px;
    min-height: 68px;
    background-color: #eaeceb;
    display: flex;
    padding-top: 8px;
    margin-right: 4px;
    margin-top: 4px;

    @media screen and (max-width: 578px) {
      flex-direction: column;
    }

    .baggage-detail-item-holder {
      flex: 1;
      display: flex;
      justify-content: center;
      align-items: center;

      .icon {
        color: ${COLORS.important};
        margin-right: 8px;
      }

      &.maleta > .icon {
        font-size: 34px;
      }

      &.carryon > .icon {
        font-size: 27px;
      }

      .description-holder {
        display: flex;
        flex-direction: column;
      }

      @media screen and (max-width: 578px) {
        justify-content: flex-start;
      }
    }
  }

  .duration-detail-holder {
    font-family: Montserrat, Arial, sans-serif;
    font-size: 22.5px;
    font-weight: bold;
    font-style: normal;
    font-stretch: normal;
    line-height: 0.76;
    letter-spacing: 0.23px;
    text-align: center;
    color: ${COLORS.white};
    padding: 7px 10px;
    background-color: ${COLORS.darkGrey};

    @media screen and (max-width: 578px) {
      padding: 12px;
      font-size: 16px;
    }
  }

  .tramo-header {
    display: flex;
    justify-content: space-between;
    min-height: 49px;
    align-items: center;

    .airlineLogo {
      max-width: 32px;
      max-height: 32px;
      height: auto;
      width: auto;
      margin-right: 8px;
    }

    @media screen and (max-width: 678px) {
      padding-left: 10px;
      padding-right: 10px;

      & :first-child {
        flex: 1;
      }

      & :last-child {
        flex: 1;
        display: flex;
        justify-content: space-between;

        span {
          max-width: 150px;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
        }
      }
    }
  }

  .tramo-itinerary-divider {
    width: 60px;
    height: 1px;
    background-color: #7b7b7b;
  }

  .tramo-duration-class {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border-left: 1px solid rgba(0, 0, 0, 0.35);

    @media screen and (max-width: 568px) {
      border: none;

      & div:first-child {
        border-bottom: 1px solid #7b7b7b !important;
      }

      & :nth-child(2) {
        order: 2 !important;

        span {
          font-size: 14px !important;
        }
      }

      & :last-child {
        order: 3 !important;
      }
    }
  }

  .tramo-itinerary-holder {
    display: flex;
    justify-content: space-evenly;
    border-top: 1px solid #7b7b7b;
    padding-top: 8px;
    margin-bottom: 18px;

    @media screen and (max-width: 568px) {
      & :nth-child(2) {
        order: 3;
      }
    }
  }

  .tramo-itinerary {
    display: flex;
    flex-direction: column;
    align-items: center;
    flex: 1;
    text-align: center;

    @media screen and (max-width: 678px) {
      /* Some media queries */
    }
  }

  .tramo-footer {
    padding: 4px 10px;
    background-color: #eaeceb;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
  }
`;
