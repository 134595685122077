import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Link } from 'react-router-dom';

import Icon from 'Components/Icon';
import Text from 'Components/Text';

import logo from 'Assets/tienda-naranja-viajes-logo-full.svg';
import { ReactComponent as Vuelos } from './vuelos.svg';
import { ReactComponent as Hoteles } from './hoteles.svg';
import { ReactComponent as Paquetes } from './paquetes.svg';
import { ReactComponent as UserMenuIcon } from './user-menu.svg';
import { routes } from 'Constants';

import { useUser } from '../../../users/utils';
import { isCorpo } from '../../../../Services/Users/types';

const returnEngineIcon = engine => {
  if (engine === 'hoteles') {
    return <Hoteles />;
  } else if (engine === 'paquetes') {
    return <Paquetes />;
  }
  return <Vuelos />;
};

const MobileNavbar = ({
  handleSelect,
  settings,
  engines,
  activeEngine,
  smallMode,
  hideHeader,
  onLogoClick,
  hidden
}) => {
  const user = useUser();

  return (
    <>
      <Container hideHeader={hideHeader || smallMode} hidden={hidden}>
        <Header>
          <Nav>
            <Link to={routes.home} onClick={onLogoClick}>
              <Logo alt="me-vuelo" src={logo} />
            </Link>
          </Nav>
        </Header>
        <NavBarSlider {...settings}>
          {Object.values(engines).map(engine => {
            const active = engine.name === activeEngine;

            // TODO: THIS IS A TEMPORAL FIX
            if (!user && engine.isPrivate) {
              return;
            }

            if (isCorpo(user) && !engine.showInCorpo) {
              return;
            }

            return (
              // Wrapper is needed because of slick styles bleeding down
              <EngineHolder key={engine.name} active={active}>
                <NavItem
                  active={active}
                  onClick={handleSelect(engine.name, engine.path)}
                  to={engine.path}>
                  {returnEngineIcon(engine.name)}
                    <NavBarLabel context={active ? 'important' : 'default'}>
                      {engine.name}
                    </NavBarLabel>
                </NavItem>
              </EngineHolder>
            );
          })}
        </NavBarSlider>
      </Container>
      {/* <Container forSpace hideHeader={hideHeader || smallMode} hidden={hidden}>
        {/* Using this so tht we dont have two <header> tags on the final dom 
        <Header as="div" />
        <NavBarSlider {...settings}>
          <NavItem to="">
            <NavBarIcon>
              <Icon size={24} />
            </NavBarIcon>
            {!smallMode && <NavBarLabel>space</NavBarLabel>}
          </NavItem>
        </NavBarSlider>
      </Container> */}
    </>
  );
};

MobileNavbar.propTypes = {
  handleSelect: PropTypes.func.isRequired,
  settings: PropTypes.object,
  engines: PropTypes.object,
  activeEngine: PropTypes.string.isRequired,
  smallMode: PropTypes.bool,
  hideHeader: PropTypes.bool,
  onLogoClick: PropTypes.func.isRequired,
  hidden: PropTypes.bool
};

export default MobileNavbar;

const EngineHolder = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 5px;
  gap: 5px;
  min-width: 98px;
  height: 79px;
  border-bottom: 2px solid ${({ active }) => (active ? '#FB8400' : 'white')};
`;

const Container = styled.div`
  width: 100%;
  transition: all 0.4s ease-in-out;
  z-index: ${({ theme }) => theme.zIndex.topNavbar};
  top: 0;
  ${({ hidden }) =>
    hidden &&
    `
    opacity: 0;
    height: 0px;
  `};

  /* Changed translateY because translate does not alter the layouts */
  ${({ hideHeader }) => hideHeader && 'margin-top: -5px;'}
  ${({ forSpace }) =>
    forSpace &&
    `
  position: relative;
  visibility: hidden;
  `}
`;

const Header = styled.header`
  height: 100%;
  height: 70px;
  background-color: #ffffff;
  border-bottom: 1px solid rgba(40, 40, 40, 0.1);
`;

const Nav = styled.nav`
  height: 100%;
  display: flex;
  align-items: center;
`;

const Logo = styled.img`
  position: relative;
  width: 172px;
  margin-left: 20px;
`;

const NavBarSlider = styled.div`
  display: flex;
  transition: 0.2s;
  list-style-type: none;
  background-color: ${({ theme }) => theme.colors.white};
  display: flex;
  justify-content: space-around;
  width: 100%;
`;

const NavItem = styled(Link)`
  padding: 0 ${({ theme }) => theme.spacing.four};
  cursor: pointer;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-decoration: none;

  path {
    fill: white;
    stroke: ${({ active }) => (active ? '#FB8400' : '#282828')};
  }

  &:hover {
    text-decoration: none;
  }
`;

const NavBarLabel = styled(Text)`
  display: flex;
  justify-content: center;
  align-items: center;
  text-transform: capitalize;
  text-align: center;
  font-family: 'Itau Display Pro';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  /* identical to box height, or 150% */

  display: flex;
  align-items: center;
  text-align: right;
  margin-top: 15px;
`;
