/**
 *
 * PaymentPromoPy
 *
 */

import React, { useEffect, useState } from 'react';
import styled, { css } from 'styled-components';
import NewText from 'Components/NewText';
import Button from 'Components/Button';
import Icon from 'Components/Icon';
import Portal from 'Components/Portal';
import Heading from 'Components/Heading';
import itauLogo from 'Assets/images/itauLogo.png';
import amexLogo from 'Assets/images/amexLogo.png';
import useBreakpoints from 'Utils/hooks/useBreakpoints';
import { ItauBanner } from 'Components/ItauBanner';
export const PaymentPromoPy = () => {
  const [showModal, setShowModal] = useState(false);
  const { largeDown } = useBreakpoints();
  useEffect(() => {
    document.body.classList.toggle('non-scroll', showModal);
    return () => {
      document.body.classList.remove('non-scroll');
    };
  }, [showModal]);
  return (
    //PARAGUAY
    <ItauBanner loadBannerHome noMargin={false}/>
  );
};
const PromoBanner = styled.div`
  ${({ theme }) => css`
    margin-bottom: ${theme.spacing.three};
    margin-top: ${theme.spacing.three};
    width:100%;
    background: #EC7000;
    top: 1508px
    padding: 50px 0px 50px 0px;
    margin-top: 100px;
    gap: 150px;

  `}
`;

const ButtonLabel = styled.span`
  white-space: nowrap;
`;

const Logo = styled.img`
  ${({ theme }) => css`
    max-height: 60px;
    margin-right: ${theme.spacing.two};
  `}
`;

const Container = styled.div`
  ${({ theme }) => css`
    margin-bottom: ${theme.spacing.three};
    margin-top: ${theme.spacing.three};
    border-radius: ${theme.borderRadius.default};
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding: ${theme.spacing.four};
    max-width: 1100px;
    margin: auto;
    .fees {
      padding-top: ${theme.spacing.one};
      padding-bottom: ${theme.spacing.one};
    }
    @media screen and (max-width: 767px) {
      position: relative;
      max-width: 95%;
      margin-bottom: ${theme.spacing.six};
      margin-top: ${theme.spacing.six};
      .ver-mas {
        display: none;
      }
    }
  `}
`;

interface TermsAndConditionsProps {
  onClose: () => void;
}

const paragraphProps = {
  paragraph: true,
  type: 'small' as const
};
const TermsAndConditions = ({ onClose }: TermsAndConditionsProps) => (
  <Portal>
    <ModalOverlay>
      <Modal>
        <ModalCard>
          <Close onClick={onClose}>
            <Icon name="mv-cross" size={20} color="default" />
          </Close>
          <Heading type="h3">Condiciones de la promo Itaú</Heading>
          <NewText {...paragraphProps}>
            Promoción válida desde el 03 de Enero 2022 al 31 de Diciembre de
            2022 para todos los clientes con tarjetas de crédito emitidas por el
            BANCO ITAÚ PARAGUAY S.A.
          </NewText>
          <NewText {...paragraphProps}>
            Hasta 10 cuotas sin intereses con cualquier tarjeta de crédito Itaú
          </NewText>
          <NewText {...paragraphProps}>
            Hasta 12 cuotas sin intereses con las Tarjetas American Express y
            Visa Infinite emitidas por banco Itaú Paraguay.
          </NewText>
          <NewText {...paragraphProps}>
            El fraccionamiento se realizará bajo solicitud del cliente para
            todos los productos comercializados por MeVuelo Paraguay a través de
            la plataforma digital, canales telefónicos o presenciales.
          </NewText>
        </ModalCard>
      </Modal>
    </ModalOverlay>
  </Portal>
);

const ModalOverlay = styled.div`
  min-width: 100vw;
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  overflow: auto;
  background-color: rgba(0, 0, 0, 0.25);
  ${({ theme }) => css`
    z-index: ${theme.zIndex.modal};
  `}
`;

const Modal = styled.div`
  max-width: 800px;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
`;

const ModalCard = styled.div`
  ${({ theme }) => css`
    background-color: ${theme.colors.white};
    padding: ${theme.spacing.four};
    display: flex;
    flex-direction: column;
    position: relative;
    h4 {
      margin-bottom: ${theme.spacing.two};
    }
    @media screen and (min-width: 767px) {
      margin: ${theme.spacing.four};
      border-radius: ${theme.borderRadius.default};
      min-height: 50vh;
    }
    @media screen and (max-width: 767px) {
      min-height: 100vh;
      padding-top: ${theme.spacing.twelve};
    }
  `}
`;

const Close = styled.div`
  ${({ theme }) => css`
    position: absolute;
    top: ${theme.spacing.two};
    right: ${theme.spacing.two};
    cursor: pointer;
  `}
`;
