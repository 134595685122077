/**
 *
 * NewCheckbox
 *
 */

import React from 'react';
import styled from 'styled-components';
import Icon from 'Components/Icon';
import { ThemeColor } from 'Theme';

interface NewCheckboxProps {
  name: string;
  checked: boolean;
  /** Accesibility purposes value */
  value?: string;
  onChange: (newVal: boolean) => void;
  color?: ThemeColor;
  className?: string;
  addMargin?: boolean;
}

const NewCheckbox = ({
  name,
  checked,
  value,
  onChange,
  color,
  className,
  addMargin
}: NewCheckboxProps) => (
  <Container className={className} addMargin={addMargin}>
    <Hidden>
      <input
        type="checkbox"
        name={name}
        checked={checked}
        value={value}
        onChange={e => onChange(e.target.checked)}
      />
      <label htmlFor={name}></label>
    </Hidden>
    {checked && <CheckboxIcon name="mv-checkmark" themeColor={color} />}
  </Container>
);

interface ContainerProps {
  addMargin: boolean;
}

const Container = styled.span<ContainerProps>`
  cursor: pointer;
  width: 16px;
  height: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: ${({ theme }) => theme.shadows.one};
  border: solid 1px ${({ theme }) => theme.palette.grays.meVueloGrey};
  background-color: ${({ theme }) => theme.palette.grays.white};
  position: relative;
  ${({ theme, addMargin }) => addMargin && `margin-right: ${theme.spacing.one}`}

  input {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
  }
`;

const CheckboxIcon = styled(Icon as any)`
  pointer-events: none;
  position: absolute;
  top: 0;
  bottom: 0;
`;

const Hidden = styled.div`
  opacity: 0;
`;

export default NewCheckbox;
