import { breakpoints } from 'Constants';
import styled from 'styled-components';

export const Title = styled.h1`
  font-family: 'Itau Display Pro';
  font-style: normal;
  font-weight: 700;
  font-size: 30px;
  line-height: 37px;
  color: #282828;
  text-align: center;
`;
export const TitleUnderline = styled.div`
  width: 138px;
  height: 4px;
  margin-bottom: 16px;
  background: #F58404;
`;
export const SectionHolder = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: ${breakpoints.xxlarge}px;
  margin: 0 auto;
  padding: 8px;
  overflow: hidden;
`;
