import styled from 'styled-components';

export const SideContent = styled.div`
  display: flex;
  flex-direction: column;
  padding: 20px;
  width: 100%;
  gap: 20px;
  color: rgba(40, 40, 40, 0.50);
  font-family: 'Itau Display Pro';
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
`;

export const SideHeader = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 10px;
  gap: 10px;
  width: 257px;
  height: 44px;
  background: #faf2e3;
  border-radius: 10px 10px 0px 0px;
  font-family: 'Itau Display Pro';
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  color: #fb8400;
`;

export const SegmentHeader = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 0px;
  gap: 9px;
`;



export const Divider = styled.div`
  width: 100%;
  height: 1px;
  background-color: rgba(40, 40, 40, 0.25);
`;

export const Title = styled.div`
  color: #282828;    
  font-style: normal;
  font-weight: 700;

  &.orange {
    color: #FB8400;  
  }
`;

export const PriceHolder = styled.div`
  text-align: center;
  display: flex;
  padding: 10px;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
  align-self: stretch;
  border-radius: 10px;
  border: 1px solid rgba(40, 40, 40, 0.25);

  div {
    width: 100%;
  }
  .negro {
    color: #282828;
  }
`

export const PrecioNaranja = styled.div`
color: #FB8400;
font-size: 24px;
font-weight: 700;
line-height: 32px; 
`;

export const TextoAzul = styled.span`
margin-left: 5px;
color: #039;
font-size: 16px;
font-weight: 700;
line-height: 24px;

svg {
  margin-left: 3px;
}
`