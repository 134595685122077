import React, { memo } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { engines, breakpoints } from 'Constants';

const Backgrounds = ({ engineActive, isReducerUnused }) => (
  <>
    {Object.values(engines).map(engine => (
      <BackgroundLayer
        key={engine.name}
        background={engine.background}
        isActive={engine.name === engineActive && isReducerUnused}
      />
    ))}
  </>
);

Backgrounds.propTypes = {
  engineActive: PropTypes.string.isRequired,
  isReducerUnused: PropTypes.bool.isRequired
};

const BackgroundLayer = styled.div`
  background-size: cover;
  background-attachment: initial;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 610px;
  opacity: ${({ isActive }) => (isActive ? 1 : 0)};
  background-image: url(${({ background }) => background});
  transition: opacity 0.6s ease-in-out;
  background-position: top;
  z-index: -1;
  

  @media screen and (max-width: ${breakpoints.medium }px) {
    top: 149px;
    height: 410px;
    background-position: -155px -66px;
  }
  @media screen and (max-width: ${breakpoints.small - 1}px) {
    top: 149px;
    height: 410px;
    background-position: -188px -66px;
  }
  @media screen and (min-width: 429px) and (max-width: 536px) {
    top: 149px;
    height: 410px;
    background-position: center -66px;
  }
`;

export default memo(Backgrounds);
