import styled from 'styled-components';

export const StyledBox = styled.div`
  margin: 32px 0;
  overflow: visible;
  align-self: normal;
`;

export const StyledTypography = styled.h2`
  
  margin-bottom: 16px;
  font-size: ${({ variant }) => variant === 'h5' ? '1.5rem' : '1.25rem'};
`;

export const StyledTableContainer = styled.div`
  width: 100%;
  overflow-x: auto;
  background: #fff;
  padding: 16px;
  box-shadow: 0px 2px 1px -1px rgba(0,0,0,0.2), 
              0px 1px 1px 0px rgba(0,0,0,0.14), 
              0px 1px 3px 0px rgba(0,0,0,0.12);
`;

export const StyledTable = styled.table`
  width: 100%;
  min-width: 650px;
  border-collapse: collapse;
`;

export const StyledTableHead = styled.thead`

`;

export const StyledTableRow = styled.tr`
  &:nth-child(even) {
    background-color: #f2f2f2;
  }
`;

export const StyledTableCell = styled.td`
  padding: 16px;
  text-align: left;
  border-bottom: 1px solid #ddd;
`;
