/**
 *
 * MobileCalendar
 *
 */

import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import cs from 'classnames';
import MobileCalendar from '../MobileCalendar';
import { dayInWeek } from '../../Utils';
import { formatSuperShort, parseIso, formatIso } from '../../Utils/dates';
import Portal from 'Components/Portal';
import { ReactComponent as CalendarIcon } from './calendar.svg';

// const getStringDate = (startDate, endDate, oneWay) => {
//   if (oneWay) {
//     return formatSuperShort(startDate);
//   }
//   return `${formatSuperShort(startDate)} - ${formatSuperShort(endDate)}`;
// };// TODO Add Funcional and Snapshot tests to this!

const capitalizeFirstLetter = string => string.charAt(0).toUpperCase() + string.slice(1);

const MobileDateSelector = ({
  className,
  oneWay = false,
  multi,
  arrivalDate,
  departureDate,
  setArrivalDate,
  setDepartureDate,
  maxDate,
  isFlight = false
}) => {
  const [openModal, setOpenModal] = useState(false);
  
  const parsedArrivalDate = parseIso(arrivalDate)
  const parsedDepartureDate = parseIso(departureDate);

  const handleClick = () => {
    setOpenModal(true);
  };

  useEffect(() => {
    document.body.classList.toggle('non-scroll', openModal);
  }, [openModal]);

  const handleSelectDates = (startDate, endDate) => {
    setOpenModal(false);
    if (!startDate || !endDate) return;
    if (oneWay) {
      setDepartureDate(formatIso(startDate));
    } else {
      setDepartureDate(formatIso(startDate));
      setArrivalDate(formatIso(endDate));
    }
  };

  return (
    <React.Fragment>
      {openModal && (
        <Portal>
          <MobileCalendar
            onSelected={handleSelectDates}
            askDateEnd={oneWay}
            initialStartDate={parsedDepartureDate}
            initialEndDate={parsedArrivalDate}
            maxDate={maxDate}
            oneWay={oneWay}
            isFlight={isFlight}
          />
        </Portal>
      )}
      <div
        className={cs(className, { 'rectangle-mobile-date-selector': true })}
        onClick={handleClick}>
        <DateHolder>
          <CalendarIcon />
          <span>{`${capitalizeFirstLetter(
            dayInWeek(parsedDepartureDate)
          )}, ${formatSuperShort(parsedDepartureDate)}`}</span>
        </DateHolder>
        <Divider />
        <DateHolder>
          <CalendarIcon />
           {!oneWay &&   <span>{`${capitalizeFirstLetter(
              dayInWeek(parsedArrivalDate)
            )}, ${formatSuperShort(parsedArrivalDate)}`}</span>} 
        </DateHolder>
      </div>
    </React.Fragment>
  );
};

const Divider = styled.div`
  width: 1px;
  height: 24px;
  background-color: #fb8400;
`;

MobileDateSelector.propTypes = {
  className: PropTypes.string.isRequired,
  oneWay: PropTypes.bool,
  onSelectDate: PropTypes.func,
  multi: PropTypes.bool,
  arrivalDate: PropTypes.string,
  departureDate: PropTypes.string,
  stateless: PropTypes.bool,
  setArrivalDate: PropTypes.func,
  setDepartureDate: PropTypes.func,
  isEmpty: PropTypes.bool,
  maxDate: PropTypes.instanceOf(Date),
  isFlight: PropTypes.bool
};

const DateHolder = styled.div`
  display: flex;
  gap: 11px;
  font-family: 'Itau Display Pro';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  display: flex;
  align-items: center;
  color: rgba(40, 40, 40, 0.5);
`;

export default styled(MobileDateSelector)`
  width: 100%;
  min-height: 40px;
  box-sizing: border-box;
  padding: 7px;
  gap: 7px;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: flex-start;
  background: #ffffff;
  border: 1px solid rgba(40, 40, 40, 0.25);
  border-radius: 5px;
  font-family: 'Itau Display Pro';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  display: flex;
  align-items: center;
  color: rgba(40, 40, 40, 0.5);
`;
