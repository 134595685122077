export default [
  {
    code: 'AF',
    plCitizenCode: 'AFG',
    coinId: 3,
    phoneCode: '93',
    name: 'Afganistán',
    enable: true,
    plContinentalRegionName: 'Asia Central'
  },
  {
    code: 'AL',
    plCitizenCode: 'ALB',
    coinId: 5,
    phoneCode: '355',
    name: 'Albania',
    enable: true,
    plContinentalRegionName: 'Península Balcánica'
  },
  {
    code: 'DE',
    plCitizenCode: 'DEU',
    coinId: 1,
    phoneCode: '49',
    name: 'Alemania',
    enable: true,
    plContinentalRegionName: 'Europa Central'
  },
  {
    code: 'AD',
    plCitizenCode: 'AND',
    coinId: 1,
    phoneCode: '376',
    name: 'Andorra',
    enable: true,
    plContinentalRegionName: 'Europa Occidental - Europa Del Oeste'
  },
  {
    code: 'AO',
    plCitizenCode: 'AGO',
    coinId: 7,
    phoneCode: '244',
    name: 'Angola',
    enable: true,
    plContinentalRegionName: 'África Central'
  },
  {
    code: 'AI',
    plCitizenCode: 'AIA',
    coinId: 4,
    phoneCode: '1264',
    name: 'Anguila',
    enable: true,
    plContinentalRegionName: 'América Central y Caribe'
  },
  {
    code: 'AG',
    plCitizenCode: 'ATG',
    coinId: 4,
    phoneCode: '1268',
    name: 'Antigua Y Barbuda',
    enable: true,
    plContinentalRegionName: 'América Central y Caribe'
  },
  {
    code: 'SA',
    plCitizenCode: 'SAU',
    coinId: 123,
    phoneCode: '966',
    name: 'Arabia Saudita',
    enable: true,
    plContinentalRegionName: 'Golfo Pérsico - Oriente Medio'
  },
  {
    code: 'DZ',
    plCitizenCode: 'DZA',
    coinId: 46,
    phoneCode: '213',
    name: 'Argelia',
    enable: true,
    plContinentalRegionName: 'África Del Norte'
  },
  {
    code: 'AR',
    plCitizenCode: 'ARG',
    coinId: 9,
    phoneCode: '54',
    name: 'Argentina',
    enable: true,
    plContinentalRegionName: 'América del Sur'
  },
  {
    code: 'AM',
    plCitizenCode: 'ARM',
    coinId: 6,
    phoneCode: '374',
    name: 'Armenia',
    enable: true,
    plContinentalRegionName: 'Europa - Eurasia'
  },
  {
    code: 'AW',
    plCitizenCode: 'ANT',
    coinId: 12,
    phoneCode: '297',
    name: 'Aruba',
    enable: true,
    plContinentalRegionName: 'América Central y Caribe'
  },
  {
    code: 'AU',
    plCitizenCode: 'AUS',
    coinId: 11,
    phoneCode: '61',
    name: 'Australia',
    enable: true,
    plContinentalRegionName: 'Australia'
  },
  {
    code: 'AT',
    plCitizenCode: 'AUT',
    coinId: 1,
    phoneCode: '43',
    name: 'Austria',
    enable: true,
    plContinentalRegionName: 'Europa Central'
  },
  {
    code: 'AZ',
    plCitizenCode: 'AZE',
    coinId: 13,
    phoneCode: '994',
    name: 'Azerbaiyán',
    enable: true,
    plContinentalRegionName: 'Eurasia'
  },
  {
    code: 'BS',
    plCitizenCode: 'BHS',
    coinId: 25,
    phoneCode: '1242',
    name: 'Bahamas',
    enable: true,
    plContinentalRegionName: 'América Central y Caribe'
  },
  {
    code: 'BD',
    plCitizenCode: 'BGD',
    coinId: 16,
    phoneCode: '880',
    name: 'Bangladesh',
    enable: true,
    plContinentalRegionName: 'Golfo de Bengala'
  },
  {
    code: 'BB',
    plCitizenCode: 'BRB',
    coinId: 15,
    phoneCode: '1',
    name: 'Barbados',
    enable: true,
    plContinentalRegionName: 'América Central y Caribe'
  },
  {
    code: 'BH',
    plCitizenCode: 'BHR',
    coinId: 19,
    phoneCode: '973',
    name: 'Baréin',
    enable: true,
    plContinentalRegionName: 'Golfo Pérsico - Oriente Medio'
  },
  {
    code: 'BZ',
    plCitizenCode: 'BLZ',
    coinId: 29,
    phoneCode: '501',
    name: 'Belice',
    enable: true,
    plContinentalRegionName: 'América Central y Caribe'
  },
  {
    code: 'BJ',
    plCitizenCode: 'BEN',
    coinId: 17,
    phoneCode: '229',
    name: 'Benín',
    enable: true,
    plContinentalRegionName: 'África Occidental - África Del Oeste'
  },
  {
    code: 'BM',
    plCitizenCode: 'BMU',
    coinId: 21,
    phoneCode: '1441',
    name: 'Bermudas',
    enable: true,
    plContinentalRegionName: 'América Central y Caribe'
  },
  {
    code: 'BY',
    plCitizenCode: 'BLR',
    coinId: 28,
    phoneCode: '375',
    name: 'Bielorrusia',
    enable: true,
    plContinentalRegionName: 'Europa Oriental - Europa Del Este'
  },
  {
    code: 'MM',
    plCitizenCode: 'MMR',
    coinId: 94,
    phoneCode: '95',
    name: 'Birmania',
    enable: true,
    plContinentalRegionName: 'Golfo de Bengala'
  },
  {
    code: 'BO',
    plCitizenCode: 'BOL',
    coinId: 23,
    phoneCode: '591',
    name: 'Bolivia',
    enable: true,
    plContinentalRegionName: 'América del Sur'
  },
  {
    code: 'BQ',
    plCitizenCode: 'BES',
    coinId: 10,
    phoneCode: '5977',
    name: 'Bonaire, San Eustaquio Y Saba',
    enable: true,
    plContinentalRegionName: 'América Central y Caribe'
  },
  {
    code: 'BA',
    plCitizenCode: 'BIH',
    coinId: 14,
    phoneCode: '387',
    name: 'Bosnia Y Herzegovina',
    enable: true,
    plContinentalRegionName: 'Península Balcánica'
  },
  {
    code: 'BW',
    plCitizenCode: 'BWA',
    coinId: 27,
    phoneCode: '267',
    name: 'Botsuana',
    enable: true,
    plContinentalRegionName: 'África Austral - África Del Sur'
  },
  {
    code: 'BR',
    plCitizenCode: 'BRA',
    coinId: 24,
    phoneCode: '55',
    name: 'Brasil',
    enable: true,
    plContinentalRegionName: 'América del Sur'
  },
  {
    code: 'BN',
    plCitizenCode: 'BRN',
    coinId: 22,
    phoneCode: '673',
    name: 'Brunei',
    enable: true,
    plContinentalRegionName: 'Sudeste Asiático'
  },
  {
    code: 'BG',
    plCitizenCode: 'BGR',
    coinId: 18,
    phoneCode: '359',
    name: 'Bulgaria',
    enable: true,
    plContinentalRegionName: 'Península Balcánica'
  },
  {
    code: 'BF',
    plCitizenCode: 'BFA',
    coinId: 17,
    phoneCode: '226',
    name: 'Burkina Faso',
    enable: true,
    plContinentalRegionName: 'África Occidental - África Del Oeste'
  },
  {
    code: 'BI',
    plCitizenCode: 'BDI',
    coinId: 20,
    phoneCode: '257',
    name: 'Burundi',
    enable: true,
    plContinentalRegionName: 'África Oriental - África Del Este'
  },
  {
    code: 'BT',
    plCitizenCode: 'BTN',
    coinId: 26,
    phoneCode: '975',
    name: 'Bután',
    enable: true,
    plContinentalRegionName: 'Asia Oriental - Asia Del Este'
  },
  {
    code: 'BE',
    plCitizenCode: 'BEL',
    coinId: 1,
    phoneCode: '32',
    name: 'Bélgica',
    enable: true,
    plContinentalRegionName: 'Europa Occidental - Europa Del Oeste'
  },
  {
    code: 'CV',
    plCitizenCode: 'CPV',
    coinId: 40,
    phoneCode: '238',
    name: 'Cabo Verde',
    enable: true,
    plContinentalRegionName: 'África Occidental - África Del Oeste'
  },
  {
    code: 'KH',
    plCitizenCode: 'KHM',
    coinId: 77,
    phoneCode: '855',
    name: 'Camboya',
    enable: true,
    plContinentalRegionName: 'Sudeste Asiático'
  },
  {
    code: 'CM',
    plCitizenCode: 'CMR',
    coinId: 32,
    phoneCode: '237',
    name: 'Camerún',
    enable: true,
    plContinentalRegionName: 'África Central'
  },
  {
    code: 'CA',
    plCitizenCode: 'CAN',
    coinId: 30,
    phoneCode: '1',
    name: 'Canadá',
    enable: true,
    plContinentalRegionName: 'América del Norte'
  },
  {
    code: 'QA',
    plCitizenCode: 'QAT',
    coinId: 118,
    phoneCode: '974',
    name: 'Catar',
    enable: true,
    plContinentalRegionName: 'Golfo Pérsico - Oriente Medio'
  },
  {
    code: 'TD',
    plCitizenCode: 'TCD',
    coinId: 32,
    phoneCode: '235',
    name: 'Chad',
    enable: true,
    plContinentalRegionName: 'África Central'
  },
  {
    code: 'CL',
    plCitizenCode: 'CHL',
    coinId: 35,
    phoneCode: '56',
    name: 'Chile',
    enable: true,
    plContinentalRegionName: 'América del Sur'
  },
  {
    code: 'CN',
    plCitizenCode: 'CHN',
    coinId: 36,
    phoneCode: '86',
    name: 'China',
    enable: true,
    plContinentalRegionName: 'Asia Oriental - Asia Del Este'
  },
  {
    code: 'CY',
    plCitizenCode: 'CYP',
    coinId: 1,
    phoneCode: '357',
    name: 'Chipre',
    enable: true,
    plContinentalRegionName: 'Oriente Medio'
  },
  {
    code: 'CO',
    plCitizenCode: 'COL',
    coinId: 37,
    phoneCode: '57',
    name: 'Colombia',
    enable: true,
    plContinentalRegionName: 'América del Sur'
  },
  {
    code: 'KM',
    plCitizenCode: 'COM',
    coinId: 78,
    phoneCode: '269',
    name: 'Comoras',
    enable: true,
    plContinentalRegionName: 'África Oriental - África Del Este'
  },
  {
    code: 'KP',
    plCitizenCode: 'PRK',
    coinId: 79,
    phoneCode: '850',
    name: 'Corea Del Norte',
    enable: true,
    plContinentalRegionName: 'Asia Oriental - Asia Del Este'
  },
  {
    code: 'KR',
    plCitizenCode: 'KOR',
    coinId: 80,
    phoneCode: '82',
    name: 'Corea Del Sur',
    enable: true,
    plContinentalRegionName: 'Asia Oriental - Asia Del Este'
  },
  {
    code: 'CI',
    plCitizenCode: 'CIV',
    coinId: 17,
    phoneCode: '225',
    name: 'Costa De Marfil',
    enable: true,
    plContinentalRegionName: 'África Occidental - África Del Oeste'
  },
  {
    code: 'CR',
    plCitizenCode: 'CRI',
    coinId: 38,
    phoneCode: '506',
    name: 'Costa Rica',
    enable: true,
    plContinentalRegionName: 'América Central y Caribe'
  },
  {
    code: 'HR',
    plCitizenCode: 'HRV',
    coinId: 63,
    phoneCode: '385',
    name: 'Croacia',
    enable: true,
    plContinentalRegionName: 'Península Balcánica'
  },
  {
    code: 'CU',
    plCitizenCode: 'CUB',
    coinId: 39,
    phoneCode: '53',
    name: 'Cuba',
    enable: true,
    plContinentalRegionName: 'América Central y Caribe'
  },
  {
    code: 'CW',
    plCitizenCode: 'CUW',
    coinId: 41,
    phoneCode: '599',
    name: 'Curazao',
    enable: true,
    plContinentalRegionName: 'América Central y Caribe'
  },
  {
    code: 'DK',
    plCitizenCode: 'DNK',
    coinId: 44,
    phoneCode: '45',
    name: 'Dinamarca',
    enable: true,
    plContinentalRegionName: 'Escandinavia'
  },
  {
    code: 'DM',
    plCitizenCode: 'DMA',
    coinId: 4,
    phoneCode: '1767',
    name: 'Dominica',
    enable: true,
    plContinentalRegionName: 'América Central y Caribe'
  },
  {
    code: 'EC',
    plCitizenCode: 'ECU',
    coinId: 10,
    phoneCode: '593',
    name: 'Ecuador',
    enable: true,
    plContinentalRegionName: 'América del Sur'
  },
  {
    code: 'EG',
    plCitizenCode: 'EGY',
    coinId: 47,
    phoneCode: '2',
    name: 'Egipto',
    enable: true,
    plContinentalRegionName: 'África Del Norte'
  },
  {
    code: 'SV',
    plCitizenCode: 'SLV',
    coinId: 135,
    phoneCode: '503',
    name: 'El Salvador',
    enable: true,
    plContinentalRegionName: 'América Central y Caribe'
  },
  {
    code: 'AE',
    plCitizenCode: 'ARE',
    coinId: 2,
    phoneCode: '971',
    name: 'Emiratos Árabes Unidos',
    enable: true,
    plContinentalRegionName: 'Golfo Pérsico - Oriente Medio'
  },
  {
    code: 'ER',
    plCitizenCode: 'ERI',
    coinId: 49,
    phoneCode: '291',
    name: 'Eritrea',
    enable: true,
    plContinentalRegionName: 'África Oriental - África Del Este'
  },
  {
    code: 'SK',
    plCitizenCode: 'SVK',
    coinId: 1,
    phoneCode: '421',
    name: 'Eslovaquia',
    enable: true,
    plContinentalRegionName: 'Europa Central'
  },
  {
    code: 'SI',
    plCitizenCode: 'SVN',
    coinId: 1,
    phoneCode: '386',
    name: 'Eslovenia',
    enable: true,
    plContinentalRegionName: 'Europa Central'
  },
  {
    code: 'ES',
    plCitizenCode: 'ESP',
    coinId: 1,
    phoneCode: '34',
    name: 'España',
    enable: true,
    plContinentalRegionName: 'Península Ibérica'
  },
  {
    code: 'US',
    plCitizenCode: 'USA',
    coinId: 10,
    phoneCode: '1',
    name: 'Estados Unidos',
    enable: true,
    plContinentalRegionName: 'América del Norte'
  },
  {
    code: 'EE',
    plCitizenCode: 'EST',
    coinId: 1,
    phoneCode: '372',
    name: 'Estonia',
    enable: true,
    plContinentalRegionName: 'Países Bálticos'
  },
  {
    code: 'SZ',
    plCitizenCode: 'SWZ',
    coinId: 137,
    phoneCode: '268',
    name: 'Esuatini - Suazilandia',
    enable: true,
    plContinentalRegionName: 'África Austral - África Del Sur'
  },
  {
    code: 'ET',
    plCitizenCode: 'ETH',
    coinId: 50,
    phoneCode: '251',
    name: 'Etiopía',
    enable: true,
    plContinentalRegionName: 'África Oriental - África Del Este'
  },
  {
    code: 'PH',
    plCitizenCode: 'PHL',
    coinId: 114,
    phoneCode: '63',
    name: 'Filipinas',
    enable: true,
    plContinentalRegionName: 'Sudeste Asiático'
  },
  {
    code: 'FI',
    plCitizenCode: 'FIN',
    coinId: 1,
    phoneCode: '358',
    name: 'Finlandia',
    enable: true,
    plContinentalRegionName: 'Escandinavia'
  },
  {
    code: 'FJ',
    plCitizenCode: 'FJI',
    coinId: 51,
    phoneCode: '679',
    name: 'Fiyi',
    enable: true,
    plContinentalRegionName: 'Melanesia'
  },
  {
    code: 'FR',
    plCitizenCode: 'FRA',
    coinId: 1,
    phoneCode: '33',
    name: 'Francia',
    enable: true,
    plContinentalRegionName: 'Europa Occidental - Europa Del Oeste'
  },
  {
    code: 'GA',
    plCitizenCode: 'GAB',
    coinId: 32,
    phoneCode: '241',
    name: 'Gabón',
    enable: true,
    plContinentalRegionName: 'África Central'
  },
  {
    code: 'GM',
    plCitizenCode: 'GMB',
    coinId: 57,
    phoneCode: '220',
    name: 'Gambia',
    enable: true,
    plContinentalRegionName: 'África Occidental - África Del Oeste'
  },
  {
    code: 'GE',
    plCitizenCode: 'GEO',
    coinId: 54,
    phoneCode: '995',
    name: 'Georgia',
    enable: true,
    plContinentalRegionName: 'Eurasia'
  },
  {
    code: 'GH',
    plCitizenCode: 'GHA',
    coinId: 55,
    phoneCode: '233',
    name: 'Ghana',
    enable: true,
    plContinentalRegionName: 'África Occidental - África Del Oeste'
  },
  {
    code: 'GI',
    plCitizenCode: 'GIB',
    coinId: 56,
    phoneCode: '350',
    name: 'Gibraltar',
    enable: true,
    plContinentalRegionName: 'Península Ibérica'
  },
  {
    code: 'GD',
    plCitizenCode: 'GRD',
    coinId: 4,
    phoneCode: '1473',
    name: 'Granada',
    enable: true,
    plContinentalRegionName: 'América Central y Caribe'
  },
  {
    code: 'GR',
    plCitizenCode: 'GRC',
    coinId: 1,
    phoneCode: '30',
    name: 'Grecia',
    enable: true,
    plContinentalRegionName: 'Península Balcánica'
  },
  {
    code: 'GL',
    plCitizenCode: 'GRL',
    coinId: 44,
    phoneCode: '299',
    name: 'Groenlandia',
    enable: true,
    plContinentalRegionName: 'América del Norte'
  },
  {
    code: 'GP',
    plCitizenCode: 'GLP',
    coinId: 1,
    phoneCode: '590',
    name: 'Guadalupe',
    enable: true,
    plContinentalRegionName: 'América Central y Caribe'
  },
  {
    code: 'GU',
    plCitizenCode: 'GUM',
    coinId: 10,
    phoneCode: '1671',
    name: 'Guam',
    enable: true,
    plContinentalRegionName: 'Micronesia'
  },
  {
    code: 'GT',
    plCitizenCode: 'GTM',
    coinId: 59,
    phoneCode: '502',
    name: 'Guatemala',
    enable: true,
    plContinentalRegionName: 'América Central y Caribe'
  },
  {
    code: 'GY',
    plCitizenCode: 'GUY',
    coinId: 60,
    phoneCode: '592',
    name: 'Guayana',
    enable: true,
    plContinentalRegionName: 'América del Sur'
  },
  {
    code: 'GF',
    plCitizenCode: 'GUF',
    coinId: 1,
    phoneCode: '592',
    name: 'Guayana Francesa',
    enable: true,
    plContinentalRegionName: 'América del Sur'
  },
  {
    code: 'GG',
    plCitizenCode: 'GGY',
    coinId: 53,
    phoneCode: '441481',
    name: 'Guernsey',
    enable: true,
    plContinentalRegionName: 'Islas Británicas'
  },
  {
    code: 'GN',
    plCitizenCode: 'GIN',
    coinId: 58,
    phoneCode: '224',
    name: 'Guinea',
    enable: true,
    plContinentalRegionName: 'África Occidental - África Del Oeste'
  },
  {
    code: 'GQ',
    plCitizenCode: 'GNQ',
    coinId: 32,
    phoneCode: '240',
    name: 'Guinea Ecuatorial',
    enable: true,
    plContinentalRegionName: 'África Central'
  },
  {
    code: 'GW',
    plCitizenCode: 'GNB',
    coinId: 17,
    phoneCode: '245',
    name: 'Guinea-Bissau',
    enable: true,
    plContinentalRegionName: 'África Occidental - África Del Oeste'
  },
  {
    code: 'HT',
    plCitizenCode: 'HTI',
    coinId: 64,
    phoneCode: '509',
    name: 'Haití',
    enable: true,
    plContinentalRegionName: 'América Central y Caribe'
  },
  {
    code: 'NL',
    plCitizenCode: 'NLD',
    coinId: 1,
    phoneCode: '31',
    name: 'Holanda (Paises Bajos)',
    enable: true,
    plContinentalRegionName: 'Europa Occidental - Europa Del Oeste'
  },
  {
    code: 'HN',
    plCitizenCode: 'HND',
    coinId: 62,
    phoneCode: '504',
    name: 'Honduras',
    enable: true,
    plContinentalRegionName: 'América Central y Caribe'
  },
  {
    code: 'HK',
    plCitizenCode: 'HKG',
    coinId: 61,
    phoneCode: '852',
    name: 'Hong Kong',
    enable: true,
    plContinentalRegionName: 'Asia Oriental - Asia Del Este'
  },
  {
    code: 'HU',
    plCitizenCode: 'HUN',
    coinId: 65,
    phoneCode: '36',
    name: 'Hungría',
    enable: true,
    plContinentalRegionName: 'Europa Central'
  },
  {
    code: 'IN',
    plCitizenCode: 'IND',
    coinId: 68,
    phoneCode: '91',
    name: 'India',
    enable: true,
    plContinentalRegionName: 'Golfo de Bengala'
  },
  {
    code: 'ID',
    plCitizenCode: 'IDN',
    coinId: 66,
    phoneCode: '62',
    name: 'Indonesia',
    enable: true,
    plContinentalRegionName: 'Golfo de Bengala'
  },
  {
    code: 'IQ',
    plCitizenCode: 'IRQ',
    coinId: 69,
    phoneCode: '964',
    name: 'Irak',
    enable: true,
    plContinentalRegionName: 'Golfo Pérsico - Oriente Medio'
  },
  {
    code: 'IE',
    plCitizenCode: 'IRL',
    coinId: 1,
    phoneCode: '353',
    name: 'Irlanda',
    enable: true,
    plContinentalRegionName: 'Islas Británicas'
  },
  {
    code: 'IR',
    plCitizenCode: 'IRN',
    coinId: 70,
    phoneCode: '98',
    name: 'Irán',
    enable: true,
    plContinentalRegionName: 'Golfo Pérsico - Oriente Medio'
  },
  {
    code: 'IM',
    plCitizenCode: 'IMN',
    coinId: 53,
    phoneCode: '441624',
    name: 'Isla De Man',
    enable: true,
    plContinentalRegionName: 'Islas Británicas'
  },
  {
    code: 'CX',
    plCitizenCode: 'CXR',
    coinId: 11,
    phoneCode: '61',
    name: 'Isla De Navidad',
    enable: true,
    plContinentalRegionName: 'Sudeste Asiático'
  },
  {
    code: 'NF',
    plCitizenCode: 'NFK',
    coinId: 11,
    phoneCode: '672',
    name: 'Isla Norfolk',
    enable: true,
    plContinentalRegionName: 'Micronesia'
  },
  {
    code: 'IS',
    plCitizenCode: 'ISL',
    coinId: 71,
    phoneCode: '354',
    name: 'Islandia',
    enable: true,
    plContinentalRegionName: 'Escandinavia'
  },
  {
    code: 'KY',
    plCitizenCode: 'CYM',
    coinId: 82,
    phoneCode: '1345',
    name: 'Islas Caimán',
    enable: true,
    plContinentalRegionName: 'América Central y Caribe'
  },
  {
    code: 'CC',
    plCitizenCode: 'CCK',
    coinId: 11,
    phoneCode: '61',
    name: 'Islas Cocos',
    enable: true,
    plContinentalRegionName: 'Sudeste Asiático'
  },
  {
    code: 'CK',
    plCitizenCode: 'COK',
    coinId: 34,
    phoneCode: '682',
    name: 'Islas Cook',
    enable: true,
    plContinentalRegionName: 'Polinesia'
  },
  {
    code: 'FK',
    plCitizenCode: 'FLK',
    coinId: 52,
    phoneCode: '500',
    name: 'Islas Falkland (Malvinas)',
    enable: true,
    plContinentalRegionName: 'América del Sur'
  },
  {
    code: 'FO',
    plCitizenCode: 'FRO',
    coinId: 44,
    phoneCode: '298',
    name: 'Islas Feroe',
    enable: true,
    plContinentalRegionName: 'Escandinavia'
  },
  {
    code: 'GS',
    plCitizenCode: 'GSS',
    coinId: 52,
    phoneCode: '500',
    name: 'Islas Georgias del Sur y Sandwich del Sur',
    enable: true,
    plContinentalRegionName: 'Ultramar'
  },
  {
    code: 'MP',
    plCitizenCode: 'MNP',
    coinId: 10,
    phoneCode: '1670',
    name: 'Islas Marianas Del Norte',
    enable: true,
    plContinentalRegionName: 'Micronesia'
  },
  {
    code: 'MH',
    plCitizenCode: 'MHL',
    coinId: 10,
    phoneCode: '692',
    name: 'Islas Marshall',
    enable: true,
    plContinentalRegionName: 'Micronesia'
  },
  {
    code: 'SB',
    plCitizenCode: 'SLB',
    coinId: 124,
    phoneCode: '677',
    name: 'Islas Salomón',
    enable: true,
    plContinentalRegionName: 'Melanesia'
  },
  {
    code: 'TC',
    plCitizenCode: 'TCA',
    coinId: 10,
    phoneCode: '1649',
    name: 'Islas Turcas Y Caicos',
    enable: true,
    plContinentalRegionName: 'América Central y Caribe'
  },
  {
    code: 'UM',
    plCitizenCode: 'UMM',
    coinId: 10,
    phoneCode: '246',
    name: 'Islas Ultramarinas Menores de Estados Unidos',
    enable: true,
    plContinentalRegionName: 'América del Norte'
  },
  {
    code: 'VI',
    plCitizenCode: 'VIR',
    coinId: 10,
    phoneCode: '1340',
    name: 'Islas Virgenes De Los Estados Unidos',
    enable: true,
    plContinentalRegionName: 'América Central y Caribe'
  },
  {
    code: 'VG',
    plCitizenCode: 'VGB',
    coinId: 10,
    phoneCode: '1284',
    name: 'Islas Vírgenes Británicas',
    enable: true,
    plContinentalRegionName: 'América Central y Caribe'
  },
  {
    code: 'IL',
    plCitizenCode: 'ISR',
    coinId: 67,
    phoneCode: '972',
    name: 'Israel',
    enable: true,
    plContinentalRegionName: 'Oriente Medio'
  },
  {
    code: 'IT',
    plCitizenCode: 'ITA',
    coinId: 1,
    phoneCode: '39',
    name: 'Italia',
    enable: true,
    plContinentalRegionName: 'Península Itálica'
  },
  {
    code: 'JM',
    plCitizenCode: 'JAM',
    coinId: 72,
    phoneCode: '1876',
    name: 'Jamaica',
    enable: true,
    plContinentalRegionName: 'América Central y Caribe'
  },
  {
    code: 'JP',
    plCitizenCode: 'JPN',
    coinId: 74,
    phoneCode: '81',
    name: 'Japón',
    enable: true,
    plContinentalRegionName: 'Asia Oriental - Asia Del Este'
  },
  {
    code: 'JE',
    plCitizenCode: 'JEY',
    coinId: 53,
    phoneCode: '441534',
    name: 'Jersey',
    enable: true,
    plContinentalRegionName: 'Islas Británicas'
  },
  {
    code: 'JO',
    plCitizenCode: 'JOR',
    coinId: 73,
    phoneCode: '962',
    name: 'Jordania',
    enable: true,
    plContinentalRegionName: 'Oriente Medio'
  },
  {
    code: 'KZ',
    plCitizenCode: 'KAZ',
    coinId: 83,
    phoneCode: '7',
    name: 'Kazajstán',
    enable: true,
    plContinentalRegionName: 'Eurasia'
  },
  {
    code: 'KE',
    plCitizenCode: 'KEN',
    coinId: 75,
    phoneCode: '254',
    name: 'Kenia',
    enable: true,
    plContinentalRegionName: 'África Oriental - África Del Este'
  },
  {
    code: 'KG',
    plCitizenCode: 'KGZ',
    coinId: 76,
    phoneCode: '996',
    name: 'Kirguizstán',
    enable: true,
    plContinentalRegionName: 'Asia Central'
  },
  {
    code: 'KI',
    plCitizenCode: 'KIR',
    coinId: 11,
    phoneCode: '686',
    name: 'Kiribati',
    enable: true,
    plContinentalRegionName: 'Polinesia'
  },
  {
    code: 'XK',
    plCitizenCode: 'XKS',
    coinId: 1,
    phoneCode: '383',
    name: 'Kosovo',
    enable: true,
    plContinentalRegionName: 'Península Balcánica'
  },
  {
    code: 'KW',
    plCitizenCode: 'KWT',
    coinId: 81,
    phoneCode: '965',
    name: 'Kuwait',
    enable: true,
    plContinentalRegionName: 'Golfo Pérsico - Oriente Medio'
  },
  {
    code: 'LA',
    plCitizenCode: 'LAO',
    coinId: 84,
    phoneCode: '856',
    name: 'Laos',
    enable: true,
    plContinentalRegionName: 'Sudeste Asiático'
  },
  {
    code: 'LS',
    plCitizenCode: 'LSO',
    coinId: 89,
    phoneCode: '266',
    name: 'Lesoto',
    enable: true,
    plContinentalRegionName: 'África Austral - África Del Sur'
  },
  {
    code: 'LV',
    plCitizenCode: 'LVA',
    coinId: 1,
    phoneCode: '371',
    name: 'Letonia',
    enable: true,
    plContinentalRegionName: 'Países Bálticos'
  },
  {
    code: 'LR',
    plCitizenCode: 'LBR',
    coinId: 88,
    phoneCode: '231',
    name: 'Liberia',
    enable: true,
    plContinentalRegionName: 'África Occidental - África Del Oeste'
  },
  {
    code: 'LY',
    plCitizenCode: 'LBY',
    coinId: 90,
    phoneCode: '218',
    name: 'Libia',
    enable: true,
    plContinentalRegionName: 'África Del Norte'
  },
  {
    code: 'LI',
    plCitizenCode: 'LIE',
    coinId: 86,
    phoneCode: '423',
    name: 'Liechtenstein',
    enable: true,
    plContinentalRegionName: 'Europa Central'
  },
  {
    code: 'LT',
    plCitizenCode: 'LTU',
    coinId: 1,
    phoneCode: '370',
    name: 'Lituania',
    enable: true,
    plContinentalRegionName: 'Países Bálticos'
  },
  {
    code: 'LU',
    plCitizenCode: 'LUX',
    coinId: 1,
    phoneCode: '352',
    name: 'Luxemburgo',
    enable: true,
    plContinentalRegionName: 'Europa Central'
  },
  {
    code: 'LB',
    plCitizenCode: 'LBN',
    coinId: 85,
    phoneCode: '961',
    name: 'Líbano',
    enable: true,
    plContinentalRegionName: 'Oriente Medio'
  },
  {
    code: 'MO',
    plCitizenCode: 'MAC',
    coinId: 96,
    phoneCode: '853',
    name: 'Macao',
    enable: true,
    plContinentalRegionName: 'Asia Oriental - Asia Del Este'
  },
  {
    code: 'MK',
    plCitizenCode: 'MKD',
    coinId: 93,
    phoneCode: '389',
    name: 'Macedonia',
    enable: true,
    plContinentalRegionName: 'Península Balcánica'
  },
  {
    code: 'MG',
    plCitizenCode: 'MDG',
    coinId: 92,
    phoneCode: '261',
    name: 'Madagascar',
    enable: true,
    plContinentalRegionName: 'África Oriental - África Del Este'
  },
  {
    code: 'MY',
    plCitizenCode: 'MYS',
    coinId: 102,
    phoneCode: '60',
    name: 'Malasia',
    enable: true,
    plContinentalRegionName: 'Sudeste Asiático'
  },
  {
    code: 'MW',
    plCitizenCode: 'MWI',
    coinId: 100,
    phoneCode: '265',
    name: 'Malaui',
    enable: true,
    plContinentalRegionName: 'África Oriental - África Del Este'
  },
  {
    code: 'MV',
    plCitizenCode: 'MDV',
    coinId: 99,
    phoneCode: '960',
    name: 'Maldivas',
    enable: true,
    plContinentalRegionName: 'Islas del Índico'
  },
  {
    code: 'ML',
    plCitizenCode: 'MLI',
    coinId: 17,
    phoneCode: '223',
    name: 'Mali',
    enable: true,
    plContinentalRegionName: 'África Occidental - África Del Oeste'
  },
  {
    code: 'MT',
    plCitizenCode: 'MLT',
    coinId: 1,
    phoneCode: '356',
    name: 'Malta',
    enable: true,
    plContinentalRegionName: 'Península Itálica'
  },
  {
    code: 'MA',
    plCitizenCode: 'MAR',
    coinId: 48,
    phoneCode: '212',
    name: 'Marruecos',
    enable: true,
    plContinentalRegionName: 'África Del Norte'
  },
  {
    code: 'MQ',
    plCitizenCode: 'MTQ',
    coinId: 1,
    phoneCode: '596',
    name: 'Martinica',
    enable: true,
    plContinentalRegionName: 'América Central y Caribe'
  },
  {
    code: 'MU',
    plCitizenCode: 'MUS',
    coinId: 98,
    phoneCode: '230',
    name: 'Mauricio',
    enable: true,
    plContinentalRegionName: 'África Oriental - África Del Este'
  },
  {
    code: 'MR',
    plCitizenCode: 'MRT',
    coinId: 97,
    phoneCode: '222',
    name: 'Mauritania',
    enable: true,
    plContinentalRegionName: 'África Occidental - África Del Oeste'
  },
  {
    code: 'YT',
    plCitizenCode: 'MYT',
    coinId: 1,
    phoneCode: '262',
    name: 'Mayotte',
    enable: true,
    plContinentalRegionName: 'África Oriental - África Del Este'
  },
  {
    code: 'FM',
    plCitizenCode: 'FSM',
    coinId: 10,
    phoneCode: '691',
    name: 'Micronesia',
    enable: true,
    plContinentalRegionName: 'Micronesia'
  },
  {
    code: 'MD',
    plCitizenCode: 'MDA',
    coinId: 91,
    phoneCode: '373',
    name: 'Moldavia',
    enable: true,
    plContinentalRegionName: 'Europa Oriental - Europa Del Este'
  },
  {
    code: 'MN',
    plCitizenCode: 'MNG',
    coinId: 95,
    phoneCode: '976',
    name: 'Mongolia',
    enable: true,
    plContinentalRegionName: 'Asia Oriental - Asia Del Este'
  },
  {
    code: 'ME',
    plCitizenCode: 'MNE',
    coinId: 1,
    phoneCode: '382',
    name: 'Montenegro',
    enable: true,
    plContinentalRegionName: 'Península Balcánica'
  },
  {
    code: 'MS',
    plCitizenCode: 'MSR',
    coinId: 4,
    phoneCode: '1664',
    name: 'Montserrat',
    enable: true,
    plContinentalRegionName: 'América Central y Caribe'
  },
  {
    code: 'MZ',
    plCitizenCode: 'MOZ',
    coinId: 103,
    phoneCode: '258',
    name: 'Mozambique',
    enable: true,
    plContinentalRegionName: 'África Oriental - África Del Este'
  },
  {
    code: 'MX',
    plCitizenCode: 'MEX',
    coinId: 101,
    phoneCode: '52',
    name: 'México',
    enable: true,
    plContinentalRegionName: 'América Central y Caribe'
  },
  {
    code: 'MC',
    plCitizenCode: 'MCO',
    coinId: 1,
    phoneCode: '377',
    name: 'Mónaco',
    enable: true,
    plContinentalRegionName: 'Europa Occidental - Europa Del Oeste'
  },
  {
    code: 'NA',
    plCitizenCode: 'NAM',
    coinId: 104,
    phoneCode: '264',
    name: 'Namibia',
    enable: true,
    plContinentalRegionName: 'África Austral - África Del Sur'
  },
  {
    code: 'NR',
    plCitizenCode: 'NRU',
    coinId: 11,
    phoneCode: '674',
    name: 'Nauru',
    enable: true,
    plContinentalRegionName: 'Micronesia'
  },
  {
    code: 'NP',
    plCitizenCode: 'NPL',
    coinId: 109,
    phoneCode: '977',
    name: 'Nepal',
    enable: true,
    plContinentalRegionName: 'Asia Oriental - Asia Del Este'
  },
  {
    code: 'NI',
    plCitizenCode: 'NIC',
    coinId: 107,
    phoneCode: '505',
    name: 'Nicaragua',
    enable: true,
    plContinentalRegionName: 'América Central y Caribe'
  },
  {
    code: 'NG',
    plCitizenCode: 'NGA',
    coinId: 106,
    phoneCode: '234',
    name: 'Nigeria',
    enable: true,
    plContinentalRegionName: 'África Occidental - África Del Oeste'
  },
  {
    code: 'NU',
    plCitizenCode: 'NIU',
    coinId: 34,
    phoneCode: '683',
    name: 'Niue',
    enable: true,
    plContinentalRegionName: 'Polinesia'
  },
  {
    code: 'NO',
    plCitizenCode: 'NOR',
    coinId: 108,
    phoneCode: '47',
    name: 'Noruega',
    enable: true,
    plContinentalRegionName: 'Escandinavia'
  },
  {
    code: 'NC',
    plCitizenCode: 'NCL',
    coinId: 105,
    phoneCode: '687',
    name: 'Nueva Caledonia',
    enable: true,
    plContinentalRegionName: 'Melanesia'
  },
  {
    code: 'NZ',
    plCitizenCode: 'NZL',
    coinId: 34,
    phoneCode: '64',
    name: 'Nueva Zelanda',
    enable: true,
    plContinentalRegionName: 'Nueva Zelanda'
  },
  {
    code: 'NE',
    plCitizenCode: 'NER',
    coinId: 17,
    phoneCode: '227',
    name: 'Níger',
    enable: true,
    plContinentalRegionName: 'África Occidental - África Del Oeste'
  },
  {
    code: 'OM',
    plCitizenCode: 'OMN',
    coinId: 110,
    phoneCode: '968',
    name: 'Omán',
    enable: true,
    plContinentalRegionName: 'Golfo Pérsico - Oriente Medio'
  },
  {
    code: 'PK',
    plCitizenCode: 'PAK',
    coinId: 115,
    phoneCode: '92',
    name: 'Pakistán',
    enable: true,
    plContinentalRegionName: 'Golfo Pérsico - Oriente Medio'
  },
  {
    code: 'PW',
    plCitizenCode: 'PLW',
    coinId: 10,
    phoneCode: '680',
    name: 'Palaos',
    enable: true,
    plContinentalRegionName: 'Micronesia'
  },
  {
    code: 'PS',
    plCitizenCode: 'PSE',
    coinId: 67,
    phoneCode: '970',
    name: 'Palestina',
    enable: true,
    plContinentalRegionName: 'Oriente Medio'
  },
  {
    code: 'PA',
    plCitizenCode: 'PAN',
    coinId: 111,
    phoneCode: '507',
    name: 'Panamá',
    enable: true,
    plContinentalRegionName: 'América Central y Caribe'
  },
  {
    code: 'PG',
    plCitizenCode: 'PNG',
    coinId: 113,
    phoneCode: '675',
    name: 'Papúa Nueva Guinea',
    enable: true,
    plContinentalRegionName: 'Melanesia'
  },
  {
    code: 'PY',
    plCitizenCode: 'PRY',
    coinId: 117,
    phoneCode: '595',
    name: 'Paraguay',
    enable: true,
    plContinentalRegionName: 'América del Sur'
  },
  {
    code: 'PE',
    plCitizenCode: 'PER',
    coinId: 112,
    phoneCode: '51',
    name: 'Perú',
    enable: true,
    plContinentalRegionName: 'América del Sur'
  },
  {
    code: 'PN',
    plCitizenCode: 'PCN',
    coinId: 34,
    phoneCode: '64',
    name: 'Pitcairin Islands',
    enable: true,
    plContinentalRegionName: 'Ultramar'
  },
  {
    code: 'PF',
    plCitizenCode: 'PYF',
    coinId: 105,
    phoneCode: '689',
    name: 'Polinesia Francesa',
    enable: true,
    plContinentalRegionName: 'Polinesia'
  },
  {
    code: 'PL',
    plCitizenCode: 'POL',
    coinId: 116,
    phoneCode: '48',
    name: 'Polonia',
    enable: true,
    plContinentalRegionName: 'Europa Central'
  },
  {
    code: 'PT',
    plCitizenCode: 'PRT',
    coinId: 1,
    phoneCode: '351',
    name: 'Portugal',
    enable: true,
    plContinentalRegionName: 'Península Ibérica'
  },
  {
    code: 'PR',
    plCitizenCode: 'PRI',
    coinId: 10,
    phoneCode: '1787',
    name: 'Puerto Rico',
    enable: true,
    plContinentalRegionName: 'América Central y Caribe'
  },
  {
    code: 'GB',
    plCitizenCode: 'GBR',
    coinId: 53,
    phoneCode: '44',
    name: 'Reino Unido',
    enable: true,
    plContinentalRegionName: 'Islas Británicas'
  },
  {
    code: 'CZ',
    plCitizenCode: 'CZE',
    coinId: 42,
    phoneCode: '420',
    name: 'Republica Checa',
    enable: true,
    plContinentalRegionName: 'Europa Central'
  },
  {
    code: 'CF',
    plCitizenCode: 'CAF',
    coinId: 32,
    phoneCode: '236',
    name: 'República Centroafricana',
    enable: true,
    plContinentalRegionName: 'África Central'
  },
  {
    code: 'CG',
    plCitizenCode: 'COG',
    coinId: 32,
    phoneCode: '242',
    name: 'República Del Congo - Congo Brazzaville',
    enable: true,
    plContinentalRegionName: 'África Central'
  },
  {
    code: 'CD',
    plCitizenCode: 'COD',
    coinId: 31,
    phoneCode: '243',
    name: 'República Democrática Del Congo - Congo Kinshasa',
    enable: true,
    plContinentalRegionName: 'África Central'
  },
  {
    code: 'DO',
    plCitizenCode: 'DOM',
    coinId: 45,
    phoneCode: '1809',
    name: 'República Dominicana',
    enable: true,
    plContinentalRegionName: 'América Central y Caribe'
  },
  {
    code: 'RE',
    plCitizenCode: 'REU',
    coinId: 1,
    phoneCode: '262',
    name: 'Reunión',
    enable: true,
    plContinentalRegionName: 'África Oriental - África Del Este'
  },
  {
    code: 'RW',
    plCitizenCode: 'RWA',
    coinId: 122,
    phoneCode: '250',
    name: 'Ruanda',
    enable: true,
    plContinentalRegionName: 'África Oriental - África Del Este'
  },
  {
    code: 'RO',
    plCitizenCode: 'ROU',
    coinId: 119,
    phoneCode: '40',
    name: 'Rumania',
    enable: true,
    plContinentalRegionName: 'Península Balcánica'
  },
  {
    code: 'RU',
    plCitizenCode: 'RUS',
    coinId: 121,
    phoneCode: '7',
    name: 'Rusia',
    enable: true,
    plContinentalRegionName: 'Europa Oriental - Europa Del Este'
  },
  {
    code: 'EH',
    plCitizenCode: 'ESH',
    coinId: 48,
    phoneCode: '212',
    name: 'Sahara Occidental',
    enable: true,
    plContinentalRegionName: 'África Del Norte'
  },
  {
    code: 'MF',
    plCitizenCode: 'MAF',
    coinId: 1,
    phoneCode: '590',
    name: 'Saint Martin',
    enable: true,
    plContinentalRegionName: 'Territorios Europeos en el Caribe'
  },
  {
    code: 'WS',
    plCitizenCode: 'WSM',
    coinId: 154,
    phoneCode: '685',
    name: 'Samoa',
    enable: true,
    plContinentalRegionName: 'Polinesia'
  },
  {
    code: 'AS',
    plCitizenCode: 'ASM',
    coinId: 10,
    phoneCode: '1684',
    name: 'Samoa Americana',
    enable: true,
    plContinentalRegionName: 'Polinesia'
  },
  {
    code: 'BL',
    plCitizenCode: 'BLM',
    coinId: 1,
    phoneCode: '590',
    name: 'San Bartolomé',
    enable: true,
    plContinentalRegionName: 'América Central y Caribe'
  },
  {
    code: 'KN',
    plCitizenCode: 'KNA',
    coinId: 4,
    phoneCode: '1869',
    name: 'San Cristóbal Y Nieves',
    enable: true,
    plContinentalRegionName: 'América Central y Caribe'
  },
  {
    code: 'SM',
    plCitizenCode: 'SMR',
    coinId: 1,
    phoneCode: '378',
    name: 'San Marino',
    enable: true,
    plContinentalRegionName: 'Península Itálica'
  },
  {
    code: 'PM',
    plCitizenCode: 'SPM',
    coinId: 1,
    phoneCode: '508',
    name: 'San Pedro Y Miquelón',
    enable: true,
    plContinentalRegionName: 'América del Norte'
  },
  {
    code: 'VC',
    plCitizenCode: 'VCT',
    coinId: 4,
    phoneCode: '1784',
    name: 'San Vicente Y Las Granadinas',
    enable: true,
    plContinentalRegionName: 'América Central y Caribe'
  },
  {
    code: 'SH',
    plCitizenCode: 'SHN',
    coinId: 129,
    phoneCode: '290',
    name: 'Santa Helena',
    enable: true,
    plContinentalRegionName: 'África Occidental - África Del Oeste'
  },
  {
    code: 'LC',
    plCitizenCode: 'LCA',
    coinId: 4,
    phoneCode: '1758',
    name: 'Santa Lucía',
    enable: true,
    plContinentalRegionName: 'América Central y Caribe'
  },
  {
    code: 'ST',
    plCitizenCode: 'STP',
    coinId: 134,
    phoneCode: '239',
    name: 'Santo Tomé Y Príncipe',
    enable: true,
    plContinentalRegionName: 'África Central'
  },
  {
    code: 'SN',
    plCitizenCode: 'SEN',
    coinId: 17,
    phoneCode: '221',
    name: 'Senegal',
    enable: true,
    plContinentalRegionName: 'África Occidental - África Del Oeste'
  },
  {
    code: 'RS',
    plCitizenCode: 'SRB',
    coinId: 120,
    phoneCode: '381',
    name: 'Serbia',
    enable: true,
    plContinentalRegionName: 'Península Balcánica'
  },
  {
    code: 'SC',
    plCitizenCode: 'SYC',
    coinId: 125,
    phoneCode: '248',
    name: 'Seychelles',
    enable: true,
    plContinentalRegionName: 'África Oriental - África Del Este'
  },
  {
    code: 'SL',
    plCitizenCode: 'SLE',
    coinId: 130,
    phoneCode: '232',
    name: 'Sierra Leona',
    enable: true,
    plContinentalRegionName: 'África Occidental - África Del Oeste'
  },
  {
    code: 'SG',
    plCitizenCode: 'SGP',
    coinId: 128,
    phoneCode: '65',
    name: 'Singapur',
    enable: true,
    plContinentalRegionName: 'Sudeste Asiático'
  },
  {
    code: 'SX',
    plCitizenCode: 'SXM',
    coinId: 41,
    phoneCode: '1',
    name: 'Sint Maarten',
    enable: true,
    plContinentalRegionName: 'América Central y Caribe'
  },
  {
    code: 'SY',
    plCitizenCode: 'SYR',
    coinId: 136,
    phoneCode: '963',
    name: 'Siria',
    enable: true,
    plContinentalRegionName: 'Oriente Medio'
  },
  {
    code: 'SO',
    plCitizenCode: 'SOM',
    coinId: 131,
    phoneCode: '252',
    name: 'Somalia',
    enable: true,
    plContinentalRegionName: 'África Oriental - África Del Este'
  },
  {
    code: 'LK',
    plCitizenCode: 'LKA',
    coinId: 87,
    phoneCode: '94',
    name: 'Sri Lanka',
    enable: true,
    plContinentalRegionName: 'Golfo de Bengala'
  },
  {
    code: 'ZA',
    plCitizenCode: 'ZAF',
    coinId: 156,
    phoneCode: '27',
    name: 'Sudáfrica',
    enable: true,
    plContinentalRegionName: 'África Austral - África Del Sur'
  },
  {
    code: 'SD',
    plCitizenCode: 'SDN',
    coinId: 126,
    phoneCode: '249',
    name: 'Sudán',
    enable: true,
    plContinentalRegionName: 'África Oriental - África Del Este'
  },
  {
    code: 'SS',
    plCitizenCode: 'SSD',
    coinId: 133,
    phoneCode: '211',
    name: 'Sudán Del Sur',
    enable: true,
    plContinentalRegionName: 'África Oriental - África Del Este'
  },
  {
    code: 'SE',
    plCitizenCode: 'SWE',
    coinId: 127,
    phoneCode: '46',
    name: 'Suecia',
    enable: true,
    plContinentalRegionName: 'Escandinavia'
  },
  {
    code: 'CH',
    plCitizenCode: 'CHE',
    coinId: 33,
    phoneCode: '41',
    name: 'Suiza',
    enable: true,
    plContinentalRegionName: 'Europa Central'
  },
  {
    code: 'SR',
    plCitizenCode: 'SUR',
    coinId: 132,
    phoneCode: '597',
    name: 'Surinam',
    enable: true,
    plContinentalRegionName: 'América del Sur'
  },
  {
    code: 'SJ',
    plCitizenCode: 'ESC',
    coinId: 108,
    phoneCode: '47',
    name: 'Svalbard',
    enable: true,
    plContinentalRegionName: 'Escandinavia'
  },
  {
    code: 'TH',
    plCitizenCode: 'THA',
    coinId: 138,
    phoneCode: '66',
    name: 'Tailandia',
    enable: true,
    plContinentalRegionName: 'Sudeste Asiático'
  },
  {
    code: 'TW',
    plCitizenCode: 'TWN',
    coinId: 145,
    phoneCode: '886',
    name: 'Taiwán',
    enable: true,
    plContinentalRegionName: 'Asia Oriental - Asia Del Este'
  },
  {
    code: 'TZ',
    plCitizenCode: 'TZA',
    coinId: 146,
    phoneCode: '255',
    name: 'Tanzania',
    enable: true,
    plContinentalRegionName: 'África Oriental - África Del Este'
  },
  {
    code: 'TJ',
    plCitizenCode: 'TJK',
    coinId: 139,
    phoneCode: '992',
    name: 'Tayikistan',
    enable: true,
    plContinentalRegionName: 'Asia Central'
  },
  {
    code: 'IO',
    plCitizenCode: 'GBN',
    coinId: 10,
    phoneCode: '246',
    name: 'Territorio Británico Del Océano Índico',
    enable: true,
    plContinentalRegionName: 'Islas del Índico'
  },
  {
    code: 'TL',
    plCitizenCode: 'TLS',
    coinId: 10,
    phoneCode: '670',
    name: 'Timor Oriental',
    enable: true,
    plContinentalRegionName: 'Melanesia'
  },
  {
    code: 'TG',
    plCitizenCode: 'TGO',
    coinId: 17,
    phoneCode: '228',
    name: 'Togo',
    enable: true,
    plContinentalRegionName: 'África Occidental - África Del Oeste'
  },
  {
    code: 'TO',
    plCitizenCode: 'TON',
    coinId: 142,
    phoneCode: '676',
    name: 'Tonga',
    enable: true,
    plContinentalRegionName: 'Polinesia'
  },
  {
    code: 'TT',
    plCitizenCode: 'TTO',
    coinId: 144,
    phoneCode: '1868',
    name: 'Trinidad Y Tobago',
    enable: true,
    plContinentalRegionName: 'América Central y Caribe'
  },
  {
    code: 'TM',
    plCitizenCode: 'TKM',
    coinId: 140,
    phoneCode: '993',
    name: 'Turkmenistán',
    enable: true,
    plContinentalRegionName: 'Asia Central'
  },
  {
    code: 'TR',
    plCitizenCode: 'TUR',
    coinId: 143,
    phoneCode: '90',
    name: 'Turquía',
    enable: true,
    plContinentalRegionName: 'Eurasia'
  },
  {
    code: 'TV',
    plCitizenCode: 'TUV',
    coinId: 11,
    phoneCode: '688',
    name: 'Tuvalu',
    enable: true,
    plContinentalRegionName: 'Polinesia'
  },
  {
    code: 'TN',
    plCitizenCode: 'TUN',
    coinId: 141,
    phoneCode: '216',
    name: 'Túnez',
    enable: true,
    plContinentalRegionName: 'África Del Norte'
  },
  {
    code: 'UA',
    plCitizenCode: 'UKR',
    coinId: 147,
    phoneCode: '380',
    name: 'Ucrania',
    enable: true,
    plContinentalRegionName: 'Europa Oriental - Europa Del Este'
  },
  {
    code: 'UG',
    plCitizenCode: 'UGA',
    coinId: 148,
    phoneCode: '256',
    name: 'Uganda',
    enable: true,
    plContinentalRegionName: 'África Oriental - África Del Este'
  },
  {
    code: 'UY',
    plCitizenCode: 'URY',
    coinId: 149,
    phoneCode: '598',
    name: 'Uruguay',
    enable: true,
    plContinentalRegionName: 'América del Sur'
  },
  {
    code: 'UZ',
    plCitizenCode: 'UZB',
    coinId: 150,
    phoneCode: '998',
    name: 'Uzbekistán',
    enable: true,
    plContinentalRegionName: 'Asia Central'
  },
  {
    code: 'VU',
    plCitizenCode: 'VUT',
    coinId: 153,
    phoneCode: '678',
    name: 'Vanuatu',
    enable: true,
    plContinentalRegionName: 'Melanesia'
  },
  {
    code: 'VE',
    plCitizenCode: 'VEN',
    coinId: 151,
    phoneCode: '58',
    name: 'Venezuela',
    enable: true,
    plContinentalRegionName: 'América del Sur'
  },
  {
    code: 'VN',
    plCitizenCode: 'VNM',
    coinId: 152,
    phoneCode: '84',
    name: 'Vietnam',
    enable: true,
    plContinentalRegionName: 'Sudeste Asiático'
  },
  {
    code: 'WF',
    plCitizenCode: 'WLF',
    coinId: 105,
    phoneCode: '681',
    name: 'Wallis Y Futuna',
    enable: true,
    plContinentalRegionName: 'Polinesia'
  },
  {
    code: 'YE',
    plCitizenCode: 'YEM',
    coinId: 155,
    phoneCode: '967',
    name: 'Yemen',
    enable: true,
    plContinentalRegionName: 'Oriente Medio'
  },
  {
    code: 'DJ',
    plCitizenCode: 'DJI',
    coinId: 43,
    phoneCode: '253',
    name: 'Yibuti',
    enable: true,
    plContinentalRegionName: 'África Oriental - África Del Este'
  },
  {
    code: 'ZM',
    plCitizenCode: 'ZMB',
    coinId: 157,
    phoneCode: '260',
    name: 'Zambia',
    enable: true,
    plContinentalRegionName: 'África Oriental - África Del Este'
  },
  {
    code: 'ZW',
    plCitizenCode: 'ZWE',
    coinId: 158,
    phoneCode: '263',
    name: 'Zimbabue',
    enable: true,
    plContinentalRegionName: 'África Oriental - África Del Este'
  }
];
