/**
 *
 * BannersContainer
 *
 */

import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import cs from 'classnames';
import maleta from 'Assets/maleta.svg';
import { breakpoints, engineCodes } from 'Constants';

import { useDispatch, useSelector } from 'react-redux';

import { bannersRequest } from '../../actions';
import Banner from '../Banner';
//import { returnImage } from 'Utils';

const getBannersFromSection = sectionItem => {
  const toReturn = [];
  for (let i in sectionItem.sectionLines) {
    for (let x in sectionItem.sectionLines[i].banners) {
      toReturn.push(sectionItem.sectionLines[i].banners[x]);
    }
  }
  return toReturn;
};

const useBannerReducer = () =>
  useSelector(({ banners }) => ({
    sections: banners.data && banners.data.sections && banners.data.sections
  }));

const BannersContainer = ({ className, engine }) => {
  const dispatch = useDispatch();
  const { sections } = useBannerReducer();

  const requestBanners = () => {
    const bannerCode = engine ? engineCodes[engine] : engineCodes.home;
    dispatch(bannersRequest(bannerCode));
  };

  useEffect(() => {
    const bannerCode = engine ? engineCodes[engine] : engineCodes.home;
    dispatch(bannersRequest(bannerCode));
  }, [engine]);

  return (
    <div className={cs(className)} onClick={requestBanners}>
      {sections &&
        sections &&
        sections.map((sectionItem, index) => (
          <div className="container section-holder" key={sectionItem.id}>
            <TitleHolder style={{ marginTop:  '40px'  }}>
              <img src={maleta} alt="paquetes" />
              <span>{sectionItem.title}</span>
            </TitleHolder>
            <BannersHolder>
              {getBannersFromSection(sectionItem).map(banner => (
                <Banner
                  key={banner.id}
                  //image={returnImage(banner.image, banner.imageWebp)}
                  image={banner.imageWebp}
                  title={banner.title}
                  price={banner.price}
                  currency="USD"
                  description={banner.subtitle}
                  type={banner.bannerType}
                  shadowColor={banner.shadowColor}
                  openInNewWindows={banner.openInNewWindows}
                  url={banner.url}
                  icons={banner.icons}
                />
              ))}
            </BannersHolder>
          </div>
        ))}
    </div>
  );
};

BannersContainer.propTypes = {
  className: PropTypes.string.isRequired,
  engine: PropTypes.any
};

const VerMasHolder = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 40px 0 60px 0;
  gap: 10px;
`;

const Button = styled.button`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 10px;
  width: 129px;
  height: 48px;
  background: #ffffff;
  border: 1px solid #fb8400;
  border-radius: 12px;
  font-family: 'Itau Display Pro';
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 28px;
  text-align: center;
  color: #fb8400;

  &:focus {
    outline: 0;
  }
`;

export const BannersHolder = styled.div`
  margin-top: 50px;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  gap: 40px 32px;

  @media screen and (max-width: ${breakpoints.large}px) {
    grid-template-columns: 1fr 1fr;
  }

  @media screen and (max-width: ${breakpoints.medium}px) {
    flex-wrap: nowrap;
    overflow-x: scroll;
    display: flex;
    margin-right: -15px;
    margin-left: -15px;
    & > div:first-child {
      margin-left: 15px;
    }
    & > div:last-child {
      margin-right: 45px;
    }
  }
`;
export const TitleHolder = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 10px;
  gap: 10px;
  font-family: 'Itau Display Pro';
  font-style: normal;
  font-weight: 700;
  font-size: 32px;
  line-height: 40px;
  display: flex;
  align-items: center;
  color: #282828;
`;

export default styled(BannersContainer)`
  display: 'block';
  .section-holder {
  }
  .row {
    display: flex;
    flex-wrap: wrap;
    margin-right: -15px;
    margin-left: -15px;
  }
`;
