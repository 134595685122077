/**
 *
 * HotelDetailReservation
 *
 */

import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import cs from 'classnames';
import moment from 'moment';

import Text from 'Components/Text';
import Card from 'Components/Card';
import Button from 'Components/Button';
import Icon from 'Components/Icon';
import { isoDateToString } from 'Utils';
import logoItau from 'Assets/logo_naranja.svg';

const HotelDetailReservation = ({
  className,
  packageRoom,
  request,
  onClick,
  checkIn,
  packages,
  checkOut
}) => {
  const total =
    packageRoom.peopleByRoom &&
    packageRoom.peopleByRoom.reduce((t, t1) => ({
      adults: t.adults + t1.adults,
      kids: t.kids + t1.kids
    }));

  const price = packageRoom.packagePrice.finalPrice;
  const totalNight = moment(request.checkOut).diff(
    moment(request.checkIn),
    'days'
  );
  const priceByNight = packageRoom.packagePrice.finalPricePerNight;
  const { hotel } = useSelector(({ hotels, availability }) => ({
    hotel: hotels,
    availability
  }));

  const {
    selectedItauPoints,
    itauPoints: { USD }
  } = useSelector(state => state.appConfiguration);

  const costCurrencyInPoints = USD;
  const priceInPoints = Math.ceil(price / costCurrencyInPoints);

  const moneyInPoints = selectedItauPoints * costCurrencyInPoints;
  const moneyToComplete = Math.ceil((price - moneyInPoints) * 100) / 100;
  const pointsToRender =
    selectedItauPoints <= priceInPoints
      ? selectedItauPoints.toLocaleString('en').replaceAll(',', '.')
      : priceInPoints.toLocaleString('en').replaceAll(',', '.');
  const totalPeople = total ? total.adults + total.kids : 0;


  const priceTotalPeople = packageRoom.packagePrice.finalPrice;
  const totalPriceInPoints = Math.ceil(priceByNight / costCurrencyInPoints);
  const totalMoneyInPoints = selectedItauPoints * costCurrencyInPoints;
  const perNightMoneyToComplete =
    Math.ceil((priceByNight - totalMoneyInPoints) * 100) / 100;
  const totalMoneyNigth =
    Math.ceil((priceTotalPeople - totalMoneyInPoints) * 100) / 100;

  const totalPointsToRender =
    selectedItauPoints <= totalPriceInPoints
      ? selectedItauPoints.toLocaleString('en').replaceAll(',', '.')
      : totalPriceInPoints.toLocaleString('en').replaceAll(',', '.');

  const [open, setOpen] = useState(false);
  console.log({packageRoom})
  return (
    <div className={cs(className)}>
      <Card
        header="Detalle de tu Reserva"
        context="arena"
        headerCentered
        boxShadow>
        <div className="content-reserve">
          <Title weight="bold">Fecha de la reserva</Title>
          <SubTitle paragraph weight="semi-bold" context="important">
            Inicia
          </SubTitle>
          <TextDetail>{`${isoDateToString(request.checkIn)}`}</TextDetail>
          <SubTitle paragraph weight="semi-bold" context="important">
            Finaliza
          </SubTitle>
          <TextDetail>{`${isoDateToString(request.checkOut)}`}</TextDetail>
        </div>
        <hr />
        <div>
          <Title weight="bold">Registrarse</Title>
        </div>
        <div className="content-check">
          <SubTitle weight="semi-bold" context="important">
            Horario de Check In
          </SubTitle>{' '}
          <TextDetail>a partir de las {checkIn} hs</TextDetail>
        </div>
        <div className="content-check">
          <SubTitle weight="semi-bold" context="important">
            Horario de Check Out
          </SubTitle>{' '}
          <TextDetail>hasta las {checkOut} hs</TextDetail>
        </div>
        <hr />
        <div>
          <div className="price-container">
            <div>
              <PrecioPorPersonaText>
                Precio final por noche para {totalPeople}{' '}
                {totalPeople > 1 ? 'personas' : 'persona'}
              </PrecioPorPersonaText>
              <PrecioUSD>USD {packageRoom.packagePrice.finalPricePerNight}</PrecioUSD>
              <div className="content-price-and-points">
                <div className="content-points">
                  ó <TextoItau>{pointsToRender} puntos</TextoItau>{' '}
                  <img src={logoItau} alt="itau" className="logo-itau" />{' '}
                </div>
                <TextoItau>
                + USD {perNightMoneyToComplete < 0 ? 0 : perNightMoneyToComplete}
                </TextoItau>{' '}
              </div>
            </div>
            <hr />

            {totalNight > 0 && (
              <div>
                <PrecioPorPersonaText>
                  Precio por {totalNight} noches
                </PrecioPorPersonaText>
                <PrecioUSD>USD {packageRoom.packagePrice.finalPrice}</PrecioUSD>
                <div className="content-price-and-points">
                  <div className="content-points">
                    ó <TextoItau>{totalPointsToRender} puntos</TextoItau>{' '}
                    <img src={logoItau} alt="itau" className="logo-itau" />{' '}
                  </div>
                  <TextoItau>
                  + USD {moneyToComplete < 0 ? 0 : totalMoneyNigth}
                  </TextoItau>{' '}
                </div>
              </div>
            )}
          </div>

          <br />
          {packageRoom.hasFreeCancellation ? (
            <WayToPay size="small" paragraph className="pay-form">
              Acepta todas nuestras formas de pago
            </WayToPay>
          ) : (
            ''
          )}
        </div>
        <div className="text-center">
          <div>
            {open &&
              packageRoom.details &&
              packageRoom.details.map((detail, index) => (
                <Text key={index} paragraph context={detail.context}>
                  {detail.label}
                </Text>
              ))}
            {open ? (
              <div className="room-detail">
                {packageRoom.rooms &&
                  packageRoom.rooms.map((room, index) => (
                    <div key={room.allocation}>
                      <Text weight="semi-bold" paragraph size="small">
                        Habitaci&oacute;n {index + 1}
                      </Text>
                      <Text context="important" size="small">
                        <Icon name="mv-user" />
                        <Text context="default" size="small">
                          Hu&eacute;spedes x{room.adultsCount + room.kidsCount}
                        </Text>
                      </Text>
                    </div>
                  ))}
              </div>
            ) : (
              ''
            )}
          </div>
          <hr style={{ margin: '0' }} />
          <ContentPolicy>
            <CancellationPolicy
              className="policy-header"
              paragraph
              weight="semi-bold"
              size="large">
              Pol&iacute;ticas de cancelaci&oacute;n
            </CancellationPolicy>
            {packageRoom.policyDetails &&
              packageRoom.policyDetails.map((policy, index) => (
                <NonRefundable key={index} paragraph context={policy.context}>
                  {policy.label}
                </NonRefundable>
              ))}
          </ContentPolicy>
          <Button
            className="button-reservation"
            onClick={() => {
              onClick(packageRoom);
            }}>
            Reservar
          </Button>
          {packageRoom.rateCommentDetails && (
            <>
              <Text
                className="policy-header"
                paragraph
                weight="semi-bold"
                size="large">
                Observaciones Importantes
              </Text>
              <hr style={{ margin: '0' }} />
              {packageRoom.rateCommentDetails.map((comment, index) => (
                <Text key={index} paragraph context={comment.context}>
                  {comment.label}
                </Text>
              ))}
            </>
          )}
        </div>
      </Card>
    </div>
  );
};

HotelDetailReservation.propTypes = {
  className: PropTypes.string.isRequired,
  onClick: PropTypes.func,
  packageRoom: PropTypes.object.isRequired,
  request: PropTypes.object.isRequired,
  checkIn: PropTypes.string,
  checkOut: PropTypes.string
};

export default styled(HotelDetailReservation)`
  height: '100%';
  width: '269px';
  .header-content {
    color: #fb8400;
    font-family: Itau Display Pro;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 24px;
  }
  .room-detail {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    font-size: 10px;
    justify-content: space-between;
  }
  .button-reservation {
    width: 217px;
    height: 44px;
    padding: 10px 30px 10px 30px;
    border-radius: 12px;
    gap: 10px;
  }
  .content-reserve {
    display: flex;
    flex-direction: column;
  }
  .logo-itau {
    width: 24px;
    height: 24px;
    gap: 10px;
    flex-shrink: 0;
  }
  .content-check {
    display: flex;
    flex-direction: column;
  }
  .price-container {
    border: 1px solid #28282840;
    border-radius: 10px;
  }
  .content-price-and-points {
    text-align: center;
  }
  .pay-form {
    color: #fb8400;
    font-family: Itau Display Pro;
    font-size: 15px;
    margin-bottom: 10px;
  }

  .policy-header {
    margin-top: 10px;
    font-size: 18px;
  }

  .hdr-detail {
    margin: 20px;

    p {
      text-align: center;
    }

    .icon {
      margin-left: 5px;
      margin-top: 2px;
      cursor: pointer;
    }
  }
`;

const Title = styled.span`
  color: #282828;
  font-family: Itau Display Pro;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 22px; /* 157.143% */
`;

const SubTitle = styled.span`
  color: #fb8400;
  font-family: Itau Display Pro;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 22px;
`;

const TextDetail = styled.span`
  color: rgba(40, 40, 40, 0.5);
  font-family: Itau Display Pro;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
`;

const PrecioPorPersonaText = styled.div`
  font-family: Itau Display Pro;
  color: #282828;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
  text-align: center;
  margin-top: 10px;
  width: 217px;
  opacity: 0.5;
`;

const PrecioUSD = styled.div`
  color: #fb8400;
  text-align: center;
  font-family: Itau Display Pro;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 32px;
`;

const TextoItau = styled.span`
  color: #039;
  font-family: Itau Display Pro;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 30px;
`;
const ContentPolicy = styled.span`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
  margin: 10px 0;
`;
const CancellationPolicy = styled.span`
  color: #282828;
  font-family: Itau Display Pro;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 22px;
`;

const NonRefundable = styled.span`
  color: rgba(40, 40, 40, 0.5);
  font-family: Itau Display Pro;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
`;

const WayToPay = styled.span`
  color: #fb8400;
`;
