import React from 'react';
import styled from 'styled-components';

import { Steps } from '../../types';

const StepperLine = styled.div`
  position: absolute;
  height: 3px;
  width: 100%;
  background-color: rgba(40, 40, 40, 0.1);
`;

const Holder = styled.div`
  width: 340px;
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const Step = styled.div<{ active: boolean }>`
  box-sizing: border-box;
  width: 60px;
  height: 60px;
  border: 3px solid rgba(208, 160, 160, 0.25);
  border-radius: 50%;
  font-family: 'Itau Display Pro';
  font-style: normal;
  font-weight: 700;
  font-size: 40px;
  line-height: 48px;
  color: ${({ active }) => (active ? 'white' : '#282828')};
  display: flex;
  align-items: center;
  justify-content: center;
  border-color: ${({ active }) =>
    active ? '#FB8400' : 'rgba(208, 160, 160, 0.25)'};
  background-color: ${({ active }) => (active ? '#FB8400' : 'white')};
  z-index: 1;
  span {
    opacity: ${({ active }) => (active ? '1' : '0.25')};
  }
`;

const StepperHolder = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin: 52px 0;
`;

interface Props {
  activeStep: Steps;
}

export const Stepper = ({ activeStep }: Props) => (
  <StepperHolder>
    <Holder>
      <StepperLine />
      <Step active={activeStep === Steps.paxData}>
        <span>1</span>
      </Step>
      <Step active={activeStep === Steps.billingData}>
        <span>2</span>
      </Step>
      <Step active={activeStep === Steps.payment}>
        <span>3</span>
      </Step>
    </Holder>
  </StepperHolder>
);
