export default Object.freeze({
  home: '/',
  flight: '/vuelos-baratos-internacionales',
  flightMultiDestination: '/vuelos-multidestino',
  flightSearch: '/:destination/vuelos-baratos-internacionales',
  flightMultiDestinationSearch: '/vuelos-multidestino',
  hotel: '/hoteles-baratos-y-ofertas',
  hotelSearch: '/:destination/hoteles-baratos-y-ofertas',
  hotelDetail: '/:destination/:nameHotel',
  package: '/promociones-en-paquetes-vacacionales',
  packageThankyou: '/promociones-en-paquetes-vacacionales/thankyou',
  packageItem: '/promociones-en-paquetes-vacacionales/paquete/:keyword/:id',
  destination: '/:destination',
  destinationFlightSearch: '/:destination/vuelos-baratos-internacionales',
  checkout: '/checkout',
  checkoutCorpo: '/checkout-corpo',
  checkoutSuccessPayment: '/checkout/success-payment',
  checkoutCancelPayment: '/checkout/cancel-payment',
  checkoutAtcIniPayment: '/checkout/bolivia/init-payment',
  checkoutAtcCheckPayment: '/checkout/bolivia/check-payment',
  thankYou: '/checkout/:ticket/thank-you',
  thankYouCorpo: '/checkout-corpo/:ticket/thank-you',
  whoWeAre: '/sobre-nosotros',
  processPayment: '/:engine/check-payment/:processId',
  blog: '/blog/:catType',
  blogCategory: '/blog/:catType/:category',
  blogArticle: '/:slug/blog',
  login: '/agent/login',
  logout: '/logout',
  resetPassword: '/agent/reset-password',
  quotations: '/agent/cotizaciones',
  quotationsDetails: '/agent/cotizaciones/:id',
  notFound: '/404',
  errorFound: '/error',
  airCheckout: '/airCheckout',
  hotelCheckout: '/hotelCheckout',
  airCheckoutSuccess: '/check-payment',
  airCheckoutCancel: '/cancel-payment',
  travelInsurance: '/asistencias',
  corporate: '/corporativo',
  tiotomPromo: '/tiotom-all-inclusive-punta-del-este',
  callbackFromItau: '/callback-itau',
  paymentLink: '/checkout/client/transaction/:orderId'
});
