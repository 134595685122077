export const ErrorMessage = {
    "VN_101": "La transacción no pudo ser procesada. Intentá nuevamente.",
    "VN_113": "La transacción no pudo ser procesada. Intentá nuevamente o contactá con tu banco.",
    "VN_114": "La transacción no pudo ser procesada. Intentá nuevamente.",
    "VN_115": "La operación ha sido rechazada.",
    "VN_116": "La transacción no pudo ser procesada. Verificá los datos de tu tarjeta e intentá nuevamente.",
    "VN_117": "La transacción no pudo ser procesada. Intentá nuevamente.",
    "VN_118": "La operación ha sido rechazada.",
    "VN_119": "La transacción no pudo ser procesada. Intentá nuevamente.",
    "VN_120": "La transacción no pudo ser procesada. Intentá nuevamente.",
    "VN_121": "La transacción no pudo ser procesada. Intentá nuevamente.",
    "VN_122": "La transacción no pudo ser procesada. Verificá los datos de tu tarjeta e intentá nuevamente.",
    "VN_123": "La transacción no pudo ser procesada. Intentá nuevamente.",
    "VN_124": "La transacción ha sido cancelada correctamente. Esperamos poder volar contigo pronto.",
    "VN_125": "La transacción no pudo ser procesada. Intentá nuevamente.",
    "VN_126": "La transacción no pudo ser procesada. Intentá nuevamente y verificá tu conexión.",
    "VN_127": "Tú tarjeta se encuentra vencida. Ingresá los datos de una tarjeta válida o contactá con tu banco.",
    "VN_128": "La operación ha sido rechazada.",
    "VN_129": "La operación ha sido rechazada.",
    "VN_130": "La operación ha sido rechazada.",
    "VN_131": "La transacción no pudo ser procesada. Verificá los datos de tu tarjeta y contactá con el banco.",
    "VN_132": "La operación ha sido rechazada. Se han registrado más de 3 errores en tu clave.",
    "VN_133": "La transacción no pudo ser procesada. Verificá los datos de tu tarjeta e intentá nuevamente.",
    "VN_134": "La transacción no pudo ser procesada. Intentá nuevamente.",
    "VN_135": "La operación ha sido rechazada.",
    "VN_136": "La transacción no pudo ser procesada. Intentá nuevamente.",
    "VN_137": "La operación ha sido rechazada.",
    "VN_138": "La transacción no pudo ser procesada. Verificá los datos de tu tarjeta e intentá nuevamente.",
    "VN_139": "La transacción no pudo ser procesada. Intentá nuevamente.",
    "VN_140": "La transacción no pudo ser procesada. Intentá nuevamente.",
    "VN_141": "La transacción no pudo ser procesada. Intentá nuevamente.",
    "VN_142": "La transacción no pudo ser procesada. Verificá los datos de tu tarjeta e intentá nuevamente.",
    "VN_143": "La transacción no pudo ser procesada. Intentá nuevamente.",
    "VN_144": "La transacción no pudo ser procesada. Intentá nuevamente.",
    "VN_145": "La transacción no pudo ser procesada. Intentá nuevamente.",
    "VN_146": "La operación ha sido rechazada.",
    "VN_147": "La operación ha sido rechazada.",
    "VN_148": "La transacción no pudo ser procesada. Intentá nuevamente con otra tarjeta.",
    "VN_149": "La operación ha sido rechazada.",
    "VN_150": "La operación ha sido rechazada.",
    "VN_151": "La transacción no pudo ser procesada. Intentá nuevamente con otra tarjeta.",
    "VN_152": "La operación ha sido rechazada.",
    "VN_153": "La transacción no pudo ser procesada. Intentá nuevamente con otra tarjeta.",
    "VN_154": "La transacción ha sido cancelada correctamente. Esperamos poder volar contigo pronto.",
    "VN_155": "La transacción no pudo ser procesada. Verificá los datos de tu tarjeta e intentá nuevamente.",
    "VN_156": "La transacción no pudo ser procesada. Verificá los datos de tu tarjeta e intentá nuevamente.",
    "VN_157": "La transacción no pudo ser procesada. Intentá nuevamente.",
    "VN_158": "La transacción está en verificación. Estaremos contactando contigo en la brevedad.",
    "VN_102": "La transacción no pudo ser procesada. No contás con puntos suficientes para esta transacción. Seleccioná una cantidad menor y recordá que podes pagar con puntos y tarjetas.",
    "VN_103": "La transacción no pudo ser procesada. Intentá nuevamente.",
    "VN_104": "La transacción no pudo ser procesada. Debés seleccionar al menos 500 puntos para canjear en un programa.",
    "VN_105": "La transacción no pudo ser procesada. Intentá nuevamente.",
    "VN_106": "La transacción no pudo ser procesada. Intentá nuevamente.",
    "VN_107": "La transacción no pudo ser procesada. Intentá nuevamente.",
    "VN_108": "La transacción no pudo ser procesada. Intentá nuevamente.",
    "VN_109": "La transacción no pudo ser procesada. Intentá nuevamente.",
    "VN_110": "La transacción no pudo ser procesada. Intentá nuevamente.",
    "VN_111": "La transacción no pudo ser procesada. Intentá nuevamente.",
    "VN_112": "La transacción no pudo ser procesada. Intentá nuevamente."
}
