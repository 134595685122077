/**
 *
 * Calendar
 *
 */

import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import cs from 'classnames';
import DatePicker, { registerLocale, setDefaultLocale } from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import es from 'date-fns/locale/es';
import Button from '../Button';

import Icon from '../Icon';

import * as COLORS from 'Constants/colors';
import MobileNavBar from '../MobileNavBar';
import { minDateCalendar } from '../../Features/flights/constants';

registerLocale('es', es);
setDefaultLocale('es');

const MobileCalendar = ({
  className,
  onSelected = () => {},
  oneWay,
  initialStartDate,
  initialEndDate,
  maxDate,
  isFlight = false
}) => {
  const steps = {
    ida: 'IDA',
    vuelta: 'VUELTA'
  };

  const [state, setState] = useState({
    startDate: initialStartDate,
    endDate: initialEndDate,
    canApply: false,
    step: steps.ida,
    lastDateSelected: new Date()
  });

  const handleChange = date => {
    if (date.getTime() < now.getTime) return;
    switch (state.step) {
      case steps.ida:
        if (oneWay) {
          setState(prevState => ({
            ...prevState,
            canApply: true,
            startDate: date,
            lastDateSelected: date
          }));
        } else {
          setState(prevState => ({
            ...prevState,
            canApply: false,
            startDate: date,
            endDate: date,
            lastDateSelected: date,
            step: steps.vuelta
          }));
        }
        break;

      case steps.vuelta:
        setState(prevState => ({
          ...prevState,
          canApply: true,
          endDate: date,
          lastDateSelected: date,
          step: steps.ida
        }));
        break;
      default:
        break;
    }
  };

  const handleApply = () => {
    onSelected(state.startDate, state.endDate);
  };

  const handleClose = () => {
    onSelected();
  };

  const setDayClassName = date => {
    if (date < now) return 'day-disabled';
    else if (
      date.getTime() === state.startDate.getTime() &&
      state.countSelect < 2
    )
      return 'day-pending-select';
    else if (date.getTime() === state.startDate.getTime())
      return 'day-selected-start';
    else if (date.getTime() === state.endDate.getTime())
      return 'day-selected-end';
    else if (startDateAux < date && state.endDate > date)
      return 'middle-days-selected';
    else if (date.getDay() === 0 || date.getDay() === 6) return 'weekend';
    else return '';
  };

  const now = new Date();
  now.setDate(isFlight ? now.getDate() + 2 : now.getDate() - 1);
  now.setHours(23, 59, 59);

  const startDateAux = new Date(state.startDate);
  startDateAux.setDate(startDateAux.getDate() - 1);
  startDateAux.setHours(23, 59, 59);

  return (
    <div className={cs(className)}>
      <div className="top-bar">
        <div className="top-bar-label">Selecciona tus fechas de viaje</div>
        <span className="top-bar-close" onClick={() => handleClose()}>
          <Icon name="mv-cross" />
        </span>
      </div>
      <div className={cs('header-action')}>{state.step}</div>
      <div className="content-wrapper">
        <DatePicker
          monthsShown={11}
          onChange={handleChange}
          openToDate={state.lastDateSelected}
          minDate={
            state.step === steps.vuelta
              ? state.lastDateSelected
              : isFlight
              ? minDateCalendar
              : new Date()
          }
          inline
          maxDate={maxDate}
          dayClassName={setDayClassName}></DatePicker>
      </div>
      <MobileNavBar className="bottom-bar">
        <Button
          context="purple"
          weight="semi-bold"
          onClick={handleApply}
          className="apply-button">
          APLICAR
        </Button>
      </MobileNavBar>
    </div>
  );
};

MobileCalendar.propTypes = {
  className: PropTypes.string.isRequired,
  oneWay: PropTypes.bool,
  onSelected: PropTypes.func,
  initialEndDate: PropTypes.instanceOf(Date),
  initialStartDate: PropTypes.instanceOf(Date),
  maxDate: PropTypes.instanceOf(Date),
  isFlight: PropTypes.bool
};

export default styled(MobileCalendar)`
  width: 100%;
  height: 100%;
  z-index: ${({ theme }) => theme.zIndex.modalCalendar};
  position: fixed;
  top: 0;
  left: 0;

  & > div {
    width: 100%;
  }

  .top-bar {
    display: flex;
    width: 100%;
    height: 62px;
    background-color: #fff;
    text-align: center;
  }

  .content-wrapper {
    display: flex;
    position: relative;
    flex-direction: column;
    height: 100%;
    overflow-x: hidden;
    overflow-y: scroll;
  }

  .top-bar-close {
    display: flex;
    position: absolute;
    width: 10%;
    height: 62px;
    right: 0;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    cursor: pointer;
    color: ${COLORS.lightGrey};
    float: right;
  }

  .top-bar-label {
    display: flex;
    width: 100%;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    font-family: Montserrat, serif;
    font-size: 18px;
    font-weight: normal;
    font-style: italic;
    font-stretch: normal;
    letter-spacing: 0.18px;
    color: ${COLORS.middleGrey};
  }

  .header-action {
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    height: 42px;
    background-color: ${COLORS.violeta};
    font-family: Montserrat, serif;
    font-weight: 600;
    font-size: 18px;
    color: ${COLORS.white};
  }

  .calendar-footer {
    background: ${COLORS.white};
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    padding-top: 15px;
    width: 100%;
    height: 71px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .ReactModal__Content {
    padding: 1px;
  }

  .react-datepicker {
    display: flex;
    flex-direction: column;
    border: 0;
    border-radius: 0;
    height: 100%;
  }

  .react-datepicker__header {
    background-color: ${COLORS.white};
    border: 0;
    margin: 18px 0 12px 0;
  }

  .react-datepicker__current-month,
  .react-datepicker-time__header,
  .react-datepicker-year-header,
  .react-datepicker__current-month--hasYearDropdown {
    display: flex;
    width: 50%;
    justify-content: center;
    font-family: Montserrat, serif;
    font-size: 18px;
    font-weight: 500;
    font-style: normal;
    font-stretch: normal;
    line-height: 0.86;
    letter-spacing: 0.18px;
    color: ${COLORS.middleBlue};
  }

  .react-datepicker__year-dropdown-container--scroll {
    display: flex;
    justify-content: center;
    width: 100%;
    font-size: 16px;
    font-weight: normal;
    color: ${COLORS.middleGrey};
  }

  .react-datepicker__year-read-view span {
    font-size: 16px;
    font-weight: normal;
    color: ${COLORS.middleGrey};
  }

  .react-datepicker__year-read-view--down-arrow {
    left: 10px;
  }

  .react-datepicker__year-dropdown .react-datepicker__year-option {
    background-color: ${COLORS.white};
  }

  .react-datepicker__year-dropdown .react-datepicker__year-option:hover {
    background-color: ${COLORS.violeta};
    opacity: 0.8;
    color: ${COLORS.white};
  }

  .react-datepicker,
  .react-datepicker__month-container {
    width: 100%;
  }

  .react-datepicker__month-container {
    padding-bottom: 10px;
    border-bottom: 1px solid ${COLORS.lightGrey};
  }

  .react-datepicker__month-container:last-child {
    border-bottom: none;
  }

  .react-datepicker__month {
    margin: 0;
  }

  .react-datepicker__navigation--previous,
  .react-datepicker__navigation--next {
    display: none;
    width: 50px;
    top: 35px;
  }

  .react-datepicker__navigation--previous {
    left: calc(50% - 150px);
  }

  .react-datepicker__navigation--next {
    left: calc(50% + 100px);
  }

  .react-datepicker__week,
  .react-datepicker__day-names {
    display: flex;
    flex-direction: row;
    justify-content: center;
    font-family: Montserrat, serif;
  }

  .react-datepicker__day {
    display: flex;
    height: 40px;
    width: 50px;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: 16px;
    text-align: center;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    color: ${COLORS.darkGrey};
    margin: 0 !important;
    letter-spacing: 0.16px;
  }

  .day-disabled {
    opacity: 0.5;
    cursor: default !important;
  }

  .day-pending-select {
    font-family: Montserrat, serif;
    color: ${COLORS.white} !important;
    font-weight: 600;
    border-radius: 5px;
    background-color: ${COLORS.violeta};
  }

  .day-pending-select:hover {
    border-radius: 5px !important;
    background-color: ${COLORS.violeta};
    opacity: 0.8;
  }

  .day-selected-start {
    font-family: Montserrat, serif;
    color: ${COLORS.white} !important;
    font-weight: 600;
    border-radius: 5px 0 0 5px;
    background-color: ${COLORS.violeta};
  }

  .day-selected-start:hover {
    border-radius: 5px 0 0 5px !important;
    background-color: ${COLORS.violeta};
  }

  .day-selected-end {
    font-family: Montserrat, serif;
    color: ${COLORS.white} !important;
    font-weight: 600;
    border-radius: 0 5px 5px 0;
    background-color: ${COLORS.violeta};
  }

  .day-selected-end:hover {
    border-radius: 0 5px 5px 0 !important;
    background-color: ${COLORS.violeta} !important;
  }

  .middle-days-selected {
    color: ${COLORS.white} !important;
    background-color: ${COLORS.violeta};
    opacity: 0.8;
  }

  .middle-days-selected:hover {
    color: ${COLORS.white} !important;
    background-color: ${COLORS.violeta} !important;
    opacity: 1;
    border-radius: 0 !important;
  }

  .weekend {
    font-weight: 600;
    color: ${COLORS.middle2Grey};
  }

  .react-datepicker__day-name {
    display: flex;
    height: 40px;
    width: 50px;
    align-items: center;
    justify-content: center;
    text-align: center;
    font-family: Montserrat, serif;
    font-weight: 600;
    font-style: normal;
    font-stretch: normal;
    font-size: 15px;
    color: ${COLORS.middle2Grey};
    text-transform: uppercase;
    margin: 0 !important;
  }

  .react-datepicker__current-month {
    text-transform: capitalize;
    white-space: nowrap;
    margin: auto;
    height: 20px;
    font-family: Montserrat, serif;
    font-size: 18px;
    font-weight: 600;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.11;
    letter-spacing: 0.18px;
    color: ${COLORS.middleBlue};
  }

  .react-datepicker__day--outside-month {
    visibility: hidden !important;
  }

  .bottom-bar {
    display: flex;
    justify-content: center;
    align-items: center;
  }
`;
