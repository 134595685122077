import styled, { css } from 'styled-components';
import { SeasonProps } from './types';

export const Season = styled.div<SeasonProps>`
  width: 220px;
  height: 30px;
  border-radius: 14px;
  display: inline-block;
  font-size: 8px;
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
  text-align: center;
  color: ${({ theme }) => theme.colors.white};
  box-shadow: ${({ theme }) => theme.shadows.two};
  padding-top: ${({ theme }) => theme.spacing.one};
  
   ${({ color, theme }) =>
     color === 'default' &&
     css`
       background-color: ${theme.palette.primary.violet};
     `}
  
  ${({ color, theme }) =>
    color === 'goldenRod' &&
    css`
      background-color: ${theme.colors.goldenRod};
    `}
  
  ${({ color, theme }) =>
    color === 'cornFlower' &&
    css`
      background-color: ${theme.colors.cornFlower};
    `}


  ${({ color, theme }) =>
    color === 'shipCove' &&
    css`
      background-color: ${theme.colors.shipCove};
    `}
  
  ${({ color, theme }) =>
    color === 'atomicTangerine' &&
    css`
      background-color: ${theme.colors.atomicTangerine};
    `}
`;
