import React from 'react';
import styled from 'styled-components';
import { useSelector } from 'react-redux';
import { useFormContext } from 'react-hook-form';
import { Breakpoint } from 'react-socks';

import countries from 'Components/InvoiceForm/countries';
import { paxDocs } from 'Constants';

import { CommonHolder as PaxHolder, FormRow } from '../Common';
import { FormInput } from '../FormInput';
import { FormSelect } from '../FormSelect';

const PaxTitle = styled.div`
  font-family: 'Itau Display Pro';
  font-style: normal;
  font-weight: 400;
  font-size: 22px;
  line-height: 30px;
  color: #282828;
  width: 100%;
`;

interface Props {
  index: number;
}

export const PaxFields = ({ index }: Props) => {
  const { genders } = useSelector(
    // @ts-ignore
    ({ appConfiguration: { sharedConfig } }) => sharedConfig
  );

  const {
    register,
    formState: { errors },
    getValues
  } = useFormContext();

  let title = '';
  const travelerCode = getValues(`passengers.${index}.travelerCode`);

  if (travelerCode === 'ADT') {
    title = `Pasajero ${index + 1}: Adulto`;
  } else if (travelerCode === 'CNN') {
    title = `Pasajero ${index + 1}: Niño`;
  } else {
    title = `Pasajero ${index + 1}: Bebé`;
  }

  return (
    <PaxHolder>
      <input {...register(`passengers.${index}.travelerCode`)} type="hidden" />
      <PaxTitle>{title}</PaxTitle>
      <Breakpoint style={{ width: '100%' }} small down>
        <FormRow>
          <FormInput
            name={`passengers.${index}.firstName`}
            label="Nombre"
            placeholder="Como figura en su documento de identidad"
            registerOptions={{
              required: true
            }}
          />
        </FormRow>
        <FormRow>
          <FormInput
            name={`passengers.${index}.lastName`}
            label="Apellido"
            placeholder="Como figura en su documento de identidad"
            registerOptions={{
              required: true
            }}
          />
        </FormRow>
        <FormRow>
          <FormInput
            name={`passengers.${index}.dob`}
            label="Fecha de nacimiento"
            type="date"
            registerOptions={{
              required: true
            }}
          />
        </FormRow>
        <FormRow>
          <FormSelect
            name={`passengers.${index}.gender`}
            label="Género"
            options={genders || []}
            registerOptions={{
              required: true
            }}
          />
        </FormRow>
        <FormRow>
          <FormSelect
            name={`passengers.${index}.docType`}
            label="Tipo de documento"
            options={paxDocs || []}
            registerOptions={{
              required: true
            }}
          />
        </FormRow>
        <FormRow>
          <FormInput
            name={`passengers.${index}.document`}
            label="Número de documento"
            registerOptions={{
              required: true
            }}
          />
        </FormRow>
        <FormRow>
          <FormSelect
            name={`passengers.${index}.nationality`}
            label="Nacionalidad"
            options={countries.map(country => ({
              id: country.plCitizenCode,
              name: country.name
            }))}
            registerOptions={{
              required: true
            }}
          />
        </FormRow>
      </Breakpoint>

      <Breakpoint medium up>
        <FormRow>
          <FormInput
            name={`passengers.${index}.firstName`}
            label="Nombre"
            placeholder="Como figura en su documento de identidad"
            registerOptions={{
              required: true
            }}
          />
          <FormInput
            name={`passengers.${index}.lastName`}
            label="Apellido"
            placeholder="Como figura en su documento de identidad"
            registerOptions={{
              required: true
            }}
          />
        </FormRow>
        <FormRow>
          <FormInput
            name={`passengers.${index}.dob`}
            label="Fecha de nacimiento"
            type="date"
            registerOptions={{
              required: true
            }}
          />
          <FormSelect
            name={`passengers.${index}.gender`}
            label="Género"
            options={genders || []}
            registerOptions={{
              required: true
            }}
          />
        </FormRow>

        <FormRow>
          <FormSelect
            name={`passengers.${index}.docType`}
            label="Tipo de documento"
            options={paxDocs || []}
            registerOptions={{
              required: true
            }}
          />
          <FormInput
            name={`passengers.${index}.document`}
            label="Número de documento"
            registerOptions={{
              required: true
            }}
          />
          <FormSelect
            name={`passengers.${index}.nationality`}
            label="Nacionalidad"
            options={countries.map(country => ({
              id: country.plCitizenCode,
              name: country.name
            }))}
            registerOptions={{
              required: true
            }}
          />
        </FormRow>
      </Breakpoint>
    </PaxHolder>
  );
};
