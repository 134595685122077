import { routes } from 'Constants';
import { MonthsEnum, MonthsList } from './types';
import {
  darkOrange,
  important,
  sunShade,
  yellow
} from '../../Constants/colors';

export const filterByRegion = (array, region) =>
  array.filter(item => item.filterDataToApply.regionIds.includes(region.id));

export const filterByCity = (array, city) =>
  array.filter(item => item.filterDataToApply.cityCodes.includes(city.code));

export const filterByHow = (array, how) =>
  array.filter(item => item.filterDataToApply.tripStyleIds.includes(how.id));

export const filterByWhen = (array, when) => {
  const { type, option } = when;
  if (!option) return [];
  switch (type) {
    case 'MONTH':
      return array.filter(item =>
        item.filterDataToApply.months.includes(option.id)
      );
    case 'SEASON':
      return array.filter(item =>
        item.filterDataToApply.tripSeasonIds.includes(option.id)
      );
    default:
      break;
  }
};

export const orderByTitle = array =>
  array.sort((a, b) => (a.title > b.title ? 1 : -1));

export const orderByDuration = array =>
  array.sort((b, a) => (a.durationNumber > b.durationNumber ? 1 : -1));

export const buildPackageUrl = (keyword: string, id: number) =>
  routes.packageItem.replace(':keyword', keyword).replace(':id', String(id));

export const getBasePriceString = (basePrice: number): [string, string] => {
  switch (basePrice) {
    case 1:
      return ['Por persona', 'en base single'];
    case 2:
      return ['Por persona', 'en base doble'];
    case 3:
      return ['Por persona', 'en base triple'];
    case 4:
      return ['Por persona', 'en base cuádruple'];
    case 5:
      return ['Por persona', 'en base quíntuple'];
    case 6:
      return ['Por persona', 'en base séxtuple'];
    case 7:
      return ['Por persona', 'en Family Plan'];
  }
};

export const getPricesColorsByPercentile = (itemPrice, minPrice, maxPrice) => {
  const middlePrice = (minPrice + maxPrice) / 2;
  const betweenMinMiddle = (minPrice + middlePrice) / 2;
  const betweenMiddleMax = (middlePrice + maxPrice) / 2;
  if (itemPrice >= minPrice && itemPrice <= betweenMinMiddle) return yellow;
  else if (itemPrice > betweenMinMiddle && itemPrice <= middlePrice)
    return darkOrange;
  else if (itemPrice > middlePrice && itemPrice <= betweenMiddleMax)
    return sunShade;
  else return important;
};

export const getMonthName = (month: MonthsEnum): string => {
  const maybeElement = MonthsList.find(element => element.index === month);
  return maybeElement ? maybeElement.name : '';
};
