import React from 'react';
import useBreakpoints from 'Utils/hooks/useBreakpoints';
import { ItauPuntosDesktop as Desktop } from './ItauPuntosDesktop';
import { ItauPuntosMobile as Mobile } from './ItauPuntosMobile';

export const ItauPuntosLogin = ({ forceMobile = false }) => {
  const isMediumDown = useBreakpoints().mediumDown;
  return isMediumDown || forceMobile ? <Mobile /> : <Desktop />;
  return;
};
