/**
 *
 * Divider
 *
 */

import React from 'react';
import styled from 'styled-components';

import { DividerProps } from './types';
import Heading from 'Components/Heading';
import useBreakpoints from 'Utils/hooks/useBreakpoints';

const BorderBox = styled.div`
  display: inline-block;
  width:100%;
  // border-bottom: 4px solid ${({ theme }) => theme.colors.orange};
  padding-bottom: 4px;
  margin:0px 20px;
`;

const Divider = ({ titleText, subtitleText,img }: DividerProps) => (
  <BorderBox>
   
    {titleText && (
      //  color="#282828"
      <Heading type="h2" fontWeight="semibold">
        {img && <img src={img}/>} {titleText}
      </Heading>
    )}
    {titleText && subtitleText && (
      <Heading type="h3" color="white" fontWeight="light">
        {subtitleText}
      </Heading>
    )}
  </BorderBox>
);

export default Divider;
