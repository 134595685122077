/* eslint-disable react/no-unknown-property */
/**
 *
 * HotelSelectFare
 *
 */

import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import cs from 'classnames';

import GloboToggle from 'Components/GloboToggle';
import logoItau from 'Assets/logo_naranja.svg';

import { lightGrey, white } from 'Constants/colors';
import { getFormatPrice } from 'Utils';
import { useSelector } from 'react-redux';

const HotelSelectFare = ({
  className,
  active,
  details,
  price,
  localPrice,
  priceType,
  currency,
  localCurrency,
  onClick,
  isCheckout = false
}) => {
  const {
    selectedItauPoints,
    itauPoints: { USD }
  } = useSelector(state => state.appConfiguration);

  const costCurrencyInPoints = USD;
  const priceInPoints = Math.ceil(price / costCurrencyInPoints);
  const moneyInPoints = selectedItauPoints * costCurrencyInPoints;
  const moneyToComplete = Math.ceil(price - moneyInPoints);
  const pointsToRender =
    selectedItauPoints <= priceInPoints
      ? selectedItauPoints.toLocaleString('en').replaceAll(',', '.')
      : priceInPoints.toLocaleString('en').replaceAll(',', '.');
  return (
    <div className={cs(className)} onClick={onClick}>
      <div className="fare-details">
        {details &&
          details.map((item, index) => (
            <div key={index} className="content-general-information">
              <span className={item.context}>{item.label}</span>
            </div>
          ))}

        <div className="price-holder">
          <div className="price-type">
            <ContentPrice context="important">
              PRECIO FINAL{' '}
              {priceType === 'PER_NIGHT' ? 'POR NOCHE' : 'POR ESTADIA'}
            </ContentPrice>
          </div>

          <div className="price">
            <ContentCurrency size="xlarge" weight="bold" context="important">
              {getFormatPrice(price, currency)}
            </ContentCurrency>
            <PromoPriceTag>
              <span className="option-itau">ó</span>
              <span>{pointsToRender} pts</span>
              <img src={logoItau} alt="itau" />
              <span>+ USD {moneyToComplete < 0 ? 0 : moneyToComplete}</span>
            </PromoPriceTag>
          </div>
        </div>
      </div>
      <p>{active}</p>
      {!isCheckout && (
        <div className="toggle-holder">
          <GloboToggle active={active} />
          <p>Seleccionar</p>
        </div>
      )}
    </div>
  );
};

HotelSelectFare.propTypes = {
  className: PropTypes.string.isRequired,
  active: PropTypes.bool,
  details: PropTypes.array,
  currency: PropTypes.string,
  localCurrency: PropTypes.string,
  onClick: PropTypes.func,
  price: PropTypes.number,
  localPrice: PropTypes.number,
  priceType: PropTypes.string,
  isCheckout: PropTypes.bool
};

export default styled(HotelSelectFare)`
  display: flex;
  align-items: center;
  width: 360px;
  height: 234px;
  padding: 10px 0 10px 0;
  gap: 6px;
  border-radius: 14px;
  transition: 0.6s;

  .general-information {
    font-family: Itau Display Pro;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0em;
    text-align: left;
  }
  .general-information span {
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
  }
  .warning {
    color: red;
  }
  .important {
    color: #fb8400;
  }
  .default {
    color: #282828;
  }

  &:hover {
    background-color: ${white};
  }

  &.active {
    background-color: ${white};
  }

  .toggle-holder {
    width: 117px;
    height: 214px;
    padding: 0 20px 0 50px;
    border: 0 0 0 1px;
    gap: 8px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-left: 1px solid ${lightGrey};
    p {
      font-family: Itau Display Pro;
      width: 77px;
      height: 24px;
      font-size: 16px;
      font-weight: 400;
      line-height: 24px;
      letter-spacing: 0;
      text-align: left;
      color: #28282880;
    }
  }
  .fare-details {
    .price-holder {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      width: 360px;
      height: 120px;
      padding: 10px;
      margin-top: 10px;
      border: 1px solid #2828281a;
      border-radius: 10px;
      gap: 8px;

      .price-type {
        padding-top: 8px;
        display: flex;
        flex-direction: column;
        width: 140px;
      }

      .price {
        display: flex;
        flex-direction: column;
        align-items: center;
      }
    }
  }
`;

const ContentPrice = styled.span`
  font-family: Itau Display Pro;
  width: 340px;
  height: 24px;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0;
  text-align: center;
`;

const ContentCurrency = styled.span`
  font-family: Itau Display Pro;
  width: 100px;
  height: 30px;
  font-size: 22px;
  font-weight: 800;
  line-height: 30px;
  letter-spacing: 0;
  text-align: center;
  color: #fb8400;
`;

export const PromoPriceTag = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 0px;
  gap: 10px;
  font-family: 'Itau Display Pro';
  font-style: normal;
  font-weight: 400;
  font-size: 22px;
  line-height: 30px;

  .option-itau {
    color: #282828;
    font-family: Itau Display Pro;
    font-size: 14px;
    font-style: normal;
    font-weight: 300;
    line-height: 22px;
  }
  span {
    color: #039;
  }

  img {
    width: 24px;
  }
`;
