/**
 *
 * Footer
 *
 */

import React from 'react';
import useBreakpoints from 'Utils/hooks/useBreakpoints';

import Desktop from './Desktop';
import Mobile from './Mobile';

const Footer = () => {
  const isMediumDown = useBreakpoints().mediumDown;
  return isMediumDown ? <Mobile /> : <Desktop />;
};

export default Footer;
