const apiActionTypes = [
  'PACKAGES',
  'PACKAGES_GET_INFO',
  'PACKAGES_GET_UPSELLING_INFO',
  'PACKAGES_SUBMIT_FORM'
];
const actionTypes = {};
apiActionTypes.forEach(item => {
  actionTypes[`${item}_REQUEST`] = `${item}_REQUEST`;
  actionTypes[`${item}_SUCCESS`] = `${item}_SUCCESS`;
  actionTypes[`${item}_FAILURE`] = `${item}_FAILURE`;
});

export default {
  ...actionTypes,
  PACKAGES_UPDATE_FILTER_DATA: 'PACKAGES_UPDATE_FILTER_DATA',
  PACKAGES_CLEAR_ERRORS: 'PACKAGES_CLEAR_ERRORS',
  PACKAGES_UPDATE_RESULTS: 'PACKAGES_UPDATE_RESULTS',
  PACKAGES_RESET_FILTER: 'PACKAGES_RESET_FILTER',
  PACKAGES_TOGGLE_MODAL: 'PACKAGES_TOGGLE_MODAL',
  PACKAGES_RESET_STATE: 'PACKAGES_RESET_STATE',
  PACKAGES_SET_ACTIVE_STEP: 'PACKAGES_SET_ACTIVE_STEP',
  PACKAGES_LOAD_FILTERS: 'PACKAGES_LOAD_FILTERS',
  PACKAGES_ONCHANGE_FORM_FIELD: 'PACKAGES_ONCHANGE_FORM_FIELD',
  PACKAGES_SUBMIT_FORM: 'PACKAGES_SUBMIT_FORM',
  PACKAGES_ONCHANGE_DEPARTURE_DATE_PRICE:
    'PACKAGES_ONCHANGE_DEPARTURE_DATE_PRICE'
};
