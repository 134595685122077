// Constants
import API from '../Services';
import ACTION_TYPES from './actionTypes';
import { getPassengers } from 'Features/flights/components/Passengers/helpers';
import { getSteps } from 'Containers/Checkout/helpers';
import { updateLoader } from '../Features/app/actions';

// Reducer
export const initialState = {
  reservationData: {
    ticket: null,
    reserveCode: null,
    atcInitData: null
  },
  requestData: {
    paymentMethod: {},
    passengers: [],
    holder: { holders: [] },
    creditCardPayment: {
      cardInfo: {},
      checkedBank: null,
      checkedFee: null,
      checkedCard: null,
      isValid: false
    },
    wireTransferPayment: {},
    branchOffices: {},
    paymentNetwork: {},
    invoice: {}
  },

  selectedAvailability: null,

  pending: false
};

export default (state = initialState, action) => {
  switch (action.type) {
    case ACTION_TYPES.PAYMENT_SIMULATOR_REQUEST:
      return {
        ...state
      };

    case ACTION_TYPES.PAYMENT_SIMULATOR_SUCCESS:
      return {
        ...state,
        paymentOptions: {
          cards: action.payload.creditCardPayment,
          transferBanks: action.payload.wireTransferPayment,
          networks: action.payload.paymentNetwork,
          offices: action.payload.branchOffices,
          paymentTypes: Object.keys(action.payload)
        }
      };

    case ACTION_TYPES.PAYMENT_SIMULATOR_FAILURE:
      return {
        ...state
      };

    case ACTION_TYPES.PAYMENT_CHECKOUT_REQUEST:
      return {
        ...state,
        pending: true
      };

    case ACTION_TYPES.PAYMENT_CHECKOUT_SUCCESS:
      return {
        ...state,
        reservationData: {
          reserveCode: action.payload.reserveCode,
          ticket: action.payload.ticket
        },
        paymentGateways: {
          atcInitPaymentForm: action.payload.atcInitPaymentForm
        },
        reserveCode: action.payload.reserveCode,
        ticket: action.payload.ticket,
        pending: false
      };

    case ACTION_TYPES.PAYMENT_CHECKOUT_FAILURE:
      return {
        ...state,
        pending: false
      };

    case ACTION_TYPES.CONFIRM_PAYMENT_STATUS_REQUEST:
      return {
        ...state,
        pending: true
      };

    case ACTION_TYPES.CONFIRM_PAYMENT_STATUS_SUCCESS:
    case ACTION_TYPES.CONFIRM_PAYMENT_STATUS_FAILURE:
      return {
        ...state,
        pending: false
      };

    case ACTION_TYPES.OWN_FINANCING_SUCCESS:
      return {
        ...state,
        conditions: action.payload.conditions,
        descriptionTimeLimit: action.payload.descriptionTimeLimit,
        expirationInfo: action.payload.expirationInfo
      };

    case ACTION_TYPES.OWN_FINANCING_RESET:
      return {
        ...state,
        conditions: null,
        descriptionTimeLimit: null,
        expirationInfo: null
      };

    case ACTION_TYPES.SELECT_AVAILABILITY:
      return {
        ...state,
        selectedAvailability: action.payload,
        requestData: {
          ...state.requestData,
          passengers:
            action.payload &&
            action.payload.flightPrice &&
            getPassengers(action.payload.flightPrice.pricePassengerDetail)
        }
      };

    case ACTION_TYPES.STORAGE_USER_DATA:
      return {
        ...state,
        requestData: {
          ...state.requestData,
          ...action.payload
        }
      };

    case ACTION_TYPES.RESET_USER_DATA:
      return {
        ...state,
        requestData: {
          ...initialState.requestData,
          passengers: state.requestData.passengers
        }
      };

    case ACTION_TYPES.CHECKOUT_STEP_FILL:
      return {
        ...state,
        steps: {
          ...state.steps,
          titles: getSteps(action.payload)
        }
      };

    default:
      return state;
  }
};

// Action
export const paymentSimulator = params => async dispatch => {
  try {
    dispatch(paymentSimulatorRequest());

    const res = await API.paymentSimulator.psf(params);

    dispatch(paymentSimulatorSuccess(res.data));
  } catch (error) {
    dispatch(paymentSimulatorFailure());

    throw error;
  }
};

// Actions creators
const paymentSimulatorRequest = () => ({
  type: ACTION_TYPES.PAYMENT_SIMULATOR_REQUEST
});

const paymentSimulatorSuccess = payload => ({
  type: ACTION_TYPES.PAYMENT_SIMULATOR_SUCCESS,
  payload
});

const paymentSimulatorFailure = () => ({
  type: ACTION_TYPES.PAYMENT_SIMULATOR_FAILURE
});

// Pay
export const paymentCheckout = (engineId, params) => async dispatch => {
  try {
    dispatch(paymentCheckoutRequest());
    dispatch(updateLoader(true));

    const res = await API.paymentSimulator.pay(engineId, params);
    dispatch(paymentCheckoutSuccess(res.data));
    dispatch(updateLoader(false));

    return res.data;
  } catch (error) {
    dispatch(paymentCheckoutFailure());
    dispatch(updateLoader(false));

    throw error;
  }
};

// CHECK PAYMENT STATUS BANCARD
const confirmPaymentStatusRequest = () => ({
  type: ACTION_TYPES.CONFIRM_PAYMENT_STATUS_REQUEST
});

const confirmPaymentStatusSuccess = () => ({
  type: ACTION_TYPES.CONFIRM_PAYMENT_STATUS_SUCCESS
});

const confirmPaymentStatusFailure = () => ({
  type: ACTION_TYPES.CONFIRM_PAYMENT_STATUS_FAILURE
});

export const confirmPaymentStatus = (engine, processId) => async dispatch => {
  try {
    dispatch(confirmPaymentStatusRequest());

    const res = await API.paymentSimulator.confirmStatus(engine, processId);

    dispatch(confirmPaymentStatusSuccess());

    return res.data;
  } catch (error) {
    dispatch(confirmPaymentStatusFailure());
  }
};

// PAYING RESERVE ACTIONS
const paymentCheckoutRequest = () => ({
  type: ACTION_TYPES.PAYMENT_CHECKOUT_REQUEST
});

const paymentCheckoutSuccess = payload => ({
  type: ACTION_TYPES.PAYMENT_CHECKOUT_SUCCESS,
  payload
});

const paymentCheckoutFailure = () => ({
  type: ACTION_TYPES.PAYMENT_CHECKOUT_FAILURE
});

export const selectAvailability = payload => dispatch => {
  dispatch({
    type: ACTION_TYPES.SELECT_AVAILABILITY,
    payload
  });
};

// Storage user data
export const storageUserData = userData => dispatch => {
  dispatch({
    type: ACTION_TYPES.STORAGE_USER_DATA,
    payload: userData
  });
};

// Reset user data
export const resetUserData = () => dispatch => {
  dispatch({ type: ACTION_TYPES.RESET_USER_DATA });
};

export const getOwnFinancingText = (
  engineId,
  conditionTypeId
) => async dispatch => {
  try {
    dispatch({
      type: ACTION_TYPES.OWN_FINANCING_REQUEST
    });
    const { data } = await API.paymentSimulator.conditionOwnfinancing(
      engineId,
      conditionTypeId
    );

    dispatch({
      type: ACTION_TYPES.OWN_FINANCING_SUCCESS,
      payload: data
    });
  } catch (error) {
    dispatch({
      type: ACTION_TYPES.OWN_FINANCING_FAILURE
    });
  }
};

export const resetOwnFinancing = () => dispatch => {
  dispatch({
    type: ACTION_TYPES.OWN_FINANCING_RESET
  });
};

export const fillCheckoutSteps = payload => dispatch => {
  dispatch({
    type: ACTION_TYPES.CHECKOUT_STEP_FILL,
    payload
  });
};
