/**
 *
 * ReviewHeader
 *
 */

import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import cs from 'classnames';
import RatingBox from '../RatingBox';
import useBreakpoints from 'Utils/hooks/useBreakpoints';

// eslint-disable-next-line react/prop-types
const ReviewHeader = ({ className, rating, ratingText, amountReviews }) => {
  const isReview = rating && ratingText;
  const { largeDown } = useBreakpoints();
  return (
    <div className={cs(className)}>
      {isReview && (
        <>
          <RatingBox big inline>
            {rating}
          </RatingBox>
        </>
      )}
      <div className="content-amount">
        <TextRating size="large" context="information" weight="semi-bold">
          {ratingText}
        </TextRating>
        <AmountReviews size="large" largeDown={largeDown}>
          {amountReviews} opiniones
        </AmountReviews>
      </div>
    </div>
  );
};

ReviewHeader.propTypes = {
  className: PropTypes.string,
  rating: PropTypes.number.isRequired,
  ratingText: PropTypes.string.isRequired
};

export default styled(ReviewHeader)`
  height: 75px;
  padding: 8px 0 10px 10px;
  position: relative;
  display: flex;

  .content-amount {
    display: flex;
    flex-direction: column;
    margin-left: 10px;
  }
  .content-text-rating {
    margin-left: 10px;
  }
  span {
    padding-left: 4px;
  }

  img {
    width: auto;
    display: block;
    max-width: 100%;
    height: 18px;
    position: absolute;
    right: 2%;
    bottom: 2%;
  }

  /* Small screens */
  @media all and (max-width: 500px) {
    span {
      display: block;
      position: absolute;

      &:nth-child(1) {
        padding-left: 7px;
      }

      &:nth-child(2) {
        top: 10px;
        left: 80px;
      }

      &:nth-child(3) {
        top: 25px;
        left: 80px;
      }
    }
  }
`;

const AmountReviews = styled.span`
  color: #282828;
  font-family: Itau Display Pro;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  opacity: 0.5;
  margin-top: ${props => props.largeDown === true && '18px'};
`;

const TextRating = styled.span`
  color: #fb8400;
  font-family: Itau Display Pro;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px;
`;
