/**
 *
 * Footer
 *
 */

import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { push } from 'connected-react-router';
import { useDispatch, useSelector } from 'react-redux';

import Modal from 'Components/Modal';
import { routes, footerCountryData } from 'Constants';
import TermsAndConditions from 'Components/TermsAndConditions';
import ModalPoliticsAndPrivacy from 'Components/ModalPoliticsAndPrivacy';

import logo from 'Assets/logo.png';
import qr from './qr.png';
import bancard from './bancard.svg';
import pci from './PCI.svg';
import instagram from './Instagram.svg';
import facebook from './Facebook.svg';
import linkedin from './Linkedin.svg';
import youtube from './Youtube.svg';
import phone from './phone.svg';
import mevuelo from './mevuelo.svg';
import { useHistory } from 'react-router-dom';
import { BaseAndCondition } from 'Components/BaseAndCondition';

const Container = styled.div`
  max-width: 1100px;
  width: 100%;
  margin: auto;
  margin-top: 200px;
  button {
    margin: 0;
  }
`;

const Row = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 20px 0px;
  justify-content: space-between;
`;

const Row2 = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0px;
  gap: 10px;
  margin-top: 20px;
`;

const QRText = styled.div`
  font-family: 'Itau Display Pro';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  display: flex;
  align-items: center;
  color: #282828;
  width: 317px;
`;

const Title = styled.div`
  font-family: 'Itau Display Pro';
  font-style: normal;
  font-weight: 700;
  font-size: 22px;
  line-height: 30px;
  display: flex;
  align-items: center;
  text-align: center;
  color: #000000;
`;

const ChecksHolder = styled.div`
  margin-top: 20px;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 0px;
  gap: 20px;
`;

const CentroDeAyuda = styled.a`
  font-family: 'Itau Display Pro';
  font-style: normal;
  font-weight: 400;
  font-size: 22px;
  line-height: 30px;
  display: flex;
  align-items: center;
  text-align: center;
  color: #fb8400;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 0px;
  width: 230px;
  height: 50px;
  background-color: white;
  border: 1px solid #fb8400;
  border-radius: 10px;
  margin-top: 20px !important;
  cursor:pointer;
  
  &:hover {
    outline: 0;
    color: #fb8400;
    text-decoration: none;
  }

  &:focus {
    outline: 0;
  }
`;

const SocialHolder = styled.div`
  margin-top: 20px;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 0px;
  gap: 10px;
  img {
    cursor: pointer;
  }
`;

const BottomHeader = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 20px 0px;
  width: 100%;
  height: 100px;
  border-top: 1px solid rgba(40, 40, 40, 0.25);
  margin-top: 40px;
`;

const BottomHeaderItemsHolder = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 0px;
  gap: 30px;
  height: 24px;

  span {
    cursor: pointer;
    font-family: 'Itau Display Pro';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    display: flex;
    align-items: center;
    text-align: center;
    color: #282828;
    &:hover {
      text-decoration: none;
    }
  }

  a {
    font-family: 'Itau Display Pro';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    display: flex;
    align-items: center;
    text-align: center;
    color: #282828;
    &:hover {
      text-decoration: none;
    }
  }
`;

const ContactoHolder = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 0px;
  gap: 10px;
  width: 96px;
  a {
    font-family: 'Itau Display Pro';  
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    display: flex;
    align-items: center;
    text-align: center;
    color: #fb8400;
    &:hover {
      text-decoration: none;
    }
  }
`;

const BottomHolder = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-end;
  margin-top: 120px;
  padding: 20px 0px;
  text-decoration: none;
  gap: 50px;
  font-family: 'Itau Display Pro';
  font-style: normal;
  font-weight: 400;
  font-size: 22px;
  line-height: 30px;
  display: flex;
  align-items: center;
  text-align: center;
  background-color: black;
  .content-logo {
    display: flex;
    justify-content: end;
    max-width: 1100px;
    width: 100%;
    img {
      width: 135px;
    }
  }
`;

const selector = ({ appConfiguration, router }) => ({
  sites: appConfiguration.sites,
  pathname: router.location.pathname
});

const FooterDesktop = ({ className }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const routing = routes;
  const { sites, pathname } = useSelector(selector);
  const sitesDefault = sites.filter(item => item.enable === true);

  const [showPaP, setshowPaP] = useState(false);

  const [showTaC, setShowTaC] = useState(false);

  const [showBase, setShowBase] = useState(false);

  const go = route => () => {
    dispatch(push(route));
  };

  const data = footerCountryData();

  const onShowPaP = () => {
    // event && event.preventDefault();

    setshowPaP(old => !old);
  };

  const pushToSocialNetwork = network => () => {
    switch (network) {
      case 'facebook':
        window.open(data.facebook);
        break;

      case 'instagram':
        window.open(data.instagram);
        break;

      case 'linkedin':
        window.open(data.linkedin);
        break;

      case 'whatsapp':
        window.open(data.whatsapp);
        break;

      default:
        break;
    }
  };

  const onShowTaC = event => {
    event && event.preventDefault();

    setShowTaC(old => !old);
  };

  const onShowBase = () => {
    setShowBase(old => !old)
  }

  {
    /*
  MVFE-926 Prevent of rendering on checkout
  No borrar este test, sin hablar con Seba
  */
  }

  const centerHelp = () => {
    window.open('http://wa.me/595984729300', '_blank');
  };

  const linkYoutube = () => {
    window.open(
      'https://www.youtube.com/channel/UCSAYA_yCX5qNODOThi1LlSQ',
      '_blank'
    );
  };
  const linkFacebook = () => {
    window.open('https://www.facebook.com/TiendaNaranjaViajesPY', '_blank');
  };
  const linkInstagram = () => {
    window.open('https://www.instagram.com/tiendanaranjaviajespy/', '_blank');
  };
  const linkLinkedin = () => {
    window.open('https://www.linkedin.com/company/mevuelo', '_blank');
  };

  return /checkout/.test(pathname) || /airCheckout/.test(pathname) ? null : (
    <>
      <Container>
        <Row>
          <div>
            <Title>Agencia de viajes registrada</Title>
            <Row2>
              <img src={qr} style={{ width: 60 }} alt="QR Code" />
              <QRText>
                Real Consulting S.A. - mevuelo.com
                <br />
                Resolución Registur N° 1263/2019
              </QRText>
            </Row2>
            <ChecksHolder>
              <img src={bancard} alt="Bancard" />
              <img src={pci} alt="PCI" />
            </ChecksHolder>
          </div>

          <div>
            <Title>¿Necesitás ayuda?</Title>

            <CentroDeAyuda
              style={{ color: '#fb8400' }}
              onClick={centerHelp}
            >
              Contactá con Nosotros
            </CentroDeAyuda>
          </div>

          <div>
            <Title>Seguinos en redes</Title>
            <SocialHolder>
              <img src={instagram} alt="instagram" onClick={linkInstagram} />
              <img src={facebook} alt="facebook" onClick={linkFacebook} />
              <img src={linkedin} alt="linkedin" onClick={linkLinkedin} />
              <img src={youtube} alt="youtube" onClick={linkYoutube} />
            </SocialHolder>
          </div>
        </Row>
        <BottomHeader>
          <img src={logo} alt="viajes naranja" />

          <BottomHeaderItemsHolder>
            <span onClick={onShowBase}>Bases y condiciones</span>
            <span onClick={onShowTaC}>Términos de uso</span>
            <span onClick={onShowPaP}>Política de privacidad</span>
            {/* 
          // TODO: esto lo dejo comentado hasta q tengamos el link a donde deberia de ir
          <a href="#">Sumate al equipo</a> 
          */}
          </BottomHeaderItemsHolder>
        </BottomHeader>
        <Modal
          show={showTaC}
          className="tac-modal"
          closeButton={{ onClick: onShowTaC }}
          header="TÉRMINOS y CONDICIONES"
          onClickOverlay={onShowTaC}
          size="large"
          headerCentered>
          <TermsAndConditions />
        </Modal>
        <ModalPoliticsAndPrivacy showPaP={showPaP} onShowPaP={onShowPaP} />
        <Modal
          show={showBase}
          className="tac-modal"
          closeButton={{ onClick: onShowBase }}
          header="BASES y CONDICIONES"
          onClickOverlay={onShowBase}
          size="large"
          headerCentered>
          <BaseAndCondition />
        </Modal>

      </Container>
      <BottomHolder>
        <div className="content-logo" style={{
          display: 'flex',
          color: 'white',
          alignItems: 'baseline',
          fontSize: 'small',
          gap: 6
        }}>
          powered by
          <img src={mevuelo} alt="me vuelo" style={{
            top: 2,
            position: 'relative'
          }} />
        </div>
      </BottomHolder>
    </>
  );
};

FooterDesktop.propTypes = {
  className: PropTypes.string
};
export default FooterDesktop;
