/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import SelectPeople from 'Components/SelectPeople';
import TextArea from 'Components/TextArea';
import Button from 'Components/Button';
import useBreakpoints from 'Utils/hooks/useBreakpoints';
import * as Yup from 'yup';
import { getBasePriceString } from 'Features/packages/helpers';
import { breakpoints, routes } from 'Constants';
import { useHistory, useParams } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { useForm, FormProvider, useWatch } from 'react-hook-form';
import { FormInput } from 'Containers/AirCheckout/components/FormInput';
import { FormSelect } from 'Containers/AirCheckout/components/FormSelect';
import { setDepartureDatePrice } from '../../actions';
import logoItau from 'Assets/logo_naranja.svg';
import API from 'Services';
import { Label } from 'Containers/AirCheckout/components/Common';
import ConnectedPackageThanyou, { PackageThankyou } from '../PackageThankyou';
import countries from 'Components/InvoiceForm/countries';
import { useQueryString } from 'Utils/hooks';
import { parseIso } from 'Utils/dates';
import { isWithinInterval } from 'date-fns';

const defaultState = {
  adultCount: 2,
  childrenCount: 0
};

export const FormDetails = ({
  basePrice,
  currency,
  price,
  prices,
  errors = {},
  title
}) => {
  const methods = useForm();
  const { setValue, control } = methods;
  const { largeDown } = useBreakpoints();
  const history = useHistory();
  const [paxState, setState] = useState(defaultState);
  const [query, setQuery] = useState('');
  const [roomRateQuoted, setRoomRateQuoted] = useState(true);
  const [queryDate, onSetQueryDate] = useQueryString('date');
  const dispatch = useDispatch();
  const { id } = useParams();

  const routing = routes;
  const {
    selectedItauPoints,
    itauPoints: { USD },
    documentTypes
  } = useSelector(state => state.appConfiguration);

  const handleSubmit = async data => {
    const index = paxState.kidsAges.map((el, i) => i);
    try {
      const toSend = {
        ...data,
        adultCount: paxState.adultsCount,
        productId: Number(id),
        childrenCount: paxState.childrenQty,
        url: window.location.href,
        ...(paxState.childrenQty > 0 && {
          childrenAges: index.map(index => paxState.kidsAges[index].id)
        }),
        prefers: 1
      };

      await API.packages.assistances(toSend);
      history.push({
        pathname: routing.packageThankyou,
        state: {
          firstName: data.name
        }
      });
    } catch (error) {
      // console.log('error', error);
    }
  };

  //function for itau points
  const costCurrencyInPoints = USD;
  const priceInPoints = Math.ceil(price / costCurrencyInPoints);
  const moneyInPoints = selectedItauPoints * costCurrencyInPoints;
  const moneyToComplete = Math.ceil(price - moneyInPoints);
  const pointsToRender =
    selectedItauPoints <= priceInPoints
      ? selectedItauPoints.toLocaleString('en').replaceAll(',', '.')
      : priceInPoints.toLocaleString('en').replaceAll(',', '.');

  const handlePaxChange = dataPax => {
    setState(dataPax);
  };

  //This function finds the lowest price
  const firstPriceDate =
    prices &&
    prices.length > 0 &&
    prices.reduce((prev, curr) =>
      prev.price.amount < curr.price.amount ? prev : curr
    ).startDate;

  //Send query date to ULR
  useEffect(() => {
    dispatch(setDepartureDatePrice(queryDate));
  }, [queryDate]);

  useEffect(() => {
    setValue('codePhone', '595');
    setValue('dateTravel', firstPriceDate);
  }, [setValue]);

  useEffect(() => {
    onSetQueryDate(dateTravel);
  }, [dateTravel]);

  const dateTravel = useWatch({
    control,
    name: 'dateTravel'
  });

  useEffect(() => {
    let isDateInRange = true;

    if (dateTravel !== undefined) {
      for (let i = 0; i < prices.length; i++) {
        const startDate = prices[i].startDate;
        const endDate = prices[i].endDate;
        if (endDate.length !== 0) {
          if (dateTravel >= startDate && dateTravel <= endDate) {
            isDateInRange = true;
            break;
          } else {
            isDateInRange = false;
          }
        } else {
          if (dateTravel == startDate) {
            isDateInRange = true;
            break;
          } else {
            isDateInRange = false;
          }
        }
      }
    }
    setRoomRateQuoted(isDateInRange);
  }, [dateTravel]);



  if (prices && dateTravel) {
    const priceFound = prices.find(price => {
      if (price.startDate && price.endDate) {
        const startDate = parseIso(price.startDate);
        const endDate = parseIso(price.endDate);
        return isWithinInterval(parseIso(dateTravel), {
          start: startDate,
          end: endDate
        });
      } else {
        return dateTravel === price.startDate;
      }
    });
    if (priceFound) {
      price = priceFound.price.amount;
      currency = priceFound.price.currency;
    }
  }

  return (
    <FormProvider {...methods}>
      <Container
        largeDown={largeDown}
        className="container-form"
        onSubmit={methods.handleSubmit(handleSubmit)}>
        {roomRateQuoted && (
          <ContentHeader>
            <div className="content-points">
              <Column>
                <TagFrom context="blackHaze" size="medium">
                  Desde
                </TagFrom>
                <div className="content-price-form">
                  <ContentPriceAndPrice context="important">
                    {currency} {price}
                  </ContentPriceAndPrice>
                </div>
              </Column>
              <PromoPriceTag>
                <p>ó</p>
                <span>{pointsToRender} puntos</span>
                <img src={logoItau} alt="itau" />
                <span>+ USD {moneyToComplete < 0 ? 0 : moneyToComplete}</span>
              </PromoPriceTag>
            </div>
          </ContentHeader>
        )}
        {roomRateQuoted && basePrice && (
          <>
            <FormLabel context="blackHaze">
              {getBasePriceString(basePrice)[0]}{' '}
              {getBasePriceString(basePrice)[1]}
            </FormLabel>
          </>
        )}
        <TitleForm context="important" weight="semi-bold" size="xlarge">
          {title || 'Contactá a un vendedor'}
        </TitleForm>
        <ContentBody largeDown={largeDown}>
          <FormLabel>
            <FormInput
              name="dateTravel"
              label="Fecha de viaje"
              type="date"
              registerOptions={{
                required: true
              }}
            />
          </FormLabel>
          <FormLabel>
            <Label>
              Pasajeros <span className="asterisk">*</span>
            </Label>
            <SelectPeople
              className="input"
              onChange={handlePaxChange}
              defaultValue={paxState}
              error={{
                message: errors.people
                  ? 'La edad de los menores es requerida'
                  : undefined,
                color: 'warning'
              }}
            />
          </FormLabel>{' '}
          <FormLabel>
            <FormInput
              name="name"
              label="Nombre"
              placeholder="Ingresar nombre"
              registerOptions={{
                required: true
              }}
            />
          </FormLabel>
          <FormLabel>
            <FormInput
              name="email"
              label="E-mail"
              placeholder="Ej: hola@mevuelo.com"
              registerOptions={{
                required: true
              }}
            />
          </FormLabel>
          <div className="mobile-content-inputs-form">
            <FormLabel>
              <FormRow className={largeDown && 'input-phone '}>
                <div className="code-phone">
                  <FormSelect
                    name="codePhone"
                    label="Codigo"
                    options={countries.map(country => ({
                      id: country.phoneCode,
                      name: `(+${country.phoneCode}) ${country.name}`
                    }))}
                    registerOptions={{
                      required: true
                    }}
                  />
                </div>
                <FormInput
                  name="numberPhone"
                  label="Teléfono"
                  placeholder="Ingresá tu teléfono"
                  registerOptions={{
                    required: true
                  }}
                />
              </FormRow>
            </FormLabel>
          </div>
          <FormLabel className="test">
            <InputLabel weight="semi-bold">Comentarios Adicionales:</InputLabel>
            <TextArea
              className={largeDown ? 'input' : 'input-coment-aditional'}
              placeholder="Escribe aquí los comentarios que quieras agregar."
              value={query}
              message={errors.query}
              context={errors.query && 'warning'}
              onChange={setQuery}
            />
          </FormLabel>
        </ContentBody>
        <Button type="submit" className="input button-form">
          Enviar consulta
        </Button>
      </Container>
    </FormProvider>
  );
};

const Container = styled.form`
  display: flex;
  flex-direction: column;
  align-items: center;
  background: #FAF2E3;
  border-radius: 14px;
  box-shadow: ${({ theme }) => theme.shadows.four};
  padding: ${({ theme }) => theme.spacing.one};
  width: ${props => (props.largeDown === true ? '100%' : '354px')};
  top: 305px;
  left: 892px;
  border-radius: 10px;
  gap: 32px;

  .input {
    margin-bottom: ${({ theme }) => theme.spacing.four};
  }
  .button-form{
    width: 314px;
    height: 40px;
    padding: 0px 30px ;
    border-radius: 10px;
    gap: 10px;
    justify-content:center;
  }
  .margin-right {
    margin-right: ${({ theme }) => theme.spacing.two};
    width: ${props => props.largeDown === true && '190px'} ;
  }
  @media (max-width: ${breakpoints.medium + 'px'}) {
    border-radius: 0;
  }
  .container-form{

  }
`;

const ContentHeader = styled.div`
  display: flex;
  align-items: center;
  margin-top: 20px;
`;

const ContentBody = styled.div`
  display: flex;
  align-content: center;
  flex-direction: column;
  padding: ${({ theme }) => theme.spacing.four};
  width: 100%;
  .content-inputs-form {
    display: flex;
    justify-content: flex-start;
  }
  .mobile-content-inputs-form {
    display: column;
  }
  .input-columns {
    margin-left: 30px;
  }
  .input-coment-aditional {
    width: 100%;
  }
  .test {
    width: 100%;
  }
  .mobile-content-phone {
  }
  .mv-input-container {
    width: ${props => props.largeDown === true && '100%'};
  }
  .content-phone {
    margin-left: 30px;
  }
  .code-phone {
    width: 200px;
    margin-right: 10px;
  }
`;

const Column = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-right: ${({ theme }) => theme.spacing.one};
  .content-price-form {
    margin-left: 10px;
  }
  .content-price-form span {
    padding-left: 10px;
    font-size: 24px;
    font-weight: 700;
    line-height: 32px;
    letter-spacing: 0em;
    text-align: left;
  }
`;

const FormLabel = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0px 3px 0px 10px;
  border-radius: 10px;
  justify-content: space-between;
  & > div {
    width: 100%;
    margin-bottom: ${({ theme }) => theme.spacing.four};
  }
  .asterisk {
    color: red;
  }
`;

const FormRow = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: nowrap;
`;

const InputLabel = styled.span`
  width: 314px;
  height: 40px;
  color: #282828;
  font-family: Itau Display Pro;
  font-size: 16px;
  font-weight: 700;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: left;
`;

const TitleForm = styled.h3`
  font-family: Itau Display Pro;
  font-size: 22px;
  font-weight: 700;
  line-height: 30px;
  letter-spacing: 0em;
  text-align: center;
  color: #fb8400;
`;

const TagFrom = styled.h3`
  font-family: Itau Display Pro;
  font-size: 14px;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: 0em;
  text-align: left;
  color: #282828;
  width: 37px;
  height: 22px;
  margin-top: 8px;
`;

export const PromoPriceTag = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 0px;
  gap: 10px;
  font-family: 'Itau Display Pro';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #003399;

  img {
    width: 24px;
  }
  span {
    font-family: Itau Display Pro;
    font-size: 24px;
    font-weight: 700;
    line-height: 32px;
    letter-spacing: 0em;
    text-align: left;
  }
  p {
    font-family: Itau Display Pro;
    font-size: 14px;
    font-weight: 400;
    margin-top: 17px;
    line-height: 22px;
    letter-spacing: 0em;
    text-align: left;
    color: #282828;
  }
`;

const ContentPriceAndPrice = styled.span`
  color: var(--primario, #fb8400);
  font-family: Itau Display Pro;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 32px;
`;
