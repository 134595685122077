import React from 'react';
import styled from 'styled-components';
import { useDispatch } from 'react-redux';

import { onUpdateFilters } from 'Features/packages/actions';
import { filterKeys, orderByOptions } from 'Features/packages/constants';
import { ReactComponent as Arrow } from './arrow.svg';

const Label = styled.label`
  font-family: Itau Display Pro;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: left;
  padding: 7px 5px 0px 0px;
`;
const OrderSelect = styled.div`
  background: #ffffff;
  border-radius: 10px;
  width: 254px;
  height: 50px;
  filter: drop-shadow(1px 1px 3px rgba(0, 0, 0, 0.25));
  padding: 13px 10px;
  font-family: 'Itau Display Pro';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #000000;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 12px;
  padding: 10px;
  border-radius: 10px;

  select {
    appearance: none;
    border: 0;
    width: 254px;

    &:focus {
      outline: 0;
    }
  }
`;

const SelectedOptions = styled.select`
  width: 120px;
  height: 24px;
  font-family: Itau Display Pro;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0;
  text-align: left;
  cursor: pointer;
`;

const Option = styled.option`
  width: 120px;
`;

const Holder = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
`;

// eslint-disable-next-line arrow-body-style
export const PacketFilterButton = () => {
  const dispatch = useDispatch();
  const handleChange = e => {
    // actions.handleUpdateOrderBy(orderByOptions.NOMBRE);
    dispatch(onUpdateFilters(filterKeys.ORDER_BY, e.target.value));
  };
  return (
    <Holder>
      <Label>Ordena por: </Label>
      <OrderSelect>
        <SelectedOptions onChange={handleChange}>
          <Option value={orderByOptions.PRECIO}>{orderByOptions.PRECIO}</Option>
          <option value={orderByOptions.NOMBRE}>{orderByOptions.NOMBRE}</option>
          <option value={orderByOptions.DURACION}>
            {orderByOptions.DURACION}
          </option>
        </SelectedOptions>
        <Arrow />
      </OrderSelect>
    </Holder>
  );
};
