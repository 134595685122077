/**
 *
 * MpcAvailabilityDesktop
 *
 */

import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { dateForMpc } from 'Utils/returnDate';
import Modal from 'Components/Modal';
import AirAvailabilityCard from '../AirAvailabilityCard';
import Icon from 'Components/Icon';
import Button from 'Components/Button';
import AirCheckError from '../AirCheckError';

import { ReactComponent as Left } from './left.svg';
import { ReactComponent as Right } from './right.svg';
import { ReactComponent as Up } from './up.svg';
import { ReactComponent as Down } from './down.svg';

import { AirItineraryContent } from 'Features/flights/components/AirAvailabilityCard/itinerary-details';
import { excludeAirlineBaggage } from 'Constants';

const CloseIconHolder = styled.div`
  display: flex;
  flex-direction: row-reverse;

  > .icon {
    cursor: pointer;
    margin-bottom: 8px;
  }
`;

const Frame1 = styled.div`
  width: 822px;
  display: flex;
  flex-direction: column;
`;

const Row = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
`;

const DateBox = styled.div`
  display: flex;
  background: #faf2e3;
  height: 44px;
  font-family: 'Itau Display Pro';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  text-align: center;
  color: #282828;
  align-items: center;
  justify-content: center;
  width: 100%;

  &.vuelta {
    height: 66px;
    width: 93px;
  }
`;

const CalendarItem = styled.div`
  height: 66px;
  background-color: ${props =>
    props.isCenter
      ? '#FB8400'
      : props.isCheaper
        ? 'rgba(76, 175, 80, 0.1)'
        : 'white'};
  border: 1px solid rgba(40, 40, 40, 0.1);
  color: ${props => (props.isCenter ? 'white' : '#898989')};
  cursor: pointer;

  font-family: 'Itau Display Pro';
  font-style: normal;
  font-weight: 300;
  font-size: 12px;
  line-height: 22px;
  text-align: center;
  flex: 1;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const Frame2 = styled.div`
  display: flex;
  flex-direction: column;
`;

const CalendarHolder = styled.div`
  background-color: white;
  display: flex;
  border: 16px;
  overflow: hidden;
  width: 822px;
  border-radius: 16px;
  filter: drop-shadow(0px 8px 16px rgba(0, 0, 0, 0.25));
`;

const PriceTotal = styled.div`
  color: ${props =>
    props.isCenter ? 'white' : props.isCheaper ? '#4CAF50' : '#FB8400'};
  font-family: 'Itau Display Pro';
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
`;

const IdaBox = styled.div`
  display: flex;
  align-items: center;
  padding: 0 10px;
  gap: 10px;
  width: 179px;
  font-family: 'Itau Display Pro';
  font-style: normal;
  font-weight: 700;
  font-size: 22px;
  line-height: 30px;
  color: #000000;
  justify-content: space-between;
  margin-bottom: 20px;
`;

const VueltaBox = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 10px;
  gap: 10px;
  font-family: 'Itau Display Pro';
  font-style: normal;
  font-weight: 700;
  font-size: 22px;
  line-height: 30px;
  color: #000000;
  justify-content: space-between;
  margin-left: 10px;
`;

const MoveButton = styled.div`
  cursor: pointer;
  background: #ffffff;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.25);
  border-radius: 50px;
  width: 24px;
  height: 24px;
  transform: matrix(-1, 0, 0, 1, 0, 0);
  display: flex;
  justify-content: center;
  align-items: center;
`;
const Holder = styled.div`
  display: flex;
  flex-direction: column;
`;

const Placer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const ItineraryContentHolder = styled.div`
  background-color: white;
  border-radius: 10px;
  margin-top: 17px;
  padding: 10px 0 21px;

  &.rowItin {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 40px;
    border-radius: 10px 10px 0 0;
    margin-top: 17px;
    padding: 10px 0 10px;
  }
`;

const BtnComprar = styled.div`
  cursor: pointer;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 170px;
  height: 48px;
  background: #fb8400;
  border-radius: 12px;
  font-family: 'Itau Display Pro';
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 28px;
  text-align: center;
  color: #ffffff;
`;

const BtnOutline = styled.div`
  cursor: pointer;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 10px 30px;
  gap: 10px;
  width: 191px;
  height: 48px;
  background: #ffffff;
  border: 1px solid #fb8400;
  border-radius: 12px;
  font-family: 'Itau Display Pro';
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 28px;
  text-align: center;
  color: #fb8400;
`;

const ItineraryContent = ({ generalSegment }) => {
  const ida = generalSegment[0][0];
  const idaSegments = ida.segments;
  const showMochilaIda = !(
    ida.baggage.total === 0 &&
    excludeAirlineBaggage.includes(ida.airLines[0].code)
  );
  const showCarryOnIda = !(ida.baggage.total === 0);

  const vuelta = generalSegment[1] ? generalSegment[1][0] : null;
  const vueltaSegments = vuelta ? vuelta.segments : null;
  const showMochilaVuelta = vuelta
    ? !(
      vuelta.baggage.total === 0 &&
      excludeAirlineBaggage.includes(vuelta.airLines[0].code)
    )
    : null;
  const showCarryOnVuelta = vuelta ? !(vuelta.baggage.total === 0) : null;

  return (
    <ItineraryContentHolder>
      <AirItineraryContent
        ida={ida}
        idaSegments={idaSegments}
        showMochilaIda={showMochilaIda}
        showCarryOnIda={showCarryOnIda}
        vuelta={vuelta}
        vueltaSegments={vueltaSegments}
        showMochilaVuelta={showMochilaVuelta}
        showCarryOnVuelta={showCarryOnVuelta}
      />
    </ItineraryContentHolder>
  );
};

const initialMultiDestinationDisplayDetail = length => {
  const toReturn = [];
  for (var i = 0; i < length; i++) {
    toReturn.push(false);
  }
  return toReturn;
};

const ActionBar = ({ modalData, onVerMas }) => {
  const { generalSegment, flightPrice, flightObject, id, onClick } = modalData;

  const isMultiDestination = generalSegment.length > 2;
  const ida = generalSegment[0];
  const vuelta = generalSegment[1] ? generalSegment[1] : false;
  const newIda = ida
    ? ida.map(item => {
      const toReturn = { ...item, displayDetails: false };
      return toReturn;
    })
    : false;
  const newVuelta = vuelta
    ? vuelta.map(item => ({ ...item, displayDetails: false }))
    : false;

  const [componentState, setComponentState] = useState({
    stateIda: newIda,
    stateVuelta: newVuelta,
    selectedIda: { index: 0 },
    selectedVuelta: { index: 0 },
    multiDestinationDisplayDetail: initialMultiDestinationDisplayDetail(
      generalSegment[0].length
    ),
    multiDestination: isMultiDestination
  });

  useEffect(() => {
    const effectIda = generalSegment[0];
    const effectVuelta = generalSegment[1] ? generalSegment[1] : false;
    const effectNewIda = effectIda
      ? effectIda.map(item => ({ ...item, displayDetails: false }))
      : false;
    const effectNewVuelta = effectVuelta
      ? effectVuelta.map(item => ({ ...item, displayDetails: false }))
      : false;
    setComponentState({
      ...componentState,
      stateIda: effectNewIda,
      stateVuelta: effectNewVuelta,
      selectedIda: { index: 0, item: effectIda[0] },
      selectedVuelta: { index: 0, item: effectVuelta[0] }
    });
  }, [generalSegment]);

  return (
    <ItineraryContentHolder className="rowItin">
      <BtnOutline onClick={onVerMas}>Ver más vuelos</BtnOutline>
      <BtnComprar
        onClick={() => {
          modalData.onClick(
            {
              ida: componentState.selectedIda,
              vuelta: componentState.selectedVuelta
            },
            id,
            flightPrice,
            flightObject
          );
        }}>
        Comprar
      </BtnComprar>
    </ItineraryContentHolder>
  );
};

const MpcAvailabilityDesktop = ({
  handleArrowButton,
  departureDates,
  arrivalDates,
  detail,
  minPrice,
  handleShowAllFlights,
  modalData,
  showModal,
  toggleShowModal,
  handleMPCItemClick,
  arrowKeys,
  showErrorModal,
  toggleErrorModal,
  canDecreaseInitialDate,
  canSubstract

}) => {
  return (
    <Placer>
      <Holder>
        <IdaBox>
          {canDecreaseInitialDate() ? (<MoveButton onClick={handleArrowButton(arrowKeys.left)}>
            {' '}
            <Left />{' '}
          </MoveButton>) : null}

          <span>Ida</span>
          {canSubstract() ? (<MoveButton onClick={handleArrowButton(arrowKeys.right)}>
            {' '}
            <Right />{' '}
          </MoveButton>) : null}

        </IdaBox>
        <div
          style={{
            display: 'flex',
            alignItems: 'end',
            marginBottom: 170
          }}>
          <CalendarHolder>
            <Frame1>
              <Row>
                {departureDates.map((departureDate, i) => (
                  <DateBox key={departureDate}>
                    {dateForMpc(departureDate)}
                  </DateBox>
                ))}
              </Row>
              {detail &&
                detail.map((detailRow, idxRow) => (
                  <Row key={idxRow}>
                    {detailRow.map((detailItem, idxCol) =>
                      detailItem.flightPrice && !detailItem.research ? (
                        <CalendarItem
                          key={idxCol}
                          price={detailItem.flightPrice.totalForOneAdult}
                          onClick={handleMPCItemClick(detailItem)}
                          isCenter={detailItem.center}
                          isCheaper={
                            detailItem.flightPrice.totalForOneAdult === minPrice
                          }>
                          <PriceTotal
                            isCenter={detailItem.center}
                            isCheaper={
                              detailItem.flightPrice.totalForOneAdult ===
                              minPrice
                            }>
                            {detailItem.flightPrice.currency}{' '}
                            {detailItem.flightPrice.totalPrice}
                          </PriceTotal>
                          {detailItem.flightPrice.currency}{' '}
                          {detailItem.flightPrice.totalForOneAdult}/pasajero
                        </CalendarItem>
                      ) : detailItem.research && !detailItem.flightPrice ? (
                        <CalendarItem>to Research </CalendarItem>
                      ) : (
                        <CalendarItem />
                      )
                    )}
                  </Row>
                ))}
            </Frame1>
            <Frame2>
              <DateBox />
              {arrivalDates &&
                arrivalDates.map((arrivalDate, i) => (
                  <DateBox className="vuelta" key={arrivalDate}>
                    {dateForMpc(arrivalDate)}
                  </DateBox>
                ))}
            </Frame2>
          </CalendarHolder>
          <VueltaBox>
            {canSubstract() ? (<MoveButton onClick={handleArrowButton(arrowKeys.up)}>
              {' '}
              <Up />{' '}
            </MoveButton>) : null}

            <span>Vuelta</span>
            <MoveButton onClick={handleArrowButton(arrowKeys.down)}>
              {' '}
              <Down />{' '}
            </MoveButton>
          </VueltaBox>
        </div>

        {/* {showModal && modalData && (
          <div className="button-bar">
            <Button
              context="information"
              onClick={() =>
                handleShowAllFlights({
                  initialDate: modalData.initialDate,
                  endDate: modalData.endDate
                })
              }>
              <Icon name="mv-list" color="white" />
              Ver todos los vuelos para estas fechas
            </Button>
          </div>
        )} */}

        {modalData && (
          <Modal show={showModal} onClickOverlay={toggleShowModal} transparent>
            <div
              style={{
                marginTop: 50,
                marginBottom: 50,
                width: 884,
                paddingBottom: 80
              }}>
              <CloseIconHolder>
                <Icon
                  name="mv-cross"
                  size={24}
                  color="white"
                  onClick={toggleShowModal}
                />
              </CloseIconHolder>
              <AirAvailabilityCard
                mpcView
                generalSegment={modalData.generalSegment}
                flightPrice={modalData.flightPrice}
                localPrice={modalData.localPrice}
                initialDate={modalData.initialDate}
                endDate={modalData.endDate}
                pending={modalData.pending}
                routesData={modalData.routesData}
                // onClick={modalData.onClick}
                showAllFlights={handleShowAllFlights}
                id={modalData.id}
                disabledDetails={true}
              // toggleShowModal={toggleShowModal}
              />
              <ItineraryContent generalSegment={modalData.generalSegment} />

              <ActionBar
                onVerMas={() =>
                  handleShowAllFlights({
                    initialDate: modalData.initialDate,
                    endDate: modalData.endDate
                  })
                }
                modalData={modalData}
              />
            </div>
          </Modal>
        )}
        <Modal
          show={showErrorModal}
          onClickOverlay={toggleErrorModal}
          noCard
          modalWidth={700}>
          <AirCheckError close={toggleErrorModal} desktop />
        </Modal>
      </Holder>
    </Placer>
  );
};

MpcAvailabilityDesktop.propTypes = {
  className: PropTypes.string.isRequired,
  canDecreaseInitialDate: PropTypes.func.isRequired,
  canSubstract: PropTypes.func.isRequired,
  handleArrowButton: PropTypes.func.isRequired,
  departureDates: PropTypes.array.isRequired,
  arrivalDates: PropTypes.array,
  handleShowAllFlights: PropTypes.func.isRequired,
  toggleShowModal: PropTypes.func,
  showModal: PropTypes.bool,
  modalData: PropTypes.object,
  handleMPCItemClick: PropTypes.func,
  handleResearchMPTBItemClick: PropTypes.func,
  arrowKeys: PropTypes.object,
  detail: PropTypes.array,
  minPrice: PropTypes.number,
  middlePrice: PropTypes.number,
  maxPrice: PropTypes.number,
  showErrorModal: PropTypes.bool,
  toggleErrorModal: PropTypes.func
};

export default MpcAvailabilityDesktop;
