import React, { useRef } from 'react';
import styled from 'styled-components';
import { useSelector } from 'react-redux';
import { useFormContext } from 'react-hook-form';

import countries from 'Components/InvoiceForm/countries';

import { CommonHolder as PaxHolder, FormRow } from '../Common';
import { FormInput } from '../FormInput';
import { FormSelect } from '../FormSelect';
import { Breakpoint } from 'react-socks';
import { Checkbox } from '../Checkbox';

const PaxTitle = styled.div`
  font-family: 'Itau Display Pro';
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  color: #FB8400;
  width: 100%;
  text-transform: uppercase;
`;

interface Props {
  index: number;
  onCheckTitular: () => void;
  isTitular?: boolean;
}

export const PaxFields = ({ index, onCheckTitular, isTitular }: Props) => {
  const { documentTypes, genders } = useSelector(
    // @ts-ignore
    ({ appConfiguration: { sharedConfig } }) => sharedConfig
  );

  const {
    register,
    formState: { errors },
    getValues
  } = useFormContext();

  const travelerCode = getValues(`passengers.${index}.travelerCode`);

  const title = `Habitación ${index + 1}:`;

  return (
    <PaxHolder>
      <input {...register(`passengers.${index}.travelerCode`)} type="hidden" />
      <PaxTitle>{title}</PaxTitle>
      <Breakpoint style={{ width: '100%' }} small down>
        <FormRow>
          <FormInput
            name={`passengers.${index}.firstName`}
            label="Nombre"
            placeholder="Como figura en su documento de identidad"
            registerOptions={{
              required: true
            }}
          />
        </FormRow>
        <FormRow>
          <FormInput
            name={`passengers.${index}.lastName`}
            label="Apellido"
            placeholder="Como figura en su documento de identidad"
            registerOptions={{
              required: true
            }}
          />
        </FormRow>

        <FormRow>
          <Checkbox checked={isTitular} onClick={onCheckTitular} ><span>Titular de reserva y facturación</span></Checkbox>
        </FormRow>

      </Breakpoint>

      <Breakpoint medium up>
        <FormRow>
          <FormInput
            name={`passengers.${index}.firstName`}
            label="Nombre"
            placeholder="Como figura en su documento de identidad"
            registerOptions={{
              required: true
            }}
          />
          <FormInput
            name={`passengers.${index}.lastName`}
            label="Apellido"
            placeholder="Como figura en su documento de identidad"
            registerOptions={{
              required: true
            }}
          />
        </FormRow>
        <FormRow style={{flexDirection: 'row-reverse'}}>
          <Checkbox checked={isTitular} onClick={onCheckTitular}><span>Titular de reserva y facturación</span></Checkbox>
        </FormRow>
      </Breakpoint>
    </PaxHolder>
  );
};
