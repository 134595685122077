/**
 *
 * MobileHotelDestinationSearch
 *
 */

import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import cs from 'classnames';
import { useDispatch, useSelector } from 'react-redux';
import Input from 'Components/Input';
import Icon from 'Components/Icon';
import Text from 'Components/Text';
import * as COLORS from 'Constants/colors';
import { debounce } from 'lodash';
import { searchDebounceTime } from 'Constants';
import {
  setHotelDestination,
  getSuggestions,
  closeSuggestionsMenu
} from 'Features/hotels';

const selector = ({ hotels }) => ({
  location: hotels.search.location,
  suggestions: hotels.search.suggestions
});

const ConnectedMobileHotelDestinationSearch = props => {
  const dispatch = useDispatch();
  const actions = {
    setLocation: location => {
      dispatch(setHotelDestination(location));
    },
    getSuggestions: value => {
      dispatch(getSuggestions(value));
    },
    closeSuggestionsMenu: () => {
      dispatch(closeSuggestionsMenu());
    }
  };

  const state = useSelector(selector);

  return (
    <StyledMobileHotelDestinationSearch
      actions={actions}
      {...state}
      {...props}
    />
  );
};

const MobileHotelDestinationSearch = ({
  className,
  actions,
  location,
  suggestions,
  onClose
}) => {
  const { pending, predictions, value: searchValue } = suggestions;
  // TODO implement recent searches
  const recentSearches = [];
  const fetchSuggestions = async value => {
    actions.getSuggestions(value);
  };

  const debouncedFetchSuggestions = debounce(
    fetchSuggestions,
    searchDebounceTime.MOBILE
  );

  const onChangeSearch = search => {
    if (search.length < 3) return;

    debouncedFetchSuggestions(search);
  };

  const onSelectSuggestion = suggestion => () => {
    actions.setLocation(suggestion);
    onClose();
  };

  return (
    <div className={cs(className)}>
      <div className="top-bar">
        <Input
          onChange={onChangeSearch}
          value={location.description}
          placeholder="Ingresa un destino"
          isLoading={pending}
          setFocus
        />

        {!pending && (
          <span className="top-bar-close" onClick={onClose}>
            <Icon name="mv-cross" />
          </span>
        )}
      </div>

      <div className="modal-options-content">
        {!searchValue && (
          <div className="section-wrap" key="recent-searches">
            <div className="modal-divider">
              <Icon className="mfd-icon" name="mv-search" />

              <Text className="modal-divider-text">Búsquedas Recientes</Text>
            </div>
            <div className="container-options">
              {!recentSearches.length && (
                <div key="none">
                  {searchValue > 3 && (
                    <Text paragraph className="option-text">
                      Aún no se realizaron búsquedas
                    </Text>
                  )}
                </div>
              )}

              {recentSearches.length > 0 &&
                recentSearches.map(recent => (
                  <div
                    key={`${recent.code}-${recent.name}`}
                    onClick={onSelectSuggestion(recent)}>
                    <Text paragraph className="option-text">
                      {recent.name}
                    </Text>
                  </div>
                ))}
            </div>
          </div>
        )}

        {!pending &&
          predictions.length > 0 &&
          predictions.map(prediction => (
            <div
              key={prediction.placeId}
              onClick={onSelectSuggestion(prediction)}>
              <Text className="option-text">{prediction.description}</Text>
            </div>
          ))}

        {pending && (
          <Text className="option-text">
            Por favor, espere. Estamos buscando tu hotel.
          </Text>
        )}

        {!pending && searchValue.length > 3 && !predictions.length && (
          <Text className="option-text">
            No hemos podido encontrar lo que buscabas.
          </Text>
        )}
      </div>
    </div>
  );
};

MobileHotelDestinationSearch.propTypes = {
  className: PropTypes.string.isRequired,
  onClose: PropTypes.func,
  location: PropTypes.shape({
    placeId: PropTypes.string,
    description: PropTypes.string
  }),
  actions: PropTypes.shape({
    setLocation: PropTypes.func.isRequired,
    getSuggestions: PropTypes.func.isRequired,
    closeSuggestionsMenu: PropTypes.func.isRequired
  }),
  suggestions: PropTypes.shape({
    predictions: PropTypes.bool,
    openMenu: PropTypes.bool,
    pending: PropTypes.bool,
    value: PropTypes.string
  })
};

const StyledMobileHotelDestinationSearch = styled(MobileHotelDestinationSearch)`
  display: flex;
  flex-direction: column;
  position: fixed;
  top: 0;
  left: 0;
  background-color: white;
  z-index: 99999999;
  width: 100%;
  height: 100%;

  .modal-options-content {
    width: 100%;
    height: calc(100% - 62px);
  }

  .section-wrap {
    display: flex;
    position: relative;
    flex-direction: column;
    width: 100%;
  }

  .modal-divider {
    display: flex;
    width: 100%;
    height: 41px;
    align-items: center;
    box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.35);
    background-color: #fb8400;
  }

  .modal-divider-text {
    flex-grow: 2;
    font-size: 18px;
    font-weight: 500;
    letter-spacing: 0.18px;
    text-align: left;
    margin-left: 20px;
    color: ${COLORS.white};
  }

  .mfd-icon {
    margin-left: 15px;
    font-size: 20px;
    color: ${COLORS.white};
  }

  .container-options {
    display: flex;
    position: relative;
    width: 100%;
    height: calc(100% - 41px);
    flex-direction: column;
    justify-content: flex-start;
    overflow-y: scroll;
    overflow-x: hidden;
  }

  .option-text {
    display: flex;
    width: calc(100% - 15px);
    font-size: 16px;
    letter-spacing: 0.12px;
    text-align: left;
    color: ${COLORS.middleGrey};
    padding: 5px 5px 5px 10px;
    margin-left: 0;
    height: 35px;
    cursor: pointer;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }

  .top-bar {
    display: flex;
    width: 100%;
    height: 62px;
    background-color: ${COLORS.white};

    .mv-input {
      display: flex;
      width: 100%;
      font-family: Montserrat, Arial, Helvetica, sans-serif;

      .mv-input-container {
        display: flex;
        width: 100%;
        border-radius: 0;
        box-shadow: none;

        .mv-input-holder {
          width: 100%;

          .input-mevuelo {
            margin: auto;
            font-size: 18px;
            font-style: italic;
            letter-spacing: 0.18px;
            color: ${COLORS.middleGrey};
          }
        }
      }
    }
  }

  .top-bar-close {
    display: flex;
    position: absolute;
    width: 10%;
    height: 62px;
    right: 0;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    cursor: pointer;
    color: ${COLORS.lightGrey};
    float: right;
  }
`;

export default ConnectedMobileHotelDestinationSearch;
export { StyledMobileHotelDestinationSearch as MobileHotelDestinationSearch };
