/**
 *
 * FilterHolder
 *
 */

import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import cs from 'classnames';

import { ReactComponent as Arrow } from './arrow.svg';

const FilterHolder = ({
  className,
  children,
  title,
  context,
  active = true,
  isCollapse = false
}) => {
  const [state, setState] = useState(active);
  const toggleActive = () => {
    setState(prevState => !prevState);
  };

  return (
    <div className={cs(className)}>
      <TitleBar onClick={toggleActive} active={state}>
        <span>{title}</span>
        <Arrow />
      </TitleBar>
      <div className={cs('filter-content', { active: state })}>{children}</div>
    </div>
  );
};

FilterHolder.propTypes = {
  className: PropTypes.string.isRequired,
  children: PropTypes.any,
  title: PropTypes.string,
  context: PropTypes.string,
  active: PropTypes.bool,
  isCollapse: PropTypes.bool
};

const TitleBar = styled.div`
  cursor: pointer;
  padding: 0;
  font-family: 'Itau Display Pro';
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  text-align: justify;
  color: #282828;
  display: flex;
  justify-content: space-between;
  align-items: center;

  svg {
    position: relative;
    transition: transform 0.26s ease;
    transform: rotate(${({ active }) => (active ? '180' : '0')}deg);
  }
`;

export default styled(FilterHolder)`
  width: 100%;
  position: relative;
  margin-bottom: 8px;

  .filter-content {
    background: #faf2e3;
    border-bottom-right-radius: 14px;
    border-bottom-left-radius: 14px;
    overflow: hidden;
    max-height: 0;
    padding: 0;
    transition: max-height 0.26s ease, padding 0.26s ease;

    &.active {
      max-height: 800px;
      padding: 11px 0;
    }
  }
`;
