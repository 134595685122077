import React, { useEffect, useState } from 'react';
import styled from 'styled-components';

import { ReactComponent as FlechaArriba } from './FlechaArriba.svg';
import { ReactComponent as Itau } from './itauMobile.svg';

import { Steps } from '../../types';
import { Stepper } from '../Stepper';
import { Title } from 'Containers/AirCheckout/styles';
import { BillingFields } from '../BillingFields';
import { PaymentStep } from '../PaymentStep';
import { useSelector } from 'react-redux';
import { useFormContext } from 'react-hook-form';
import {
  ButtonsHolder,
  Container,
  DetailItau,
  DetailLocal,
  DetailPerse,
  DetailsHolder,
  DetalleButton,
  LocalRow1,
  LocalRow2,
  LocalRow3,
  NavButton,
  OrangeHeader,
  StickyBottom
} from './styles';
import { SideDetails } from '../SideDetails';

interface Props {
  activeStep: Steps;
  onRequest: ({
    selectedInterventajasPoints,
    selectedMembershipPoints,
    moneyToComplete,
    pointsToRender
  }) => void;
  onPreviousStep: () => void;
  onNextStep: () => void;
  priceToTransaction: number;
  description: string;
  priceUSD: number;
}

const formatMoney = value => value.toLocaleString('en').replaceAll(',', '.');

export const PaymentLinkMobile = ({
  activeStep,
  onRequest,
  onNextStep,
  onPreviousStep,
  priceToTransaction,
  priceUSD,
  description
}: Props) => {
  const {
    selectedItauPoints,
    itauPoints: { PYG }
  } = useSelector(
    // @ts-ignore
    state => state.appConfiguration
  );

  const [showDetalle, setShowDetalle] = useState(false);
  const toggleShowDetalle = () => setShowDetalle(prevState => !prevState);

  const costCurrencyInPoints = PYG;
  const priceInPoints = priceToTransaction * costCurrencyInPoints;
  const moneyInPoints = selectedItauPoints / costCurrencyInPoints;
  const moneyToComplete =
    Math.ceil((priceToTransaction - moneyInPoints) * 100) / 100;
  const pointsToRender =
    selectedItauPoints <= priceInPoints
      ? selectedItauPoints.toLocaleString('en').replaceAll(',', '.')
      : priceInPoints.toLocaleString('en').replaceAll(',', '.');

  const { setValue } = useFormContext();

  return (
    <Container>
      <OrangeHeader>COMPLETÁ TUS DATOS Y FINALIZÁ TU COMPRA</OrangeHeader>
      <Stepper activeStep={activeStep} />
      {activeStep === Steps.billingData && (
        <>
          <Title>Datos de facturación</Title>
          <BillingFields />
        </>
      )}

      {activeStep === Steps.payment && (
        <PaymentStep
          onRequest={onRequest}
          priceToTransaction={priceToTransaction}
        />
      )}

      <StickyBottom>
        <DetalleButton onClick={toggleShowDetalle}>
          Detalle <FlechaArriba />
        </DetalleButton>

        <DetailPerse show={showDetalle}>
          <SideDetails
            isCheckoutMobile
            priceToTransaction={priceToTransaction}
            description={description}
          />
        </DetailPerse>

        <DetailsHolder>
          <DetailLocal>
            <LocalRow1>Precio total final</LocalRow1>
            <LocalRow2>USD {priceUSD}</LocalRow2>
            <LocalRow3>(PYG {formatMoney(priceToTransaction)})</LocalRow3>
          </DetailLocal>

          <DetailItau>
            <div>
              ó <span>{pointsToRender}</span> puntos <Itau />
            </div>
            <div>
              + <span>PYG {moneyToComplete}</span>
            </div>
          </DetailItau>
        </DetailsHolder>

        <ButtonsHolder>
          {activeStep !== Steps.paxData ? (
            <NavButton onClick={onPreviousStep}>Atrás</NavButton>
          ) : (
            <div />
          )}

          {activeStep !== Steps.payment ? (
            <NavButton onClick={onNextStep}>Siguiente</NavButton>
          ) : (
            <div />
          )}
        </ButtonsHolder>
      </StickyBottom>
    </Container>
  );
};
