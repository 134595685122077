/**
 *
 * DesktopHotelDetails
 *
 */

// Vendors
import React, { Fragment, useState, useMemo } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import cs from 'classnames';
import { Element, scroller } from 'react-scroll';

// Components
import HotelHeader from '../HotelHeader';
import Card from 'Components/Card';
import MenuTab from 'Components/MenuTab';
import Button from 'Components/Button';
import Icon from 'Components/Icon';
import Text from 'Components/Text';
import TextHeader from 'Components/TextHeader';
import Divider from 'Components/Divider';
import Checkbox from 'Components/Checkbox';
import Radio from 'Components/Radio';
import GMap from 'Components/GMap';
import HotelServices from '../HotelServices';
import Modal from 'Components/Modal';
import HotelDetailFareBlock from '../HotelDetailFareBlock';
import HotelDetailReservation from '../HotelDetailReservation';
import HotelsGoogleComments from '../HotelsGoogleComments';
import { ReactComponent as HotelIcon } from 'Assets/icons/mv-hotel-timeout.svg';

// Constants
import { hotelPropertyPriceTypes } from 'Constants';
import * as COLORS from 'Constants/colors';
import ErrorPortal from 'Components/ErrorPortal';

const tabValues = {
  information: true,
  rates: false,
  opinions: false,
  map: false
};

const scrollType = {
  duration: 600,
  delay: 100,
  smooth: true, // linear “easeInQuint” “easeOutCubic”
  offset: -110
};

// I'm duplicating because I'm not sure how reusable is this
const shouldShowMore = descriptions => {
  let chars = 0;

  descriptions.forEach(d => {
    if (d.paragraph && d.title) {
      chars += d.paragraph.length + d.title.length;
    }
  });

  return chars > 350;
};

const DesktopHotelDetails = ({
  className,
  ratePackagesRooms,
  name,
  images,
  starRating,
  keyRating,
  textRating,
  checkIn,
  checkOut,
  initialCenter,
  facilities,
  descriptions,
  detailRequest,
  handleClickReservation,
  address,
  reviews,
  closeError,
  checkRateError,
  accommodationCode
}) => {
  const [tabActive, setTabActive] = useState(tabValues);
  const [selectedFare, setSelectedFare] = useState(
    ratePackagesRooms &&
      ratePackagesRooms[0] &&
      ratePackagesRooms &&
      ratePackagesRooms[0].packages &&
      ratePackagesRooms[0].packages[0] &&
      ratePackagesRooms[0].packages[0]
  );
  const [breakfastIncluded, setBreakfastIncluded] = useState(false);
  const [freeCancellation, setFreeCancellation] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [priceType, setPriceType] = useState(hotelPropertyPriceTypes.PER_NIGHT);
  const [roomInformation, setRoomInformation] = useState();

  const onActiveTab = tabName => {
    const newTabValuesInFalse = {
      ...Object.keys(tabValues).reduce(
        (reduced, key) => ({ ...reduced, [key]: false }),
        {}
      )
    };
    const newTabValues = Object.assign(
      newTabValuesInFalse,
      (newTabValuesInFalse[tabName] = true)
    );
    setTabActive(newTabValues);
    scroller.scrollTo(`${tabName}-hotel`, scrollType);
  };

  const getRooms = () => {
    if (ratePackagesRooms) {
      if (freeCancellation && breakfastIncluded) {
        return ratePackagesRooms.filter(
          room => room.hasFreeCancellationOpt && room.hasBreakfastOpt
        );
      } else if (freeCancellation) {
        return ratePackagesRooms.filter(room => room.hasFreeCancellationOpt);
      } else if (breakfastIncluded) {
        return ratePackagesRooms.filter(room => room.hasBreakfastOpt);
      }
      return ratePackagesRooms;
    }
    return null;
  };

  const rooms = getRooms();

  const limit = 4;

  const [showMoreRooms, setShowMoreRooms] = useState(false);
  const [showMoreDetails, setShowMoreDetails] = useState(false);
  const hasMoreToShow = rooms && rooms.length > limit;
  const hasMoreDetails = useMemo(() => shouldShowMore(descriptions), [
    descriptions
  ]);

  const onShowMoreRooms = () => setShowMoreRooms(old => !old);
  const onShowMoreDetails = () => setShowMoreDetails(old => !old);

  const toggleShowModal = () => {
    setShowModal(!showModal);
  };

  const getPackages = packages => {
    if (packages) {
      if (freeCancellation && breakfastIncluded) {
        return packages.filter(
          item => item.hasFreeCancellation && item.hasBreakfast
        );
      } else if (freeCancellation) {
        return packages.filter(item => item.hasFreeCancellation);
      } else if (breakfastIncluded) {
        return packages.filter(item => item.hasBreakfast);
      }
      return packages;
    }
  };

  const handleSelectFare = fareItem => {
    setSelectedFare(fareItem);
  };

  const handleToggleBreakfastIncluded = () => {
    setBreakfastIncluded(!breakfastIncluded);
  };

  const handleToggleFreeCancellation = () => {
    setFreeCancellation(!freeCancellation);
  };

  return (
    <>
      <ErrorPortal
        show={checkRateError}
        onClose={closeError}
        buttonLabel="Volver al Listado"
        icon={<HotelIcon />}
        message="Por favor, volvé al listado a buscar tu hotel."
        title="Lo sentimos, el hotel no nos ha devuelto disponibilidad para sus habitaciones."
      />
      <Modal
        show={showModal}
        onClickOverlay={toggleShowModal}
        modalWidth={790}
        transparent>
        <div style={{ marginTop: 50, marginBottom: 50, width: 790 }}>
          <HotelServices
            images={images}
            facilitiesGroup={facilities.facilitiesGroup}
            name={name}
            close={toggleShowModal}
          />
        </div>
      </Modal>
      <div className={cs(className)}>
        <div className="container">
          <HotelHeader
            name={name}
            image1={images[0] && images[0].imageUrl}
            image2={images[1] && images[1].imageUrl}
            image3={images[2] && images[2].imageUrl}
            checkin={checkIn ? checkIn : 'not set'}
            checkout={checkOut ? checkOut : 'not set'}
            starRating={starRating}
            keyRating={keyRating}
            textRating={textRating}
            accommodationCode={accommodationCode}
            onShowServices={toggleShowModal}
            address={address}
            facilities={facilities.facilitiesIcons}
          />
          <Card>
            <Divider context="light" />

            <div className="row d-flex align-items-stretch content-information">
              <div className="col-9">
                <div className="anchor-holder">
                  <ListItems>
                    <MenuTab.Item
                      className="item"
                      onClick={() => {
                        onActiveTab('information');
                      }}>
                      <Icon
                        name="mv-information-circle"
                        size={20}
                        color="important"
                      />
                      <span className="item-text">Información</span>
                    </MenuTab.Item>
                    <MenuTab.Item
                      className="item"
                      onClick={() => {
                        onActiveTab('rates');
                      }}>
                      <Icon
                        name="mv-price-circle"
                        size={20}
                        color="important"
                      />
                      <span className="item-text">Tarifas y habitaciones</span>
                    </MenuTab.Item>
                    {reviews && (
                      <MenuTab.Item
                        className="item"
                        color="positive"
                        onClick={() => {
                          onActiveTab('opinions');
                        }}>
                        <Icon name="mv-globo" size={20} color="important" />
                        <span className="item-text">Opiniones</span>
                      </MenuTab.Item>
                    )}
                    <MenuTab.Item
                      className="item"
                      color="purple"
                      active={tabActive.map}
                      onClick={() => {
                        onActiveTab('map');
                      }}>
                      <Icon name="mv-location-1" size={20} color="important" />
                      <span className="item-text">Mapa</span>
                    </MenuTab.Item>
                  </ListItems>
                </div>
                {/* RATES HOTEL */}
                <Element name="rates-hotel">
                  {/* INFORMATION TAB */}
                  <Element name="information-hotel">
                    <div className="row">
                      <div className="col-12 hotel-info">
                        <Information>Información</Information>

                        <TextInformation
                          paragraph
                          className={cs('hotel-descr', {
                            'show-more': showMoreDetails
                          })}>
                          {descriptions.map((desc, index) => (
                            <Fragment key={index}>
                              <b>{desc.title}</b>
                              <br />
                              {desc.paragraph}
                              <br />
                              <br />
                            </Fragment>
                          ))}
                        </TextInformation>

                        {hasMoreDetails && (
                          <div style={{ textAlign: 'center', padding: 12 }}>
                            <Button onClick={onShowMoreDetails} size="sm">
                              {showMoreDetails ? 'Ver menos' : 'Ver más'}
                            </Button>
                          </div>
                        )}
                      </div>
                    </div>
                  </Element>
                  <div className="hotel-fares-title">
                    Tarifas y habitaciones
                  </div>
                  {!rooms.length && <div>No se encontraron habitaciones.</div>}
                  <div className="filters-holder">
                    <Title context="important" weight="semi-bold">
                      Mostrar s&oacute;lo habitaciones con:
                    </Title>
                    <div className="content-items">
                      <div className="filter-item">
                        <Checkbox
                          onClick={handleToggleBreakfastIncluded}
                          checked={breakfastIncluded}>
                          Desayuno Incluido
                        </Checkbox>
                      </div>
                      <div className="filter-item">
                        <Checkbox
                          onClick={handleToggleFreeCancellation}
                          checked={freeCancellation}>
                          Cancelaci&oacute;n Gratis
                        </Checkbox>
                      </div>
                      <div className="filter-item">
                        <Radio
                          checked={
                            priceType === hotelPropertyPriceTypes.PER_NIGHT
                          }
                          value={hotelPropertyPriceTypes.PER_NIGHT}
                          onClick={setPriceType}>
                          Precio por noche
                        </Radio>
                      </div>
                      <div className="filter-item">
                        <Radio
                          checked={
                            priceType === hotelPropertyPriceTypes.PER_STAY
                          }
                          value={hotelPropertyPriceTypes.PER_STAY}
                          onClick={setPriceType}>
                          Total por estancia
                        </Radio>
                      </div>
                    </div>
                  </div>
                  {rooms.length > 0 &&
                    rooms.map((room, index) => (
                      <HotelDetailFareBlock
                        className={cs({
                          hidden: index > limit - 1 && !showMoreRooms
                        })}
                        nameRoom={room.nameRoom}
                        activeId={selectedFare.packageId}
                        key={index}
                        packages={getPackages(room.packages)}
                        priceType={priceType}
                        images={room.imagesUrl}
                        onSelectFare={handleSelectFare}
                      />
                    ))}

                  {hasMoreToShow && (
                    <Button onClick={onShowMoreRooms} context="information">
                      {showMoreRooms
                        ? 'Ver menos habitaciones'
                        : 'Ver más habitaciones'}
                    </Button>
                  )}
                </Element>

                {/* OPINIONS HOTEL */}
                {reviews && (
                  <Element name="opinions-hotel" className="content-reviews">
                    <div className="section-header">
                      <div className="text-icon">
                        <Valoration weight="semi-bold">
                          Valoración de viajeros
                        </Valoration>
                      </div>
                    </div>

                    <div className="separator" />

                    <HotelsGoogleComments
                      reviews={reviews}></HotelsGoogleComments>
                  </Element>
                )}

                {/* MAP HOTEL */}
                <Element name="map-hotel">
                  <div className="section-header">
                    <div className="text-icon">
                      <Icon name="mv-location-1" size={20} color="secondary" />
                      <Text weight="semi-bold">Mapas</Text>
                    </div>
                  </div>

                  <div className="separator" />

                  <GMap initialCenter={initialCenter} markerName={name} />
                </Element>
              </div>

              <div className="col">
                <div className="sticky">
                  {/* {rooms.length > 0 &&
                    rooms.map(room => setRoomInformation(room.packages))} */}
                  <HotelDetailReservation
                    packageRoom={selectedFare}
                    request={detailRequest}
                    checkIn={checkIn}
                    checkOut={checkOut}
                    onClick={handleClickReservation}
                    packages={getPackages}
                  />
                </div>
              </div>
            </div>
          </Card>
        </div>
      </div>
    </>
  );
};

DesktopHotelDetails.propTypes = {
  className: PropTypes.string.isRequired,
  images: PropTypes.array,
  name: PropTypes.string,
  starRating: PropTypes.number,
  keyRating: PropTypes.number,
  textRating: PropTypes.string,
  checkIn: PropTypes.string,
  checkOut: PropTypes.string,
  descriptions: PropTypes.array,
  initialCenter: PropTypes.object,
  ratePackagesRooms: PropTypes.array,
  facilities: PropTypes.object,
  detailRequest: PropTypes.object,
  handleClickReservation: PropTypes.func,
  address: PropTypes.string,
  reviews: PropTypes.shape({
    amountReviews: PropTypes.number,
    rating: PropTypes.number,
    ratingText: PropTypes.string,
    results: PropTypes.arrayOf(
      PropTypes.shape({
        author_name: PropTypes.string,
        author_url: PropTypes.string,
        id: PropTypes.string,
        language: PropTypes.string,
        profile_photo_url: PropTypes.string,
        rating: PropTypes.number,
        ratingText: PropTypes.string,
        relative_time_description: PropTypes.string,
        text: PropTypes.string,
        time: PropTypes.number
      })
    )
  }).isRequired,
  closeError: PropTypes.func,
  checkRateError: PropTypes.string,
  accommodationCode: PropTypes.string
};

export default styled(DesktopHotelDetails)`
  display: block;

  .hidden {
    display: none;
  }
  .content-reviews {
    margin-top: 70px;
  }
  .content-information {
    border-radius: 10px;
  }
  .image-item {
    width: 100%;
  }

  .anchor-holder {
    display: flex;
    width: 834px;
    height: 60px;
    border-radius: 10px 10px 0 0;
    justify-content: space-between;
  }

  .button-icon-content {
    display: flex;
    align-items: center;

    .icon {
      margin-right: 15px;
    }
  }

  .menu-tab {
    width: 100%;
    justify-content: center;
    align-items: center;
    box-shadow: 0 3px 7px 0 rgba(0, 0, 0, 0.35);
  }

  .description-paragraph {
    margin-bottom: 20px;
  }

  .filters-holder {
    display: flex;
    flex-direction: column;
    margin: 10px 0;
    justify-content: center;
  }
  .content-items {
    display: flex;
    margin-bottom: 32px;
  }
  .filter-item {
    margin-left: 16px;
    color: rgba(40, 40, 40, 0.5);
    font-family: Itau Display Pro;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
  }

  .hotel-fares-title {
    color: #282828;
    font-family: Itau Display Pro;
    font-size: 22px;
    font-style: normal;
    font-weight: 700;
    line-height: 30px;
  }

  .sticky {
    position: sticky;
    top: 92px;
    margin-left: 6px;
  }
  .item {
    display: flex;
    align-items: center;
    justify-content: center;
    .item-text {
      font-family: Itau Display Pro;
      height: 30px;
      font-size: 16px;
      font-weight: 400;
      line-height: 20px;
      letter-spacing: 0em;
      text-align: left;
      margin-left: 10px;
      color: #282828;
    }
    .item:hover {
      background-color: white;
    }
    span {
      align-items: flex-start;
    }
  }
  .btn-showmore {
    display: flex;
    width: 100%;
    justify-content: center;
    margin-bottom: 15px;
  }

  .hotel-info {
    .hotel-descr {
      overflow: hidden;

      /* Change this to variate closed height */
      max-height: 200px;
      transition: all 1s ease-in-out;

      &.show-more {
        display: block;
        max-height: 8000px;
      }
    }
  }

  .section-header {
    margin: 10px 14px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .text-icon {
      display: flex;

      .icon {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-right: 5px;
      }
    }
  }
`;

const Valoration = styled.span`
  color: #282828;
  font-family: Itau Display Pro;
  font-size: 22px;
  font-style: normal;
  font-weight: 700;
  line-height: 30px;
`;
const Title = styled.span`
  color: #fb8400;
  font-family: Itau Display Pro;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 150% */
`;
const Information = styled.h2`
  font-family: Itau Display Pro;
  width: 115px;
  height: 30px;
  font-size: 22px;
  font-weight: 700;
  line-height: 30px;
  letter-spacing: 0;
  margin-top: 20px;
  text-align: left;
`;

const TextInformation = styled.span`
  font-family: Itau Display Pro;
  width: 794px;
  height: 168px;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: left;
  margin-bottom: 20px;
`;

const ListItems = styled.ul`
  display: flex;
  width: 834px;
  height: 60px;
  padding: 0 20px 0 20px;
  border-radius: 10px 10px 0 0;
  box-shadow: 0px 4px 8px 0px #00000033;
`;
