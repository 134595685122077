import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Calendar from 'Components/NewCalendar';
import PaxDetailAir from '../PaxDetailAir';
import Checkbox from 'Components/NewCheckbox';
import { formatIso, getMaxFlightDate } from '../../../../Utils/dates';
import DestinationFlight, { locationTypes } from 'Components/DestinationFlight';
import NewText from 'Components/NewText';
import { CheckboxContainer } from 'Features/flights/components/RoundTrip/RoundTrip';

const OneWay = ({
  isMPC,
  knowDestination,
  initialDate,
  dateMessages,
  minDateToStartSearch,
  actions,
  isCurrentViewDestinations
}) => (
  <>
    <div className="mv-buscador-line-details">
      <div>
        <div className="mv-buscador-locations">
          <DestinationFlight type={locationTypes.ORIGIN} />
          <DestinationFlight type={locationTypes.DESTINATION} right />
        </div>
        {!isCurrentViewDestinations && (
          <CheckboxContainer>
            <Checkbox
              name="know-destination"
              checked={knowDestination}
              color="orange"
              addMargin
              onChange={actions.setKnowDestination}
            />
            <NewText type="small">Conocer más del destino</NewText>
          </CheckboxContainer>
        )}
      </div>
      <div className="pax-calendar-holder">
        <CalendarWrapper>
          <Calendar
            showLabels
            startDate={initialDate}
            onChangeStart={actions.setInitialDate}
            startLabel="Fecha de partida"
            minDate={minDateToStartSearch}
            maxDate={formatIso(getMaxFlightDate())}
            color={{ primaryColor: 'orange', lightColor: 'orangeLight' }}
            startError={{
              message: dateMessages.length > 0 && dateMessages[0]
            }}
          />
          <CheckboxContainer>
            <Checkbox
              checked={isMPC}
              name="close-dates"
              addMargin
              onChange={actions.toggleMPC}
              color="orange"
            />
            <NewText type="small"> Buscar fechas cercanas</NewText>
          </CheckboxContainer>
        </CalendarWrapper>
        <PaxDetailAir />
      </div>
    </div>
  </>
);

OneWay.propTypes = {
  validationMessages: PropTypes.object,
  isMPC: PropTypes.bool,
  knowDestination: PropTypes.bool.isRequired,
  initialDate: PropTypes.string.isRequired,
  minDateToStartSearch: PropTypes.string,
  actions: PropTypes.shape({
    toggleMPC: PropTypes.func.isRequired,
    setKnowDestination: PropTypes.func.isRequired,
    setInitialDate: PropTypes.func.isRequired
  }),
  dateMessages: PropTypes.array,
  isCurrentViewDestinations: PropTypes.bool
};

export default OneWay;

export const CalendarWrapper = styled.div`
  margin-right: ${({ theme }) => theme.spacing.two};
  flex-shrink: 0;
`;
