export const initialState = {
  flightsConfig: {},
  hotelsConfig: {},
  itauPoints: {},
  packagesConfig: {},
  paymentsConfig: {},
  sharedConfig: {},
  rate: {},
  localize: {},
  sites: [],
  engines: [],
  loader: false,
  pending: false,
  navBarSmallMode: false,
  hideNavbar: false,
  errorBoundaryKey: 0,
  maxItauPuntos: 130000,
  selectedItauPoints: 5000
};
