import ACTION_TYPES from './actionTypes';
import { initialState } from './constants';

export default (state = initialState, action) => {
  switch (action.type) {
    case ACTION_TYPES.GET_APP_CONFIGURATION_REQUEST:
      return {
        ...state,
        pending: true
      };

    case ACTION_TYPES.GET_APP_CONFIGURATION_SUCCESS:
      return {
        ...state,
        ...action.payload,
        pending: false
      };

    case ACTION_TYPES.GET_APP_CONFIGURATION_FAILURE:
      return {
        ...state,
        pending: false
      };

    case ACTION_TYPES.LOCALIZE_REQUEST:
      return {
        ...state
      };

    case ACTION_TYPES.LOCALIZE_SUCCESS:
      return {
        ...state,
        localize: action.payload
      };

    case ACTION_TYPES.LOCALIZE_FAILURE:
      return {
        ...state
      };

    case ACTION_TYPES.UPDATE_LOADER:
      return {
        ...state,
        loader: action.payload
      };

    case ACTION_TYPES.SET_HIDE_NAVBAR:
      return {
        ...state,
        hideNavbar: action.payload
      };

    case ACTION_TYPES.SET_NAVBAR_SMALL_MODE:
      return {
        ...state,
        navBarSmallMode: action.payload
      };

    case ACTION_TYPES.CLEAN_ERROR_BOUNDARY:
      return {
        ...state,
        errorBoundaryKey: state.errorBoundaryKey + 1
      };

    case ACTION_TYPES.UPDATE_ITAU_POINTS:
      return {
        ...state,
        selectedItauPoints: action.payload
      };

    default:
      return state;
  }
};
