/**
 *
 * RatingMolecule
 *
 */

import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import cs from 'classnames';
import RatingBox from '../RatingBox';
import Text from '../Text';

const RatingMolecule = ({
  className,
  rating,
  amountReviews,
  ratingText,
  inline
}) => (
  <div className={cs(className)}>
    <RatingBox>{parseFloat(rating).toFixed(2)}</RatingBox>
    <div className={cs('text-data', { inline })}>
      <span className="text-rating">{ratingText}</span>
      {amountReviews && (
        <Text size="small" context="default" className="margin-left">
          {`${amountReviews} opiniones`}
        </Text>
      )}
    </div>
  </div>
);

RatingMolecule.propTypes = {
  className: PropTypes.string,
  rating: PropTypes.number,
  amountReviews: PropTypes.number,
  ratingText: PropTypes.string,
  inline: PropTypes.bool
};

export default styled(RatingMolecule)`
  display: flex;
  align-items: center;
  gap: 12px;

  .text-data {
    display: flex;
    flex-direction: column;

    .text-rating {
      font-family: Itau Display Pro;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 22px;
      color: #fb8400;
    }

    &.inline {
      width: 100%;
      flex-direction: row;
      margin: 10;

      .margin-left {
        margin-left: 5px;
      }
    }
  }
`;
