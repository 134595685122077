export default api => ({
  psf: async params =>
    await api.post(`/checkout/${params.engineId}/paymentmethods`, {
      ...params
    }),
  pay: async (engineId = 1, params) =>
    await api.post(`/checkout/${engineId}/pay`, {
      ...params
    }),
  confirmStatus: async (engineId = 6, processId) =>
    await api.post('bancard/payment/confirm/status', {
      shopProcessId: Number(processId), // el shopProcessId que llego por la url de redirección
      engine: engineId // engine en base a la url si es /hotels/check-payment/${shopProcessId} es el engine de hotel (6)
    }),
  confirmPaymentStatusAtc: async reference =>
    await api.get('/atc/payment/confirm/status/' + reference),
  conditionOwnfinancing: async (engineId, conditionTypeId) =>
    await api.get(`/ownfinancing/conditions/${engineId}/${conditionTypeId}`)
});
