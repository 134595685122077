import React from 'react';
import { useSelector } from 'react-redux';
import { Breakpoint } from 'react-socks'

import countries from 'Components/InvoiceForm/countries';
import { billingDocs } from 'Constants';

import { CommonHolder as Holder, FormRow } from '../Common';
import { FormInput } from '../FormInput';
import { FormSelect } from '../FormSelect';


export const BillingFields = () => {
  const { personTypes } = useSelector(
    // @ts-ignore
    ({ appConfiguration: { sharedConfig } }) => sharedConfig
  );

  return (
    <Holder>
      <Breakpoint small down>
        <FormRow>
          <FormSelect
            name="personType"
            label="Situación fiscal"
            options={personTypes || []}
            registerOptions={{
              required: true
            }}
          />
        </FormRow>
        <FormRow>
          <FormInput
            name="firstName"
            label="Nombre / Razón social"
            placeholder="Como figura en su documento de identidad"
            registerOptions={{
              required: true
            }}
          />
        </FormRow>
        <FormRow>
          <FormInput
            name="lastName"
            label="Apellido / Razón social"
            placeholder="Como figura en su documento de identidad"
            registerOptions={{
              required: true
            }}
          />
        </FormRow>

        <FormRow>
          <FormSelect
            name="docType"
            label="Tipo de documento"
            options={billingDocs || []}
            registerOptions={{
              required: true
            }}
          />
        </FormRow>
        <FormRow>
          <FormInput
            name="document"
            label="Número de documento"
            placeholder="Ej: 17 444 222"
            registerOptions={{
              required: true
            }}
          />
        </FormRow>
        <FormRow>
          <FormInput
            name="email"
            label="E-mail"
            placeholder="Ej: acme@mevuelo.com"
            registerOptions={{
              required: true,
              pattern: {
                value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                message: 'Mail Invalido'
              }
            }}
          />
        </FormRow>

        <FormRow>
          <FormSelect
            name="nationality"
            label="Nacionalidad"
            options={countries.map(country => ({
              id: country.plCitizenCode,
              name: country.name
            }))}
            registerOptions={{
              required: true
            }}
          />
        </FormRow>
        <FormRow>
          <FormSelect
            name="countryCode"
            label="Codigo de area"
            options={countries.map(country => ({
              id: country.phoneCode,
              name: `${country.name} (+${country.phoneCode})`
            }))}
            registerOptions={{
              required: true
            }}
          />
        </FormRow>
        <FormRow>
          <FormInput
            name="phone"
            label="Telefono"
            registerOptions={{
              required: true
            }}
          />
        </FormRow>
        <FormRow>
          <FormInput
            name="address"
            label="Direccion"
            registerOptions={{
              required: true
            }}
          />
        </FormRow>
        <FormRow>
          <FormSelect
            name="country"
            label="Pais"
            options={countries.map(country => ({
              id: country.code,
              name: country.name
            }))}
            registerOptions={{
              required: true
            }}
          />
        </FormRow>
        <FormRow>
          <FormInput
            name="city"
            label="Ciudad"
            registerOptions={{
              required: true
            }}
          />
        </FormRow>
        <FormRow>
          <FormInput
            name="state"
            label="Estado"
            registerOptions={{
              required: true
            }}
          />
        </FormRow>
      </Breakpoint>

      <Breakpoint medium up>
        <FormRow>
          <div style={{ width: 380 }}>
            <FormSelect
              name="personType"
              label="Situación fiscal"
              options={personTypes || []}
              registerOptions={{
                required: true
              }}
            />
          </div>

          <FormInput
            name="firstName"
            label="Nombre / Razón social"
            placeholder="Como figura en su documento de identidad"
            registerOptions={{
              required: true
            }}
          />
          <FormInput
            name="lastName"
            label="Apellido / Razón social"
            placeholder="Como figura en su documento de identidad"
            registerOptions={{
              required: true
            }}
          />
        </FormRow>
        <FormRow>
          <div style={{ width: 380 }}>
            <FormSelect
              name="docType"
              label="Tipo de documento"
              options={billingDocs || []}
              registerOptions={{
                required: true
              }}
            />
          </div>
          <FormInput
            name="document"
            label="Número de documento"
            placeholder="Ej: 17 444 222"
            registerOptions={{
              required: true
            }}
          />
          <FormInput
            name="email"
            label="E-mail"
            placeholder="Ej: acme@mevuelo.com"
            registerOptions={{
              required: true,
              pattern: {
                value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                message: 'Mail Invalido'
              }
            }}
          />
        </FormRow>
        <FormRow>
          <FormRow>
            <FormSelect
              name="nationality"
              label="Nacionalidad"
              options={countries.map(country => ({
                id: country.plCitizenCode,
                name: country.name
              }))}
              registerOptions={{
                required: true
              }}
            />

            <FormSelect
              name="countryCode"
              label="Codigo"
              options={countries.map(country => ({
                id: country.phoneCode,
                name: `${country.name} (+${country.phoneCode})`
              }))}
              registerOptions={{
                required: true
              }}
            />
          </FormRow>
          <FormInput
            name="phone"
            label="Telefono"
            registerOptions={{
              required: true
            }}
          />
        </FormRow>
        <FormInput
          name="address"
          label="Direccion"
          registerOptions={{
            required: true
          }}
        />

        <FormRow>
          <FormSelect
            name="country"
            label="Pais"
            options={countries.map(country => ({
              id: country.code,
              name: country.name
            }))}
            registerOptions={{
              required: true
            }}
          />
          <FormInput
            name="city"
            label="Ciudad"
            registerOptions={{
              required: true
            }}
          />

          <FormInput
            name="state"
            label="Estado"
            registerOptions={{
              required: true
            }}
          />
        </FormRow>
      </Breakpoint>
    </Holder>
  );
};
