import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useCombobox } from 'downshift';
import Icon from 'Components/Icon';
import { searchDebounceTime } from 'Constants';
import API from 'Services';
import spinner from 'Assets/loader/spinner.svg';
import Label from 'Components/Label';
// import Input from 'Components/NewInput';
import marker from 'Assets/marker.svg';
import { useDebounceState } from 'hooks/useDebouncedState';

interface DestinationHotelState {
  cities: Suggestion[];
  pending: boolean;
  hasSearched: boolean;
  cache: {};
}

interface DestinationHotelActions {
  setLocation: (suggestion: Suggestion) => void;
}

export interface DestinationHotelProps {
  actions: DestinationHotelActions;
  location: Partial<Suggestion>;
  label: string;
  placeHolder: string;
  messages: string[];
}

const initialState: DestinationHotelState = {
  cities: [],
  pending: false,
  hasSearched: false,
  cache: {}
};

interface Suggestion {
  placeId: string;
  description: string;
  iconType: string;
}

const Container = styled.div`
  display: block;
  position: relative;
  width: 100%;
`;

const InputContainer = styled.div`
  position: relative;
`;

interface LoaderProps {
  loading?: boolean;
}

const Loader = styled.img<LoaderProps>`
  position: absolute;
  opacity: 0;
  top: 0;
  bottom: 0;
  margin: auto 0;
  right: 10px;
  width: 22px;
  height: 22px;
  transition: opacity 200ms ease-in-out;
  ${({ loading }) => loading && 'opacity: 1'};
  z-index: 2;
`;

const SuggestionList = styled.div`
  width: 100%;
  position: absolute;
  right: 0;
  left: 0;
  max-height: 200px;
  background-color: ${({ theme }) => theme.colors.white};
  z-index: 20;
  box-shadow: ${({ theme }) => theme.shadows.one};
  overflow-y: auto;
  top: 100%;
  border-bottom-left-radius: 14px;
  border-bottom-right-radius: 14px;
  ${({ theme }) => theme.scrollbar(theme.colors.important)}
`;

interface SuggestionProps {
  selected?: boolean;
}

const SuggestionItem = styled.div<SuggestionProps>`
  display: flex;
  padding: ${({ theme }) => theme.spacing.two};
  color: ${({ theme }) => theme.colors.meVueloGrey};
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-family: ${({ theme }) => theme.text.fontFamily.default};
  font-size: 14px;
  font-weight: 500;
  &:hover {
    cursor: pointer;
    background: ${({ theme }) => theme.colors.lightGrey};
  }

  ${({ selected, theme }) =>
    selected && `background: ${theme.colors.lightGrey}`};
  .icon {
    margin-right: ${({ theme }) => theme.spacing.two};
  }
`;

const InputHolder = styled.div`
  height: 40px;
  background-color: ${({ theme }) => theme.colors.white};
  border-radius: 6px;
  padding: 10px 16px;
  box-sizing: border-box;
  box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.25);
  display: flex;
  gap: 8px;
`;

const Input = styled.input`
  background-color: ${({ theme }) => theme.colors.white};
  width: 100%;
  font-family: ${({ theme }) => theme.text.fontFamily.default};
  font-size: 14px;
  line-height: 22px;
  font-style: normal;
  font-weight: 300;
  color: #282828;

  z-index: 1;
  position: relative;
  border: none;
  text-overflow: ellipsis;
  display: flex;

  ::placeholder {
    /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: #282828;
    opacity: 1; /* Firefox */
  }

  :focus {
    border: none;
    outline: none;
  }
`;

const DestinationHotel = ({
  actions,
  location,
  label,
  placeHolder,
  messages
}: DestinationHotelProps) => {
  const [state, setState] = useState<DestinationHotelState>(initialState);
  const [searchValue, setSearchValue] = useState('');

  const fetchSuggestions = async (value: string) => {
    setState(prevState => ({ ...prevState, pending: true }));
    const { cache } = state;
    let data;
    if (cache[value]) {
      data = cache[value];
    } else {
      const response = await API.hotels.suggestions(value);
      data = response.data.predictions;
    }
    setState(prevState => ({
      ...prevState,
      cities: data,
      pending: false,
      hasSearched: true,
      cache: { ...prevState.cache, [value]: data }
    }));
  };

  const debouncedSearch: string = useDebounceState(searchValue, searchDebounceTime.DESKTOP);

  useEffect(() => {
    if (debouncedSearch.length < 3) return;
    fetchSuggestions(debouncedSearch);
  }, [debouncedSearch])

  const handleChange = ({ inputValue }): void => {
    if (inputValue.length < 3) return;
    setSearchValue(inputValue);
  };

  const onSelectSuggestion = (option: Suggestion) => {
    actions.setLocation(option);
  };

  const {
    isOpen,
    getLabelProps,
    getMenuProps,
    getInputProps,
    getComboboxProps,
    highlightedIndex,
    getItemProps
  } = useCombobox({
    items: state.cities,
    itemToString: item => item?.description,
    onInputValueChange: handleChange,
    onSelectedItemChange: changes => {
      if (changes.selectedItem) {
        onSelectSuggestion(changes.selectedItem);
      }
    },
    initialSelectedItem: { description: location.description } as Suggestion
  });

  const { cities, pending } = state;

  return (
    <Container>
      <Label color="orange" {...getLabelProps()}>
        {label}
      </Label>
      <InputContainer {...getComboboxProps()}>
        <InputHolder>
          <img src={marker} alt="marker" />
          <Input
            type="text"
            {...getInputProps()}
            placeholder={placeHolder}
            // defaultValue={locationName}
          />
        </InputHolder>
        {/* <Input
          type="text"
          {...getInputProps()}
          placeholder={placeHolder}
          defaultValue={location.description}
          icon={{ name: 'mv-location-2', color: 'orange', size: 20 }}
          error={{ message: messages.length && messages[0] }}
        /> */}
        {/* @ts-ignore */}
        <Loader loading={isOpen && pending} src={spinner} />
      </InputContainer>
      <SuggestionList {...getMenuProps()}>
        {isOpen &&
          !pending &&
          cities.length > 0 &&
          cities.map((city, index) => (
            <SuggestionItem
              key={city.placeId}
              selected={highlightedIndex === index}
              {...getItemProps({ item: city, index })}>
              <Icon name="mv-cities" themeColor="orange" />
              {city.description}
            </SuggestionItem>
          ))}
      </SuggestionList>
    </Container>
  );
};

export default DestinationHotel;
