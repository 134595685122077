import React from 'react';
import { useSelector } from 'react-redux';

import { ReactComponent as Itau } from '../../assets/Itau.svg';
import { ContentCard } from '../Common';

import {
  SideHeader,
  SideContent,
  Divider,
  BreakdownHolder,
  BreakdownTitle,
  BreakdownItem,
  BreakdownTotal,
  BreakdownItau
} from './styles';

const formatMoney = value => value.toLocaleString('en').replaceAll(',', '.');

interface Props {
  priceToTransaction?: number;
  hideDesgloce?: boolean;
  isCheckoutMobile?: boolean;
  description: string;
}

export const SideDetails = ({
  priceToTransaction,
  hideDesgloce,
  isCheckoutMobile,
  description
}: Props) => {
  const {
    selectedItauPoints,
    itauPoints: { PYG }
  } = useSelector(
    // @ts-ignore
    state => state.appConfiguration
  );

  const costCurrencyInPoints = PYG;
  const priceInPoints = priceToTransaction
    ? priceToTransaction * costCurrencyInPoints
    : 0;
  const moneyInPoints = selectedItauPoints / costCurrencyInPoints;
  const moneyToComplete = priceToTransaction
    ? Math.ceil(priceToTransaction - moneyInPoints)
    : 0;
  const pointsToRender =
    selectedItauPoints <= priceInPoints
      ? selectedItauPoints.toLocaleString('en').replaceAll(',', '.')
      : priceInPoints.toLocaleString('en').replaceAll(',', '.');

  return (
    <div>
      <ContentCard noPaddingBottom={isCheckoutMobile}>
        {!isCheckoutMobile && <SideHeader>DETALLE DE TU COMPRA</SideHeader>}
        <SideContent>
          {description}

          {priceToTransaction && !hideDesgloce && (
            <>
              <Divider />

              <BreakdownHolder>
                <BreakdownTitle>Desglose</BreakdownTitle>
                <Divider />
                <BreakdownTotal>
                  <span>TOTAL</span>
                  <span>PYG {formatMoney(priceToTransaction)}</span>
                </BreakdownTotal>

                {/* TODO: queda asi, no se si o van a volver a querer por ahora es pa lio y mucha charla. mejor q se vaya ¯\_(ツ)_/¯  */}
                {/* <BreakdownItau
                  style={{
                    width: '100%',
                    justifyContent: 'center',
                    display: 'flex',
                    gap: 4
                  }}>
                  <span>ó</span> {pointsToRender} puntos. <Itau /> + PYG{' '}
                  {moneyToComplete.toLocaleString('en').replaceAll(',', '.')}
                </BreakdownItau> */}
              </BreakdownHolder>
            </>
          )}
        </SideContent>
      </ContentCard>
    </div>
  );
};
