/**
 *
 * AirAvailability
 *
 */

// vendopr
import PropTypes from 'prop-types';
import { default as React, useState, useEffect, useMemo } from 'react';
import qs from 'query-string';
import { useDispatch, useSelector } from 'react-redux';
import { Breakpoint } from 'react-socks';
import { throttle } from 'lodash';
import { useLocation, useHistory } from 'react-router';


// redux
import { checkSelectionRequest, resetFilters } from 'Features/flights/actions';
import { updateLoader } from 'Features/app/actions';
import {
  checkError,
  checkHasMPCData,
  checkHasMPTBData
} from '../MpcMptbAvailability';
import AirAvailabilityDesktop from '../AirAvailabilityDesktop';
import AirAvailabilityMobile from '../AirAvailabilityMobile';
import { scaleTypes, baggageTypes, filterListTypes, engines, routes } from 'Constants';
import { Helmet } from 'react-helmet';

import { resetToEngine } from 'ReduxConfig/availability';
import { cleanSearch } from 'Features/flights';

import { PaymentPromoBanner } from '../../../../Components/PaymentPromoBanner';
import { useUser } from '../../../users/utils';
import { isCorpo } from '../../../../Services/Users/types';

const orderByFlightPrice = response =>
  response
    ? response.sort(
      (a, b) => a.flightPrice.totalPrice - b.flightPrice.totalPrice
    )
    : null;
const orderByFlightRecommendation = response =>
  response.sort(
    (a, b) =>
      b.containerOrderInfo.byRecommended.total -
      a.containerOrderInfo.byRecommended.total
  );
const orderByFastestFlight = response =>
  response.sort(
    (a, b) =>
      a.containerOrderInfo.byDuration.total -
      b.containerOrderInfo.byDuration.total
  );

const getMinPrice = data =>
  data && data.length > 0
    ? data.reduce(
      (min, p) =>
        p.flightPrice.totalPrice < min ? p.flightPrice.totalPrice : min,
      data[0].flightPrice.totalPrice
    )
    : 0;

const getMaxPrice = data =>
  data && data.length > 0
    ? data.reduce(
      (max, p) =>
        p.flightPrice.totalPrice > max ? p.flightPrice.totalPrice : max,
      data[0].flightPrice.totalPrice
    )
    : 0;

const parseResponse = (response, filters, isDestination = false) => {
  let toReturn = response;

  if (!(response && response.length > 0)) return false;

  // Apply Airport Change Filter

  if (filters.hasFlex) {
    toReturn = toReturn.filter(item => item.hasFlex);
  }

  if (!filters.airPortChange) {
    toReturn = toReturn.filter(
      item => item.filtersDataToApply[0].hasChangeAirport === false
    );
  }

  // Apply luggage filter
  switch (filters.luggageFilter) {
    case baggageTypes.maxCargo:
      toReturn = toReturn.filter(
        item => item.filtersDataToApply[0].hasBaggage === true
      );
      break;
    case baggageTypes.maxHand:
      toReturn = toReturn.filter(
        item => item.filtersDataToApply[0].hasBaggage === false
      );
      break;
    default:
    //
  }

  // Apply scales filter
  switch (filters.scales) {
    case scaleTypes.maxOne:
      toReturn = toReturn.filter(item => {
        const scalesParsed = item.filtersDataToApply[0].scalesNumbers.filter(
          scaleValue => scaleValue > 1
        );
        return scalesParsed.length === 0;
      });
      break;

    case scaleTypes.maxDirect:
      toReturn = toReturn.filter(item => !item.filtersDataToApply[0].hasScales);
      break;

    default:
    //
  }

  // Apply Price filter
  if (filters.fareFilter.minFare && filters.fareFilter.maxFare) {
    toReturn = toReturn.filter(
      item =>
        item.flightPrice.totalPrice > filters.fareFilter.minFare &&
        item.flightPrice.totalPrice < filters.fareFilter.maxFare
    );
  }

  //Apply airlines filter
  if (filters.airLinesFilter.length > 0) {
    toReturn = toReturn.filter(item => {
      let res = false;
      filters.airLinesFilter.forEach(filteredCode => {
        if (item.filtersDataToApply[0].airlines.includes(filteredCode)) {
          res = true;
        }
      });
      return res;
    });
  }
  //Apply alliances filter
  if (filters.alliancesFilter.length > 0) {
    toReturn = toReturn.filter(item => {
      let res = false;
      filters.alliancesFilter.forEach(filteredCode => {
        if (item.filtersDataToApply[0].alliances.includes(filteredCode)) {
          res = true;
        }
      });
      return res;
    });
  }

  //Apply serviceClass filter
  if (filters.serviceClassFilter.length > 0) {
    toReturn = toReturn.filter(item =>
      filters.serviceClassFilter.some(
        filteredCode => item.filtersDataToApply[0].cabin === filteredCode
      )
    );
  }

  switch (filters.resultListType) {
    case filterListTypes.convenientes:
      toReturn = orderByFlightRecommendation(toReturn);
      break;

    case filterListTypes.rapidos:
      toReturn = orderByFastestFlight(toReturn);
      break;

    default:
      toReturn = orderByFlightPrice(toReturn);
  }

  return isDestination ? toReturn.slice(0, 5) : toReturn;
};

const initialFilters = {
  resultListType: filterListTypes.convenientes,
  fareFilter: {
    minFare: 0,
    maxFare: 0
  },
  luggageFilter: baggageTypes.all,
  airLinesFilter: [],
  alliancesFilter: [],
  serviceClassFilter: [],
  scales: scaleTypes.all,
  airPortChange: true,
  hasFlex: false
};

const selector = ({ flights, availability, destinations }) => {
  return {
    hasMPCData: checkHasMPCData(flights),
    hasMPTBData: checkHasMPTBData(flights),
    hasError: checkError(flights),
    mpcAvailability: flights.mpcAvailability,
    selectionError: flights.availability.selectionError,
    availability:
      availability.engine === 'vuelos'
        ? flights.availability
        : flights.mptbAvailability,
    isDestination: destinations.active && destinations.status === 'ready',
    flightSearch: flights.search
  }
};

const AirAvailability = ({ className }) => {
  const {
    availability,
    isDestination,
    hasMPCData,
    hasError,
    hasMPTBData,
    mpcAvailability,
    selectionError,
    flightSearch
  } = useSelector(selector);
  const { isChecking, resetFilters: resetFiltersStatus, data } = availability;
  const { response, request, filtersData, orderPriceInfo } = data;
  const initialDate = request ? request.routesData[0].initialDate : '';
  const endDate = request ? request.routesData[0].endDate : '';
  const history = useHistory();

  const user = useUser();

  const { search } = useLocation();
  const params = qs.parse(search);
  if (params.sorting) {
    initialFilters.resultListType = params.sorting;
  }

  const minFare = useMemo(() => Math.floor(getMinPrice(response)), [response]);

  const maxFare = useMemo(() => Math.floor(getMaxPrice(response)), [response]);

  const currency = 'USD';
  const dispatch = useDispatch();

  const [filters, setFilters] = useState(initialFilters);
  const [showErrorModal, setShowErrorModal] = useState(false);

  const toggleErrorModal = () => setShowErrorModal(!showErrorModal);

  useEffect(() => {
    dispatch(updateLoader(false));
  }, [response]);

  useEffect(() => {
    if (resetFiltersStatus === true) {
      setFilters({
        resultListType: filterListTypes.convenientes,
        fareFilter: {
          minFare: 0,
          maxFare: 0
        },
        luggageFilter: baggageTypes.all,
        airLinesFilter: [],
        alliancesFilter: [],
        serviceClassFilter: [],
        scales: baggageTypes.all,
        airPortChange: true,
        hasFlex: false
      });
      dispatch(resetFilters(false));
    }
  }, [resetFiltersStatus]);
  useEffect(() => {
    if (selectionError) {
      dispatch(updateLoader(false));
      setShowErrorModal(true);
    }
  }, [selectionError]);

  const handleFareFilterChange = val => {
    setFilters({
      ...filters,
      fareFilter: {
        minFare: val[0],
        maxFare: val[1]
      }
    });
  };

  const handleFlexFilterChange = () => {
    setFilters(prev => ({ ...prev, hasFlex: !prev.hasFlex }));
  };

  const throttledHandler = handler =>
    throttle(handler, 200, { trailing: true });

  const handleClickListType = value => () => {
    // TODO If i update the URL the search will fire
    // dispatch(push({ search: qs.stringify({ ...params, sorting: value })}));
    setFilters({
      ...filters,
      resultListType: value
    });
  };

  const handleClickLuggageFilter = value => {
    setFilters({
      ...filters,
      luggageFilter: value
    });
  };

  const handleClickScalesFilter = value => {
    setFilters({
      ...filters,
      scales: value
    });
  };

  const handleAirlineFilterClick = value => {
    const newFilters = { ...filters };
    let airLinesFilter = newFilters.airLinesFilter;

    if (!airLinesFilter.includes(value)) {
      airLinesFilter.push(value);
    } else {
      airLinesFilter = airLinesFilter.filter(item => item !== value);
    }

    setFilters({
      ...filters,
      airLinesFilter
    });
  };

  const handleAllianceFilterClick = value => {
    const newFilters = { ...filters };
    let alliancesFilter = newFilters.alliancesFilter;

    if (!alliancesFilter.includes(value)) {
      alliancesFilter.push(value);
    } else {
      alliancesFilter = alliancesFilter.filter(item => item !== value);
    }
    setFilters({
      ...filters,
      alliancesFilter
    });
  };

  const handleServiceClassFilterClick = value => {
    const newFilters = { ...filters };
    let serviceClassFilter = newFilters.serviceClassFilter;
    if (!serviceClassFilter.includes(value)) {
      serviceClassFilter.push(value);
    } else {
      serviceClassFilter = serviceClassFilter.filter(item => item !== value);
    }
    setFilters({
      ...filters,
      serviceClassFilter
    });
  };

  const handleNoAirportChange = () => {
    const { airPortChange } = filters;
    setFilters({
      ...filters,
      airPortChange: !airPortChange
    });
  };

  const onSelectAvailability = (availability = null) => (
    selectedSegment,
    id
  ) => {

    // Check de que el cantidad de pax matchee con el buscador
    const result = {
      adultsCountOk: request.totalAdult === flightSearch.adults,
      childrenCountOk: flightSearch.children.length === (request.totalChildren + request.totalInfant + request.totalInfantWithSeat),
    }
    
    if (!result.adultsCountOk || !result.childrenCountOk) {
      alert('El itinerario no coincide con la informacion ingresada en el buscador.');
      goHome();
      history.push(routes.flights);
      return;
    }


    const segments = Object.keys(selectedSegment)
      .filter(key => selectedSegment[key].item)
      .map(key => ({
        segments: selectedSegment[key].item.segments
      }));

    const params = {
      selectedOption: {
        completeFlights: segments,
        validatingCarrier: availability.flightPrice.validatingCarrier
      },
      passengerInfo: {
        totalAdult: request.totalAdult,
        totalChildren: request.totalChildren,
        totalInfant: request.totalInfant,
        totalInfantWithSeat: request.totalInfantWithSeat
      }
    };

    dispatch(updateLoader(true));
    dispatch(
      checkSelectionRequest(
        params,
        availability,
        selectedSegment,
        id,
        isCorpo(user)
      )
    );
  };

  const goHome = () => {
    dispatch(resetToEngine(engines.FLIGHTS.name));
    dispatch(cleanSearch());
  };

  const parsedResponse = useMemo(
    () => parseResponse(response, filters, isDestination),
    [response, filters]
  );

  const fareSliderDetails = {
    minFare,
    maxFare,
    handleFareFilterChange,
    currency
  };

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>
          {' '}
          {`Resultado de Vuelos a ${request.routesData[0].destinationName} | Elegí en MeVuelo`}
        </title>
        <meta
          name="description"
          content={`Las mejores ofertas en vuelos baratos internacionales. Elegí MeVuelo para comprar tu pasaje de avión a ${request.routesData[0].destinationName}. Obtené los pasajes más baratos hoy!`}
        />
        <meta
          name="keywords"
          content={`vuelos baratos internacionales a ${request.routesData[0].destinationName}, comprar pasaje de avion a ${request.routesData[0].destinationName}, pasajes más baratos a ${request.routesData[0].destinationName}`}
        />
        <meta
          property="og:title"
          content={`Resultado de Vuelos a ${request.routesData[0].destinationName} | Elegí en MeVuelo`}
        />
        <meta
          property="og:description"
          content={`Las mejores ofertas en vuelos baratos internacionales. Elegí MeVuelo para comprar tu pasaje de avión a ${request.routesData[0].destinationName}. Obtené los pasajes más baratos hoy!`}
        />
      </Helmet>
      {/* <PaymentPromoBanner /> */}
      <Breakpoint small down>
        <AirAvailabilityMobile
          className={className}
          parseResponse={parsedResponse}
          handleClickListType={handleClickListType}
          filters={filters}
          fareSliderDetails={fareSliderDetails}
          handleClickLuggageFilter={handleClickLuggageFilter}
          filtersData={filtersData}
          handleClickScalesFilter={handleClickScalesFilter}
          handleAirlineFilterClick={handleAirlineFilterClick}
          handleFareFilterChange={throttledHandler(handleFareFilterChange)}
          handleAllianceFilterClick={handleAllianceFilterClick}
          handleServiceClassFilterClick={handleServiceClassFilterClick}
          handleFlexFilterChange={handleFlexFilterChange}
          onSelectAvailability={onSelectAvailability}
          request={request}
          initialDate={initialDate}
          endDate={endDate}
          isChecking={isChecking}
          handleNoAirportChange={handleNoAirportChange}
          showErrorModal={showErrorModal}
          toggleErrorModal={toggleErrorModal}
          orderPriceInfo={orderPriceInfo}
          hasMPCData={hasMPCData}
          hasError={hasError}
          hasMPTBData={hasMPTBData}
          mpcAvailability={mpcAvailability}
        />
      </Breakpoint>
      <Breakpoint medium up>
        <AirAvailabilityDesktop
          className={className}
          parseResponse={parsedResponse}
          handleClickListType={handleClickListType}
          filters={filters}
          fareSliderDetails={fareSliderDetails}
          handleClickLuggageFilter={handleClickLuggageFilter}
          filtersData={filtersData}
          handleClickScalesFilter={handleClickScalesFilter}
          handleAllianceFilterClick={handleAllianceFilterClick}
          handleAirlineFilterClick={handleAirlineFilterClick}
          handleFareFilterChange={throttledHandler(handleFareFilterChange)}
          handleServiceClassFilterClick={handleServiceClassFilterClick}
          handleFlexFilterChange={handleFlexFilterChange}
          onSelectAvailability={onSelectAvailability}
          request={request}
          initialDate={initialDate}
          endDate={endDate}
          isChecking={isChecking}
          handleNoAirportChange={handleNoAirportChange}
          showErrorModal={showErrorModal}
          toggleErrorModal={toggleErrorModal}
          isDestination={isDestination}
          orderPriceInfo={orderPriceInfo}
          hasMPCData={hasMPCData}
          hasError={hasError}
          hasMPTBData={hasMPTBData}
          mpcAvailability={mpcAvailability}
        />
      </Breakpoint>
    </>
  );
};

AirAvailability.propTypes = {
  className: PropTypes.string,
  availability: PropTypes.any
};

export default AirAvailability;
