/**
 *
 * HotelAvailabilityMap
 *
 */

import React, { useState, useMemo, useEffect } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import cs from 'classnames';
import { useDispatch } from 'react-redux';
import {
  GoogleMap,
  Marker,
  LoadScript,
  InfoWindow,
  MarkerClusterer
} from '@react-google-maps/api';
import { GOOGLE_MAPS_KEY } from 'Constants';
import HotelCard from '../HotelCard';
import MobileHotelCard from '../MobileHotelCard';
import purpleGlobe3 from 'Assets/icons/markers/globo-hotel.png';
import m1 from 'Assets/icons/markers/m1.png';
import m2 from 'Assets/icons/markers/m2.png';
import m3 from 'Assets/icons/markers/m3.png';
import m4 from 'Assets/icons/markers/m4.png';
import m5 from 'Assets/icons/markers/m5.png';
import { setNavbarSmallMode } from 'Features/app/actions';

const icons = [m1, m2, m3, m4, m5];
const iconStyles = icons.map(icon => ({
  height: 45,
  width: 38,
  url: icon,
  textColor: 'white'
}));

const HotelAvailabilityMap = ({
  className,
  initialCenter,
  radiusInMeters,
  hotels,
  onHotelClick,
  totalPeople,
  mobile
}) => {
  const [state, setState] = useState({
    activeMarker: null,
    id: null,
    isLoaded: false
  });
  const dispatch = useDispatch();

  useEffect(() => {
    if (mobile) {
      dispatch(setNavbarSmallMode(true));
      window.scroll({
        top: 0,
        left: 0,
        behavior: 'smooth'
      });
    }
    return () => {
      dispatch(setNavbarSmallMode(false));
    };
  }, []);

  const onLoad = () =>
    setState(prevStaate => ({ ...prevStaate, isLoaded: true }));

  const zoom = useMemo(() => Math.floor(radiusInMeters / 2727), [
    radiusInMeters
  ]);

  const onInfoClose = () =>
    setState(prevState => ({ ...prevState, activeMarker: null, id: null }));

  const checkedHotel = hotels.find(hotel => hotel.id === state.id);

  const onMarkerClick = id => (props, marker) => {
    setState(prevState => ({ ...prevState, activeMarker: marker, id }));
  };

  const markers = useMemo(
    () => (
      <MarkerClusterer
        averageCenter
        gridSize={60}
        enableRetinaIcons
        styles={iconStyles}>
        {clusterer =>
          state.isLoaded &&
          hotels.map(hotel => (
            <Marker
              key={hotel.id}
              clusterer={clusterer}
              onClick={onMarkerClick(hotel.id)}
              position={{
                lat: hotel.geoLocation.latitude,
                lng: hotel.geoLocation.longitude
              }}
              icon={{
                url: purpleGlobe3,
                scaledSize: new window.google.maps.Size(45.55, 66)
              }}
            />
          ))
        }
      </MarkerClusterer>
    ),
    [hotels, state.isLoaded]
  );

  const hotelCard = useMemo(() => {
    if (!checkedHotel) {
      return <div />;
    }
    const {
      breakfast,
      allInclusive,
      airConditioning,
      wifi,
      pool,
      parking,
      gym
    } = checkedHotel.facilities;
    const {
      address,
      lowestPackagePerNightPrice,
      lowestPackagePrice,
      price,
      localPrice,
      totalNight,
      reviews
    } = checkedHotel;
    if (mobile) {
      return (
        <MobileHotelCard
          key={checkedHotel.id}
          address={address}
          hotelStars={checkedHotel.starRating}
          propertyName={checkedHotel.name}
          thumbnail={checkedHotel.defaultImage.imageUrl}
          price={lowestPackagePerNightPrice}
          wifi={wifi}
          parking={parking}
          airConditioning={airConditioning}
          gym={gym}
          pool={pool}
          breakfast={breakfast}
          isAllInclusive={allInclusive}
          currency={price.currency}
          priceByNight={lowestPackagePrice}
          localCurrency={localPrice && localPrice.currency}
          localPrice={localPrice && localPrice.lowestPackagePerNightPrice}
          totalNight={totalNight}
          totalPeople={totalPeople}
          reviews={reviews}
          onSelect={onHotelClick(checkedHotel.hotelId)}
          isMap
          onClose={onInfoClose}
        />
      );
    }
    return (
      <HotelCard
        key={checkedHotel.id}
        address={address}
        hotelStars={checkedHotel.starRating}
        propertyName={checkedHotel.name}
        thumbnail={checkedHotel.defaultImage.imageUrl}
        price={lowestPackagePerNightPrice}
        wifi={wifi}
        parking={parking}
        airConditioning={airConditioning}
        gym={gym}
        pool={pool}
        breakfast={breakfast}
        isAllInclusive={allInclusive}
        currency={price.currency}
        priceByNight={lowestPackagePrice}
        localCurrency={localPrice && localPrice.currency}
        localNightPrice={localPrice && localPrice.lowestPackagePerNightPrice}
        localPrice={localPrice && localPrice.lowestPackagePrice}
        totalNight={totalNight}
        totalPeople={totalPeople}
        reviews={reviews}
        onSelect={onHotelClick(checkedHotel.hotelId)}
        noLazy
        isMap
      />
    );
  }, [checkedHotel, totalPeople, mobile]);

  return (
    <div className={cs(className, { mobile })}>
      <LoadScript
        id="script-loader"
        googleMapsApiKey={GOOGLE_MAPS_KEY}
        onLoad={onLoad}>
        <GoogleMap
          id="availability-map"
          mapContainerStyle={{
            height: '100%',
            width: '100%',
            borderRadius: mobile ? '0' : '14px',
          }}
          zoom={zoom}
          clickableIcons={false}
          options={{
            zoomControl: false,
            mapTypeControl: false,
            scaleControl: false,
            streetViewControl: false,
            rotateControl: false,
            fullscreenControl: false,
            gestureHandling: 'greedy'
          }}
          center={initialCenter}>
          {markers}
          {!mobile && checkedHotel && (
            <InfoWindow
              position={{
                lat: checkedHotel.geoLocation.latitude,
                lng: checkedHotel.geoLocation.longitude
              }}
              onCloseClick={onInfoClose}>
              {hotelCard}
            </InfoWindow>
          )}
          {mobile && checkedHotel && (
            <div className="mobile-card-map">{hotelCard}</div>
          )}
        </GoogleMap>
      </LoadScript>
    </div>
  );
};

HotelAvailabilityMap.propTypes = {
  className: PropTypes.string.isRequired,
  hotels: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      geoLocation: PropTypes.shape({
        latitude: PropTypes.number.isRequired,
        longitude: PropTypes.number.isRequired
      }).isRequired
    }).isRequired
  ),
  onHotelClick: PropTypes.func.isRequired,
  radiusInMeters: PropTypes.number.isRequired,
  initialCenter: PropTypes.shape({
    lat: PropTypes.number.isRequired,
    lng: PropTypes.number.isRequired
  }).isRequired,
  totalPeople: PropTypes.number.isRequired,
  mobile: PropTypes.bool
};

export default styled(HotelAvailabilityMap)`
  display: block;
  height: 100%;
  width: 100%;

  .mobile-card-map {
    position: absolute;
    bottom: 80px;
    margin-left: auto;
    margin-right: auto;
    left: 8px;
    right: 8px;
    display: flex;
    flex-direction: column;
  }
`;
