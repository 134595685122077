/**
 *
 * MobilePaxDetailAirCard
 *
 */

import React, { useRef, useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { classItems } from 'Constants/mocks';
import useOutsideClick from '../../../../Utils/hooks/useOutsideClick';
import cs from 'classnames';
import Modal from 'Components/Modal';
import MobilePaxDetailAir from '../MobilePaxDetailAir';
import { ReactComponent as PaxIcon } from './Pax.svg';

const Text = styled.div`
  font-family: 'Itau Display Pro';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  display: flex;
  align-items: center;
  color: rgba(40, 40, 40, 0.5);
`;

const MobilePaxDetailAirCard = ({ className, adults, kids, cabinCode }) => {
  const [openModal, setOpenModal] = useState(false);
  const refOpenModal = useRef();

  useOutsideClick(refOpenModal, () => {
    if (openModal) setOpenModal(false);
  });

  const toggleModal = () => {
    setOpenModal(!openModal);
  };

  const totalPassengers = adults + kids.length;

  return (
    <>
      <div 
      className={cs(className)} 
      onClick={() => !openModal && toggleModal()}
      >
        <PaxIcon />
        <Text>
          {`
          ${
            totalPassengers === 0
              ? '¿Quiénes viajan?'
              : totalPassengers > 1
              ? `${totalPassengers} Pasajeros`
              : '1 Pasajero'
          }, 
        
        
            ${
              cabinCode
                ? `, ${
                    classItems.filter(
                      classItem => classItem.id === cabinCode
                    )[0].name
                  }`
                : 'Tipo de Servicio'
            }`}
        </Text>
        <Modal
          show={openModal}
          onClickOverlay={toggleModal}
          transparent
          isSecondModal>
          <MobilePaxDetailAir onClose={toggleModal} />
        </Modal>
      </div>
    </>
  );
};

MobilePaxDetailAirCard.propTypes = {
  className: PropTypes.string,
  adults: PropTypes.number.isRequired,
  kids: PropTypes.arrayOf(
    PropTypes.shape({
      age: PropTypes.number,
      seat: PropTypes.bool
    })
  ),
  cabinCode: PropTypes.string.isRequired
};
export default styled(MobilePaxDetailAirCard)`
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0px;
  width: 100%;
  min-height: 40px;
  border: 1px solid rgba(40, 40, 40, 0.25);
  border-radius: 5px;
  padding: 10px 9px;
  gap: 12px;
`;
