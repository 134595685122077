import { getAirLineLogo, isoDateToFullDate } from 'Utils';
import React from 'react';
import styled from 'styled-components';
import { excludeAirlineBaggage } from '../../../../Constants/index';

import { ReactComponent as Mochila } from './mochila.svg';
import { ReactComponent as Carryon } from './carryon.svg';
import { ReactComponent as Suitcase } from './suitcase.svg';
import { Escalador } from '../Esclador';

const Holder = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 20px;
  gap: 20px;
  border-top: 1px solid rgba(40, 40, 40, 0.1);

  &:first-child {
    border-top: 0;
  }
`;

const SegmentData = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 0px;
  gap: 20px;
  width: 580px;
`;

const DetailBox = styled.div<{ toTheRight?: boolean }>`
  display: flex;
  flex-direction: column;
  font-family: 'Itau Display Pro';
  font-style: normal;
  font-weight: 300;
  font-size: 16px;
  line-height: 24px;
  color: #282828;
  min-width: 208px;
  align-items: ${({ toTheRight }) => (!toTheRight ? 'flex-start' : 'flex-end')};
`;

const Date = styled.div`
  opacity: 0.6;
`;

const Time = styled.div`
  font-weight: 700;
`;

const City = styled.div`
  font-weight: 400;
`;

const Lugage = styled.div`
  display: flex;
  gap: 4px;
  align-items: flex-end;
`;

interface Props {
  segment: any;
}

export const AirSegment = ({ segment }: Props) => {
  const airline = segment[0].airLines[0];
  const escalas = segment[0].scales.length;
  const departureData = segment[0].departureData;
  const arrivalData = segment[0].arrivalData;

  const showSuitcase = !(
    segment[0].baggage.total === 0 &&
    excludeAirlineBaggage.includes(segment[0].airLines[0].code)
  );
  const showNotCarry =   segment[0].baggage.total === 0 &&
  excludeAirlineBaggage.includes(segment[0].airLines[0].code);
  const showCarryOn = !(segment[0].baggage.total === 0);

  return (
    <Holder>
      <img style={{maxWidth:100}} src={getAirLineLogo(airline.code)} alt={airline.name} />
      <SegmentData>
        <DetailBox toTheRight>
          <Date>{isoDateToFullDate(departureData.flightDate)}</Date>
          <Time>{departureData.flightTime} hs</Time>
          <City>
            {departureData.airport.city.name},{' '}
            {departureData.airport.countryName}
          </City>
        </DetailBox>
        <Escalador stopovers={escalas} />
      
        <DetailBox>
          <Date>{isoDateToFullDate(arrivalData.flightDate)}</Date>
          <Time>{arrivalData.flightTime} hs</Time>
          <City>
            {arrivalData.airport.city.name}, {arrivalData.airport.countryName}
          </City>
        </DetailBox>

        <Lugage>
          {showSuitcase && <Suitcase />}
          {showCarryOn && <Carryon />}
          {showNotCarry && <Mochila/>}
        </Lugage>
      </SegmentData>
    </Holder>
  );
};
