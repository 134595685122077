import { engineCodes, engines } from 'Constants';
import { getFormatPrice } from '../../Utils';
import api from 'Services';

export const getSteps = engine => {
  switch (engine) {
    case engines.FLIGHTS.name:
    case `${engines.FLIGHTS.name}MPC`:
      return [
        'Medios de pago',
        'Datos de facturación',
        'Pasajeros',
        'Contacto'
      ];

    default:
      return ['Habitaciones', 'Datos de facturación', 'Medios de pago'];
  }
};

export const getEnginePrice = (engine, availability) => {
  if (!availability) {
    return;
  }

  switch (engine) {
    case 'hotel-details':
      return getFormatPrice(
        availability.hotelInformation.packagePrice.finalPrice,
        availability.hotelInformation.packagePrice.currency
      );

    case 'vuelos':
    case 'vuelosMPC':
      return getFormatPrice(
        availability.flightPrice.totalPrice,
        availability.flightPrice.currency
      );

    default:
      return null;
  }
};

export const bancardIframeGenerator = res => {
  const overlay = document.createElement('div');
  const form = document.createElement('div');
  const cancelButton = document.createElement('span');
  cancelButton.innerHTML = 'Cancelar Compra';
  cancelButton.style.color ='white';
  cancelButton.style.backgroundColor= '#ff0000';
  cancelButton.style.borderRadius= '8px';
  
  cancelButton.style.padding= '5px 8px';
  cancelButton.style.display= 'flex';
  cancelButton.style.alignItems= 'center';
  cancelButton.style.justifyContent= 'center';
  cancelButton.style.position= 'relative';
  cancelButton.style.top = '5px';
  cancelButton.style.right = '-83px';
  cancelButton.style.cursor = 'pointer';

  const onClickOverlay = async (event) => {
    cancelButton.innerHTML = 'Cancelando...';

    await api.checkout.cancelBancard(res.shop_process_id);
    document.body.removeChild(overlay);
    // @ts-ignore
    window.location.href = document.location.origin;
  };

  form.id = 'bancard-iframe';

  document.body.appendChild(overlay);
  overlay.appendChild(form);
  overlay.appendChild(cancelButton);

  overlay.style.position = 'fixed';
  overlay.style.top = '0';
  overlay.style.bottom = '0';
  overlay.style.right = '0';
  overlay.style.left = '0';
  overlay.style.display = 'flex';
  overlay.style.flexDirection= 'column';
  overlay.style.justifyContent = 'center';
  overlay.style.alignItems = 'center';
  overlay.style.backgroundColor = 'rgba(0, 0, 0, .8)';
  overlay.style.zIndex = '99999999999999999';

  form.style.minHeight = '200px';
  form.style.minWidth = '200px';

  cancelButton.addEventListener('click', onClickOverlay);

  window.Bancard.Checkout.createForm('bancard-iframe', res.process_id, {});
};

export const siemprePagoCheckout = (userData, isMobile) => {
  const checkout = window.PWCheckout;

  checkout.SetProperties({
    name: `${userData.invoice.firstName} ${userData.invoice.lastName}`,
    button_label: 'Total #monto#',
    email: userData.invoice.email,
    image: 'https://picsum.photos/100', // TODO: ADD HERE THE ME VUELO LOGO OR SOMETHING ELSE
    currency: userData.creditCardPayment.checkedFee.currency,
    amount: userData.creditCardPayment.checkedFee.totalPrice,
    description: `${userData.creditCardPayment.checkedFee.fee} pago(s) de ${userData.creditCardPayment.checkedFee.priceByFee}`,
    form_id: 'formId',
    autoSubmit: false,
    checkout_card: 1,
    close_onclick: false
  });

  checkout.OpenIframeNormal();

  checkout.Bind('opened', () => {
    const spModal = document.getElementById('custom_modal');

    if (!isMobile) {
      spModal.style.top = '99px';
    }
    spModal.style.zIndex = '99999999999999999';
    spModal.style.overflow = 'hidden';
  });

  checkout.Bind('closed', () => {
    // TODO: DO SOMETHING TO HANDLE EACH CASE OF CLOSE TYPE
  });

  checkout.Bind('notificationReceived', () => {});

  return checkout;
};

export const getPaymentSimulator = (availability, engine) => {
  if (!availability) {
    return;
  }

  switch (engine) {
    case 'vuelos':
    case 'vuelosMPC':
      return {
        engineId: engineCodes.vuelos,
        vc: availability.flightPrice.validatingCarrier,
        price: availability.flightPrice.totalPrice
      };

    default:
      return {
        engineId: engineCodes.hoteles,
        price: availability.hotelInformation.packagePrice.finalPrice,
        refundPolicyType: availability.hotelInformation.refundPolicyType
      };
  }
};
