/**
 *
 * MobilePaxDetailHotelCard
 *
 */

import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import cs from 'classnames';
import { useSelector } from 'react-redux';
import * as COLORS from 'Constants/colors';
import Modal from 'Components/Modal';
import MobilePaxDetailHotel from '../MobilePaxDetailHotel';
import { totalPeople } from 'Features/hotels';

import { ReactComponent as Bed } from './bed.svg';
import { ReactComponent as Pax } from './pax.svg';

const selector = ({ hotels }) => ({
  roomInformation: hotels.search.roomInformation,
  isEmpty: false
});

const ConnectedMobilePaxDetailHotelCard = props => {
  const state = useSelector(selector);

  return <StyledMobilePaxDetailHotelCard {...state} {...props} />;
};

const MobilePaxDetailHotelCard = ({
  className,
  minHeight,
  roomInformation,
  isEmpty
}) => {
  const [openModal, setOpenModal] = useState(false);
  // TODO Implement this in reducer
  const toggleModal = () => {
    setOpenModal(!openModal);
  };

  const totalRoomInformation = totalPeople(roomInformation);
  const totalRooms = roomInformation.length;

  return (
    <>
      <div className={cs(className)} onClick={toggleModal}>
        <span>
          <Bed />
          {totalRooms > 1
            ? `${totalRooms} habitaciones`
            : `${totalRooms} habitación`}
        </span>
        <Divider />
        <span>
          <Pax />
          {totalRoomInformation > 1
            ? `${totalRoomInformation} huéspedes`
            : `${totalRoomInformation} huésped`}
        </span>
      </div>

      <Modal
        show={openModal}
        onClickOverlay={toggleModal}
        transparent
        whiteBackground
        isSecondModal>
        <MobilePaxDetailHotel close={toggleModal} />
      </Modal>
    </>
  );
};

MobilePaxDetailHotelCard.propTypes = {
  className: PropTypes.string.isRequired,
  minHeight: PropTypes.number,
  roomInformation: PropTypes.array,
  isEmpty: PropTypes.bool
};

const StyledMobilePaxDetailHotelCard = styled(MobilePaxDetailHotelCard)`
  display: block;
  background-color: ${COLORS.white};
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 8px;
  height: 42px;
  border: 1px solid rgba(40, 40, 40, 0.25);
  border-radius: 5px;
  font-family: 'Itau Display Pro';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  display: flex;
  align-items: center;
  color: #282828;

  > span {
    display: flex;
    gap: 8px;

    svg {
      color: white;
      opacity: 0.6;
    }
  }
`;

const Divider = styled.div`
  width: 1px;
  height: 24px;
  background-color: #fb8400;
`;

export default ConnectedMobilePaxDetailHotelCard;
export { StyledMobilePaxDetailHotelCard as MobilePaxDetailHotelCard };
