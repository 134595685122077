import { breakpoints } from 'Constants';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';

const SliderLabelsHolder = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-end;
  padding: 0px 0px 12px;
  gap: 24px;

  font-family: 'Itau Display Pro';
  font-style: normal;
  font-weight: 300;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  justify-content: right;
  color: #282828;
  gap: 16px;
  min-width: 130px;
  span {
    font-weight: 700;
  }
`;

export const SliderParent = styled.div`
  display: flex;
  width: 100%;
  align-items: flex-end;
  gap: 16px;
`;

const SliderLabel = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 0px;
  gap: 32px;

  @media screen and (max-width: ${breakpoints.medium}px) {
    flex-direction: column;
  }
`;

export const SliderInput = styled.input`
  padding: 5px;
  gap: 10px;
  width: 112px;
  min-height: 40px;
  background: #ffffff;
  border: 1px solid rgba(40, 40, 40, 0.2);
  border-radius: 6px;
  font-family: 'Itau Display Pro';
  font-style: normal;
  font-weight: 400;
  font-size: 22px;
  line-height: 30px;
  display: flex;
  align-items: center;
  text-align: center;
  color: #282828;
  &:focus {
    outline: none;
  }
`;

export const SliderHolder = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 0px;
  gap: 24px;
  width: 100%;

  input[type='range'] {
    height: 38px;
    appearance: none;
    margin: 4px 0;
    width: 100%;
  }
  input[type='range']:focus {
    outline: none;
  }

  input[type='range']::-webkit-slider-runnable-track {
    width: 100%;
    height: 4px;
    cursor: pointer;
    background: rgba(40, 40, 40, 0.25);
    border-radius: 14px;
    border: 0;
  }

  input[type='range']::-webkit-slider-thumb {
    height: 20px;
    width: 20px;
    border-radius: 50%;
    background-color: #ec7000;
    background-position: center;

    cursor: pointer;
    -webkit-appearance: none;
    top: -8px;
    position: relative;
  }
`;

interface Props {
  svgLogo: React.ReactNode;
  maxPoints: number;
  initialValue: number;
  onChange: (newValue: number) => void;
  disabled?: boolean;
}

export const RewardSlider = ({
  svgLogo,
  maxPoints,
  initialValue,
  disabled,
  onChange
}: Props) => {
  const [value, setValue] = useState(0);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = Number(e.target.value);
    if (isNaN(newValue)) {
      return;
    }
    setValue(newValue);
    onChange(newValue);
  };

  useEffect(() => {
    setValue(initialValue);
  }, [initialValue]);
  return (
    <SliderParent>
      <SliderLabel>
        {svgLogo}
        <SliderLabelsHolder>
          <div>
            Disponible:{' '}
            <span>{maxPoints.toLocaleString('en').replaceAll(',', '.')}</span>
          </div>
          <div>Canjear:</div>
        </SliderLabelsHolder>
      </SliderLabel>
      <SliderHolder>
        <input
          type="range"
          disabled={disabled}
          min="0"
          max={maxPoints}
          onChange={handleChange}
          value={value}
        />
        <SliderInput
          type="text"
          max={maxPoints}
          min={0}
          onChange={handleChange}
          disabled={disabled}
          value={value}
        />
      </SliderHolder>
    </SliderParent>
  );
};
