/**
 *
 * AirAvailabilityError
 *
 */

import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import cs from 'classnames';
import Text from 'Components/Text';
import Button from 'Components/Button';
import ErrorGone from 'Components/ErrorGone';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import { cleanError } from 'Features/flights/actions';

import useBreakpoints from 'Utils/hooks/useBreakpoints';

import SkyBackground from 'Components/SkyBackground';
import astronaut from 'Assets/astronaut.svg';
import planet from 'Assets/planet.svg';
import lodging from 'Assets/lodging.svg';
import { engines, ERRORS_API } from 'Constants';

const selector = ({ flights, availability }) => ({
  error: flights.availability.error,
  engine: availability.engine
});

const AirAvailabilityError = ({ className }) => {
  const isMediumDown = useBreakpoints().mediumDown;
  const dispatch = useDispatch();
  const { error, engine } = useSelector(selector, shallowEqual);
  const hasErrorMessages =
    error &&
    !(Object.entries(error).length === 0 && error.constructor === Object);

  useEffect(() => {
    if (isMediumDown) {
      document.body.style.overflow = 'hidden';
    }
    // Fixes some cases where this toggle does not fire on unmount
    return () => {
      document.body.style.overflow = 'unset';
    };
  }, [isMediumDown]);

  const isEmptyFlightsError =
    error &&
    error.request &&
    error.request.routesData &&
    error.request.routesData.length &&
    error.response &&
    error.response.length === 0;

  const defaulErrors = () => (
    <Text className="error-label" context="white" size="xlarge">
      <b>¡Ups!</b>{' '}
      {isEmptyFlightsError
        ? 'no hemos encontrado vuelos! Por favor, intenta modificar la búsqueda.'
        : 'ha surgido una escala no esperada, volvamos a empezar.'}
    </Text>
  );

  const errorsNotifications = (engine, error) => {
    switch (error.statusCode) {
      case ERRORS_API.notFound: {
        return errors404ByEngine(engine);
      }
      case ERRORS_API.gone: {
        return errors410ByEngine(engine);
      }
      case ERRORS_API.unprocessableEntity: {
        return errors422ByEngine();
      }
      default:
        return defaulErrors();
    }
  };

  // FIXME: Porque hay codigo de Hoteles aca?
  const errors404ByEngine = engine => {
    switch (engine) {
      case engines.HOTELS.name: {
        return hasErrorMessages &&
          error.messages &&
          engine === engines.HOTELS.name ? (
          <Text className="error-label" context="white" size="xlarge">
            <b>¡Ups!</b> {error.messages}
          </Text>
        ) : (
          defaulErrors()
        );
      }
      default:
        return defaulErrors();
    }
  };

  const errors422ByEngine = () => (
    <Text className="error-label" context="white" size="xlarge">
      <b>¡Ups!</b> por favor cambia tu busqueda, volvamos a empezar.
    </Text>
  );

  const errors410ByEngine = engine => {
    switch (engine) {
      case engines.HOTELS.name: {
        return hasErrorMessages &&
          error.messages &&
          engine === engines.HOTELS.name ? (
          <ErrorGone
            onClick={handleSearchAgain}
            iconName="mv-globo"
            title="Lo sentimos, el hotel no nos ha devuelto disponibilidad para tus habitaciones"
            subtitle="Por favor, volvé al listado a buscar tu hotel."
          />
        ) : (
          defaulErrors()
        );
      }
      default:
        return defaulErrors();
    }
  };

  const handleSearchAgain = () => {
    dispatch(cleanError());
  };

  return (
    <div className={cs(className)}>
      <SkyBackground />
      <div className="planet">
        <img alt="planet" src={planet} />
      </div>
      {engine !== engines.HOTELS.name && (
        <div className="astronaut">
          <img alt="astronaut " src={astronaut} />
        </div>
      )}
      {error &&
        engine === engines.HOTELS.name &&
        (error.statusCode === ERRORS_API.notFound ||
          error.statusCode === ERRORS_API.unprocessableEntity) && (
          <div className="lodging">
            <img alt="lodging" src={lodging} />
          </div>
        )}
      {error && errorsNotifications(engine, error)}
      {isMediumDown && (
        <div className="buttonHolder">
          <Button onClick={handleSearchAgain}>Buscar de Nuevo</Button>
        </div>
      )}
    </div>
  );
};

AirAvailabilityError.propTypes = {
  className: PropTypes.string.isRequired
};

export default styled(AirAvailabilityError)`
  .error-label {
    position: absolute;
    width: 400px;
    top: calc(50% + 180px);
    left: calc(50% - 200px);
  }

  .astronaut {
    position: absolute;
    top: 0;
    left: 0;
  }

  .lodging {
    position: absolute;
    left: 0;
    right: 0;
    top: 70%;
    z-index: 4;

    img {
      width: 100%;
    }
  }

  .planet {
    position: absolute;
    right: 93px;
    bottom: 93px;
    z-index: 5;
  }

  .buttonHolder {
    position: absolute;
    top: 300px;
    left: calc(50% - 80px);
  }

  @media all and (max-width: 900px) {
    .lodging {
      top: 80%;
    }

    .buttonHolder {
      top: 220px;
    }

    .planet {
      display: none;
    }

    .error-label {
      position: absolute;
      width: 100%;
      top: 250px;
      padding: 16px;
      left: unset;
    }
  }
`;
