import useBreakpoints from '../../../../Utils/hooks/useBreakpoints';
import React from 'react';
import { PackagePriceByMonth } from '../../types';
import PackagePriceBar from '../PackagePriceBar';
import { getMonthName, getPricesColorsByPercentile } from '../../helpers';
import Card from '../../../../Components/Card';
import styled from 'styled-components';
import { breakpoints } from '../../../../Constants';
import Divider from 'Components/Divider';

interface PricesGraphProps {
  pricesData: PackagePriceByMonth[];
}

const PackagePricesGraph = ({ pricesData }: PricesGraphProps) => {
  const prices = pricesData.map(x => x.price.amount);
  const minMax = { min: Math.min(...prices), max: Math.max(...prices) };
  const breakpoints = useBreakpoints();

  const months =
    pricesData &&
    pricesData.map((key, index) => (
      <PackagePriceBar
        key={index}
        currency={key.price.currency}
        month={getMonthName(key.month)}
        amount={key.price.amount}
        color={getPricesColorsByPercentile(
          key.price.amount,
          minMax.min,
          minMax.max
        )}
        minMax={minMax}
      />
    ));

  return (
    <Wrapper>
      {/*
        // @ts-ignore */}
      <Card boxShadow>
        <GraphContainer>
          {!breakpoints.mediumDown && (
            <>
              <Months>
                {months.map((months, i) => (
                  <Month key={i}>{months}</Month>
                ))}
              </Months>
            </>
          )}

          {breakpoints.mediumDown && (
            <Months>
              {months.map((month, i) => (
                <>
                  <Month key={i}>{month}</Month>
                </>
              ))}
            </Months>
          )}
        </GraphContainer>
      </Card>
    </Wrapper>
  );
};

export const GraphContainer = styled.div`
  padding: 16px;
`;

export const Wrapper = styled.div`
  padding: 0;
  margin: 10px 0 50px 0;
  list-style: none;
  display: flex;
  justify-content: center;
  overflow: hidden;

  > div {
    max-width: 100%;
  }

  .mv-card-content {
    padding: 0;
  }
`;

export const Months = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: flex-end;
  min-height: 180px;

  & > div {
    margin-bottom: ${({ theme }) => theme.spacing.two};

    &:not(:last-child) {
      margin-right: ${({ theme }) => theme.spacing.one};
    }

    @media screen and (max-width: ${breakpoints.medium}px) {
      width: 100%;
      margin-right: 0 !important;
    }
  }
`;

export const Month = styled.div`
  display: flex;
  align-items: flex-end;

  > div:not(:last-child) {
    margin-right: ${({ theme }) => theme.spacing.one};
  }

  @media screen and (max-width: ${breakpoints.medium}px) {
    flex-direction: column;
    margin-right: 0;
    align-items: flex-start;

    > div:not(:last-child) {
      margin-bottom: ${({ theme }) => theme.spacing.two};
    }
  }
`;

export default PackagePricesGraph;
