export default api => ({
  requestProcessId: async body =>
    await api.post('/api/viaje-naranja/checkouts/start', body),
  completeCheckout: async id =>
    await api.get(`/api/viaje-naranja/checkouts/end/money/${id}`),
  completeOnlyPoints: async id =>
    await api.get(`/api/viaje-naranja/checkouts/end/points/${id}`),
  cancelBancard: async shopProcessId =>
    await api.get(
      `/api/viaje-naranja/checkouts/cancel-bancard-payment/${shopProcessId}`
    ),
  getPaymentLinkById: async orderId =>
    await api.get(`/api/viaje-naranja/orders/${orderId}/infotoclient`)
});
