/**
 *
 * Input
 *
 */

import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import cs from 'classnames';
import Icon from '../Icon';
import { isMobile, toCreditCardFormat } from '../../Utils';
import {
  darkGrey,
  important,
  information,
  positive,
  violeta,
  warning,
  white
} from 'Constants/colors';

import spinner from '../SkyLoader/assets/spinner.svg';

function usePrevious(value) {
  const ref = useRef();
  useEffect(() => {
    ref.current = value;
  });
  return ref.current;
}

const Input = (props, ...rest) => {
  const {
    className,
    value = '',
    message,
    icon,
    context,
    label,
    placeholder,
    onChange,
    onBlur,
    onFocus,
    setFocus = false,
    isCreditCard,
    isRequired,
    disabled,
    isLoading,
    type,
    onKeyDown,
    autocomplete,
    name,
    id
  } = props;
  const [defaultValue, setDefaultValue] = useState(value);
  const oldProps = usePrevious({ value });
  const inputRef = useRef();

  useEffect(() => {
    if (oldProps && oldProps.value !== value) {
      setDefaultValue(value);
    }
  });

  const handleChange = e => {
    let value = e.target.value;

    if (isCreditCard) {
      value = toCreditCardFormat(value);
    }

    setDefaultValue(value);
    onChange && onChange(value);
  };

  const handleBlur = e => {
    setDefaultValue(e.target.value);
    if (onBlur) onBlur(e.target);
  };

  const handleFocus = e => {
    if (onFocus) onFocus(e.target);
  };

  return (
    <div className={cs(className, 'mv-input')}>
      {label && (
        <label>
          {isRequired && <b style={{ color: important }}>*</b>}

          {label}
        </label>
      )}

      <div className={cs('mv-input-container', context)}>
        <div className="mv-input-holder">
          {icon && (
            <Icon
              name={icon}
              context={context === 'violeta' ? 'violeta' : 'important'}
            />
          )}
          <input
            className="input-mevuelo"
            placeholder={placeholder}
            value={defaultValue}
            onChange={handleChange}
            onBlur={handleBlur}
            type={type}
            onKeyDown={onKeyDown}
            disabled={disabled}
            onFocus={handleFocus}
            ref={inputRef}
            autoFocus={setFocus}
            autoComplete={autocomplete}
            name={name}
            id={id}
            {...rest}
          />

          {isLoading && (
            <div className="mv-input-loader">
              <img className="spinner" alt="spinner" src={spinner} />
            </div>
          )}
        </div>
        {message && <div className="mv-input-message">{message}</div>}
      </div>
    </div>
  );
};

Input.propTypes = {
  className: PropTypes.string.isRequired,
  value: PropTypes.string,
  message: PropTypes.string,
  icon: PropTypes.string,
  context: PropTypes.oneOf(['important', 'information', 'warning', 'positive']),
  label: PropTypes.string,
  placeholder: PropTypes.string,
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  onFocus: PropTypes.func,
  setFocus: PropTypes.bool,
  isCreditCard: PropTypes.bool,
  isRequired: PropTypes.bool,
  disabled: PropTypes.bool,
  isLoading: PropTypes.bool,
  type: PropTypes.string,
  onKeyDown: PropTypes.func,
  autocomplete: PropTypes.bool,
  name: PropTypes.string,
  id: PropTypes.string
};

export default styled(Input)`
  width: 100%;

  label {
    font-family: Montserrat, serif;
    color: ${darkGrey};
    font-size: 14px;
    font-weight: 600;
    font-style: normal;
    font-stretch: normal;
    line-height: 0.96;
    letter-spacing: 0.1px;
    text-align: left;
    text-transform: uppercase;
  }

  input {
    height: 40px;
    border: solid 1px ${darkGrey};
    background-color: ${white};
    width: 100%;
    padding: 0 10px;
    font-family: Montserrat, serif;
    font-size: 14px;
    color: ${darkGrey};
  }

  .mv-input-container {
    border-radius: 14px;
    overflow: hidden;
    background: #ffffff;
    border: 1px solid rgba(40, 40, 40, 0.25);
    border-radius: 10px;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: space-between;
    box-shadow: 1px 1px 3px 0px #00000040;

    &.important {
      border-color: ${important};
      background-color: ${important};

      input {
        color: ${important};
      }
    }

    &.information {
      border-color: ${information};
      background-color: ${information};

      input {
        color: ${information};
      }
    }

    &.positive {
      border-color: ${positive};
      background-color: ${positive};

      input {
        color: ${positive};
      }
    }

    &.violeta {
      border-color: ${violeta};

      input {
        color: ${violeta};
      }
    }

    &.warning {
      border-color: ${warning};
      background-color: ${warning};

      input {
        color: ${warning};
      }
    }

    .mv-input-holder {
      display: flex;
      border-radius: 14px;
      background: ${white};
      overflow: hidden;
      align-items: center;
      width: 100%;
      height: 100%;

      .icon {
        font-size: 20px;
        margin-left: 8px;
        margin-top: 4px;
        color: #fb8400;
      }

      input {
        border: 0;
        font-size: ${isMobile.any() ? '14px' : '14px'};
        font-weight: 500;
        font-style: normal;
        font-stretch: normal;
        line-height: 0.96;
        letter-spacing: 0.1px;
        text-align: left;
        color: ${darkGrey};
        white-space: nowrap;
        max-width: 99%;
        text-overflow: ellipsis;

        &:focus {
          outline: none;
        }
      }
    }

    .mv-input-message {
      padding: 0 10px;
      font-size: 12px;
      line-height: 1.5;
      letter-spacing: 0.1px;
      text-align: left;
      color: ${white};
      font-weight: 500;
      width: 100%;
    }

    .mv-input-loader {
      margin-right: 10px;
      margin-left: 10px;
      width: 22px;
      height: 24px;
      text-align: center;

      .spinner {
        max-width: 100%;
        max-height: 100%;
      }
    }
  }
`;
