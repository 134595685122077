/**
 *
 * SkyBackground
 *
 */

import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import cs from 'classnames';

// import * as COLORS from 'Constants/colors';

// import mid from './assets/mid.svg';
// import topbot from './assets/topbot.svg';
// import stars from './assets/stars.svg';

const SkyBackground = ({ className }) => <div className={cs(className)} />;

SkyBackground.propTypes = {
  className: PropTypes.string.isRequired
};

export default styled(SkyBackground)`
  position: fixed;
  overflow: hidden;
  width: 100%;
  height: 100%;
  bottom: 0;
  left: 0;
  background: #fb8400;
`;
