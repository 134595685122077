/**
 *
 * MobileFilterHolder
 *
 */

import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import cs from 'classnames';
import * as COLORS from 'Constants/colors';
import Icon from '../Icon';

const MobileFilterHolder = ({
  className,
  children,
  title,
  context,
  active = true
}) => {
  const [state, setState] = useState(active);
  const toggleActive = () => (state ? setState(false) : setState(true));
  return (
    <div className={cs(className)}>
      <div className={cs('filter-title-bar', context)} onClick={toggleActive}>
        <span>{title}</span>
        <span className={cs('icon-box', { active: state })}>
          <Icon name="mv-arrow-down" />
        </span>
      </div>
      <div className={cs('filter-content', { active: state })}>{children}</div>
    </div>
  );
};

MobileFilterHolder.propTypes = {
  className: PropTypes.string.isRequired,
  children: PropTypes.any,
  title: PropTypes.string,
  context: PropTypes.string,
  active: PropTypes.bool
};

export default styled(MobileFilterHolder)`
  width: 100%;
  position: relative;
  margin-bottom: 8px;

  .filter-title-bar {
    cursor: pointer;
    text-transform: uppercase;
    width: 100%;
    height: 26px;
    box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.35);
    background-color: ${COLORS.violeta};
    font-family: Montserrat, serif;
    font-size: 15px;
    font-weight: 600;
    font-style: normal;
    font-stretch: normal;
    font-family: 'Itau Display Pro';
    letter-spacing: 0.2px;
    text-align: left;
    color: ${COLORS.white};
    display: flex;
    align-items: center;
    padding: 9px 13px 7px 13px;
    justify-content: space-between;

    .icon-box {
      color: ${COLORS.white};
      position: relative;
      transition: transform 0.26s ease;

      &.active {
        transform: rotate(180deg);
        top: -5px;
      }
    }

    &.inverse {
      background-color: ${COLORS.arena};
      color: ${COLORS.secondary};

      .icon-box {
        color: ${COLORS.secondary};
      }
    }
  }

  .filter-content {
    background-color: ${COLORS.white};
    font-family: 'Itau Display Pro';
    border-bottom-right-radius: 14px;
    border-bottom-left-radius: 14px;
    overflow: hidden;
    max-height: 0;
    padding: 0;
    transition: max-height 0.26s ease, padding 0.26s ease;

    &.active {
      max-height: 2000px;
      padding: 11px;
    }
  }
`;
