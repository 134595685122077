/**
 *
 * HotelAvailabilityDesktop
 *
 */

import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import cs from 'classnames';
import InfiniteScroll from 'react-infinite-scroller';
import { useDispatch, useSelector } from 'react-redux';
import HotelAvailabilityMap from '../HotelAvailabilityMap';
import HotelCard from '../HotelCard';
import MenuTab from 'Components/MenuTab';
import FilterHolder from 'Components/FilterHolder';
import StarsFilter from 'Components/StarsFilter';
// import Slider from 'Components/Slider';
// import Radio from 'Components/Radio';
import Checkbox from 'Components/Checkbox';
import Input from 'Components/Input';
import { typeOfOrderPrice } from 'Constants';

import RatingFilterOption from 'Components/RatingFilterOption';
import { toggleMap } from '../../actions';
import { RootState } from 'ReduxConfig/store';

import { ReactComponent as MarkerIcon } from './marker.svg';
import { ReactComponent as Arrow } from './arrow.svg';
import width from 'Theme/width';
import { ItauBanner } from 'Components/ItauBanner';

const HotelAvailabilityDesktop = ({
  className,
  orderBy,
  setOrderBy,
  ratingFilter,
  handleSelectStartFilter,
  searchNameValue,
  handleSearchHotelNameChange,
  // sliderMin,
  // sliderMax,
  // fareRange,
  // handleFareFilterChange,
  // fareType,
  // handleSelectFareType,
  handleFacilityFilterClick,
  facilities,
  selectedFilters,
  response,
  paginatedResponse,
  totalPeople,
  onSelectHotelProperty,
  addPage,
  hasMoreOptions,
  ratingOptions,
  reviewFilter,
  handleReviewFilterClick,
  initialCenter,
  radiusInMeters
}) => {
  // TODO disconnect component and use props
  const dispatch = useDispatch();
  const isMap = useSelector(({ hotels }: RootState) => hotels.search.showMap);
  const setMap = value => () => {
    if (value && !isMap) {
      dispatch(toggleMap());
    }
    if (!value && isMap) {
      dispatch(toggleMap());
    }
  };

  const handleSelectOrderBy = e => {
    setOrderBy(e.target.value);
  };

  return (
    <div className={cs(className)}>
      <div className="container"></div>
      <Content>
        <div>
          {/* Filtros */}
          <MenuTab>
              <MenuTab.Item active={!isMap} onClick={setMap(false)}>
                LISTADO
              </MenuTab.Item>
              <MenuTab.Item active={isMap} onClick={setMap(true)}>
                MAPA
              </MenuTab.Item>
            </MenuTab>

          <FilterWrapper>
            {/* TODO 
              //@ts-ignore */}
            <FilterHolder active title="Categoría">
              <div className="rating-holder">
                {/* TODO 
              //@ts-ignore */}
                <StarsFilter
                  initialValue={ratingFilter}
                  onSelect={handleSelectStartFilter}
                />
              </div>
            </FilterHolder>
            {/* TODO 
              //@ts-ignore */}
            <FilterHolder active title="Buscar por nombre">
              <Input
                icon="mv-lense"
                placeholder="Buscar..."
                value={searchNameValue}
                onChange={handleSearchHotelNameChange}
              />
            </FilterHolder>

            {ratingOptions && (
              // TODO
              //@ts-ignore
              <FilterHolder active title="Valoración">
                <div className="rating-holder">
                  {ratingOptions.map(option => (
                    // TODO
                    //@ts-ignore
                    <RatingFilterOption
                      key={`option-${option}`}
                      value={option}
                      active={reviewFilter === option}
                      onClick={handleReviewFilterClick}
                    />
                  ))}
                </div>
              </FilterHolder>
            )}
            {/* <FilterHolder active={true} title="VALORACIÓN" >
            <div className="align-center">
              <RatingBox>+ 1</RatingBox>
              <RatingBox>2</RatingBox>
              <RatingBox>3</RatingBox>
              <RatingBox>4</RatingBox>
              <RatingBox>5</RatingBox>
            </div>
          </FilterHolder> */}

            {/* TODO: Use when this is ready */}
            {/*
              <FilterHolder active={true} title="PRECIO">
                <Slider
                  min={sliderMin()}
                  max={sliderMax()}
                  defaultValue={fareRange}
                  onChange={handleFareFilterChange}
                  currency="USD"
                />
                <div className="filter-by-price-types ">
                  <Radio
                    checked={fareType === typeOfFareFilter.noche}
                    value={typeOfFareFilter.noche}
                    onClick={handleSelectFareType}
                    context="default">
                    Por noche
                  </Radio>
                  <Radio
                    checked={fareType === typeOfFareFilter.total}
                    value={typeOfFareFilter.total}
                    onClick={handleSelectFareType}
                    context="default">
                    Total
                  </Radio>
                </div>
              </FilterHolder>
            */}

            {facilities && (
              // TODO
              // @ts-ignore
              <FilterHolder active title="Servicios">
              <div className="facilities-holder">
                {facilities
                .slice()
                .sort((a,b)=>a.name.localeCompare(b.name))
                .map((facility, index) => (
                  // @ts-ignore
                  <Checkbox
                    key={`${facility.id}-${index}`}
                    checked={selectedFilters.includes(facility.id)}
                    value={facility.id}
                    onClick={handleFacilityFilterClick}
                    >
                    {facility.name}
                  </Checkbox>
                ))}
              </div>
            </FilterHolder>
            )}
          </FilterWrapper>
        </div>
        <div className="el-content">
          {!isMap &&
          <TopSelectionBlock>
          {/* <OutlineBtn onClick={setMap(true)}>
            <MarkerIcon />
            Ver Mapa
          </OutlineBtn> */}
          <OrderSelect>
            <select onChange={handleSelectOrderBy}>
              <option value={typeOfOrderPrice.byPriority}>
                Recomendados
              </option>
              <option value={typeOfOrderPrice.byPrice}>Económicos</option>
            </select>
            <Arrow />
          </OrderSelect>
        </TopSelectionBlock>
          }
          {isMap ? (
            <div
              style={{
                position: 'relative',
                width: 'calc(100% - 30px)',
                height: '100%'
              }}>
              {/* TODO
               @ts-ignore */}
              <HotelAvailabilityMap
                initialCenter={initialCenter}
                hotels={response}
                radiusInMeters={radiusInMeters}
                onHotelClick={onSelectHotelProperty}
                totalPeople={totalPeople}
              />
            </div>
          ) : (
            <>
              {/* <MenuTab>
                  <MenuTab.Item
                    onClick={() => setOrderBy(typeOfOrderPrice.byPriority)}
                    active={orderBy === typeOfOrderPrice.byPriority}>
                    +CONVENIENTES
                  </MenuTab.Item>
                  <MenuTab.Item
                    active={orderBy === typeOfOrderPrice.byPrice}
                    onClick={() => setOrderBy(typeOfOrderPrice.byPrice)}>
                    +ECONÓMICOS
                  </MenuTab.Item>
                </MenuTab> */}
              <InfiniteScroll
                style={{
                  width: '100%',
                  paddingRight: 32,
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center'
                }}
                pageStart={0}
                loadMore={addPage}
                hasMore={hasMoreOptions}
                loader={
                  <div
                    key="loading"
                    style={{ color: 'white' }}
                    className="loader">
                    Cargando ...
                  </div>
                }>
                {paginatedResponse &&
                  paginatedResponse.length > 0 &&
                  paginatedResponse.map((hotelItem, index) => {
                    const {
                      breakfast,
                      allInclusive,
                      airConditioning,
                      wifi,
                      pool,
                      parking,
                      gym
                    } = hotelItem.facilities;
                    const {
                      address,
                      lowestPackagePerNightPrice,
                      lowestPackagePrice,
                      price,
                      totalNight,
                      reviews
                    } = hotelItem;
                    return (
                      <>
                        {/* 
                        // @ts-ignore */}
                        <HotelCard
                          key={index}
                          noLazy
                          address={address}
                          starRating={hotelItem.starRating}
                          keyRating={hotelItem.keyRating}
                          textRating={hotelItem.textRating}
                          propertyName={hotelItem.name}
                          thumbnail={hotelItem.defaultImage.imageUrl}
                          price={lowestPackagePerNightPrice}
                          wifi={wifi}
                          parking={parking}
                          airConditioning={airConditioning}
                          gym={gym}
                          pool={pool}
                          breakfast={breakfast}
                          isAllInclusive={allInclusive}
                          currency={price.currency}
                          priceByNight={lowestPackagePrice}
                          localCurrency={hotelItem.localPrice?.currency}
                          localNightPrice={
                            hotelItem.localPrice?.lowestPackagePerNightPrice
                          }
                          localPrice={hotelItem.localPrice?.lowestPackagePrice}
                          totalNight={totalNight}
                          totalPeople={totalPeople}
                          reviews={reviews}
                          onSelect={onSelectHotelProperty(hotelItem.hotelId)}
                        />
                        {index === 4 && <ItauBanner noMargin />}
                      </>
                    );
                  })}
              </InfiniteScroll>
            </>
          )}
        </div>
      </Content>
    </div>
  );
};

HotelAvailabilityDesktop.propTypes = {
  className: PropTypes.string.isRequired,
  orderBy: PropTypes.string,
  setOrderBy: PropTypes.func,
  ratingFilter: PropTypes.number,
  handleSelectStartFilter: PropTypes.func,
  searchNameValue: PropTypes.string,
  handleSearchHotelNameChange: PropTypes.func,
  sliderMin: PropTypes.func,
  sliderMax: PropTypes.func,
  fareRange: PropTypes.array,
  handleFareFilterChange: PropTypes.func,
  fareType: PropTypes.string,
  handleSelectFareType: PropTypes.func,
  handleFacilityFilterClick: PropTypes.func,
  facilities: PropTypes.array,
  selectedFilters: PropTypes.array,
  response: PropTypes.array,
  paginatedResponse: PropTypes.array,
  totalPeople: PropTypes.number,
  onSelectHotelProperty: PropTypes.func,
  addPage: PropTypes.func,
  hasMoreOptions: PropTypes.bool,
  ratingOptions: PropTypes.array,
  reviewFilter: PropTypes.number,
  handleReviewFilterClick: PropTypes.func,
  radiusInMeters: PropTypes.number.isRequired,
  initialCenter: PropTypes.shape({
    lat: PropTypes.number.isRequired,
    lng: PropTypes.number.isRequired
  }).isRequired
};

const OrderSelect = styled.div`
  background: #ffffff;
  border-radius: 10px;
  filter: drop-shadow(1px 1px 3px rgba(0, 0, 0, 0.25));
  padding: 13px 10px;
  font-family: 'Itau Display Pro';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #000000;
  display: flex;
  align-items: center;
  gap: 12px;

  select {
    appearance: none;
    border: 0;

    &:focus {
      outline: 0;
    }
  }
`;

const Content = styled.div`
  display: grid;
  grid-template-columns: 281px 1fr;
  gap: 32px;
`;

const TopSelectionBlock = styled.div`
  display: flex;
  flex-direction: row-reverse;
  margin-bottom: 20px;
  gap: 16px;
  width: 100%;
  max-width: 933px;
`;

const OutlineBtn = styled.div`
  cursor: pointer;
  display: flex;
  gap: 10px;
  border-radius: 10px;
  border: 1px solid #fb8400;
  color: #fb8400;
  background-color: white;
  font-size: 22px;
  font-style: normal;
  font-weight: 400;
  line-height: 30px;
  padding: 10px;
  align-items: center;
`;

const FilterWrapper = styled.div`
  border-radius: 10px;
  background: var(--beige, #faf2e3);
  box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.25);
  padding: 20px;
  flex-direction: column;
  align-items: flex-start;
  gap: 32px;
`;

export default styled(HotelAvailabilityDesktop)`
  display: 'block';

  .el-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 24px;

  }

  .align-center {
    display: flex;
    justify-content: center;
  }

  .filter-by-price-types {
    display: flex;
    margin-top: 22px;
    justify-content: space-evenly;
  }

  .facilities-holder {
    display: flex;
    flex-direction: column;
    gap: 8px;
  }

  .rating-holder {
    display: flex;
    justify-content: space-between;
    gap: 8px;
  }
`;
