/**
 *
 * Checkbox
 *
 */

import React from 'react';
import cs from 'classnames';
import Icon from '../Icon';
import styled from 'styled-components';
import Theme from 'Theme';
import { IProps } from './models';

const Checkbox = ({
  value,
  context,
  children,
  onClick,
  checked,
  className
}: IProps) => (
  <div className={cs(className)}>
    <div
      className={cs('checkbox-square', context)}
      onClick={() => onClick && onClick(value)}>
      {checked && <Icon name="mv-checkmark" color="important" size={20} />}
    </div>

    <label>{children}</label>
  </div>
);

export default styled(Checkbox)`
  cursor: pointer;
  display: flex;
  align-items: center;

  & > .checkbox-square {
    position: relative;
    width: 16px;
    height: 15px;
    border-radius: 3px;
    /* box-shadow: ${Theme.shadows.one}; */
    border: solid 1px ${Theme.colors.meVueloGrey};
    background-color: ${Theme.colors.white};
    display: inline-block;
    color: ${Theme.colors.important};

    &.positive {
      color: ${Theme.colors.positive};
    }

    &.information {
      color: ${Theme.colors.information};
    }

    & > .icon {
      position: absolute;
      bottom: -7px;
      left: -1px;
    }
  }

  label {
    font-family: 'Itau Display Pro';
    font-style: normal;
    font-weight: 300;
    font-size: 16px;
    line-height: 24px;
    color: rgba(40, 40, 40, 0.50);;
    position: relative;
    margin-left: 10px;
    margin-bottom: 0;
    flex: 1;
  }
`;
