import { isError } from 'lodash';
import styled from 'styled-components';

export const FormBox = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  gap: 4px;
  width: 100%;
`;

export const Label = styled.label`
  font-family: 'Itau Display Pro';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #282828;

  span {
    font-family: 'Itau Display Pro';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: #fb0000;
  }
`;

export const ErrorText = styled.span`
  font-family: 'Itau Display Pro';
  font-style: normal;
  font-weight: 300;
  font-size: 14px;
  line-height: 22px;
  color: #fb0000;
`;

export const InputHolder = styled.div<{ isError?: boolean }>`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 10px;
  width: 100%;
  height: 40px;
  background: #ffffff;
  border: 1px solid
    ${({ isError }) =>
      isError ? 'rgba(251, 0, 0, 0.5)' : 'rgba(40, 40, 40, 0.25)'};
  border-radius: 10px;
  overflow: hidden;

  input {
    width: 100%;
    border: 0;
    &:focus {
      outline: none;
    }
  }

  select {
    width: 100%;
    border: 0;
    &:focus {
      outline: none;
    }
  }
`;

export const CommonHolder = styled.div`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  padding: 0 20px;
  gap: 20px;
  width: 100%;
  margin-bottom: 70px;
`;

export const FormRow = styled.div`
  display: flex;
  gap: 25px;
  width: 100%;
`;

export const ContentCard = styled.div<{ noPaddingBottom?: boolean }>`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  padding: 0px;
  background: #ffffff;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
  overflow: hidden;
  align-items: center;
  ${({ noPaddingBottom }) => !noPaddingBottom && 'padding-bottom: 44px;'}
`;
