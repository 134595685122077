/**
 *
 * NavBar
 *
 */

import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import cs from 'classnames';
import { push } from 'connected-react-router';
import { useDispatch, useSelector, batch } from 'react-redux';
import { CSSTransition } from 'react-transition-group';

// Components
import MobileNavBar from './MobileNavbar';
import Icon from 'Components/Icon';
import Link from 'Components/Link';

// Helpers
import { getEngine, removeCharacter, isEmpty } from 'Utils';
import { useUser } from 'Features/users/utils';
import { useIsScrollTopHook } from 'Utils/hooks/useIsScrollTop';
import useBreakpoints from 'Utils/hooks/useBreakpoints';
import { cleanSearch } from 'Features/flights';
import { resetHotelsEngine, cleanError } from 'Features/hotels';
import { resetToEngine } from 'ReduxConfig/availability';
import {
  setActive,
  reset as resetDestinations
} from 'Features/destinations/actions';

// Constantsng
import * as colors from 'Constants/colors';
import { engines, routes } from 'Constants';

// Assets
import logo from 'Assets/tienda-naranja-viajes-logo-full.svg';
import whiteLogo from 'Assets/tienda-naranja-viajes-logo-variant.svg';
import { useLocation } from 'react-router';
import { selectorDestinationsBottom } from 'Features/destinations/selectors';

const selector = ({
  router,
  hotels,
  flights,
  availability,
  appConfiguration,
  destinations
}) => ({
  router,
  availability: flights.availability,
  engine: availability.engine,
  hotelStatus: hotels.status,
  smallMode: appConfiguration.navBarSmallMode,
  hideNavbar: appConfiguration.hideNavbar,
  isDestination: destinations.active && !isEmpty(destinations.data)
});

const excluded = [
  routes.whoWeAre,
  routes.packageThankyou,
  routes.airCheckout
].map(e => removeCharacter(e, '/'));

const noShowMobile = [routes.airCheckout].map(e => removeCharacter(e, '/'));

const NavBar = ({ className }) => {
  const [showMenu, setShowMenu] = useState(false);
  const optionsRef = useRef(null);
  const routing = routes;
  const { search } = useLocation();
  const hotelId = search.includes('hotelId');

  const {
    router,
    availability,
    smallMode,
    hideNavbar,
    engine,
    hotelStatus,
    isDestination
  } = useSelector(selector);
  const isHomeTop = useIsScrollTopHook();
  const { mediumDown } = useBreakpoints();
  // TODO sacar los estatus a una constante
  const unused =
    engine === engines.FLIGHTS.name
      ? availability.status === 'unused'
      : hotelStatus === 'unused';
  const user = useUser();
  const dispatch = useDispatch();

  const activeNav = removeCharacter(router.location.pathname, '/');

  const engineActive = activeNav ? getEngine(activeNav) : engines.FLIGHTS.name;

  const handleSelect = (engineName, enginePath) => () => {
    batch(() => {
      dispatch(push(enginePath));
      dispatch(resetToEngine(engineName));
      dispatch(setActive(false));
      dispatch(cleanError());
    });
  };

  const handleShowMenu = () => {
    setShowMenu(old => !old);
  };

  const handleClickOutside = e => {
    if (optionsRef.current && !optionsRef.current.contains(e.target)) {
      setShowMenu(false);
    }
  };

  const goHome = () => {
    dispatch(resetToEngine(engines.FLIGHTS.name));
    dispatch(cleanSearch());
    dispatch(resetHotelsEngine());
    dispatch(resetDestinations());
  };

  const settings = {
    arrows: false,
    dots: false,
    infinite: false,
    slidesToScroll: 1,
    slidesToShow: 1,
    speed: 300,
    variableWidth: true
  };

  useEffect(() => {
    document.addEventListener('click', handleClickOutside);

    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  });
  if (mediumDown) {
    if (hotelId) {
      return null;
    }
    return (
      <MobileNavBar
        settings={settings}
        engines={engines}
        activeEngine={engine}
        handleSelect={handleSelect}
        hideHeader={!isHomeTop}
        onLogoClick={goHome}
        smallMode={smallMode}
        hidden={hideNavbar}
      />
    );
  }

  // eslint-disable-next-line no-unreachable
  const activateHomeTopClass = false;
  //  &&
  //   unused &&
  //   !excluded.includes(activeNav) &&
  //   !window.location.pathname.includes('hotelCheckout') &&
  //   !window.location.pathname.includes('airCheckout'); //&&
  // engineActive !== 'blog' &&
  // engineActive !== 'asistencias' &&
  // engineActive !== 'Asistencias' &&
  // activeNav.indexOf(excluded[2]) !== 0 &&
  // !applyDestinations;

  return (
    <>
      <header
        className={cs(className, {
          'home-top': activateHomeTopClass
        })}>
        <nav className="nav container">
          <LogoHolder>
            <Link to={routes.home} onClick={goHome}>
              <StyledLogo
                alt="tienda-naranja-viajes-logo"
                src={logo}
                isHomeTop={!activateHomeTopClass}
              />
              <StyledLogo
                white
                alt="tienda-naranja-viajes-logo"
                src={whiteLogo}
                isHomeTop={activateHomeTopClass}
              />
            </Link>
          </LogoHolder>

          <div>
            {/* TODO: THIS COULD BE A REUSABLE COMPONENT */}
            {user && (
              <UserContainer isOpen={showMenu}>
                <User onClick={handleShowMenu}>
                  <span style={{ marginLeft: 8 }}>{user.name}</span>
                  <Icon name="mv-user-circle" color="important" size={20} />
                </User>

                <CSSTransition
                  in={showMenu}
                  timeout={300}
                  unmountOnExit
                  classNames="options">
                  <Options ref={optionsRef}>
                    <Option
                      onClick={() => setShowMenu(false)}
                      to={routing.logout}>
                      Salir
                      <Icon name="mv-arrow-back" color="important" size={13} />
                    </Option>
                  </Options>
                </CSSTransition>
              </UserContainer>
            )}

            <ul className="navbar-ul">
              {Object.values(engines).map(engine => (
                <li
                  className={cs('navbar-li', {
                    'home-top': activateHomeTopClass
                  })}
                  key={engine.name}>
                  <Link
                    to={engine.path}
                    onClick={handleSelect(engine.name, engine.path)}
                    className={cs(
                      { active: engine.name === engineActive },
                      engine.name
                    )}>
                    {engine.name}
                  </Link>
                </li>
              ))}
            </ul>
          </div>

          <div className={cs('userMenu', { 'home-top': activateHomeTopClass })}>
            {/* <span>Registrarme</span>{' '}
            <span>
              {' '}
              <img src={usermenu} alt="userMenu" />
              Iniciar Session
            </span> */}
          </div>
        </nav>
      </header>
      {/* Este espejado, pero con position relative, empuja el contenido del resto del sitio */}
      <div
        style={{ visibility: 'hidden', position: 'relative' }}
        className={cs(
          className,
          {
            'home-top':
              isHomeTop &&
              unused &&
              !excluded.includes(activeNav) &&
              engineActive !== 'blog' &&
              activeNav.indexOf(excluded[2]) !== 0
          },
          'mobile-header'
        )}
      />
    </>
  );
};

NavBar.propTypes = {
  className: PropTypes.string.isRequired,
  isHomeTop: PropTypes.bool,
  isHomeScrollUp: PropTypes.bool
};

export default styled(NavBar)`
  width: 100%;
  height: 72px;
  background-color: white;
  background: white;
  transition: background-color 0.6s ease-in-out;
  z-index: 99;
  position: fixed;
  top: 0;
  box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.25);

  &.is-hidden {
    display: none;
  }

  &.home-top {
    background: linear-gradient(180deg, #454545 0%, rgba(0, 0, 0, 0) 100%);
    background-color: transparent;
    box-shadow: none;
    border-bottom: 0;
  }

  .container {
    display: flex;
    justify-content: space-between;
    padding-top: 13px;
    align-items: center;
    position: relative;

    @media only screen and (max-width: 768px) {
      max-width: 100%;
    }
  }

  .userMenu {
    font-family: 'Itau Display Pro';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: #282828;
    display: flex;
    gap: 29px;

    &.home-top {
      color: white;
    }

    span {
      cursor: pointer;

      &:hover {
        color: ${colors.yellow};
      }
    }

    img {
      margin-right: 9px;
    }
  }

  .navbar-ul {
    list-style-type: none;
    margin: 0;
    padding: 0;
    display: flex;
    align-items: flex-end;
    transition: 0.2s;
    right: 0;
    bottom: 2px;

    .navbar-li {
      a {
        padding: 5px 20px;
        cursor: pointer;
        display: block;
        text-decoration: none;
        font-family: 'Itau Display Pro';
        font-size: 16px;
        font-weight: 400;
        font-style: normal;
        font-stretch: normal;
        line-height: 24px;
        text-align: left;
        color: ${colors.white};
        border-radius: 20px;
        text-transform: capitalize;
        color: #282828;

        &.active {
          background: rgba(251, 132, 0, 0.8);
          &:hover {
            color: #282828;
          }
        }

        &:hover {
          color: ${colors.yellow};
        }
      }

      &.home-top {
        a {
          padding: 5px 20px;
          cursor: pointer;
          display: block;
          text-decoration: none;
          font-family: 'Itau Display Pro';
          font-size: 16px;
          font-weight: 400;
          font-style: normal;
          font-stretch: normal;
          line-height: 24px;
          text-align: left;
          color: ${colors.white};
          border-radius: 20px;
          text-transform: capitalize;

          &.active {
            background: rgba(251, 132, 0, 0.8);
            &:hover {
              color: white;
            }
          }

          &:hover {
            color: ${colors.yellow};
          }
        }
      }
    }
  }
`;

const User = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;

  span {
    color: ${({ theme }) => theme.colors.white};
    margin-right: ${({ theme }) => theme.spacing.one};
  }
`;

const UserContainer = styled.div`
  position: relative;
  transition: all 300ms ease;
  border-top-right-radius: ${({ theme }) => theme.borderRadius.smaller};
  border-top-left-radius: ${({ theme }) => theme.borderRadius.smaller};
  z-index: 99;
  ${({ isOpen }) =>
    isOpen &&
    css`
      background-color: ${({ theme }) => theme.colors.white};
      color: ${({ theme }) => theme.colors.important};
      box-shadow: ${({ theme }) => theme.shadows.two};
      padding: ${({ theme }) => theme.spacing.two};
      transform: translateZ(10px);

      span {
        color: ${({ theme }) => theme.colors.important};
      }
    `};

  .options-enter {
    opacity: 0;
  }
  .options-enter-active {
    opacity: 1;
    transition: opacity 300ms ease;
  }
  .options-exit {
    opacity: 1;
  }
  .options-exit-active {
    opacity: 0;
    transition: opacity 300ms ease;
  }
`;

const Options = styled.ul`
  background-color: ${({ theme }) => theme.colors.white};
  box-shadow: ${({ theme }) => theme.shadows.two};
  border-bottom-right-radius: ${({ theme }) => theme.borderRadius.smaller};
  border-bottom-left-radius: ${({ theme }) => theme.borderRadius.smaller};
  padding: 0;
  position: absolute;
  left: 0;
  right: 0;
  bottom: -50px;
  z-index: 90;
  overflow: hidden;
`;

const Option = styled(Link)`
  color: ${({ theme }) => theme.colors.important};
  font-weight: ${({ theme }) => theme.text.fontWeights.semibold};
  font-size: ${({ theme }) => theme.text.fontSize.body.small};
  padding: ${({ theme }) => theme.spacing.two}
    ${({ theme }) => theme.spacing.four};
  display: flex;
  align-items: center;
  justify-content: space-between;
  transition: background-color 300ms ease;

  &:hover {
    color: ${({ theme }) => theme.colors.important};
    background-color: ${({ theme }) => theme.colors.greyHoverBkg};
  }
`;

const StyledLogo = styled.img`
  position: absolute;
  opacity: ${({ isHomeTop }) => (isHomeTop ? 1 : 0)};
  transition: opacity 0.3s ease-in-out;
  width: 172px;
  top: ${props => (props.white ? '-13px' : '-13px')};
`;

const LogoHolder = styled.div`
  position: relative;
`;
