/* eslint-disable react/no-unknown-property */
/**
 *
 * PageNotFound
 *
 */

// vebdir
import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import cs from 'classnames';
import { push } from 'connected-react-router';
import { useDispatch } from 'react-redux';

// assests
import oldWest from '../PageNotFound/assets/old-west.png';

// components
import Button from 'Components/Button';

// Constants
import { routes } from 'Constants';
import { cleanSearch } from 'Features/flights/actions';
import { cleanErrorBoundary } from 'Features/app/actions';
import useBreakpoints from 'Utils/hooks/useBreakpoints';

const ErrorPage = ({ className }) => {
  const dispatch = useDispatch();
  const { largeDown } = useBreakpoints();

  const handleContact = () => {
    document.getElementById('errorBtn').click();
  };

  const goHome = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
    dispatch(cleanSearch());
    dispatch(push(routes.home));
    dispatch(cleanErrorBoundary());
  };

  return (
    <div className={cs(className,{largeDown})}>
      <div className={cs("content-error",{largeDown})}>
        {/* <Error /> */}

        <div className='svg'>¯\_(ツ)_/¯ </div>

        <p>¡Ups!</p>
      </div>
      <div className="content-box">
        <div className="content-error-message">
          <MessageError className={cs({largeDown})}>
          Lo sentimos, ha ocurrido un error inesperado. 
Nos disculpamos por las molestias que esto pueda causar.
Si el problema persiste, por favor, no dudes en contactarnos
          </MessageError>
          <div className={cs("buttons-holder",{largeDown})}>
            <Button
              onClick={goHome}
              className={cs("button-home",{largeDown})}>
              Volver al Inicio
            </Button>
            <Button className={cs("button-contact",{largeDown})} onClick={handleContact}>
              Contactanos
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

ErrorPage.propTypes = {
  className: PropTypes.string.isRequired
};

export default styled(ErrorPage)`
  background-image: url(${oldWest});
  background-size: cover;
  background-position: center;
  position: fixed;
  width: 100%;
  height: 100vh;
  min-height: 100%;
  z-index: 99;
  top: 0;

  .buttons-holder {
    width: 500px;
    position: relative;
    margin: 0 auto;
    display: flex;
    justify-content: space-around;
    margin-top: 20px;

    &.largeDown{
      align-items:  center;
      flex-direction:  column;
    }
    .button-home {
      display: flex;
      padding: 10px 30px;
      margin-right: 35px;
      justify-content: center;
      align-items: center;
      gap: 10px;
      flex: 1 0 0;
      border-radius: 12px;
      background: #fb8400;
      box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.25);
      &.largeDown{
        width: 365px;
      }
    }
    .button-contact {
      display: flex;
      color: #fff;
      background-color: transparent;
      padding: 10px 30px;
      margin-right: 35px;
      justify-content: center;
      align-items: center;
      gap: 10px;
      flex: 1 0 0;
      border-radius: 12px;
      border: 2px solid #fff;
      &.largeDown{
        width: 365px;
        margin-top: 10px;
      }
    }
  }

  .content-box {
    display: flex;
    justify-content: center;
    z-index: 1;
    position: relative;
    text-align: center;
    margin-top: 30px;
  }
  .content-error-message {
    flex-direction: column;
    justify-content: center;
    width: 466px;
    text-align: start;
  }
  .content-error {
    text-align: center;
    margin-bottom: 60px;
    p {
      position: absolute;
      top: 202px;
      text-align: center;
      width: 100%;
      color: #000;
      text-align: flex;
      font-family: Itau Display Pro;
      font-size: 80px;
      font-style: normal;
      font-weight: 700;
      line-height: 88px;
    }
    .svg {
      color: #FB8400;
      margin: 0 auto;
      font-size: 144px;
      text-align: center;
      width: 581px;
      height: 247px;
      flex-shrink: 0;
      fill: rgba(219, 191, 167, 0.01);
      margin-top: 36px;
    }
    &.largeDown svg {
      width: calc(100% - 40px);
      font-size: 100px;
    }
    &.largeDown p {
      top:165px;
    }
  }
`;

const MessageError = styled.div`
  color: #282828;
  font-family: Itau Display Pro;
  font-size: 16px;
  font-style: normal;
  align-self: stretch;
  font-weight: 400;
  line-height: 24px;
  opacity: 0.7;
  &.largeDown {
    text-align: center;
    margin: 0 35px;
  }
`;
