/**
 *
 * AirAvailabilityMobile
 *
 */

import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import cs from 'classnames';
import Button from 'Components/Button';
import MobileAirFilter from '../MobileAirFilter';
import MobileNavBar from 'Components/MobileNavBar';
import MobileAirAvailabilityCard from '../MobileAirAvailabilityCard';
import { filterListTypes, routes } from 'Constants';
import Modal from 'Components/Modal';
import MobileFlightPriceDetails from '../MobileFlightPriceDetails';
import MobileFlightItineraryDetails from '../MobileFlightItineraryDetails';
import AirCheckError from '../AirCheckError';
import Icon from 'Components/Icon';
import CardAlert from 'Components/CardAlert';
import Text from 'Components/Text';
import Portal from 'Components/Portal';
import { ItauPuntosLogin } from 'Components/ItauPuntosLogin';
import MPCAvailability from '../MpcAvailability';

import { ReactComponent as Arrow } from '../AirAvailabilityDesktop/arrow.svg';
import { useSelector } from 'react-redux';
import { selectorDestinationsBottom } from 'Features/destinations/selectors';
import DealButton from 'Features/destinations/components/BestDeals/DealButton';
import Divider from 'Components/Divider';
import { roundTripRequestUrl } from 'Features/flights/helpers';

import pesos from 'Assets/Pesos.svg';
import { getSearchObject } from 'Utils/getSearchObject';

const AirAvailabilityMobile = ({
  className,
  parseResponse,
  handleClickListType,
  filters,
  fareSliderDetails,
  handleClickLuggageFilter,
  filtersData,
  handleClickScalesFilter,
  handleAirlineFilterClick,
  handleServiceClassFilterClick,
  handleAllianceFilterClick,
  handleFareFilterChange,
  handleNoAirportChange,
  handleFlexFilterChange,
  onSelectAvailability,
  showErrorModal,
  toggleErrorModal,
  request,
  orderPriceInfo,
  hasMPCData,
  hasError,
  mpcAvailability
}) => {
  const [showFilters, setShowFilters] = useState(false);
  const [openPriceModal, setOpenPriceModal] = useState(false);
  const [openFlightModal, setOpenFlightModal] = useState(false);
  const [flightPrice, setFlightPrice] = useState();
  const [flightInfo, setFlightInfo] = useState();
  const [direction, setDirection] = useState(0);

  const state = useSelector(selectorDestinationsBottom);

  const closePrice = () => {
    setOpenPriceModal(false);
    setFlightPrice();
  };
  const showPrice = flightPrice => {
    setFlightPrice(flightPrice);
    setOpenPriceModal(true);
  };

  const closeFlight = () => {
    setOpenFlightModal(false);
    setFlightInfo();
  };

  const showFlight = (flightInfo, dir) => {
    setFlightInfo(flightInfo);
    setDirection(dir);
    setOpenFlightModal(true);
  };

  const handleSelectOrderBy = e => {
    handleClickListType(e.target.value)();
  };

  const handleCloseError = () => {
    closeFlight();
    closePrice();
    toggleErrorModal();
  };

  const currentUrl = window.location.href;
  const searchQuery = 'show-suggested-date';
  const urlSuggestedDate = currentUrl.includes(searchQuery);

  const toggleFilters = () => setShowFilters(!showFilters);


  const getUrl = (state, destination) => {
    const searchObject = getSearchObject({
      destination: state.destination,
      origin:state.origin,
      dateFrom: state.dateFrom,
      dateTo: state.dateTo,
    });
    const urlParams = roundTripRequestUrl(searchObject);

    return `${routes.flightSearch.replace(
      ':destination',
      destination
    )}${urlParams}`;
  };


  return (
    <div className={cs(className)}>
      <Modal
        show={showErrorModal}
        onClickOverlay={handleCloseError}
        transparent>
        <AirCheckError close={handleCloseError} />
      </Modal>
      {urlSuggestedDate && (
        <div style={{ width: '100%', display: 'flex', justifyContent: 'center', flexWrap: 'wrap' }}>
          <div style={{ display: 'flex', justifyContent: 'center', textAlign: 'center' }}>
            <Divider titleText={`Los mejores precios de vuelos a ${state.name}`} img={pesos} />
          </div>
          {state.flightsBanners.map((banner, index) => (
            <div key={index} style={{ margin: '0 10px' }}>
              <ButtonsHolder>
                <ButtonItemContainer key={banner.id}>
                  <DealButton
                    title={banner.month}
                    price={banner.price}
                    url={getUrl(banner, banner.destination)}
                  />
                </ButtonItemContainer>
              </ButtonsHolder>
            </div>
          ))}
        </div>
      )}
      {parseResponse && (
        <>
          {showFilters && (
            <Portal>
              <MobileAirFilter
                close={toggleFilters}
                fareSliderDetails={fareSliderDetails}
                filters={filters}
                filtersData={filtersData}
                handleClickLuggageFilter={handleClickLuggageFilter}
                handleClickScalesFilter={handleClickScalesFilter}
                handleFareFilterChange={handleFareFilterChange}
                handleAirlineFilter={handleAirlineFilterClick}
                handleAllianceFilter={handleAllianceFilterClick}
                handleClassFilter={handleServiceClassFilterClick}
                handleNoAirportChange={handleNoAirportChange}
                handleFlexFilterChange={handleFlexFilterChange}
              />
            </Portal>
          )}

          <Modal
            show={openPriceModal}
            onClickOverlay={closePrice}
            transparent
            whiteBackground>
            {flightPrice ? (
              <MobileFlightPriceDetails
                price={flightPrice}
                closeModal={closePrice}
              />
            ) : (
              <div></div>
            )}
          </Modal>
          <Modal
            show={openFlightModal}
            onClickOverlay={closeFlight}
            transparent
            whiteBackground>
            <div>
              {flightInfo && (
                <MobileFlightItineraryDetails
                  segments={flightInfo.segments}
                  duration={flightInfo.duration}
                  scales={flightInfo.scales}
                  baggage={flightInfo.baggage}
                  closeModal={closeFlight}
                  direction={direction}
                />
              )}
            </div>
          </Modal>

          {/* <div className="menu-tab-holder">
            <MenuTab>
              <MenuTab.Item
                active={filters.resultListType === filterListTypes.convenientes}
                onClick={handleClickListType(filterListTypes.convenientes)}>
                +CONVENIENTES <br></br>
                {orderPriceInfo.moreConvenient.currency}{' '}
                {orderPriceInfo.moreConvenient.amount}
              </MenuTab.Item>

              <MenuTab.Item
                active={filters.resultListType === filterListTypes.economico}
                onClick={handleClickListType(filterListTypes.economico)}>
                +ECONÓMICOS <br></br>
                {orderPriceInfo.cheaper.currency}{' '}
                {orderPriceInfo.cheaper.amount}
              </MenuTab.Item>

              <MenuTab.Item
                active={filters.resultListType === filterListTypes.rapidos}
                onClick={handleClickListType(filterListTypes.rapidos)}>
                +RÁPIDOS <br></br>
                {orderPriceInfo.faster.currency} {orderPriceInfo.faster.amount}
              </MenuTab.Item>
            </MenuTab>
          </div> */}

          {hasMPCData && !hasError && (
            <MPCAvailability
              availability={mpcAvailability}
              onSelect={onSelectAvailability}
            />
          )}

          <ItauPuntosHolder>
            <ItauPuntosLogin />
          </ItauPuntosHolder>

          <OrderHolder>
            <span>Ordenar por:</span>
            <OrderSelect>
              <select onChange={handleSelectOrderBy}>
                <option value={filterListTypes.convenientes}>
                  Recomendados
                </option>
                <option value={filterListTypes.economico}>Económicos</option>
                <option value={filterListTypes.rapidos}>Rápidos</option>
              </select>
              <Arrow />
            </OrderSelect>
          </OrderHolder>

          {parseResponse &&
            parseResponse.length > 0 &&
            request.routesDataChanged && (
              <CardAlert
                nameIcon="mv-circuit"
                color="information"
                title="Tu búsqueda no coincide con los vuelos disponibles.">
                <Text paragraph>
                  Te ofrecemos{' '}
                  <b>vuelos a {request.routesData[0].destinationName}</b>, la
                  opción más conveniente para viajar desde{' '}
                  {request.routesData[0].originName}.
                </Text>
              </CardAlert>
            )}
          {parseResponse && parseResponse.length === 0 && (
            <CardAlert
              color="important"
              nameIcon="mv-information-circle"
              title={`¡Ups! no hemos encontrado vuelos a ${request.routesData[0].originName}`}>
              <Text paragraph>
                Puede ser que no haya lugar en los vuelos o que no haya vuelos
                en esos días.
                <br />
                Por favor, intenta modificar la búsqueda.
              </Text>
            </CardAlert>
          )}
          <CardsHolder>
            {Array.isArray(parseResponse) &&
              parseResponse.map((item, i) => (
                <MobileAirAvailabilityCard
                  generalSegment={item.generalSegments}
                  flightPrice={item.flightPrice}
                  localPrice={item.localPrice}
                  onSelect={onSelectAvailability(item)}
                  showPrice={() => showPrice(item.flightPrice)}
                  showFlight={showFlight}
                  id={item.id}
                  hasFlex={item.hasFlex}
                />
              ))}
          </CardsHolder>
          <MobileNavBar>
            <Button right context="purple" onClick={toggleFilters} iconLeft>
              <Icon name="mv-selection" color="white" size={20} />
              Filtros
            </Button>
          </MobileNavBar>
        </>
      )}
    </div>
  );
};
const ItauPuntosHolder = styled.div`
  width: 100%;
  padding: 0 20px 20px;
`;
const CardsHolder = styled.div`
  display: flex;
  flex-direction: column;
  gap: 40px;
  padding: 0 20px;
`;

AirAvailabilityMobile.propTypes = {
  className: PropTypes.string.isRequired,
  handleClickListType: PropTypes.func.isRequired,
  filters: PropTypes.object.isRequired,
  fareSliderDetails: PropTypes.object.isRequired,
  handleClickLuggageFilter: PropTypes.func.isRequired,
  filtersData: PropTypes.object.isRequired,
  handleClickScalesFilter: PropTypes.func.isRequired,
  handleAirlineFilterClick: PropTypes.func.isRequired,
  handleAllianceFilterClick: PropTypes.func.isRequired,
  handleFareFilterChange: PropTypes.func.isRequired,
  returnCabinCodeName: PropTypes.func,
  handleServiceClassFilterClick: PropTypes.func.isRequired,
  onSelectAvailability: PropTypes.func.isRequired,
  handleFlexFilterChange: PropTypes.func.isRequired,
  request: PropTypes.object.isRequired,
  initialDate: PropTypes.string.isRequired,
  endDate: PropTypes.string,
  isChecking: PropTypes.bool,
  handleNoAirportChange: PropTypes.func.isRequired,
  parseResponse: PropTypes.oneOfType([PropTypes.array, PropTypes.bool]),
  showErrorModal: PropTypes.bool,
  toggleErrorModal: PropTypes.func,
  orderPriceInfo: PropTypes.object
};

export default styled(AirAvailabilityMobile)`
  display: block;

  .menu-tab-holder {
    margin-top: 20px;
  }

  .card-container {
    margin-left: 14px;
    margin-right: 14px;
    margin-bottom: 5px;
  }
`;

const OrderHolder = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0px;
  gap: 7px;
  font-family: 'Itau Display Pro';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #000000;
  margin-bottom: 40px;
  margin-left: 20px;
`;

const OrderSelect = styled.div`
  background: #ffffff;
  border-radius: 10px;
  filter: drop-shadow(1px 1px 3px rgba(0, 0, 0, 0.25));
  padding: 13px 10px;
  font-family: 'Itau Display Pro';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #000000;
  display: flex;
  align-items: center;
  gap: 12px;

  select {
    appearance: none;
    border: 0;

    &:focus {
      outline: 0;
    }
  }
`;


const ButtonsHolder = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-bottom: ${props => props.theme.spacing.five};
  ${({ theme }) => theme.mediaQueries.mobile} {
    flex-direction: column;
    align-items: center;
  }
`;

const ButtonItemContainer = styled.div`
  margin: ${props => props.theme.spacing.four} 0;
`;