export const getSearchObject = ({
    destination,
    origin,
    dateFrom,
    dateTo,
  }: {
    destination: string;
    origin: string;
    dateFrom: string;
    dateTo: string;
  }) => ({
    adults: 1,
    cabinCode: 'M',
    children: [],
    groupResults: true,
    isMPC: true,
    type: 'ida-vuelta',
    routesData: [
      {
        destination: destination,
        origin: origin,
        initialDate: dateFrom,
        endDate: dateTo,
      },
    ],
  });