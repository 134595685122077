/**
 *
 * Radio
 *
 */

import React from 'react';
import styled, { css } from 'styled-components';
import { ThemeColor } from 'Theme';

export interface RadioProps<T> {
  color?: ThemeColor;
  children?: React.ReactNode;
  value: T;
  onClick: (value: T) => void;
  checked: boolean;
  addMargin?: boolean;
  className?: string;
}

const Radio = <T extends {}>({
  color = 'orange',
  children,
  value,
  checked,
  onClick,
  addMargin,
  className,
  ...rest
}: RadioProps<T>) => (
  <Container
    onClick={() => onClick(value)}
    className={className}
    addMargin={addMargin}>
    <input type="radio" {...rest} checked={checked} />
    <RadioInput checked={checked} color={color} />
    {children}
  </Container>
);

interface ContainerProps {
  addMargin: boolean;
}
const Container = styled.label<ContainerProps>`
  display: flex;
  align-items: center;
  margin: 0;
  input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }

  ${({ theme, addMargin }) =>
    addMargin &&
    css`
      margin-right: ${theme.spacing.two};
    `}
`;

interface RadioInputProps {
  color: ThemeColor;
  checked: boolean;
}
const RadioInput = styled.div<RadioInputProps>`
  ${({ theme, checked, color }) => css`
    height: 15px;
    width: 15px;
    flex-shrink: 0;
    margin-right: ${theme.spacing.two};
    border-radius: ${theme.borderRadius.rounded};
    background-color: ${theme.colors.white};
    border: 1px solid ${theme.colors.meVueloGrey};
    box-shadow: ${theme.shadows.one};
    transition: 0.3s;
    cursor: pointer;
    &:hover {
      background-color: ${theme.colors.lightGrey};
    }
    ${checked &&
      css`
        background-color: ${theme.colors[color]};
      `}
  `}
`;

export default Radio;
