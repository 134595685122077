/**
 *
 * Icon
 *
 */

import React from 'react';
import styled from 'styled-components';
import cs from 'classnames';
import '../../Assets/mv-fonts.css';
import Theme from 'Theme';
import { IconProps } from './types';

const Icon = ({
  name,
  color = 'default',
  size,
  hardColor,
  onClick,
  pointer,
  className,
  ...rest
}: IconProps) => (
  <span
    {...rest}
    onClick={onClick && onClick}
    style={hardColor && { color: hardColor }}
    className={cs('icon', `icon-${name} icon-${size}`, `${color}`, {
      [className]: className,
      pointer
    })}
  />
);

export default styled(Icon)`
  font-size: ${props => props.size || 13}px !important;
  line-height: ${props => props.size || 13}px !important;
  display: flex;
  align-items: center;
  justify-content: center;
  &.pointer {
    cursor: pointer;
  }

  &.default {
    color: ${Theme.colors.darkGrey};
  }

  &.arena {
    color: ${Theme.colors.arena};
  }

  &.secondary {
    color: ${Theme.colors.secondary};
  }

  &.important {
    color: ${Theme.colors.important};
  }

  &.violeta {
    color: ${Theme.colors.violeta};
  }

  &.information {
    color: ${Theme.colors.information};
  }

  &.positive {
    color: ${Theme.colors.positive};
  }

  &.blue {
    color: ${Theme.colors.blue};
  }

  &.warning {
    color: ${Theme.colors.warning};
  }

  &.white {
    color: ${Theme.colors.white};
  }

  &.wine {
    color: ${Theme.colors.wine};
  }
  &.orange{
    color:${Theme.colors.orange}
  }

  ${({ themeColor, theme }) =>
    themeColor && `color: ${theme.colors[themeColor]} !important`};
`;

export * from './types';
