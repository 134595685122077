import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import Divider from 'Components/Divider';
import DealButton from './DealButton';
/* import Button from 'Components/Button'; */
import { StatsFlightPrice } from 'Services/FlightsGraphql/types';
import { roundTripRequestUrl } from 'Features/flights/helpers';
import { routes } from 'Constants';
import { useHistory, useParams } from 'react-router';
import Api from 'Services'
import getDestination from 'Features/destinations/graphql/queries/getDestination';
import { getSearchObject } from 'Utils/getSearchObject';
import SkyLoader from 'Components/SkyLoader';
import { whileSearchMessages } from 'Features/flights';


const ButtonsHolder = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  margin-bottom: ${props => props.theme.spacing.five};
  ${({ theme }) => theme.mediaQueries.mobile} {
    flex-direction: column;
    align-items: center;
  }
`;

const ButtonItemContainer = styled.div`
  margin: ${props => props.theme.spacing.four} 0;
`;


interface DestinatonsRouteParams {
  destination?: string;
}


const getUrl = (state: StatsFlightPrice, destination: string): string => {
  const searchObject = getSearchObject({
    destination: state.destination,
    origin:state.origin,
    dateFrom: state.dateFrom,
    dateTo: state.dateTo,
  });
  const urlParams = roundTripRequestUrl(searchObject);

  return `${routes.flightSearch.replace(
    ':destination',
    destination
  )}${urlParams}`;
};


const BestDeals = () => {
  const [response, setResponse] = useState([]);
  const [cheapestProducts, setCheapestProducts] = useState([])
  const { destination } = useParams<DestinatonsRouteParams>();
  const history = useHistory()

  useEffect(() => {
    if (destination) {

      const fetchBanners = async () => {
        const query = getDestination(destination);
        const resultDestination = await Api.destinations.getCitiesContent(query);

        const resultCode = resultDestination.data.data.allCities.map((el) => el.iata);
        const result = await Api.flightsGraphql.getFlightsMiniBanners(resultCode);
        setResponse(result.data.data.flightPrices);

        const extractPrice = (priceString) => {
          const match = priceString.match(/\d+/);
          return match ? parseInt(match[0], 10) : 0;
        };

        const minPrice = Math.min(...result.data.data.flightPrices.map((item) => extractPrice(item.price)));
        const cheapestProducts = result.data.data.flightPrices.filter((item) => extractPrice(item.price) === minPrice);
        setCheapestProducts(cheapestProducts);

        if (cheapestProducts[0] !== undefined) {

          const currentUrl = getUrl(cheapestProducts[0], destination);
          const separator = currentUrl.includes('?') ? '&' : '?';

          const modifiedUrl = currentUrl.includes('class-of-service') && currentUrl.replace(/class-of-service=[^&]*/, 'class-of-service=null');
          const URL = `${modifiedUrl}${separator}show-suggested-date`;

          history.push(URL);

        } else {
          history.push(routes.notFound);
        }
      };

      fetchBanners();
    }
  }, [destination, history]);



  return (
    <>
      <SkyLoader messages={whileSearchMessages} className='destination' />
      {cheapestProducts[0] && (
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <div style={{ display: 'flex', justifyContent: 'center', width: '100%', textAlign: 'center' }}>
            <Divider titleText={` Los mejores precios de vuelos a ${destination}`} />
          </div>
          <ButtonsHolder>
            {response.map(banner => (
              <ButtonItemContainer key={banner.id}>
                <DealButton
                  title={banner.month}
                  price={banner.price}
                  url={destination && getUrl(cheapestProducts[0], destination)}
                />
              </ButtonItemContainer>
            ))}
          </ButtonsHolder>
        </div>
      )}
    </>
  )
};

export default BestDeals;
