import styled from 'styled-components';

export const SideContent = styled.div`
  display: flex;
  flex-direction: column;
  padding: 20px;
  width: 100%;
  gap: 20px;
`;

export const SideHeader = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 10px;
  gap: 10px;
  width: 257px;
  height: 44px;
  background: #faf2e3;
  border-radius: 10px 10px 0px 0px;
  font-family: 'Itau Display Pro';
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  color: #fb8400;
`;

export const SegmentHeader = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 0px;
  gap: 9px;
`;

export const IndicadorTramo = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0px;
  gap: 8px;
  font-family: 'Itau Display Pro';
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 22px;
  color: #282828;

  svg.rotated {
    transform: rotate(180deg);
  }
`;

export const SegmentDate = styled.div`
  font-family: 'Itau Display Pro';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  color: #282828;
`;

export const Column = styled.div`
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  padding: 0;
  gap: 8px;
  font-family: 'Itau Display Pro';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  color: rgba(40, 40, 40, 0.5);

  &.ticket {
    font-weight: 700;
  }
`;
export const SegmentItemDetailHolder = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 0px;
  gap: 8px;
  width: 100%;

  img {
    width: 30px;
    height: 30px;
    object-fit: contain;
  }
`;

export const SegmentDetails = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  width: 100%;
  padding: 0;
  gap: 12px;
`;

export const CityDataHolder = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0;
  font-family: 'Itau Display Pro';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  display: flex;
  align-items: center;
  color: #282828;

  span {
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
  }
`;

export const Divider = styled.div`
  width: 100%;
  height: 1px;
  background-color: rgba(40, 40, 40, 0.25);
`;

export const BreakdownHolder = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 10px;
  gap: 16px;
  width: 100%;
  border: 1px solid rgba(40, 40, 40, 0.25);
  border-radius: 10px;
`;

export const BreakdownTitle = styled.div`
  width: 100%;
  font-family: 'Itau Display Pro';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  text-align: center;
  color: #282828;
`;

export const BreakdownItem = styled.div`
  width: 100%;
  font-family: 'Itau Display Pro';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  text-align: center;
  color: #282828;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;

  .negro {
    color: #000;
  }
  .azul {
    color: #039;
    font-weight: 300;
  }
`;

export const BreakdownTotal = styled.div`
  width: 100%;
  font-family: 'Itau Display Pro';
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  color: #fb8400;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const BreakdownItau = styled.div`
  font-family: 'Itau Display Pro';
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  color: #003399;
  span {
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
    color: #282828;
  }
`;
