import { createStore, applyMiddleware, compose } from 'redux';
import { routerMiddleware } from 'connected-react-router';
import { createBrowserHistory } from 'history';
import { persistStore, persistReducer } from 'redux-persist';
import expireIn from 'redux-persist-transform-expire-in';
import storage from 'redux-persist/lib/storage';
import thunk from 'redux-thunk';

import createRootReducer from 'ReduxConfig';

import { initialState } from './checkout';

const expireTime = 10 * 60 * 1000; // 10 mins

const persistConfig = {
  key: 'v1',
  storage,
  whitelist: ['checkout'],
  transforms: [expireIn(expireTime, 'expirationKey', initialState)]
};

export const history = createBrowserHistory();
const enhancers = [];
const middleware = [thunk, routerMiddleware(history)];

if (process.env.NODE_ENV === 'development') {
  // @ts-ignore
  const devToolsExtension = window.__REDUX_DEVTOOLS_EXTENSION__;

  if (typeof devToolsExtension === 'function') {
    enhancers.push(devToolsExtension());
  }
}

const composedEnhancers = compose(applyMiddleware(...middleware), ...enhancers);

const rootReducer = createRootReducer(history);
const persistedReducer = persistReducer(persistConfig, rootReducer);
export type RootState = ReturnType<typeof rootReducer>;
export const store = createStore(
  persistedReducer,
  initialState,
  composedEnhancers
);
export const persistor = persistStore(store);

// export const store = createStore(rootReducer, initialState, composedEnhancers);
