/**
 *
 * MobileHotelOptionItem
 *
 */

import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import cs from 'classnames';
import * as COLORS from 'Constants/colors';
import Text from 'Components/Text';
import logoItau from 'Assets/logo_naranja.svg';
import Icon from 'Components/Icon';
import GloboToggle from 'Components/GloboToggle';
import { getFormatPrice } from 'Utils';
import { useSelector } from 'react-redux';

const MobileHotelOptionItem = ({
  className,
  item,
  index,
  active,
  handleClick
}) => {
  const { details, packagePrice, hasFreeCancellation } = item;
  const { currency, finalPrice } = packagePrice;

  const {
    selectedItauPoints,
    itauPoints: { USD }
  } = useSelector(state => state.appConfiguration);

  const costCurrencyInPoints = USD;
  const priceInPoints = Math.ceil(finalPrice / costCurrencyInPoints);
  const moneyInPoints = selectedItauPoints * costCurrencyInPoints;
  const moneyToComplete = Math.ceil(finalPrice - moneyInPoints);
  const pointsToRender =
    selectedItauPoints <= priceInPoints
      ? selectedItauPoints.toLocaleString('en').replaceAll(',', '.')
      : priceInPoints.toLocaleString('en').replaceAll(',', '.');

  return (
    <div className={cs(className, { active })}>
      <div className="innerCard">
        <div className="header">
          <div>
            <Option context="important" size="large" weight="semi-bold">
              Opción {index + 1}{' '}
            </Option>
            {hasFreeCancellation ? (
              <WayToPay context="important">
                Acepta todas las formas de pago
              </WayToPay>
            ) : (
              <div className="withIcon">
                <Icon name="mv-creditcard" color="important" size={18} />
                <WayToPay context="important">Solo tarjeta de crédito</WayToPay>
              </div>
            )}
          </div>
        </div>
        <div className="context">
          <ul>
            {details.map((d, i) => (
              <li key={i}>
                <Text context={d.context}>{d.label}</Text>
              </li>
            ))}
          </ul>
        </div>
        <hr className="separator" />
        <div className="prices">
          <div className="content-mobile-price">
            <TotalPrice>PRECIO TOTAL</TotalPrice>
            <Price context="important" size="large" weight="bold">
              {getFormatPrice(finalPrice, currency)}
            </Price>
            <br />
            {item.localPrice && (
              <LocalPrice context="important" size="large" weight="bold">
                (
                {getFormatPrice(
                  item.localPrice.finalPrice,
                  item.localPrice.currency,
                  true
                )}
                )
              </LocalPrice>
            )}
            <PromoPriceTag>
              <span className="option-itau">ó</span>
              <span>{pointsToRender} pts</span>
              <img src={logoItau} alt="itau" />
              <span>+ USD {moneyToComplete < 0 ? 0 : moneyToComplete}</span>
            </PromoPriceTag>
          </div>
          <br />
          <div className="content-toggle">
            <GloboToggle active={active} onClick={handleClick} />
            {active ? (
              <p className="option-select">Tarifa seleccionada</p>
            ) : (
              <p className="select">Seleccionar tarifa</p>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

MobileHotelOptionItem.propTypes = {
  className: PropTypes.string.isRequired,
  index: PropTypes.number.isRequired,
  item: PropTypes.object,
  active: PropTypes.bool,
  handleClick: PropTypes.func
};

const Option = styled.span`
  color: #fb8400;
  width: 275px;
  display: flex;
  justify-content: center;
  text-align: center;
  font-family: Itau Display Pro;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px;
`;

const WayToPay = styled.span`
  color: #fb8400;
  text-align: center;
  font-family: Itau Display Pro;
  font-size: 16px;
  font-style: normal;
  font-weight: 300;
  line-height: 24px;
  margin-left: 10px;
`;

const TotalPrice = styled.h2`
  color: #282828;
  text-align: center;
  font-family: Itau Display Pro;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
`;

const Price = styled.span`
  color: #fb8400;
  font-family: Itau Display Pro;
  font-size: 22px;
  font-style: normal;
  font-weight: 400;
  line-height: 30px;
`;

const LocalPrice = styled.span`
  color: #fb8400;
  font-family: Itau Display Pro;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px; /* 157.143% */
`;
export default styled(MobileHotelOptionItem)`
  display: flex;
  background: transparent;
  border-radius: 15px;
  flex-direction: column;
  align-items: center;
  padding: 5px;
  width: 320px;

  .content-toggle {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .content-toggle .option-select {
    color: #fb8400;
    font-family: Itau Display Pro;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 24px;
  }
  .content-toggle p {
    color: rgba(40, 40, 40, 0.5);
    font-family: Itau Display Pro;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px; /* 150% */
  }
  &.active {
    box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.35);
    background-image: linear-gradient(
      to right,
      ${COLORS.gradientDefault.left},
      ${COLORS.gradientDefault.right}
    );

    .text {
      visibility: visible;
    }
  }

  &.text {
    visibility: hidden;
  }

  .innerCard {
    display: flex;
    flex-direction: column;
    border-radius: 15px;
    background-color: ${COLORS.white};
    box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.35);
    width: 100%;
  }

  .header {
    background-color: ${COLORS.golf};
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 5px;
    width: 100%;
    border-radius: 15px 15px 0 0;
    box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.35);

    .withIcon {
      display: flex;
    }

    .icon {
      display: flex;
      align-items: center;
      margin-left: 5px;
    }
  }

  .context {
    background-color: ${COLORS.white};
    padding: 5px;
    width: 100%;

    ul {
      list-style: none;
      margin: 0;
      padding: 0;

      li {
        padding-left: 1em;
        text-indent: -0.7em;
        margin-bottom: 10px;
      }
    }
  }

  .prices {
    display: flex;
    flex-direction: column;
    padding: 5px;
    width: 100%;
    border-radius: 0 0 15px 15px;
  }

  .separator {
    height: 5px;
    box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.35);
    background-color: ${COLORS.greyBkg};
    margin: 0;
    width: 100%;
  }
  .content-mobile-price {
    padding: 10px 20px;
    border-radius: 10px;
    border: 1px solid rgba(40, 40, 40, 0.1);
  }
`;

export const PromoPriceTag = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 0px;
  gap: 10px;
  font-family: 'Itau Display Pro';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;

  .option-itau {
    color: #282828;
    font-family: Itau Display Pro;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
  }
  span {
    color: #039;
  }

  img {
    width: 24px;
  }
`;
