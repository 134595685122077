/**
 *
 * Calendar
 *
 */

import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import { useSelector } from 'react-redux';

import { getAirLineLogo, isoDateToFullDate } from 'Utils';
import { ReactComponent as ItauLogo } from 'Assets/logo_naranja.svg';

import { Escalador } from '../Esclador';
import { MobileAirItineraryDetails } from '../MobileAirItineraryDetails';

import { excludeAirlineBaggage } from 'Constants';

import { ReactComponent as Avion } from '../AirAvailabilityCard/plane.svg';
import { ReactComponent as Mochila } from '../AirAvailabilityCard/mochila.svg';
import { ReactComponent as CarryOn } from '../AirAvailabilityCard/carryon.svg';
import { ReactComponent as Suitcase } from '../AirAvailabilityCard/suitcase.svg';

const MobileAirAvailabilityCard = ({
  className,
  generalSegment,
  flightPrice,
  localPrice,
  onSelect,
  showPrice,
  showFlight,
  id,
  hasFlex
}) => {
  const [selectedSegments, setSelectedSegments] = useState({
    0: { index: 0 },
    1: { index: 0 }
  });

  const idaSegments = generalSegment[0];
  const airlineIda = idaSegments[0].airLines[0];
  const escalasIda = idaSegments[0].scales.length;
  const summaryIda = idaSegments[0].summaryDateAndAirports;
  const departTimeIda = idaSegments[0].departureData.flightTime;
  const arrivalTimeIda = idaSegments[0].arrivalData.flightTime;

  const showSuitcaseIda = !(
    idaSegments[0].baggage.total === 0 &&
    excludeAirlineBaggage.includes(idaSegments[0].airLines[0].code)
  );

  const showMochila =
    idaSegments[0].baggage.total === 0 &&
    excludeAirlineBaggage.includes(idaSegments[0].airLines[0].code)

  const showCarryOnIda = !(idaSegments[0].baggage.total === 0);

  const calculateReturnSegmentConstants = (vueltaSegments) => {
    if (vueltaSegments !== undefined) {
      const airlineVuelta = vueltaSegments[0].airLines[0];
      const summaryVuelta = vueltaSegments[0].summaryDateAndAirports;
      const departTimeVuelta = vueltaSegments[0].departureData.flightTime;
      const arrivalTimeVuelta = vueltaSegments[0].arrivalData.flightTime;
      const escalasVuelta = vueltaSegments[0].scales.length;

      return {
        airlineVuelta,
        summaryVuelta,
        departTimeVuelta,
        arrivalTimeVuelta,
        escalasVuelta,
      };
    } else {
      return [];
    }
  }

  const vueltaSegments = generalSegment[1];
  const {
    airlineVuelta,
    summaryVuelta,
    departTimeVuelta,
    arrivalTimeVuelta,
    escalasVuelta,
  } = calculateReturnSegmentConstants(vueltaSegments);


  const showSuitcaseVuelta = !(
    idaSegments[0].baggage.total === 0 &&
    excludeAirlineBaggage.includes(idaSegments[0].airLines[0].code)
  );
  const showMochilaVuelta =
    idaSegments[0].baggage.total === 0 &&
    excludeAirlineBaggage.includes(idaSegments[0].airLines[0].code)

  const showCarryOnVuelta = !(idaSegments[0].baggage.total === 0);

  const {
    selectedItauPoints,
    itauPoints: { USD }
  } = useSelector(state => state.appConfiguration);

  const costCurrencyInPoints = USD;
  const priceInPoints = Math.ceil(
    flightPrice.totalPrice / costCurrencyInPoints
  );
  const moneyInPoints = selectedItauPoints * costCurrencyInPoints;
  const moneyToComplete =
    Math.ceil((flightPrice.totalPrice - moneyInPoints) * 100) / 100;
  const pointsToRender =
    selectedItauPoints <= priceInPoints
      ? selectedItauPoints.toLocaleString('en').replaceAll(',', '.')
      : priceInPoints.toLocaleString('en').replaceAll(',', '.');

  const [showDetails, setShowDetails] = useState(false);

  const toggleDetails = () => {
    setShowDetails(prevState => !prevState);
  };

  useEffect(() => {
    const ida = generalSegment[0];
    const vuelta = generalSegment[1] ? generalSegment[1] : false;

    setSelectedSegments({
      0: { index: 0, item: ida[0] },
      1: { index: 0, item: vuelta[0] }
    });
  }, [generalSegment]);

  return (
    <Container>
      <SegmentHeader>
        <SegmentType>
          <Avion /> IDA
        </SegmentType>
        <SegmentDate>{isoDateToFullDate(summaryIda.flightDate)}</SegmentDate>
      </SegmentHeader>
      <AirLine>
        <img style={{ maxWidth: 100 }} src={getAirLineLogo(airlineIda.code)} alt={airlineIda.name} />
        <span>{airlineIda.name}</span>
      </AirLine>
      <Details>
        <AirportTime>
          <span className="airport">{summaryIda.departureAirport.code}</span>
          <span>{departTimeIda}</span>
        </AirportTime>

        <Escalador stopovers={escalasIda} />

        <AirportTime>
          <span className="airport">{summaryIda.arrivalAirport.code}</span>
          <span>{arrivalTimeIda}</span>
        </AirportTime>

        <Lugage>
          {showSuitcaseIda && <Suitcase />}
          {showCarryOnIda && <CarryOn />}
          {showMochila && <Mochila />}
        </Lugage>
      </Details>

      {generalSegment[1] !== undefined && (
        <>
          <SegmentHeader>
            <SegmentType>
              <Avion className="rotated" /> Vuelta
            </SegmentType>
            <SegmentDate>{isoDateToFullDate(summaryVuelta.flightDate)}</SegmentDate>
          </SegmentHeader>
          <AirLine>
            <img
              style={{ maxWidth: 100 }}
              src={getAirLineLogo(airlineVuelta.code)}
              alt={airlineVuelta.name}
            />
            <span>{airlineVuelta.name}</span>
          </AirLine>

          <Details>
            <AirportTime>
              <span className="airport">{summaryVuelta.departureAirport.code}</span>
              <span>{departTimeVuelta}</span>
            </AirportTime>

            <Escalador stopovers={escalasVuelta} />

            <AirportTime>
              <span className="airport">{summaryVuelta.arrivalAirport.code}</span>
              <span>{arrivalTimeVuelta}</span>
            </AirportTime>

            <Lugage>
              {showSuitcaseVuelta && <Suitcase />}
              {showCarryOnVuelta && <CarryOn />}
              {showMochilaVuelta && <Mochila />}
            </Lugage>
          </Details>
        </>
      )}
      <PricingHolder>
        <PricingLeftColumn>
          <span>Precio total final</span>
          <span className="dolar">
            {flightPrice.currency} {flightPrice.totalPrice}
          </span>
          <span className="grs">
            ({localPrice.currency} {localPrice.totalPrice})
          </span>
        </PricingLeftColumn>
        <BlueText>
          ó <span>{pointsToRender}</span> puntos <ItauLogo />
          <br />+ <span>USD {moneyToComplete < 0 ? 0 : moneyToComplete}</span>
        </BlueText>
      </PricingHolder>
      <ButtonHolder>
        <Button onClick={toggleDetails}>Ver más</Button>
      </ButtonHolder>
      {showDetails && (
        <MobileAirItineraryDetails
          onClose={toggleDetails}
          generalSegment={generalSegment}
          pointsToRender={pointsToRender}
          moneyToComplete={moneyToComplete}
          localPrice={localPrice.totalPrice}
          localCurrency={localPrice.currency}
          price={flightPrice.totalPrice}
          currency={
            flightPrice.breakdown[0].currency
              ? flightPrice.breakdown[0].currency
              : 'USD'
          }
          onBuy={() =>
            onSelect(
              { ida: selectedSegments[0], vuelta: selectedSegments[1] },
              id
            )
          }
        />
      )}
    </Container>
  );
};

const Button = styled.div`
  cursor: pointer;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 10px 30px;
  gap: 10px;
  width: 100%;
  height: 40px;
  background: #fb8400;
  border-radius: 10px;
  font-family: 'Itau Display Pro';
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 28px;
  text-align: center;
  color: #ffffff;
`;

const ButtonHolder = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 10px;
  gap: 10px;
  width: 100%;
  min-height: 60px;
`;

const BlueText = styled.div`
  font-family: 'Itau Display Pro';
  font-style: normal;
  font-weight: 300;
  font-size: 14px;
  line-height: 22px;
  color: #003399;
  text-align: center;

  span {
    font-weight: 700;
  }

  svg {
    width: 24px;
    height: 24px;
  }
`;

const PricingHolder = styled.div`
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 8px 10px;
  gap: 46px;
  width: 100%;
  min-height: 92px;
  border-top: 1px solid rgba(40, 40, 40, 0.25);
`;

const PricingLeftColumn = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  padding: 0px;
  width: 127px;
  min-height: 76px;
  font-family: 'Itau Display Pro';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #fb8400;

  span.dolar {
    font-size: 22px;
    line-height: 30px;
  }
  span.grs {
    font-size: 14px;
    line-height: 22px;
  }
`;

const Lugage = styled.div`
  display: flex;
  gap: 4px;
  align-items: flex-end;
`;

const AirportTime = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;
  font-family: 'Itau Display Pro';
  font-style: normal;
  font-weight: 300;
  font-size: 14px;
  line-height: 22px;
  color: #282828;

  span.airport {
    font-weight: 700;
    opacity: 0.3;
  }
`;

const Details = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 0px 8px;
  gap: 34px;

  width: 100%;
  height: 49px;
`;

const AirLine = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  font-family: 'Itau Display Pro';
  font-style: normal;
  font-weight: 300;
  font-size: 14px;
  line-height: 22px;
  color: #282828;
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  padding: 0px;
  gap: 16px;
  width: 100%;
  min-width: 320px;
  background: #ffffff;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.25);
  border-radius: 20px;
  overflow: hidden;
`;

const SegmentHeader = styled.div`
  height: 38px;
  background: #faf2e3;
  width: 100%;
  display: flex;
  align-items: center;
  padding: 9px 8px;
  justify-content: space-between;
`;

const SegmentType = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 0px;
  gap: 8px;
  font-family: 'Itau Display Pro';
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 22px;
  color: #282828;

  svg.rotated {
    transform: rotate(180deg);
  }
`;

const SegmentDate = styled.div`
  font-family: 'Itau Display Pro';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  color: #282828;
`;

export const FlexibleContainer = styled.div`
  margin-bottom: 12px;
  ${({ hasFlex, theme }) =>
    hasFlex &&
    css`
      border-radius: 14px;
      background-color: ${theme.palette.primary.orange};
      padding-top: ${theme.spacing.two};
      padding-bottom: ${theme.spacing.two};
      .holder-detail-prices .header--mpc: {
        border-radius: 0 !important;
      }
    `}
`;

export const FlexText = styled.div`
  ${({ theme }) => css`
    color: ${theme.colors.white};
    width: 100%;
    padding: ${theme.spacing.two};
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 12px;
    .icon {
      margin-right: ${theme.spacing.three};
    }
  `}
`;

MobileAirAvailabilityCard.propTypes = {
  className: PropTypes.string,
  generalSegment: PropTypes.array.isRequired,
  flightPrice: PropTypes.object,
  localPrice: PropTypes.object,
  onSelect: PropTypes.func,
  showPrice: PropTypes.func,
  showFlight: PropTypes.func,
  hasFlex: PropTypes.bool,
  id: PropTypes.string
};

export default MobileAirAvailabilityCard;
