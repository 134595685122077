/**
 *
 * MobileMpcItem
 *
 */

import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import cs from 'classnames';
import Text from 'Components/Text';
import * as COLORS from 'Constants/colors';
import { mpcItemStatus } from 'Features/flights/constants';
import { getClassColors } from 'Features/flights/helpers';

const MobileMpcItem = ({
  className,
  status,
  currency,
  price,
  onClick,
  empty,
  isCenter
}) => {
  if (empty && status === mpcItemStatus.EMPTY)
    return <div className={cs(className, 'EMPTY')}></div>;

  const { minPrice, middlePrice, maxPrice } = status;
  const itemStatus = getClassColors(price, minPrice, middlePrice, maxPrice);

  return (
    <div
      className={cs(className, `${itemStatus}`, { isCenter })}
      onClick={onClick}>
      {status && (
        <>
          <span> {currency}</span>

          {price}
        </>
      )}
    </div>
  );
};

MobileMpcItem.propTypes = {
  className: PropTypes.string.isRequired,
  status: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.shape({
      minPrice: PropTypes.number,
      middlePrice: PropTypes.number,
      maxPrice: PropTypes.number
    })
  ]),
  currency: PropTypes.string,
  price: PropTypes.number,
  onClick: PropTypes.func,
  empty: PropTypes.bool,
  isCenter: PropTypes.bool
};

export default styled(MobileMpcItem)`
  height: 48px;
  width: 14.28%;
  display: flex;
  flex-direction: column;
  background: #FFFFFF;
  border: 1px solid rgba(40, 40, 40, 0.1);
  justify-content: center;
  align-items: center;
  text-align: center;
  transition: 0.3s;

  font-family: 'Itau Display Pro';
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 18px;

  color: #FB8400;

  span {
    font-size: 8px;
    line-height: 10px;
  }

  .content-icon {
    height: 24px;
  }

  .content-spinner {
    img {
      width: 24px;
      height: 24px;
    }
  }
/* 
  &.MORE_EXPENSIVE {
    background-color: ${COLORS.white};
  }

  &.CHEAPER_MIN {
    background-color: ${COLORS.white};
  }

  &.CHEAPER_60 {
    background-color: ${COLORS.sandyBeach};
  }

  &.CHEAPER_80 {
    background-color: ${COLORS.peachYellow};
  } */

  &.CHEAPER_MAX {
    background: rgba(76, 175, 80, 0.1);
    border: 1px solid rgba(40, 40, 40, 0.1);
    color: #4CAF50;
  }

  &.EMPTY {
    background-color: ${COLORS.navBarGrey};
  }

  &.isCenter {
    z-index: 1;
    background: #FB8400;
    border: 1px solid rgba(40, 40, 40, 0.1);
    color: white;
  }

  &:hover {
    box-shadow: 4.6px 3.9px 6px 0 rgba(0, 0, 0, 0.35);
  }

  .currency {
    font-size: 10px;
    text-transform: uppercase;
  }

  .price {
    font-size: 11px;
  }

  &.empty {
    border: none;
  }


`;
