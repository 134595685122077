import React from 'react';
import styled from 'styled-components';

interface Props {
  stopovers: number;
}

export const Escalador = ({ stopovers }: Props) => (
  <Container>
    {stopovers === 0 ? (
      <Texto>sin escalas</Texto>
    ) : (
      <Texto>
        {stopovers} {stopovers > 1 ? 'escalas' : 'escala'}
      </Texto>
    )}

    <Bar>
      {stopovers > 0 && [...Array(stopovers).keys()].map((_item, index) => (
        <Pelotita key={index} />
      ))}
    </Bar>
  </Container>
);

const Pelotita = styled.div`
  width: 8px;
  height: 8px;
  border-radius: 50%;
  border: 1px solid #fb8400;
  background-color: white;
`;

const Bar = styled.div`
  width: 100%;
  height: 2px;
  background: #fb8400;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: space-around;
`;

const Texto = styled.div`
  font-family: 'Itau Display Pro';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  display: flex;
  align-items: center;
  text-align: center;
  color: #282828;
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0px;
  gap: 4px;
  width: 69px;
  height: 34px;
`;
