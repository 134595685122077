import styled from 'styled-components';
import * as COLORS from 'Constants/colors';

export default DesktopPackageSearch => styled(DesktopPackageSearch)`
  display: block;
  min-height: 20px;
  max-width: 1140px;
  margin-top: 70px;
  width: 100%;

  .tags-holder {
    display: flex;
    margin-left: 30px;
    margin-top: 20px;
  }

  .form-item {
    display: flex;
    width:100%;
    align-items: center;
    margin-right: 10px;
    gap: 7px;

    label {
      margin: 0;
      white-space: nowrap;
      margin-right: 18px;
      width:30%;
    }
    .label-input{
      width:40%
      padding-left: 8%;
    }

    &.top-margin {
      margin-top: 8px;
    }

    &.to-right {
      justify-content: flex-end;
    }
    #downshift-0-toggle-button{
      box-shadow: 1px 1px 3px 0px #00000040;
    }
    .mv-input{
      width:100%
      margin-left:40px;
    }
    .form-item_label-holder {
      margin-right: 8px;
    }
    .input-mevuelo{
      box-shadow: 4px 0px 8px 0px #00000040;
    }
    &.space-between {
      justify-content: space-between;
    }
  }

  .package-search_top-card {
    background-color: transparent;
    box-shadow: ${COLORS.shadows.three};
    overflow: hidden;

    .package-search-tabs_holder {
      display: flex;

      .package-search-tabs_item {
        transition: 0.2s;
        cursor: pointer;
        flex: 1;
        display: flex;
        justify-content: center;
        align-items: center;
        height: 50px;
        background: #faf2e3;
        border-radius: 10px 10px 0 0;
        font-family: 'Itau Display Pro';
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
        color: #28282880;


        &.active {
          background: #ffffff;
          box-shadow: 4px 0px 8px rgba(0, 0, 0, 0.25);
          color: #fb8400;
        }
      }
    }

    .package-search-options_holder {
      min-height: 218px;
      background: white;
      box-shadow: ${COLORS.shadows.three};
      display: flex;
      flex-direction: column;
      justify-content: center;
      padding: 10px 0;

      .icons-list {
        display: flex;
        justify-content: space-evenly;
        align-items: center;

        &.wrap {
          flex-wrap: wrap;
        }
      }
    }
  }

  @media screen and (max-width: 1140px) {
    width: 90%;

    .package-search_top-card {
      .package-search-options_holder {
        max-height: 218px;

        .icons-list_holder {
          overflow-y: auto;
          margin: 0 5px;
          display: flex;
          justify-content: center;
          align-items: center;

          .icons-list {
            display: flex;
            justify-content: center;
            align-items: center;

            &.wrap {
              flex-wrap: nowrap;
            }
          }
        }
      }
    }
  }
`;

export const Card = styled.div`
  background: white;
  padding: 30px 40px 30px;
  border-radius: 0px 0px 10px 10px;
`;

export const Label = styled.div`
  font-family: 'Itau Display Pro';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #000000;
`;
