import React from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Text from 'Components/Text';
import Icon from 'Components/Icon';
import StarsFilter from 'Components/StarsFilter';
import RatingMolecule from 'Components/RatingMolecule';
import Button from 'Components/Button';
import * as COLORS from 'Constants/colors';
import { getFormatPrice } from 'Utils';
import LazyImg from 'Components/LazyImg';
import hotelGeneric from 'Assets/images/Hotel-Generic.jpg';
import Badge from 'Components/Badge';
import ItauLogo from 'Assets/logo_naranja.svg';

const HotelName = styled.div`
  padding: 0 10px;
  color: #282828;
  font-family: Itau Display Pro;
  font-size: 22px;
  font-style: normal;
  font-weight: 700;
  line-height: 30px;
`;

const Divider = styled.div`
  height: 1px;
  background: #c4c4c4;
  margin: 10px;
  width: calc(100% - 20px);
`;

const PriceHolder = styled.div`
  display: flex;
  padding-bottom: 0px;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  align-self: stretch;
  color: #282828;
  font-family: 'Itau Display Pro';
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
  padding: 0 10px 10px;

  .precio-itau {
    display: flex;
    gap: 3px;
    & > svg {
      margin: 0 4px;
    }
  }
`;

const PriceUSD = styled.div`
  font-size: 22px;
  line-height: 30px;
  color: #fb8400;
`;
const PricePYG = styled.div`
  color: #fb8400;
`;

const BlueText = styled.span`
  color: #039;
  font-size: 16px;
  line-height: 24px;
`;

const MobileHotelCard = ({
  className,
  address,
  starRating,
  keyRating,
  textRating,
  wifi,
  parking,
  pool,
  breakfast,
  isAllInclusive,
  propertyName,
  currency,
  price,
  localPrice,
  localCurrency,
  onSelect,
  thumbnail,
  airConditioning,
  gym,
  reviews,
  showFromPrice,
  isMap,
  onClose
}) => {
  const {
    selectedItauPoints,
    itauPoints: { USD }
  } = useSelector(state => state.appConfiguration);

  const costCurrencyInPoints = USD;
  const priceInPoints = Math.ceil(price / costCurrencyInPoints);
  const moneyInPoints = selectedItauPoints * costCurrencyInPoints;
  const moneyToComplete = Math.ceil((price - moneyInPoints) * 100) / 100;
  const pointsToRender =
    selectedItauPoints <= priceInPoints
      ? selectedItauPoints.toLocaleString('en').replaceAll(',', '.')
      : priceInPoints.toLocaleString('en').replaceAll(',', '.');
  return (
    <div className={className} onClick={isMap ? () => {} : onSelect}>
      <div className="mhc-container">
        {/* <div className="mhc-header">
        <Text context="white" weight="semi-bold" size="large" paragraph>
          {propertyName}
        </Text>
        {isMap ? (
          <Icon
            name="mv-cross"
            color="white"
            className="close-icon"
            onClick={onClose}
          />
        ) : null}
      </div> */}
        <div className="mhc-body">
          <div className="mhc-container-img">
            <LazyImg
              src={thumbnail}
              alt={propertyName}
              className="mhc-container-img"
              skip={isMap}
              imgFallback={hotelGeneric}
            />
            {isAllInclusive && (
              <div className="all-inclusive-tag">
                <Icon name="mv-cocktail" color="white" size={24} />
                <span>All Inclusive</span>
              </div>
            )}
            {isMap && (
              <div className="facilities-icons-container">
                {wifi && <Icon size={20} color="important" name="wifi" />}
                {parking && (
                  <Icon size={20} color="important" name="mv-car-parking" />
                )}
                {pool && (
                  <Icon size={20} color="important" name="mv-swimming-pool" />
                )}
                {breakfast && (
                  <Icon size={20} color="important" name="mv-breakfast" />
                )}
                {airConditioning && (
                  <Icon size={20} color="important" name="mv-sun" />
                )}
                {gym && <Icon size={20} color="important" name="mv-gym" />}
              </div>
            )}
          </div>

          <HotelName>{propertyName}</HotelName>

          {/* {!isMap && (
          <div className="mhc-container-loc">
            <Icon name="mv-location-2" color="important" size={15} />
            <Text paragraph weight="semi-bold">
              {address}
            </Text>
          </div>
        )} */}
          {isMap ? (
            <div className="map-details-container">
              <div className="map-ratings-row">
                {starRating && (
                  <StarsFilter justDisplay initialValue={starRating} />
                )}
                {keyRating && (
                  <StarsFilter
                    justDisplay
                    initialValue={keyRating}
                    isKey={keyRating}
                  />
                )}
                {textRating && (
                  <Badge variation="yellow" inverted>
                    {textRating}
                  </Badge>
                )}
              </div>
              <div className="map-details-row">
                {showFromPrice && (
                  <div className="map-container-price">
                    <Text
                      context="important"
                      weight="semi-bold"
                      className="price-item">
                      Precio por noche desde
                    </Text>
                    <Text
                      weight="bold"
                      context="important"
                      size="xlarge"
                      className="price-item">
                      {getFormatPrice(price, currency)}
                    </Text>
                    {localPrice && (
                      <Text
                        weight="semi-bold"
                        size="large"
                        context="important"
                        className="price-item">
                        {getFormatPrice(localPrice, localCurrency, 'es-PY')}
                      </Text>
                    )}
                  </div>
                )}
                <Button className="semi-bold" onClick={onSelect}>
                  Ver Oferta
                </Button>
              </div>
            </div>
          ) : (
            <>
              <div className="mhc-container-rate-service">
                <div>
                  {starRating && (
                    <StarsFilter justDisplay initialValue={starRating} />
                  )}
                  {keyRating && (
                    <StarsFilter
                      justDisplay
                      initialValue={keyRating}
                      isKey={keyRating}
                    />
                  )}
                  {textRating && (
                    <Badge variation="yellow" inverted>
                      {textRating}
                    </Badge>
                  )}
                  {reviews && (
                    <RatingMolecule
                      className="container-rating"
                      rating={reviews.rating}
                      amountReviews={reviews.amountReviews}
                      ratingText={reviews.ratingText}
                    />
                  )}
                </div>
                <div>
                  <div className="mhc-container-rate-service-services">
                    <Icon
                      size={20}
                      color={wifi ? 'important' : 'default'}
                      name="wifi"
                    />
                    <Icon
                      size={20}
                      color={parking ? 'important' : 'default'}
                      name="mv-car-parking"
                    />
                    <Icon
                      size={20}
                      color={pool ? 'important' : 'default'}
                      name="mv-swimming-pool"
                    />
                    <Icon
                      size={20}
                      color={breakfast ? 'important' : 'default'}
                      name="mv-breakfast"
                    />
                    <Icon
                      size={20}
                      color={airConditioning ? 'important' : 'default'}
                      name="mv-sun"
                    />
                    <Icon
                      size={20}
                      color={gym ? 'important' : 'default'}
                      name="mv-gym"
                    />
                  </div>
                </div>
              </div>

              <Divider />

              <PriceHolder>
                <div>Precio por noche desde</div>
                <PriceUSD>{getFormatPrice(price, currency)}</PriceUSD>
                <PricePYG>
                  {getFormatPrice(localPrice, localCurrency, true)}
                </PricePYG>
                <div className="precio-itau">
                  ó <BlueText>{pointsToRender} pts</BlueText>
                  <img src={ItauLogo} style={{width: 23}} />{' '}
                  <BlueText>
                    + USD {moneyToComplete < 0 ? 0 : moneyToComplete}
                  </BlueText>
                </div>
              </PriceHolder>

              {/* <div className="mhc-container-price">
              {showFromPrice ? (
                <div className="container-price">
                  <Text className="from" paragraph context="important">
                    Precio por noche desde
                  </Text>
                  <Text
                    className="price"
                    paragraph
                    weight="semi-bold"
                    context="important">
                    {getFormatPrice(price, currency)}
                  </Text>
                  {localPrice && (
                    <Text
                      className="price"
                      paragraph
                      weight="semi-bold"
                      context="important">
                      {getFormatPrice(localPrice, localCurrency, true)}
                    </Text>
                  )}
                </div>
              ) : (
                ''
              )}
              {reviews && (
                <RatingMolecule
                  className="container-rating"
                  rating={reviews.rating}
                  amountReviews={reviews.amountReviews}
                  ratingText={reviews.ratingText}
                />
              )}
            </div> */}
            </>
          )}
        </div>
      </div>
    </div>
  );
};

MobileHotelCard.propTypes = {
  className: PropTypes.string.isRequired,
  starRating: PropTypes.number,
  keyRating: PropTypes.number,
  textRating: PropTypes.string,
  wifi: PropTypes.bool,
  parking: PropTypes.bool,
  pool: PropTypes.bool,
  breakfast: PropTypes.bool,
  isAllInclusive: PropTypes.bool,
  propertyName: PropTypes.string,
  currency: PropTypes.string,
  localCurrency: PropTypes.string,
  localPrice: PropTypes.number,
  price: PropTypes.number,
  onSelect: PropTypes.func,
  thumbnail: PropTypes.string,
  address: PropTypes.string,
  airConditioning: PropTypes.bool,
  gym: PropTypes.bool,
  reviews: PropTypes.shape({
    rating: PropTypes.number,
    amountReviews: PropTypes.number,
    ratingText: PropTypes.string
  }),
  showFromPrice: PropTypes.bool,
  onClose: PropTypes.func,
  isMap: PropTypes.bool
};

MobileHotelCard.defaultProps = {
  showFromPrice: true
};
export default styled(MobileHotelCard)`
  .mhc-container {
    display: flex;
    flex-direction: column;
    border-radius: 10px 10px 18px 18px;
    overflow: hidden;
    box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.25);
  }

  .mhc-header {
    width: 100%;
    /* border-radius: 14px; */
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
    box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.35);
    background-color: ${COLORS.violeta};
    padding: 5px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .icon {
      margin-right: 4px;
    }

    p {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

  .mhc-body {
    width: 100%;
    height: auto;
    /* border-radius: 14px; */
    border-top-right-radius: 0;
    border-top-left-radius: 0;
    box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.35);
    background-color: ${COLORS.white};
  }

  .mhc-container-img {
    width: 100%;
    min-height: 150px;
    background-position: center;
    background-size: cover;
    position: relative;

    .all-inclusive-tag {
      width: 120px;
      height: 28px;
      box-shadow: 0 3px 7px 0 rgba(0, 0, 0, 0.35);
      background-image: linear-gradient(
        to right,
        ${COLORS.eminence},
        ${COLORS.otroVioleta}
      );
      bottom: 0;
      left: 0;
      position: absolute;
      border-top-right-radius: 14px;
      color: ${COLORS.white};
      display: flex;
      align-items: center;
      justify-content: space-evenly;

      .all-in-icon {
        width: 15px;
        height: 17px;
        background-image: url('../../../Assets/images/hotels/shape-2913.png');
        background-position: center;
        background-size: contain;
        background-repeat: no-repeat;
      }
    }

    .facilities-icons-container {
      box-shadow: ${COLORS.shadows.two};
      background-color: ${COLORS.white};
      border-top-right-radius: 14px;
      display: flex;
      align-items: center;
      position: absolute;
      bottom: 0;
      left: 0;
      padding-left: 4px;

      .icon {
        margin-right: 4px;
      }
    }
  }

  .mhc-container-loc {
    height: 28px;
    display: flex;
    margin-left: 10px;
    margin-right: 10px;
    margin-top: 5px;

    img {
      width: 15px;
      height: 20px;
    }

    p {
      color: ${COLORS.darkGrey};
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      margin-left: 5px;
    }
  }

  .mhc-container-rate-service {
    display: flex;
    margin-left: 10px;
    margin-right: 10px;
    flex-direction: row;

    & > div {
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
    }

    .mhc-container-rate-service-services {
      margin-left: 5px;
      display: flex;
      width: 100%;
      justify-content: space-around;
    }
  }

  .mhc-container-price {
    display: flex;
    flex-direction: row-reverse;
    margin-left: 10px;
    margin-right: 10px;
    height: 75px;
    justify-content: space-between;
    cursor: pointer;

    .container-price {
      display: flex;
      height: 30px;
      flex-direction: column;

      .from {
        font-size: 12px;
        text-align: right;
      }

      .price {
        font-size: 18px;
        line-height: 1.44;
        letter-spacing: 0.18px;
        text-align: right;
      }
    }
  }

  .map-container-price {
    display: flex;
    flex-direction: column;

    .price-item {
      margin: 0;
      line-height: normal;
    }
  }

  .map-details-row {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .map-ratings-row {
    display: flex;
    align-items: center;

    .container-rating {
      margin-left: 12px;
    }
  }

  .map-details-container {
    padding: 4px;
  }

  .close-icon {
    cursor: pointer;
  }
`;
