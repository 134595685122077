import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';
import { updateLoader, updateItauPoints } from 'Features/app/actions';

import sliderThumb from 'Assets/logo_naranja.svg';
import api from 'Services';
import { updateItauValues } from 'ReduxConfig/itau';
import Cookies from 'js-cookie';
import { clearItauToken, doLogin, getItauToken } from './utils';

export const ItauPuntosDesktop = () => {
  const { selectedItauPoints, maxItauPuntos, pointsClient } = useSelector(
    // @ts-ignore reducers not typed?
    ({ appConfiguration, itau }) => ({
      selectedItauPoints: appConfiguration.selectedItauPoints,
      maxItauPuntos: appConfiguration.maxItauPuntos,
      pointsClient: itau.pointsClient
    })
  );
  const [value, setValue] = useState(0);
  const dispatch = useDispatch();

  useEffect(() => {
    const updatePointsClient = async () => {
      const token = getItauToken();
      if (token) {
        try {
          const result = await api.itau.clientClientPoints(token);
          dispatch(updateItauValues(result.data));
        } catch (_e) {
          clearItauToken();
        }
      }
    };
    updatePointsClient();
    setValue(selectedItauPoints);
  }, []);

  useEffect(() => {
    dispatch(updateItauPoints({ points: value }));
  }, [value, dispatch]);

  const isLogged = Boolean(getItauToken());
  const finalMaxValue = isLogged
    ? pointsClient.reduce(
        (accumulator, membership) => accumulator + membership.points,
        0
      )
    : maxItauPuntos;

  const valueToRender = value.toLocaleString('en').replaceAll(',', '.');
  return (
    <ContentWrapper >
    <Container>
      <Holder>
        <BannerHolder>
          <ContentText>
            <BannerTextoHolder>
              <PrimerTexto>
                ¡Viajá gratis o pagá menos con tus <span>puntos Itaú</span>!
              </PrimerTexto>
              <SegundoTexto>
              Deslizá para conocer el beneficio de tus puntos Itaú.
              </SegundoTexto>
            </BannerTextoHolder>
            <SliderHolder>
              <Puntos>{valueToRender}</Puntos>
              <input
                type="range"
                min="0"
                max={finalMaxValue}
                onChange={e => setValue(Number(e.target.value))}
                value={value}
              />
            </SliderHolder>
          </ContentText>
          <LoginHolder>
            {!isLogged && (
              <BlueText>
                Para ver tus puntos reales Itaú, accedé a tu cuenta.
              </BlueText>
            )}
            <ButtonsHolder>
              {isLogged ? (
                <Button1
                  onClick={() => {
                    Cookies.remove('itau-token');
                    window.location.reload();
                  }}>
                  Logout
                </Button1>
              ) : (
                <Button2 onClick={doLogin(dispatch)}>Click aquí</Button2>
              )}
            </ButtonsHolder>
          </LoginHolder>
        </BannerHolder>
      </Holder>
    </Container>
    </ContentWrapper>
  );
};

const ContentWrapper = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
`;

const Container = styled.div`
  width: 100%;
  max-width: 754px;
  gap: 10px;
  margin-right: 40px;
`;

const SliderHolder = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0px;
  gap: 4px;
  width: 354px;

  input[type='range'] {
    height: 38px;
    appearance: none;
    margin: 0 0;
    width: 100%;
  }
  input[type='range']:focus {
    outline: none;
  }

  input[type='range']::-webkit-slider-runnable-track {
    width: 100%;
    height: 4px;
    cursor: pointer;
    background: rgba(40, 40, 40, 0.25);
    border-radius: 14px;
    border: 0;
  }

  input[type='range']::-webkit-slider-thumb {
    height: 30px;
    width: 30px;
    background: url(${sliderThumb});
    background-position: center;
    background-size: 30px 30px;

    cursor: pointer;
    -webkit-appearance: none;
    margin-top: -11px;
  }
`;

const Puntos = styled.div`
  font-family: 'Itau Display Pro';
  font-style: normal;
  font-weight: 400;
  font-size: 22px;
  line-height: 30px;
  display: flex;
  align-items: center;
  text-align: center;
  color: #282828;
  display: flex;
  flex-direction: row;
  justify-content: center;
  padding: 5px 60px;
  gap: 10px;
  width: 100%;
`;

const BannerTextoHolder = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0;
  width: 450px;
  height: 50px;
  margin-top: 10px;
  justify-content: space-around;
`;

const ContentText = styled.div`
  display: flex;
  align-items: center;
  width: 674px;
`;

const PrimerTexto = styled.div`
  font-family: 'Itau Display Pro';
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  display: flex;
  align-items: center;
  color: #282828;

  span {
    margin-left: 5px;
    color: #003399;
  }
`;

const SegundoTexto = styled.div`
  font-family: 'Itau Display Pro';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  display: flex;
  align-items: center;
  color: #959595;
`;

const BannerHolder = styled.div`
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0 10px;
  margin-left: 15px;
  width: 100%;
  min-height: 10px;
  border: 1px solid rgba(40, 40, 40, 0.1);
  border-radius: 14px;
`;

const Button1 = styled.button`
  font-family: Itau Display Pro;
  font-size: 14px;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: 0;
  text-align: center;
  color: #fb8400;
`;

const Button2 = styled.a`
  font-family: Itau Display Pro;
  font-size: 14px;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: 0;
  text-align: center;
  color: #fb8400;
  cursor: pointer;
  border-bottom: 1px solid #fb8400;
`;

const ButtonsHolder = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 0;
  gap: 4px;
  height: 48px;
  color: #fb8400;
  cursor: pointer;
`;

const BlueText = styled.div`
  height: 24px;
  font-family: 'Itau Display Pro';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  display: flex;
  align-items: center;
  color: #003399;
`;

const LoginHolder = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 0;
  gap: 4px;
`;

const Holder = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0;
  gap: 50px;
  width: 100%;
`;
