/**
 *
 * HotelError
 *
 */

import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import cs from 'classnames';
import Text from 'Components/Text';
import ErrorGone from 'Components/ErrorGone';
import { useDispatch, useSelector } from 'react-redux';
// Todo handle errors in hotels
import { packageClearErrors } from '../../actions';

import SkyBackground from 'Components/SkyBackground';
import lodging from 'Assets/lodging.svg';
import { ERRORS_API } from 'Constants';

const PackageError = ({ className }) => {
  const dispatch = useDispatch();
  const error = useSelector(({ packages }) => packages.error);

  const defaulErrors = () => (
    <Text className="error-label" context="white" size="xlarge">
      <b>¡Ups!</b> ha surgido una escala no esperada, volvamos a empezar.
    </Text>
  );

  const errorsNotifications = error => {
    switch (error.status) {
      case ERRORS_API.notFound: {
        return <ErrorNotFound />;
      }
      case ERRORS_API.gone: {
        return (
          <ErrorGone
            onClick={handleSearchAgain}
            className="error-card"
            iconName="mv-globo"
            title="Lo sentimos, el paquete buscado ya no posee disponibilidad"
            subtitle="Por favor, volvé al listado a buscar un nuevo paquete."
          />
        );
      }
      case ERRORS_API.unprocessableEntity: {
        return <ErrorUE />;
      }
      default:
        return defaulErrors();
    }
  };

  const ErrorNotFound = () => (
    <Text className="error-label" context="white" size="xlarge">
      <b>¡Ups!</b> {error.data.messages}
    </Text>
  );

  const ErrorUE = () => (
    <Text className="error-label" context="white" size="xlarge">
      <b>¡Ups!</b> por favor cambiar su busqueda, volvamos a empezar.
    </Text>
  );

  const handleSearchAgain = () => {
    dispatch(packageClearErrors());
  };

  return (
    <div className={cs(className)}>
      <SkyBackground />
      {error &&
        (error.status === ERRORS_API.notFound ||
          error.status === ERRORS_API.unprocessableEntity) && (
          <div className="lodging">
            <img alt="lodging" src={lodging} />
          </div>
        )}
      {error && errorsNotifications(error)}
    </div>
  );
};

PackageError.propTypes = {
  className: PropTypes.string.isRequired
};

export default styled(PackageError)`
  .error-label {
    position: absolute;
    width: 100%;
    top: calc(50% + 135px);
    left: calc(50% - 380px);
  }

  .error-card {
    position: relative;
    margin: 25%;
    top: 0%;
  }

  .lodging {
    position: absolute;
    left: 0;
    right: 0;
    top: 40%;

    z-index: 1;

    img {
      width: 50%%;
    }
  }

  .buttonHolder {
    position: absolute;
    top: 300px;
  }

  @media all and (max-width: 900px) {
    .lodging {
      top: 50%;
    }

    .buttonHolder {
      top: 420px;
    }
  }
`;
