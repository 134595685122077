import styled from 'styled-components';

export const PackageCommonListContainer = styled.div`
  flex-direction: column;
  margin: 0 auto;
  max-width: 1100px;
  padding: 8px;
  position: relative;

  .left-arrow,
  .right-arrow {
    position: absolute;
    z-index: 1;
    top: 50%;
    transform: translateY(-50%);
    width: 48px;
    height: 48px;
    border-radius: 24px;
    background-color: white;
    border: 1px solid #ddd;
  }

  .left-arrow {
    left: 24px;
  }

  .right-arrow {
    right: 24px;
  }

  .divider {
    margin: 3px;
    padding-left: 2px;
  }

  .more-packages-mobile {
    display: flex;
    flex-wrap: nowrap;
    overflow-x: scroll;
    width: 100%;

    margin: 0;

    ::-webkit-scrollbar {
      display: none;
    }

    .item {
      min-width: 350px;
      width: 33%;
      padding: 10px;
      padding-left: -10px;
      transition: all 250ms linear;

      &.large {
        width: 50%;
      }

      &.medium {
        width: 100%;
      }
    }
  }
  .more-packages {
    display: flex;
    flex-wrap: wrap;
    margin: 0;
    max-width: 1100px;

    .item {
      min-width: 300px;
      width: 33%;
      padding: 10px;
      padding-left: -10px;

      &.large {
        width: 50%;
      }

      &.medium {
        width: 100%;
      }
    }
  }
`;

export const TitleHolder = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 10px;
  gap: 10px;
  font-family: 'Itau Display Pro';
  font-style: normal;
  font-weight: 700;
  font-size: 32px;
  line-height: 40px;
  display: flex;
  align-items: center;
  color: #282828;
`;
