export enum Steps {
  paxData,
  billingData,
  payment
}

export interface BillingData {
  personType: number;
  firstName: string;
  lastName: string;
  documentType: number;
  document: string;
  country: Country;
  cityName: string;
  stateName: string;
  email: string;
  phoneData: PhoneData;
  address: string;
}

export interface Country {
  code: string;
  name: string;
}

export interface PhoneData {
  phoneType: number;
  countryCode: string;
  phoneNumber: string;
}

export interface PaxData {
  firstName: string;
  lastName: string;
  birthDate: BirthDate;
  gender: number;
  documentData: DocumentData;
  travelerCode: string;
}

export interface BirthDate {
  day: number;
  month: number;
  year: number;
}

export interface DocumentData {
  nationality: string;
  documentType: number;
  document: string;
}

export interface SelectedSegments {
  ida: SegmentItem;
  vuelta: SegmentItem;
}

export interface SegmentItem {
  index: number;
  item: Item;
}

export interface Item {
  airLines: AirLine[];
  departureData: DepartureData;
  arrivalData: ArrivalData;
  duration: Duration;
  scales: Scale[];
  segments: Segment[];
  baggage: Baggage;
  refNumber: number;
  summaryDateAndAirports: SummaryDateAndAirports;
  hasSliceAndDice: boolean;
  show: boolean;
  filterDataToApply: FilterDataToApply;
  cabinsClass: string;
  tariffTypes: number[];
  id: string;
}

export interface AirLine {
  code: string;
  name: string;
  alliance: Alliance;
  id: string;
}

export interface Alliance {
  id: number;
  name: string;
  image: string;
}

export interface DepartureData {
  airport: Airport;
  flightDate: string;
  flightTime: string;
}

export interface Airport {
  code: string;
  lat: number;
  lng: number;
  name: string;
  city: City;
  countryName: string;
}

export interface City {
  code: string;
  lat: number;
  lng: number;
  name: string;
  isIata: boolean;
}

export interface ArrivalData {
  airport: Airport;
  flightDate: string;
  flightTime: string;
}

export interface Duration {
  totalHours: number;
  totalMinutes: number;
}

export interface Scale {
  airport: Airport;
  duration: Duration;
  changeInfo: ChangeInfo;
  id: string;
}

export interface ChangeInfo {
  changeOfPlane: boolean;
  changeOfAirport: boolean;
}

export interface Segment {
  flightInformation: FlightInformation;
  departureData: DepartureData;
  arrivalData: ArrivalData;
  duration: Duration;
  cabin: Cabin;
  baggage: Baggage;
  fareDetails: FareDetail[];
  id: string;
}

export interface FlightInformation {
  airline: Airline;
  flightNumber: string;
  completeFlightNumber: string;
  optOperatingCompany: string;
}

export interface Airline {
  code: string;
  name: string;
  alliance: Alliance;
  id: string;
}

export interface Cabin {
  code: string;
  cabinClass: string;
  fistLetterFareBasis: string;
}

export interface Baggage {
  total: number;
  baggageType: number;
}

export interface FareDetail {
  ptcCodes: string[];
  fareBasis: string;
  fareTypeCorporateId: FareTypeCorporateId;
}

export interface FareTypeCorporateId {
  fareType: string;
}

export interface SummaryDateAndAirports {
  departureAirport: DepartureAirport;
  arrivalAirport: ArrivalAirport;
  flightDate: string;
}

export interface DepartureAirport {
  code: string;
  lat: number;
  lng: number;
  name: string;
  city: City;
  countryName: string;
}

export interface City {
  code: string;
  lat: number;
  lng: number;
  name: string;
  isIata: boolean;
}

export interface ArrivalAirport {
  code: string;
  lat: number;
  lng: number;
  name: string;
  city: City;
  countryName: string;
}

export interface FilterDataToApply {
  departure: string;
  arrival: string;
  totalChangeAirport: number;
  hasChangeAirport: boolean;
  totalScales: number;
  hasScales: boolean;
  hasBaggage: boolean;
  totalDurationInMinutes: number;
  cabins: string[];
  airlines: string[];
  alliances: number[];
}

export interface LocalPrice {
  pricePassengerDetail: PricePassengerDetail[];
  validatingCarrier: string;
  maxFees: number;
  currency: string;
  breakdown: Breakdown[];
  totalPrice: number;
  totalBaseRate: number;
  totalTaxes: number;
  totalFee: number;
  totalDiscount: number;
  totalPassenger: number;
  totalForOneAdult: number;
}

export interface PricePassengerDetail {
  totalPrice: number;
  totalBaseRate: number;
  totalTaxes: number;
  totalPassenger: number;
  totalForAllPassenger: number;
  totalBaseRateForAllPassenger: number;
  totalTaxesForAllPassenger: number;
  passengerCode: string;
  tariffDiscountToApply: number;
  totalFee: number;
  totalDiscount: number;
  totalFeeForAllPassenger: number;
  totalDiscountForAllPassenger: number;
  markUtility: number;
  currency: string;
}

export interface Breakdown {
  label: string;
  currency: string;
  price: number;
}
