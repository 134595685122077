import styled from 'styled-components';

export const Container = styled.div`
  padding-bottom: 200px;
  background-color: #f8f8f8;
  min-height: calc(100vh - 150px);
  margin-top: 150px;
`;

export const OrangeHeader = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 10px;
  gap: 10px;
  width: 100%;
  min-height: 42px;
  background: #fb8400;
  border-radius: 0;

  font-family: 'Itau Display Pro';
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 22px;
  color: #ffffff;
`;

export const StickyBottom = styled.div`
  position: fixed;
  bottom: 0;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  min-height: 182px;
  border-radius: 10px 10px 0px 0px;
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.25);
  background: #faf2e3;
`;

export const DetalleButton = styled.div`
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.25);
  border-radius: 10px 10px 0px 0px;
  min-height: 40px;
  font-family: 'Itau Display Pro';
  font-style: normal;
  font-weight: 400;
  font-size: 22px;
  line-height: 30px;
  display: flex;
  align-items: center;
  color: #fb8400;
  width: 100%;
  gap: 12px;
  justify-content: center;
  cursor: pointer;
`;

export const DetailsHolder = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 46px;
  width: 100%;
  padding: 8px 30px;
`;

export const DetailLocal = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  padding: 0;
  font-family: 'Itau Display Pro';
  font-style: normal;
  font-weight: 400;
  color: #fb8400;
`;

export const LocalRow1 = styled.div`
  font-size: 16px;
  line-height: 24px;
`;
export const LocalRow2 = styled.div`
  font-size: 22px;
  line-height: 30px;
`;
export const LocalRow3 = styled.div`
  font-weight: 300;
  font-size: 14px;
  line-height: 22px;
`;

export const DetailItau = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0;

  font-family: 'Itau Display Pro';
  font-style: normal;
  font-weight: 300;
  font-size: 14px;
  line-height: 22px;
  color: #003399;
  span {
    font-weight: 700;
  }
  svg {
    margin-left: 3px;
  }
`;

export const ButtonsHolder = styled.div`
  padding: 10px 20px;
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: space-between;
`;

export const NavButton = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 10px 30px;
  min-height: 40px;
  background: #fb8400;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
  font-family: 'Itau Display Pro';
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 28px;
  color: #ffffff;
  cursor: pointer;
`;

export const DetailPerse = styled.div<{ show?: boolean }>`
  display: ${({ show }) => (show ? 'block' : 'none')};
  max-height: 40vh;
  padding: 10px;
  overflow-y: scroll;
  width: 100%;
`;
