// TODO: hacer los backgrounds por servicios que va a devolver rodo
import { addDays, format } from 'date-fns';
import vuelosBackgroundJpg from '../Assets/backgrounds/fondo-nuevo-home-vuelos.webp';
import hotelesBackgroundJpg from '../Assets/backgrounds/fondo-nuevo-home-hoteles.webp';
import paquetesBackgroundJpg from '../Assets/backgrounds/fondo-nuevo-home-paquetes.webp';
import routes from './routes';
import IMGPY from '../Assets/images/imgPY.png';
import IMGUY from '../Assets/images/imgUY.png';
import NEQUICO from '../Assets/images/mv-payment-nequi.png';
import IMGCO from '../Assets/images/minturco.png';
import IMGBO from '../Assets/images/minturbo.png';
import OCA from '../Assets/images/ocaLogo.png';
import BANCARD from '../Assets/images/bancard.png';
import REDENLACE from '../Assets/images/redenlace.png';
import QRREALCONSULTING from '../Assets/images/qrRealConsulting.jpeg';
import QRMASTERBOX from '../Assets/images/qrMasterbox.jpg';

export const GOOGLE_MAPS_KEY = process.env.REACT_APP_GMAP_API_KEY;
export const SIEMPRE_PAGO_API_KEY = 'jo4jtAKbdeYAkM0ORH5jSm_I56ONV7HQ';
export const BANCARD_STATUS = process.env.REACT_APP_BANCARD;
export const ATC_STATUS = process.env.REACT_APP_ATC;
export const CIBERSOURCE_URL = process.env.REACT_APP_CIBERSOURCE_URL;
export const SIEMPRE_PAGO_STATUS = process.env.REACT_APP_SIEMPRE_PAGO;
export const STRAPI_IMAGE_SERVER = process.env.REACT_APP_STRAPI_SERVER_URL;
export const ASSETS_URL = process.env.REACT_APP_SITE_URL;
export const LANG_COUNTRY = process.env.REACT_APP_LANG_COUNTRY;
export { default as routes } from './routes';

export const footerCountryData = () => {
  if (process.env.REACT_APP_COUNTRY === 'UY') {
    return {
      phone: '2903 34 34',
      address:
        'RIO BRANCO 1410, MONTEVIDEO | WORLD TRADE CENTER MVD Local 011,021 y 022, MONTEVIDEO',
      facebook: 'https://www.facebook.com/mevuelo.uy',
      instagram: 'https://www.instagram.com/mevuelo.uy/',
      linkedin: 'https://www.linkedin.com/company/mevuelo',
      sociedad: 'MASTERBOX S.A. - HABILITACIÓN MINTUR Nº 472',
      imagen: IMGUY,
      imagenCard: OCA,
      validationLink:
        'http://apps.mintur.gub.uy/operadores/operador.php?tipo=10&numero=472',
      qr: QRMASTERBOX,
      mail: 'hola.uy@mevuelo.com',
      whatsapp: 'http://wa.me/59897683318',
      ogLanguage: 'es-UY',
      whatsNumber: '59897683318'
    };
  }
  if (process.env.REACT_APP_COUNTRY === 'PY') {
    return {
      phone: '021 729 0 629',
      address:
        'Explanada de Torre 3 (PB) - WTC Asuncion, Aviadores del Chaco 2050 (frente a Shopping del Sol)',
      facebook: 'https://www.facebook.com/mevuelo.py',
      instagram: 'https://www.instagram.com/mevuelo.py/',
      linkedin: 'https://www.linkedin.com/company/mevuelo',
      sociedad: 'REAL CONSULTING S.A. - RESOLUCIÓN REGISTUR Nº 1263/2019',
      imagen: IMGPY,
      imagenCard: BANCARD,
      qr: QRREALCONSULTING,
      validationLink:
        'https://registur.gov.py/registur/verificacion?qr=b6a4d52dc46c3d296dd4b8b9b45fc3b1',
      whatsapp: 'http://wa.me/595992228058',
      mail: 'hola.py@mevuelo.com',
      ogLanguage: 'es-PY',
      whatsNumber: '595992228058'
    };
  }
  if (process.env.REACT_APP_COUNTRY === 'BO') {
    return {
      phone: '77084299',
      address: 'DRA MARIA DE OLIVEIRA CASI 4to ANILLO Nro. 3110, SANTA CRUZ',
      facebook: 'https://facebook.com/mevuelobo',
      instagram: 'https://www.instagram.com/mevuelo_bo',
      linkedin: 'https://www.linkedin.com/company/mevuelo',
      sociedad: 'MEVUELO BOL S.R.L. - NIT Nº 383000025',
      imagen: IMGBO,
      imagenCard: REDENLACE,
      validationLink: 'https://www.mevuelo.com.bo',
      mail: 'hola.bo@mevuelo.com',
      ogLanguage: 'es-BO'
    };
  }
  if (process.env.REACT_APP_COUNTRY === 'CO') {
    return {
      phone: '',
      address: 'Calle 98 #9A-41',
      facebook: 'https://www.facebook.com/mevuelo.co',
      instagram: 'https://www.instagram.com/mevuelo.co/',
      linkedin: 'https://www.linkedin.com/company/mevuelo',
      sociedad: 'MEVUELO S.A.S.',
      imagen: IMGCO,
      imagenCard: NEQUICO,
      validationLink: 'https://www.mevuelo.com.co',
      mail: 'hola.co@mevuelo.com',
      whatsapp: 'http://wa.me/59897683318',
      ogLanguage: 'es-CO',
      whatsNumber: '59897683318'
    };
  }

  return {
    phone: 'not set',
    address: 'not set',
    facebook: 'not set',
    instagram: 'not set',
    twitter: 'not set',
    sociedad: 'not set',
    validationLink: 'not set',
    mail: 'not set',
    ogLanguage: 'es-UY'
  };
};

export const ogLanguage = footerCountryData().ogLanguage;

export const excludeAirlineBaggage = ['LA', 'JA', 'H2', 'WJ'];

export const defaultPackageOrigin = process.env.REACT_APP_AIRPORT_CODE_DEFAULT;

export const engineCodes = {
  home: 99,
  vuelos: 5,
  hoteles: 6,
  paquetes: 7
};

export const requestErrors = {
  default: '¡Ups! Algo salio mal, intentalo más tarde'
};

export const breakpoints = {
  xsmall: 0,
  small: 540,
  medium: 768,
  large: 960,
  xlarge: 1140,
  xxlarge: 1366
};

export const airSearchFilterType = {
  ONE_WAY: 1,
  ROUND_TRIP: 2,
  MULTIDESTINATION: 3
};

export const airSearchTypes = {
  roundTrip: 'ida-vuelta',
  oneWay: 'ida',
  multi: 'multidestino'
};

export const flightSearchTypes = [
  {
    id: airSearchTypes.roundTrip,
    label: 'Ida y vuelta',
    path: routes.flight
  },
  {
    id: airSearchTypes.oneWay,
    label: 'Solo Ida',
    path: routes.flight
  }
  // {
  //   id: airSearchTypes.multi,
  //   label: 'Multi Destino',
  //   path: routes.flightMultiDestination
  // }
];

export const firstDate = format(addDays(new Date(), 5), 'yyyy-MM-dd');
export const secondDate = format(addDays(new Date(), 10), 'yyyy-MM-dd');

export const roundTripBase = {
  routesData: [
    {
      origin: process.env.REACT_APP_AIRPORT_CODE_DEFAULT,
      originName: process.env.REACT_APP_AIRPORT_NAME_DEFAULT,
      destination: '',
      destinationName: '',
      initialDate: firstDate,
      endDate: secondDate
    }
  ],
  totalAdult: 1,
  totalChildren: 0,
  totalInfant: 0,
  totalInfantWithSeat: 0,
  filterType: airSearchFilterType.ROUND_TRIP,
  groupResults: true,
  isMPC: false,
  knowDestination: false
};

export const oneWayBase = {
  routesData: [
    {
      origin: 'ASU',
      originName: 'Asunción, Paraguay',
      destination: '',
      destinationName: '',
      initialDate: firstDate,
      endDate: secondDate
    }
  ],
  totalAdult: 1,
  totalChildren: 0,
  totalInfant: 0,
  totalInfantWithSeat: 0,
  filterType: airSearchFilterType.ONE_WAY,
  groupResults: true,
  isMPC: false,
  knowDestination: false
};

export const multidestinationBase = {
  routesData: [
    {
      origin: 'ASU',
      originName: 'Asunción, Paraguay',
      destination: '',
      destinationName: '',
      initialDate: firstDate,
      endDate: secondDate
    },
    {
      origin: '',
      originName: '',
      destination: '',
      destinationName: '',
      initialDate: secondDate
    }
  ],
  departureDate: firstDate,
  departureCity: 'Asunción, Paraguay',
  finalDate: secondDate,
  finalDestination: '',
  totalAdult: 1,
  totalChildren: 0,
  totalInfant: 0,
  totalInfantWithSeat: 0,
  filterType: airSearchFilterType.MULTIDESTINATION,
  groupResults: true,
  isMPC: false
};

export function getInitialStates(searchType) {
  switch (searchType) {
    case airSearchFilterType.ONE_WAY:
      return oneWayBase;
    case airSearchFilterType.MULTIDESTINATION:
      return multidestinationBase;
    default:
      return roundTripBase;
  }
}

export const urlBankcard = `${process.env.REACT_APP_PAY_GATE_BANKCARD_LIB}`;

export const urlSiemprePago = `${process.env.REACT_APP_PAY_GATE_SIEMPREPAGO_LIB}?key=${process.env.REACT_APP_SIEMPRE_PAGO_API_KEY}`;

export const engines = {
  FLIGHTS: {
    name: 'vuelos',
    path: routes.flight,
    background: vuelosBackgroundJpg,
    showInCorpo: true,
    icon: 'mv-flights'
  },
  HOTELS: {
    name: 'hoteles',
    path: routes.hotel,
    background: hotelesBackgroundJpg,
    showInCorpo: true,
    icon: 'mv-hotel'
  },
  PACKAGES: {
    name: 'paquetes',
    path: routes.package,
    background: paquetesBackgroundJpg,
    showInCorpo: false,
    icon: 'mv-packages'
  }
  // BLOG: {
  //   name: 'blog',
  //   path: routes.blog.replace(':catType', 'tips'),
  //   background: isSafari ? blogBackgroundJpg : blogBackground,
  //   showInCorpo: true,
  //   icon: 'mv-photo-camera'
  // },
  // QUOTATIONS: {
  //   name: 'Cotizationes',
  //   path: routes.quotations,
  //   background: null, // TODO: Add quotations background
  //   icon: 'mv-photo-camera',
  //   showInCorpo: false,
  //   isPrivate: true
  // },
  // TRAVELINSURANCE: {
  //   name: 'Asistencias',
  //   path: routes.travelInsurance,
  //   background: null,
  //   icon: 'medkit',
  //   showInCorpo: false,
  //   isPrivate: false
  // },
  // CORPORATE: {
  //   name: 'Corporativo',
  //   path: routes.corporate,
  //   background: null,
  //   icon: 'mv-hand-bag',
  //   isPrivate: false,
  //   showInCorpo: true
  // }
};

export const hotelPropertyPriceTypes = {
  PER_NIGHT: 'PER_NIGHT',
  PER_STAY: 'PER_STAY'
};

export const diasDeLaSemana = {
  0: 'Dom',
  1: 'Lun',
  2: 'Mar',
  3: 'Mie',
  4: 'Jue',
  5: 'Vie',
  6: 'Sab'
};

export const mesesDelAno = {
  0: 'Ene',
  1: 'Feb',
  2: 'Mar',
  3: 'Abr',
  4: 'May',
  5: 'Jun',
  6: 'Jul',
  7: 'Ago',
  8: 'Sep',
  9: 'Oct',
  10: 'Nov',
  11: 'Dec'
};

export const commentPlaceholderFlights =
  'Ejemplo: acá puedes poner si quieres maletas en bodega, maletas de mano, o cualquier requerimiento que se ajuste a su viaje';
export const commentPlaceholderHotels =
  'Ejemplo: acá puedes poner cualquier requerimiento extra que se ajuste a su reserva';

export const choicesRoute = {
  withoutContent: 'sin-contenido',
  withContent: 'con-contenido'
};

export const storyTypes = {
  JUST_GENERATED: 'Just Generated',

  COMMON: 'Common | General',
  COMMON_DESKTOP: 'Common | Desktop',
  COMMON_MOBILE: 'Common | Mobile',
  COMMON_INPUTS: 'Common | Inputs',

  VUELOS: 'Vuelos | General',
  VUELOS_DESKTOP: 'Vuelos | Desktop',
  VUELOS_MOBILE: 'Vuelos | Mobile',

  CHECKOUT: 'Checkout | General',
  CHECKOUT_DESKTOP: 'Checkout | Desktop',
  CHECKOUT_MOBILE: 'Checkout | Mobile',

  HOTELES: 'Hoteles | General',
  HOTELES_DESKTOP: 'Hoteles | Desktop',
  HOTELES_MOBILE: 'Hoteles | Mobile',

  DESTINATIONS: 'Destinos | General',
  DESTINATIONS_DESKTOP: 'Destinos | Desktop',
  DESTINATIONS_MOBILE: 'Destinos | Mobile',

  TO_DELETE: 'To Review | Componentes a Borrar',
  TO_REVIEW: 'To Review | Componentes a Revisar',

  PACKAGES: 'Paquetes | General',
  PACKAGES_DESKTOP: 'Paquetes | Desktop',

  BLOG: 'BLOG | General',

  USERS: 'Usuarios | General',

  QUOTATIONS: 'Cotizationes'
};

export const scaleTypes = {
  all: 'allOptions',
  maxOne: 'maxOneScala',
  maxDirect: 'maxDirect'
};

export const baggageTypes = {
  all: 'allOptions',
  maxCargo: 'maxCargoHold',
  maxHand: 'maxHandBaggage'
};

export const filterListTypes = {
  convenientes: 'recomendado',
  economico: 'menor-precio',
  rapidos: 'menor-duracion'
};

export const packageStepsId = {
  WHERE: 1,
  WHEN: 2,
  STYLE: 3
};

// TODO: pasar esto de un array a un objeto
export const packageSteps = [
  {
    id: packageStepsId.WHERE,
    label: '¿A dónde vamos?'
  },
  {
    id: packageStepsId.WHEN,
    label: '¿Cuándo nos vamos?'
  },
  {
    id: packageStepsId.STYLE,
    label: 'Tipo de viaje'
  }
];

export const mobilePackageSteps = [
  {
    id: packageStepsId.WHERE,
    label: '¿A dónde?'
  },
  {
    id: packageStepsId.WHEN,
    label: '¿Cuándo?'
  },
  {
    id: packageStepsId.STYLE,
    label: 'Tipo de viaje'
  }
];

export const packagesWhenType = {
  SEASON: 'SEASON',
  MONTH: 'MONTH'
};

export const packageSorts = {
  RECOMENTATION: 'RECOMMENDATION',
  CHEAPER: 'CHEAPER'
};

export const meteorologyColors = {
  temperature: [
    { height: 165, value: 31, color: 'atomicTangerine' }, // HEAD
    { height: 165, value: 30, color: 'atomicTangerine' },
    { height: 160, value: 29, color: 'atomicTangerine' },
    { height: 155, value: 28, color: 'atomicTangerine' },
    { height: 155, value: 27, color: 'atomicTangerine' },
    { height: 150, value: 26, color: 'atomicTangerine' },
    { height: 150, value: 25, color: 'atomicTangerine' },

    { height: 145, value: 24, color: 'goldenRod' },
    { height: 145, value: 23, color: 'goldenRod' },
    { height: 140, value: 22, color: 'goldenRod' },
    { height: 140, value: 21, color: 'goldenRod' },
    { height: 135, value: 20, color: 'goldenRod' },

    { height: 130, value: 19, color: 'givry' },
    { height: 130, value: 18, color: 'givry' },
    { height: 125, value: 17, color: 'givry' },
    { height: 125, value: 16, color: 'givry' },
    { height: 120, value: 15, color: 'givry' },

    { height: 115, value: 14, color: 'spindle' },
    { height: 115, value: 13, color: 'spindle' },
    { height: 110, value: 12, color: 'spindle' },
    { height: 110, value: 11, color: 'spindle' },
    { height: 105, value: 10, color: 'spindle' },

    { height: 100, value: 9, color: 'cornFlower' },
    { height: 100, value: 8, color: 'cornFlower' },
    { height: 95, value: 7, color: 'cornFlower' },
    { height: 95, value: 6, color: 'cornFlower' },
    { height: 90, value: 5, color: 'cornFlower' },

    { height: 85, value: 4, color: 'shipCove' },
    { height: 85, value: 3, color: 'shipCove' },
    { height: 80, value: 2, color: 'shipCove' },
    { height: 80, value: 1, color: 'shipCove' },
    { height: 75, value: 0, color: 'shipCove' },
    { height: 70, value: -1, color: 'shipCove' },
    { height: 65, value: -2, color: 'shipCove' },
    { height: 60, value: -3, color: 'shipCove' },
    { height: 45, value: -4, color: 'shipCove' } // TAIL
  ],
  sea: [
    { height: 165, value: 31, color: 'cornFlower' }, // HEAD
    { height: 165, value: 30, color: 'cornFlower' },
    { height: 160, value: 29, color: 'cornFlower' },
    { height: 155, value: 28, color: 'cornFlower' },
    { height: 155, value: 27, color: 'cornFlower' },
    { height: 150, value: 26, color: 'cornFlower' },
    { height: 150, value: 25, color: 'cornFlower' },
    { height: 145, value: 24, color: 'cornFlower' },
    { height: 145, value: 23, color: 'cornFlower' },
    { height: 140, value: 22, color: 'cornFlower' },
    { height: 140, value: 21, color: 'cornFlower' },
    { height: 135, value: 20, color: 'cornFlower' },
    { height: 130, value: 19, color: 'shipCove' },
    { height: 130, value: 18, color: 'shipCove' },
    { height: 125, value: 17, color: 'shipCove' },
    { height: 125, value: 16, color: 'shipCove' },
    { height: 120, value: 15, color: 'shipCove' },
    { height: 115, value: 14, color: 'shipCove' },
    { height: 115, value: 13, color: 'shipCove' },
    { height: 110, value: 12, color: 'shipCove' },
    { height: 110, value: 11, color: 'shipCove' },
    { height: 105, value: 10, color: 'shipCove' },
    { height: 100, value: 9, color: 'spindle' },
    { height: 95, value: 8, color: 'spindle' },
    { height: 90, value: 7, color: 'spindle' },
    { height: 80, value: 6, color: 'spindle' },
    { height: 75, value: 5, color: 'spindle' },
    { height: 70, value: 4, color: 'spindle' },
    { height: 65, value: 3, color: 'spindle' },
    { height: 60, value: 2, color: 'spindle' },
    { height: 50, value: 1, color: 'spindle' },
    { height: 45, value: 0, color: 'spindle' } // TAIL
  ]
};

export const months = [
  'JAN',
  'FEB',
  'MAR',
  'APR',
  'MAY',
  'JUN',
  'JUL',
  'AGO',
  'SEP',
  'OCT',
  'NOV',
  'DEC'
];

// export const personDocuments = [
//   {
//     id: 1,
//     name: 'Pasaporte'
//   },
//   {
//     id: 2,
//     name: 'Cédula de identidad'
//   },
//   {
//     id: 3,
//     name: 'DNI'
//   }
// ];
//
// export const companyDocuments = [
//   {
//     id: 1,
//     name: 'RUT'
//   },
//   {
//     id: 2,
//     name: 'RUC'
//   },
//   {
//     id: 3,
//     name: 'NIT'
//   }
// ];
//
// export const userFiscalTypes = [
//   {
//     id: 1,
//     name: 'Persona fisica'
//   },
//   {
//     id: 2,
//     name: 'Persona jurídica / Empresa'
//   }
// ];

export const typeOfFareFilter = {
  noche: 'noche',
  total: 'total'
};
export const typeOfOrderPrice = {
  byPriority: 'byPriority',
  byPrice: 'byPrice'
};

export const ERRORS_API = {
  notFound: 404,
  unprocessableEntity: 422,
  gone: 410
};

export const ratingOptions = [0, 1, 2, 3, 4];

export const PAGINATED = {
  visible: 4,
  next: 4
};

export const searchDebounceTime = {
  DESKTOP: 1000,
  MOBILE: 1000
};

export const paxDocs = [
  {
    id: '1',
    name: 'Pasaporte'
  },
  {
    id: '2',
    name: 'CI'
  },
  {
    id: '3',
    name: 'DNI'
  }
];

export const billingDocs = [
  {
    id: '1',
    name: 'RUC',
  },

  {
    id: '2',
    name: 'Cedula'
  },
  {
    id: '3',
    name: 'Pasaporte',
  },
  {
    id: '4',
    name: 'DNI'
  }
]

export const formErrors = {
  lessThanFour: 'Este campo no puede ser menor a 4 caracteres.',
  maxLength255: 'Este campo no puede tener más de 255 caracteres.',
  maxLength1000: 'Este campo no puede tener más de 1000 caracteres.',
  lessThanOne: 'Este campo no puede ser menor a 1 caracteres.',
  wrongContentField: 'El campo debe tener algún valor válido.',
  wrongEmail: 'El correo electrónico es incorrecto.',
  nonNumbers: 'Este campo no puede contener numeros.',
  nonLetters: 'Este campo no puede contener letras o simbolos.',
  nonSymbols: 'Este campo no puede contener simbolos.',
  required: 'Este campo es requerido.',
  needToFill: 'Es necesario que completes este campo.',
  moreThanFifteen: 'El documento no puede contener más de 15 caracteres.',
  cardNotValid: 'Tu tarjeta de crédito no es valida.',
  cardNotMatch: 'Tu tarjeta es válida pero no coincide con su selección',
  cardYearNotValid: 'El año seleccionado no es valido.',
  cardMonthNotValid: 'El mes seleccionado no es valido.',
  cardCVCNotValid: 'Su CVC/CVV no es valida.',
  emailShouldMatch: 'El correo electronico debe de ser identico.',
  passwordPattern:
    'Recuerda, tu contraseña debe contener almenos 8 carácteres, y contener minúsculas, mayúsculas, y números.'
};

export const formValidators = {
  // TODO: Add needed validators
  nonSymbols: t => !/^[_A-z0-9]*((-|\s)*[_A-z0-9])*$/g.test(t)
};

export const paginationLimit = 10;

export const getBaseString = base => {
  switch (base) {
    case 1:
      return 'Single';
    case 2:
      return 'Doble';
    case 3:
      return 'Triple';
    case 4:
      return 'Quadruple';
    default:
      break;
  }
};

export const keywordsWithDestinationData = [
  'dubai',
  'buenos-aires',
  'puerto-iguazu',
  'san-carlos-de-bariloche',
  'cordoba',
  'el-calafate',
  'mendoza',
  'san-salvador-de-jujuy',
  'salta',
  'san-miguel-de-tucuman',
  'ushuaia',
  'vienna',
  'sydney',
  'adelaide',
  'brisbane',
  'canberra',
  'gold-coast',
  'melbourne',
  'newcastle',
  'perth',
  'bruselas',
  'la-paz',
  'florianopolis',
  'maceio',
  'natal',
  'recife',
  'rio-de-janeiro',
  'salvador-de-bahia',
  'fortaleza',
  'ottawa',
  'bern',
  'santiago',
  'antofagasta',
  'puerto-montt',
  'beijing',
  'barranquilla',
  'bogota',
  'cali',
  'cartagena',
  'medellin',
  'san-andres',
  'santa-marta',
  'san-jose',
  'la-habana',
  'praga',
  'berlin',
  'frankfurt',
  'munich',
  'copenhagen',
  'punta-cana',
  'quito',
  'cairo',
  'barcelona',
  'madrid',
  'helsinki',
  'suva',
  'paris',
  'marsella',
  'londres',
  'atenas',
  'split',
  'dubrovnik',
  'budapest',
  'jakarta',
  'dublin',
  'tel-aviv',
  'nueva-delhi',
  'florencia',
  'milan',
  'napoles',
  'roma',
  'venecia',
  'tokio',
  'seul',
  'vientian',
  'beirut',
  'colombo',
  'rabat',
  'male',
  'cancun',
  'ciudad-de-mexico',
  'kuala-lumpur',
  'amsterdam',
  'oslo',
  'auckland',
  'wellington',
  'christchurch',
  'queenstown',
  'colon',
  'ciudad-de-panama',
  'cusco',
  'lima',
  'puerto-moresby',
  'manila',
  'lisboa',
  'asuncion',
  'doha',
  'moscu',
  'san-petersburgo',
  'estocolmo',
  'singapur',
  'bangkok',
  'ankara',
  'estambul',
  'miami',
  'nueva-york',
  'orlando',
  'chicago',
  'los-angeles',
  'las-vegas',
  'new-orleans',
  'san-francisco',
  'washington',
  'montevideo',
  'punta-del-este',
  'hanoi',
  'port-vila',
  'pretoria'
];
