/**
 *
 * Label
 *
 */

import React from 'react';
import styled from 'styled-components';
import { ThemeColor } from 'Theme/types';

interface LabelProps extends React.LabelHTMLAttributes<HTMLLabelElement> {
  children: React.ReactNode;
  color?: ThemeColor;
  for?: string;
}

const Label = ({ color = 'darkGrey', children }: LabelProps) => (
  <Container color={color}>{children}</Container>
);

interface StyleLabelProps {
  color: ThemeColor;
}

const Container = styled.label<StyleLabelProps>`
  font-family: 'Itau Display Pro';
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  color: #fb8400;
  text-transform: uppercase;
`;

export default Label;
