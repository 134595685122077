// Do it like this to get intellisense
export default Object.freeze({
  GET_APP_CONFIGURATION_REQUEST: 'GET_APP_CONFIGURATION_REQUEST',
  GET_APP_CONFIGURATION_SUCCESS: 'GET_APP_CONFIGURATION_SUCCESS',
  GET_APP_CONFIGURATION_FAILURE: 'GET_APP_CONFIGURATION_FAILURE',
  LOCALIZE_REQUEST: 'LOCALIZE_REQUEST',
  LOCALIZE_SUCCESS: 'LOCALIZE_SUCCESS',
  LOCALIZE_FAILURE: 'LOCALIZE_FAILURE',
  UPDATE_LOADER: 'UPDATE_LOADER',
  SET_HIDE_NAVBAR: 'SET_HIDE_NAVBAR',
  SET_NAVBAR_SMALL_MODE: 'SET_NAVBAR_SMALL_MODE',
  CLEAN_ERROR_BOUNDARY: 'CLEAN_ERROR_BOUNDARY',
  UPDATE_ITAU_POINTS: 'UPDATE_ITAU_POINTS'
});
