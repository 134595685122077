import React, { useCallback, useEffect, useState, useRef } from 'react';
import { useForm, FormProvider } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { Breakpoint } from 'react-socks';
import * as Sentry from '@sentry/react';
import { v4 as uuidv4 } from 'uuid';
import invokeLibrary from 'Utils/invokeLibrary';
import { routes, urlBankcard } from 'Constants';
import api from 'Services';

import { getAppConfiguration, updateLoader } from 'Features/app/actions';

import { BillingData, Steps } from './types';

import { Form } from './styles';
import { bancardIframeGenerator } from 'Containers/Checkout/helpers';
import { PaymentLinkDesktop } from './components/PaymentLinkDesktop';
import { PaymentLinkMobile } from './components/PaymentLinkMobile';
import {
  dateTransform,
  getChildCode,
  returnCountryName,
  scrollToTop
} from './utils';
import { getItauToken } from 'Components/ItauPuntosLogin/utils';
import { useHistory, useParams } from 'react-router';
import Modal from 'Components/Modal';
import AirCheckError from 'Features/flights/components/AirCheckError';

const returnItauData = ({
  itau,
  selectedInterventajasPoints,
  selectedMembershipPoints
}) => {
  const itauData = {
    token: getItauToken(),
    aliasClient: itau.aliasClient,
    pointsClient: []
  };
  if (selectedInterventajasPoints > 500) {
    itauData.pointsClient.push({
      program: 'INTERVENTAJAS',
      points: selectedInterventajasPoints
    });
  }

  if (selectedMembershipPoints > 500) {
    itauData.pointsClient.push({
      program: 'MEMBERSHIP',
      points: selectedMembershipPoints
    });
  }
  return itauData;
};

export const PaymentLink = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { orderId } = useParams<{ orderId: string }>();
  const [priceToTransaction, setPriceToTransaction] = useState(0);
  const [priceUSD, setPriceUSD] = useState(0);
  const [description, setDescription] = useState('Sin descripción');
  const [billingData, setBillingData] = useState<BillingData>();
  const [activeStep, setActiveStep] = useState<Steps>(Steps.billingData);
  const [showErrorModal, setShowErrorModal] = useState(false);
  const [errorMessage,setErrorMessage]= useState('');


  const toggleErrorModal = () => setShowErrorModal(!showErrorModal);
  const handleGoBack = () => {
    history.goBack()
    setShowErrorModal(false);
  }; 
  
  const isLogged = Boolean(getItauToken());
  const { itau } = useSelector(
    // @ts-ignore
    ({ itau }) => ({
      itau
    })
  );

  useEffect(() => {
    const getPaymentLinkData = async () => {
      if (orderId) {
        try {
          const result = await api.checkout.getPaymentLinkById(orderId);
          setPriceToTransaction(result.data.priceToTransaction.amount);
          setPriceUSD(result.data.price.amount);
          setDescription(result.data.description);
        } catch (_e) {
          Sentry.captureMessage(`Payment Link: Link de pago invalido: orderId: ${orderId}`);
          alert('Este link de pago es invalido');
          history.push('/');
        }
      }
    };

    getPaymentLinkData();
    dispatch(updateLoader(false));
    dispatch(getAppConfiguration());
    scrollToTop();
  }, [dispatch, orderId]);

  const formRef = useRef<HTMLFormElement>();
  const methods = useForm();
  const { setValue } = methods;

  const { documentTypes, personTypes } = useSelector(
    // @ts-ignore
    ({ appConfiguration: { sharedConfig } }) => sharedConfig
  );

  useEffect(() => {
    initForm();
  }, [setValue, documentTypes, personTypes]);

  const handleRequest = async ({
    selectedInterventajasPoints,
    selectedMembershipPoints,
    moneyToComplete,
    pointsToRender
  }) => {
    dispatch(updateLoader(true));

    const request = {
      billingData,
      paymentData: {
        amountPrice: moneyToComplete > 0 ? moneyToComplete : 0
      },
      product: 'ORDER',
      orderId,
      toShow: {
        isOrder: true,
        pointsToRender,
        selectedInterventajasPoints,
        selectedMembershipPoints,
        moneyToComplete,
        description,
        priceToTransaction
      }
    };

    if (
      isLogged &&
      (selectedInterventajasPoints > 0 || selectedMembershipPoints > 0)
    ) {
      // @ts-ignore
      request.paymentData.itauData = returnItauData({
        itau,
        selectedInterventajasPoints,
        selectedMembershipPoints
      });
    }

    try {
      const result = await api.checkout.requestProcessId(request);
      if (moneyToComplete > 0) {
        await invokeLibrary(urlBankcard);
        dispatch(updateLoader(false));
        bancardIframeGenerator(result.data);
      } else if (result.data.status && result.data.status === 'success') {
        const reservationCode = result.data.id;
        history.push(
          `${routes.airCheckoutSuccess}/onlyPoints/?reservationCode=${reservationCode}`
        );
      } else if (result.data.status && result.data.status === 'failed') {
        const reservationCode = result.data.id;
        history.push(
          `${routes.airCheckoutSuccess}/onlyPoints/?reservationCode=${reservationCode}&status=payment_fail`
        );
      } else {
        history.push(
          `${routes.airCheckoutSuccess}/onlyPoints/?status=payment_fail`
        );
      }
    } catch (e) {
      dispatch(updateLoader(false));
      const uuid = uuidv4();
      if (e.response && e.response.data) {
        Sentry.captureMessage(`JobProcessId failure : Payment Link: ${uuid} - ${JSON.stringify(e.response.data)}`);
      } else {
        Sentry.captureMessage(`JobProcessId failure : Payment Link: ${uuid} - No Error mensage with correct structure`);
      }
      setShowErrorModal(true);
      setErrorMessage(e.response.data.message)
      alert(`Error al intentar procesar el pago: ${uuid}`);
    }
  };

  const formDispatch = data => {
    if (activeStep < Steps.payment) {
      handleParseBillingData(data);
      setActiveStep(activeStep + 1);
    }
    scrollToTop();
  };

  const handleParseBillingData = data => {
    setBillingData({
      personType: 1,
      firstName: data.firstName,
      lastName: data.lastName,
      documentType: Number(data.docType),
      document: data.document,
      country: {
        code: data.country,
        name: returnCountryName(data.country)
      },
      cityName: data.city,
      stateName: data.state,
      email: data.email,
      phoneData: {
        phoneType: 1,
        countryCode: data.countryCode,
        phoneNumber: data.phone
      },
      address: data.address
    });
  };

  const initForm = useCallback(() => {
    const persistFormData = localStorage.getItem('vn-checkout-data');
    if (persistFormData) {
      const obj = JSON.parse(persistFormData);
      setValue('address', obj.address);
      setValue('city', obj.city);
      setValue('country', obj.country);
      setValue('countryCode', obj.countryCode);
      setValue('docType', obj.docType);
      setValue('document', obj.document);
      setValue('email', obj.email);
      setValue('firstName', obj.firstName);
      setValue('lastName', obj.lastName);
      setValue('nationality', obj.nationality);
      setValue('personType', obj.personType);
      setValue('phone', obj.phone);
      setValue('state', obj.state);
      setValue('passengers', obj.passengers);
      localStorage.removeItem('vn-checkout-data');
    } else {
      setValue('firstName', '');
      setValue('lastName', '');

      setValue('document', '');
      setValue('nationality', 'PRY');
      setValue('countryCode', '595');
      setValue('docType', '4');
      setValue('personType', '1');
      setValue('country', 'PY');
      setValue('city', 'Asunción');
      setValue('state', 'Asunción');
    }
  }, [setValue]);

  const handleNextStep = () =>
    formRef.current.dispatchEvent(new Event('submit', { cancelable: true }));

  const handlePreviousStep = useCallback(() => {
    if (activeStep > Steps.billingData) {
      setActiveStep(activeStep - 1);
      scrollToTop();
    }
  }, [activeStep, setActiveStep]);

  const props = {
    activeStep,
    onNextStep: handleNextStep,
    onPreviousStep: handlePreviousStep,
    onRequest: handleRequest,
    priceToTransaction,
    priceUSD,
    description
  };

  return (
    <FormProvider {...methods}>
      <Form onSubmit={methods.handleSubmit(formDispatch)} ref={formRef}>
        <Breakpoint small down>
          <PaymentLinkMobile {...props} />
        </Breakpoint>
        <Breakpoint medium up>
          <PaymentLinkDesktop {...props} />
        </Breakpoint>
      </Form>
        {/* 
      // @ts-ignore */}
      <Modal
              show={showErrorModal}
              onClickOverlay={toggleErrorModal}
              noCard
              modalWidth={1000}>
            <AirCheckError close={toggleErrorModal} message={errorMessage} handleGoBack={handleGoBack} />
       </Modal>
    </FormProvider>
  );
};
