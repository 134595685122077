/**
 *
 * AirAvailabilityDesktop
 *
 */

import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import cs from 'classnames';
// import MenuTab from 'Components/MenuTab';
import Modal from 'Components/Modal';
import AirAvailabilityCard from '../AirAvailabilityCard';
import AirFilter from '../AirFilter';
import { Breakpoint } from 'react-socks';
import MPCAvailability from '../MpcAvailability';

import { filterListTypes, routes } from 'Constants';
import AirCheckError from '../AirCheckError';
import CardAlert from 'Components/CardAlert';
import Text from 'Components/Text';

import { ItauPuntosLogin } from 'Components/ItauPuntosLogin';
import { ItauBanner } from 'Components/ItauBanner';

import { ReactComponent as Arrow } from './arrow.svg';
import { useSelector } from 'react-redux';
import { selectorDestinationsBottom } from 'Features/destinations/selectors';
import DealButton from 'Features/destinations/components/BestDeals/DealButton';
import Divider from 'Components/Divider'; 
import { roundTripRequestUrl } from 'Features/flights/helpers';

import pesos from 'Assets/Pesos.svg'
import { getSearchObject } from 'Utils/getSearchObject';


const AirAvailabilityDesktop = ({
  className,
  parseResponse,
  handleClickListType,
  filters,
  fareSliderDetails,
  handleClickLuggageFilter,
  filtersData,
  handleClickScalesFilter,
  handleAirlineFilterClick,
  handleAllianceFilterClick,
  handleServiceClassFilterClick,
  handleFareFilterChange,
  handleNoAirportChange,
  handleFlexFilterChange,
  onSelectAvailability,
  request,
  initialDate,
  endDate,
  isChecking,
  showErrorModal,
  toggleErrorModal,
  isDestination,
  orderPriceInfo,
  hasMPCData,
  hasError,
  mpcAvailability
}) => {

  const state = useSelector(selectorDestinationsBottom);
  const handleSelectOrderBy = e => {
    handleClickListType(e.target.value)();
  };

  const currentUrl = window.location.href;
  const searchQuery = 'show-suggested-date';
  const urlSuggestedDate = currentUrl.includes(searchQuery);

  const getUrl = (state, destination) => {
    const searchObject = getSearchObject({
      destination: state.destination,
      origin:state.origin,
      dateFrom: state.dateFrom,
      dateTo: state.dateTo,
    });
    const urlParams = roundTripRequestUrl(searchObject);

    return `${routes.flightSearch.replace(
      ':destination',
      destination
    )}${urlParams}`;
  };

  return (
    <div className={cs(className)} >
      {parseResponse && (
        <div className="availability-holder">
          <Content>
            {!isDestination && (
              <Breakpoint xl up>
                <AirFilter
                  fareSliderDetails={fareSliderDetails}
                  filters={filters}
                  filtersData={filtersData}
                  handleClickLuggageFilter={handleClickLuggageFilter}
                  handleClickScalesFilter={handleClickScalesFilter}
                  handleFareFilterChange={handleFareFilterChange}
                  handleAirlineFilter={handleAirlineFilterClick}
                  handleAllianceFilter={handleAllianceFilterClick}
                  handleClassFilter={handleServiceClassFilterClick}
                  handleNoAirportChange={handleNoAirportChange}
                  handleFlexFilterChange={handleFlexFilterChange}
                  />
              </Breakpoint>
            )}
            <div>
              {parseResponse &&
                parseResponse.length > 0 &&
                request.routesDataChanged && (
                  <CardAlert
                  nameIcon="mv-circuit"
                  color="information"
                  title="Tu búsqueda no coincide con los vuelos disponibles.">
                    <Text paragraph>
                      Te ofrecemos{' '}
                      <b>vuelos a {request.routesData[0].destinationName}</b>,
                      la opción más conveniente para viajar desde{' '}
                      {request.routesData[0].originName}.
                    </Text>
                  </CardAlert>
                )}
              {parseResponse && parseResponse.length === 0 && (
                <CardAlert
                color="important"
                nameIcon="mv-information-circle"
                title={`¡Ups! no hemos encontrado vuelos a ${request.routesData[0].originName}`}>
                  <Text paragraph>
                    Puede ser que no haya lugar en los vuelos o que no haya
                    vuelos en esos días.
                    <br />
                    Por favor, intenta modificar la búsqueda.
                  </Text>
                </CardAlert>
              )}
              {urlSuggestedDate && (
                <div>
                  <div style={{ display: 'flex', justifyContent: 'center', textAlign: 'center'}}>
                    <Divider titleText={`Los mejores precios de vuelos a ${state.name}`} img={pesos}/>
                  </div>
                  <div style={{ width: '100%', display: 'flex', justifyContent: 'center', flexWrap: 'wrap' }}>
                    {state.flightsBanners.map((banner, index) => (
                      <div key={index} style={{ margin: '0 10px' }}>
                        <ButtonsHolder>
                          <ButtonItemContainer key={banner.id}>
                            <DealButton
                              title={banner.month}
                              price={banner.price}
                              url={getUrl(banner, banner.destination)}
                            />
                          </ButtonItemContainer>
                        </ButtonsHolder>
                      </div>
                    ))}
                  </div>
                </div>
              )}

              {hasMPCData && !hasError && (
                <MPCAvailability availability={mpcAvailability} />
              )}

              <ItauHolderPoints>
                <ItauPuntosLogin />
              </ItauHolderPoints>
              <OrderHolder>
                <span>Ordenar por:</span>
                <OrderSelect>
                  <select onChange={handleSelectOrderBy}>
                    <option value={filterListTypes.convenientes}>
                      Recomendados
                    </option>
                    <option value={filterListTypes.economico}>
                      Económicos
                    </option>
                    <option value={filterListTypes.rapidos}>Rápidos</option>
                  </select>
                  <Arrow />
                </OrderSelect>
              </OrderHolder>
              <CardsContainer>
                {Array.isArray(parseResponse) &&
                  parseResponse.map((item, i) => (
                    <>
                      <AirAvailabilityCard
                        key={item.id}
                        id={item.id}
                        generalSegment={item.generalSegments}
                        flightPrice={item.flightPrice}
                        localPrice={item.localPrice}
                        initialDate={initialDate}
                        endDate={endDate}
                        pending={isChecking}
                        routesData={request.routesData}
                        onClick={onSelectAvailability(item)}
                        flightObject={item}
                        hasFlex={item.hasFlex}
                      />
                      {i === 4 && <ItauBanner noMargin />}
                    </>
                  ))}
              </CardsContainer>
            </div>
          </Content>
        </div>
      )}
      <Modal
        show={showErrorModal}
        onClickOverlay={toggleErrorModal}
        noCard
        modalWidth={700}>
        <AirCheckError close={toggleErrorModal} />
      </Modal>
    </div>
  );
};
AirAvailabilityDesktop.propTypes = {
  className: PropTypes.string.isRequired,
  handleClickListType: PropTypes.func.isRequired,
  filters: PropTypes.object.isRequired,
  fareSliderDetails: PropTypes.object.isRequired,
  handleClickLuggageFilter: PropTypes.func.isRequired,
  filtersData: PropTypes.object,
  handleClickScalesFilter: PropTypes.func.isRequired,
  handleAirlineFilterClick: PropTypes.func.isRequired,
  handleAllianceFilterClick: PropTypes.func.isRequired,
  handleFareFilterChange: PropTypes.func.isRequired,
  getCabinName: PropTypes.func,
  handleServiceClassFilterClick: PropTypes.func.isRequired,
  onSelectAvailability: PropTypes.func.isRequired,
  handleFlexFilterChange: PropTypes.func.isRequired,
  request: PropTypes.object.isRequired,
  initialDate: PropTypes.string.isRequired,
  endDate: PropTypes.string,
  isChecking: PropTypes.bool,
  handleNoAirportChange: PropTypes.func.isRequired,
  parseResponse: PropTypes.oneOfType([PropTypes.array, PropTypes.bool]),
  showErrorModal: PropTypes.bool,
  toggleErrorModal: PropTypes.func,
  isDestination: PropTypes.bool,
  orderPriceInfo: PropTypes.object
};

const Content = styled.div`
  display: grid;
  grid-template-columns: 281px 1fr;
  gap: 32px;
`;

const OrderSelect = styled.div`
  background: #ffffff;
  border-radius: 10px;
  filter: drop-shadow(1px 1px 3px rgba(0, 0, 0, 0.25));
  padding: 13px 10px;
  font-family: 'Itau Display Pro';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #000000;
  display: flex;
  align-items: center;
  gap: 12px;

  select {
    appearance: none;
    border: 0;

    &:focus {
      outline: 0;
    }
  }
`;

const ItauHolderPoints = styled.div`
  margin-bottom: 89px;
`;

const CardsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
  padding-right: 32px;
  align-items: center;
`;

const OrderHolder = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0px;
  gap: 7px;
  font-family: 'Itau Display Pro';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #000000;
  margin-bottom: 40px;
`;

export default styled(AirAvailabilityDesktop)`
  display: block;

  .availability-holder {
    padding-top: 31px;
  }

  .scale-filter-line {
    display: flex;
    justify-content: space-between;
  }

  .airlineCheckbox {
    margin-bottom: 8px;
    white-space: nowrap;
    width: 100%;

    label {
      margin-bottom: 0;
      overflow: hidden;
      text-overflow: ellipsis;
      vertical-align: middle;
      width: calc(100% - 10px - 15px);
    }
  }
`;

const ButtonsHolder = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-bottom: ${props => props.theme.spacing.five};
  ${({ theme }) => theme.mediaQueries.mobile} {
    flex-direction: column;
    align-items: center;
  }
`;

const ButtonItemContainer = styled.div`
  margin: ${props => props.theme.spacing.four} 0;
`;