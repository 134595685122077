/**
 *
 * PoliticsAndPrivacy
 *
 */

import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import cs from 'classnames';
// import TextHeader from '../TextHeader';
import Text from '../Text';
import { darkGrey } from 'Constants/colors';

const PoliticsAndPrivacy = ({ className }) => (
  <div className={cs(className)}>
    <Text paragraph className="with-separator">
      Real Consulting S.A es una sociedad de la República del Paraguay, que opera el sitio web viajes.tiendanaranja.com.py (en adelante el «Sitio»).
      Y declara que el Sitio ha sido desarrollado por su Empresa constituida según las normas del derecho paraguayo
      (en adelante el «Administrador»).
    </Text>

    <Text paragraph className="with-separator">
      Al utilizar los servicios de viajes.tiendanaranja.com.py, el usuario consiente y acepta que el tratamiento de sus datos personales,
      se realice de acuerdo con lo informado en este documento y con lo dispuesto en las leyes vigentes de la materia.
    </Text>

    <Text paragraph className="with-separator">
      Cualquier información personal obtenida por Tienda Naranja Viajes por los medios aquí descriptos será tratada de conformidad con las disposiciones de estas políticas
      y de conformidad con la normativa vigente.
    </Text>

    <Text paragraph className="with-separator">
      Se comprende que el sitio viajes.tiendanaranja.com.py facilita a sus usuarios personas físicas o jurídicas,
      contactarse con personal de la empresa especializado en el sector turístico entre otros, para poder acceder a información acerca de los servicios que el sitio ofrece,
      así como adquirirlos. El fin es ayudar a los usuarios del sitio o “clientes” (El término “clientes” refiere a quien pretende recibir o recibió en el pasado,
      servicios turísticos de Tienda Naranja VIajes) a encontrar el servicio turístico adecuado a sus intereses.
    </Text>

    <Text paragraph className="with-separator">
      A efectos de acceder a los servicios ofrecidos por el Sitio, el usuario (en adelante el «Usuario») debe brindar ciertos datos personales
      los cuales a criterio del Administrador pueden ser utilizados para las siguientes finalidades:
    </Text>

    <Text paragraph className="with-separator">
      La mejora de los servicios que el sitio ofrece, para entender mejor a los usuarios de sus “plataformas”
      (El término “plataformas” comprende a: sitios web, redes sociales, sistemas de mensajería de mails, whatsapp, sms, notificaciones push o llamadas)
      y servicios, y para proteger su propiedad e impedir daños.
    </Text>

    <Text paragraph className="with-separator">
      Para procesar reservas de un servicio turístico de interés del “usuario” y guardar información sobre el/los itinerarios, si procede.
    </Text>

    <Text paragraph className="with-separator">
      Para acciones de marketing como enviar emails desde una dirección de correo electrónico de Tienda Naranja Viajes
      relacionados con sus servicios y promociones (sólo en los casos en que el usuario aceptó recibir emails por previa aviso de confirmación
      o haya realizado alguna transacción con el sitio), mostrar al usuario anuncios publicitarios de los servicios del sitio y/o promociones.
      Puedes darte de baja de estos tipos de comunicaciones en cualquier momento haciendo clic en el enlace correspondiente.
    </Text>

    <Text paragraph className="with-separator">
      Para acciones de marketing y/o análisis de parte de terceros con los que el administrador mantenga una relación contractual vigente.
    </Text>

    <Text paragraph className="with-separator">
      El Sitio sólo utilizará los datos personales obtenidos de sus usuarios web si se cumplen acumulativamente los siguientes requisitos:
      (i) el Usuario se registra en el Sitio o en formularios de publicidad de la empresa mencionada o asociados brindando los datos personales solicitados;
      y (ii) el Usuario acepta los presentes Términos y Condiciones del Sitio.
    </Text>

    <Text paragraph className="with-separator">
      El Usuario podrá solicitar que su registro de usuario y sus datos personales sean eliminados del Sitio y de sus bases de datos mediante el envío de un correo electrónico
      a contacto@tiendaranja.com.py con el Asunto (Subject): «Desuscribirme» e incluyendo el número de teléfono del Usuario en el cuerpo del correo electrónico.
    </Text>

    <Text paragraph className="with-separator">
      <strong> Los datos personales recabados por Tienda Naranja Viajes podrán ser compartidos a:</strong>
    </Text>

    <ul>
      <li>
        <Text paragraph className="with-separator" style={{ marginBottom: '16px' }}>
          Proveedores que realizan servicios en nombre de Tienda Naranja VIajes y que han aceptado por escrito proteger y no revelar esta información.
        </Text>
      </li>

      <li>
        <Text paragraph className="with-separator" style={{ marginBottom: '16px' }}>
          Proveedores de servicios de pago y proveedores de viajes. Esto incluye agencias de viaje en línea, hoteles, aerolíneas, agencias de alquiler de autos, proveedores de seguros de viaje y otros. Estos terceros procesarán los Datos Personales del Usuario en calidad de encargado de tratamientos y por este motivo Tienda Naranja VIajes recomienda que el Usuario analice las políticas de privacidad de estos proveedores en su caso debido.
        </Text>
      </li>

      <li>
        <Text paragraph className="with-separator" style={{ marginBottom: '16px' }}>
          La información compartida en público como la opinión del Usuario sobre su viaje, en la que autoriza automáticamente a Tienda Naranja Viajes a publicarla en todas su “plataformas” bajo el nombre que haya proporcionado y a mostrarla junto con otras valoraciones.
        </Text>
      </li>

      <li>
        <Text paragraph className="with-separator" style={{ marginBottom: '16px' }}>
          Aliados estratégicos para la consecución de los productos y servicios proveídos en viajes.tiendanaranja.com.py
        </Text>
      </li>

      <li>
        <Text paragraph className="with-separator" style={{ marginBottom: '16px' }}>
          Cumplimiento de la normativa aplicable: Tienda Naranja Viajes podría divulgar tus Datos Personales si la normativa aplicable así lo solicitara y para cooperar con las autoridades competentes.
        </Text>
      </li>
    </ul>

    <Text paragraph className="with-separator">
      Los puntos antes descritos son solo algunos de los ejemplos en los que el Usuario o cliente comprende y acepta que,
      para llevar a cabo transacciones de compra a través de Tienda Naranja Viajes, implicará de forma necesaria
      que Tienda Naranja Viajes comparta Datos Personales.
    </Text>

    <Text paragraph className="with-separator">
      Es importante aclarar que dichas terceras personas, socios comerciales y empresas,
      actuarán en calidad de encargados de tratamiento y tienen por la normativa aplicable prohibida
      la utilización o divulgación no autorizada de la información personal a la cual tienen acceso.
    </Text>

    <Text paragraph className="with-separator"><strong>Introducción</strong></Text>

    <ul>
      <li>
        <Text paragraph className="with-separator">
          El presente documento describe las Políticas de Privacidad generales (las «Políticas de Privacidad»)
          aplicables al uso del Sitio y los servicios que la empresa ofrece. El Usuario que desee acceder y/o usar el Sitio o los servicios
          regulados en el Sitio, acepta sus Políticas de Privacidad, junto con todos los demás términos, condiciones y principios que rigen la contratación con el Sitio,
          que son incorporados al presente por referencia.
        </Text>
      </li>

      <li>
        <Text paragraph className="with-separator">
          El Usuario acepta expresamente todas las condiciones establecidas en las Políticas de privacidad previo a la navegación en el Sitio,
          la utilización de cualquier servicio o el registro como usuario registrado. Por ello, es obligación y carga del Usuario,
          leer y entender los Términos y Condiciones, así como las Políticas de Privacidad previo a cualquier utilización del Sitio como de algún servicio o contacto con la marca Tienda Naranja VIajes.
        </Text>
      </li>
    </ul>


    <Text paragraph className="with-separator"><strong>El Servicio y la relación del Usuario con la Empresa</strong></Text>

    <ul>
      <li>
        <Text paragraph className="with-separator">
          El Usuario acepta y reconoce que los servicios que se prestan mediante el Sitio se limitan a obtener los datos personales del Usuario
          y a usarlos para su contratación con la empresa, la ejecución de los servicios contratados y, así como para la realización de acciones necesarias
          para concretar la venta o reserva de los servicios mencionados en el sitio.
        </Text>
      </li>

      <li>
        <Text paragraph className="with-separator">
          El Sitio y su Administrador se reservan el derecho de rechazar o cancelar cualquier solicitud de servicio sin que esté obligado a comunicar o exponer las razones de su decisión
          y sin que ello genere algún derecho a indemnización o resarcimiento.
        </Text>
      </li>

      <li>
        <Text paragraph className="with-separator">
          Si por cualquier razón el Usuario no viera finalizados todos los pasos proporcionados por el sistema para poder acceder a los servicios del Sitio,
          deberá volver a iniciar el proceso para lograr el registro en el formulario de contacto o consultar/ llamar a Tiedna Naranja Viajes por intermedio de los contactos y redes sociales mencionadas en el sitio.
        </Text>
      </li>
    </ul>


    <Text paragraph className="with-separator"><strong>Datos Personales</strong></Text>

    <ul>
      <li>
        <Text paragraph className="with-separator">
          El Usuario no está de ningún modo obligado a aportar sus datos personales para acceder al Sitio o navegar en él.
        </Text>
      </li>

      <li>
        <Text paragraph className="with-separator">
          El Usuario podrá elegir no aceptar la presente Política de Privacidad y no brindar sus Datos Personales,
          pero reconocerá entonces que Tienda Naranja Viajes requiere cierta información personal para que éste pueda adquirir servicios turísticos
          y por lo tanto, no podrán llevarse a cabo las contrataciones de dichos servicios.
        </Text>
      </li>

      <li>
        <Text paragraph className="with-separator">
          Al proporcionarnos acceso a sus Datos personales o de terceros, el Usuario confirma que son auténticos, exactos y que están actualizados.
          Asimismo, expresa que tiene autorización para proporcionarlos a Tienda Naranja Viajes.
        </Text>
      </li>

      <li>
        <Text paragraph className="with-separator">
          El Sitio se reserva el derecho de solicitar algún comprobante adicional a efectos de corroborar los datos personales proporcionados
          (sin que ello suponga obligación alguna por parte del Sitio o su Administrador), así como de suspender temporal o definitivamente a Usuarios y/o
          negarse a brindar los servicios en los casos en que los datos no hayan podido ser confirmados.
        </Text>
      </li>

      <li>
        <Text paragraph className="with-separator">
          El Usuario es responsable por cualquier dato erróneo de datos de otras personas que informe a Tienda Naranja Viajes
          y de los daños y perjuicios que dicho dato erróneo o comunicación de datos personales de terceros pudiere ocasionar, sin limitación.
        </Text>
      </li>

      <li>
        <Text paragraph className="with-separator">
          Tienda naranja Viajes podrá grabar llamados o contactos que tuviese con el Usuario y/o controlar para propósitos de control de calidad,
          formación del personal y mejor atención. Las grabaciones de dichas llamadas se conservarán durante el tiempo que sea necesario para cumplir con el servicio
          y/o que sea legamente requerido, y posteriormente se eliminarán.Los pagos en línea se realizan a través de un proveedor seguro.
        </Text>
      </li>

      <li>
        <Text paragraph className="with-separator">
          ASIMISMO, EL USUARIO PRESTA SU CONSENTIMIENTO RESPECTO DE QUE EL ADMINISTRADOR PODRÁ UTILIZAR LOS DATOS APORTADOS POR EL USUARIO PARA ENVIAR INFORMACIÓN
          Y/O PROMOCIONES Y/O PUBLICIDAD ASOCIADA AL SITIO Y SUS SERVICIOS.
        </Text>
      </li>

      <li>
        <Text paragraph className="with-separator">
          En todos los casos el Usuario autoriza expresamente la utilización de su información personal para llevar a cabo estudios internos sobre los intereses, comportamientos
          y demografía de los Usuarios en general, de modo de que el Sitio cuente con información que le permita mejorar el Sitio y sus servicios.
        </Text>
      </li>

      <li>
        <Text paragraph className="with-separator">
          En todos los casos el titular de los datos personales tiene la facultad de ejercer sus derechos de acceso, rectificación, actualización, supresión, oposición y portabilidad en forma gratuita.
        </Text>
      </li>

      <li>
        <Text paragraph className="with-separator">
          Para ejercer sus derechos antes descritos o por consultas sobre las prácticas de privacidad
          de Tienda Naranja Viajes, el Usuario podrá contactarse por escrito a través de la presentación
          de la solicitud respectiva por correo electrónico dirigido a contacto@tiendanaranja.com.py.
          En la solicitud deberá indicar su nombre completo y documento que acredite la representación legal
          del titular, adjuntar copia simple de su cédula de identidad, indicar su correo electrónico,
          proporcionar un número telefónico de contacto, describir en forma clara y precisa los datos personales
          respecto de los que busca ejercer su derecho y por último, adjuntar los documentos que sustenten el cambio cuando aplique.
          Una vez cumplidos los recaudos antes detallados y siempre que correspondiera atender a la solicitud,
          Tienda Naranja Viajes se comunicará con el Usuario si ha procedido a hacer lugar o si rechazara lo solicitado,
          para lo cual contará con el plazo establecido en el marco legal vigente.
        </Text>
      </li>
    </ul>


    <Text paragraph className="with-separator"><strong>Aceptación del Usuario</strong></Text>
    <ul>
      <li>
        <Text paragraph className="with-separator">
          A través del clic en la casilla de “sí”, el Usuario declara conocer y aceptar en su totalidad las presentes Políticas de Privacidad, declarando conocer que son válidas y vinculantes.
        </Text>
      </li>
    </ul>

    <Text paragraph className="with-separator"><strong>Seguridad</strong></Text>
    <ul>
      <li> <Text paragraph className="with-separator">
        El Sitio cumple con la normativa aplicable sobre seguridad de la información personal. El Sitio protege los datos personales de los Usuarios y demás información reservada y confidencial mediante el uso de cortafuegos («firewalls») y Secure Socket Layers («SSL»). Sin perjuicio de ello, en la medida que no sea posible asegurar en un 100% la seguridad de los Usuarios así como cualquier dato en general, en virtud de los distintos ataques e imprevisiones técnicas que se pueden suscitar, el Sitio no se responsabiliza por cualquier ataque cibernético, intercepción ilegal de datos o violación a sus bases de datos ni por los daños que pudieren ocasionar dichas circunstancias.
      </Text>
      </li>
    </ul>

    <Text paragraph className="with-separator">
      <strong>Exoneración de responsabilidad</strong>
    </Text>
    <ul>
      <li>
        <Text paragraph className="with-separator">
          EL USUARIO EXONERA DE RESPONSABILIDAD AL SITIO y su Administrador por cualquier daño, perjuicio o pérdida al Usuario o terceros causados por fallas en el sistema, en el servidor o en Internet. EL USUARIO TAMBIÉN EXONERA DE RESPONSABILIDAD AL SITIO Y SU ADMINISTRADOR por cualquier virus que pudiera infectar el equipo del Usuario como consecuencia del acceso, uso o examen de su sitio web o a raíz de cualquier transferencia de datos, archivos, imágenes, textos, o audios contenidos en el mismo, cuando ello no sea imputable a dolo o culpa del Sitio o su Administrador. EN VIRTUD DE LO ANTERIOR, EL SITIO Y SU ADMINISTRADOR NO SERÁ RESPONSABLE FRENTE AL USUARIO POR LOS RIESGOS Y DAÑOS REFERIDOS EN EL PRESENTE PÁRRAFO.
        </Text>
      </li>
    </ul>

    <Text paragraph className="with-separator"><strong>Enlaces</strong></Text>
    <ul>
      <li>
        <Text paragraph className="with-separator">
          La eventual presencia de enlaces a otros sitios web no implica una sociedad, relación, aprobación, respaldo del Sitio a dichos sitios ni sus contenidos.
        </Text>
      </li>
    </ul>

    <Text paragraph className="with-separator"><strong>Anuncios y Cookies</strong></Text>
    <ul>
      <li>
        <Text paragraph className="with-separator">
          Determinados proveedores terceros, incluido Google, utilizan cookies para mostrar anuncios en función de las visitas anteriores del usuario a su sitio web. Los Usuarios pueden inhabilitar el uso de cookies por parte de Google a través de la página de inhabilitación de publicidad de Google. También puede indicar a los Usuarios que inhabiliten el uso de cookies por parte de un proveedor tercero a través de la página de inhabilitación de la Network Advertising Initiative o Iniciativa publicitaria en la red.
        </Text>
      </li>
    </ul>

    <Text paragraph className="with-separator" >
      <strong>Indemnidad</strong>
    </Text>
    <ul>
      <li>
        <Text paragraph className="with-separator">
          El Usuario indemnizará y mantendrá indemne al Sitio, su Administrador y sus directivos, gerentes, representantes y empleados, por cualquier reclamo o demanda de otros Usuarios o terceros por sus actividades en el Sitio o por su incumplimiento de las Políticas de Privacidad que se entienden incorporadas al presente o por la violación de cualesquiera leyes o derechos de terceros, incluyendo los honorarios de abogados en una cantidad razonable.
        </Text>
      </li>
    </ul>


    <Text paragraph className="with-separator">
      <strong>Modificación de Términos y Condiciones como de las Políticas de Privacidad</strong>
    </Text>
    <ul>
      <li>
        <Text paragraph className="with-separator">
          Los Términos y Condiciones, así como las Políticas de Privacidad pueden ser modificados en cualquier momento, en cuyo caso se publicarán los nuevos Términos y Condiciones en el Sitio. Todos los términos modificados entrarán en vigor inmediatamente a su publicación por lo que es obligación de los Usuario informarse de ellos cada vez que accedan al Sitio.
        </Text>
      </li>
    </ul>

    <Text paragraph className="with-separator">
      <strong>Jurisdicción y Tribunales Competentes</strong>
    </Text>
    <ul>
      <li>
        <Text paragraph className="with-separator">
          Cualquier conflicto derivado del presente acuerdo, será sometido a la competencia de los tribunales de la Ciudad de Asunción y se fallará de acuerdo con la ley paraguaya.
        </Text>
      </li>
    </ul>


    {/* 
    <ul>
      <li>
        <Text>
          La mejora de los servicios que el sitio ofrece, para entender mejor a
          los usuarios de sus “plataformas” (El término “plataformas” comprende
          a: sitios web, redes sociales, sistemas de mensajería de mails,
          whatsapp, sms, notificaciones push o llamadas) y servicios, y para
          proteger su propiedad e impedir daños.
        </Text>
      </li>
      <li>
        <Text>
          Para procesar reservas de un servicio turístico de interés del
          “usuario” y guardar información sobre el/los itinerarios, si procede.
        </Text>
      </li>
      <li>
        <Text>
          Para acciones de marketing como enviar emails desde una dirección de
          correo electrónico de MeVuelo relacionados con sus servicios y
          promociones (sólo en los casos en que el usuario aceptó recibir emails
          por previa aviso de confirmación o haya realizado alguna transacción
          con el sitio), mostrar al usuario anuncios publicitarios de los
          servicios del sitio y/o promociones. Puedes darte de baja de estos
          tipos de comunicaciones en cualquier momento haciendo clic en el
          enlace correspondiente.
        </Text>
      </li>
      <li>
        <Text>
          Para el cumplimiento de contratos relacionados a los servicios que el
          usuario haya solicitado.
        </Text>
      </li>
    </ul> */}
    {/* <Text paragraph className="with-separator">
      El Sitio sólo utilizará los datos personales obtenidos de sus usuarios web
      si se cumplen acumulativamente los siguientes requisitos: (i) el Usuario
      se registra en el Sitio o en formularios de publicidad de la empresa
      mencionada o asociados brindando los datos personales solicitados; y (ii)
      el Usuario acepta los presentes Términos y Condiciones del Sitio.
    </Text>
    <Text paragraph className="with-separator">
      El Usuario podrá solicitar que su registro de usuario y sus datos
      personales sean eliminados del Sitio y de sus bases de datos mediante el
      envío de un correo electrónico a hola@mevuelo.com con el Asunto (Subject):
      «Desuscribirme» e incluyendo el número de teléfono del Usuario en el
      cuerpo del correo electrónico.
    </Text>
    <Text
      paragraph
      className="with-separator title-background"
      weight="bold"
      context="white">
      Los datos personales recabados por MeVuelo podrán ser compartidos a
    </Text>
    <ul>
      <li>
        <Text>
          Proveedores que realizan servicios en nombre de MeVuelo y que han
          aceptado por escrito proteger y no revelar esta información.
        </Text>
      </li>
      <li>
        <Text>
          Proveedores de servicios de pago y proveedores de viajes. Esto incluye
          agencias de viaje en línea, hoteles, aerolíneas, agencias de alquiler
          de autos, proveedores de seguros de viaje y otros. Estos terceros
          procesarán los Datos Personales del Usuario en calidad de encargado de
          tratamientos y por este motivo MeVuelo recomienda que el Usuario
          analice las políticas de privacidad de estos proveedores en su caso
          debido.
        </Text>
      </li>
      <li>
        <Text>
          La información compartida en público como la opinión del Usuario sobre
          su viaje, en la que autoriza automáticamente a MeVuelo a publicarla en
          todas su “plataformas” bajo el nombre que haya proporcionado y a
          mostrarla junto con otras valoraciones.
        </Text>
      </li>
      <li>
        <Text>
          Cumplimiento de la normativa aplicable: MeVuelo podría divulgar tus
          Datos Personales si la normativa aplicable asi lo solicitara y para
          cooperar con las autoridades competentes.
        </Text>
      </li>
    </ul>
    <Text paragraph className="with-separator">
      Los puntos antes descritos son solo algunos de los ejemplos en los que el
      Usuario o cliente comprende y acepta que para llevar a cabo transacciones
      de compra a través de Mevuelo, implicará de forma necesaria que Mevuelo
      comparta Datos Personales.
    </Text>

    <Text paragraph className="with-separator">
      Es importante aclarar que dichas terceras personas, socios comerciales y
      empresas, actuarán en calidad de encargados de tratamiento y tienen por la
      normativa aplicable prohibida la utilización o divulgación no autorizada
      de la información personal a la cual tienen acceso.
    </Text>

    <Text
      paragraph
      className="with-separator title-background"
      weight="bold"
      context="white">
      Introducción
    </Text>

    <Text paragraph className="with-separator">
      El presente documento describe las Políticas de Privacidad generales (las
      «Políticas de Privacidad») aplicables al uso del Sitio y los servicios que
      la empresa ofrece. El Usuario que desee acceder y/o usar el Sitio o los
      servicios regulados en el Sitio, acepta sus Políticas de Privacidad, junto
      con todos los demás términos, condiciones y principios que rigen la
      contratación con el Sitio, que son incorporados al presente por
      referencia.
    </Text>

    <Text paragraph className="with-separator">
      El Usuario acepta todas las condiciones establecidas en las Políticas de
      privacidad previo a la navegación en el Sitio, la utilización de cualquier
      servicio o el registro como usuario registrado. Por ello, es obligación y
      carga del Usuario, leer y entender los Términos y Condiciones, así como
      las Políticas de Privacidad previo a cualquier utilización del Sitio como
      de algún servicio o contacto con la marca MeVuelo.
    </Text>

    <Text
      paragraph
      className="with-separator title-background"
      weight="bold"
      context="white">
      El Servicio y la relación del Usuario con la Empresa
    </Text>

    <Text paragraph className="with-separator">
      El Usuario acepta y reconoce que los servicios que se prestan mediante el
      Sitio se limitan a obtener los datos personales del Usuario y a usarlos
      para su contactación con la empresa, así como para la realización de
      acciones necesarias para concretar la venta o reserva de los servicios
      mencionados en el sitio.
    </Text>
    <Text paragraph className="with-separator">
      El Sitio y su Administrador se reservan el derecho de rechazar o cancelar
      cualquier solicitud de servicio sin que esté obligado a comunicar o
      exponer las razones de su decisión y sin que ello genere algún derecho a
      indemnización o resarcimiento.
    </Text>
    <Text paragraph className="with-separator">
      Si por cualquier razón el Usuario no viera finalizados todos los pasos
      proporcionados por el sistema para poder acceder a los servicios del
      Sitio, deberá volver a iniciar el proceso para lograr el registro en el
      formulario de contacto o consultar/ llamar a MeVuelo por intermedio de los
      contactos y redes sociales mencionadas en el sitio.
    </Text>

    <Text
      paragraph
      className="with-separator title-background"
      weight="bold"
      context="white">
      Datos Personales
    </Text>

    <Text paragraph className="with-separator">
      El Usuario no está de ningún modo obligado a aportar sus datos personales
      para acceder al Sitio o navegar en él.
    </Text>

    <Text paragraph className="with-separator">
      El Usuario podrá elegir no aceptar la presente Política de Privacidad y no
      brindar sus Datos Personales pero reconocerá entonces que Mevuelo requiere
      cierta información personal para que éste pueda adquirir servicios
      turísticos y por lo tanto, no podrán llevarse a cabo las contrataciones de
      dichos servicios.
    </Text>
    <Text paragraph className="with-separator">
      Al proporcionarnos acceso a sus Datos personales o de terceros, el Usuario
      confirma que son auténticos, exactos y que están actualizados. Asimismo,
      expresa que tiene autorización para proporcionarlos a MeVuelo.
    </Text>
    <Text paragraph className="with-separator">
      El Sitio se reserva el derecho de solicitar algún comprobante adicional a
      efectos de corroborar los datos personales proporcionados (sin que ello
      suponga obligación alguna por parte del Sitio o su Administrador), así
      como de suspender temporal o definitivamente a Usuarios y/o negarse a
      brindar los servicios en los casos en que los datos no hayan podido ser
      confirmados.
    </Text>
    <Text paragraph className="with-separator">
      El Usuario es responsable por cualquier dato erróneo de datos de otras
      personas que informe a MeVuelo y de los daños y perjuicios que dicho dato
      erróneo o comunicación de datos personales de terceros pudiere ocasionar,
      sin limitación.
    </Text>
    <Text paragraph className="with-separator">
      Mevuelo podrá grabar llamados o contactaciones que tuviese con el Usuario
      y/o controlar para propósitos de control de calidad, formación del
      personal y mejor atención. Las grabaciones de dichas llamadas se
      conservarán durante el tiempo que sea necesario para cumplir con el
      servicio y/o que sea legamente requerido, y posteriormente se eliminarán.
    </Text>
    <Text paragraph weight="bold" className="with-separator">
      ASIMISMO, EL USUARIO PRESTA SU CONSENTIMIENTO RESPECTO DE QUE EL
      ADMINISTRADOR PODRÁ UTILIZAR LOS DATOS APORTADOS POR EL USUARIO PARA
      ENVIAR INFORMACIÓN Y/O PROMOCIONES Y/O PUBLICIDAD ASOCIADA AL SITIO Y SUS
      SERVICIOS.
    </Text>
    <Text paragraph className="with-separator">
      En todos los casos el Usuario autoriza expresamente la utilización de su
      información personal para llevar a cabo estudios internos sobre los
      intereses, comportamientos y demografía de los Usuarios en general, de
      modo de que el Sitio cuente con información que le permita mejorar el
      Sitio y sus servicios.
    </Text>
    <Text paragraph className="with-separator">
      En todos los casos el titular de los datos personales tiene la facultad de
      ejercer el derecho de acceso a los mismos en forma gratuita a intervalos
      no inferiores a seis meses, salvo que se acredite un interés legítimo al
      efecto conforme lo establecido en el artículo 14, de la Ley No 18.331.
    </Text>
    <Text paragraph className="with-separator">
      La Unidad Reguladora y de Control de Datos Personales, Órgano de Control
      de la Ley No 18.331, tiene la atribución de atender las denuncias y
      reclamos que se interpongan con relación al incumplimiento de las normas
      sobre protección de datos personales.
    </Text>
    <Text paragraph className="with-separator">
      Para ejercer sus derechos antes descritos o por consultas sobre las
      prácticas de privacidad se MeVuelo, el Usuario podrá contactarse por
      escrito a través de la presentación de la solicitud respectiva por correo
      electrónico dirigido a hola@mevuelo.com. En la solicitud deberá indicar su
      nombre completo y documento que acredite la representación legal del
      titular, adjuntar copia simple de su documento nacional de identidad,
      indicar su correo electrónico, dirección postal para notificaciones,
      proporcionar un número telefónico de contacto, describir en forma clara y
      precisa los datos personales respecto de los que busca ejercer el derecho
      de acceso, rectificación, actualización, inclusión o supresión y por
      último, adjuntar los documentos que sustenten el cambio. Una vez cumplidos
      los recaudos antes detallados y siempre que correspondiera atender a la
      solicitud, MeVuelo se comunicará con el Usuario si ha procedido a hacer
      lugar o si rechazara lo solicitado, para lo cual contará con 5 (cinco)
      días hábiles desde la recepción de la solicitud (artículo 14 de la Ley
      18.331).
    </Text>
    <Text
      paragraph
      className="with-separator title-background"
      weight="bold"
      context="white">
      Aceptación del Usuario
    </Text>

    <Text paragraph className="with-separator">
      A través del clic en la casilla de “sí”, el Usuario declara conocer y
      aceptar en su totalidad las presentes Políticas de Privacidad, declarando
      conocer que son válidas y vinculantes.
    </Text>

    <Text
      paragraph
      className="with-separator title-background"
      weight="bold"
      context="white">
      Seguridad
    </Text>

    <Text paragraph className="with-separator">
      El Sitio cumple con la normativa aplicable sobre seguridad de la
      información personal. El Sitio protege los datos personales de los
      Usuarios y demás información reservada y confidencial mediante el uso de
      cortafuegos («firewalls») y Secure Socket Layers («SSL»). Sin perjuicio de
      ello, en la medida que no es posible asegurar en un 100% la seguridad de
      los Usuarios así como cualquier dato en general, en virtud de los
      distintos ataques e imprevisiones técnicas que se pueden suscitar, el
      Sitio no se responsabiliza por cualquier ataque cibernético, intercepción
      ilegal de datos o violación a sus bases de datos ni por los daños que
      pudieren ocasionar dichas circunstancias.
    </Text>
    <Text paragraph className="with-separator">
      Los Datos Personales compartidos a Mevuelo serán tratados con el grado de
      protección legalmente exigible para preservar su seguridad y evitar su
      alteración, pérdida, tratamiento o acceso no autorizado. Mevuelo no
      transmitirá, divulgará o proporcionará los Datos Personales recopilados a
      terceros diferentes a los mencionados en esta Política de Privacidad.
    </Text>

    <Text
      paragraph
      className="with-separator title-background"
      weight="bold"
      context="white">
      Exoneración de responsabilidad
    </Text>

    <Text paragraph className="with-separator">
      EL USUARIO EXONERA DE RESPONSABILIDAD AL SITIO y su Administrador por
      cualquier daño, perjuicio o pérdida al Usuario o terceros causados por
      fallas en el sistema, en el servidor o en Internet. EL USUARIO TAMBIÉN
      EXONERA DE RESPONSABILIDAD AL SITIO Y SU ADMINISTRADOR por cualquier virus
      que pudiera infectar el equipo del Usuario como consecuencia del acceso,
      uso o examen de su sitio web o a raíz de cualquier transferencia de datos,
      archivos, imágenes, textos, o audio contenidos en el mismo, cuando ello no
      sea imputable a dolo o culpa del Sitio o su Administrador. EN VIRTUD DE LO
      ANTERIOR, EL SITIO Y SU ADMINISTRADOR NO SERÁ RESPONSABLE FRENTE AL
      USUARIO POR LOS RIESGOS Y DAÑOS REFERIDOS EN EL PRESENTE PÁRRAFO.
    </Text>
    <Text paragraph className="with-separator">
      Los Usuarios NO podrán imputarle responsabilidad alguna ni exigir pago por
      lucro cesante, en virtud de perjuicios resultantes de dificultades
      técnicas o fallas en los sistemas o en Internet. No se garantiza el acceso
      y uso continuado o ininterrumpido del Sitio, pudiendo el sistema
      eventualmente no estar disponible debido a dificultades técnicas o fallas
      de Internet, o por cualquier otra circunstancia ajena al Sitio; en tales
      casos se procurará restablecerlo con la mayor celeridad posible sin que
      por ello pueda imputársele algún tipo de responsabilidad.
    </Text>

    <Text
      paragraph
      className="with-separator title-background"
      weight="bold"
      context="white">
      Enlaces
    </Text>
    <Text paragraph className="with-separator">
      La eventual presencia de enlaces a otros sitios web no implica una
      sociedad, relación, aprobación, respaldo del Sitio a dichos sitios ni sus
      contenidos.
    </Text>
    <Text
      paragraph
      className="with-separator title-background"
      weight="bold"
      context="white">
      Anuncios y Cookies
    </Text>
    <Text paragraph className="with-separator">
      Determinados proveedores terceros, incluido Google, utilizan cookies para
      mostrar anuncios en función de las visitas anteriores del usuario a su
      sitio web. Los Usuarios pueden inhabilitar el uso de cookies por parte de
      Google a través de la página de inhabilitación de publicidad de Google.
      También puede indicar a los Usuarios que inhabiliten el uso de cookies por
      parte de un proveedor tercero a través de la página de inhabilitación de
      la Network Advertising Initiative o Iniciativa publicitaria en la red.
    </Text>
    <Text
      paragraph
      className="with-separator title-background"
      weight="bold"
      context="white">
      Indemnidad
    </Text>
    <Text paragraph className="with-separator">
      El Usuario indemnizará y mantendrá indemne al Sitio, su Administrador y
      sus directivos, gerentes, representantes y empleados, por cualquier
      reclamo o demanda de otros Usuarios o terceros por sus actividades en el
      Sitio o por su incumplimiento de las Políticas de Privacidad que se
      entienden incorporadas al presente o por la violación de cualesquiera
      leyes o derechos de terceros, incluyendo los honorarios de abogados en una
      cantidad razonable.
    </Text>
    <Text
      paragraph
      className="with-separator title-background"
      weight="bold"
      context="white">
      Modificación de Términos y Condiciones como de las Políticas de Privacidad
    </Text>
    <Text paragraph className="with-separator">
      Los Términos y Condiciones, así como las Políticas de Privacidad pueden
      ser modificados en cualquier momento, en cuyo caso se publicarán los
      nuevos Términos y Condiciones en el Sitio. Todos los términos modificados
      entrarán en vigor inmediatamente a su publicación por lo que es obligación
      de los Usuario informarse de ellos cada vez que accedan al Sitio.
    </Text>
    <Text
      paragraph
      className="with-separator title-background"
      weight="bold"
      context="white">
      Jurisdicción y Tribunales Competentes
    </Text>
    <Text paragraph className="with-separator">
      Cualquier conflicto derivado del presente acuerdo, será sometido a la
      competencia de los tribunales de la Ciudad de Montevideo y se fallará de
      acuerdo a la ley uruguaya.
    </Text> */}
  </div>
);

PoliticsAndPrivacy.propTypes = {
  className: PropTypes.string
};

export default styled(PoliticsAndPrivacy)`
  .with-separator {
    margin: 20px 10px;
  }

  .with-top-separator {
    margin: 10px 10px 0 10px;
  }

  .with-lr-separator {
    margin: 0 10px;
  }

  .title-background {
    background: ${darkGrey};
    padding-left: 10px;
    border-top-left-radius: 16px;
    border-bottom-right-radius: 16px;
  }
`;
