import cs from 'classnames';
import PackageAvailabilityCard from '../PackageAvailabilityCard';
import * as COLORS from '../../../../Constants/colors';
import { buildPackageUrl } from '../../helpers';
import React, { useState } from 'react';
import * as Styles from './styles';
import useBreakpoints from '../../../../Utils/hooks/useBreakpoints';
import maleta from 'Assets/maleta.svg';

export const PackageCommonList = ({ packageList, dividerTitle }: Props) => {
  const { largeDown, mediumDown } = useBreakpoints();

  const itemClassName = cs('item', {
    medium: mediumDown,
    large: largeDown
  });

  const [currentIndex, setCurrentIndex] = useState(0);
  const length = packageList.length;

  const next = () => {
    if (currentIndex < length - 1) {
      setCurrentIndex(prevState => prevState + 1);
    }
  };

  const prev = () => {
    if (currentIndex > 0) {
      setCurrentIndex(prevState => prevState - 1);
    }
  };

  return (
    <Styles.PackageCommonListContainer>
      {dividerTitle && (
        <Styles.TitleHolder style={{ marginTop: 160 }}>
          <img src={maleta} alt="paquetes" />
          <span>Los paquetes más pedidos por nuestros viajeros</span>
        </Styles.TitleHolder>
      )}

      {currentIndex > 0 && !mediumDown && (
        <button onClick={prev} className="left-arrow">
          &lt;
        </button>
      )}
      <div
        className={cs(
          'more-packages-mobile',
          { large: largeDown },
          { medium: mediumDown }
        )}>
        {packageList.map(pckg => (
          <div
            style={{ transform: `translateX(-${currentIndex * 100}%)` }}
            className={itemClassName}
            key={pckg.id + pckg.title}>
            <PackageAvailabilityCard
              image={pckg.image}
              price={pckg.lowerPrice.amount}
              currency={pckg.lowerPrice.currency}
              title={pckg.title}
              icons={pckg.compositeIcons}
              color={pckg.shadowColor ? pckg.shadowColor : COLORS.green}
              lowerLocalPrice={pckg.lowerLocalPrice}
              duration={pckg.duration}
              travelOn={pckg.travelOn}
              url={buildPackageUrl(pckg.keyword, pckg.id)}
              basePrice={pckg.basePrice}
              actions={undefined}
              orderBy={undefined}
              orderByOptions={undefined}
            />
          </div>
        ))}
      </div>
      {currentIndex < length - 1 && !mediumDown && (
        <button onClick={next} className="right-arrow">
          &gt;
        </button>
      )}
    </Styles.PackageCommonListContainer>
  );
};

interface Props {
  packageList: PackageAvailabilityCardProps[];
  dividerTitle?: string;
}

interface PackageAvailabilityCardProps {
  className?: string;
  currency?: string;
  price?: number;
  title: string;
  image: string;
  lowerPrice: any; // FIXME
  duration: string;
  travelOn: string;
  icons?: {
    name: string;
    icon: string;
  }[];
  lowerLocalPrice?: any; // FIXME
  compositeIcons: any; // FIXME
  shadowColor: any; // FIXME
  keyword: any; // FIXME
  id: any; // FIXME
  color?: string;
  index?: number;
  url?: string;
  basePrice: number;
  rating?: {
    name: string;
    tagColor: string;
  }[];
}
