/**
 *
 * MobileFlightDestination
 *
 */

import React from 'react';
import PropTypes from 'prop-types';
import MobileFlightDestinationCard from '../MobileFlightDestinationCard';
import { locationTypes } from 'Components/DestinationFlight';

const MobileFlightDestination = ({ className, index }) => (
  <>
    <MobileFlightDestinationCard type={locationTypes.ORIGIN} index={index} />
    <MobileFlightDestinationCard
      type={locationTypes.DESTINATION}
      index={index}
    />
  </>
);

MobileFlightDestination.propTypes = {
  className: PropTypes.string,
  index: PropTypes.number
};

export default MobileFlightDestination;
