/**
 *
 * MobileHotelDestinationCard
 *
 */

import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { useSelector } from 'react-redux';
import cs from 'classnames';
import * as COLORS from 'Constants/colors';
import { ReactComponent as Location } from './location.svg';
import MobileHotelDestinationSearch from '../MobileHotelDestinationSearch';
import Portal from 'Components/Portal';

const selector = ({ hotels }) => ({
  location: hotels.search.location
});

const ConnectedMobileHotelDestinationCard = props => {
  const state = useSelector(selector);
  return <StyledMobileHotelDestinationCard {...state} {...props} />;
};

const MobileHotelDestinationCard = ({ className, location }) => {
  const [openModal, setOpenModal] = useState();

  const toggleModal = () => {
    setOpenModal(!openModal);
  };

  useEffect(() => {
    document.body.classList.toggle('non-scroll', openModal);
  }, [openModal]);

  return (
    <div className={cs(className)}>
      <InputHolder onClick={toggleModal}>
        <Location />{' '}
        {location.description
          ? location.description.split(',')[1]
          : 'Destino: ciudad o alojamiento.'}
      </InputHolder>
      {openModal && (
        <Portal>
          <MobileHotelDestinationSearch onClose={toggleModal} />
        </Portal>
      )}
    </div>
  );
};

MobileHotelDestinationCard.propTypes = {
  className: PropTypes.string.isRequired,
  location: PropTypes.shape({
    placeId: PropTypes.string,
    description: PropTypes.string
  })
};

const InputHolder = styled.div`
  display: flex;
  flex-direction: row;
  cursor: pointer;
  align-items: center;
  padding: 10px 12px;
  width: 100%;
  height: 40px;
  border: 1px solid rgba(40, 40, 40, 0.25);
  gap: 12px;
  border-radius: 5px;
  font-family: 'Itau Display Pro';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  display: flex;
  align-items: center;
  color: rgba(40, 40, 40, 0.5);
`;

const StyledMobileHotelDestinationCard = styled(MobileHotelDestinationCard)`
  display: flex;
  background-color: ${COLORS.white};

  .container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-radius: 14px;
    box-shadow: 2px 2px 6px 0 rgba(0, 0, 0, 0.25);
    padding: 10px;

    .row {
      display: flex;
      align-items: center;
    }

    .icon {
      color: ${COLORS.important};
      margin-right: 5px;

      &::before {
        vertical-align: -15%;
      }
    }
  }
`;

export default ConnectedMobileHotelDestinationCard;
export { StyledMobileHotelDestinationCard as MobileHotelDestinationCard };
