import { formatIso } from 'Utils/dates';
import { airSearchTypes, flightSearchTypes, getInitialStates } from 'Constants';
import { addDays } from 'date-fns';

export const emptyRouteValidations = {
  origin: [],
  destination: [],
  initialDate: [],
  endDate: []
};

export const mpcItemStatus = {
  DEFAULT: 'DEFAULT',
  MORE_EXPENSIVE: 'MORE_EXPENSIVE',
  SAME_PRICE: 'SAME_PRICE',
  CHEAPER: 'CHEAPER',
  CHEAPER_MIN: 'CHEAPER_MIN',
  CHEAPER_20: 'CHEAPER_20',
  CHEAPER_40: 'CHEAPER_40',
  CHEAPER_60: 'CHEAPER_60',
  CHEAPER_80: 'CHEAPER_80',
  CHEAPER_MAX: 'CHEAPER_MAX',
  LOADING: 'LOADING',
  RESEARCH: 'RESEARCH',
  EMPTY: 'EMPTY'
};

export const statusTypes = {
  ready: 'ready',
  unused: 'unused',
  loading: 'loading',
  error: 'error'
};

export const initialValidations = {
  paxDetails: [],
  routesData: [emptyRouteValidations, emptyRouteValidations]
};

export const emptyRoute = {
  origin: '',
  originName: '',
  destination: '',
  destinationName: '',
  initialDate: formatIso(addDays(new Date(), 60)),
  endDate: formatIso(addDays(new Date(), 60 + 8))
};

export const minDateCalendar = new Date(
  new Date().getTime() + 3 * 24 * 60 * 60 * 1000
);

export const initialState = {
  search: {
    routesData: [
      {
        origin: process.env.REACT_APP_AIRPORT_CODE_DEFAULT,
        originName: process.env.REACT_APP_AIRPORT_NAME_DEFAULT,
        destination: '',
        destinationName: '',
        initialDate: formatIso(addDays(new Date(), 60)),
        endDate: formatIso(addDays(new Date(), 59 + 8))
      },
      emptyRoute
    ],
    cityKeyword: '',
    adults: 1,
    children: [],
    type: airSearchTypes.roundTrip,
    groupResults: true,
    isMPC: false,
    cabinCode: null,
    validations: initialValidations,
    status: 'unused'
  },
  mpcAvailability: {
    loading: false,
    error: false,
    data: {},
    selectionError: undefined
  },
  mptbAvailability: {
    loading: false,
    error: false,
    data: {},
    selectionError: undefined
  },
  availability: {
    typeFlight: flightSearchTypes[0].id,
    status: 'unused',
    resetFilters: false,
    data: {
      request: getInitialStates(flightSearchTypes[0].id)
    },
    selectionError: undefined,
    checkSelection: '',
    pending: false
  }
};

export const limits = {
  adults: {
    min: 1,
    max: 9
  },
  totalPeople: {
    min: 1,
    max: 9
  },
  routes: {
    min: 2,
    max: 6
  }
};

export const validationMessages = {
  origin: { empty: 'Origen no puede estar vacío' },
  destination: {
    empty: 'Destino no puede estar vacío',
    same: 'El destino no puede ser igual al origen'
  },
  initialDate: {
    empty: 'Fecha de salida no puede estar vacía',
    inPast: 'Fecha de salida no puede ser en el pasado',
    beforePrevious: 'Fecha de salida tiene que ser posterior al tramo anterior'
  },
  endDate: {
    empty: 'Fecha de arrivo no puede estar vacía',
    inPast: 'Fecha de arrivo no puede ser en el pasado',
    beforeInitial: 'Fecha de arrivo no puede ser anterior a la salida'
  },
  adults: {
    min: `No puede haber menos de ${limits.adults.min} adultos`,
    max: `No puede haber más de ${limits.adults.max} adultos`
  },
  totalInfant: {
    max: 'No puede haber más infantes sin asiento que adultos'
  },
  totalPassangers: {
    max: `No puede haber más de ${limits.totalPeople.max} pasajeros`,
    min: `No puede haber menos de ${limits.totalPeople.min} pasajeros`
  }
};

export const packagesWhenType = {
  SEASON: 'SEASON',
  MONTH: 'MONTH'
};

export const whileSearchMessages = [
  'Con tu tarjeta Itaú, ¡aprovechá las cuotas sin intereses y planificá tu viaje!', 
  'Con tus puntos Itaú, estás más cerca de un nuevo destino.',
  'Comprá desde donde estés y empezá a explorar el mundo',
  'Tienda Naranja le da un nuevo color a tus viajes.'
];
