/**
 *
 * Banner
 *
 */

import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import cs from 'classnames';
import { useSelector } from 'react-redux';
import LinkTo from 'Components/LinkTo';
import LazyImg from 'Components/LazyImg';
import logoItau from 'Assets/logo_naranja.svg';
import { breakpoints } from 'Constants';

const Banner = ({
  className,
  type,
  description,
  price,
  title,
  shadowColor,
  currency,
  image,
  url,
  icons,
  openInNewWindows
}) => {
  const {
    selectedItauPoints,
    itauPoints: { USD }
  } = useSelector(state => state.appConfiguration);

  const costCurrencyInPoints = USD;
  const priceInPoints = Math.ceil(price / costCurrencyInPoints);
  const moneyInPoints = selectedItauPoints * costCurrencyInPoints;
  const moneyToComplete = Math.ceil(price - moneyInPoints);
  const pointsToRender =
    selectedItauPoints <= priceInPoints
      ? selectedItauPoints.toLocaleString('en').replaceAll(',', '.')
      : priceInPoints.toLocaleString('en').replaceAll(',', '.');

  return (
    <MainHolder>
      <LinkTo
        className={cs(className)}
        urlTo={url}
        alt=""
        openInNewTab={openInNewWindows}>
        <Holder>
          <LazyImg src={image} alt={title} className="backgroundHolder" />
          <PriceHolder>
            <PriceTag>{`${currency} ${price}`}</PriceTag>
            <PromoPriceTag>
              <span>ó {pointsToRender} pts</span>
              <img src={logoItau} alt="itau" />
              <span>+ USD {moneyToComplete < 0 ? 0 : moneyToComplete}</span>
            </PromoPriceTag>
          </PriceHolder>
          <div className="orangeMask">
            {title && <Title>{title}</Title>}
            {description && <Description>{description}</Description>}
          </div>
        </Holder>
      </LinkTo>
    </MainHolder>
  );
};

Banner.propTypes = {
  type: PropTypes.number,
  description: PropTypes.string,
  price: PropTypes.any,
  title: PropTypes.string,
  shadowColor: PropTypes.string,
  currency: PropTypes.string,
  image: PropTypes.string,
  openInNewWindows: PropTypes.bool,
  url: PropTypes.string,
  icons: PropTypes.array
};

const MainHolder = styled.div`
  @media screen and (max-width: ${breakpoints.medium}px) {
    min-width: 75%;
  }
`;
const Holder = styled.div`
  width: 100%;
  height: 454px;
  border-radius: 14px;
  position: relative;
  overflow: hidden;

  .backgroundHolder {
    background-position: center;
    background-size: cover;
    border-radius: 16px 14px 14px 14px;
    position: absolute;
    width: 100%;
    height: 100%;
    transition: transform 0.6s;
  }

  .orangeMask {
    position: absolute;
    left: 0%;
    top: 0%;
    background: linear-gradient(360deg, #fb8400 0%, rgba(251, 132, 0, 0) 50%),
      url(.jpg), transparent;
    border-radius: 14px;
    width: 100%;
    height: 100%;
  }
  @media screen and (max-width: ${breakpoints.medium}px) {
    margin: 15px;
    width: 100%;
    height: 300px;
  }
`;

const Title = styled.h4`
  position: absolute;
  bottom: 22px;
  font-family: 'Itau Display Pro';
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 32px;
  text-align: center;
  color: #ffffff;
  width: 100%;
  padding: 0 46px;
`;

const Description = styled.h5`
  position: absolute;
  bottom: 0px;
  font-family: 'Itau Display Pro';
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 32px;
  text-align: center;
  color: #ffffff;
  width: 100%;
  padding: 0 15px;
`;

const PriceHolder = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 5px 20px;
  position: absolute;
  width: 242px;
  left: 0;
  top: 0;
  background: #ffffff;
  border-radius: 14px 0px;
  border: #e7e8e7;
  border-style: groove;
  border-width: thin;
`;

const PriceTag = styled.div`
  font-family: 'Itau Display Pro';
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 32px;
  color: #282828;
`;

export const PromoPriceTag = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 0px;
  gap: 10px;
  font-family: 'Itau Display Pro';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #003399;

  img {
    width: 24px;
  }
`;

export default Banner;
