/**
 *
 * MobileFlightDestinationCard
 *
 */

import React, { useRef, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import cs from 'classnames';

import MobileFlightDestinationSearch from '../MobileFlightDestinationSearch';
import * as COLORS from 'Constants/colors';
import useOutsideClick from '../../../../Utils/hooks/useOutsideClick';
import { locationTypes } from 'Components/DestinationFlight';
import Portal from 'Components/Portal';

import origen from './origen.svg';
import destino from './destino.svg';

const MobileFlightDestinationCard = ({
  className,
  location,
  locationName,
  label,
  type,
  index
}) => {
  const [openModal, setOpenModal] = useState(false);
  const refOpenModal = useRef();

  useOutsideClick(refOpenModal, () => {
    if (openModal) setOpenModal(false);
  });

  const toggleModal = () => {
    setOpenModal(!openModal);
  };

  useEffect(() => {
    document.body.classList.toggle('non-scroll', openModal);
  }, [openModal]);

  return (
    <div className={cs(className)} onClick={toggleModal}>
      <img src={label.toLowerCase() === 'origen' ? origen : destino} />{' '}
      <span>{`${label}: ${location} ${locationName ||
        'Ingresá donde viajas'}`}</span>
      {openModal && (
        <Portal>
          <MobileFlightDestinationSearch
            onClose={toggleModal}
            type={type}
            index={index}
          />
        </Portal>
      )}
    </div>
  );
};

MobileFlightDestinationCard.propTypes = {
  className: PropTypes.string,
  location: PropTypes.string,
  locationName: PropTypes.string,
  label: PropTypes.string.isRequired,
  type: PropTypes.oneOf(Object.values(locationTypes)),
  index: PropTypes.number
};

export default styled(MobileFlightDestinationCard)`
  display: block;
  background-color: ${COLORS.white};
  height: 40px;
  font-family: 'Itau Display Pro';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  display: flex;
  align-items: center;
  color: rgba(40, 40, 40, 0.5);
  gap: 7px;
  border: 1px solid rgba(40, 40, 40, 0.25);
  border-radius: 5px;
  padding: 7px;
`;
