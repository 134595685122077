import React from 'react';
import { useSelector } from 'react-redux';

import { Escalador } from 'Features/flights/components/Esclador';
import { getAirLineLogo } from 'Utils';
import { excludeAirlineBaggage } from 'Constants';

import { LocalPrice, SelectedSegments } from '../../types';
import { ReactComponent as Avion } from '../../assets/avion.svg';
import { ReactComponent as Mochila } from '../../assets/mochila.svg';
import { ReactComponent as CarryOn } from '../../assets/carryOn.svg';
import {ReactComponent as Suitcase} from '../../assets/suitcase.svg';
import { ReactComponent as Itau } from '../../assets/Itau.svg';
import { ContentCard } from '../Common';

import {
  SideHeader,
  SideContent,
  SegmentHeader,
  IndicadorTramo,
  Divider,
  SegmentDate,
  Column,
  SegmentItemDetailHolder,
  SegmentDetails,
  CityDataHolder,
  BreakdownHolder,
  BreakdownTitle,
  BreakdownItem,
  BreakdownTotal,
  BreakdownItau
} from './styles';

const formatMoney = value => value.toLocaleString('en').replaceAll(',', '.');

interface Props {
  selectedSegment?: SelectedSegments;
  localPrice?: LocalPrice;
  hideDesgloce?: boolean;
  isCheckoutMobile?: boolean;
}

export const SideDetails = ({
  selectedSegment,
  localPrice,
  hideDesgloce,
  isCheckoutMobile
}: Props) => {
  const {
    selectedItauPoints,
    itauPoints: { PYG }
  } = useSelector(
    // @ts-ignore
    state => state.appConfiguration
  );

  const costCurrencyInPoints = PYG;
  const priceInPoints = localPrice
    ? localPrice.totalForOneAdult * costCurrencyInPoints
    : 0;
  const moneyInPoints = selectedItauPoints / costCurrencyInPoints;
  const moneyToComplete = localPrice
    ? Math.ceil(localPrice.totalForOneAdult - moneyInPoints)
    : 0;
  const pointsToRender =
    selectedItauPoints <= priceInPoints
      ? selectedItauPoints.toLocaleString('en').replaceAll(',', '.')
      : priceInPoints.toLocaleString('en').replaceAll(',', '.');
      
  return (
    <div>
      <ContentCard noPaddingBottom={isCheckoutMobile}>
        {!isCheckoutMobile && <SideHeader>DETALLE DE TU COMPRA</SideHeader>}
        <SideContent>
          {selectedSegment?.ida && (
            <>
              <SegmentHeader>
                <IndicadorTramo>
                  <Avion />
                  <span>IDA</span>
                </IndicadorTramo>
                <SegmentDate>
                  {selectedSegment.ida.item.departureData.flightDate}
                </SegmentDate>
              </SegmentHeader>

              <Column className="ticket">
                <span>Ticket</span>
                <SegmentItemDetailHolder>
                  <img
                    src={getAirLineLogo(
                      selectedSegment.ida.item.airLines[0].code
                    )}
                    alt={selectedSegment.ida.item.airLines[0].name}
                  />
                  <SegmentDetails>
                    <CityDataHolder>
                      {selectedSegment.ida.item.departureData.airport.code}
                      <span>
                        {selectedSegment.ida.item.departureData.flightTime}
                      </span>
                    </CityDataHolder>
                    <Escalador
                      stopovers={selectedSegment.ida.item.scales.length}
                    />
                    <CityDataHolder>
                      {selectedSegment.ida.item.arrivalData.airport.code}
                      <span>
                        {selectedSegment.ida.item.arrivalData.flightTime}
                      </span>
                    </CityDataHolder>
                  </SegmentDetails>
                </SegmentItemDetailHolder>
              </Column>
              <Column>
                {!(selectedSegment.ida.item.baggage.total === 0 &&
                  excludeAirlineBaggage.includes(
                    selectedSegment.ida.item.airLines[0].code
                  )) && (
                    <span>
                      <Suitcase /> Incluye equipaje de cabina{' '}
                    </span>
                  )}
                {!(selectedSegment.ida.item.baggage.total === 0) && (
                  <span>
                    <CarryOn /> Incluye equipaje para despachar
                  </span>
                )}
              </Column>
            </>
          )}

          {selectedSegment?.vuelta.item && (
            <>
              <Divider />
              <SegmentHeader>
                <IndicadorTramo>
                  <Avion className="rotated" />
                  <span>VUELTA</span>
                </IndicadorTramo>
                <SegmentDate>
                  {selectedSegment.vuelta.item.departureData.flightDate}
                </SegmentDate>
              </SegmentHeader>
              <Column className="ticket">
                <span>Ticket </span>
                <SegmentItemDetailHolder>
                  <img
                    src={getAirLineLogo(
                      selectedSegment.vuelta.item.airLines[0].code
                    )}
                    alt={selectedSegment.vuelta.item.airLines[0].name}
                  />
                  <SegmentDetails>
                    <CityDataHolder>
                      {selectedSegment.vuelta.item.departureData.airport.code}
                      <span>
                        {selectedSegment.vuelta.item.departureData.flightTime}
                      </span>
                    </CityDataHolder>
                    <Escalador
                      stopovers={selectedSegment.vuelta.item.scales.length}
                    />
                    <CityDataHolder>
                      {selectedSegment.vuelta.item.arrivalData.airport.code}
                      <span>
                        {selectedSegment.vuelta.item.arrivalData.flightTime}
                      </span>
                    </CityDataHolder>
                  </SegmentDetails>
                </SegmentItemDetailHolder>
              </Column>
              <Column>
                {!(selectedSegment.vuelta.item.baggage.total === 0 &&
                  excludeAirlineBaggage.includes(
                    selectedSegment.vuelta.item.airLines[0].code
                  )) && (
                    <span>
                      <Suitcase /> Incluye equipaje de cabina{' '}
                    </span>
                  )}

                {!(selectedSegment.vuelta.item.baggage.total === 0) && (
                  <span>
                    <CarryOn /> Incluye equipaje para despachar
                  </span>
                )}
                {((selectedSegment.vuelta.item.baggage.total === 0 &&
                  excludeAirlineBaggage.includes(
                    selectedSegment.vuelta.item.airLines[0].code
                  )) && selectedSegment.vuelta.item.baggage.total === 0) && (
                 <span>
                    <Mochila /> Incluye equipaje personal
                 </span>
                )}
              </Column>
            </>
          )}

          {localPrice && !hideDesgloce && (
            <>
              <Divider />

              <BreakdownHolder>
                <BreakdownTitle>Desglose</BreakdownTitle>
                {localPrice.breakdown.map(item =>
                  item.label !== 'TOTAL' ? (
                    <BreakdownItem>
                      <span>{item.label}</span>
                      <span>
                        {item.currency} {formatMoney(item.price)}
                      </span>
                    </BreakdownItem>
                  ) : (
                    <>
                      <Divider />
                      <BreakdownTotal>
                        <span>TOTAL</span>
                        <span>
                          {item.currency} {formatMoney(item.price)}
                        </span>
                      </BreakdownTotal>
                    </>
                  )
                )}
                {/* TODO: queda asi, no se si o van a volver a querer por ahora es pa lio y mucha charla. mejor q se vaya ¯\_(ツ)_/¯  */}
                {/* <BreakdownItau
                  style={{
                    width: '100%',
                    justifyContent: 'center',
                    display: 'flex',
                    gap: 4
                  }}>
                  <span>ó</span> {pointsToRender} puntos. <Itau /> + PYG{' '}
                  {moneyToComplete.toLocaleString('en').replaceAll(',', '.')}
                </BreakdownItau> */}
              </BreakdownHolder>
            </>
          )}
        </SideContent>
      </ContentCard>
    </div>
  );
};
