import React, { useEffect } from 'react';
import styled from 'styled-components';
import { useSelector, useDispatch, shallowEqual } from 'react-redux';

import HotelSearchDialog from 'Features/hotels/components/HotelSearchDialog';
import HotelAvailability from 'Features/hotels/components/HotelAvailability';
import BannersContainer from 'Features/banners/components/BannersContainer';
import HotelError from 'Features/hotels/components/HotelError';
import { statusCodes, viewingCodes } from 'Features/hotels/constants';
import HotelPropertyPage from 'Features/hotels/components/HotelPropertyPage';
import { handleUrlChange } from 'Features/hotels/components/HotelsHome/helpers';
import SkyLoader from 'Components/SkyLoader';

import useBreakpoints from 'Utils/hooks/useBreakpoints';
import { breakpoints } from 'Constants';

import SearchEngineWrapper from 'Components/SearchEngineWrapper';
import { ItauBanner } from 'Components/ItauBanner';
import { ItauPuntosLogin } from 'Components/ItauPuntosLogin';
import { BannerTarjetas } from 'Components/BannerTarjetas';
import MoveDownArrow from 'Components/MoveDownArrow';

import { mostWantedPackagesRequest } from '../../../common/actions';
import { PackageCommonList } from '../../../packages/components/PackageCommonList';
import { PromoBanners } from 'Components/PromoBanners';

const selector = ({ hotels, router, common }) => ({
  error: hotels.error,
  detailMode: hotels.search.detailMode,
  status: hotels.status,
  location: router.location,
  viewing: hotels.viewing,
  mostWantedPackages: common.mostWantedPackages,
  statusReadyPackages: common.status
});

const messages = [
  'Con tu tarjeta Itaú, ¡aprovechá las cuotas sin intereses y planificá tu viaje!', 
  'Con tus puntos Itaú, estás más cerca de un nuevo destino.',
  'Comprá desde donde estés y empezá a explorar el mundo.',
  'Tienda Naranja le da un nuevo color a tus viajes.'
];

const HotelSearch = () => {
  const dispatch = useDispatch();
  const mediumDown = useBreakpoints().mediumDown;
  const {
    error,
    status,
    location,
    viewing,
    mostWantedPackages,
    statusReadyPackages
  } = useSelector(selector, shallowEqual);
  useEffect(() => {
    handleUrlChange(location, dispatch);
  }, [location, dispatch]);

  useEffect(() => {
    dispatch(mostWantedPackagesRequest());
  }, []);

  const unused = status === statusCodes.UNUSED;
  const loading = status === statusCodes.LOADING;

  return (
    <>
      <Container>
        {unused && (
          <TitleHolder>
            <Title>
              Busquemos un lindo
              <br /> alojamiento
            </Title>
          </TitleHolder>
        )}
      </Container>
      {error && <HotelError />}
      <SearchEngineWrapper
        unused={unused || error !== null}
        isMobile={mediumDown}>
        <HotelSearchDialog />
        {/* {unused && (
          <MoveDownArrow name="mv-arrow-down" size={38} color="white" />
        )} */}
      </SearchEngineWrapper>
      {loading && <SkyLoader messages={messages} />}
      {!error && viewing === viewingCodes.AVAILABILITY && <HotelAvailability />}
      {!error && viewing === viewingCodes.PROPERTY && <HotelPropertyPage />}
      {/* {unused && <PaymentPromoBanner />} */}

      {unused && <ItauBanner loadBannerHome/>}
      {unused && <ItauPuntosLogin />}
      {statusReadyPackages === 'ready' &&
        mostWantedPackages.length > 0 &&
        unused && (
          <PackageCommonList
            packageList={mostWantedPackages}
            dividerTitle={'Los paquetes más pedidos por nuestros viajeros:'}
          />
        )}
      {unused && <PromoBanners />}
      {unused && <BannersContainer />}
      {unused && <BannerTarjetas />}
    </>
  );
};

HotelSearch.propTypes = {};

export default HotelSearch;

const Title = styled.div`
  margin-top: 175px;
  width: 634px;
  font-family: 'Itau Display Pro';
  font-style: normal;
  font-weight: 700;
  font-size: 60px;
  line-height: 68px;
  /* or 113% */

  display: flex;
  align-items: center;
  color: #ffffff;

  text-shadow: 4px 4px 8px rgba(0, 0, 0, 0.8);
`;

const Container = styled.div`
  max-width: 1100px;
  width: 100%;
  margin: auto;
  button {
    margin: 0;
  }
`;

const TitleHolder = styled.div`
  @media screen and (max-width: ${breakpoints.medium}px) {
    display: none;
  }
`;
