import React, { useEffect, useState } from 'react';
import styled from 'styled-components';

import { ReactComponent as FlechaArriba } from './FlechaArriba.svg';
import { ReactComponent as Itau } from './itauMobile.svg';

import { Steps } from '../../types';
import { Stepper } from '../Stepper';
import { Title } from 'Containers/AirCheckout/styles';
import { PaxFields } from '../PaxFields';
import { BillingFields } from '../BillingFields';
import { PaymentStep } from '../PaymentStep';
import { useDispatch, useSelector } from 'react-redux';
import { useFormContext } from 'react-hook-form';
import {
  ButtonsHolder,
  Container,
  DetailItau,
  DetailLocal,
  DetailPerse,
  DetailsHolder,
  DetalleButton,
  LocalRow1,
  LocalRow2,
  LocalRow3,
  NavButton,
  OrangeHeader,
  StickyBottom
} from './styles';
import { SideDetails } from '../SideDetails';
import { updateItauPoints } from 'Features/app/actions';

interface Props {
  activeStep: Steps;
  fields: Record<'id', string>[];
  onRequest: ({
    selectedInterventajasPoints,
    selectedMembershipPoints,
    moneyToComplete,
    pointsToRender
  }) => void;
  onPreviousStep: () => void;
  onNextStep: () => void;
  selectedSegment: any;
  localPrice: any;
  flightPrice: any;
}

const getPointsToBeConsumed = ({
  priceInPoints,
  selectedMembershipPoints,
  selectedInterventajasPoints
}) => {
  let toRenderMembership = 0;
  let toRenderInterventajas = 0;
  if (priceInPoints > selectedMembershipPoints) {
    toRenderMembership = selectedMembershipPoints;
  } else if (priceInPoints <= selectedMembershipPoints) {
    toRenderMembership = priceInPoints;
    return {
      toRenderMembership,
      toRenderInterventajas
    };
  }
  const remainingPoints = priceInPoints - toRenderMembership;

  if (remainingPoints > selectedInterventajasPoints) {
    toRenderInterventajas = selectedInterventajasPoints;
  } else {
    toRenderInterventajas = remainingPoints;
  }

  return {
    toRenderMembership,
    toRenderInterventajas
  };
};

export const CheckoutMobile = ({
  activeStep,
  fields,
  onRequest,
  onNextStep,
  onPreviousStep,
  selectedSegment,
  flightPrice,
  localPrice
}: Props) => {

  const [selectedMembershipPoints, setSelectedMembershipPoints] = useState(0);
  const [selectedInterventajasPoints, setSelectedInterventajasPoints] = useState(0);
  const dispatch = useDispatch();

  const {
    pointsClient,
    itauPoints: { PYG }
  } = useSelector(
    state => ({
      // @ts-ignore
      itauPoints: state.appConfiguration.itauPoints,
      // @ts-ignore
      pointsClient: state.itau.pointsClient
    })
  );

  const [showDetalle, setShowDetalle] = useState(false);
  const toggleShowDetalle = () => setShowDetalle(prevState => !prevState);

  const selectedItauPoints =
    selectedInterventajasPoints + selectedMembershipPoints;
  const costCurrencyInPoints = PYG;
  const priceInPoints = Math.ceil(localPrice.totalPrice / costCurrencyInPoints);
  const moneyInPoints = selectedItauPoints * costCurrencyInPoints;
  const moneyToComplete = Math.ceil((localPrice.totalPrice - moneyInPoints) * 100) / 100;

  const { toRenderMembership, toRenderInterventajas } = getPointsToBeConsumed({
    priceInPoints,
    selectedMembershipPoints,
    selectedInterventajasPoints
  });
  const totalPoints = toRenderInterventajas + toRenderMembership;

  const { setValue } = useFormContext();

  useEffect(() => {
    for (let i = 0; i < fields.length; i++) {
      setValue(`passengers.${i}.docType`, 1);
      setValue(`passengers.${i}.nationality`, 'PRY');
    }
  }, [setValue, fields]);

  useEffect(() => {
    if (pointsClient && pointsClient.length < 1) {
      dispatch(updateItauPoints({ points: 0 }));
    }
  }, [dispatch, pointsClient])



  return (
    <Container>
      <OrangeHeader>COMPLETÁ TUS DATOS Y FINALIZÁ TU COMPRA</OrangeHeader>
      <Stepper activeStep={activeStep} />

      {activeStep === Steps.paxData && (
        <>
          <Title>Pasajeros</Title>
          {fields.map((field, index) => (
            <PaxFields key={field.id} index={index} />
          ))}

          {/* <PaxFields title="Joven 1" /> */}
        </>
      )}

      {activeStep === Steps.billingData && (
        <>
          <Title>Datos de facturación</Title>
          <BillingFields />
        </>
      )}

      {activeStep === Steps.payment && <PaymentStep onRequest={onRequest} selectedInterventajasPoints={selectedInterventajasPoints} setSelectedInterventajasPoints={setSelectedInterventajasPoints}
        selectedMembershipPoints={selectedMembershipPoints} setSelectedMembershipPoints={setSelectedMembershipPoints} />}

      <StickyBottom>
        <DetalleButton onClick={toggleShowDetalle}>
          Detalle <FlechaArriba />
        </DetalleButton>

        <DetailPerse show={showDetalle}>
          <SideDetails
            isCheckoutMobile
            selectedSegment={selectedSegment}
            localPrice={localPrice}
          />
        </DetailPerse>

        <DetailsHolder>
          <DetailLocal>
            <LocalRow1>Precio total final</LocalRow1>
            <LocalRow2>
              {flightPrice.currency} {flightPrice.totalPrice}
            </LocalRow2>
            <LocalRow3>
              ({localPrice.currency} {localPrice.totalPrice})
            </LocalRow3>
          </DetailLocal>

          <DetailItau>
            <div>
              ó <span>{totalPoints}</span> puntos <Itau />
            </div>
            <div>
              +{' '}
              <span>
                {localPrice.currency} {' '}
                {moneyToComplete < 0
                  ? 0
                  : moneyToComplete.toLocaleString('en').replaceAll(',', '.')}
              </span>
            </div>
          </DetailItau>
        </DetailsHolder>

        <ButtonsHolder>
          {activeStep !== Steps.paxData ? (
            <NavButton onClick={onPreviousStep}>Atrás</NavButton>
          ) : (
            <div />
          )}

          {activeStep !== Steps.payment ? (
            <NavButton onClick={onNextStep}>Siguiente</NavButton>
          ) : (
            <div />
          )}
        </ButtonsHolder>
      </StickyBottom>
    </Container>
  );
};
