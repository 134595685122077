/**
 *
 * AirAvailabilityCard
 *
 */

import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { useSelector } from 'react-redux';

import { getFormatPrice } from 'Utils';

import ItauLogo from 'Assets/logo_naranja.svg';

import { ItineraryDetails } from './itinerary-details';
import { AirSegment } from './segment';

const initialMultiDestinationDisplayDetail = length => {
  const toReturn = [];
  for (var i = 0; i < length; i++) {
    toReturn.push(false);
  }
  return toReturn;
};

const AirAvailabilityCard = ({
  generalSegment,
  flightPrice,
  onClick,
  id,
  toggleShowModal,
  flightObject,
  disabledDetails = false
  // hasFlex
}) => {
  const [showDetails, setShowDetails] = useState(false);
  const isMultiDestination = generalSegment.length > 2;
  const ida = generalSegment[0];
  const vuelta = generalSegment[1] ? generalSegment[1] : false;
  const newIda = ida
    ? ida.map(item => {
        const toReturn = { ...item, displayDetails: false };
        return toReturn;
      })
    : false;
  const newVuelta = vuelta
    ? vuelta.map(item => ({ ...item, displayDetails: false }))
    : false;
  const [showDesglose, setShowDesglose] = useState(false);
  const [componentState, setComponentState] = useState({
    stateIda: newIda,
    stateVuelta: newVuelta,
    selectedIda: { index: 0 },
    selectedVuelta: { index: 0 },
    multiDestinationDisplayDetail: initialMultiDestinationDisplayDetail(
      generalSegment[0].length
    ),
    multiDestination: isMultiDestination
  });

  const toggleDetails = () =>
    !disabledDetails && setShowDetails(prevState => !prevState);

  useEffect(() => {
    const effectIda = generalSegment[0];
    const effectVuelta = generalSegment[1] ? generalSegment[1] : false;
    const effectNewIda = effectIda
      ? effectIda.map(item => ({ ...item, displayDetails: false }))
      : false;
    const effectNewVuelta = effectVuelta
      ? effectVuelta.map(item => ({ ...item, displayDetails: false }))
      : false;
    setComponentState({
      ...componentState,
      stateIda: effectNewIda,
      stateVuelta: effectNewVuelta,
      selectedIda: { index: 0, item: effectIda[0] },
      selectedVuelta: { index: 0, item: effectVuelta[0] }
    });
  }, [generalSegment]);

  const handleSelect = () => {
    if (toggleShowModal) {
      toggleShowModal();
    }
    toggleDetails();
    if (onClick) {
      onClick(
        {
          ida: componentState.selectedIda,
          vuelta: componentState.selectedVuelta
        },
        id,
        flightPrice,
        flightObject
      );
    }
  };

  const {
    selectedItauPoints,
    itauPoints: { USD }
  } = useSelector(state => state.appConfiguration);

  const costCurrencyInPoints = USD;
  const priceInPoints = Math.ceil(
    flightPrice.totalPrice / costCurrencyInPoints
  );
  const moneyInPoints = selectedItauPoints * costCurrencyInPoints;
  const moneyToComplete =
    Math.ceil((flightPrice.totalPrice - moneyInPoints) * 100) / 100;
  const pointsToRender =
    selectedItauPoints <= priceInPoints
      ? selectedItauPoints.toLocaleString('en').replaceAll(',', '.')
      : priceInPoints.toLocaleString('en').replaceAll(',', '.');

  return (
    <CardHolder onClick={toggleDetails} notCursorPointer={disabledDetails}>
      <SegmentsHolder>
        {generalSegment.map(segment => (
          <AirSegment segment={segment} />
        ))}
      </SegmentsHolder>
      <PriceBox>
        <PriceText>
          {' '}
          {getFormatPrice(
            flightPrice.totalPrice,
            flightPrice.breakdown[0].currency
              ? flightPrice.breakdown[0].currency
              : 'USD'
          )}
        </PriceText>
        <BlueText>
          ó <span>{pointsToRender}</span> puntos <img src={ItauLogo} style={{width: 23}} />
          <br />+ <span>USD {moneyToComplete < 0 ? 0 : moneyToComplete}</span>
        </BlueText>
      </PriceBox>
      {showDetails && (
        <ItineraryDetails
          onClose={toggleDetails}
          generalSegment={generalSegment}
          onBuy={handleSelect}
        />
      )}
    </CardHolder>
  );
};

const SegmentsHolder = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: stretch;
  justify-content: stretch;
`;

const PriceBox = styled.div`
  min-width: 136px;
  max-width: 178px;
  min-height: 121px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 17px 37px;
  gap: 15px;
  border-left: 1px solid rgba(40, 40, 40, 0.1);
`;

const BlueText = styled.div`
  font-family: 'Itau Display Pro';
  font-style: normal;
  font-weight: 300;
  font-size: 14px;
  line-height: 22px;
  color: #003399;
  text-align: center;

  span {
    font-weight: 700;
  }

  svg {
    width: 24px;
    height: 24px;
  }
`;

const PriceText = styled.div`
  font-family: 'Itau Display Pro';
  font-style: normal;
  font-weight: 400;
  font-size: 22px;
  line-height: 30px;
  text-align: center;
  color: #282828;
`;

const CardHolder = styled.div`
  display: flex;
  cursor: ${props => (props.notCursorPointer ? 'auto' : 'pointer')};
  box-shadow: 0;
  flex-direction: row;
  align-items: stretch;
  padding: 0;
  max-width: 928px;
  width: 100%;
  min-height: 141px;
  background: #ffffff;
  border: 1px solid rgba(40, 40, 40, 0.1);
  border-radius: 10px;
  justify-content: space-around;
  transition: box-shadow 0.4s;
  &:hover {
    box-shadow: 3px 12px 55px -43px rgba(0, 0, 0, 0.75);
  }
`;

AirAvailabilityCard.propTypes = {
  className: PropTypes.string.isRequired,
  generalSegment: PropTypes.array,
  flightPrice: PropTypes.object,
  localPrice: PropTypes.object,
  endDate: PropTypes.string,
  initialDate: PropTypes.string,
  routesData: PropTypes.any,
  onClick: PropTypes.func,
  pending: PropTypes.bool,
  mpcView: PropTypes.bool,
  isQuotation: PropTypes.bool,
  showAllFlights: PropTypes.func,
  id: PropTypes.string,
  toggleShowModal: PropTypes.func,
  flightObject: PropTypes.object,
  readOnlySelectedSegmets: PropTypes.any,
  hasFlex: PropTypes.bool
};

export default AirAvailabilityCard;
