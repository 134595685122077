/**** PaymentPromoBanner**/

import React from 'react';
import { PaymentPromoPy } from 'Components/PaymentPromoPy';
import { PaymentPromoUy } from 'Components/PaymentPromoUy';

export const PaymentPromoBanner = () => {
  if (process.env.REACT_APP_COUNTRY === 'PY') {
    return (
      //PARAGUAY PY
      <PaymentPromoPy />
    );
  }
  if (process.env.REACT_APP_COUNTRY === 'UY') {
    return (
      //URUGUAY UY
      <PaymentPromoUy />
    );
  }
  return null;
};
