/**
 *
 * MobileMpcAvailability
 *
 */

import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import cs from 'classnames';
import { useSelector } from 'react-redux';
import { important, white, golf } from 'Constants/colors';
import MobileMPCItem from '../MobileMpcItem';
import { dateForMpc } from 'Utils/returnDate';
import Modal from 'Components/Modal';

// import ArrowSelector from 'Components/ArrowSelector';
import MobileMpcFlightDetails from '../MobileMpcFlightDetails';
import { MobileAirItineraryDetails } from '../MobileAirItineraryDetails';
import AirCheckError from '../AirCheckError';
// import MpcLegend from '../MpcLegend';
import { mpcItemStatus } from 'Features/flights/constants';
import MpcItemResearch from 'Features/flights/components/MpcItemResearch';

import { ReactComponent as Left } from './left.svg';
import { ReactComponent as Right } from './right.svg';

const Container = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0;
  width: 146px;
  height: 46px;
  justify-content: space-between;
  text-transform: uppercase;
`;

const LabelText = styled.div`
  font-family: 'Itau Display Pro';
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 22px;
  color: #000000;
`;

const BtnRoundWhite = styled.div`
  width: 24px;
  height: 24px;
  cursor: pointer;
  background: #ffffff;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.25);
  border-radius: 50px;
  transform: matrix(-1, 0, 0, 1, 0, 0);
  display: flex;
  align-items: center;
  justify-content: center;
`;

const DatesNavigator = ({ label, onClickLeft, onClickRight }) => {
  return (
    <Container>
      <BtnRoundWhite onClick={onClickLeft}>
        <Left />
      </BtnRoundWhite>
      <LabelText>{label}</LabelText>
      <BtnRoundWhite onClick={onClickRight}>
        <Right />
      </BtnRoundWhite>
    </Container>
  );
};

const MobileMpcAvailability = ({
  className,
  canDecreaseInitialDate,
  canSubstract,
  handleArrowButton,
  departureDates,
  arrivalDates,
  detail,
  minPrice,
  middlePrice,
  maxPrice,
  handleShowAllFlights,
  modalData,
  showModal,
  toggleShowModal,
  handleMPCItemClick,
  arrowKeys,
  leftArrowContent,
  rightArrowContent,
  showErrorModal,
  toggleErrorModal
}) => {
  const statusByPrice = { minPrice, middlePrice, maxPrice };

  const {
    selectedItauPoints,
    itauPoints: { USD }
  } = useSelector(state => state.appConfiguration);

  const precio = modalData ? modalData.flightPrice.totalForOneAdult : 0;

  const costCurrencyInPoints = USD;
  const priceInPoints = Math.ceil(precio / costCurrencyInPoints);
  const moneyInPoints = selectedItauPoints / costCurrencyInPoints;
  const moneyToComplete = Math.ceil((precio - moneyInPoints) * 100) / 100;
  const pointsToRender =
    selectedItauPoints <= priceInPoints
      ? selectedItauPoints.toLocaleString('en').replaceAll(',', '.')
      : priceInPoints.toLocaleString('en').replaceAll(',', '.');

  return (
    <>
      <div className={cs(className, 'container')}>
        <div className="arrow-selectors">
          <DatesNavigator
            label="ida"
            onClickLeft={
              !(!canDecreaseInitialDate() && !canSubstract()) &&
              handleArrowButton(arrowKeys.left)
            }
            onClickRight={
              !(arrivalDates && !canSubstract()) &&
              handleArrowButton(arrowKeys.right)
            }
          />
          {/* <ArrowSelector
            disabledLeft={!canDecreaseInitialDate() && !canSubstract()}
            disabledRight={arrivalDates && !canSubstract()}
            content={leftArrowContent}
            onClickLeft={handleArrowButton(arrowKeys.left)}
            onClickRight={handleArrowButton(arrowKeys.right)}
          /> */}
          {arrivalDates && (
            <DatesNavigator
              label="vuelta"
              onClickLeft={!!canSubstract() && handleArrowButton(arrowKeys.up)}
              onClickRight={handleArrowButton(arrowKeys.down)}
            />
            // <ArrowSelector
            //   disabledLeft={!canSubstract()}
            //   content={rightArrowContent}
            //   onClickLeft={handleArrowButton(arrowKeys.up)}
            //   onClickRight={handleArrowButton(arrowKeys.down)}
            // />
          )}
        </div>
        <div className="calendar-holder">
          <div className="departure-dates">
            {/* DEPARTURE DATES ROW */}
            {arrivalDates && <div className="empty-box"></div>}
            {departureDates.map((departureDate, i) => {
              const [day, date] = dateForMpc(departureDate).split(' ');
              return (
                <div
                  key={departureDate}
                  className={cs('departure-date-item', { isDay: i === 3 })}>
                  {day}
                  <br />
                  {date}
                </div>
              );
            })}
            {/* END DEPARTURE DATES ROW */}
          </div>

          <div className="dates-holder">
            {/* RETURN DATES COLUNN*/}
            <>
              {arrivalDates && (
                <div className="return-dates">
                  {arrivalDates.map((arrivalDate, i) => {
                    const [day, date] = dateForMpc(arrivalDate).split(' ');
                    return (
                      <div
                        key={arrivalDate}
                        className={cs('return-date-item', { isDay: i === 3 })}>
                        {day}
                        <br />
                        {date}
                      </div>
                    );
                  })}
                </div>
              )}
            </>
            {/* END RETURN DATES COLUMN */}
            <div className="rows-holder">
              {detail &&
                detail.map((detailRow, idxRow) => (
                  <div key={idxRow} className="mpc-row">
                    {detailRow.map((detailItem, idxCol) =>
                      detailItem.flightPrice && !detailItem.research ? (
                        <MobileMPCItem
                          key={idxCol}
                          status={statusByPrice}
                          currency="USD"
                          price={detailItem.flightPrice.totalForOneAdult}
                          description={`Por ${detailItem.flightPrice.totalPassenger} pasajeros`}
                          totalPrice={`USD ${detailItem.flightPrice.totalPrice}`}
                          onClick={handleMPCItemClick(detailItem)}
                          isCenter={detailItem.center}
                        />
                      ) : detailItem.research && !detailItem.flightPrice ? (
                        <MpcItemResearch
                          key={idxCol}
                          detailItem={detailItem}
                          row={idxRow}
                          col={idxCol}
                        />
                      ) : (
                        <MobileMPCItem
                          empty
                          key={idxCol}
                          status={mpcItemStatus.EMPTY}
                        />
                      )
                    )}
                  </div>
                ))}
            </div>
          </div>
        </div>
      </div>
      {modalData && showModal && (
        <MobileAirItineraryDetails
          onClose={toggleShowModal}
          generalSegment={modalData.generalSegment}
          pointsToRender={pointsToRender}
          moneyToComplete={moneyToComplete}
          localPrice={modalData.localPrice.totalForOneAdult}
          localCurrency={modalData.localPrice.currency}
          price={modalData.flightPrice.totalForOneAdult}
          currency={
            modalData.flightPrice.breakdown[0].currency
              ? modalData.flightPrice.breakdown[0].currency
              : 'USD'
          }
          //onBuy={modalData.onClick}
          onBuy={() => {
            const ida = modalData.generalSegment[0];
            const vuelta = modalData.generalSegment[1]
              ? modalData.generalSegment[1]
              : false;
            modalData.onClick({
              ida: { index: 0, item: ida[0] },
              vuelta: { index: 0, item: vuelta[0] }
            });
          }}
        />
      )}
      <Modal
        show={showErrorModal}
        onClickOverlay={toggleErrorModal}
        transparent>
        <AirCheckError close={toggleErrorModal} mobile />
      </Modal>
    </>
  );
};

MobileMpcAvailability.propTypes = {
  className: PropTypes.string.isRequired,
  canDecreaseInitialDate: PropTypes.func.isRequired,
  canSubstract: PropTypes.func.isRequired,
  handleArrowButton: PropTypes.func.isRequired,
  departureDates: PropTypes.array.isRequired,
  arrivalDates: PropTypes.array.isRequired,
  handleShowAllFlights: PropTypes.func.isRequired,
  toggleShowModal: PropTypes.func,
  showModal: PropTypes.bool,
  modalData: PropTypes.object,
  handleMPCItemClick: PropTypes.func,
  arrowKeys: PropTypes.object,
  detail: PropTypes.array,
  minPrice: PropTypes.number,
  middlePrice: PropTypes.number,
  maxPrice: PropTypes.number,
  leftArrowContent: PropTypes.node,
  rightArrowContent: PropTypes.node,
  showErrorModal: PropTypes.bool,
  toggleErrorModal: PropTypes.func
};

export default styled(MobileMpcAvailability)`
  display: block;
  margin-bottom: 80px;

  .calendar-holder {
    width: 100%;
    border-radius: 16px;
    background-color: #eaeceb;
    overflow: hidden;

    .departure-dates {
      height: 41px;
      background-color: #faf2e3;
      display: flex;
    }

    .departure-date-item {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      width: 14%;
      font-family: 'Itau Display Pro';
      font-style: normal;
      font-weight: 400;
      font-size: 10px;
      line-height: 13px;
      text-align: center;
      color: #282828;

      &.isDay {
        background-color: #ffd4a5;
      }
    }

    .empty-box {
      width: 15%;
      height: 32px;
    }

    .return-dates {
      display: flex;
      flex-direction: column;
      height: 100%;
      width: 14%;
      background-color: #faf2e3;
    }

    .return-date-item {
      text-align: center;
      height: 48px;
      margin: 0;

      font-family: 'Itau Display Pro';
      font-style: normal;
      font-weight: 400;
      font-size: 10px;
      line-height: 13px;
      text-align: center;
      color: #282828;
      display: flex;
      justify-content: center;
      align-items: center;

      &.isDay {
        background-color: #ffd4a5;
      }
    }

    .rows-holder {
      width: 100%;
    }

    .dates-holder {
      display: flex;

      .mpc-row {
        display: flex;
      }
    }
  }

  .button-bar {
    margin-top: 10px;
    display: flex;
    justify-content: flex-end;

    .context {
      padding: 5px;
      margin: 5px;
    }

    .cheaper {
      border-radius: 4px;
      box-shadow: 2px 3px 12px 0 rgba(0, 0, 0, 0.25);
      border: solid 2px ${important};
      background-color: ${golf};
      font-weight: bold;
      color: ${important};
    }
  }

  .footer-info {
    margin-top: 10px;
    display: flex;
    align-items: center;
    color: ${white};

    .icon {
      margin-right: 5px;
    }
  }

  .arrow-selectors {
    margin-top: 10px;
    margin-bottom: 10px;
    display: flex;
    justify-content: space-around;
  }
`;
