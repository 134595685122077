/**
 *
 * ConnectedMpcMptbAvailability
 *
 */

import React from 'react';
import { useSelector } from 'react-redux';
import MpcMptbAvailability from './MpcMptbAvailability';

export const checkError = flights =>
  !flights.mpcAvailability.loading &&
  !flights.mptbAvailability.loading &&
  flights.mpcAvailability.data.hasOwnProperty('response') &&
  flights.mpcAvailability.data.response.hasOwnProperty('detail') &&
  flights.mpcAvailability.data.response.detail.length === 0 &&
  flights.mptbAvailability.data.hasOwnProperty('response') &&
  flights.mptbAvailability.data.response.length === 0;

export const checkHasMPCData = flights => {
  const hasMPCData =
    !flights.mpcAvailability.loading &&
    flights.mpcAvailability.data.hasOwnProperty('response') &&
    flights.mpcAvailability.data.response.hasOwnProperty('detail') &&
    flights.mpcAvailability.data.response.detail.length > 0;

  return hasMPCData;
};

export const checkHasMPTBData = flights => {
  const hasMPTBData =
    !flights.mptbAvailability.loading &&
    flights.mptbAvailability.data.hasOwnProperty('response') &&
    flights.mptbAvailability.data.response.length > 0;

  return hasMPTBData;
};

const selectorMpcMptbAvailability = ({ flights }) => ({
  hasMPCData: checkHasMPCData(flights),
  hasMPTBData: checkHasMPTBData(flights),
  hasError: checkError(flights),
  mpcAvailability: flights.mpcAvailability,
  mptbAvailability: flights.mptbAvailability,
  routesData: flights.mptbAvailability.data.request
    ? flights.mptbAvailability.data.request.routesData
    : flights.availability.data.request.routesData,
  messagesError: `¡Ups! no hemos encontrado vuelos a ${flights.search.routesData[0].destinationName}`
});

const ConnectedMpcMptbAvailability = ({ ...props }) => {
  const state = useSelector(selectorMpcMptbAvailability);

  return <MpcMptbAvailability {...state} {...props} />;
};

export { MpcMptbAvailability };
export default ConnectedMpcMptbAvailability;
