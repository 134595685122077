/**
 *
 * HotelsGoogleComments
 *
 */

import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import cs from 'classnames';
// import * as COLORS from 'Constants/colors';

import ReviewHeader from 'Components/ReviewHeader';
import CardReview from 'Components/CardReview';

const HotelsGoogleComments = ({ className, reviews }) => {
  const listComents =
    reviews &&
    reviews.results.map(comment => (
      <CardReview
        className="comment"
        key={comment.id}
        rating={comment.rating}
        ratingText={comment.ratingText}
        author={comment.author_name}
        created={comment.relative_time_description}
        reviews={comment.text}
      />
    ));

  return reviews ? (
    <div className={cs(className)}>
      <ReviewHeader
        rating={reviews.rating}
        ratingText={reviews.ratingText}
        amountReviews={reviews.amountReviews}
      />
      {listComents}
    </div>
  ) : null;
};

HotelsGoogleComments.propTypes = {
  className: PropTypes.string,
  reviews: PropTypes.shape({
    amountReviews: PropTypes.number,
    rating: PropTypes.number,
    ratingText: PropTypes.string,
    results: PropTypes.arrayOf(
      PropTypes.shape({
        author_name: PropTypes.string,
        author_url: PropTypes.string,
        id: PropTypes.string,
        language: PropTypes.string,
        profile_photo_url: PropTypes.string,
        rating: PropTypes.number,
        ratingText: PropTypes.string,
        relative_time_description: PropTypes.string,
        text: PropTypes.string,
        time: PropTypes.number
      })
    )
  }).isRequired
};

export default styled(HotelsGoogleComments)`
  .comment {
    margin: 20px auto;
  }
`;
