/**
 *
 * FlightSearchCompact
 *
 */

import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { textTruncate } from '../../../../Utils';
import returnDate from '../../../../Utils/returnDate';
import { airSearchTypes } from 'Constants';

import { ReactComponent as Avion } from './avion.svg';
import { ReactComponent as Calendar } from './calendar.svg';
import { ReactComponent as Pax } from './pax.svg';
import { ReactComponent as Edit } from './edit.svg';

const FlightSearchCompact = ({
  originName,
  destinationName,
  initialDate,
  endDate,
  adults,
  kids,
  type,
  actions
}) => {
  const passengers = adults + kids.length;

  return (
    <FlexColumn>
      <Holder>
        <span>{textTruncate(originName, 30)}</span>
        <Avion />
        <span>{textTruncate(destinationName, 30)}</span>
        <Divider />
        {type === airSearchTypes.roundTrip ? (
          <CalendarHolder>
            <Calendar />
            <CapitalizeFisrt>{returnDate(initialDate)}</CapitalizeFisrt> -{' '}
            <CapitalizeFisrt>{returnDate(endDate)}</CapitalizeFisrt>
          </CalendarHolder>
        ) : (
          <CalendarHolder>
            {' '}
            <Calendar />{' '}
            <CapitalizeFisrt>{returnDate(initialDate)}</CapitalizeFisrt>
          </CalendarHolder>
        )}
        <Divider />
        <CalendarHolder>
          <Pax /> {passengers}
        </CalendarHolder>
      </Holder>
      <Modificar onClick={actions.setModify}>
        <Edit /> <span>Modificar</span>
      </Modificar>
    </FlexColumn>
  );
};

FlightSearchCompact.propTypes = {
  originName: PropTypes.string.isRequired,
  destinationName: PropTypes.string.isRequired,
  initialDate: PropTypes.string.isRequired,
  endDate: PropTypes.string.isRequired,
  adults: PropTypes.number.isRequired,
  kids: PropTypes.arrayOf(
    PropTypes.shape({
      age: PropTypes.number,
      seat: PropTypes.bool
    })
  ),
  actions: PropTypes.shape({
    setModify: PropTypes.func.isRequired
  }),
  type: PropTypes.string.isRequired
};

const Modificar = styled.div`
  cursor: pointer;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 10px 16px;
  background: #fb8400;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.25);
  border-radius: 50px;
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  color: #ffffff;
  gap: 9px;
`;

const FlexColumn = styled.div`
  display: flex;
  flex-direction: column;
  margin: 50px 0;
  gap: 10px;
  align-items: center;
`;

const CalendarHolder = styled.div`
  display: flex;
  gap: 11px;
`;

const CapitalizeFisrt = styled.span`
  &::first-letter {
    text-transform: capitalize;
  }
`;

const Holder = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 4px 16px;
  gap: 16px;
  width: 831px;
  height: 48px;
  background: #ffffff;
  box-shadow: 2px 2px 6px rgba(0, 0, 0, 0.25);
  border-radius: 14px;
  font-family: 'Itau Display Pro';
  font-style: normal;
  font-weight: 300;
  font-size: 16px;
  line-height: 24px;
  color: #282828;
`;

const Divider = styled.div`
  width: 2px;
  height: 25px;
  background: #c4c4c4;
`;

export default FlightSearchCompact;
