/**
 *
 * MobileFlightSearchCompact
 *
 */

import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import MobileFlightSearchCompact from './MobileFlightSearchCompact';
import { setSearchDialogDetailMode } from 'Features/flights/actions';

const selector = ({ flights }) => ({
  origin: flights.search.routesData[0].originName,
  destination: flights.search.routesData[0].destinationName,
  initialDate: flights.search.routesData[0].initialDate,
  endDate: flights.search.routesData[0].endDate,
  adults: flights.search.adults,
  kids: flights.search.children,
  type: flights.search.type
});

const ConnectedMobileFlightSearchCompact = props => {
  const dispatch = useDispatch();
  const actions = {
    setModify: () => {
      dispatch(setSearchDialogDetailMode(false));
    }
  };
  const state = useSelector(selector);

  return <MobileFlightSearchCompact {...props} {...state} actions={actions} />;
};

export default ConnectedMobileFlightSearchCompact;
export { MobileFlightSearchCompact };
