/**
 *
 * Tag
 *
 */

import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import * as COLORS from 'Constants/colors';

import Icon from '../Icon';

const Tag = ({ children, onClick, color = COLORS.violeta }) => (
  <Container color={color}>
    <TagText>{children}</TagText>
    {onClick && (
      <>
        <div className="gap" />
        <Icon
          name="mv-cross"
          color="white"
          size={12}
          onClick={onClick}
          pointer
        />
      </>
    )}
  </Container>
);

Tag.propTypes = {
  children: PropTypes.string,
  onClick: PropTypes.func,
  color: PropTypes.string
};

const Container = styled.div`
  display: inline-flex;
  background-color: #fb8400;

  align-items: center;
  margin: 8px 6px;
  height: 28px;
  padding: 5px 8px 5px 8px;
  border-radius: 30px;
  gap: 5px;
`;

export const TagText = styled.div`
  font-family: 'Itau Display Pro';
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 18px;
  color: #ffffff;
`;

export default Tag;
