/**
 *
 * HotelServices
 *
 */

import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import cs from 'classnames';
import Text from 'Components/Text';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import Slider from 'react-slick';
import HotelServicesList from '../HotelServicesList';
import SectionTitle from 'Components/SectionTitle';
import Icon from 'Components/Icon';
import { white, darkGrey } from 'Constants/colors';

const HotelServices = ({
  className,
  name,
  images,
  facilitiesGroup,
  close,
  mobile
}) => (
  <div className={cs(className, { mobile })}>
    <div className="padding">
      <div className="title">
        <Text size="xlarge" weight="semi-bold">
          Servicios de {name}
        </Text>
        <div className="close-icon" onClick={close}>
          <Icon name="mv-cross" size={24} />
        </div>
      </div>
      {images ? (
        <Slider {...sliderSettings}>
          {images.map((imageItem, index) => (
            <img
              key={index}
              className="image-item"
              alt={imageItem.description}
              src={imageItem.imageUrl}
            />
          ))}
        </Slider>
      ) : null}
    </div>
    {facilitiesGroup
      ? facilitiesGroup.map((group, index) => (
          <div key={index + group.title}>
            <SectionTitle title={group.title} />
            <HotelServicesList facilities={group.facilities} />
          </div>
        ))
      : null}
  </div>
);

const sliderSettings = {
  dots: false,
  infiite: true,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
  lazyLoad: true
};

HotelServices.propTypes = {
  className: PropTypes.string.isRequired,
  onClickOverlay: PropTypes.func,
  name: PropTypes.string.isRequired,
  images: PropTypes.array,
  facilitiesGroup: PropTypes.array,
  close: PropTypes.func,
  mobile: PropTypes.bool
};

export default styled(HotelServices)`
  background-color: ${white};
  border-radius: 15px;

  &.mobile {
    width: 100%;
    border-radius: 0;
  }

  .image-item {
    width: 762px;
    height: 443px;
    object-fit: cover;
    border-radius: 15px;
    margin-bottom: 15px;
  }

  .padding {
    padding: 12px;
  }

  .title {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .close-icon {
      color: ${darkGrey};
      cursor: pointer;
    }
  }
`;
