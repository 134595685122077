import Text from "Components/Text";
import { arena, blue, darkGrey, important, information, newBackground, positive, secondary, violeta, white } from "Constants/colors";
import React from "react"

export const BaseAndCondition = () => {
    return (
        <div style={{ margin: '10px', paddingRight: '14px' }}>
            <Text><strong>Promoción “Campaña puntos x2” – Tienda Naranja Viajes</strong></Text>

            <ol style={{
                paddingTop: '12px',
                display: 'flex',
                flexDirection: 'column',
                gap: '8px'
            }}>
                <li>
                    <Text paragraph alignment="justify">
                        <strong>ORGANIZADOR</strong>. Pont S.A., en adelante el <strong>ORGANIZADOR</strong>, con RUC N° 80131394, domicilio situado en la calle Santa Teresa casi Herminio Maldonado de la ciudad de Asunción, teléfono +595 981 802 088, realizará la promoción denominada “Lanzamiento”- Tienda Naranja Viajes, en adelante la <strong>PROMOCIÓN</strong>.
                    </Text>
                </li>
                <li>
                    <Text paragraph alignment="justify">
                        <strong>VIGENCIA</strong>. La PROMOCION tendrá vigencia desde el lunes 19 hasta el domingo 25 de agosto del 2024, y será válida para todo el territorio nacional.
                    </Text>
                </li>

                <li>
                    <Text paragraph alignment="justify">
                        <strong>PARTICIPANTES</strong> Participan de la promoción todas las personas físicas mayores de 18 años o jurídicas que cumplan los requisitos establecidos en estas bases y condiciones
                    </Text>
                </li>
                <li>
                    <Text paragraph alignment="justify">
                        <strong>CONDICIONES</strong> Serán válidas todas las compras que se realicen únicamente en la Plataforma Tienda Naranja Viajes (<a href="https://viajes.tiendanaranja.com.py" target="_blank">viajes.tiendanaranja.com.py</a>) en adelante, “el Sitio” y que cumplan las siguientes condiciones.
                    </Text>
                    <br />
                    <Text paragraph alignment="justify">
                        <ol type="a">
                            <li>
                                Todas las Compras hechas con Tarjetas de crédito Itaú hasta 15 cuotas sin intereses Con las tarjetas de crédito Itaú podés elegir pagar con el beneficio de hasta 15 cuotas sin intereses. La cantidad de cuotas la elegís al momento de la compra. Aplica para compras realizadas a través del vPOS de Bancard. No incluye tarjetas pre-pagas.
                                <ul>
                                    <li>
                                        Para compras en cuotas; en el extracto ingresará la cuotización (sin modificación) por el total de la compra.
                                    </li>
                                </ul>
                            </li>
                            <li>
                                Puntos de programas de recompensas Membership Rewards e Interventajas, ya aplicados en la plataforma de Tienda Naranja Viajes contarán con el beneficio final del valor de canje triplicado.
                                <ul>
                                    <li>
                                        Mínimo de Canje por Cliente/Programa es de 500 puntos.
                                    </li>
                                    <li>
                                        Para acceder al canje, el cliente debe ingresar con sus accesos personales de su cuenta Itaú al servicio de canje de puntos mediante la plataforma de Tienda Naranja Viajes.
                                    </li>
                                </ul>
                            </li>
                            <li>
                                Promoción válida únicamente en Tienda Naranja Viajes (viajes.tiendanaranja.com.py). Beneficios no aplican en Tienda Naranja (<a href="https://tiendanaranja.com.py/" target="_blank">www.tiendanaranja.com.py</a>)
                            </li>
                            <li>
                                Tienda Naranja Viajes podrá cancelar las compras luego de verificar que en las mismas se han utilizado procedimientos, datos o información en incumplimiento a los términos y condiciones de uso del Sitio.
                            </li>
                            <li>
                                Ante sospechas de casos de fraude de cualquier tipo, Tienda Naranja Viajes podrá validar la identidad del cliente y/o cualquier otra información, reservándose el derecho de acudir ante las instancias judiciales.
                            </li>
                        </ol>
                    </Text>
                </li>
                <li>
                    <Text paragraph alignment="justify">
                        <strong>INTERPRETACIÓN Y CONSULTAS:</strong>  Para cualquier situación no prevista en estas bases y condiciones serán aplicados los Términos y Condiciones y la Política de Privacidad publicados en viajes.tiendanaranja.com.py y adicionalmente Tienda Naranja Viajes podrá resolver los reclamos y/o situaciones a su solo arbitrio. Consultas adicionales podrán ser canalizadas a través del Servicio de Atención al Cliente al número de WhatsApp +595984729300
                    </Text>
                </li>
            </ol>

        </div>
    )
}

