/**
 *
 * SearchsDialogsContainer
 *
 */

import React, { memo } from 'react';
import useBreakpoints from 'Utils/hooks/useBreakpoints';

import { engines, routes } from 'Constants';
import { useSelector, shallowEqual } from 'react-redux';

import Backgrounds from './Backgrounds';
import { getEngine, removeCharacter } from 'Utils';
import { statusCodes } from 'Features/hotels';

const selector = ({ flights, router, hotels }) => ({
  isFlightsUnused: flights.search.status === 'unused',
  isHotelsUnused: hotels.status === statusCodes.UNUSED,
  pathname: router.location.pathname
});

const ConnectedComponent = () => {
  const isMediumDown = useBreakpoints().mediumDown;
  const { isFlightsUnused, pathname, isHotelsUnused } = useSelector(
    selector,
    shallowEqual
  );

  const activeNav = removeCharacter(pathname, '/');
  const engineActive = activeNav ? getEngine(activeNav) : engines.FLIGHTS.name;
  // if (isMediumDown) {
  //   return null;
  // }

  if (activeNav === routes.tiotomPromo.replace('/', '')) {
    return;
  }

  return /airCheckout/.test(pathname) ||
    /hotelCheckout/.test(pathname) ||
    /asistencias/.test(pathname) ||
    /sobre-nosotros/.test(pathname) ? null : (
    <Backgrounds
      engines={engines}
      isReducerUnused={
        engines.HOTELS && engineActive === engines.HOTELS.name
          ? isHotelsUnused
          : isFlightsUnused
      }
      engineActive={engineActive}
    />
  );
};

export default memo(ConnectedComponent);
