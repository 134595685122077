import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useParams } from 'react-router';
import api from 'Services';
import * as Sentry from '@sentry/react';
import { SideDetails as HotelSidedetails } from 'Containers/HotelCheckout/components/SideDetails';
import { SideDetails } from './components/SideDetails';
import { ContentCard } from './components/Common';
import {
  ReactComponent as AirSuccessIcon,
} from './assets/air-success.svg';
import { ReactComponent as AirError } from './assets/air-error.svg'
import {
  BreakdownHolder,
  BreakdownItau,
  BreakdownItem,
  BreakdownTitle,
  BreakdownTotal
} from './components/SideDetails/styles';
import { useQuery } from 'Utils/hooks/useQuery';
import { updateLoader } from 'Features/app/actions';
import { useDispatch } from 'react-redux';
import { Breakpoint } from 'react-socks';
import { ErrorMessage } from './errorMessage';

const scrollToTop = () =>
  window.scroll({
    top: 0,
    behavior: 'smooth'
  });

const ContentHeader = styled.div<{ isError?: boolean }>`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 10px;
  width: 100%;
  min-height: 44px;
  background: ${({ isError }) => (isError ? '#F16464' : '#0cda83')};
  font-family: 'Itau Display Pro';
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  color: #ffffff;
`;

const Holder = styled.div`
  display: grid;
  grid-template-columns: 1fr 257px;
  gap: 35px;
  margin: 60px 0;
`;

const HolderContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 50px 30px 30px;
  gap: 50px;
  width: 100%;
`;

const IconHolder = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0px;
  gap: 16px;

  font-family: 'Itau Display Pro';
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 28px;
  text-align: center;
  color: #282828;
`;

const DetailText = styled.div`
  font-family: 'Itau Display Pro';
  font-style: normal;
  font-weight: 300;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  color: #282828;
`;

export const Divider = styled.div`
  width: 100%;
  height: 1px;
  opacity: 0.2;
  background-color: #282828;
`;

const MobileHolder = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 40px;
  margin-top: 180px;
  margin-bottom: 80px;
  /* background-color: white;  */
`;

const formatMoney = value => value.toLocaleString('en').replaceAll(',', '.');

export const CheckoutSuccess = () => {
  const query = useQuery();
  const dispatch = useDispatch();
  const isError = query.get('status') === 'payment_fail';
  const reservationCode = query.get('reservationCode');
  const { id } = useParams<{ id: string }>();
  const [apiResult, setApiResult] = useState<any>();
  const [errorMessage, setErrorMessage] = useState<string>('');

  useEffect(() => {
    const fetchApi = async () => {
      const result =
        id !== 'onlyPoints'
          ? await api.checkout.completeCheckout(id)
          : await api.checkout.completeOnlyPoints(reservationCode);
      setApiResult(result.data as any);
      if (ErrorMessage[result.data.errorCode]) {
        setErrorMessage(ErrorMessage[result.data.errorCode]);
        Sentry.captureMessage(ErrorMessage[result.data.errorCode]);
      }
      dispatch(updateLoader(false));
    };
    fetchApi();
    scrollToTop();
  }, [id, setApiResult]);

  const selectedInterventajasPoints: any = apiResult
    ? apiResult?.toShow.selectedInterventajasPoints
    : 0;
  const selectedMembershipPoints: any = apiResult
    ? apiResult?.toShow.selectedMembershipPoints
    : 0;
  const isOrder: boolean = Boolean(apiResult?.toShow.isOrder);

  const product = apiResult?.product;

  if (product === 'HOTEL') {

    const {
      checkinTime,
      checkoutTime,
      checkinDate,
      checkoutDate,
      totalPrice,
      pricePerNight,
      rooms,
      policy,
      peopleByRoom,
      paxData,
      moneyToComplete
    } = apiResult.toShow;
    const content = (
      <ContentCard>
        <ContentHeader isError={isError}>
          {isError ? '¡Ups!' : '¡Excelente compra!'}
        </ContentHeader>

        <HolderContent>
          {isError ? (
            <IconHolder>
              <AirError />
              <span>{errorMessage ? errorMessage : 'Parece que hubo un error'}</span>
              <DetailText>
                Te devolveremos los puntos canjeados para que intentes
                nuevamente
              </DetailText>
            </IconHolder>
          ) : (
            <IconHolder>
              <AirSuccessIcon />
              <span>¡Pago completado con éxito!</span>
            </IconHolder>
          )}
        </HolderContent>

        <div style={{ width: 'calc(100% - 40px)' }}>
          <BreakdownHolder>
            <BreakdownTitle>Resumen</BreakdownTitle>
            {paxData?.map((pax, paxIndex) => (
              <BreakdownItem>
                <span className="negro">Titular</span>
                <span className="negro">
                  {pax.firstName} {pax.lastName}
                </span>
              </BreakdownItem>
            )
            )}


            <Divider />
            <BreakdownItem>
              <span className="azul">Membership Rewards</span>
              <span className="azul">
                {selectedMembershipPoints} pts
              </span>
            </BreakdownItem>
            <BreakdownItem>
              <span className="azul">Interventajas</span>
              <span className="azul">
                {selectedInterventajasPoints} pts
              </span>
            </BreakdownItem>

            <Divider />
            <BreakdownTotal>
              <span>TOTAL CON TARJETA</span>
              <span>
                PYG {formatMoney(moneyToComplete)}
              </span>
            </BreakdownTotal>



          </BreakdownHolder>
        </div>

      </ContentCard>
    );

    return (
      <div className="container">
        <Breakpoint small down>
          <MobileHolder>
            {content}
            <HotelSidedetails
              checkinTime={checkinTime}
              checkoutTime={checkoutTime}
              checkinDate={checkinDate}
              checkoutDate={checkoutDate}
              totalPrice={totalPrice}
              pricePerNight={pricePerNight}
              rooms={rooms}
              policy={policy}
              peopleByRoom={peopleByRoom}
            />
          </MobileHolder>
        </Breakpoint>
        <Breakpoint medium up>
          <Holder>
            <div>{content}</div>

            <HotelSidedetails
              checkinTime={checkinTime}
              checkoutTime={checkoutTime}
              checkinDate={checkinDate}
              checkoutDate={checkoutDate}
              totalPrice={totalPrice}
              pricePerNight={pricePerNight}
              rooms={rooms}
              policy={policy}
              peopleByRoom={peopleByRoom}
            />
          </Holder>
        </Breakpoint>
      </div>
    );
  }

  if (isOrder) {
    const {
      moneyToComplete,
      description,
      priceToTransaction
    } = apiResult.toShow;
    const content = (
      <ContentCard>
        <ContentHeader isError={isError}>
          {isError ? '¡Ups!' : '¡Excelente compra!'}
        </ContentHeader>

        <HolderContent>
          {isError ? (
            <IconHolder>
              <AirError />
              <span>{errorMessage ? errorMessage : 'Parece que hubo un error'}</span>
              <DetailText>
                Te devolveremos los puntos canjeados para que intentes
                nuevamente
              </DetailText>
            </IconHolder>
          ) : (
            <IconHolder>
              <AirSuccessIcon />
              <span>¡Pago completado con éxito!</span>
            </IconHolder>
          )}
        </HolderContent>

        {!isError && (
          <div style={{ width: 'calc(100% - 40px)' }}>
            <BreakdownHolder>
              <BreakdownTitle>Resumen</BreakdownTitle>
              <div>{description}</div>
              <Divider />

              <BreakdownItem>
                <span>Total a pagar</span>
                <span>PYG {formatMoney(priceToTransaction)}</span>
              </BreakdownItem>
              <Divider />
              <BreakdownItem>
                <span className="azul">Membership Rewards</span>
                <span className="azul">{selectedMembershipPoints} pts</span>
              </BreakdownItem>
              <BreakdownItem>
                <span className="azul">Interventajas</span>
                <span className="azul">{selectedInterventajasPoints} pts</span>
              </BreakdownItem>

              <Divider />
              <BreakdownTotal>
                <span>TOTAL CON TARJETA</span>
                <span>PYG {formatMoney(moneyToComplete)}</span>
              </BreakdownTotal>
            </BreakdownHolder>
          </div>
        )}
      </ContentCard>
    );

    return (
      <div className="container">
        <Breakpoint small down>
          <MobileHolder>{content}</MobileHolder>
        </Breakpoint>
        <Breakpoint medium up>
          <Holder>
            <div>{content}</div>
          </Holder>
        </Breakpoint>
      </div>
    );
  }

  //Success de aire es la respuesta por defecto

  const localPrice: any = apiResult ? apiResult?.toShow.localPrice : null;
  const firstName: any = apiResult
    ? apiResult?.toShow.paxData[0].firstName
    : '';
  const lastName: any = apiResult
    ? apiResult?.toShow.paxData[0].lastName
    : '';
  const selectedSegment: any = apiResult
    ? apiResult?.toShow.selectedSegment
    : null;

  const content = (
    <ContentCard>
      <ContentHeader isError={isError}>
        {isError ? '¡Ups!' : '¡Excelente compra!'}
      </ContentHeader>

      <HolderContent>
        {isError ? (
          <IconHolder>
            <AirError />
            <span>{errorMessage ? errorMessage : 'Parece que hubo un error'}</span>
            <DetailText>
              Te devolveremos los puntos canjeados para que intentes
              nuevamente
            </DetailText>
          </IconHolder>
        ) : (
          <IconHolder>
            <AirSuccessIcon />
            <span>¡Pago completado con éxito!</span>
          </IconHolder>
        )}
      </HolderContent>

      {localPrice && !isError && (
        <div style={{ width: 'calc(100% - 40px)' }}>
          <BreakdownHolder>
            <BreakdownTitle>Resumen</BreakdownTitle>
            <BreakdownItem>
              <span className="negro">Titular</span>
              <span className="negro">
                {firstName} {lastName}
              </span>
            </BreakdownItem>
            <Divider />
            {localPrice.breakdown.map(item =>
              item.label !== 'TOTAL' ? (
                <BreakdownItem>
                  <span>{item.label}</span>
                  <span>
                    {item.currency} {formatMoney(item.price)}
                  </span>
                </BreakdownItem>
              ) : (
                <>
                  <Divider />
                  <BreakdownItem>
                    <span className="azul">Membership Rewards</span>
                    <span className="azul">
                      {selectedMembershipPoints} pts
                    </span>
                  </BreakdownItem>
                  <BreakdownItem>
                    <span className="azul">Interventajas</span>
                    <span className="azul">
                      {selectedInterventajasPoints} pts
                    </span>
                  </BreakdownItem>

                  <Divider />
                  <BreakdownTotal>
                    <span>TOTAL CON TARJETA</span>
                    <span>
                      {item.currency} {formatMoney(item.price)}
                    </span>
                  </BreakdownTotal>
                </>
              )
            )}
          </BreakdownHolder>
        </div>
      )}
    </ContentCard>
  );

  return (
    <div className="container">
      <Breakpoint small down>
        <MobileHolder>
          {content}
          <SideDetails
            localPrice={localPrice}
            selectedSegment={selectedSegment}
            hideDesgloce={!isError}
            isCheckoutMobile
          />
        </MobileHolder>
      </Breakpoint>
      <Breakpoint medium up>
        <Holder>
          <div>{content}</div>

          {localPrice && selectedSegment && (
            <SideDetails
              localPrice={localPrice}
              selectedSegment={selectedSegment}
              hideDesgloce={!isError}
            />
          )}
        </Holder>
      </Breakpoint>
    </div>
  );
};
