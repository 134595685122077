/**
 *
 * MobileFlightSearchCompact
 *
 */

import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { isoDateToFullDate } from 'Utils';

import { ReactComponent as Location } from './Location.svg';
import { ReactComponent as Destination } from './Destination.svg';
import { ReactComponent as Calendar } from './Calendar.svg';
import { ReactComponent as Pax } from './Pax.svg';

const MobileFlightSearchCompact = ({
  className,
  origin,
  destination,
  initialDate,
  endDate,
  adults,
  kids,
  type,
  actions
}) => {
  const passengers = adults + kids.length;

  return (
    <Container>
      <InputHolder>
        <InputSvgHolder>
          <Location />
        </InputSvgHolder>{' '}
        {origin}{' '}
      </InputHolder>
      <InputHolder>
        <InputSvgHolder>
          <Destination />
        </InputSvgHolder>{' '}
        {destination}
      </InputHolder>
      <InputHolder>
        <InputSvgHolder>
          <Calendar />
        </InputSvgHolder>
        {isoDateToFullDate(initialDate)} <Divider />{' '}
        {isoDateToFullDate(endDate)}
      </InputHolder>
      <InputHolder>
        <InputSvgHolder>
          <Pax />
        </InputSvgHolder>{' '}
        {passengers} {passengers > 1 ? 'Pasajeros' : 'Pasajero'}{' '}
      </InputHolder>
    </Container>
  );
  // return (
  //   <div className={cs(className)}>
  //     <div className="flight-container">
  //       <div className="flex-row space-around">
  //         <div className="flex-row">
  //           <Icon name="mv-plane-ida" size={20} />
  //           <Text weight="semi-bold">
  //             {origin} - {destination}
  //           </Text>
  //         </div>
  //         <div className="flex-row margin-left">
  //           <Icon name="mv-user" size={20} />
  //           <Text weight="semi-bold">{passengers}</Text>
  //         </div>
  //       </div>
  //       <div className="flex-row">
  //         <Icon name="mv-calendar" size={20} />
  //         {type === airSearchTypes.roundTrip ? (
  //           <Text>
  //             {formatSuperShort(initialDate)}{' '}
  //             {endDate ? `- ${formatSuperShort(endDate)}` : ''}
  //           </Text>
  //         ) : (
  //           <Text>{formatSuperShort(initialDate)}</Text>
  //         )}
  //       </div>
  //     </div>
  //     <div className="search-container" onClick={actions.setModify}>
  //       <Icon name="mv-lense" size={24} />
  //     </div>
  //   </div>
  // );
};

MobileFlightSearchCompact.propTypes = {
  className: PropTypes.string.isRequired,
  origin: PropTypes.string.isRequired,
  destination: PropTypes.string.isRequired,
  initialDate: PropTypes.string.isRequired,
  endDate: PropTypes.string.isRequired,
  adults: PropTypes.number.isRequired,
  kids: PropTypes.arrayOf(
    PropTypes.shape({
      age: PropTypes.number,
      seat: PropTypes.bool
    })
  ),
  actions: PropTypes.shape({
    setModify: PropTypes.func.isRequired
  }),
  type: PropTypes.string.isRequired
};

export default MobileFlightSearchCompact;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 25px;
  position: absolute;
  max-width: 320px;
  width: 100%;
  margin: 171px auto 28px;
  position: relative;
  background: #ffffff;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
  gap: 10px;
`;

const Divider = styled.div`
  width: 1px;
  height: 24px;
  background-color: #fb8400;
`;

const InputSvgHolder = styled.div`
  width: 26px;
  height: 26px;
`;

const InputHolder = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  padding: 10px 12px;
  gap: 12px;
  min-height: 40px;
  background: rgba(40, 40, 40, 0.05);
  border-radius: 5px;
  font-family: 'Itau Display Pro';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  color: #282828;
`;

// export default styled(MobileFlightSearchCompact)`
//   display: flex;
//   justify-content: space-between;
//   padding: 5px 15px 5px 15px;
//   background: ${COLORS.white};
//   width: 100%;
//   margin-top: 200px;

//   border: 1px solid black;

//   .search-container {
//     color: ${COLORS.important};
//     border-left: 1px solid ${COLORS.darkGrey};
//     display: flex;
//     justify-content: center;
//     align-items: center;

//     .icon {
//       display: flex;
//       justify-content: center;
//       align-items: center;
//       margin-left: 15px;
//       margin-right: 10px;
//     }
//   }

//   .flight-container {
//     display: flex;
//     flex-direction: column;
//     justify-content: center;
//     flex: 1;

//     .flex-row {
//       display: flex;
//       align-items: center;

//       .icon {
//         display: flex;
//         justify-content: center;
//         align-items: center;
//         margin-right: 7px;
//         color: ${COLORS.important};
//       }
//     }
//   }

//   .margin-left {
//     margin-left: 15px;
//     margin-right: 30px;
//   }
// `;
