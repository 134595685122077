import React, { useCallback, useEffect, useState, useRef } from 'react';
import { useForm, FormProvider, useFieldArray } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { Breakpoint } from 'react-socks';
import * as Sentry from '@sentry/react';
import invokeLibrary from 'Utils/invokeLibrary';
import { v4 as uuidv4 } from 'uuid';
import { routes, urlBankcard } from 'Constants';
import api from 'Services';

import { getAppConfiguration, updateLoader } from 'Features/app/actions';

import { BillingData, PaxData, Steps } from './types';

import { Form } from './styles';
import { bancardIframeGenerator } from 'Containers/Checkout/helpers';
import { CheckoutDesktop } from './components/CheckoutDesktop';
import {
  dateTransform,
  getChildCode,
  returnCountryName,
  scrollToTop
} from './utils';
import { CheckoutMobile } from './components/CheckoutMobile';
import { getItauToken } from 'Components/ItauPuntosLogin/utils';
import { useHistory } from 'react-router';
import Modal from 'Components/Modal';
import AirCheckError from 'Features/flights/components/AirCheckError';

const returnItauData = ({
  itau,
  selectedInterventajasPoints,
  selectedMembershipPoints
}) => {
  const itauData = {
    token: getItauToken(),
    aliasClient: itau.aliasClient,
    pointsClient: []
  };
  if (selectedInterventajasPoints > 500) {
    itauData.pointsClient.push({
      program: 'INTERVENTAJAS',
      points: selectedInterventajasPoints
    });
  }

  if (selectedMembershipPoints > 500) {
    itauData.pointsClient.push({
      program: 'MEMBERSHIP',
      points: selectedMembershipPoints
    });
  }
  return itauData;
};

export const AirCheckout = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [billingData, setBillingData] = useState<BillingData>();
  const [paxData, setPaxData] = useState<PaxData[]>([]);
  const [activeStep, setActiveStep] = useState<Steps>(Steps.paxData);
  const [message,setMessage]= useState('');
  const [showErrorModal, setShowErrorModal] = useState(false);

  const toggleErrorModal = () => setShowErrorModal(!showErrorModal);
  const handleGoBack = () => {
    history.goBack()
    setShowErrorModal(false);
  }; 
  
  const isLogged = Boolean(getItauToken());
  const { selectedAvailability, search, itau } = useSelector(
    // @ts-ignore
    ({ checkout, flights, appConfiguration, itau }) => ({
      selectedAvailability: checkout.selectedAvailability,
      search: flights.search,
      selectedItauPoints: appConfiguration.selectedItauPoints,
      PYG: appConfiguration.itauPoints.PYG,
      itau
    })
  );

  const {
    localPrice,
    flightPrice,
    optionId,
    selectedSegment
  } = selectedAvailability;

  useEffect(() => {
    dispatch(updateLoader(false));
    dispatch(getAppConfiguration());
    scrollToTop();
  }, [dispatch]);

  const formRef = useRef<HTMLFormElement>();
  const methods = useForm();
  const {
    formState: { errors },
    control,
    setValue
  } = methods;
  const { fields, append } = useFieldArray({
    control,
    name: 'passengers'
  });

  useEffect(() => {
    // assign adults
    for (let i = 0; i < search.adults; i++) {
      append({
        firstName: '',
        lastName: '',
        birthDate: '',
        gender: '',
        nationality: '',
        documentType: '',
        document: '',
        travelerCode: 'ADT'
      });
    }
    for (const pax of search.children) {
      append({
        firstName: '',
        lastName: '',
        birthDate: '',
        gender: '',
        nationality: '',
        documentType: '',
        document: '',
        travelerCode:
          pax.age > 12 ? 'ADT' : pax.age > 1 ? 'CNN' : getChildCode(pax.seat)
      });
    }

    const persistFormData = localStorage.getItem('vn-checkout-data');
    if (persistFormData) {
      const obj = JSON.parse(persistFormData);

      setValue('address', obj.address);
      setValue('city', obj.city);
      setValue('country', obj.country);
      setValue('countryCode', obj.countryCode);
      setValue('docType', obj.docType);
      setValue('document', obj.document);
      setValue('email', obj.email);
      setValue('firstName', obj.firstName);
      setValue('lastName', obj.lastName);
      setValue('nationality', obj.nationality);
      setValue('personType', obj.personType);
      setValue('phone', obj.phone);
      setValue('state', obj.state);
      setValue('passengers', obj.passengers);
      localStorage.removeItem('vn-checkout-data');
    }
  }, [search, setValue]);

  const handleRequest = async ({
    selectedInterventajasPoints,
    selectedMembershipPoints,
    moneyToComplete,
    pointsToRender
  }) => {
    dispatch(updateLoader(true));

    const request = {
      billingData,
      paymentData: {
        amountPrice: moneyToComplete > 0 ? moneyToComplete : 0
      },
      product: 'FLIGHT',
      flightData: {
        toShow: {
          paxData,
          localPrice,
          selectedSegment,
          pointsToRender,
          moneyToComplete,
          selectedInterventajasPoints,
          selectedMembershipPoints
        },
        main: {
          selectedOption: {
            completeFlights: Object.keys(selectedAvailability.selectedSegment)
              .filter(key => selectedAvailability.selectedSegment[key].item)
              .map(key => ({
                segments:
                  selectedAvailability.selectedSegment[key].item.segments
              })),
            validatingCarrier: localPrice.validatingCarrier
          },
          totalPriceSale: flightPrice.totalPrice,
          coinId: 10,
          optionId
        },
        passengers: paxData
      }
    };

    if (
      isLogged &&
      (selectedInterventajasPoints > 0 || selectedMembershipPoints > 0)
    ) {
      // @ts-ignore
      request.paymentData.itauData = returnItauData({
        itau,
        selectedInterventajasPoints,
        selectedMembershipPoints
      });
    }

    try {
      const result = await api.checkout.requestProcessId(request);
      if (moneyToComplete > 0) {
        await invokeLibrary(urlBankcard);
        dispatch(updateLoader(false));
        bancardIframeGenerator(result.data);
      } else if (result.data.status && result.data.status === 'success') {
        const reservationCode = result.data.id;
        history.push(
          `${routes.airCheckoutSuccess}/onlyPoints/?reservationCode=${reservationCode}`
        );
      } else if (result.data.status && result.data.status === 'failed') {
        const reservationCode = result.data.id;
        history.push(
          `${routes.airCheckoutSuccess}/onlyPoints/?reservationCode=${reservationCode}&status=payment_fail`
        );
      } else {
        history.push(
          `${routes.airCheckoutSuccess}/onlyPoints/?status=payment_fail`
        );
      }
    } catch (e) {
      const errorText = '"No se pudo confirmar la reserva de los asientos para todo los segmentos del vuelo"'
      dispatch(updateLoader(false));
      const uuid = uuidv4();
      setMessage(JSON.stringify(e.response.data.message));
      if(JSON.stringify(e.response.data.message) === errorText){
        setShowErrorModal(true);
      }else{
       Sentry.captureMessage(`JobProcessId failure : Air: ${uuid} - ${JSON.stringify(e.response.data)}`);
       setShowErrorModal(true);
       console.error(`Error al intentar procesar el pago: ${uuid}`);
     }
    }
  };

  const formDispatch = data => {
    if (activeStep < Steps.payment) {
      if (activeStep === Steps.paxData) {
        handleParsePax(data);
      } else {
        handleParseBillingData(data);
      }
      setActiveStep(activeStep + 1);
    }
    scrollToTop();
  };

  const handleParseBillingData = data => {
    setBillingData({
      personType: 1,
      firstName: data.firstName,
      lastName: data.lastName,
      documentType: Number(data.docType),
      document: data.document,
      country: {
        code: data.country,
        name: returnCountryName(data.country)
      },
      cityName: data.city,
      stateName: data.state,
      email: data.email,
      phoneData: {
        phoneType: 1,
        countryCode: data.countryCode,
        phoneNumber: data.phone
      },
      address: data.address
    });
  };

  const handleParsePax = (data: any) => {
    const { passengers } = data;
    setValue('firstName', passengers[0].firstName);
    setValue('lastName', passengers[0].lastName);
    setValue('docType', passengers[0].docType);
    setValue('document', passengers[0].document);
    setValue('nationality', passengers[0].nationality);
    setValue('countryCode', '595');
    setValue('personType', 1);
    setValue('country', 'PY');
    setValue('city', 'Asunción');
    setValue('state', 'Asunción');

    setPaxData(
      passengers.map(item => ({
        firstName: item.firstName,
        lastName: item.lastName,
        birthDate: dateTransform(item.dob),
        gender: Number(item.gender),
        documentData: {
          nationality: item.nationality,
          documentType: Number(item.docType),
          document: item.document
        },
        travelerCode: item.travelerCode // FIXME : hardcodeado de mientras
      }))
    );
  };

  const handleNextStep = () =>
    formRef.current.dispatchEvent(new Event('submit', { cancelable: true }));

  const handlePreviousStep = useCallback(() => {
    if (activeStep > Steps.paxData) {
      setActiveStep(activeStep - 1);
      scrollToTop();
    }
  }, [activeStep, setActiveStep]);

  const props = {
    activeStep,
    fields: fields,
    onNextStep: handleNextStep,
    onPreviousStep: handlePreviousStep,
    onRequest: handleRequest,
    selectedSegment: selectedSegment,
    localPrice: localPrice
  };

  return (
    <FormProvider {...methods}>
      <Form onSubmit={methods.handleSubmit(formDispatch)} ref={formRef}>
        <Breakpoint small down>
          <CheckoutMobile {...props} flightPrice={flightPrice} />
        </Breakpoint>
        <Breakpoint medium up>
          <CheckoutDesktop {...props} />
        </Breakpoint>
      </Form>
      {/* 
      // @ts-ignore */}
          <Modal
              show={showErrorModal}
              onClickOverlay={toggleErrorModal}
              noCard
              modalWidth={1000}>
              <AirCheckError close={toggleErrorModal} message={message} handleGoBack={handleGoBack} />
            </Modal>
    
    </FormProvider>
  );
};
