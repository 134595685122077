import React, { useEffect, useState } from 'react';
import Carousel from '@brainhubeu/react-carousel';
import { useDispatch, useSelector } from 'react-redux';

import useBreakpoints from 'Utils/hooks/useBreakpoints';
import  PriceTable from './components/PriceTable/PriceTable';

import { Section } from './components/Section';
import { logosColumnQty, returnBanner, returnCarrouselItems } from './helpers';
import { Banner, FormCard, IconHolder, LogosHolder, Row, VideoContainer, ServiceHolder} from './styles';

import {ItauBanner} from '../../Components/ItauBanner';
import LogoBanner from './components/LogoBanner/LogoBanner';


import Text from 'Components/Text';

import actividades from './assets/icons/actividades.png';
import cocina from './assets/icons/cocina-gourmet.png';
import deportes from './assets/icons/deportes.png';
import entretenimiento from './assets/icons/entretenimiento.png';
import kids from './assets/icons/kids-club.png';
import logo from './assets/logos/tiotom-svg-white.svg';
import bar from './assets/icons/open-bar.png';
import piscina from './assets/icons/piscina.png';
import playa from './assets/icons/servicio-de-playa.png';
import ImageGallery from './components/ImageGallery/ImageGallery';

import atardecer from './assets/images/2.jpg';
import playados from './assets/images/6.jpg';
import gastro from './assets/images/1.jpg';
import habmenores from './assets/images/4.jpg';
import habitacion from './assets/images/3.jpg';
import piscinas from './assets/images/5.jpg';
import show from './assets/images/8.jpg';
import vista from './assets/images/7.jpg';

const imageUrls = [vista, atardecer, playados, piscinas, habmenores, habitacion, show, gastro]


export const TiotomPromo = () => {
  const dispatch = useDispatch();
  const breakpoints = useBreakpoints();

  return (
    <div>
      <VideoContainer>
      <iframe 
        src="https://player.vimeo.com/video/870766363?h=e7a07a4123&background=1&loop=1&title=0&byline=0&portrait=0&autoplay=1"
        allow="autoplay; fullscreen"
      ></iframe>
      </VideoContainer>
      <LogoBanner backgroundColor="#1F5992" logo={logo} />
      <Section title="TioTom All Inclusive">
        <Text paragraph className="with-separator"> 
        El hotel Tio Tom es un oasis de tranquilidad y diversión, perfecto para aquellos que buscan
        descansar y disfrutar de impresionantes atardeceres. Ideal para familias y grupos de amigos,
        se esfuerza por ser el centro de nuevas experiencias y oportunidades de relajación.
        Ubicado en un lugar privilegiado sobre el mar, el hotel Tio Tom ofrece el entorno perfecto para disfrutar
        al máximo de las vacaciones. Se convierte en el punto de partida y regreso ideal para una
        escapada inolvidable, con su servicio ALL INCLUSIVE que asegura una experiencia completa y gratificante.
        </Text>
        <LogosHolder columns={4}>
          <ServiceHolder>
            <img src={actividades} alt="Actividades" style={{width:'60%'}}/>
            <Text
              paragraph
              className="with-separator title-background"
              weight="regular"
              size="small"
              context="secondary">
             Actividades
            </Text>
          </ServiceHolder>
          <ServiceHolder>
            <img src={cocina} alt="Cocina gourmet" style={{width:'60%'}}/>
            <Text
              paragraph
              className="with-separator title-background"
              weight="regular"
              size="small"
              context="secondary">
             Cocina Gourmet
            </Text>
          </ServiceHolder>
          <ServiceHolder>
            <img src={deportes} alt="Deportes" style={{width:'60%'}}/>
            <Text
              paragraph
              className="with-separator title-background"
              weight="regular"
              size="small"
              context="secondary">
             Deportes
            </Text>
          </ServiceHolder>
          <ServiceHolder>
            <img src={entretenimiento} alt="Entretenimiento" style={{width:'60%'}}/>
            <Text
              paragraph
              className="with-separator title-background"
              weight="regular"
              size="small"
              context="secondary">
                Entretenimiento
            </Text>
          </ServiceHolder>
          <ServiceHolder>
            <img src={kids} alt="Kids Club" style={{width:'60%'}}/>
            <Text
              paragraph
              className="with-separator title-background"
              weight="regular"
              size="small"
              context="secondary">
                Kids Club
            </Text>
          </ServiceHolder>
          <ServiceHolder>
            <img src={bar} alt="Open Bar" style={{width:'60%'}}/>
            <Text
              paragraph
              className="with-separator title-background"
              weight="regular"
              size="small"
              context="secondary">
               Open Bar
            </Text>
          </ServiceHolder>
          <ServiceHolder>
            <img src={piscina} alt="Piscinas" style={{width:'60%'}}/>
            <Text
              paragraph
              className="with-separator title-background"
              weight="regular"
              size="small"
              context="secondary">
               Piscinas
            </Text>
          </ServiceHolder>
          <ServiceHolder>
            <img src={playa} alt="Servicio de playa" style={{width:'60%'}}/>
            <Text
              paragraph
              className="with-separator title-background"
              weight="regular"
              size="small"
              context="secondary">
               Servicio de playa
            </Text>
          </ServiceHolder>
        </LogosHolder>
        <ImageGallery imageUrls={imageUrls}/>
      </Section>
      <Section title="Tarifas promocionales:">
        <PriceTable/> 
      </Section>
      <ItauBanner noMargin={false}/>
      <Section title="Bases y condiciones:">
        <FormCard>
          <Text
            paragraph
            className="with-separator title-background"
            weight="bold"
            context="primary">
            PROMOCIÓN &ldquo;TioTom All Inclusive Punta del Este&ldquo;
          </Text>
          <Text paragraph className="with-separator">
          1. Tarifas exclusivas para la temporada de verano 2024 para el mercado paraguayo y para compra a través del sitio de Tienda Naranja Viajes. <br/>
          2. El valor establecido en la tabla arriba es el TOTAL por la habitación para 7 noches de alojamiento ALL INCLUSIVE* <br/>
          3. Habitación de tipo &ldquo;Doble&rdquo;: Admite un máximo de 2 adultos <br/>
          4. Habitación de tipo &ldquo;Doble + Chd hasta 12 años&rdquo;: Admite un máximo de 2 adultos y 1 menor de hasta 12 años de edad. <br/>
          5. Habitación de tipo &ldquo;2 adultos + 2 menores hasta 17 años o 3 adultos&rdquo;: Admite un máximo de 2 adultos y 2 menores de hasta 17 años de edad ó un total de 3 adultos en la habitación. <br/>
          6. Habitación de tipo &ldquo;4 adultos y 2 menores hasta 17 años&rdquo;: Admite un máximo de 4 adultos y 2 menores de hasta 17 años de edad. <br/>
          7. Bebes o niño de 0 a 2 años FREE. <br/>
          8. Sujeto a disponibilidad de habitación y promoción al momento de la confirmación y pago.
          </Text>
        </FormCard>
      </Section>
      <Section title="Consultanos para reservar:">
        <iframe
          id="forms-app-iframe"
          allowFullScreen={true}
          allow="geolocation; microphone; camera"
          src="https://my.forms.app/form/6582435ffb38082618326ddc"
          style={{ width: '100%', minWidth: '100%', height: '100vh', border: 'none' }}
          title="Form"
        ></iframe>
      </Section>
    </div>
  );
};
