/* eslint-disable semi */
import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import SelectDate from 'Components/SelectDate';
import SelectPeople from 'Components/SelectPeople';
import Select from 'Components/Select';
import Input from 'Components/Input';
import TextArea from 'Components/TextArea';
import Button from 'Components/Button';
import { formKeys } from './index';
import { getBasePriceString } from 'Features/packages/helpers';
import { breakpoints } from 'Constants';
import useBreakpoints from 'Utils/hooks/useBreakpoints';

const PackageContactForm = ({
  basePrice,
  currency,
  price,
  date,
  errors = {},
  onChange,
  assistancePrefers,
  listPhoneCodes,
  prefers,
  name,
  email,
  phoneCode,
  phone,
  query,
  onSubmit,
  title
}) => {
  const { largeDown } = useBreakpoints();
  return (
    <Container largeDown={largeDown}>
      {price && (
        <ContentHeader>
          <Column>
            <TagFrom context="blackHaze" size="medium">
              Desde
            </TagFrom>
            <div className="content-price-form">
              <ContentPriceAndPrice context="important">
                {currency} {price}
              </ContentPriceAndPrice>
            </div>
          </Column>
        </ContentHeader>
      )}
      {price && basePrice && (
        <>
          <FormLabel context="blackHaze">
            {getBasePriceString(basePrice)[0]}{' '}
            {getBasePriceString(basePrice)[1]}
          </FormLabel>
        </>
      )}
      <TitleForm context="important" weight="semi-bold" size="xlarge">
        {title || 'Contactá a un vendedor'}
      </TitleForm>
      <ContentBody largeDown={largeDown}>
        <div
          className={
            largeDown ? 'mobile-content-inputs-form' : 'content-inputs-form'
          }>
          <FormLabel largeDown={largeDown}>
            <InputLabel weight="semi-bold">Fecha de viaje:</InputLabel>
            <SelectDate
              className="input"
              onChange={onChange(formKeys.date)}
              selectedValue={date}
            />
          </FormLabel>
          <FormLabel
            largeDown={largeDown}
            className={!largeDown && 'input-columns'}>
            <InputLabel weight="semi-bold">Pasajeros:</InputLabel>
            <SelectPeople
              className="input"
              onChange={onChange(formKeys.people)}
              error={{
                message: errors.people
                  ? 'La edad de los menores es requerida'
                  : undefined,
                color: 'warning'
              }}
            />
          </FormLabel>
        </div>
        <div
          className={
            largeDown ? 'mobile-content-inputs-form' : 'content-inputs-form'
          }>
          <FormLabel largeDown={largeDown}>
            <InputLabel weight="semi-bold">Nombre:</InputLabel>
            <Input
              placeholder="Ingresá tu nombre"
              className="input"
              onChange={onChange(formKeys.name)}
              value={name}
              message={errors.name}
              context={errors.name && 'warning'}
              id="name"
              autocomplete
            />
          </FormLabel>
          <FormLabel
            largeDown={largeDown}
            className={!largeDown && 'input-columns'}>
            <InputLabel weight="semi-bold">E-mail:</InputLabel>
            <Input
              placeholder="Ej: hola@mevuelo.com"
              className="input"
              onChange={onChange(formKeys.email)}
              value={email}
              message={errors.email}
              context={errors.email && 'warning'}
              type="email"
              id="email"
              autocomplete
            />
          </FormLabel>
        </div>

        <div
          className={
            largeDown ? 'mobile-content-inputs-form' : 'content-inputs-form'
          }>
          <FormLabel>
            <InputLabel weight="semi-bold">Via de contacto:</InputLabel>
            <Select
              className="input"
              placeholder="¿Por qué medio te contactamos?"
              onSelect={onChange(formKeys.prefers)}
              selectedValue={prefers && prefers.id}
              items={assistancePrefers}
              message={errors.prefers}
              context={errors.prefers && 'warning'}
            />
          </FormLabel>
          <FormLabel
            largeDown={largeDown}
            className={largeDown ? 'mobile-content-phone' : 'content-phone'}>
            <InputLabel weight="semi-bold">Teléfono:</InputLabel>
            <FormRow className={largeDown && 'input-phone'}>
              <Select
                largeDown={largeDown}
                className="input margin-right"
                items={listPhoneCodes}
                onSelect={onChange(formKeys.phoneCode)}
                selectedValue={phoneCode && phoneCode.id}
                message={errors.phoneCode}
                context={errors.phoneCode && 'warning'}
              />
              <Input
                placeholder="Número de teléfono"
                className="input"
                value={phone}
                message={errors.phone}
                context={errors.phone && 'warning'}
                onChange={onChange(formKeys.phone)}
              />
            </FormRow>
          </FormLabel>
        </div>

        <FormLabel className="test">
          <InputLabel weight="semi-bold">Comentarios Adicionales:</InputLabel>
          <TextArea
            className={largeDown ? 'input' : 'input-coment-aditional'}
            placeholder="Escribe aquí los comentarios que quieras agregar."
            value={query}
            message={errors.query}
            context={errors.query && 'warning'}
            onChange={onChange(formKeys.query)}
          />
        </FormLabel>
      </ContentBody>
      <Button type="submit" className="input button-form" onClick={onSubmit}>
        Enviar consulta
      </Button>
    </Container>
  );
};

PackageContactForm.propTypes = {
  date: PropTypes.string,
  people: PropTypes.shape({
    adultsCount: PropTypes.number.isRequired,
    childrenQty: PropTypes.number.isRequired
  }),
  prefers: PropTypes.shape({
    id: PropTypes.number.isRequired
  }),
  query: PropTypes.string,
  email: PropTypes.string,
  phone: PropTypes.string,
  phoneCode: PropTypes.shape({
    id: PropTypes.string.isRequired
  }),
  currency: PropTypes.string,
  price: PropTypes.number,
  onChange: PropTypes.func.isRequired,
  assistancePrefers: PropTypes.any,
  listPhoneCodes: PropTypes.any,
  name: PropTypes.string,
  onSubmit: PropTypes.func.isRequired,
  errors: PropTypes.object,
  touched: PropTypes.object,
  basePrice: PropTypes.number,
  title: PropTypes.node
};

export default PackageContactForm;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: ${({ theme }) => theme.colors.white};
  border-radius: 14px;
  box-shadow: ${({ theme }) => theme.shadows.four};
  padding: ${({ theme }) => theme.spacing.one};
  margin-top: 150px;
  .input {
    margin-bottom: ${({ theme }) => theme.spacing.four};
  }
  .button-form {
    width: 191px;
    height: 40px;
    padding: 10px 30px 10px 30px;
    border-radius: 10px;
    gap: 10px;
    justify-content: center;
  }
  .margin-right {
    margin-right: ${({ theme }) => theme.spacing.two};
    width: ${props => props.largeDown === true && '190px'};
  }
  @media (max-width: ${breakpoints.medium + 'px'}) {
    border-radius: 0;
  }
`;

const ContentHeader = styled.div`
  display: flex;
  align-items: center;
`;

const ContentBody = styled.div`
  display: flex;
  align-content: center;
  flex-direction: column;
  padding: ${({ theme }) => theme.spacing.four};
  width: 100%;
  .content-inputs-form {
    display: flex;
    justify-content: flex-start;
  }
  .mobile-content-inputs-form {
    display: column;
  }
  .input-columns {
    margin-left: 30px;
  }
  .input-coment-aditional {
    width: 100%;
  }
  .test {
    width: 100%;
  }
  .mobile-content-phone {
  }
  .mv-input-container {
    width: ${props => props.largeDown === true && '100%'};
  }
  .content-phone {
    margin-left: 30px;
  }
`;

const Column = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-right: ${({ theme }) => theme.spacing.one};
  .content-price-form {
    margin-left: 10px;
  }
  .content-price-form span {
    padding-left: 10px;
    font-size: 24px;
    font-weight: 700;
    line-height: 32px;
    letter-spacing: 0em;
    text-align: left;
  }
`;

const FormLabel = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  text-align: center;
  & > div {
    width: 100%;
    margin-bottom: ${({ theme }) => theme.spacing.four};
  }
  .input-phone {
    display: column;
    flex-direction: column;
  }
`;

const FormRow = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: nowrap;
`;

const InputLabel = styled.span`
  font-family: Itau Display Pro;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: left;
`;

const TitleForm = styled.h3`
  font-family: Itau Display Pro;
  font-size: 14px;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: 0em;
  text-align: center;
  color: #282828;
`;

const ContentPriceAndPrice = styled.span`
  color: var(--primario, #fb8400);
  font-family: Itau Display Pro;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 32px;
`;
const TagFrom = styled.h3`
  font-family: Itau Display Pro;
  font-size: 14px;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: 0em;
  text-align: left;
  color: #282828;
  width: 37px;
  height: 22px;
  margin-top: 8px;
`;
