import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import MobileFlightDestination from '../MobileFlightDestination';
import MobilePaxDetailAirCard from '../MobilePaxDetailAirCard';
import MobileDateSelector from 'Components/MobileDateSelector';
import Toggle from 'Components/Toggle';
import { getMaxFlightDate } from '../../../../Utils/dates';

const MobileRoundTrip = ({
  className,
  knowDestination,
  isMPC,
  actions,
  initialDate,
  endDate,
  isCurrentViewDestinations
}) => (
  <div className={className}>
    <MobileFlightDestination />
    <MobileDateSelector
      arrivalDate={endDate}
      departureDate={initialDate}
      setArrivalDate={actions.setEndDate}
      setDepartureDate={actions.setInitialDate}
      maxDate={getMaxFlightDate()}
      isFlight
    />

    <div className="container-checkboxes">
      <Toggle
        size="medium"
        context="important"
        rightLabel="Fechas flexibles"
        status={isMPC}
        onToggle={actions.toggleMPC}
      />
    </div>
    <MobilePaxDetailAirCard />
  </div>
);

MobileRoundTrip.propTypes = {
  className: PropTypes.string,
  validationMessages: PropTypes.object,
  actions: PropTypes.shape({
    toggleMPC: PropTypes.func.isRequired,
    setKnowDestination: PropTypes.func.isRequired,
    setInitialDate: PropTypes.func.isRequired,
    setEndDate: PropTypes.func.isRequired
  }),
  isMPC: PropTypes.bool,
  knowDestination: PropTypes.bool,
  initialDate: PropTypes.string.isRequired,
  endDate: PropTypes.string.isRequired,
  isCurrentViewDestinations: PropTypes.bool
};

export default styled(MobileRoundTrip)`
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: space-around;
  gap: 12px;
`;
