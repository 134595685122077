/**
 *
 * FloatingButton
 *
 */

import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import cs from 'classnames';
import * as COLORS from 'Constants/colors';
import Icon from '../Icon';
import Text from '../Text';

const FloatingButton = ({
  className,
  onClickLeft,
  onClickRight,
  rightLabel,
  rightIconName,
  leftLabel,
  leftIconName
}) => (
  <div className={cs(className)}>
    <div className="section" onClick={onClickLeft}>
      <Icon name={leftIconName} color="white" size={24} />
      <Text context="white" size="large" weight="semi-bold">
        {leftLabel}
      </Text>
    </div>
    <div className="separator" />
    <div className="section" onClick={onClickRight}>
      <Icon name={rightIconName} color="white" size={24} />
      <Text context="white" size="large" weight="semi-bold">
        {rightLabel}
      </Text>
    </div>
  </div>
);

FloatingButton.propTypes = {
  className: PropTypes.string.isRequired,
  onClickLeft: PropTypes.func.isRequired,
  onClickRight: PropTypes.func.isRequired,
  leftLabel: PropTypes.string.isRequired,
  leftIconName: PropTypes.string.isRequired,
  rightIconName: PropTypes.string.isRequired,
  rightLabel: PropTypes.string.isRequired
};

export default styled(FloatingButton)`
  display: flex;
  position: fixed;
  bottom: 16px;
  margin: 0 50vw;
  border-radius: 27.5px;
  height: 55px;
  padding: 15px;
  width: 243px;
  justify-content: space-around;
  align-items: center;
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.75);
  background-color: #fb8400;
  transform: translateX(-50%);
  z-index: 2;

  .section {
    display: flex;
  }

  .icon {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 5px;
  }

  .separator {
    border-left: 2px solid ${COLORS.white};
    height: 35px;
    width: 1px;
    border-radius: 15px;
  }
`;
