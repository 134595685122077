/**
 *
 * HotelPropertyPage
 *
 */

import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { Breakpoint } from 'react-socks';
import DesktopHotelDetails from '../DesktopHotelDetails';
import MobileHotelDetails from '../MobileHotelDetails';
import { Helmet } from 'react-helmet';
import { resetToEngine } from '../../../../ReduxConfig/availability';
import { checkRate, clearCheckRateError } from 'Features/hotels/actions';
import { useUser } from '../../../users/utils';
import { isCorpo } from '../../../../Services/Users/types';
import { ItauPuntosDesktop } from 'Components/ItauPuntosLogin/ItauPuntosDesktop';
import styled from 'styled-components';
import useBreakpoints from 'Utils/hooks/useBreakpoints';

const HotelPropertyPage = () => {
  const {
    hotel,
    checkInDate,
    checkOutDate,
    availability,
    checkRateError
  } = useSelector(({ hotels, availability }) => ({
    hotel: hotels.property,
    checkRateError: hotels.checkRateError,
    checkInDate: hotels.search.checkIn,
    checkOutDate: hotels.search.checkOut,
    availability
  }));

  const {
    name,
    images,
    starRating,
    keyRating,
    textRating,
    ratePackagesRooms,
    checkIn,
    checkOut,
    descriptions,
    geoLocation,
    facilities,
    address,
    reviews,
    accommodationCode
  } = hotel;

  const { largeDown } = useBreakpoints();

  const initialCenter = geoLocation && {
    lat: geoLocation.latitude,
    lng: geoLocation.longitude
  };
  const dispatch = useDispatch();
  const user = useUser();

  const handleClickReservation = hotel => {
    const urlParams = new URLSearchParams(window.location.search);
    const hotelId = Number(urlParams.get('hotelId'));

    dispatch(
      checkRate(
        {
          ...availability,
          hotelInformation: hotel,
          checkIn: checkIn,
          checkOut: checkOut,
          hotelId
        },
        isCorpo(user)
      )
    );
  };

  useEffect(() => {
    dispatch(resetToEngine('hotel-details'));
  }, []);

  const closeError = () => dispatch(clearCheckRateError());

  const ogImage = images[0].imageUrl;

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title> {`${name} | Elegí en MeVuelo`}</title>
        <meta
          name="description"
          content={`MeVuelo tiene los mejores precios para tu alojamiento en el ${name}. Hoteles baratos por noche y más. Hoteles baratos y ofertas.`}
        />
        <meta
          name="keywords"
          content={`${name}, hoteles baratos y ofertas, precios de hoteles, hoteles baratos por noche`}
        />
        <meta property="og:title" content={`${name} | Elegí en MeVuelo`} />
        <meta
          property="og:description"
          content={`MeVuelo tiene los mejores precios para tu alojamiento en el ${name}. Hoteles baratos por noche y más. Hoteles baratos y ofertas.`}
        />
        <meta property="og:image" content={ogImage} />
      </Helmet>
      {!largeDown && (
        <ContentBannerItau>
          <ItauPuntosDesktop />
        </ContentBannerItau>
      )}
      <Breakpoint medium up>
        <DesktopHotelDetails
          ratePackagesRooms={ratePackagesRooms}
          name={name}
          images={images}
          starRating={starRating}
          keyRating={keyRating}
          textRating={textRating}
          checkIn={checkIn}
          checkOut={checkOut}
          accommodationCode={accommodationCode}
          initialCenter={initialCenter}
          facilities={facilities}
          descriptions={descriptions}
          // TODO remove this and connect component to avoid passing this info
          detailRequest={{ checkIn: checkInDate, checkOut: checkOutDate }}
          handleClickReservation={handleClickReservation}
          address={address}
          reviews={reviews}
          closeError={closeError}
          checkRateError={checkRateError}
        />
      </Breakpoint>
      <Breakpoint small down>
        <MobileHotelDetails
          accommodationCode={accommodationCode}
          ratePackagesRooms={ratePackagesRooms}
          name={name}
          images={images}
          starRating={starRating}
          keyRating={keyRating}
          textRating={textRating}
          checkIn={checkIn}
          checkOut={checkOut}
          initialCenter={initialCenter}
          facilities={facilities}
          descriptions={descriptions}
          handleClickReservation={handleClickReservation}
          address={address}
          reviews={reviews}
          closeError={closeError}
          checkRateError={checkRateError}
        />
      </Breakpoint>
    </>
  );
};

HotelPropertyPage.propTypes = {
  availability: PropTypes.any
};

const ContentBannerItau = styled.div`
  margin: 70px 0;
`;
export default HotelPropertyPage;
