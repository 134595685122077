import React, { useEffect } from 'react';

import { Stepper } from '../Stepper';
import { ContentCard } from '../Common';
import { PaymentStep } from '../PaymentStep';
import { BillingFields } from '../BillingFields';
import { SideDetails } from '../SideDetails';

import { ReactComponent as Chevron } from '../../assets/chevron.svg';
import {
  Holder,
  ContentHeader,
  Title,
  Navigation,
  NavButton
} from '../../styles';
import { Steps } from '../../types';
import { useFormContext } from 'react-hook-form';

interface Props {
  activeStep: Steps;
  onRequest: ({
    selectedInterventajasPoints,
    selectedMembershipPoints,
    moneyToComplete,
    pointsToRender
  }) => void;
  onPreviousStep: () => void;
  onNextStep: () => void;
  priceToTransaction: number;
  description: string;
}

export const PaymentLinkDesktop = ({
  activeStep,
  onRequest,
  onNextStep,
  onPreviousStep,
  priceToTransaction,
  description
}: Props) => {
  const { setValue } = useFormContext();

  return (
    <div className="container">
      <Holder>
        <div>
          <ContentCard>
            <ContentHeader>
              COMPLETÁ TUS DATOS Y FINALIZÁ TU COMPRA
            </ContentHeader>
            <Stepper activeStep={activeStep} />

            {activeStep === Steps.billingData && (
              <>
                <Title>Datos de facturación</Title>
                <BillingFields />
              </>
            )}

            {activeStep === Steps.payment && (
              <PaymentStep
                onRequest={onRequest}
                priceToTransaction={priceToTransaction}
              />
            )}

            <Navigation>
              {activeStep !== Steps.paxData ? (
                <NavButton onClick={onPreviousStep}>
                  <Chevron className="rotated" />
                </NavButton>
              ) : (
                <div />
              )}

              {activeStep !== Steps.payment ? (
                <NavButton onClick={onNextStep}>
                  <Chevron />
                </NavButton>
              ) : (
                <div />
              )}
            </Navigation>
          </ContentCard>
        </div>
        <SideDetails
          priceToTransaction={priceToTransaction}
          description={description}
        />
      </Holder>
    </div>
  );
};
