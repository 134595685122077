import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
  clearItauToken,
  doLogin,
  getItauToken
} from 'Components/ItauPuntosLogin/utils';
import { ItauMembershipProgram, updateItauValues } from 'ReduxConfig/itau';
import api from 'Services';

import { ReactComponent as Itau } from 'Assets/logo_naranja.svg';
import { ReactComponent as InfoIcon } from '../../assets/info.svg';
import { ReactComponent as Membership } from '../../assets/membership.svg';
import { ReactComponent as Interventajas } from '../../assets/interventajas.svg';
import { RewardSlider } from '../RewardSlider';

import {
  Breakdown,
  BuyButtonHolder,
  Card,
  Divider,
  Holder,
  LoginButtonsHolder,
  MemberShipsHolder,
  TitleHolder,
  TitleLeft,
  TitleRight,
  ValorEnPuntosHolder
} from './styles';
import { useFormContext } from 'react-hook-form';

interface Props {
  selectedMembershipPoints: number;
  selectedInterventajasPoints: number
  setSelectedMembershipPoints: Dispatch<SetStateAction<number>>;
  setSelectedInterventajasPoints: Dispatch<SetStateAction<number>>
  onRequest: ({
    selectedInterventajasPoints,
    selectedMembershipPoints,
    moneyToComplete,
    pointsToRender
  }) => void;
}

const getPoints = (pointsClient: any, plan: ItauMembershipProgram) => {
  const result = pointsClient.find(item => item.program === plan);
  return result ? result.points : 0;
};

const getPointsToBeConsumed = ({
  priceInPoints,
  selectedMembershipPoints,
  selectedInterventajasPoints
}) => {
  let toRenderMembership = 0;
  let toRenderInterventajas = 0;
  if (priceInPoints > selectedMembershipPoints) {
    toRenderMembership = selectedMembershipPoints;
  } else if (priceInPoints <= selectedMembershipPoints) {
    toRenderMembership = priceInPoints;
    return {
      toRenderMembership,
      toRenderInterventajas
    };
  }
  const remainingPoints = priceInPoints - toRenderMembership;

  if (remainingPoints > selectedInterventajasPoints) {
    toRenderInterventajas = selectedInterventajasPoints;
  } else {
    toRenderInterventajas = remainingPoints;
  }

  return {
    toRenderMembership,
    toRenderInterventajas
  };
};

export const PaymentStep = ({ onRequest, selectedMembershipPoints, selectedInterventajasPoints, setSelectedMembershipPoints, setSelectedInterventajasPoints }: Props) => {
  const dispatch = useDispatch();
  const { getValues } = useFormContext();
  const isLogged = Boolean(getItauToken());
  const [loadingBancard, setLoadingBancard] = useState(false);

  const { pointsClient, PYG, localPrice } = useSelector(
    // @ts-ignore reducers not typed?
    ({ itau, appConfiguration, checkout }) => ({
      pointsClient: itau.pointsClient,
      PYG: appConfiguration.itauPoints.PYG,
      localPrice: checkout.selectedAvailability.localPrice
    })
  );

  const maxMembershipPoints = getPoints(
    pointsClient,
    ItauMembershipProgram.MEMBERSHIP
  );
  const maxInterventajasPoints = getPoints(
    pointsClient,
    ItauMembershipProgram.INTERVENTAJAS
  );
  const selectedItauPoints =
    selectedInterventajasPoints + selectedMembershipPoints;
  const costCurrencyInPoints = PYG;
  const priceInPoints = Math.ceil(localPrice.totalPrice / costCurrencyInPoints);
  const moneyInPoints = selectedItauPoints * costCurrencyInPoints;
  const moneyToComplete = Math.ceil((localPrice.totalPrice - moneyInPoints) * 100) / 100;
  const pointsToRender =
    selectedItauPoints <= priceInPoints
      ? selectedItauPoints.toLocaleString('en').replaceAll(',', '.')
      : priceInPoints.toLocaleString('en').replaceAll(',', '.');

  const { toRenderMembership, toRenderInterventajas } = getPointsToBeConsumed({
    priceInPoints,
    selectedMembershipPoints,
    selectedInterventajasPoints
  });

  useEffect(() => {
    const updatePointsClient = async () => {
      const token = getItauToken();
      if (token) {
        try {
          const result = await api.itau.clientClientPoints(token);
          dispatch(updateItauValues(result.data));
        } catch (_e) {
          clearItauToken();
        }
      }
    };
    updatePointsClient();
  }, []);

  const handleInterventajasChange = (value: number) => {
    setSelectedInterventajasPoints(value);
  };

  const handleMembershipChange = (value: number) => {
    setSelectedMembershipPoints(value);
  };

  const handleRequest = () => {
    if (!loadingBancard) {
      setLoadingBancard(true);
      onRequest({
        selectedInterventajasPoints: toRenderInterventajas,
        selectedMembershipPoints: toRenderMembership,
        moneyToComplete,
        pointsToRender
      });
    }
  };

  const handleLogin = () => {
    const formData = getValues();
    localStorage.setItem('vn-checkout-data', JSON.stringify(formData));
    doLogin(dispatch)();
  };

  return (
    <Holder>
      <TitleHolder>
        <TitleLeft>
          <Itau />
          <span>Pagá con tus puntos Itaú</span>
        </TitleLeft>

        <TitleRight>
          <InfoIcon />

          <span>Podés pagar combinando puntos y tarjeta.</span>
        </TitleRight>
      </TitleHolder>

      {!isLogged && (
        <Card>
          <span>Para usar tus puntos Itaú, accedé a tu cuenta.</span>
          <LoginButtonsHolder>
            <div onClick={handleLogin}>Ingresar</div>
          </LoginButtonsHolder>
        </Card>
      )}

      <ValorEnPuntosHolder>
        Valor de esta compra: <span>{priceInPoints} puntos</span>
      </ValorEnPuntosHolder>

      <MemberShipsHolder>
        <RewardSlider
          onChange={handleMembershipChange}
          initialValue={selectedMembershipPoints}
          maxPoints={maxMembershipPoints}
          svgLogo={<Membership />}
          disabled={!isLogged}
        />
        <Divider />
        <RewardSlider
          onChange={handleInterventajasChange}
          initialValue={selectedInterventajasPoints}
          maxPoints={maxInterventajasPoints}
          svgLogo={<Interventajas />}
          disabled={!isLogged}
        />
      </MemberShipsHolder>
      <Breakdown>
        <div>
          <span>
            {toRenderMembership.toLocaleString('en').replaceAll(',', '.')}
          </span>
          Puntos <br /> Membership Rewards
        </div>
        <span>+</span>
        <div>
          <span>
            {toRenderInterventajas.toLocaleString('en').replaceAll(',', '.')}
          </span>
          Puntos <br /> Interventajas
        </div>
        <span>+</span>
        <div>
          <span>
            PYG{' '}
            {moneyToComplete < 0
              ? 0
              : moneyToComplete.toLocaleString('en').replaceAll(',', '.')}
          </span>
          Tarjeta de <br /> crédito o débito
        </div>
      </Breakdown>
      <BuyButtonHolder>
        <div className={loadingBancard && 'loading'} onClick={handleRequest}>{loadingBancard ? 'Cargando...' : 'Pagar'}</div>
      </BuyButtonHolder>
    </Holder>
  );
};
