import countries from 'Components/InvoiceForm/countries';

export const returnCountryName = (countryCode: string) => {
  const myCountry = countries.find(country => country.code === countryCode);
  return myCountry.name;
};

export const getChildCode = (hasSeat: boolean) => (hasSeat ? 'INS' : 'INF');

export const dateTransform = value => {
  const splitted = value.split('-');
  return {
    day: Number(splitted[2]),
    month: Number(splitted[1]),
    year: Number(splitted[0])
  };
};

export const scrollToTop = () =>
  window.scroll({
    top: 0,
    behavior: 'smooth'
  });
