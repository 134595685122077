// vendor
import React from 'react';
import PropTypes from 'prop-types';
import cs from 'classnames';

// utils
import { mobilePackageSteps, packagesWhenType } from 'Constants';
import useBreakpoints from 'Utils/hooks/useBreakpoints';

// styles
import styles, { Label } from './MobilePackageSearch.styles';

// Components
import PackageSearchIcon from '../PackageSearchIcon';
import Input from 'Components/Input';
import Tag from 'Components/Tag';
import NewSelect from '../../../../Components/NewSelect';
import styled from 'styled-components';

const calcIconHolderStyles = (isXlDown, numberOfItems) => {
  if (!isXlDown) {
    return null;
  }
  const iconSize = 120;
  return {
    width: numberOfItems * iconSize
  };
};

const Button2 = styled.button`
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 10px 30px;
  gap: 10px;
  width: 100%;
  height: 48px;
  background: #fb8400;
  border: 1px solid white;
  border-radius: 12px;
  font-family: 'Itau Display Pro';
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 28px;
  text-align: center;
  color: white;
`;

const MobilePackageSearch = ({
  className,
  actions,
  when,
  where,
  how,
  orderBy,
  orderByOptions,
  departureOptions,
  selectedDepartureOption,
  handleSelectDeparture,
  region,
  stateWhen,
  stateHow,
  selectedTabId,
  text
}) => {
  const { xlDown } = useBreakpoints();

  const renderRegions = () =>
    where && (
      <div className="icons-list_holder">
        <div
          className="icons-list wrap"
          style={calcIconHolderStyles(xlDown, where.length)}>
          {where.map(d => (
            <PackageSearchIcon
              onClick={actions.handleSelectRegion(d)}
              context={region && region.id === d.id ? 'active' : 'default'}
              key={d.id}
              name={d.name}
              icon={d.icon}
              labelPosition="bottom"
            />
          ))}
        </div>
      </div>
    );

  const renderMonths = () =>
    when.months && (
      <div className="icons-list_holder">
        <div className="icons-list wrap">
          {when.months.map(d => (
            <PackageSearchIcon
              onClick={actions.handleSelectMonth(d)}
              context={
                stateWhen &&
                stateWhen.type === packagesWhenType.MONTH &&
                stateWhen.option &&
                stateWhen.option.id === d.id
                  ? 'active'
                  : 'default'
              }
              key={d.id}
              name={d.name}
              icon={d.icon}
              size="small"
              noText
            />
          ))}
        </div>
      </div>
    );

  const renderSeasons = () =>
    when.seasons && (
      <div className="icons-list_holder">
        <div
          className="icons-list wrap"
          style={calcIconHolderStyles(xlDown, when.seasons.length)}>
          {when.seasons.map(d => (
            <PackageSearchIcon
              onClick={actions.handleSelectSeason(d)}
              context={
                stateWhen &&
                stateWhen.type === packagesWhenType.SEASON &&
                stateWhen.option &&
                stateWhen.option.id === d.id
                  ? 'active'
                  : 'default'
              }
              key={d.id}
              name={d.name}
              icon={d.icon}
              labelPosition="bottom"
            />
          ))}
        </div>
      </div>
    );

  const renderHow = () =>
    how && (
      <div className="icons-list_holder">
        <div
          className="icons-list wrap"
          style={calcIconHolderStyles(xlDown, how.length)}>
          {how.map(d => (
            <PackageSearchIcon
              onClick={actions.handleSelectHow(d)}
              context={stateHow && stateHow.id === d.id ? 'active' : 'default'}
              key={d.id}
              name={d.name}
              icon={d.icon}
              labelPosition="bottom"
            />
          ))}
        </div>
      </div>
    );

  const renderSteps = () =>
    mobilePackageSteps && (
      <>
        <div
          className={cs('package-search-tabs_item', {
            active: mobilePackageSteps[1].id === selectedTabId
          })}
          key={mobilePackageSteps[1].id}
          onClick={actions.handleSelectTab(mobilePackageSteps[1].id)}>
          {mobilePackageSteps[1].label}
        </div>
        <div
          className={cs('package-search-tabs_item', {
            active: mobilePackageSteps[0].id === selectedTabId
          })}
          key={mobilePackageSteps[0].id}
          onClick={actions.handleSelectTab(mobilePackageSteps[0].id)}>
          {mobilePackageSteps[0].label}
        </div>
        <div
          className={cs('package-search-tabs_item', {
            active: mobilePackageSteps[2].id === selectedTabId
          })}
          key={mobilePackageSteps[2].id}
          onClick={actions.handleSelectTab(mobilePackageSteps[2].id)}>
          {mobilePackageSteps[2].label}
        </div>
      </>
    );

  return (
    <div className={cs(className)}>
      <div className="package-search_top-card">
        <div className="package-search-tabs_holder">{renderSteps()}</div>

        <div className="package-search-options_holder">
          {selectedTabId === mobilePackageSteps[0].id && <>{renderRegions()}</>}

          {selectedTabId === mobilePackageSteps[1].id && (
            <>
              {renderSeasons()}
              {renderMonths()}
            </>
          )}

          {selectedTabId === mobilePackageSteps[2].id && <>{renderHow()}</>}
        </div>
      </div>
      <div className="content-inputs-search">
        {departureOptions.length > 1 && (
          <div className="search-input_holder">
            <Label>Saliendo desde</Label>
            <NewSelect
              className="select-departure-box"
              items={departureOptions}
              initialSelectedItem={selectedDepartureOption}
              itemToString={item => item.name}
              onItemSelected={handleSelectDeparture}
            />
          </div>
        )}
        <div className="search-input_holder">
          <Input
            icon="mv-lense"
            placeholder="Filtrar por: Ej. Miami"
            value={text}
            onChange={actions.handleFilterByText}
          />
        </div>
        <Button2 className="button-search" onClick={actions.doSearch}>
          Buscar
        </Button2>
      </div>
      <div className="tags-holder">
        {!(stateWhen === null || stateWhen.option === null) && (
          <Tag onClick={actions.cleanWhen}>{stateWhen.option.name}</Tag>
        )}
        {region !== null && (
          <Tag onClick={actions.cleanRegion}>{region.name}</Tag>
        )}
        {stateHow !== null && (
          <Tag onClick={actions.cleanHow}>{stateHow.name}</Tag>
        )}
      </div>
      {/* <div className="order-by_container">
        <div className="order-by_holder">
          <div
            className={cs('order-by_button', {
              active: orderBy === orderByOptions.NOMBRE
            })}
            onClick={actions.handleUpdateOrderBy(orderByOptions.NOMBRE)}>
            <Text
              weight="semi-bold"
              context={
                orderBy === orderByOptions.NOMBRE ? 'white' : 'important'
              }>
              {orderByOptions.NOMBRE}
            </Text>
          </div>

          <div
            className={cs('order-by_button', {
              active: orderBy === orderByOptions.PRECIO
            })}
            onClick={actions.handleUpdateOrderBy(orderByOptions.PRECIO)}>
            <Text
              weight="semi-bold"
              context={
                orderBy === orderByOptions.PRECIO ? 'white' : 'important'
              }>
              {orderByOptions.PRECIO}
            </Text>
          </div>

          <div
            className={cs('order-by_button', {
              active: orderBy === orderByOptions.DURACION
            })}
            onClick={actions.handleUpdateOrderBy(orderByOptions.DURACION)}>
            <Text
              weight="semi-bold"
              context={
                orderBy === orderByOptions.DURACION ? 'white' : 'important'
              }>
              {orderByOptions.DURACION}
            </Text>
          </div>
        </div>
      </div> */}
    </div>
  );
};

MobilePackageSearch.propTypes = {
  className: PropTypes.string.isRequired,
  responseData: PropTypes.any,
  onChange: PropTypes.func,
  actions: PropTypes.object,
  departures: PropTypes.array,
  when: PropTypes.object,
  departureOptions: PropTypes.array,
  selectedDepartureOption: PropTypes.object,
  handleSelectDeparture: PropTypes.func,
  where: PropTypes.array,
  how: PropTypes.array,
  orderByName: PropTypes.bool,
  orderBy: PropTypes.string,
  region: PropTypes.any,
  city: PropTypes.any,
  stateWhen: PropTypes.any,
  stateHow: PropTypes.any,
  queryString: PropTypes.string,
  engine: PropTypes.string,
  orderByOptions: PropTypes.object,
  selectedTabId: PropTypes.number,
  text: PropTypes.string
};

export default styles(MobilePackageSearch);
