import { updateLoader } from 'Features/app/actions';
import api from 'Services';
import Cookies from 'js-cookie';

export const doLogin = dispatch => async () => {
  localStorage.setItem('lastURL', window.location.href);
  try {
    dispatch(updateLoader(true));
    const response = await api.itau.getPreLoginToken();
    const { preLoginToken } = response.data;

    // const conf = window.confirm(`Tu preLogin token es: ${preLoginToken}`);
    window.location.href = `${process.env.REACT_APP_ITAU_REDIRECT_TO}?token=${preLoginToken}`;
  } catch (e) {
    dispatch(updateLoader(false));
  }
};

export const getItauToken = () => Cookies.get('itau-token');

export const clearItauToken = () => Cookies.remove('itau-token');
