import React from 'react';
import { Redirect } from 'react-router';
import Cookies from 'js-cookie';

import { routes } from 'Constants';
import { useQuery } from 'Utils/hooks/useQuery';

export const ItauCallback = () => {
  const query = useQuery();
  const token = query.get('Token');
  Cookies.set('itau-token', token);

  const redirectUrl = localStorage.getItem('lastURL')
    ? localStorage
        .getItem('lastURL')
        .replace(window.location.origin, '')
        .slice(1)
    : routes.flight;

  return <Redirect to={redirectUrl} />;
};
