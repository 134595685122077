/**
 *
 * HotelHeader
 *
 */

import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import cs from 'classnames';
import Icon from 'Components/Icon';
import Button from 'Components/Button';
import StarsFilter from 'Components/StarsFilter';
import { blue, important } from 'Constants/colors';
import { textTruncate } from 'Utils';
import Badge from 'Components/Badge';
import { ReactComponent as Clock } from './clock.svg';

const HotelHeader = ({
  className,
  name,
  image1,
  image2,
  image3,
  starRating,
  keyRating,
  textRating,
  checkin,
  checkout,
  onShowServices,
  address,
  facilities
}) => (
  <div className={cs(className)}>
    {image1 && (
      <div className="photos-holder">
        <div
          className={cs('big-background', 'photo-item')}
          style={{ background: `url(${image1})`, marginRight: image2 ? 6 : 0 }}
          alt="photo1"
        />
        {image2 && (
          <div className={cs('small-photos-container')}>
            <div
              className={cs('photo-item')}
              style={{ background: `url(${image2})`, marginBottom: 3 }}
              alt="photo1"
            />
            {image3 && (
              <div
                className={cs('photo-item')}
                style={{ background: `url(${image3})`, marginTop: 3 }}
                alt="photo1"
              />
            )}
          </div>
        )}
      </div>
    )}
    <div className="information-holder">
      <div className="info-item">
        <Title size="xlarge" weight="semi-bold" context="blue">
          {textTruncate(name, 24)}
        </Title>
        {starRating && <StarsFilter justDisplay initialValue={starRating} />}
        {keyRating && (
          <StarsFilter justDisplay initialValue={keyRating} isKey={keyRating} />
        )}
        {textRating && (
          <Badge variation="yellow" inverted>
            {textRating}
          </Badge>
        )}
        <div className="content-addres">
          <Icon name="mv-location-2" color="important" />
          <Address>{address}</Address>
        </div>
      </div>
      <div className="info-item borders">
        <div>
          <Title size="xlarge" weight="semi-bold" context="blue">
            Horarios
          </Title>
        </div>
        <div className="content-check-in">
          <Check weight="semi-bold" context="important">
            <Clock /> Horario de Check In:
          </Check>{' '}
          <CheckText>a partir de las {checkin} hs</CheckText>
        </div>
        <div className="content-check-out">
          <Check weight="semi-bold" context="important">
            <Clock /> Horario de Check Out:
          </Check>{' '}
          <CheckText>hasta las {checkout} hs</CheckText>
        </div>
      </div>
      <div className="info-item">
        <div>
          <Title size="xlarge" weight="semi-bold" context="blue">
            Servicios
          </Title>
        </div>
        <div className="facilities-holder">
          <div className="content-icons">
            <div className="icon">
              <Icon
                size={32}
                color={
                  facilities && facilities.parking ? 'important' : 'default'
                }
                name="mv-car-parking">
                <p>Parking</p>
              </Icon>
              <Icon size={32} color="important" name="mv-swimming-pool">
                <p>Pileta</p>
              </Icon>
              <Icon size={32} color="important" name="mv-gym">
                <p>Gimnasio</p>
              </Icon>
            </div>
          </div>
          <div className="content-icons">
            <div className="icon">
              <Icon size={32} color="important" name="mv-sun">
                <p>Solárium</p>
              </Icon>
              <Icon size={32} color="important" name="mv-breakfast">
                <p>Desayuno</p>
              </Icon>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
);

HotelHeader.propTypes = {
  className: PropTypes.string.isRequired,
  name: PropTypes.string,
  image1: PropTypes.string,
  image2: PropTypes.string,
  image3: PropTypes.string,
  starRating: PropTypes.number,
  keyRating: PropTypes.number,
  textRating: PropTypes.string,
  checkin: PropTypes.string,
  checkout: PropTypes.string,
  onShowServices: PropTypes.func,
  address: PropTypes.string,
  facilities: PropTypes.object,
  accommodationCode: PropTypes.string
};

export default styled(HotelHeader)`
  display: block;
  border-radius: 14px;
  overflow: hidden;
  background: #fff;
  margin-bottom: 30px;
  width: 1126px;
  border-radius: 10px;
  background: #fff;
  box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.25);

  .button-holder {
    display: flex;
    justify-content: center;
  }

  .facilities-holder {
    display: flex;
    justify-content: space-around;
    .content-icons {
      display: flex;
      flex-direction: column;
    }
    .icon {
      display: flex;
      flex-direction: column;
      align-items: start;
      margin: 9px 0;
    }
    .icon p {
      font-family: Itau Display Pro;
      font-size: 14px;
      font-weight: 400;
      letter-spacing: 0;
      margin: 0 0 0 5px;
      text-align: left;
      color: #282828;
    }
    .icon span {
      flex-direction: row;
    }
  }
  svg {
    fill: none;
  }
  .photos-holder {
    display: flex;
    min-height: 380px;

    .big-background {
      flex-grow: 2;
    }

    .photo-item {
      background-size: cover !important;
      background-position: center !important;
      box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.35);
    }

    .small-photos-container {
      flex-grow: 1;
      display: flex;
      flex-direction: column;

      .photo-item {
        flex-grow: 1;
      }
    }
  }
  .content-addres {
    display: flex;
    margin-top: 25px;
  }
  .content-check-in {
    padding: 20px;
  }
  .content-check-out {
    padding: 0 20px;
  }
  .information-holder {
    display: flex;
    margin: 10px;

    .info-item {
      flex: 1;
      padding: 11px;
      width: 375px;
      height: 216px;
      padding: 20px;
      border: 0 1px 0 0;
      gap: 16px;
      &.borders {
        border-left: 1px solid rgba(40, 40, 40, 0.25);
        border-right: 1px solid rgba(40, 40, 40, 0.25);
      }
    }
  }
`;

const Title = styled.span`
  color: #282828;
  font-family: Itau Display Pro;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 32px; /* 133.333% */
`;

const Address = styled.span`
  font-family: Itau Display Pro;
  font-size: 14px;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: 0em;
  text-align: left;
  margin-left: 10px;
  color: #282828;
`;

const Check = styled.span`
  font-family: Itau Display Pro;
  font-size: 14px;
  font-weight: 700;
  line-height: 22px;
  letter-spacing: 0em;
  text-align: left;
  color: #fb8400;
`;

const CheckText = styled.span`
  font-family: Itau Display Pro;
  font-size: 14px;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: 0em;
  text-align: left;
`;
