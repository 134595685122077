const fontStyle = {
  default: 'normal' as const,
  italic: 'italic' as const
};

const fontFamily = {
  default: 'Itau Display Pro' as const
};

const fontSize = {
  body: {
    xSmall: '10px',
    small: '12px',
    default: '14px'
  } as const,
  headings: {
    xxlarge: '36px' as const,
    xlarge: '30px' as const,
    large: '24px' as const,
    medium: '18px' as const,
    small: '16px' as const
  } as const
};

const fontWeights = {
  default: 'normal' as const,
  light: 300 as const,
  medium: 500 as const,
  bold: 'bold' as const,
  semibold: 600 as const
};

const bodyStyle = {
  medium: `
    font-family: ${fontFamily.default};
    font-style: ${fontStyle.default};
    font-size: ${fontSize.body.default};
    font-weight: ${fontWeights.default};
    line-height: 17px;
  `,
  small: `
    font-family: ${fontFamily.default};
    font-style: ${fontStyle.default};
    font-size: ${fontSize.body.small};
    font-weight: ${fontWeights.default};
    line-height: 15px;
  `
};

const h1 = `
    font-family: ${fontFamily.default};
    font-style: ${fontStyle.default};
    font-size: ${fontSize.headings.xxlarge};
    font-weight: ${fontWeights.medium};
    line-height: 44px;
`;
const h2 = `
    font-family: ${fontFamily.default};
    font-style: ${fontStyle.default};
    font-size: ${fontSize.headings.xlarge};
    font-weight: ${fontWeights.medium};
    line-height: 37px;
`;
const h3 = `
    font-family: ${fontFamily.default};
    font-style: ${fontStyle.default};
    font-size: ${fontSize.headings.large};
    font-weight: ${fontWeights.medium};
    line-height: 29px;
`;
const h4 = `
    font-family: ${fontFamily.default};
    font-style: ${fontStyle.default};
    font-size: ${fontSize.headings.medium};
    font-weight: ${fontWeights.medium};
    line-height: 22px;
`;
const h5 = `
    font-family: ${fontFamily.default};
    font-style: ${fontStyle.default};
    font-size: ${fontSize.headings.small};
    font-weight: ${fontWeights.light};
    line-height: 20px;
`;

const numLarge = `
    font-family: ${fontFamily.default};
    font-style: ${fontStyle.default};
    font-size: ${fontSize.headings.large};
    font-weight: ${fontWeights.semibold};
    line-height: 29px;
`;

const numMedium = `
    font-family: ${fontFamily.default};
    font-style: ${fontStyle.default};
    font-size: ${fontSize.headings.medium};
    font-weight: ${fontWeights.semibold};
    line-height: 22px;
`;

const numSmall = `
    font-family: ${fontFamily.default};
    font-style: ${fontStyle.default};
    font-size: ${fontSize.headings.small};
    font-weight: ${fontWeights.medium};
    line-height: 20px;
`;

const numeralStyle = {
  small: numSmall,
  medium: numMedium,
  large: numLarge
};

const headingStyle = {
  h1,
  h2,
  h3,
  h4,
  h5
};

export default {
  fontFamily,
  fontSize,
  fontWeights,
  fontStyle,
  bodyStyle,
  headingStyle,
  numeralStyle
};
