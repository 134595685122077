import styled from 'styled-components';
import * as colors from 'Constants/colors';

export default FlightSearchDialog => styled(FlightSearchDialog)`
  max-width: 1110px;
  width: 100%;
  z-index: 98;

  .holder-mobile-search-btn {
    display: flex;
    justify-content: center;
  }

  &.fixed-holder {
    position: fixed;
    top: 214px;
  }

  .noDetailedInReducerUsed {
    position: relative;
  }

  &.detailMode {
    z-index: 1;
  }

  .aire-multidestino {
    height: 15px;
  }

  .mv-buscador-line-details {
    display: flex;
    padding: 12px 4px & > div {
      width: 50%;
      &:first-child: {
        margin-right: ${({ theme }) => theme.spacing.two};
      }
    }

    .plus-minus {
      position: relative;
      top: 3px;
    }

    .pax-calendar-holder {
      display: flex;
    }
    .mv-buscador-locations {
      display: flex;
      align-items: center;
      justify-content: center;
      & > * {
        margin-right: ${({ theme }) => theme.spacing.two};
      }
    }
  }

  .mv-buscador-options {
    justify-content: flex-end;
    display: flex;
    margin-top: 10px;

    & > *:first-child {
      margin-right: 5px;
    }
  }

  .mv-buscador-button {
    bottom: -19px;
    left: 50%;
    margin-left: -46px;
    position: absolute;
  }

  @media only screen and (max-width: 1096px) {
    left: 0;
    margin-left: 0;
  }

  @media only screen and (max-width: 768px) {
    height: 100%;
    left: auto;
    margin-left: 0;
    margin-top: 0;
    max-width: auto;
    padding: 11px 13px 6px;
    position: static;
    top: auto;
    width: 100%;

    .engine-title {
      color: ${colors.darkGrey};
      font-family: Montserrat, Arial, Helvetica, sans-serif;
      font-size: 16px;
      font-stretch: normal;
      font-style: normal;
      font-weight: 600;
      letter-spacing: 0.16px;
      line-height: 0.53;
      margin-bottom: 10px;
      text-align: left;
      text-transform: capitalize;
    }
  }
`;
