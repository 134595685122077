/**
 *
 * DesktopHotelSearchCompact
 *
 */

import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';
import cs from 'classnames';
import * as COLORS from 'Constants/colors';
import { ReactComponent as EditIcon } from './Edit.svg';
import Text from 'Components/Text';
import Icon from 'Components/Icon';
import { totalPeople } from 'Features/hotels';
import { formatLong } from 'Utils/dates';
import { setDetailMode } from 'Features/hotels/actions';

const selector = ({ hotels }) => ({
  checkIn: hotels.search.checkIn,
  checkOut: hotels.search.checkOut,
  location: hotels.search.location,
  roomInformation: hotels.search.roomInformation
});

const ConnectedDesktopHotelSearchCompact = props => {
  const dispatch = useDispatch();
  const actions = {
    setModify: () => {
      dispatch(setDetailMode(false));
    }
  };

  const state = useSelector(selector);

  return (
    <StyledDesktopHotelSearchCompact actions={actions} {...state} {...props} />
  );
};

const DesktopHotelSearchCompact = ({
  className,
  checkIn,
  checkOut,
  roomInformation,
  location,
  actions
}) => (
  <div className={cs(className)}>
    <div className="holder">
      <div className="detail-bar">
        <div className="detail-bar-segment borderRight">
          <div className="detail-bar-segment-item">
            <Icon name="mv-location-2" size={24} />
            <Text>{location.description} </Text>
          </div>
        </div>
        <div className="detail-bar-segment borderRight">
          <div className="detail-bar-segment-item">
            <Icon name="mv-calendar" size={24} />
            <Text>
              {formatLong(checkIn)} - {formatLong(checkOut)}
            </Text>
          </div>
        </div>
        <div className="detail-bar-segment">
          <div className="detail-bar-segment-item">
            <Icon name="mv-user" size={24} />
            <Text>{totalPeople(roomInformation)}</Text>
          </div>
          <div className="detail-bar-segment-item" />
          <div className="detail-bar-segment-item">
            <Icon name="mv-bed-double" size={24} />
            <Text>{roomInformation.length}</Text>
          </div>
        </div>
      </div>
      <MyButton onClick={actions.setModify}>
        <EditIcon />
        MODIFICAR
      </MyButton>
    </div>
  </div>
);

DesktopHotelSearchCompact.propTypes = {
  className: PropTypes.string.isRequired,
  checkIn: PropTypes.string,
  checkOut: PropTypes.string,
  roomInformation: PropTypes.array,
  location: PropTypes.shape({
    placeId: PropTypes.string,
    description: PropTypes.string
  }),
  actions: PropTypes.shape({
    setModify: PropTypes.func.isRequired
  })
};

const MyButton = styled.div`
  display: flex;
  padding: 4px 16px;
  gap: 8px;
  justify-content: center;
  align-items: center;
  border-radius: 50px;
  background: var(--primario, #fb8400);
  box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.25);
  color: var(--blanco-puro, #fff);
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  cursor: pointer;
  height: 40px;
`;

const StyledDesktopHotelSearchCompact = styled(DesktopHotelSearchCompact)`
  padding-top: ${({ theme }) => theme.spacing.two};
  padding-bottom: ${({ theme }) => theme.spacing.two};
  display: flex;
  justify-content: center;
  width: 100%;

  .holder {
    display: flex;
    gap: 10px;
    margin-top: 50px;
    align-items: center;
  }
  .detail-bar {
    display: flex;
    align-items: center;
    gap: 16px;
    min-height: 49px;
    border-radius: 14px;
    box-shadow: 2px 2px 6px 0px rgba(0, 0, 0, 0.25);
    padding: 4px 16px;

    .detail-bar-segment {
      align-items: center;

      display: flex;
      height: 40px;
      margin-right: 8px;
      padding-right: 10px;

      &.borderRight {
        border-right: 3px solid ${COLORS.lightGrey};
      }

      .detail-bar-segment-item {
        display: flex;
        align-items: center;
        margin-right: 8px;

        .icon {
          color: ${COLORS.important};
          display: flex;
          justify-content: center;
          align-items: center;
          margin-right: 10px;
        }
      }

      &:first-child {
        .detail-bar-segment-item {
          width: 100%;
          max-width: 360px;
        }
      }
    }

    .btn-modify {
      display: flex;
    }
  }
`;

export default ConnectedDesktopHotelSearchCompact;
export { StyledDesktopHotelSearchCompact as DesktopHotelSearchCompact };
