// vendor
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

//utils
import useBreakpoints from 'Utils/hooks/useBreakpoints';
import { breakpoints } from 'Constants';
import { handleOnlyDestinationRequest, handleUrlChange } from '../../helpers';
import { hasContentValue } from 'Utils';

// components
import FlightSearchDialog from '../FlightSearchDialog';
import AirAvailability from '../AirAvailability';
import MpcMptbAvailability from '../MpcMptbAvailability';
import SkyLoader from 'Components/SkyLoader';
import BannersContainer from 'Features/banners/components/BannersContainer';
import AirAvailabilityError from '../AirAvailabilityError';
import SearchEngineWrapper from 'Components/SearchEngineWrapper';

// constants
import { statusTypes, whileSearchMessages } from '../../constants';
import { setActive, setCurrentView } from 'Features/destinations';
import Button from 'Components/Button';
import Portal from 'Components/Portal';
import { cleanSearch } from 'Features/flights/actions';
import { resetToEngine } from 'ReduxConfig/availability';
import { mostWantedPackagesRequest } from '../../../common/actions';
import { PackageCommonList } from '../../../packages/components/PackageCommonList';
import { ItauBanner } from 'Components/ItauBanner';
import { ItauPuntosLogin } from 'Components/ItauPuntosLogin';
import { BannerTarjetas } from 'Components/BannerTarjetas';
import { PromoBanners } from 'Components/PromoBanners';

const getAvailability = e => {
  switch (e) {
    case 'vuelos':
      return <AirAvailability />;
    case 'vuelosMPC':
      return <MpcMptbAvailability />;
    default:
      return null;
  }
};
const getContent = (s, e) => {
  switch (s) {
    case statusTypes.loading:
      return <SkyLoader messages={whileSearchMessages} />;
    case statusTypes.ready:
      return getAvailability(e);
    default:
      return null;
  }
};

const selector = ({ flights, availability, router, destinations, common }) => ({
  status: flights.search.status,
  engine: availability.engine,
  router: router,
  error: flights.availability.error,
  mostWantedPackages: common.mostWantedPackages,
  statusReadyPackages: common.status,
  destinationData: {
    active: destinations.active,
    status: destinations.status,
    imageUrl:
      destinations.data.background && destinations.data.background.publicUrl
  }
});

const FlightsHome = () => {
  const dispatch = useDispatch();
  const {
    engine,
    status,
    router,
    error,
    destinationData,
    mostWantedPackages,
    statusReadyPackages
  } = useSelector(selector);
  const { destination } = useParams();
  const isMediumDown = useBreakpoints().mediumDown;
  const showDestination =
    destinationData.active && destinationData.status === 'ready';

  const unused = status === 'unused';
  useEffect(() => {
    handleUrlChange(router, dispatch);
  }, [router]);

  useEffect(() => {
    hasContentValue(destination) &&
      router.location.pathname.indexOf('vuelos-baratos-internacionales') < 0 &&
      handleOnlyDestinationRequest(destination, dispatch);
  }, [destination]);

  useEffect(() => {
    dispatch(setCurrentView(showDestination));
    return () => {
      dispatch(setCurrentView(showDestination));
    };
  }, [showDestination]);

  useEffect(() => {
    dispatch(mostWantedPackagesRequest());
  }, []);

  const disableDestinations = () => {
    dispatch(setActive(false));
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  const hasError = !!error && error !== {};

  const getErrorComponent = () =>
    isMediumDown ? (
      <Portal>
        <div
          style={{
            position: 'absolute',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            zIndex: 5000
          }}>
          <AirAvailabilityError />
        </div>
      </Portal>
    ) : (
      <AirAvailabilityError />
    );

  return (
    <>
      {/* 
      Entiendo que destinations no va
      {showDestination && <SectionBgImages image={destinationData.imageUrl} />}
      {showDestination && <DestinationsTop />}
       */}
      <Container>
        {unused && (
          <TitleHolder>
            <Title>Encontrá el destino que estás buscando.</Title>
            <SubTitle>No dejes de explorar el mundo.</SubTitle>
          </TitleHolder>
        )}
      </Container>
      <SearchEngineWrapper unused={unused || hasError} isMobile={isMediumDown}>
        <FlightSearchDialog />
      </SearchEngineWrapper>
      {hasError ? getErrorComponent() : getContent(status, engine)}

      {unused && <ItauBanner loadBannerHome />}
      {unused && <ItauPuntosLogin />}

      {statusReadyPackages === 'ready' &&
        mostWantedPackages.length > 0 &&
        unused && (
          <PackageCommonList
            packageList={mostWantedPackages}
            dividerTitle={'Los paquetes más pedidos por nuestros viajeros:'}
          />
        )}
      {unused && <PromoBanners />}
      {unused && <BannersContainer />}
      {unused && <BannerTarjetas />}
    </>
  );
};

// Disabling this, should go away when migrating to typescript
// eslint-disable-next-line react/prop-types
const ShowAllFlightsButton = ({ onClick }) => (
  <Container>
    <Button fullWidth onClick={onClick}>
      Ver Más
    </Button>
  </Container>
);

const Title = styled.div`
  margin-top: 111px;
  width: 634px;
  font-family: 'Itau Display Pro';
  font-style: normal;
  font-weight: 700;
  font-size: 60px;
  line-height: 68px;
  /* or 113% */

  display: flex;
  align-items: center;
  color: #ffffff;

  text-shadow: 4px 4px 8px rgba(0, 0, 0, 0.8);
`;

const SubTitle = styled.div`
  margin-top: 20px;
  width: 419px;
  font-family: 'Itau Display Pro';
  font-style: normal;
  font-weight: 700;
  font-size: 32px;
  line-height: 40px;
  display: flex;
  align-items: center;
  color: #fbb702;
  text-shadow: 4px 4px 8px rgba(0, 0, 0, 0.8);
  margin-bottom: 122px;
`;

const Container = styled.div`
  max-width: 1100px;
  width: 100%;
  margin: auto;
  button {
    margin: 0;
  }
`;

const TitleHolder = styled.div`
  @media screen and (max-width: ${breakpoints.medium}px) {
    display: none;
  }
`;

export default FlightsHome;
