import styled from 'styled-components';
import * as COLORS from 'Constants/colors';

export const CardContainer = styled.div`
  display: flex;
  flex-direction: column;
  background-color: ${COLORS.white};
  position: relative;

  .img {
    overflow: hidden;
    width: 100%;
    height: 258px;
    object-fit: cover;
  }
`;

export const ServiceItem = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0px;
`;

export const ServiceIcon = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 10px;
  gap: 10px;
  width: 24px;
  height: 24px;
`;

export const ServiceText = styled.div`
  font-family: 'Itau Display Pro';
  font-style: normal;
  font-weight: 300;
  font-size: 14px;
  line-height: 22px;
  text-align: center;
  color: #282828;
`;

export const ContentButtonSee = styled.div`
  display: flex;
  justify-content: flex-end;
  padding: 10px 20px 10px 20px;
`;

export const ViewMore = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  padding: 10px 30px;
  cursor: pointer;
  width: 109px;
  height: 40px;
  background: #ffffff;
  border: 1px solid #fb8400;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.25);
  border-radius: 8px;
  font-family: 'Itau Display Pro';
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 28px;
  text-align: center;
  color: #ffffff;
  margin: 10px 0 10px 10px;
  padding: 10px 20px 10px 20px;
  background: #fb8400;
`;

export const YellowBoxText = styled.div`
  height: 129px;
  position: absolute;
  bottom: 0;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-end;
  padding: 10px;
  font-family: 'Itau Display Pro';
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 28px;
  text-align: center;
  color: #ffffff;
  height: auto;
  width: 100%;
  background: linear-gradient(0deg, rgba(0,0,0,0.49763655462184875) 0%, rgba(0,212,255,0) 100%);
`;

export const PriceTag = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  font-family: 'Itau Display Pro';
  font-style: normal;
  color: #282828;
`;

export const CurrencyHolder = styled.div`
  display: flex;
  min-width: fit-content;
  margin-right: 6px;
  margin: 0;
`;

export const FromTag = styled.div`
  font-size: 12px;
  font-weight: 200;
  line-height: 12px;
`;

export const Currency = styled.div`
  font-family: Itau Display Pro;
  font-size: 24px;
  font-weight: 700;
  line-height: 32px;
  letter-spacing: 0em;
  text-align: left;
  margin-right: 5px;
`;

export const Price = styled.div`
  min-width: fit-content;
  margin-right: 6px;
  font-family: Itau Display Pro;
  font-size: 24px;
  font-weight: 700;
  line-height: 32px;
  letter-spacing: 0em;
  text-align: left;
`;

export const BasePriceHolder = styled.div`
  align-self: center;
`;
export const Content = styled.div`
  display: flex;
  flex-direction: column;
  width: 107px;
  height: 37px;
`;

export const BaseType = styled.p`
  font-family: Itau Display Pro;
  font-size: 12px;
  font-weight: 400;
  line-height: 15px;
  letter-spacing: 0;
  text-align: left;
  margin-bottom: 5px;
  margin-left: 5px;
  color: #282828;
  width: 69px;
  height: 30px;
  opacity: 30%;
`;

export const From = styled.p`
  font-family: Itau Display Pro;
  font-size: 12px;
  font-weight: 400;
  line-height: 3px;
  letter-spacing: 0;
  text-align: left;
  margin-bottom: 0;
  color: #282828;
  width: 129px;
  height: 10px;
  opacity: 30%;
`;

export const PerPaxPrice = styled.div`
  font-size: 12px;
  line-height: 12px;
  font-weight: 200;
`;

export const BasePrice = styled.div`
  font-size: 12px;
  font-weight: 600;
  line-height: 12px;
`;

export const PromoPriceTag = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 0px;
  gap: 10px;
  font-family: 'Itau Display Pro';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #003399;
  letter-spacing: 0em;
  text-align: left;

  img {
    width: 24px;
  }
`;

export const IconsHolder = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px 0px;
  gap: 0 60px;
  flex-wrap: wrap;
`;

export const PriceHolder = styled.div`
  position: absolute;
  width: 242px;
  min-height: 66px;
  border-radius: 14px 0px;
  padding: 5px 20px;
  background: #ffffff;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

export const BaseMobile = styled.div`
  transition: 0.3s ease-out;
  position: relative;
  cursor: pointer;
  border-radius: 14px;
  overflow: hidden;
  min-height: 386px;
  background-color: white;
  box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.25);
  margin-bottom: 40px;
  border: 1px solid red;

  > a {
    text-decoration: none;
  }

  &:hover {
    transform: translateY(9px);
  }
`;

export const Base = styled.div`
  transition: 0.3s ease-out;
  position: relative;
  cursor: pointer;
  border-radius: 14px;
  overflow: hidden;
  min-height: 386px;
  background-color: white;
  box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.25);
  margin-bottom: 40px;

  > a {
    text-decoration: none;
  }

  &:hover {
    transform: translateY(9px);
  }
`;
