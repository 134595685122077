/**
 *
 * SkyLoader
 *
 */

import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import cs from 'classnames';
import Slider from 'react-slick';

import useBreakpoints from '../../Utils/hooks/useBreakpoints';
import Text from '../Text';

import spinner from './assets/spinner.svg';
import logo from 'Assets/tienda-naranja-viajes-logo-blanco.svg';

import SkyBackground from '../SkyBackground';

import './animations.css';
const skyLoaderSliderSettings = {
  dots: false,
  autoplay: true,
  arrows: false,
  infinite: true,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1
};
const SkyLoader = ({ className, messages = [] }) => {

const { smallDown } = useBreakpoints();
return(
  <div className={cs(className, 'animated fadeIn')}>
  <SkyBackground />

  <div className="spinnerHolder">
    <img alt="spinner" src={spinner} />
  </div>

  <div className="sliderHolder">
    <Slider {...skyLoaderSliderSettings}>
      {messages.map((message, index) => (
        <Text className="itauText" key={index} context="white" size={smallDown ? 'xmlarge' : 'xlarge'}>
          {message}
        </Text>
      ))}
    </Slider>
    <div className="logoHolder container">
      <img alt="logo" className="logo" src={logo} />
    </div>
  </div>
</div>
)
};

SkyLoader.propTypes = {
  className: PropTypes.string.isRequired,
  messages: PropTypes.array
};

export default styled(SkyLoader)`
  position: fixed;
  overflow: hidden;
  width: 100%;
  height: 100%;
  bottom: 0;
  left: 0;
  z-index: 999999999;

  .logoHolder {
    display: flex;
    padding-top: 13px;
    position: relative;
    justify-content: center;
    margin-top: 112px;

    .logo {
      width: 227px;
      position: relative;
    }

    @media only screen and (max-width: 768px) {
      max-width: 100%;
    }
  }

  .spinnerHolder {
    width: 64px;
    height: 64px;
    position: absolute;
    left: calc(50% - 32px);
    top: calc(50% - 100px);
  }

  .sliderHolder {
    position: absolute;
    text-align: center;
    width: 500px;
    left: calc(50% - 250px);
    top: calc(50% - 170px);
    .itauText {
      max-widtg: 300px;
      font-family: 'Itau Display Pro';
      font-style: normal;
      font-weight: 300;
      font-size: 14px;
      line-height: 22px;
      color: #ffffff;
    }
  }

  .globoHolder {
    width: 300px;
    height: 300px;
    position: absolute;
    bottom: 150px;
    left: calc(50% - 150px);
    animation-name: infinite-spinning;
    animation-duration: 12s;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
  }
`;
