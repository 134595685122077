import React from 'react';
import { useSelector } from 'react-redux';

import { LocalPrice, SelectedSegments } from '../../types';
import { ReactComponent as Itau } from '../../assets/Itau.svg';
import { ContentCard } from '../Common';
import { isoDateToString } from 'Utils';

import {
  SideHeader,
  SideContent,
  Divider,
  Title,
  PriceHolder,
  PrecioNaranja,
  TextoAzul
} from './styles';
import moment from 'moment';

const formatMoney = value => value.toLocaleString('en').replaceAll(',', '.');

interface Props {
  selectedSegment?: SelectedSegments;
  pricePerNight: number;
  totalPrice: number;
  hideDesgloce?: boolean;
  isCheckoutMobile?: boolean;
  checkinTime: string;
  checkoutTime: string;
  checkinDate: string;
  checkoutDate: string;
  rooms: any[];
  policy: any[];
  peopleByRoom: any;
}

export const SideDetails = ({
  totalPrice,
  pricePerNight,
  hideDesgloce,
  isCheckoutMobile,
  checkinTime,
  checkoutTime,
  checkinDate,
  checkoutDate,
  rooms,
  policy,
  peopleByRoom
}: Props) => {
  const {
    selectedItauPoints,
    itauPoints: { PYG }
  } = useSelector(
    // @ts-ignore
    state => state.appConfiguration
  );
  
  const total =
    peopleByRoom && peopleByRoom.reduce((t, t1) => ({
      adults: t.adults + t1.adults,
      kids: t.kids + t1.kids
    }));
  const totalPeople = total ? total.adults + total.kids : 0;

  const totalNight = moment(checkoutDate).diff(
    moment(checkinDate),
    'days'
  );

  const costCurrencyInPoints = PYG;
  
  const priceInPoints = totalPrice
    ? totalPrice * costCurrencyInPoints
    : 0;
  const moneyInPoints = selectedItauPoints / costCurrencyInPoints;
  const moneyToComplete = totalPrice
    ? Math.ceil(totalPrice - moneyInPoints)
    : 0;
  const pointsToRender =
    selectedItauPoints <= priceInPoints
      ? selectedItauPoints.toLocaleString('en').replaceAll(',', '.')
      : priceInPoints.toLocaleString('en').replaceAll(',', '.');
  return (
    <div>
      <ContentCard noPaddingBottom={isCheckoutMobile}>
        {!isCheckoutMobile && <SideHeader>DETALLE DE TU COMPRA</SideHeader>}
        <SideContent>
          <div>
            <Title>Fecha de la reservca</Title>
            <Title className='orange'>Inicia</Title>
            <div>{isoDateToString(checkinDate)}</div>
            <Title className='orange'>Finaliza</Title>
            <div>{isoDateToString(checkoutDate)}</div>
          </div>
          <Divider />
          <div>
            <Title>Registrarse</Title>
            <Title className='orange'>Horario de Check In</Title>
            <div>A partir de las {checkinTime} hs</div>
            <Title className='orange'>Horatio de Check Out</Title>
            <div>Hasta las {checkoutTime} hs</div>
          </div>
          <Divider />
          {rooms.map((room, index) => (
            <div key={index}>
              <Title className='orange'>Habitacion {index + 1}</Title>
              {room.occupancy.adults > 0 && <div>{room.occupancy.adults}x Adulto</div>}
              {room.occupancy.chidren > 0 && <div>{room.occupancy.children}x Niño</div>}
            </div>
          ))}

          <Divider />

          <PriceHolder>
            <div> Precio final por noche para {totalPeople} personas</div>
            <PrecioNaranja>PYG {pricePerNight.toLocaleString('en').replaceAll(',', '.')}</PrecioNaranja>
            <Divider />
            <div> Precio por {totalNight} noches</div>
            <PrecioNaranja>PYG {totalPrice.toLocaleString('en').replaceAll(',', '.')}</PrecioNaranja>
            {/* TODO: queda asi, no se si o van a volver a querer por ahora es pa lio y mucha charla. mejor q se vaya ¯\_(ツ)_/¯  */}
            {/* <div className='negro'>ó <TextoAzul>{pointsToRender} puntos <Itau /> <br /> + PYG {moneyToComplete.toLocaleString('en').replaceAll(',', '.')}</TextoAzul></div> */}
          </PriceHolder>
          <Divider />
          <div>
            <Title>Políticas de cancelación</Title>
            {policy && policy.map((pol, index) => (
              <div>Hab {index +1}: {pol.label}</div>
            ))}
            
          </div>
        </SideContent>
      </ContentCard>
    </div>
  );
};
