import React from 'react';
import styled from 'styled-components';

import {
  isoDateToFullDate,
  getAirLineLogo,
  getCabinName,
  isoDateToString
} from 'Utils';
import { excludeAirlineBaggage } from 'Constants';

import { ReactComponent as Close } from './close.svg';
import { ReactComponent as Avion } from '../AirAvailabilityCard/plane.svg';
import { ReactComponent as Mochila } from '../AirAvailabilityCard/mochila.svg';
import { ReactComponent as CarryOn } from '../AirAvailabilityCard/carryon.svg';
import { ReactComponent as Suitcase } from '../AirAvailabilityCard/suitcase.svg';
import { ReactComponent as ItauLogo } from 'Assets/logo_naranja.svg';

export const MobileAirItineraryDetails = ({
  onClose,
  generalSegment,
  pointsToRender,
  price,
  currency,
  moneyToComplete,
  localPrice,
  localCurrency,
  onBuy
}) => {
  const ida = generalSegment[0][0];
  const idaSegments = ida.segments;
  const showSuitcaseIda = !(
    ida.baggage.total === 0 &&
    excludeAirlineBaggage.includes(ida.airLines[0].code)
  );
  const showCarryOnIda = !(ida.baggage.total === 0);

  const calculateReturnFlightDetails = (vuelta) => {
    if (vuelta !== null || vuelta !== undefined) {
      const vueltaSegments = vuelta ? vuelta.segments : null;
      const showSuitcaseVuelta = vuelta
        ? !(
          vuelta.baggage.total === 0 &&
          excludeAirlineBaggage.includes(vuelta.airLines[0].code)
        )
        : null;
      const showCarryOnVuelta = vuelta ? !(vuelta.baggage.total === 0) : null;

      return {
        vueltaSegments,
        showSuitcaseVuelta,
        showCarryOnVuelta,
      };
    } else {
      return [];
    }
  }

  const vuelta = generalSegment?.[1]?.[0] || null;
  const {
    vueltaSegments,
    showSuitcaseVuelta,
    showCarryOnVuelta
  } = calculateReturnFlightDetails(vuelta)

  return (
    <Holder>
      <CloseRow>
        <div onClick={onClose}>
          <Close />
        </div>
      </CloseRow>
      <CaCard>
        {idaSegments.map((segment, index) => (
          <React.Fragment key={index}>
            {index === 0 && (
              <SegmentHeader className="isTop">
                <SegmentType>
                  <Avion /> IDA
                </SegmentType>
                <SegmentDate>
                  {isoDateToFullDate(segment.departureData.flightDate)}
                </SegmentDate>
              </SegmentHeader>
            )}
            {index > 0 && (
              <SeparadorEscala>
                Espera de {ida.scales[index - 1].duration.totalHours}h{' '}
                {ida.scales[index - 1].duration.totalMinutes}min en{' '}
                {ida.scales[index - 1].airport.city.name}{' '}
                {ida.scales[index - 1].changeInfo.changeOfPlane &&
                  '(cambio de avión)'}{' '}
                {ida.scales[index - 1].changeInfo.changeOfAirport &&
                  '(cambio de aeropuerto)'}
              </SeparadorEscala>
            )}
            <FlightDataBox>
              <AirLine>
                <img
                  src={getAirLineLogo(segment.flightInformation.airline.code)}
                  alt={segment.flightInformation.airline.name}
                />
                <span>{segment.flightInformation.airline.name}</span>
              </AirLine>
              <FlightNumber>
                Vuelo {segment.flightInformation.completeFlightNumber}
                {segment.flightInformation.flightNumber}
                <br />
                Clase: {getCabinName(segment.cabin.code)}
              </FlightNumber>
            </FlightDataBox>
            <SegmentBox>
              <SegmentBoxItem>
                <AirportCode>{segment.departureData.airport.code}</AirportCode>
                <DateTime>
                  {isoDateToString(segment.departureData.flightDate)}
                </DateTime>
                <DateTime>{segment.departureData.flightTime}</DateTime>
                <AirportName>{segment.departureData.airport.name}</AirportName>
              </SegmentBoxItem>

              <SegmentBoxItem>
                <AirportCode>{segment.arrivalData.airport.code}</AirportCode>
                <DateTime>
                  {isoDateToString(segment.arrivalData.flightDate)}
                </DateTime>
                <DateTime>{segment.arrivalData.flightTime}</DateTime>
                <AirportName>{segment.arrivalData.airport.name}</AirportName>
              </SegmentBoxItem>
            </SegmentBox>
          </React.Fragment>
        ))}

        {(showCarryOnIda || showSuitcaseIda) && (
          <EquipajeBox>
            <div>Equipaje</div>

            {showSuitcaseIda && (
              <EquipajeRow>
                <div>
                  <Suitcase />
                </div>
                <div>
                  <div>
                    {' '}
                    <span className="titleOrange">
                      {' '}
                      Incluye equipaje de cabina
                    </span>{' '}
                    <br />
                    Debe caber dentro del compartimento superior.
                  </div>
                </div>
              </EquipajeRow>
            )}

            {showCarryOnIda && (
              <EquipajeRow>
                <div>
                  <CarryOn />
                </div>
                <div>
                  <div>
                    {' '}
                    <span className="titleOrange">
                      {' '}
                      Incluye equipaje para despachar
                    </span>{' '}
                    <br />
                    2 valijas por adulto <br />
                    Se despacha en el aeropuerto durante el Check-in.
                  </div>
                </div>
              </EquipajeRow>
            )}
          </EquipajeBox>
        )}

        {(!showCarryOnIda && !showSuitcaseIda) && (
          <EquipajeBox>
            <div>Equipaje</div>
            <EquipajeRow>
              <div>
                <Mochila />
              </div>
              <div>
                <div>
                  {' '}
                  <span className="titleOrange">
                    {' '}
                    Incluye equipaje personal
                  </span>{' '}
                  <br />
                  Debe caber bajo el asiento delantero.
                </div>
              </div>
            </EquipajeRow>
          </EquipajeBox>
        )}
        <SeparadorEscala>
          Duración total de ida {ida.duration.totalHours}h{' '}
          {ida.duration.totalMinutes}min
        </SeparadorEscala>

        {/* LA VUELTA!!!!! */}

        {vuelta !== null && (
          <>
            {vueltaSegments && (
              <React.Fragment>
                {vueltaSegments.map((segment, index) => (
                  <React.Fragment key={index}>
                    {index === 0 && (
                      <SegmentHeader className="isMiddle">
                        <SegmentType>
                          <Avion className="rotated" /> Vuelta
                        </SegmentType>
                        <SegmentDate>
                          {isoDateToFullDate(segment.departureData.flightDate)}
                        </SegmentDate>
                      </SegmentHeader>
                    )}
                    {index > 0 && (
                      <SeparadorEscala>
                        Espera de {vuelta.scales[index - 1].duration.totalHours}h{' '}
                        {vuelta.scales[index - 1].duration.totalMinutes}min en{' '}
                        {vuelta.scales[index - 1].airport.city.name}{' '}
                        {vuelta.scales[index - 1].changeInfo.changeOfPlane &&
                          '(cambio de avión)'}{' '}
                        {vuelta.scales[index - 1].changeInfo.changeOfAirport &&
                          '(cambio de aeropuerto)'}
                      </SeparadorEscala>
                    )}
                    <FlightDataBox>
                      <AirLine>
                        <img
                          src={getAirLineLogo(
                            segment.flightInformation.airline.code
                          )}
                          alt={segment.flightInformation.airline.name}
                        />
                        <span>{segment.flightInformation.airline.name}</span>
                      </AirLine>
                      <FlightNumber>
                        Vuelo {segment.flightInformation.completeFlightNumber}
                        {segment.flightInformation.flightNumber}
                        <br />
                        Clase: {getCabinName(segment.cabin.code)}
                      </FlightNumber>
                    </FlightDataBox>
                    <SegmentBox>
                      <SegmentBoxItem>
                        <AirportCode>
                          {segment.departureData.airport.code}
                        </AirportCode>
                        <DateTime>
                          {isoDateToString(segment.departureData.flightDate)}
                        </DateTime>
                        <DateTime>{segment.departureData.flightTime}</DateTime>
                        <AirportName>
                          {segment.departureData.airport.name}
                        </AirportName>
                      </SegmentBoxItem>

                      <SegmentBoxItem>
                        <AirportCode>
                          {segment.arrivalData.airport.code}
                        </AirportCode>
                        <DateTime>
                          {isoDateToString(segment.arrivalData.flightDate)}
                        </DateTime>
                        <DateTime>{segment.arrivalData.flightTime}</DateTime>
                        <AirportName>
                          {segment.arrivalData.airport.name}
                        </AirportName>
                      </SegmentBoxItem>
                    </SegmentBox>
                  </React.Fragment>
                ))}

                {(showCarryOnVuelta || showSuitcaseVuelta) && (
                  <EquipajeBox>
                    <div>Equipaje</div>

                    {showSuitcaseVuelta && (
                      <EquipajeRow>
                        <div>
                          <Suitcase />
                        </div>
                        <div>
                          <div>
                            {' '}
                            <span className="titleOrange">
                              {' '}
                              Incluye equipaje de cabina
                            </span>{' '}
                            <br />
                            Debe caber dentro del compartimento superior.
                          </div>
                        </div>
                      </EquipajeRow>
                    )}

                    {showCarryOnVuelta && (
                      <EquipajeRow>
                        <div>
                          <CarryOn />
                        </div>
                        <div>
                          <div>
                            {' '}
                            <span className="titleOrange">
                              {' '}
                              Incluye equipaje para despachar
                            </span>{' '}
                            <br />
                            2 valijas por adulto <br />
                            Se despacha en el aeropuerto durante el Check-in.
                          </div>
                        </div>
                      </EquipajeRow>
                    )}
                  </EquipajeBox>
                )}


                {(!showCarryOnVuelta && !showSuitcaseVuelta) && (
                  <EquipajeBox>
                    <div>Equipaje</div>
                    <EquipajeRow>
                      <div>
                        <Mochila />
                      </div>
                      <div>
                        <div>
                          {' '}
                          <span className="titleOrange">
                            {' '}
                            Incluye equipaje personal
                          </span>{' '}
                          <br />
                          Debe caber bajo el asiento delantero .
                        </div>
                      </div>
                    </EquipajeRow>
                  </EquipajeBox>
                )}

                <SeparadorEscala>
                  Duración total de vuelta {vuelta.duration.totalHours}h{' '}
                  {vuelta.duration.totalMinutes}min
                </SeparadorEscala>
              </React.Fragment>
            )}
          </>
        )}

        <PricingHolder>
          <PricingLeftColumn>
            <span>Precio total final</span>
            <span className="dolar">
              {currency} {price}
            </span>
            <span className="grs">
              ({localCurrency} {localPrice})
            </span>
          </PricingLeftColumn>
          <BlueText>
            ó <span>{pointsToRender}</span> puntos <ItauLogo />
            <br />+ <span>USD {moneyToComplete}</span>
          </BlueText>
        </PricingHolder>

        <BuyBotton onClick={onBuy}>Comprar</BuyBotton>
      </CaCard>
    </Holder>
  );
};

const BuyBotton = styled.div`
  cursor: pointer;
  width: calc(100% - 50px);
  margin: 16px 0 24px 0;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 10px 30px;
  background: #fb8400;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.25);
  border-radius: 12px;
  font-family: 'Itau Display Pro';
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 28px;
  text-align: center;
  color: #ffffff;
`;

const BlueText = styled.div`
  font-family: 'Itau Display Pro';
  font-style: normal;
  font-weight: 300;
  font-size: 14px;
  line-height: 22px;
  color: #003399;
  text-align: center;

  span {
    font-weight: 700;
  }

  svg {
    width: 24px;
    height: 24px;
  }
`;

const PricingHolder = styled.div`
  display: block;
  position: relative;
  box-sizing: border-box;
  background: #faf2e3;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 8px 10px;
  margin-top: 16px;
  gap: 46px;
  width: 100%;
  min-height: 92px;
  border-top: 1px solid rgba(40, 40, 40, 0.25);
`;

const PricingLeftColumn = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  padding: 0px;
  width: 127px;
  min-height: 76px;
  font-family: 'Itau Display Pro';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #fb8400;

  span.dolar {
    font-size: 22px;
    line-height: 30px;
  }
  span.grs {
    font-size: 14px;
    line-height: 22px;
  }
`;

const EquipajeRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0px;
  gap: 8px;
  width: calc(100% - 40px);

  .titleOrange {
    color: #fb8400;
  }
`;

const EquipajeBox = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 20px;
  gap: 20px;
  width: calc(100% - 20px);
  border: 1px solid rgba(40, 40, 40, 0.25);
  border-radius: 20px;
  font-family: 'Itau Display Pro';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  color: #282828;
  margin: 16px 0;
`;

const SeparadorEscala = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 10px 4px;
  gap: 10px;
  width: calc(100% - 20px);
  background: rgba(251, 183, 2, 0.5);
  border-radius: 8px;
  font-family: 'Itau Display Pro';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  text-align: center;
  color: #282828;
  margin-top: 16px;
`;

const AirportName = styled.div`
  font-family: 'Itau Display Pro';
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 22px;
  text-align: center;
  color: #282828;
  opacity: 0.3;
`;

const DateTime = styled.div`
  font-family: 'Itau Display Pro';
  font-style: normal;
  font-weight: 300;
  font-size: 14px;
  line-height: 22px;
  color: #282828;
`;

const AirportCode = styled.div`
  font-family: 'Itau Display Pro';
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 22px;
  text-align: center;
  color: #282828;
`;

const SegmentBoxItem = styled.div`
  display: flex;
  max-width: 200px;
  flex-direction: column;
  align-items: center;
  padding: 0px;
  gap: 5px;
`;

const SegmentBox = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  padding: 0px 8px;
  width: 100%;
`;

const FlightNumber = styled.div`
  font-family: 'Itau Display Pro';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  color: rgba(40, 40, 40, 0.5);
`;

const AirLine = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0px;
  gap: 12px;

  width: 168px;
  height: 30px;

  img {
    max-width: 50px;
  }

  span {
    font-family: 'Itau Display Pro';
    font-style: normal;
    font-weight: 300;
    font-size: 14px;
    line-height: 22px;
    color: #282828;
  }
`;

const FlightDataBox = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-top: 16px;
  padding: 0 8px;
  border-bottom: 1px solid rgba(40, 40, 40, 0.1);
`;

const SegmentHeader = styled.div`
  height: 38px;
  background: #faf2e3;
  width: 100%;
  display: flex;
  align-items: center;
  padding: 9px 8px;
  justify-content: space-between;

  &.isTop {
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
  }

  &.isMiddle {
    margin-top: 16px;
  }
`;

const SegmentType = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 0px;
  gap: 8px;
  font-family: 'Itau Display Pro';
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 22px;
  color: #282828;

  svg.rotated {
    transform: rotate(180deg);
  }
`;

const SegmentDate = styled.div`
  font-family: 'Itau Display Pro';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  color: #282828;
`;

const CaCard = styled.div`
  width: 100%;
  position: relative;
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  background: white;
`;

const Holder = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.4);
  width: 100%;
  height: 100%;
  z-index: 1100;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 8px;
  gap: 8px;
  overflow-y: scroll;
`;

const CloseRow = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row-reverse;

  > div {
    cursor: pointer;
    width: 38px;
    height: 38px;
    border-radius: 50%;
    background-color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;

    svg {
      position: absolute;
      width: 40px;
      height: 40px;
      top: -1px;
      left: -1px;
    }
  }
`;
