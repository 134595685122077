import React from 'react';
import cs from 'classnames';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Icon from 'Components/Icon';
import Text from 'Components/Text';

import * as COLORS from 'Constants/colors';

const PackageSearchIcon = ({
  className,
  name,
  icon,
  context = 'default',
  size = 'regular',
  labelPosition = 'top',
  onClick,
  noText
}) => (
  <div className={cs(className)} onClick={onClick}>
    <div className={cs('package-search-icon', { small: size === 'small' })}>
      {!noText && labelPosition === 'top' && (
        <Texto className={context === 'active' && 'active'}>{name}</Texto>
        // <Text
        //   paragraph
        //   size={size}
        //   className="package-search-icon_title"
        //   weight="semi-bold">
        //   {name ? name : ''}
        // </Text>
      )}
      <div className={cs('package-search-icon_container', context, size)}>
        <Icon
          size={(size === 'regular' && 44) || (size === 'small' && 32)}
          color={cs({
            violeta: size === 'small' && context !== 'active',
            white: size !== 'small',
            important: size === 'small' && context === 'active'
          })}
          name={icon}
          className="package-search-icon_icon"
        />
      </div>
      {!noText && labelPosition === 'bottom' && (
        <Texto className={context === 'active' ? 'active' : ''}>{name}</Texto>
        // <Text
        //   paragraph
        //   size={size}
        //   className="package-search-icon_title"
        //   weight="semi-bold"
        //   context={context === 'active' ? 'important' : 'violeta'}>

        // </Text>
      )}
    </div>
  </div>
);

const Texto = styled.div`
  font-family: 'Itau Display Pro';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  display: flex;
  align-items: center;
  text-align: center;
  color: #282828;

  .active {
    color: #fb8400;
  }
`;

PackageSearchIcon.propTypes = {
  className: PropTypes.string.isRequired,
  name: PropTypes.string,
  icon: PropTypes.string.isRequired,
  context: PropTypes.string.isRequired,
  size: PropTypes.string,
  labelPosition: PropTypes.oneOf(['top', 'bottom']),
  onClick: PropTypes.func,
  noText: PropTypes.bool
};

export default styled(PackageSearchIcon)`
  display: inline-flex;

  .package-search-icon {
    cursor: pointer;
    text-align: center;
    width: 120px;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 0 auto;
    position: relative;
    z-index: 3;

    &.small {
      width: 56px;
      height: 56px;
    }

    .package-search-icon_title {
      height: 40px;
      width: 120px;
      display: table-cell;
      vertical-align: middle;
      text-align: center;
      margin: 0 auto 7px auto;
    }

    .package-search-icon_container {
      border-radius: 50%;
      position: relative;
      transition: all 0.3s ease;
      margin-bottom: 8px;

      &.default {
        background: #faf2e3;

        > span {
          color: #fbb702;
        }
      }

      &.active {
        background: #fb8400;
      }

      &.regular {
        width: 86px;
        height: 86px;
        margin-top: 7px;
      }

      &.small {
        width: 56px;
        height: 56px;
        background: transparent;
      }

      &:hover {
        transform: translateY(-4px);
      }
    }

    .package-search-icon_icon {
      position: absolute;
      left: 50%;
      top: 50%;
      line-height: 10px;
      transform: translate(-50%, -50%);
    }
  }
`;
