import React from 'react';
import { useFormContext, RegisterOptions } from 'react-hook-form';

import { FormBox, Label, InputHolder, ErrorText } from '../Common';

interface Props {
  name: string;
  label: string;
  placeholder?: string;
  errorMessage?: string;
  type?: string;
  registerOptions?: RegisterOptions;
}

export const FormInput = ({
  name,
  label,
  placeholder,
  type = 'text',
  registerOptions,
  errorMessage = 'Este campo no debe estar vacio.'
}: Props) => {
  const {
    register,
    formState: { errors }
  } = useFormContext();

  const isMandatory = registerOptions?.required;
  const error = Boolean(errors[name]);

  return (
    <FormBox>
      <Label>
        {label} {isMandatory && <span>*</span>}
      </Label>
      <InputHolder isError={error}>
        <input
          type={type}
          placeholder={placeholder}
          {...register(name, registerOptions)}
        />
      </InputHolder>
      {error && <ErrorText>{errorMessage}</ErrorText>}
    </FormBox>
  );
};
