import React from 'react';
import styled from 'styled-components';
import itau from 'Assets/logo_blanco.svg';
import useBreakpoints from 'Utils/hooks/useBreakpoints';
import { ReactComponent as LogoMobile } from 'Assets/logo_blanco.svg';
import style from 'Features/flights/components/FlightSearchDialog/style';

const Desktop = ({ noMargin = false }) => (
  <Holder noMargin={noMargin}>
    <div className="content-quince">
      <InterestFree>
        <Until>Hasta</Until>
      </InterestFree>
      <Row>
        <Quince>15</Quince>
        <InterestFree>
      </InterestFree>
      </Row>
      <InterestFree>
        <p>cuotas sin intereses con tus tarjetas de crédito Itaú</p>
      </InterestFree>
    </div>

    <img src={itau} alt="itau" />
  </Holder>
);

const BannerHome = ({ noMargin = false }) => (
  <HolderHome noMargin={noMargin}>
  <div className="content-banner-home">
  <div className="content-number-home">
      <UntilPackage>Hasta</UntilPackage>
      <Row>
        <QuinceHome>15</QuinceHome>
      </Row>
      <InterestFreeHome>
      <p>cuotas sin intereses con tarjetas de crédito Itaú</p>
      </InterestFreeHome>
    </div>
    
    <img src={itau} alt="itau" />
  </div>
  </HolderHome>
);

const Row = styled.div`
  display: flex;
  align-items: baseline;
  gap: 10px;
`;

const InterestFree = styled.div`
  font-family: 'Itau Display Pro';
  font-style: normal;
  font-weight: 800;
  font-size: 30px;
  line-height: 30px;
  color: #ffffff;
  text-align: start;
  p {
    margin: 0;
  }
  .credito-itau{
    width:199px;
    font-family: Itau Display Pro;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0;
    text-align: left;
  }
`;

const Until = styled.p`
white-space: nowrap;
padding-left:10px;
`
const InterestFreeHome = styled.div`
  font-family: 'Itau Display Pro';
  font-style: normal;
  font-weight: 800;
  font-size: 27px;
  line-height: 30px;
  color: #ffffff;
  width: 196px;
  text-align: start;
  margin-left: 10px;
  width:100%;
  p {
    margin: 0;
  }
  `;

  const UntilPackage = styled.p`
  font-family: 'Itau Display Pro';
  font-style: normal;
  font-weight: 800;
  font-size: 27px;
  line-height: 30px;
  color: #ffffff;
  white-space: nowrap;
  margin-bottom:0;
  `;
  
const Quince = styled.div`
  width: 100px;
  height: 70px;
  font-family: 'Itau Display Pro';
  font-style: normal;
  font-weight: 700;
  font-size: 100px;
  line-height: 90px;
  display: flex;
  align-items: center;
  text-align: center;
  color: #ffffff;
  margin-left: 25px;
  margin-rigth:5px;
`;

const QuinceHome = styled.div `
width: 123px;
height: 70px;
font-family: 'Itau Display Pro';
font-style: normal;
font-weight: 800;
font-size: 120px;
line-height: 88px;
display: flex;
align-items: center;
text-align: center;
color: #ffffff;
margin-left: 30px;
margin-rigth:15px;
`

const Holder = styled.div<{ noMargin?: boolean }>`
  margin: ${({ noMargin }) => (noMargin ? '20px' : '40px')} 0;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 20px 0px;
  width: 100%;
  min-height: 120px;
  background: #ec7000;
  .content-quince {
    display: flex;
    align-items: center;
    max-width:610px;
  }
`;


const HolderHome = styled.div<{ noMargin?: boolean }>`
margin: ${({ noMargin }) => (noMargin ? '20px' : '40px')} 0;
display: flex;
flex-direction: row;
justify-content: center;
align-items: center;
gap: 150px;
width: 100%;
.content-number-home{
  display:flex;
  flex-direction: row;
  align-items:center
}
.content-banner-home{
  width:997px;
  padding:25px 50px 25px 50px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  background: #ec7000;
}
`;

const MobileContainer = styled.div`
  width: 100%;
  max-width:995px;
  height: 180px;
  padding: 25px 20px 20px;
  display: flex;
  justify-content: center;
  border-radius: 15px;  
`;

const MobileOrangeHolder = styled.div`
  max-width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 10px 16px;
  gap: 21px;
  min-height: 86px;
  background: #ec7000;
  border-radius: 15px;
`;

const MobileInterestFree = styled.div`
  font-family: Itau Display Pro;
  font-size: 14px;
  font-weight: 700;
  line-height: 22px;
  letter-spacing: 0em;
  text-align: center;
  color: #ffffff;
  p {
    margin: 0;
  }
`;

const MobileQuince = styled.div`
  width: 40px;
  height: 41px;
  font-family: 'Itau Display Pro';
  font-style: normal;
  font-weight: 600;
  font-size: 39px;
  line-height: 48px;
  display: flex;
  align-items: center;
  text-align: center;
  color: #ffffff;
  margin-left: 12px;
`;

const MobileRow = styled.div`
  display: flex;
  align-items: center;
  gap: 2px;
`;

const MobileTextBold = styled.div`
  font-family: 'Itau Display Pro';
  font-style: normal;
  font-weight: 700;
  font-size: 11px;
  line-height: 22px;
  display: flex;
  align-items: center;
  color: #ffffff;
  width: 153px;
`;


const Mobile = () => (
  <MobileContainer>
    <MobileOrangeHolder>
      <div>
        <MobileRow>
          <MobileQuince>15</MobileQuince>
        <MobileInterestFree>
          <p>cuotas</p>
          <p>sin intereses</p>
        </MobileInterestFree>
        </MobileRow>
        <MobileTextBold>Con tus tarjetas de crédito Itaú.</MobileTextBold>
      </div>
      <LogoMobile />
    </MobileOrangeHolder>
  </MobileContainer>
);

export const ItauBanner = ({ noMargin = false,loadBannerHome} : {noMargin: boolean, loadBannerHome?:boolean}) => {
  const isMediumDown = useBreakpoints().mediumDown;
  return isMediumDown ? <Mobile /> : loadBannerHome ? <Desktop noMargin={noMargin} /> : <BannerHome noMargin={noMargin} />;
};
