import React, { useEffect, useState } from 'react';

import { Stepper } from '../Stepper';
import { ContentCard } from '../Common';
import { PaymentStep } from '../PaymentStep';
import { BillingFields } from '../BillingFields';
import { PaxFields } from '../PaxFields';
import { SideDetails } from '../SideDetails';

import { ReactComponent as Chevron } from '../../assets/chevron.svg';
import {
  Holder,
  ContentHeader,
  Title,
  Navigation,
  NavButton
} from '../../styles';
import { Steps } from '../../types';
import { useFormContext } from 'react-hook-form';

interface Props {
  activeStep: Steps;
  fields: Record<'id', string>[];
  onRequest: ({
    selectedInterventajasPoints,
    selectedMembershipPoints,
    moneyToComplete,
    pointsToRender
  }) => void;
  onPreviousStep: () => void;
  onNextStep: () => void;
  selectedSegment: any;
  localPrice: any;
}

export const CheckoutDesktop = ({
  activeStep,
  fields,
  onRequest,
  onNextStep,
  onPreviousStep,
  selectedSegment,
  localPrice
}: Props) => {
  const [selectedMembershipPoints, setSelectedMembershipPoints] = useState(0);
  const [selectedInterventajasPoints, setSelectedInterventajasPoints] = useState(0);
  const { setValue } = useFormContext();

  useEffect(() => {
    for (let i = 0; i < fields.length; i++) {
      setValue(`passengers.${i}.docType`, 1);
      setValue(`passengers.${i}.nationality`, 'PRY');
    }
  }, [setValue, fields]);

  return (
    <div className="container">
      <Holder>
        <div>
          <ContentCard>
            <ContentHeader>
              COMPLETÁ TUS DATOS Y FINALIZÁ TU COMPRA
            </ContentHeader>
            <Stepper activeStep={activeStep} />

            {activeStep === Steps.paxData && (
              <>
                <Title>Pasajeros</Title>
                {fields.map((field, index) => (
                  <PaxFields key={field.id} index={index} />
                ))}
              </>
            )}

            {activeStep === Steps.billingData && (
              <>
                <Title>Datos de facturación</Title>
                <BillingFields />
              </>
            )}

            {activeStep === Steps.payment && (
              <PaymentStep onRequest={onRequest} selectedInterventajasPoints={selectedInterventajasPoints} setSelectedInterventajasPoints={setSelectedInterventajasPoints}
                selectedMembershipPoints={selectedMembershipPoints} setSelectedMembershipPoints={setSelectedMembershipPoints} />
            )}

            <Navigation>
              {activeStep !== Steps.paxData ? (
                <NavButton onClick={onPreviousStep}>
                  <Chevron className="rotated" />
                </NavButton>
              ) : (
                <div />
              )}

              {activeStep !== Steps.payment ? (
                <NavButton onClick={onNextStep}>
                  <Chevron />
                </NavButton>
              ) : (
                <div />
              )}
            </Navigation>
          </ContentCard>
        </div>
        <SideDetails
          selectedSegment={selectedSegment}
          localPrice={localPrice}
        />
      </Holder>
    </div>
  );
};
