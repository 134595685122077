/**
 *
 * PackageAvailability
 *
 */

import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import cs from 'classnames';
import Masonry from 'react-masonry-css';

import { useSelector, useDispatch } from 'react-redux';
import PackageAvailabilityCard from '../PackageAvailabilityCard';
import PackageAvailabilityError from '../PackageAvailabilityError';
import { toggleModal } from '../../actions';
import PackageContactForm from '../PackageContactForm';
import Modal from 'Components/Modal';
import Icon from 'Components/Icon';
import useBreakpoints from 'Utils/hooks/useBreakpoints';
import * as COLORS from 'Constants/colors';
import { Helmet } from 'react-helmet';
import { packageSteps } from 'Constants';
import { filterKeys } from 'Features/packages/constants';
import { buildPackageUrl } from 'Features/packages/helpers';
//import { returnImage } from 'Utils';
import { ItauBanner } from 'Components/ItauBanner';

const selector = ({ packages }) => ({
  results: packages.filtered,
  stateWhen: packages.filterData[filterKeys.WHEN],
  showModal: packages.showModal,
  hasResults: packages.results.length > 0,
  activeStep: packages.activeStep
});

export const getPackagePrice = (stateWhen, packageItem) => {
  const pckg = packageItem;
  let price = packageItem.lowerPrice;
  if (stateWhen) {
    const { type, option } = stateWhen;
    if (type && option) {
      switch (type) {
        case 'MONTH': {
          const searchObj = pckg.filterDataToApply.prices.byMonths.find(
            price => price.month === option.id
          );
          price = searchObj ? searchObj.price : { amount: 0, currency: 'USD' };
          break;
        }
        case 'SEASON': {
          const searchObj = pckg.filterDataToApply.prices.bySeasons.find(
            price => price.seasonId === option.id
          );
          price = searchObj ? searchObj.price : { amount: 0, currency: 'USD' };
          break;
        }
        default:
          break;
      }
    }
  }
  const { amount, currency } = price;

  return { amount, currency };
};

const ConnectedPackageAvailability = props => {
  const dispatch = useDispatch();

  const actions = {
    toggleModal: () => dispatch(toggleModal())
  };
  const state = useSelector(selector);

  return <StyledPackageAvailability {...state} {...props} actions={actions} />;
};

const PackageAvailability = ({
  className,
  results,
  actions,
  hasResults,
  showModal,
  activeStep,
  stateWhen
}) => {
  const { mediumDown, largeDown, xlDown } = useBreakpoints();
  const { toggleModal } = actions;

  const returnColumnQuantity = () => {
    if (mediumDown) {
      return 1;
    } else if (largeDown) {
      return 2;
    } else {
      return 3;
    }
  };
  const newListaPaquetes = useMemo(() => {
    const res = [...results];
    const fisrtBlock = res.slice(0, 6);
    const secondBlock = res.slice(6);
    return {
      fisrtBlock,
      secondBlock
    };
  }, [results]);

  return (
    <div
      className={cs(className, {
        mobile: xlDown,
        'two-lines': activeStep === packageSteps[2].id
      })}>
      <Helmet>
        <meta charSet="utf-8" />
        <title>
          Promociones en paquetes vacacionales a todos los destinos del mundo.
          Los Mejores paquetes de viaje para vos | Elegí en MeVuelo
        </title>
        <meta
          name="description"
          content="Los Mejores paquetes de viaje para vos. Promociones en paquetes vacacionales a todos los destinos del mundo. Elegí en MeVuelo."
        />
        <meta
          name="keywords"
          content="paquetes de viaje, promociones en paquetes vacacionales"
        />
      </Helmet>
      <Masonry
        breakpointCols={returnColumnQuantity()}
        className="card-holder"
        columnClassName="card-holder_column">
        {newListaPaquetes.fisrtBlock.map((pckg, index) => (
          <>
            <PackageAvailabilityCard
              key={index}
              image={pckg.imageWebp}
              price={getPackagePrice(stateWhen, pckg).amount}
              currency={getPackagePrice(stateWhen, pckg).currency}
              title={pckg.title}
              icons={pckg.compositeIcons}
              color={pckg.shadowColor ? pckg.shadowColor : COLORS.green}
              duration={pckg.duration}
              travelOn={pckg.travelOn}
              url={buildPackageUrl(pckg.keyword, pckg.id)}
              lowerLocalPrice={pckg.lowerLocalPrice}
              basePrice={pckg.basePrice}
              rating={pckg.rating}
            />
          </>
        ))}
      </Masonry>
      <ItauBanner />
      <Masonry
        breakpointCols={returnColumnQuantity()}
        className="card-holder"
        columnClassName="card-holder_column">
        {newListaPaquetes.secondBlock.map((pckg, index) => (
          <>
            <PackageAvailabilityCard
              key={index}
              image={pckg.imageWebp}
              price={getPackagePrice(stateWhen, pckg).amount}
              currency={getPackagePrice(stateWhen, pckg).currency}
              title={pckg.title}
              icons={pckg.compositeIcons}
              color={pckg.shadowColor ? pckg.shadowColor : COLORS.green}
              duration={pckg.duration}
              travelOn={pckg.travelOn}
              url={buildPackageUrl(pckg.keyword, pckg.id)}
              lowerLocalPrice={pckg.lowerLocalPrice}
              basePrice={pckg.basePrice}
              rating={pckg.rating}
            />
          </>
        ))}
      </Masonry>
      {/* {hasResults && (
        <PackageContactForm
          //twoColumns={!mediumDown}
          title={
            <NotFoundContactTitle>
              <TitleForm>¿No encontraste tú paquete ideal?</TitleForm>
              <SubtitleForm
                size="medium"
                context="important"
                alignment="center">
                {' '}
                Completa este formulario y te lo armamos a medida:
              </SubtitleForm>
            </NotFoundContactTitle>
          }
        />
      )} */}

      {results.length === 0 && hasResults && <PackageAvailabilityError />}
      <Modal show={showModal} transparent onClickOverlay={toggleModal}>
        <ModalWrapper>
          <div className="modal-top-bar">
            <Icon
              name="mv-cross"
              color="white"
              size={24}
              className="button-close"
              onClick={toggleModal}
            />
          </div>
          <PackageContactForm twoColumns={!mediumDown} />
        </ModalWrapper>
      </Modal>
    </div>
  );
};

PackageAvailability.propTypes = {
  className: PropTypes.string,
  activeStep: PropTypes.number,
  stateWhen: PropTypes.any,
  results: PropTypes.arrayOf(
    PropTypes.shape({
      image: PropTypes.string.isRequired,
      id: PropTypes.number.isRequired,
      title: PropTypes.string.isRequired,
      rating: PropTypes.shape({
        name: PropTypes.string,
        tagColor: PropTypes.string
      }),
      lowerPrice: PropTypes.shape({
        currency: PropTypes.string.isRequired,
        amount: PropTypes.number.isRequired
      }).isRequired,
      shadowColor: PropTypes.string.isRequired,
      icons: PropTypes.arrayOf(
        PropTypes.shape({
          icon: PropTypes.string.isRequired,
          name: PropTypes.string.isRequired
        })
      )
    })
  ).isRequired,
  hasResults: PropTypes.bool,
  showModal: PropTypes.bool,
  actions: PropTypes.shape({
    toggleModal: PropTypes.func.isRequired,
    onItemClick: PropTypes.func
  })
};

const ModalWrapper = styled.div`
  max-width: 1110px;
  width: 100%;
  margin: auto;
  background: transparent;

  .modal-top-bar {
    width: 100%;
    justify-content: flex-end;
    display: flex;
  }
`;

const ContentBanner = styled.div`
  border: 1px solid red;
  margin: 10px 0 !important;
  .itau-banner-package {
    margin-top: 0;
  }
`;
const StyledPackageAvailability = styled(PackageAvailability)`
  position: relative;
  margin: 0 auto;
  padding: 15px;
  max-width: 1100px;

  &.mobile {
    top: 0;
    margin-top: 0;
  }

  .card-holder {
    display: flex;
    margin-left: -30px;
    width: auto;
  }

  .card-holder_column {
    padding-left: 30px; /* gutter size */
    background-clip: padding-box;

    & > div {
      margin-bottom: 30px;
    }
  }
`;

export default ConnectedPackageAvailability;
export { StyledPackageAvailability as PackageAvailability };

const NotFoundContactTitle = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
`;

const TitleForm = styled.span`
font-family: Itau Display Pro;
font-size: 32px;
font-weight: 700;
line-height: 40px;
letter-spacing: 0em;
text-align: left;
color: #FB8400;
width: 466px
height: 40px
`;

const SubtitleForm = styled.span`
  font-family: Itau Display Pro;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: left;
  color: #fb8400;
`;
