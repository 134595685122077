import React from 'react';
import styled from 'styled-components';

import visa from 'Assets/black-visa.svg';
import amex from 'Assets/black-amex.svg';
import master from 'Assets/black-master.svg';
import { breakpoints } from 'Constants';

export const BannerTarjetas = () => (
  <Holder>
    <Texto>¡Pagá con cualquiera de estas tarjetas de crédito!</Texto>
    <CardsHolder>
      <img src={visa} alt="visa" />
      <img src={amex} alt="amex" />
      <img src={master} alt="master" />
    </CardsHolder>
  </Holder>
);

const Holder = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 30px 0px;
  gap: 30px;
  width: 100%;
  height: 219.34px;

  @media screen and (max-width: ${breakpoints.medium}px) {
    gap: 20px;
  }
`;

const Texto = styled.div`
  font-family: 'Itau Display Pro';
  font-style: normal;
  font-weight: 700;
  font-size: 32px;
  line-height: 40px;
  display: flex;
  align-items: center;
  text-align: center;
  color: #282828;

  @media screen and (max-width: ${breakpoints.medium}px) {
    gap: 20px;
    font-size: 20px;
    line-height: 28px;
  }
`;

const CardsHolder = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0px;
  gap: 56.51px;
  height: 89.34px;
  @media screen and (max-width: ${breakpoints.medium}px) {
    gap: 20px;
  }
`;
