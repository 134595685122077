import styled from 'styled-components';

export const Form = styled.form`
  width: 100%;
`;

export const NavButton = styled.div`
  width: 60px;
  height: 60px;
  background: #ffffff;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.25);
  border-radius: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  svg.rotated {
    transform: rotate(180deg);
  }
`;

export const Navigation = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 20px;
  margin-bottom: 20px;
  width: 100%;
`;

export const Title = styled.div`
  font-family: 'Itau Display Pro';
  font-style: normal;
  font-weight: 700;
  font-size: 22px;
  line-height: 30px;
  color: #282828;
  width: 100%;
  padding: 0 20px;
  margin-bottom: 20px;
`;

export const ContentHeader = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 10px;
  width: 100%;
  min-height: 44px;
  background: #fb8400;
  font-family: 'Itau Display Pro';
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  color: #ffffff;
`;

export const Holder = styled.div`
  display: grid;
  grid-template-columns: 1fr 257px;
  gap: 35px;
  margin: 60px 0;
`;
