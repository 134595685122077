import ACTION_TYPES from './actionTypes';
import { initialState, emptyRouteValidations } from './constants';
import {
  airSearchTypes,
  airSearchFilterType,
  getInitialStates
} from 'Constants';
import { addDays } from 'date-fns';
import { parseIso, formatIso } from 'Utils/dates';
import { getItemDetailValueByKey } from './helpers';
import { statusTypes } from 'Features/banners/constants';

export default (state = initialState, action) => {
  switch (action.type) {
    case ACTION_TYPES.FLIGHTS_SET_ADULTS:
      return {
        ...state,
        search: {
          ...state.search,
          adults: action.payload
        }
      };

    case ACTION_TYPES.FLIGHTS_SET_CHILDREN:
      return {
        ...state,
        search: {
          ...state.search,
          children: action.payload
        }
      };

    case ACTION_TYPES.FLIGHTS_TOGGLE_MPC:
      return {
        ...state,
        search: {
          ...state.search,
          isMPC: !state.search.isMPC
        }
      };

    case ACTION_TYPES.FLIGHTS_SET_SEARCH_STATUS:
      return {
        ...state,
        search: {
          ...state.search,
          status: action.payload
        }
      };

    case ACTION_TYPES.FLIGHTS_SET_INITIAL_DATE:
      return {
        ...state,
        search: {
          ...state.search,
          routesData: state.search.routesData.map((item, index) => {
            if (index !== action.payload.index) {
              return item;
            }

            if (state.search.type === airSearchTypes.oneWay) {
              return {
                ...item,
                initialDate: action.payload.initialDate,
                endDate: formatIso(
                  addDays(parseIso(action.payload.initialDate), 7)
                )
              };
            }

            return {
              ...item,
              initialDate: action.payload.initialDate
            };
          })
        }
      };

    case ACTION_TYPES.FLIGHTS_SET_END_DATE:
      return {
        ...state,
        search: {
          ...state.search,
          routesData: state.search.routesData.map((item, index) => {
            if (index !== action.payload.index) {
              return item;
            }
            return {
              ...item,
              endDate: action.payload.endDate
            };
          })
        }
      };

    case ACTION_TYPES.FLIGHTS_SET_ORIGIN:
      return {
        ...state,
        search: {
          ...state.search,
          routesData: state.search.routesData.map((item, index) => {
            if (index !== action.payload.index) {
              return item;
            }
            return {
              ...item,
              origin: action.payload.origin,
              originName: action.payload.originName
            };
          })
        }
      };

    case ACTION_TYPES.FLIGHTS_SET_DESTINATION:
      return {
        ...state,
        search: {
          ...state.search,
          routesData: state.search.routesData.map((item, index) => {
            if (index !== action.payload.index) {
              return item;
            }
            return {
              ...item,
              destination: action.payload.destination,
              destinationName: action.payload.destinationName
            };
          })
        }
      };

    case ACTION_TYPES.FLIGHTS_SET_CABIN_CODE:
      return {
        ...state,
        search: {
          ...state.search,
          cabinCode: action.payload
        }
      };

    case ACTION_TYPES.FLIGHTS_SET_TYPE:
      return {
        ...state,
        search: {
          ...state.search,
          type: action.payload
        }
      };

    case ACTION_TYPES.FLIGHTS_SET_CITY_KEYWORD:
      return {
        ...state,
        search: {
          ...state.search,
          cityKeyword: action.payload
        }
      };

    case ACTION_TYPES.FLIGHTS_LOAD_SEARCH_FROM_REQUEST:
      return {
        ...state,
        search: {
          ...state.search,
          routesData: [...state.search.routesData].map((item, index) =>
            action.payload.routesData[index] !== undefined
              ? action.payload.routesData[index]
              : item
          ),
          adults: action.payload.totalAdult,
          children: action.payload.children || [],
          cityKeyword: action.payload.cityKeyword,
          status: statusTypes.ready
        }
      };

    case ACTION_TYPES.FLIGHTS_VALIDATION_FAILURE:
      return {
        ...state,
        search: {
          ...state.search,
          validations: { ...action.payload }
        }
      };

    case ACTION_TYPES.FLIGHTS_RESET_VALIDATIONS:
    case ACTION_TYPES.FLIGHTS_VALIDATION_SUCCESS:
      return {
        ...state,
        search: {
          ...state.search,
          validations: {
            paxDetails: [],
            routesData: state.search.routesData.map(() => emptyRouteValidations)
          }
        }
      };

    case ACTION_TYPES.FLIGHTS_SET_ROUTES:
      return {
        ...state,
        search: {
          ...state.search,
          routesData: action.payload.routesData,
          validations: action.payload.validations
        }
      };

    case 'MPC_AVAILABILITY_SUCCESS':
    case 'MPTB_AVAILABILITY_SUCCESS': {
      const filterType = action.payload.request.filterType;
      const destination = action.payload.request.routesData[0].destination;
      const destinationName =
        action.payload.request.routesData[0].destinationName;
      let type = '';
      switch (filterType) {
        case airSearchFilterType.ONE_WAY:
          type = airSearchTypes.oneWay;
          break;
        case airSearchFilterType.ROUND_TRIP:
          type = airSearchTypes.roundTrip;
          break;
        case airSearchFilterType.MULTIDESTINATION:
          type = airSearchTypes.multi;
          break;
        default:
          break;
      }

      return {
        ...state,
        search: {
          ...state.search,
          routesData: state.search.routesData.map((item, index) => {
            if (index !== 0) {
              return item;
            }
            return {
              ...item,
              destination,
              destinationName
            };
          }),
          type
        }
      };
    }

    case ACTION_TYPES.FLIGHTS_MPTB_AND_MPC_REQUEST:
      return {
        ...state,
        search: {
          ...state.search,
          detailMode: true
        },
        mpcAvailability: {
          ...state.mpcAvailability,
          loading: true,
          error: false
        },
        mptbAvailability: {
          ...state.mptbAvailability,
          loading: true,
          error: false
        }
      };

    case ACTION_TYPES.FLIGHTS_MPTB_AND_MPC_SUCCESS:
      return {
        ...state,
        mpcAvailability: {
          ...state.mpcAvailability,
          data: action.payload.mpcResponse,
          loading: false,
          error: false
        },
        mptbAvailability: {
          ...state.mptbAvailability,
          data: action.payload.mptbResponse,
          loading: false,
          error: false
        },
        search: {
          ...state.search,
          routesData: [...state.search.routesData].map((item, index) =>
            action.payload.mptbResponse.request.routesData[index] !== undefined
              ? action.payload.mptbResponse.request.routesData[index]
              : item
          ),
          adults: action.payload.mptbResponse.request.totalAdult,
          children: action.payload.mptbResponse.request.children || [],
          cityKeyword: action.payload.mptbResponse.request.cityKeyword,
          status: statusTypes.ready
        }
      };

    case ACTION_TYPES.FLIGHTS_MPTB_AND_MPC_FAILURE:
      return {
        ...state,
        mpcAvailability: {
          ...state.mpcAvailability,
          loading: false,
          error: action.payload
        },
        mptbAvailability: {
          ...state.mptbAvailability,
          loading: false,
          error: action.payload
        }
      };

    // LEGACY FROM AVAILABILITY
    case ACTION_TYPES.FLIGHTS_SEARCH_TYPE_FLIGHT: {
      const { availability } = state;
      return {
        ...state,
        availability: {
          ...availability,
          typeFlight: action.payload,
          data: {
            ...availability.data,
            request: {
              ...getInitialStates(action.payload),
              ...availability.data.request
            }
          }
        }
      };
    }
    case ACTION_TYPES.FLIGHTS_MPTB_AVAILABILITY_REQUEST: {
      const { availability } = state;
      return {
        ...state,
        search: {
          ...state.search,
          detailMode: true
        },
        availability: {
          ...availability,
          status: statusTypes.loading,
          error: null,
          selectionError: null
        }
      };
    }

    case ACTION_TYPES.FLIGHTS_MPTB_AVAILABILITY_SUCCESS: {
      const { availability } = state;
      return {
        ...state,
        availability: {
          ...availability,
          resetFilters: true,
          status: statusTypes.ready,
          data: action.payload
        }
      };
    }

    case ACTION_TYPES.FLIGHTS_MPTB_AVAILABILITY_FAILURE: {
      const { availability } = state;
      return {
        ...state,
        search: {
          ...state.search,
          detailMode: false
        },
        availability: {
          ...availability,
          status: statusTypes.unused,
          error: action.payload
        }
      };
    }

    case ACTION_TYPES.FLIGHTS_CHECK_SELECTION_REQUEST: {
      const { availability } = state;
      return {
        ...state,
        availability: {
          ...availability,
          pending: true
        }
      };
    }
    case ACTION_TYPES.FLIGHTS_CHECK_SELECTION_SUCCESS: {
      const { availability } = state;
      return {
        ...state,
        availability: {
          ...availability,
          pending: false,
          checkSelection: action.payload
        }
      };
    }
    case ACTION_TYPES.FLIGHTS_CHECK_SELECTION_FAILURE: {
      const { availability } = state;
      const { selectedSegment, id } = action.payload;
      const newData = { ...availability.data };
      const isMPTB = Array.isArray(newData.response);

      if (isMPTB) {
        const gsIndex = newData.response.findIndex(item => item.id === id);
        const generalSegments =
          gsIndex > -1 ? newData.response[gsIndex].generalSegments : null;

        if (generalSegments) {
          const { ida, vuelta } = selectedSegment;

          if (generalSegments[0].length > 2) {
            generalSegments[0] = generalSegments[0].filter(
              item => item.id !== ida.item.id
            );
            newData.response[gsIndex].generalSegments = generalSegments;
          } else if (vuelta && generalSegments[1] && generalSegments[1].length && generalSegments[1].length > 2) {
            generalSegments[1] = generalSegments[1].filter(
              item => item.id !== vuelta.item.id
            );
            newData.response[gsIndex].generalSegments = generalSegments;
          } else {
            // Coulnt delete just one segment, so remove the entire Card from redux!
            newData.response.splice(gsIndex, 1);
          }
        }
        // else {
        //   return {
        //     ...state,
        //     availability: {
        //       ...availability,
        //       pending: false,
        //       selectionError: true
        //     }
        //   }
        // }
      }

      return isMPTB
        ? {
          ...state,
          availability: {
            ...availability,
            pending: false,
            selectionError: action.payload,
            data: { ...newData }
          }
        }
        : {
          ...state,
          availability: {
            ...availability,
            pending: false,
            selectionError: action.payload
          }
        };
    }

    case ACTION_TYPES.FLIGHTS_AVAILABILITY_RESET_FILTERS: {
      const { availability } = state;
      return {
        ...state,
        availability: {
          ...availability,
          resetFilters: action.payload
        }
      };
    }

    case ACTION_TYPES.FLIGHTS_CLEAN_SEARCH: {
      const { availability } = state;
      return {
        ...state,
        search: {
          ...state.search,
          detailMode: false,
          status: statusTypes.unused
        },
        mpcAvailability: {
          loading: false,
          error: false,
          data: []
        },
        mptbAvailability: {
          loading: false,
          error: false,
          data: []
        },
        availability: {
          ...availability,
          data: {
            ...availability.data,
            response: []
          },
          status: statusTypes.unused,
          error: false
        }
      };
    }

    case ACTION_TYPES.FLIGHTS_SET_DETAIL_MODE: {
      return {
        ...state,
        search: {
          ...state.search,
          detailMode: action.payload
        }
      };
    }

    case ACTION_TYPES.FLIGHTS_CLEAN_ERROR: {
      const { availability } = state;
      return {
        ...state,
        search: {
          ...state.search,
          detailMode: false
        },
        availability: {
          ...availability,
          status: statusTypes.unused,
          error: false
        }
      };
    }

    case ACTION_TYPES.FLIGHTS_MPTB_ITEM_RESEARCH_SUCCESS:
      return {
        ...state,
        mpcAvailability: {
          ...state.mpcAvailability,
          data: {
            ...state.mpcAvailability.data,
            response: {
              ...state.mpcAvailability.data.response,
              detail: [...state.mpcAvailability.data.response.detail].map(
                (rowItems, idxRowPos) =>
                  idxRowPos === action.payload.row
                    ? rowItems.map((colItems, idxColPos) =>
                      idxColPos === action.payload.col
                        ? Object.assign({}, colItems, {
                          research: action.payload.response.length === 0,
                          center:
                            action.payload.row === 3 &&
                            action.payload.col === 3,
                          flightPrice: getItemDetailValueByKey(
                            action.payload.response,
                            'flightPrice'
                          ),
                          markRuleInfoApplied: getItemDetailValueByKey(
                            action.payload.response,
                            'markRuleInfoApplied'
                          ),
                          localPrice: getItemDetailValueByKey(
                            action.payload.response,
                            'localPrice'
                          ),
                          generalSegments: getItemDetailValueByKey(
                            action.payload.response,
                            'generalSegments'
                          )
                        })
                        : colItems
                    )
                    : rowItems
              )
            }
          }
        }
      };

    case ACTION_TYPES.FLIGHTS_MPTB_ITEM_RESEARCH_FAILURE:
      return {
        ...state,
        mpcAvailability: {
          ...state.mpcAvailability,
          data: {
            ...state.mpcAvailability.data,
            response: {
              ...state.mpcAvailability.data.response,
              detail: [...state.mpcAvailability.data.response.detail].map(
                (rowItems, idxRowPos) =>
                  idxRowPos === action.payload.row
                    ? rowItems.map((colItems, idxColPos) =>
                      idxColPos === action.payload.col
                        ? Object.assign({}, colItems, {
                          flightPrice: {}
                        })
                        : colItems
                    )
                    : rowItems
              )
            }
          }
        }
      };

    default:
      return state;
  }
};
