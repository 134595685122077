import React from 'react';
import {
  StyledBox,
  StyledTypography,
  StyledTableContainer,
  StyledTable,
  StyledTableHead,
  StyledTableRow,
  StyledTableCell
} from './PriceTableStyles'; // Asegúrate de ajustar la ruta de importación

const PriceTable = () => {
  return (
    <StyledBox>
      <StyledTypography variant="h6">
        Valor total por habitación por 7 noches de alojamiento:
      </StyledTypography>
      <StyledTableContainer>
        <StyledTable>
          <StyledTableHead>
            <StyledTableRow>
              <StyledTableCell width="20%">CheckIn</StyledTableCell>
              <StyledTableCell width="20%">Doble</StyledTableCell>
              <StyledTableCell width="20%">Doble + Chd hasta 12 años</StyledTableCell>
              <StyledTableCell width="20%">2 adultos + 2 menores hasta 17 años o 3 adultos</StyledTableCell>
              <StyledTableCell width="20%">4 adultos y 2 menores hasta 17 años</StyledTableCell>
            </StyledTableRow>
          </StyledTableHead>
          <tbody>
            <StyledTableRow>
              <StyledTableCell component="th" scope="row">20 de enero</StyledTableCell>
              <StyledTableCell>USD 2.310</StyledTableCell>
              <StyledTableCell>USD 2.975</StyledTableCell>
              <StyledTableCell>USD 3.400</StyledTableCell>
              <StyledTableCell>SOLD OUT</StyledTableCell>
            </StyledTableRow>
            <StyledTableRow>
              <StyledTableCell component="th" scope="row"> 27 de enero</StyledTableCell>
              <StyledTableCell>USD 2.310</StyledTableCell>
              <StyledTableCell>USD 2.975</StyledTableCell>
              <StyledTableCell>USD 3.400</StyledTableCell>
              <StyledTableCell>USD 4.400</StyledTableCell>
            </StyledTableRow>
            <StyledTableRow>
              <StyledTableCell component="th" scope="row"> 10 de febrero</StyledTableCell>
              <StyledTableCell>USD 2.600</StyledTableCell>
              <StyledTableCell>USD 3.350</StyledTableCell>
              <StyledTableCell>USD 3.400</StyledTableCell>
              <StyledTableCell>SOLD OUT</StyledTableCell>
            </StyledTableRow>
            <StyledTableRow>
              <StyledTableCell component="th" scope="row"> 24 de febrero</StyledTableCell>
              <StyledTableCell>USD 2.000</StyledTableCell>
              <StyledTableCell>USD 2.650</StyledTableCell>
              <StyledTableCell>USD 3.200</StyledTableCell>
              <StyledTableCell>USD 4.300</StyledTableCell>
            </StyledTableRow>
            <StyledTableRow>
              <StyledTableCell component="th" scope="row"> 9 de marzo</StyledTableCell>
              <StyledTableCell>USD 1.200</StyledTableCell>
              <StyledTableCell>USD 1.500</StyledTableCell>
              <StyledTableCell>USD 1.800</StyledTableCell>
              <StyledTableCell>USD 2.600</StyledTableCell>
            </StyledTableRow>
            <StyledTableRow>
              <StyledTableCell component="th" scope="row"> 16 de marzo</StyledTableCell>
              <StyledTableCell>USD 1.200</StyledTableCell>
              <StyledTableCell>USD 1.500</StyledTableCell>
              <StyledTableCell>USD 1.800</StyledTableCell>
              <StyledTableCell>USD 2.600</StyledTableCell>
            </StyledTableRow>
            <StyledTableRow>
              <StyledTableCell component="th" scope="row"> 23 de marzo</StyledTableCell>
              <StyledTableCell>USD 2.100</StyledTableCell>
              <StyledTableCell>USD 2.600</StyledTableCell>
              <StyledTableCell>USD 3.100</StyledTableCell>
              <StyledTableCell>USD 4.200</StyledTableCell>
            </StyledTableRow>
          </tbody>
        </StyledTable>
      </StyledTableContainer>
    </StyledBox>
  );
};

export default PriceTable;
