/**
 *
 * AirFilter
 *
 */

import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import cs from 'classnames';
import FilterHolder from 'Components/FilterHolder';
import Slider from 'Components/Slider';
import FilterRadioItem from 'Components/FilterRadioItem';
import FilterCheckboxImageItem from 'Components/FilterCheckboxImageItem';
import Checkbox from 'Components/Checkbox';
import {
  getScaleLabel,
  getBaggageLabel,
  getCabinName
} from '../../../../Utils';

const AirFilter = ({
  className,
  fareSliderDetails,
  filtersData,
  handleFareFilterChange,
  handleClickLuggageFilter,
  handleClickScalesFilter,
  handleAirlineFilter,
  handleAllianceFilter,
  handleClassFilter,
  handleNoAirportChange,
  handleFlexFilterChange,
  filters
}) => {
  const {
    alliances,
    airlines,
    scales,
    cabins,
    baggage,
    totalFlex
  } = filtersData;
  const { minFare, maxFare, currency } = fareSliderDetails;

  return (
    <div className={cs(className)}>
      {totalFlex && (
        <FilterHolder active title="Flex">
          <Checkbox checked={filters.hasFlex} onClick={handleFlexFilterChange}>
            Tarifa Flexible
          </Checkbox>
        </FilterHolder>
      )}
      <FilterHolder active title="Escalas">
        {Object.keys(scales).map((scale, index) => (
          <FilterRadioItem
            badgeText={scales[scale]}
            label={getScaleLabel(scale)}
            key={index}
            value={scale}
            onClick={handleClickScalesFilter}
            checked={filters.scales === scale}
          />
        ))}
        <div className="checkbox">
          <Checkbox
            checked={filters.airPortChange === false}
            onClick={handleNoAirportChange}>
            Sin cambio de aeropuerto
          </Checkbox>
        </div>
      </FilterHolder>
      <FilterHolder title="Equipaje" active>
        {Object.keys(baggage).map((b, i) => (
          <FilterRadioItem
            badgeText={baggage[b]}
            label={getBaggageLabel(b)}
            key={i}
            value={b}
            onClick={handleClickLuggageFilter}
            checked={filters.luggageFilter === b}
          />
        ))}
      </FilterHolder>
      <FilterHolder title="Precio" active>
        <Slider
          min={minFare}
          max={maxFare}
          currency={currency}
          onChange={handleFareFilterChange}
          defaultValue={[minFare, maxFare]}
          className="slider"
        />
      </FilterHolder>
      <FilterHolder title="Aerolíneas" active>
        {airlines.map(({ airline, total }, index) => (
          <FilterCheckboxImageItem
            checked={
              filters.airLinesFilter &&
              filters.airLinesFilter.includes(airline.code)
            }
            name={airline.name}
            key={index}
            airlineCode={airline.code}
            value={airline.code}
            onClick={handleAirlineFilter}
            badgeText={total}
          />
        ))}
      </FilterHolder>
      <FilterHolder title="Clase de Servicio" active>
        {cabins.map((cabin, index) => (
          <FilterCheckboxImageItem
            checked={
              filters.serviceClassFilter &&
              filters.serviceClassFilter.includes(cabin.cabinCode)
            }
            onClick={handleClassFilter}
            value={cabin.cabinCode}
            key={index}
            name={getCabinName(cabin.cabinCode)}
          />
        ))}
      </FilterHolder>
      <FilterHolder title="Alianzas" active>
        {alliances.map(({ alliance, total }, index) => (
          <FilterCheckboxImageItem
            key={index}
            checked={
              filters.alliancesFilter &&
              filters.alliancesFilter.includes(alliance.id)
            }
            onClick={handleAllianceFilter}
            value={alliance.id}
            name={alliance.name}
            imgAlt={alliance.name}
            imgSrc={alliance.image}
            badgeText={total}
          />
        ))}
      </FilterHolder>
    </div>
  );
};
AirFilter.propTypes = {
  className: PropTypes.string.isRequired,
  filtersData: PropTypes.object.isRequired,
  fareSliderDetails: PropTypes.object.isRequired,
  handleFareFilterChange: PropTypes.func.isRequired,
  handleClickLuggageFilter: PropTypes.func.isRequired,
  handleClickScalesFilter: PropTypes.func.isRequired,
  handleAirlineFilter: PropTypes.func.isRequired,
  handleAllianceFilter: PropTypes.func.isRequired,
  handleClassFilter: PropTypes.func.isRequired,
  handleNoAirportChange: PropTypes.func.isRequired,
  handleFlexFilterChange: PropTypes.func.isRequired,
  filters: PropTypes.object.isRequired
};

export default styled(AirFilter)`
  display: block;
  background: #faf2e3;
  border-radius: 0px 20px 20px 0px;
  max-width: 281px;
  padding: 12px;
`;
