// vendor
import React from 'react';
import styled from 'styled-components';
import useBreakpoints from 'Utils/hooks/useBreakpoints';

// components
import PackageSearch from '../PackageSearch';
import PackageAvalability from '../PackageAvailability';
import { PaymentPromoBanner } from '../../../../Components/PaymentPromoBanner';
import { ItauBanner } from 'Components/ItauBanner';
import { ItauPuntosLogin } from 'Components/ItauPuntosLogin';
import { PacketFilterButton } from 'Components/PacketFilterButton';

const PackagesHome = () => {
  const { mediumDown } = useBreakpoints();

  return (
    <>
      <SearchWrapper isMobile={mediumDown}>
        <PackageSearch />
      </SearchWrapper>
      {!mediumDown ? (
        <ContentFilter>
          <ItauPuntosLogin />
          <PacketFilterButton />
        </ContentFilter>
      ) : (
        <ItauPuntosLogin />
      )}
      {/* <PaymentPromoBanner /> */}
      <PackageAvalability />
    </>
  );
};

export default PackagesHome;

const SearchWrapper = styled.div`
display:flex;
justify-content:center;
  margin: 0 auto;
  max-width: 1100px;
  margin-top: 15px;
  box-shadow: ${props =>
    props.isMobile === true ? '0' : '0px 4px 8px 0px #00000040'}
    ;
    
  ${({ theme, isMobile }) => {
    if (!isMobile) {
      const margin = theme.spacing.four;
      return `
        margin-bottom: ${margin}
      `;
    }
  }};
`;

const ContentFilter = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: flex-end;
  margin: 150px 0 20px 0;
  max-width: 1530px;
  width: 100%;
  padding: 0 10px;
`;
