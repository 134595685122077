/**
 *
 * PaxDetailAir
 *
 */

import React, { useRef, useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import cs from 'classnames';
import Text from 'Components/Text';
import PlusMinusSelector from 'Components/PlusMinusSelector';
import Select from 'Components/Select';
import Button from 'Components/Button';
import { classItems, classItemsSelect, edadesOpciones } from 'Constants/mocks';
import * as COLORS from 'Constants/colors';
import useOutsideClick from '../../../../Utils/hooks/useOutsideClick';
import { types } from 'Features/hotels';
import Label from 'Components/Label';
import pax from 'Assets/pax.svg';
import Checkbox from 'Components/Checkbox';

const PaxDetailAir = ({ className, adults, kids, cabinCode, actions }) => {
  const [openMenu, setOpenMenu] = useState(false);
  const refOpenMenu = useRef();

  useOutsideClick(refOpenMenu, () => {
    if (openMenu) setOpenMenu(false);
  });

  const toggleMenu = () => {
    setOpenMenu(!openMenu);
  };
  const totalPassengers = adults + kids.length;

  const {
    onChangeAdults,
    onChangeChildren,
    onChangeChildrenAge,
    onToggleChildrenSeat,
    onChangeCabinCode
  } = actions;

  return (
    <div className={cs(className)}>
      <div className="mv-input" onClick={toggleMenu}>
        <Label color="orange">Pasajeros y Clase</Label>

        <InputHolder>
          <img src={pax} alt="pax" />
          <DescriptionText>
            {' '}
            {totalPassengers > 1
              ? `${totalPassengers} Pasajeros`
              : '1 Pasajero'}
            {cabinCode
              ? `, ${
                  classItems.filter(classItem => classItem.id === cabinCode)[0]
                    .name
                }`
              : ''}
          </DescriptionText>
        </InputHolder>
      </div>
      {openMenu && (
        <div className="mv-pax-detail_popup-holder" ref={refOpenMenu}>
          <div className="divider-popup padding-popup">
            <img src={pax} alt="pax" className="padding-right" />
            <DescriptionText>Pasajeros</DescriptionText>
          </div>
          <div className="form-group padding-popup">
            <div className="pax-label-holder">
              <InfoText>Adultos: </InfoText>
              <br />
              <InfoText important>12 ó más años*</InfoText>
            </div>
            <div>
              <StyledPlusMinusSelector
                stateless
                onIncrement={onChangeAdults(types.INCREMENT)}
                onDecrement={onChangeAdults(types.DECREMENT)}
                value={adults}
                minValue={1}
              />
            </div>
          </div>

          <div className="form-group padding-popup">
            <div className="pax-label-holder">
              <InfoText>Menores: </InfoText>
              <br />
              <InfoText important>Hasta los 11 años*</InfoText>
            </div>
            <div>
              <StyledPlusMinusSelector
                stateless
                onIncrement={onChangeChildren(types.INCREMENT)}
                onDecrement={onChangeChildren(types.DECREMENT)}
                value={kids.length}
                minValue={0}
              />
            </div>
          </div>

          {kids.map((childAge, index) => (
            <div key={index} className="form-group padding-popup">
              <div className="pax-label-holder">
                <InfoText>Edad del menor: </InfoText>
              </div>
              <div className="selector">
                <StyledSelect
                  selectedValue={edadesOpciones[0].id}
                  items={edadesOpciones}
                  onSelect={onChangeChildrenAge(index)}
                />
                {childAge.age <= 1 && (
                  <div>
                    <InfoText important>Tarifa de bebé.</InfoText>
                    <div className="flex">
                      <InfoText important>¿Requiere asiento?</InfoText>
                      <NewCheckbox
                        className="custom-icon"
                        checked={childAge.seat}
                        onClick={onToggleChildrenSeat(index)}></NewCheckbox>
                    </div>
                  </div>
                )}
                {childAge.age > 1 && childAge.age <= 11 && (
                  <InfoText important>Tarifa de niño</InfoText>
                )}
                {childAge.age > 11 && childAge.age <= 17 && (
                  <InfoText important>Tarifa de adulto</InfoText>
                )}
              </div>
            </div>
          ))}

          {/* <div className="divider-popup padding-popup">Clase</div>
          <div className="padding-popup margin-class">
            <Select
              selectedValue={cabinCode}
              items={classItemsSelect}
              onSelect={onChangeCabinCode}
            />
          </div> */}
          <div className="apply-btn-holder">
            <InfoText important>*Al momento de finalizar el viaje</InfoText>
          </div>
          <div className="apply-btn-holder">
            <StyledButton weight="semi-bold" onClick={toggleMenu}>
              Aplicar
            </StyledButton>
          </div>
        </div>
      )}
    </div>
  );
};

PaxDetailAir.propTypes = {
  className: PropTypes.string.isRequired,
  actions: PropTypes.shape({
    onChangeAdults: PropTypes.func.isRequired,
    onChangeChildren: PropTypes.func.isRequired,
    onChangeChildrenAge: PropTypes.func.isRequired,
    onToggleChildrenSeat: PropTypes.func.isRequired,
    onChangeCabinCode: PropTypes.func.isRequired
  }),
  adults: PropTypes.number.isRequired,
  kids: PropTypes.arrayOf(
    PropTypes.shape({
      age: PropTypes.number,
      seat: PropTypes.bool
    })
  ),
  cabinCode: PropTypes.number
};

const NewCheckbox = styled(Checkbox)`
  padding-left: 5px;
  display: inline;
`;

const StyledSelect = styled(Select)`
  padding-bottom: 10px;
  .mv-select-container {
    .selected-value-holder {
      font-weight: 300;
      font-size: 13px;
    }
  }
  .mv-select-options {
    font-weight: 300;
    font-size: 13px;
  }
`;

const StyledButton = styled(Button)`
  width: 204px;
  height: 40px;
  border-radius: 6px;
`;

const StyledPlusMinusSelector = styled(PlusMinusSelector)`
  border-radius: 50px;
  width: 125px;
  .action-btn {
    width: 38px;
    border-radius: 100px;
  }
`;

const InputHolder = styled.div`
  height: 40px;
  background-color: ${({ theme }) => theme.colors.white};
  border-radius: 6px;
  padding: 10px 16px;
  box-sizing: border-box;
  box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.25);
  display: flex;
  gap: 8px;
`;

const InfoText = styled.div`
  font-family: 'Itau Display Pro';
  font-style: normal;
  font-weight: 300;
  font-size: 14px;
  position: ${props => (props.important ? 'relative' : 'absolute')};
  color: ${props => (props.important ? '#F69339' : '#282828')};
`;

const DescriptionText = styled.div`
  font-family: 'Itau Display Pro';
  font-style: normal;
  font-weight: 300;
  font-size: 14px;
  line-height: 22px;
  color: #282828;
`;

export default styled(PaxDetailAir)`
  width: 100%;
  margin-left: 10px;

  .flex {
    display: flex;
  }

  .mv-pax-detail_popup-holder {
    width: 290px;
    box-shadow: ${COLORS.shadows.one};
    border-bottom-left-radius: 14px;
    border-bottom-right-radius: 14px;
    padding-top: 13px;
    position: absolute;
    background: white;
    right: 0;
    z-index: 2;
  }

  .selector {
    max-width: 150px;
  }

  .form-group {
    display: flex;
    justify-content: space-between;
    margin-top: 7px;
  }

  .padding-popup {
    padding: 0 18px;
  }

  .padding-right {
    padding-right: 10px;
  }

  .margin-class {
    margin-top: 10px;
    margin-bottom: 10px;
  }

  .apply-btn-holder {
    height: 50px;
    background-color: #ffffff;
    border-bottom-left-radius: 14px;
    border-bottom-right-radius: 14px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .divider-popup {
    background-color: #faf2e3;
    color: ${COLORS.secondary};
    font-size: 16px;
    display: flex;
    padding: 10px;
  }

  .tariff {
    margin-top: 5px;
  }

  .custom-icon {
    .StyledcheckbNeware {
      .icon {
        position: absolute;
        bottom: -7px;
        left: -1px;
        font-size: 20px;
      }
    }
  }
`;
