import React from 'react';
import { render } from 'react-dom';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import { ThemeProvider } from 'styled-components';
import { ToastContainer, Zoom } from 'react-toastify';
import { store, persistor } from 'ReduxConfig/store';
import ReactGA from "react-ga4";
import App from 'Features/app/components/AppRouter';
import TagManager from 'react-gtm-module';
import theme from 'Theme';
import * as Sentry from '@sentry/react';
import packageJson from '../package.json'

import 'react-toastify/dist/ReactToastify.min.css';
import 'sanitize.css/sanitize.css';
import './index.css';

const tagManagerArgs = {
  gtmId: 'GTM-P4N8GF5W'
};

TagManager.initialize(tagManagerArgs);

const trackingId= process.env.REACT_APP_GA4_ID

trackingId && ReactGA.initialize(trackingId);

const target = document.querySelector('#root');

    if(process.env.NODE_ENV === 'production'){
       //sentry
     Sentry.init({
      dsn: "https://270873e7a19f67f90a62f16287a97160@o4505947096875008.ingest.sentry.io/4505965557383168",
      release: packageJson.version,
      integrations: [
        new Sentry.BrowserTracing({
          // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
          tracePropagationTargets: [
            "localhost",
            'https://viajes-naranja-testing.travelonux.com/',
            'https://viajes.tiendanaranja.com.py/'
          ],
        }),
        new Sentry.Replay(),
      ],
      // Performance Monitoring
      tracesSampleRate: 1.0, // Capture 100% of the transactions, reduce in production!
      // Session Replay
      replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
      replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
    });
    }

render(
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <ThemeProvider theme={theme}>
        <App />
      </ThemeProvider>

      <ToastContainer
        draggable
        pauseOnHover
        newestOnTop
        position="bottom-center"
        transition={Zoom}
        autoClose={false}
      />
    </PersistGate>
  </Provider>,
  target
);
