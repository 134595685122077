import React from 'react';
import styled from 'styled-components';
import { breakpoints } from 'Constants';

interface ContainerProps {
  backgroundColor: string;
}

const Container = styled.div<ContainerProps>`
  width: 100%;
  background-color: ${props => props.backgroundColor};
  text-align: center;
  margin-top: 8px;
`;

const Logo = styled.img`
  margin: 8px;
  width: 100px; 

  @media (max-width: ${breakpoints.small}px) {
    width: 60px;
  }
`;

interface LogoBannerProps {
  backgroundColor: string;
  logo: string;
}

const LogoBanner: React.FC<LogoBannerProps> = ({ backgroundColor, logo }) => {
  return (
    <Container backgroundColor={backgroundColor}>
      <Logo src={logo} alt="Logo" />
    </Container>
  );
};

export default LogoBanner;
