/**
 *
 * HotelCard
 *
 */

import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import cs from 'classnames';
import { useSelector } from 'react-redux';

import StarsFilter from 'Components/StarsFilter';
import RatingMolecule from 'Components/RatingMolecule';
import Button from 'Components/Button';
import * as COLORS from 'Constants/colors';
import Icon from 'Components/Icon';
import Tooltip from 'Components/Tooltip';
import Text from 'Components/Text';
import Badge from 'Components/Badge';
import LazyImg from 'Components/LazyImg';

import { textTruncate } from 'Utils';
import { toMoney } from 'Utils/transforms';
import logoItau from 'Assets/logo_naranja.svg';

import hotelGeneric from 'Assets/images/Hotel-Generic.jpg';
import allInIcon from 'Assets/images/shape-2913.png';

const PrecioPorPersonaText = styled.div`
  color: #282828;
  text-align: center;
  font-family: Itau Display Pro;
  font-size: 14px;
  font-style: normal;
  font-weight: 300;
  line-height: 22px;
`;

const PrecioUSD = styled.div`
  color: #fb8400;
  text-align: center;
  font-family: Itau Display Pro;
  font-size: 22px;
  font-style: normal;
  font-weight: 800;
  line-height: 30px;
`;

const TextoItau = styled.span`
  color: #039;
  font-family: Itau Display Pro;
  font-size: 22px;
  font-style: normal;
  font-weight: 400;
  line-height: 30px;
`;

const HotelCard = ({
  className,
  address,
  starRating,
  keyRating,
  textRating,
  wifi,
  parking,
  pool,
  breakfast,
  isAllInclusive,
  propertyName,
  currency,
  price,
  priceByNight,
  localCurrency,
  localNightPrice,
  localPrice,
  totalNight,
  totalPeople,
  onSelect,
  thumbnail,
  airConditioning,
  gym,
  reviews,
  small,
  noLazy,
  isMap
}) => {
  const iconSize = small ? 20 : 32;
  const {
    selectedItauPoints,
    itauPoints: { USD }
  } = useSelector(state => state.appConfiguration);

  const costCurrencyInPoints = USD;
  const priceInPoints = Math.ceil(price / costCurrencyInPoints);
  const moneyInPoints = selectedItauPoints * costCurrencyInPoints;
  const moneyToComplete = Math.ceil((price - moneyInPoints) * 100) / 100;
  const pointsToRender =
    selectedItauPoints <= priceInPoints
      ? selectedItauPoints.toLocaleString('en').replaceAll(',', '.')
      : priceInPoints.toLocaleString('en').replaceAll(',', '.');

  const totalPriceInPoints = Math.ceil(priceByNight / costCurrencyInPoints);
  const totalMoneyInPoints = selectedItauPoints * costCurrencyInPoints;
  const totalMoneyToComplete =
    Math.ceil((priceByNight - totalMoneyInPoints) * 100) / 100;

  const totalPointsToRender =
    selectedItauPoints <= totalPriceInPoints
      ? selectedItauPoints.toLocaleString('en').replaceAll(',', '.')
      : totalPriceInPoints.toLocaleString('en').replaceAll(',', '.');

  return (
    <div className={cs(className, { small })} onClick={onSelect}>
      <div className={cs('hotel-photo', { small })}>
        <LazyImg
          src={thumbnail}
          alt={propertyName}
          className="img"
          skip={noLazy}
          imgFallback={hotelGeneric}
        />
        {isAllInclusive && (
          <div className="all-inclusive-tag">
            <img src={allInIcon} className="all-in-icon" alt={propertyName} />
            <Text context="white">All Inclusive</Text>
          </div>
        )}
      </div>

      <div className="details">
        <HotelTitle>
          {isMap ? textTruncate(propertyName, 23) : propertyName}
        </HotelTitle>

        {starRating && <StarsFilter justDisplay initialValue={starRating} />}
        <div className="location-container">
          <Icon size={small ? 15 : 20} color="important" name="mv-location-2" />
          <Text size={small && 'small'}>{address}</Text>
        </div>
        {keyRating && (
          <StarsFilter justDisplay initialValue={keyRating} isKey={keyRating} />
        )}
        {textRating && (
          <Badge variation="yellow" inverted className="mr-auto">
            {textRating}
          </Badge>
        )}

        {reviews && (
          <div className="margin-top">
            <RatingMolecule
              rating={reviews.rating}
              amountReviews={reviews.amountReviews}
              ratingText={reviews.ratingText}
              inline={small}
            />
          </div>
        )}

        <div className="details-row">
          <Icon
            size={iconSize}
            color={wifi ? 'important' : 'default'}
            name="wifi"
          />
          <Icon
            size={iconSize}
            color={parking ? 'important' : 'default'}
            name="mv-car-parking"
          />
          <Icon
            size={iconSize}
            color={pool ? 'important' : 'default'}
            name="mv-swimming-pool"
          />
          <Icon
            size={iconSize}
            color={breakfast ? 'important' : 'default'}
            name="mv-breakfast"
          />
          <Icon
            size={iconSize}
            color={airConditioning ? 'important' : 'default'}
            name="mv-sun"
          />
          <Icon
            size={iconSize}
            color={gym ? 'important' : 'default'}
            name="mv-gym"
          />
        </div>
      </div>

      <div className="grey-divider" />

      {small ? (
        <div className="price-container">
          <Text context="important" size="small" alignment="center">
            Precio final por noche para {totalPeople}{' '}
            {totalPeople > 1 ? 'personas' : 'persona'}
          </Text>
          <Text context="important" weight="semi-bold" size="small">
            Desde {currency}
          </Text>
          {localNightPrice ? (
            <Tooltip
              placement="top"
              text={toMoney(localNightPrice, localCurrency)}>
              <div>
                <Text context="important" weight="bold" size="xlarge">
                  {price}
                </Text>
              </div>
            </Tooltip>
          ) : (
            <div>
              <Text context="important" weight="bold" size="xlarge">
                {price}
              </Text>
            </div>
          )}
          <Button size="xs" fullWidth onClick={onSelect}>
            Ver Oferta
          </Button>
          {localPrice ? (
            <Tooltip
              placement="bottom"
              text={toMoney(localPrice, localCurrency)}>
              <div className="prices-footer">
                <Text size="small">
                  Precio por {totalNight} {totalNight > 1 ? 'noches' : 'noche'}
                </Text>
                <Text size="small">
                  {currency} {priceByNight}
                </Text>
              </div>
            </Tooltip>
          ) : (
            <div className="prices-footer">
              <Text size="small">
                Precio por {totalNight} {totalNight > 1 ? 'noches' : 'noche'}
              </Text>
              <Text size="small">
                {currency} {priceByNight}
              </Text>
            </div>
          )}
        </div>
      ) : (
        <div className="price-container">
          <div className="content-points-itau">
            <PrecioPorPersonaText>
              Precio final por noche para {totalPeople}{' '}
              {totalPeople > 1 ? 'personas' : 'persona'}
            </PrecioPorPersonaText>
            <PrecioUSD>USD {price}</PrecioUSD>
            <div className='itau-points-card'>
              ó <TextoItau>{pointsToRender} puntos</TextoItau>{' '}
              <img src={logoItau} alt="itau" />{' '}
              <TextoItau>
                + USD {moneyToComplete < 0 ? 0 : moneyToComplete}
              </TextoItau>{' '}
            </div>
          </div>

          {totalNight > 1 && (
            <div>
              <PrecioPorPersonaText>
                Precio por {totalNight} noches
              </PrecioPorPersonaText>
              <PrecioUSD>USD {priceByNight}</PrecioUSD>
              <div>
                ó <TextoItau>{totalPointsToRender} puntos</TextoItau>{' '}
                <TextoItau>
                  + USD {totalMoneyToComplete < 0 ? 0 : totalMoneyToComplete}
                </TextoItau>{' '}
              </div>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

HotelCard.propTypes = {
  className: PropTypes.string.isRequired,
  starRating: PropTypes.number,
  keyRating: PropTypes.number,
  textRating: PropTypes.string,
  wifi: PropTypes.any,
  parking: PropTypes.any,
  pool: PropTypes.any,
  breakfast: PropTypes.any,
  isAllInclusive: PropTypes.any,
  propertyName: PropTypes.any,
  onSelect: PropTypes.any,
  thumbnail: PropTypes.any,
  address: PropTypes.any,
  airConditioning: PropTypes.bool,
  gym: PropTypes.bool,
  currency: PropTypes.any,
  price: PropTypes.any,
  priceByNight: PropTypes.any,
  localCurrency: PropTypes.any,
  localPrice: PropTypes.any,
  totalNight: PropTypes.any,
  totalPeople: PropTypes.any,
  localNightPrice: PropTypes.number,
  reviews: PropTypes.shape({
    rating: PropTypes.number,
    amountReviews: PropTypes.number,
    ratingText: PropTypes.string
  }),
  small: PropTypes.bool,
  noLazy: PropTypes.bool,
  isMap: PropTypes.bool
};

export default styled(HotelCard)`
  display: flex;
  align-items: stretch;
  overflow: hidden;
  height: 256px;
  border-radius: 10px;
  margin-bottom: 24px;
  border: 1px solid rgba(40, 40, 40, 0.1);
  background-color: ${COLORS.white};
  padding-right: 20px;
  position: relative;
  max-width: 933px;
  width: 100%;
  gap: 20px;
  align-items: center;
  cursor: pointer;

  .margin-top {
    margin-top: 5px;
  }

  .grey-divider {
    background-color: #c4c4c4;
    width: 1px;
    height: calc(100% - 40px);
  }

  .price-container {
    background-color: ${COLORS.white};
    padding: 5px;
    display: flex;
    flex-direction: column;
    align-items: center;
    /* box-shadow: -1px 0 3px 0 rgba(0, 0, 0, 0.35); */
    justify-content: space-evenly;
    gap: 15px;

    & > div {
      display: flex;
      flex-direction: column;
      align-items: center;
    }

    .prices-footer {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-top: 5px;
    }
    .content-points-itau {
      img {
        width: 24px;
        height: 24px;
      }
    }
    .itau-points-card{
      text-align:center;
    }
  }
 
  .hotel-photo {
    position: relative;
    width: 298px;

    .img {
      width: 100%;
      height: 256px;
      overflow: hidden;
      object-fit: cover;
    }

    .all-inclusive-tag {
      width: 150px;
      height: 28px;
      box-shadow: 0 3px 7px 0 rgba(0, 0, 0, 0.35);
      background-image: linear-gradient(to right, #1f672d, #8bb136);
      position: absolute;
      bottom: 0;
      right: 0;
      border-top-left-radius: 14px;
      border-bottom-left-radius: 14px;
      display: flex;
      align-items: center;
      justify-content: space-evenly;
      z-index: 2;

      .all-in-icon {
        width: 15px;
        height: 17px;
      }
    }
  }

  .location-container {
    display: flex;
    align-items: center;
  }

  .details {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    width: 100%;
    max-width: 296px;
    flex: 1;
    padding: 5px;

    .details-row {
      margin-top: 10px;
      display: flex;
      justify-content: space-evenly;
      align-items: center;

      & > div {
        flex: 1;
      }
    }
  }

  &.small {
    .hotel-photo {
      width: 120px;
    }

    .price-container {
      max-width: 150px;
    }
  }
`;

const HotelTitle = styled.h1`
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 32px;
  margin: 0;
`;
