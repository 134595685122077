import styled from 'styled-components';

export const BuyButton = styled.div`
  cursor: pointer;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 10px 30px;
  gap: 10px;
  width: 270px;
  height: 40px;
  background: #fb8400;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.25);
  border-radius: 12px;
  font-family: 'Itau Display Pro';
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 28px;
  text-align: center;
  color: #ffffff;
`;

export const EquipajeTitle = styled.div`
  color: #fb8400;
`;

export const EquipajeItemTextHolder = styled.div`
  display: flex;
  flex-direction: column;
`;

export const EquipajeItemBox = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 0px;
  gap: 8px;
  min-height: 44px;
`;

export const DetalleEquipaje = styled.div`
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  padding: 0px 20px;
  gap: 32px;
  border-left: 1px solid rgba(40, 40, 40, 0.25);
`;

export const CardEquipaje = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 20px;
  gap: 20px;
  width: 889px;
  height: 258px;
  border: 1px solid rgba(40, 40, 40, 0.25);
  border-radius: 20px;
  font-family: 'Itau Display Pro';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  color: #282828;
`;

export const Scroller = styled.div`
  width: 100%;
  ${({ scroller }) => (scroller ? 'max-height: 544px;' : '')}
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 0 20px;
`;

export const SeparadorEscala = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 10px;
  width: 100%;
  min-height: 42px;
  background: #faf2e3;
  border-radius: 8px;
  font-family: 'Itau Display Pro';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  text-align: center;
  color: #282828;
`;

export const DurationBox = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0px 0px 0px 10px;
  gap: 37px;
  width: 130px;
  height: 94px;
  border-left: 1px solid rgba(40, 40, 40, 0.25);
`;

export const TimeBox = styled.div`
  font-family: 'Itau Display Pro';
  font-style: normal;
  font-weight: 700;
  font-size: 22px;
  line-height: 30px;
  color: #282828;
`;

export const DateBox = styled.div`
  font-family: 'Itau Display Pro';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  color: #282828;
`;

export const DateTimeHolder = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const AirportBox = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0px;
  gap: 20px;

  /* width: 238px; */
`;

export const DetailsBox = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 0px;
  gap: 31px;
  width: 100%;
  height: 94px;

  svg.rotated {
    transform: rotate(180deg);
  }
`;

export const FlightNumber = styled.div`
  font-family: 'Itau Display Pro';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: rgba(40, 40, 40, 0.5);
`;

export const AirLine = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0px;
  gap: 12px;

  width: 168px;
  height: 30px;

  img {
    max-width: 50px;
  }

  span {
    font-family: 'Itau Display Pro';
    font-style: normal;
    font-weight: 300;
    font-size: 14px;
    line-height: 22px;
    color: #282828;
  }
`;

export const RowSpaceBetween = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
`;

export const SegmentCard = styled.div`
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 20px;
  gap: 24px;
  width: 100%;
  min-height: 206px;
  border: 1px solid rgba(40, 40, 40, 0.25);
  border-radius: 20px;
`;

export const Titulo = styled.div`
  font-family: 'Itau Display Pro';
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 32px;
  color: #000000;
  width: 100%;
`;

export const Holder = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.4);
  width: 100%;
  height: 100%;
  z-index: 99;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const ModalCard = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
  padding: 20px 0;
  background: #ffffff;
  border-radius: 20px;
  position: relative;
  max-width: 933px;
  width: 100%;
  max-height: 666px;
  height: 100%;
`;
