import { clearItauToken, getItauToken } from 'Components/ItauPuntosLogin/utils';
import { updateItauValues } from 'ReduxConfig/itau';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import api from 'Services';

interface Props {
  timeToCheck: number;
}

let keepSessionLiveTimer;

const events = ['click', 'scroll', 'load', 'keydown'];

export const useKeepSessionLive = ({ timeToCheck }: Props) => {
  const dispatch = useDispatch();
  const [freeToCheck, setFreeToCheck] = useState(false);
  const milliSeconds = timeToCheck * 1000;

  events.forEach(eventName => {
    window.addEventListener(eventName, getClientPoints);
  });

  const getClientPoints = async () => {
    const token = getItauToken();
    if (freeToCheck) {
      setFreeToCheck(false);
      if (token) {
        try {
          const result = await api.itau.clientClientPoints(token);
          dispatch(updateItauValues(result.data));
        } catch (_e) {
          clearItauToken();
        }
      }
    }
  };
  useEffect(() => {
    keepSessionLiveTimer = setTimeout(() => {
      setFreeToCheck(true);
    }, milliSeconds);
  }, [freeToCheck]);
};
