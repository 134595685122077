/**
 *
 * NewCheckbox
 *
 */

import React from 'react';
import styled from 'styled-components';
import { RegisterOptions, useFormContext } from 'react-hook-form';

import Icon from 'Components/Icon';
import { ThemeColor } from 'Theme';

interface FormCheckboxProps {
  children: JSX.Element
  checked?: boolean;
  onClick?: () => void;
}

export const Checkbox = ({
  checked,
  onClick,
  children
}: FormCheckboxProps) => {


  return (
    <Holder>
    <FakeCheckbox onClick={onClick && onClick}>
      {checked && <CheckboxIcon name="mv-checkmark" themeColor="orange" />}
    </FakeCheckbox>
    {children}
    </Holder>
  )
};

interface ContainerProps {
  addMargin?: boolean;
}

const Holder = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  font-family: Itau Display Pro;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  color: #282828;
  margin-top: 24px;
`

const FakeCheckbox = styled.span<ContainerProps>`
  cursor: pointer;
  width: 16px;
  height: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: ${({ theme }) => theme.shadows.one};
  border: solid 1px ${({ theme }) => theme.palette.grays.meVueloGrey};
  background-color: ${({ theme }) => theme.palette.grays.white};
  position: relative;
  ${({ theme, addMargin }) => addMargin && `margin-right: ${theme.spacing.one}`}

  input {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
  }
`;

const CheckboxIcon = styled(Icon as any)`
  pointer-events: none;
  position: absolute;
  top: 0;
  bottom: 0;
`;

const Hidden = styled.div`
  opacity: 0;
`;
