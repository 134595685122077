/**
 *
 * MobileHotelRoom
 *
 */

import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import cs from 'classnames';
import * as COLORS from 'Constants/colors';
import Card from 'Components/Card';
import Slider from 'react-slick';
import MobileHotelOptionItem from '../MobileHotelOptionItem';
import Icon from 'Components/Icon';
import LazyImg from 'Components/LazyImg';
import genericRoom from 'Assets/images/Hotel-Room-Generic.jpg';

const imgSliderSettings = {
  dots: true,
  slidersToShow: 1,
  speed: 500
};

const MobileHotelRoom = ({ className, room, selected, setSelected }) => {
  const [activeSlide, setActiveSlide] = useState(0);

  const handleClick = id => () => {
    setSelected(id);
  };

  const { nameRoom, imagesUrl, packages } = room;
  const sliderSettings = {
    className: 'center',
    centerMode: true,
    infinite: false,
    dots: true,
    centerPadding: '20px',
    slidesToShow: 1,
    speed: 500,
    afterChange: current => setActiveSlide(current)
  };

  return (
    <div className={cs(className)}>
      <Card header={nameRoom} noPadding>
        <div className="img-container">
          {imagesUrl.length > 1 ? (
            <Slider {...imgSliderSettings}>
              {imagesUrl.map((img, index) => (
                <img src={img} alt={nameRoom} className="img" key={index} />
              ))}
            </Slider>
          ) : (
            <LazyImg
              src={imagesUrl[0]}
              alt={nameRoom}
              className="img"
              imgFallback={genericRoom}
            />
          )}
          <div className="img-overlay">
            <Icon name="mv-eye" color="white" size={44} />
          </div>
        </div>

        <div className="slider-holder">
          <Slider {...sliderSettings}>
            {packages.map((pkg, i) => (
              <MobileHotelOptionItem
                index={i}
                item={pkg}
                key={pkg.packageId}
                active={selected && selected.packageId === pkg.packageId}
                handleClick={handleClick(pkg)}
              />
            ))}
          </Slider>
        </div>
      </Card>
    </div>
  );
};

MobileHotelRoom.propTypes = {
  className: PropTypes.string.isRequired,
  room: PropTypes.object,
  selected: PropTypes.object,
  setSelected: PropTypes.func
};

export default styled(MobileHotelRoom)`
  display: block;
  background-color: ${COLORS.white};
  border-radius: 14px;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.35);

  .content-holder {
    margin-top: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;

    ul {
      list-style: none;
      margin: 0;
      padding: 0;

      li {
        padding-left: 1em;
        text-indent: -0.7em;
        margin-bottom: 10px;
      }

      li::before {
        content: '• ';
        color: ${COLORS.orange};
        font-size: 14px !important;
      }
    }
  }

  .slider-holder {
    margin-top: 5px;

    .slick-dots {
      bottom: -20px;
    }
  }
  .slick-list {
    background-color: white;
  }
  .img-container {
    position: relative;

    .img-overlay {
      position: absolute;
      height: 45px;
      width: 59px;
      bottom: 0;
      right: 0;
      border-radius: 15px 0 0 0;
      background-image: linear-gradient(
        to right,
        ${COLORS.gradientDefault.left},
        ${COLORS.gradientDefault.right}
      );
      display: flex;
      justify-content: center;
      align-items: center;

      .icon {
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
  }

  .img {
    width: 100%;
    height: 200px;
  }

  .slick-dots li button::before {
    font-family: Montserrat, serif;
    content: '–';
    font-weight: 900;
    font-size: 35px;
    color: ${COLORS.darkGrey};
    opacity: 0.7;
  }

  .slick-dots li.slick-active button::before {
    color: ${COLORS.important};
    opacity: 1;
  }
`;
