/**
 *
 * NewSelect
 *
 */

import React, { useRef } from 'react';
import styled, { css } from 'styled-components';
import { useSelect } from 'downshift';
import { ThemeColor } from 'Theme/types';
import Icon from 'Components/Icon';
import Text from 'Components/Text';
import { TIconName } from 'Components/Icon/types';
import InputErrorMessage, {
  InputErrorMessageProps
} from 'Components/InputErrorMessage';
import useOutsideClick from 'Utils/hooks/useOutsideClick';

interface BaseSelectProps {
  label?: string;
  disabled?: boolean;
  color?: ThemeColor;
  icon?: TIconName;
  error?: InputErrorMessageProps;
  className?: string;
}

interface NewSelectProps<T> extends BaseSelectProps {
  items: T[];
  onItemSelected: (itemSelected?: T) => void;
  itemToString: (item: T) => string;
  initialSelectedItem?: T;
}

const NewSelect = <T extends {}>({
  label,
  items,
  onItemSelected,
  itemToString,
  disabled,
  color,
  icon,
  error,
  initialSelectedItem,
  ...rest
}: NewSelectProps<T>) => {
  const {
    isOpen,
    selectedItem,
    getToggleButtonProps,
    getMenuProps,
    getItemProps,
    highlightedIndex
  } = useSelect({
    items,
    onSelectedItemChange: ({ selectedItem }) => onItemSelected(selectedItem),
    itemToString,
    initialSelectedItem
  });

  return (
    <Container>
      <InputErrorMessage message={error?.message} color={error?.color} />
      <SelectButton
        {...getToggleButtonProps()}
        disabled={disabled}
        {...rest}
        icon={!!icon}
        isOpen={isOpen}>
        {(selectedItem && itemToString(selectedItem)) || label}
        <Arrow isOpen={isOpen} color={color} />
        <LeftIcon name={icon} hardColor={color} />
      </SelectButton>
      <ListContainer aria-label="menu" {...getMenuProps()}>
        {isOpen &&
          items.map((item, index) => (
            <ListItem
              icon={!!icon}
              key={`${item}${index}`}
              selected={index === highlightedIndex}
              {...getItemProps({ item, index })}>
              <Text>{itemToString(item)}</Text>
            </ListItem>
          ))}
      </ListContainer>
    </Container>
  );
};

const Container = styled.div`
  position: relative;
  width: 100%;
`;

interface SelectButtonProps {
  icon?: boolean;
  isOpen?: boolean;
}

const SelectButton = styled.button<SelectButtonProps>`
  /* box-shadow: ${({ theme }) => theme.shadows.one}; */
  border-radius: 10px;
  width: 100%;
  font-family: ${({ theme }) => theme.text.fontFamily.default};
  font-size: 14px;
  color: ${({ theme }) => theme.colors.meVueloGrey};
  height: 40px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  z-index: 1;
  box-sizing: border-box;
  padding: ${({ theme }) => `${theme.spacing.one} ${theme.spacing.two}`};
  padding-left: ${({ icon }) => icon && '34px'};
  background-color: ${({ theme }) => theme.colors.white};
  position: relative;
  border: 1px solid rgba(40, 40, 40, 0.25);
`;

const LeftIcon = styled(Icon as any)`
  position: absolute;
  left: 4px;
  top: 0;
  bottom: 0;
  margin: auto 0;
  z-index: 2;
  height: 26px;
  width: 26px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

interface ArrowProps {
  color?: ThemeColor;
  isOpen?: boolean;
}

const Arrow = styled.div<ArrowProps>`
  border-left: 3px solid;
  border-bottom: 3px solid;
  border-color: #fb8400;
  width: 12px;
  height: 12px;
  transform: rotate(-45deg);
  transition: 0.6s;
  margin-left: ${({ theme }) => theme.spacing.two};
  ${({ isOpen }) => isOpen && 'transform: rotate(-225deg)'};
`;

interface ListContainerProps {
  color?: ThemeColor;
  tall?: boolean;
}

const ListContainer = styled.div<ListContainerProps>`
  position: absolute;
  right: 0;
  background-color: ${({ theme }) => theme.colors.white};
  box-shadow: ${({ theme }) => theme.shadows.one};
  border-bottom-left-radius: ${({ theme }) => theme.borderRadius.default};
  border-bottom-right-radius: ${({ theme }) => theme.borderRadius.default};
  width: 100%;
  ${({ tall, theme, color }) =>
    !tall &&
    css`
      max-height: 200px;
      ${theme.scrollbar(color ? theme.colors[color] : theme.colors.important)};
    `};
  z-index: 2;
`;

interface ListItemProps {
  icon?: boolean;
  selected?: boolean;
}

const ListItem = styled.div<ListItemProps>`
  padding: ${({ theme }) => `${theme.spacing.one} ${theme.spacing.two}`};
  padding-left: ${({ icon }) => icon && '34px'};
  cursor: pointer;
  ${({ selected, theme }) =>
    selected && `background-color: ${theme.colors.greyHoverBkg};`};

  /* stylelint-disable */
  :hover {
    background-color: ${({ theme }) => theme.colors.greyHoverBkg};
  }
  /* stylelint-enable */
`;

export default NewSelect;

interface CustomSelectProps extends BaseSelectProps {
  showValue: string;
  children: React.ReactNode;
  isOpen: boolean;
  toggleOpen: (e: any) => void;
  close: () => void;
}

export const CustomSelect = ({
  error,
  disabled,
  icon,
  isOpen,
  label,
  showValue,
  color,
  children,
  close,
  toggleOpen,
  ...rest
}: CustomSelectProps) => {
  const refIsOpen = useRef();
  const innerRef = useRef();

  useOutsideClick(refIsOpen, () => {
    if (isOpen) {
      close();
    }
  });
  return (
    <Container ref={refIsOpen} {...rest}>
      <InputErrorMessage message={error?.message} color={error?.color} />
      <SelectButton
        onClick={toggleOpen}
        disabled={disabled}
        icon={!!icon}
        isOpen={isOpen}
        ref={innerRef}>
        {showValue || label}
        <Arrow isOpen={isOpen} color={color} />
        <LeftIcon name={icon} hardColor={color} />
      </SelectButton>
      <ListContainer tall>{isOpen && children}</ListContainer>
    </Container>
  );
};
