/**
 *
 * AirCheckError
 *
 */

import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import errorImg from './error.png';

const Card = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 20px;
  isolation: isolate;

  position: relative;
  min-height: 410px;

  background: #ffffff;
  box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.25);
  border-radius: 16px;

  font-family: 'Itau Display Pro';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  color: #000000;

  h1 {
    font-weight: 700;
    font-size: 40px;
    line-height: 48px;
  }

  div {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0px;
    gap: 4px;
    margin-bottom: 32px;
  }
`;

const Button = styled.div`
  cursor: pointer;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center !important;
  padding: 10px 30px;
  width: 100%;
  min-height: 40px;
  background: #fb8400;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.25);
  border-radius: 5px;
  font-weight: 700;
  font-size: 20px;
  line-height: 28px;
  text-align: center;
  color: #ffffff;
`;
const Holder = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const AirCheckError = ({ close,message,handleGoBack}) => (
  <Holder>
    <Card>
      <img src={errorImg} alt="fare not found" />
      <h1>¡Ups!</h1>
      {message?.length > 0 ? <p>{message}</p> : (
      <div>
        Parece que la aerolínea ya no cuenta con disponibilidad para este vuelo.
        <span>Probá con otra de las opciones disponibles.</span>
      </div>
      )}
      {message ? <Button onClick={handleGoBack}>Volver</Button> : <Button onClick={close}>Ver más</Button>}
    </Card>
  </Holder>
);

AirCheckError.propTypes = {
  close: PropTypes.func,
  message:PropTypes.string,
  mobile: PropTypes.bool,
  desktop: PropTypes.bool
};

export default AirCheckError;