import styled from 'styled-components';

export const BuyButtonHolder = styled.div`
  display: flex;
  width: 100%;
  justify-content: flex-end;
  align-items: center;
  padding: 20px;

  div {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 10px 30px;
    gap: 10px;
    width: 170px;
    height: 48px;
    background: #fb8400;
    border-radius: 12px;
    font-family: 'Itau Display Pro';
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 28px;
    text-align: center;
    color: #ffffff;
    cursor: pointer;
  }
`;

export const Holder = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 20px;
  gap: 24px;
  width: 100%;
`;

export const TitleHolder = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 0px;
  gap: 80px;
  width: 100%;
`;

export const TitleLeft = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 16px;
  gap: 16px;

  span {
    font-family: 'Itau Display Pro';
    font-style: normal;
    font-weight: 700;
    font-size: 22px;
    line-height: 30px;
    color: #282828;
  }
`;

export const TitleRight = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 10px 10px 10px 0px;
  width: 215px;
  min-height: 64px;
  border: 1px solid #fb8400;
  border-radius: 8px;
  font-family: 'Itau Display Pro';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  display: flex;
  color: #fb8400;
  padding: 10px;
  gap: 10px;

  svg {
    background-color: white;
    color: white;
  }
`;

export const Card = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  gap: 24px;
  width: 100%;
  background: #ffffff;
  box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.25);
  border-radius: 16px;
  font-family: 'Itau Display Pro';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  display: flex;
  align-items: center;
  color: #003399;
  margin: 72px 0;
`;

export const LoginButtonsHolder = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-start;
  padding: 0px;
  gap: 24px;

  div {
    cursor: pointer;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 10px 30px;
    gap: 10px;
    min-width: 170px;
    background: #fb8400;
    border-radius: 12px;
    font-family: 'Itau Display Pro';
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 28px;
    text-align: center;
    color: #ffffff;

    &.inverted {
      color: #fb8400;
      background: #ffffff;
      border: 1px solid #fb8400;
    }
  }
`;

export const ValorEnPuntosHolder = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 0;
  gap: 10px;
  width: 100%;
  font-family: 'Itau Display Pro';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  color: #282828;

  span {
    color: #003399;
    font-weight: 700;
  }
`;

export const MemberShipsHolder = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
`;

export const Divider = styled.div`
  width: 100%;
  height: 1px;
  opacity: 0.2;
  background-color: #282828;
  margin: 50px 0;
`;

export const Breakdown = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 20px 32px;
  gap: 24px;
  width: 100%;
  background: #faf2e3;
  border-radius: 16px;

  font-family: 'Itau Display Pro';
  font-style: normal;
  font-weight: 300;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  color: #282828;
  margin-top: 50px;

  span {
    font-weight: 700;
    font-size: 22px;
    line-height: 30px;
  }

  div {
    display: flex;
    flex-direction: column;
  }
`;
