/**
 *
 * MpcMptbAvailability
 *
 */

import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import cs from 'classnames';

import AirAvailability from '../AirAvailability';
import CardAlert from 'Components/CardAlert';
import Text from 'Components/Text';

const MpcMptbAvailability = ({
  className,
  hasMPTBData,
  mptbAvailability,
  hasError,
  messagesError
}) => {
  return (
    <div className={cs(className)}>
      {hasError && (
        <CardAlert
          className="container-alert"
          color="important"
          nameIcon="mv-information-circle"
          title={messagesError}>
          <Text paragraph>
            Puede ser que no haya lugar en los vuelos o que no haya vuelos en
            esos días.
            <br />
            Por favor, intenta modificar la búsqueda.
          </Text>
        </CardAlert>
      )}

      {hasMPTBData && !hasError && (
        <>
          {/* <div>
          <div className="container">
           caigo aca
          </div>
        </div> */}
          <AirAvailability availability={mptbAvailability} />
        </>
      )}
    </div>
  );
};

MpcMptbAvailability.propTypes = {
  className: PropTypes.string.isRequired,
  hasMPCData: PropTypes.bool,
  hasMPTBData: PropTypes.bool,
  mpcAvailability: PropTypes.object,
  mptbAvailability: PropTypes.object,
  hasError: PropTypes.bool,
  messagesError: PropTypes.string,
  routesData: PropTypes.object
};

export default styled(MpcMptbAvailability)`
  .availability-holder {
    padding-top: 10px;

    .menu-tab-holder {
      display: flex;
      justify-content: center;
      width: 100%;

      > div {
        width: 100%;

        > button {
          width: 32%;
        }
      }
    }
  }

  .container-alert {
    width: 100%;
    margin: 0 auto;
    max-width: 1100px;
    text-align: center;

    @media all and (max-width: 768px) {
      margin-top: 10px;
    }
  }
`;
