/**
 *
 * ArticleContainer
 *
 */

import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import cs from 'classnames';
import { useHistory } from 'react-router';

// Components
import Package from '../Package';
import Divider from 'Components/Divider';
import Button from 'Components/Button';
import PackageAvailabilityCard from '../PackageAvailabilityCard';
import { PaymentPromoBanner } from '../../../../Components/PaymentPromoBanner';
import { ReactComponent as Arrow } from './arrow.svg';

// Constants
import * as COLORS from 'Constants/colors';

// Utils
import useBreakpoints from 'Utils/hooks/useBreakpoints';
import PackagePricesGraph from '../PackagePricesGraph';
import PackageError from '../PackageError';
import Link from 'Components/Link';
import { buildPackageUrl } from 'Features/packages/helpers';
import { routes } from 'Constants';
import { FormDetails } from '../PackageContactForm/FormDetails';

const GoBack = styled.div`
  cursor: pointer;
  display: inline-flex;
  padding: 5px 10px;
  justify-content: center;
  align-items: center;
  border-radius: 12px;
  border: 1px solid #fb8400;
  background: white;
  color: var(--primario, #fb8400);
  text-align: center;
  font-family: Itau Display Pro;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 28px; /* 140% */
`;

const GoBackHolder = styled.div`
  max-width: 1091px;
  width: 100%;
  padding: 10px 0;
  margin: 0px auto;
`;

const BackLoader = styled.div`
  position: fixed;
  top: 0;
  left: 0%;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.8);
  z-index: 999999999999999;
`;

const PackageContainer = ({
  className,
  actions,
  packageItemData,
  error,
  upSelling,
  keyword,
  id,
  loading
}) => {
  const { largeDown, mediumDown } = useBreakpoints();
  const itemClassName = cs('item', {
    medium: mediumDown,
    large: largeDown
  });

  const history = useHistory();

  useEffect(() => {
    window.scrollTo(0, 0);
    actions.getPackage(keyword, id);
  }, [keyword, id]);

  useEffect(() => {
    if (!largeDown) {
      actions.getUpselling(id);
    }
  }, [id]);

  return (
    <>
      <div className={cs(className, { mobile: mediumDown })}>
        {loading && <BackLoader />}

        {!mediumDown && (
          <div className="go-back">
            <GoBackHolder>
              <GoBack onClick={() => history.push(routes.package)}>
                <Arrow className="icon" />
                <span>Volver al listado</span>
              </GoBack>
            </GoBackHolder>
            {/* <Link to={routes.package}>
              <IconButton context="information" className="desktop-button-next">
                <Arrow className="icon" />
                Volver al listado
              </IconButton>
            </Link> */}
          </div>
        )}
        <div className={cs('package-holder', { mobile: largeDown })}>
          {error && <PackageError />}
          {packageItemData && (
            <>
              <Package packageDetail={packageItemData} />
              {!largeDown && (
                <div className="form-container">
                  <div className={cs('form-holder', { mobile: largeDown })}>
                    <FormDetails
                      price={packageItemData.lowerPrice.amount}
                      currency={packageItemData.lowerPrice.currency}
                      prices={packageItemData.prices}
                      basePrice={packageItemData.basePrice}
                    />
                  </div>
                </div>
              )}
            </>
          )}
        </div>
        {!largeDown && upSelling.length > 0 && (
          <div className="bottom-content">
            <PaymentPromoBanner />
            {packageItemData && packageItemData.pricesByMonth.length > 0 && (
              <>
                <div
                  className={cs(
                    'more-packages',
                    { large: largeDown },
                    { medium: mediumDown }
                  )}>
                  <Divider titleText={'Precio por mes'} />
                </div>
                <PackagePricesGraph
                  pricesData={packageItemData.pricesByMonth}
                />
              </>
            )}
            <div
              className={cs(
                'more-packages',
                { large: largeDown },
                { medium: mediumDown }
              )}>
              <Divider
                titleText="Otros paquetes que te pueden interesar"
                size="default"
                className="divider-package"
              />
              {upSelling.map(pckg => (
                <div className={itemClassName} key={pckg.id + pckg.title}>
                  <PackageAvailabilityCard
                    image={pckg.image}
                    price={pckg.lowerPrice.amount}
                    currency={pckg.lowerPrice.currency}
                    title={pckg.title}
                    icons={pckg.compositeIcons}
                    color={pckg.shadowColor ? pckg.shadowColor : COLORS.green}
                    lowerLocalPrice={pckg.lowerLocalPrice}
                    duration={pckg.duration}
                    travelOn={pckg.travelOn}
                    orderBy={pckg.orderBy}
                    orderByOptions={pckg.orderByOptions}
                    url={buildPackageUrl(pckg.keyword, pckg.id)}
                    basePrice={pckg.basePrice}
                  />
                </div>
              ))}
            </div>
          </div>
        )}

        {largeDown && packageItemData && (
          <div className="fixed-contact-form">
            <PaymentPromoBanner />
            {packageItemData && packageItemData.pricesByMonth.length > 0 && (
              <>
                <Divider titleText={'Precios por mes'} />
                <PackagePricesGraph
                  pricesData={packageItemData.pricesByMonth}
                />
              </>
            )}
            <FormDetails
              price={packageItemData.lowerPrice.amount}
              currency={packageItemData.lowerPrice.currency}
              prices={packageItemData.prices}
              basePrice={packageItemData.basePrice}
            />
          </div>
        )}

        {mediumDown && (
          <div className="go-back-mobile">
            <Link to={routes.package}>
              <IconButton context="information">
                <Arrow className="icon" />
                Volver al listado
              </IconButton>
            </Link>
          </div>
        )}
      </div>
    </>
  );
};

PackageContainer.propTypes = {
  className: PropTypes.string.isRequired,
  keyword: PropTypes.string.isRequired,
  id: PropTypes.number.isRequired,
  packageItemData: PropTypes.object,
  error: PropTypes.object,
  upSelling: PropTypes.array,
  history: PropTypes.object,
  actions: PropTypes.shape({
    getPackage: PropTypes.func.isRequired,
    getUpselling: PropTypes.func.isRequired
  }),
  loading: PropTypes.bool
};

const IconButton = styled(Button)`
  display: flex;
  align-items: flex-center;
  justify-content: flex-start;
  margin: 10px 95px;
  background-color:white;
  color:#FB8400;
  font-family: Itau Display Pro;
  font-size: 20px;
  font-weight: 700;
  line-height: 28px;
  letter-spacing: 0em;
  text-align: center;
  width: 186px;
  height: 40px;
  top: 245px;
  left: 120px;
  padding: 5px 10px 5px 10px;
  border-radius: 12px;
  margin-bottom: ${({ theme }) => theme.spacing.two};
  .icon {
    margin-right: ${({ theme }) => theme.spacing.one};
    margin-top:1px;
  }
  &.desktop-button-next{
    font-family: Itau Display Pro;
    border: 1px solid #FB8400;
    margin-left:243px;
  }
`;

export default styled(PackageContainer)`
  padding-top: ${({ theme }) => theme.spacing.four};
  margin: 0 auto;
  padding: 5px, 10px, 5px, 10px;
  margin-top: ${({ theme }) => theme.spacing.four};
  &.mobile {
    margin-top: 0;
    padding-top: 0;

    .go-back-mobile {
      display: flex;
      justify-content: center;
      margin-top: ${({ theme }) => theme.spacing.four};
    }

    .fixed-contact-form {
      margin-top: ${({ theme }) => theme.spacing.four};
    }
  }

  .package-holder {
    display: flex;
    justify-content: center;
    align-items: stretch;

    &.mobile {
      flex-direction: column;
    }

    .form-holder {
      top: 99px;
      position: sticky;
      margin-left: 10px;
      max-width: 650px;

      &.mobile {
        top: auto;
        position: relative;
        width: 100%;
        max-width: 722px;
        margin: 50px auto 0 auto;
      }
    }
  }

  .bottom-content {
    margin-top: ${({ theme }) => theme.spacing.eight};
  }
  .divider-package {
    width: 100%;
  }
  .more-packages {
    display: flex;
    padding: 0 120px;
    flex-wrap: wrap;
    margin: 0 auto;
    max-width: 1300px;
    .item {
      width: 33%;
      padding: 10px;

      &.large {
        width: 50%;
      }

      &.medium {
        width: 100%;
      }
    }
  }
`;
