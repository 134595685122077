import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';
import Cookies from 'js-cookie';

import { updateItauPoints } from 'Features/app/actions';
import { updateItauValues } from 'ReduxConfig/itau';
import api from 'Services';

import sliderThumb from 'Assets/logo_naranja.svg';
import { doLogin } from './utils';

export const ItauPuntosMobile = () => {
  const { selectedItauPoints, maxItauPuntos, pointsClient } = useSelector(
    // @ts-ignore reducers not typed?
    ({ appConfiguration, itau }) => ({
      selectedItauPoints: appConfiguration.selectedItauPoints,
      maxItauPuntos: appConfiguration.maxItauPuntos,
      pointsClient: itau.pointsClient
    })
  );
  const [value, setValue] = useState(0);
  const dispatch = useDispatch();

  useEffect(() => {
    const updatePointsClient = async () => {
      const token = Cookies.get('itau-token');
      if (token) {
        try {
          const result = await api.itau.clientClientPoints(token);
          dispatch(updateItauValues(result.data));
        } catch (_e) {
          Cookies.remove('itau-token');
        }
      }
    };
    updatePointsClient();
    setValue(selectedItauPoints);
  }, []);

  useEffect(() => {
    dispatch(updateItauPoints({ points: value }));
  }, [value, dispatch]);

  const isLogged = Boolean(Cookies.get('itau-token'));
  const finalMaxValue = isLogged
    ? pointsClient.reduce(
        (accumulator, membership) => accumulator + membership.points,
        0
      )
    : maxItauPuntos;

  const valueToRender = value.toLocaleString('en').replaceAll(',', '.');
  return (
    <Container>
      <Holder>
        <BannerHolder>
          <BannerTextoHolder>
            <PrimerTexto>
              ¡Viajá gratis o pagá menos con tus <span>puntos Itaú</span>!
            </PrimerTexto>
            <SegundoTexto>
            Deslizá para conocer el beneficio de tus puntos Itaú.
            </SegundoTexto>
          </BannerTextoHolder>
          <SliderHolder>
            <Puntos>{valueToRender}</Puntos>
            <input
              type="range"
              min="0"
              max={finalMaxValue}
              onChange={e => setValue(Number(e.target.value))}
              value={value}
            />
          </SliderHolder>
          {!isLogged ? (
            <LoginHolder>
              <BlueText>
                Accedé a tu cuenta para ver tus puntos reales Itaú.
              </BlueText>
              <ButtonsHolder>
                {/* <Button1>Registrarme</Button1> */}
                <Button2 onClick={doLogin(dispatch)}>Click aquí</Button2>
              </ButtonsHolder>
            </LoginHolder>
          ) : (
            <LoginHolder>
              <ButtonsHolder>
                <Button1
                  onClick={() => {
                    Cookies.remove('itau-token');
                    window.location.reload();
                  }}>
                  Logout
                </Button1>
              </ButtonsHolder>
            </LoginHolder>
          )}
        </BannerHolder>
      </Holder>
    </Container>
  );
};
const Container = styled.div`
  max-width: 1100px;
  width: 90%;
  margin:  40px auto;
`;

const SliderHolder = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0px;
  background-color: white;
  gap: 4px;
  width: 85%;

  input[type='range'] {
    height: 38px;
    appearance: none;
    margin: 10px 0;
    width: 100%;
  }
  input[type='range']:focus {
    outline: none;
  }

  input[type='range']::-webkit-slider-runnable-track {
    width: 100%;
    height: 4px;
    cursor: pointer;
    background: rgba(40, 40, 40, 0.25);
    border-radius: 14px;
    border: 0;
  }

  input[type='range']::-webkit-slider-thumb {
    height: 40px;
    width: 40px;
    background: url(${sliderThumb});
    background-position: center;
    background-size: 40px 40px;

    cursor: pointer;
    -webkit-appearance: none;
    margin-top: -15px;
  }
`;

const Puntos = styled.div`
  font-family: 'Itau Display Pro';
  font-style: normal;
  font-weight: 400;
  font-size: 22px;
  line-height: 30px;
  display: flex;
  align-items: center;
  text-align: center;
  color: #282828;
  display: flex;
  flex-direction: row;
  justify-content: center;
  padding: 5px 60px;
  gap: 10px;
  width: 100%;
`;

const BannerTextoHolder = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0px;
  justify-content: space-around;
  gap: 10px;
`;

const PrimerTexto = styled.div`
  font-family: 'Itau Display Pro';
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  max-width: 85%;
  text-align: center;
  align-items: center;
  color: #282828;

  span {
    margin-left: 5px;
    color: #003399;
  }
`;

const SegundoTexto = styled.div`
  font-family: 'Itau Display Pro';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  display: flex;
  text-align: center;
  align-items: center;
  color: #959595;
  max-width: 85%;
`;

const BannerHolder = styled.div`
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 20px;
  gap: 40px;
  min-height: 104px;
  border: 1px solid rgba(40, 40, 40, 0.1);
  border-radius: 14px;
  background-color: white;
`;

const Button1 = styled.a`
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 10px 30px;
  gap: 10px;
  width: 170px;
  height: 48px;
  background: #ffffff;
  border: 1px solid #fb8400;
  border-radius: 12px;
  font-family: 'Itau Display Pro';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  text-align: center;
  color: #fb8400;
  cursor: pointer;
  border-bottom: 1px solid #fb8400;
`;

const Button2 = styled.a`
  font-family: 'Itau Display Pro';
  font-size: 14px;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: 0;
  text-align: center;
  color: #fb8400 !important;
  cursor: pointer;
  border-bottom: 1px solid #fb8400;
`;

const ButtonsHolder = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: center;
  padding: 0px;
  gap: 24px;
  width: 85%;
  height: 48px;
`;

const BlueText = styled.div`
  height: 24px;
  font-family: 'Itau Display Pro';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  display: flex;
  align-items: center;
  color: #003399;
  width: 280px;
  text-align: center;
`;

const LoginHolder = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0px;
  gap: 24px;
  max-width: 85%;
  height: 96px;
`;

const Holder = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0px;
  gap: 50px;
  width: 100%;
`;
