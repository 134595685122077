import React, { useCallback, useEffect, useState, useRef } from 'react';
import { useForm, FormProvider, useFieldArray } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { Breakpoint } from 'react-socks';
import * as Sentry from '@sentry/react';
import invokeLibrary from 'Utils/invokeLibrary';
import { v4 as uuidv4 } from 'uuid';
import { routes, urlBankcard } from 'Constants';
import api from 'Services';
import Modal from 'Components/Modal';

import { getAppConfiguration, updateLoader } from 'Features/app/actions';

import { BillingData, PaxData, Steps } from './types';

import { Form } from './styles';
import { bancardIframeGenerator } from 'Containers/Checkout/helpers';
import { HotelCheckoutDesktop } from './components/HotelCheckoutDesktop';
import {
  dateTransform,
  getChildCode,
  returnCountryName,
  scrollToTop
} from './utils';
import { HotelCheckoutMobile } from './components/HotelCheckoutMobile';
import { getItauToken } from 'Components/ItauPuntosLogin/utils';
import { useHistory } from 'react-router';
import AirCheckError from 'Features/flights/components/AirCheckError';
import styled from 'styled-components';

const returnItauData = ({
  itau,
  selectedInterventajasPoints,
  selectedMembershipPoints
}) => {
  const itauData = {
    token: getItauToken(),
    aliasClient: itau.aliasClient,
    pointsClient: []
  };
  if (selectedInterventajasPoints > 500) {
    itauData.pointsClient.push({
      program: 'INTERVENTAJAS',
      points: selectedInterventajasPoints
    });
  }

  if (selectedMembershipPoints > 500) {
    itauData.pointsClient.push({
      program: 'MEMBERSHIP',
      points: selectedMembershipPoints
    });
  }
  return itauData;
};

export const HotelCheckout = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [billingData, setBillingData] = useState<BillingData>();
  const [paxData, setPaxData] = useState<PaxData[]>([]);
  const [activeStep, setActiveStep] = useState<Steps>(Steps.paxData);
  const [titularIndex, setTitularIndex] = useState(0);
  const [showErrorModal, setShowErrorModal] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [resultWithChildren, setResultWithChildren] = useState<PaxData[]>([]);
  const [resultWithoutChildren, setResultWithoutChildren] = useState<PaxData[]>([]);
  const isLogged = Boolean(getItauToken());
  const {
    rooms,
    itau,
    checkinTime,
    checkoutTime,
    checkinDate,
    checkoutDate,
    policy,
    totalPrice,
    totalPriceUSD,
    pricePerNight,
    peopleByRoom,
    netPrice,
    isFreeCancellation,
    hotelId,
    optionId
  } = useSelector(
    // @ts-ignore
    ({ checkout, itau }) => ({
      rooms: checkout.selectedAvailability.hotelInformation.rooms,
      checkinTime: checkout.selectedAvailability.checkIn,
      checkoutTime: checkout.selectedAvailability.checkOut,
      checkinDate: checkout.selectedAvailability.hotelInformation.checkRate.checkIn,
      checkoutDate: checkout.selectedAvailability.hotelInformation.checkRate.checkOut,
      policy: checkout.selectedAvailability.hotelInformation.policyDetails,
      totalPrice: checkout.selectedAvailability.hotelInformation.localPrice.finalPrice,
      totalPriceUSD: checkout.selectedAvailability.hotelInformation.packagePrice.finalPrice,
      pricePerNight: checkout.selectedAvailability.hotelInformation.localPrice.finalPricePerNight,
      peopleByRoom: checkout.selectedAvailability.hotelInformation.peopleByRoom,
      netPrice: checkout.selectedAvailability.hotelInformation.packagePrice.netPrice,
      isFreeCancellation: checkout.selectedAvailability.hotelInformation.hasFreeCancellation,
      hotelId: checkout.selectedAvailability.hotelId,
      optionId: checkout.selectedAvailability.hotelInformation.optionId,
      itau
    })
  );

  const { checkout } = useSelector(
    // @ts-ignore
    ({ checkout, itau }) => ({
      checkout,
      itau
    })
  );

  useEffect(() => {
    dispatch(updateLoader(false));
    dispatch(getAppConfiguration());
    scrollToTop();
  }, [dispatch]);

  const formRef = useRef<HTMLFormElement>();
  const methods = useForm();
  const {
    formState: { errors },
    control,
    setValue
  } = methods;
  const { fields, append } = useFieldArray({
    control,
    name: 'rooms'
  });

  useEffect(() => {
    rooms.forEach(room => {
      for (let index = 0; index < room.occupancy.rooms; index++) {
        append({
          firstName: '',
          lastName: '',
        });
      }
    });

    const persistFormData = localStorage.getItem('vn-checkout-data');
    if (persistFormData) {
      const obj = JSON.parse(persistFormData);

      setValue('address', obj.address);
      setValue('city', obj.city);
      setValue('country', obj.country);
      setValue('countryCode', obj.countryCode);
      setValue('docType', obj.docType);
      setValue('document', obj.document);
      setValue('email', obj.email);
      setValue('firstName', obj.firstName);
      setValue('lastName', obj.lastName);
      setValue('nationality', obj.nationality);
      setValue('personType', obj.personType);
      setValue('phone', obj.phone);
      setValue('state', obj.state);
      setValue('passengers', obj.passengers);
      localStorage.removeItem('vn-checkout-data');
    }
  }, [append, rooms, setValue]);

  // TODO: Esto va en un proximo PR
  const handleRequest = async ({
    selectedInterventajasPoints,
    selectedMembershipPoints,
    moneyToComplete,
    pointsToRender
  }) => {



    const request = {
      billingData,
      paymentData: {
        amountPrice: moneyToComplete > 0 ? moneyToComplete : 0
      },
      product: 'HOTEL',
      hotelData: {
        optionId,
        toShow: {
          paxData,
          checkinTime,
          checkoutTime,
          checkinDate,
          checkoutDate,
          totalPrice,
          pricePerNight,
          rooms,
          policy,
          peopleByRoom,
          pointsToRender,
          moneyToComplete,
          selectedInterventajasPoints,
          selectedMembershipPoints
        },
        main: {
          bookingPrice: totalPriceUSD,
          priceInSupplier: netPrice,
          coinId: 10,
          hotelId,
          checkIn: `${checkinDate}T${checkinTime}`, //"2023-05-12T15:00",
          checkOut: `${checkoutDate}T${checkoutTime}`, //"2023-05-13T11:00",
          isFreeCancellation,
        },
        titular: { // sería el pasajero con el check de titular de la reserva cuando existen muchas habitaciones
          firstName: paxData[0].firstName,
          lastName: paxData[0].lastName
        },
        rooms: rooms.map(
          ({ rateKey, boardName, roomName, occupancy, rateComments }, index) => ({
            rateKey,
            boardName,
            roomName,
            rateComments,
            totalRooms: occupancy.rooms,
            totalAdults: occupancy.adults,
            totalChildren: occupancy.children,
            childrenAges: occupancy.paxes
              ? occupancy.paxes.map(({ age }) => age)
              : [],
            holders: index === 0 && occupancy.children > 0
              ? resultWithChildren
              : resultWithoutChildren,
          })
        ),
        additionalRequirements: ""
      }
    };
    if (
      isLogged &&
      (selectedInterventajasPoints > 0 || selectedMembershipPoints > 0)
    ) {
      // @ts-ignore
      request.paymentData.itauData = returnItauData({
        itau,
        selectedInterventajasPoints,
        selectedMembershipPoints
      });
    }
    try {
      const result = await api.checkout.requestProcessId(request);
      if (moneyToComplete > 0) {
        await invokeLibrary(urlBankcard);
        dispatch(updateLoader(false));
        bancardIframeGenerator(result.data);
      } else if (result.data.status && result.data.status === 'success') {
        const reservationCode = result.data.id;
        history.push(
          `${routes.airCheckoutSuccess}/onlyPoints/?reservationCode=${reservationCode}`
        );
      } else if (result.data.status && result.data.status === 'failed') {
        const reservationCode = result.data.id;
        history.push(
          `${routes.airCheckoutSuccess}/onlyPoints/?reservationCode=${reservationCode}&status=payment_fail`
        );
      } else {
        history.push(
          `${routes.airCheckoutSuccess}/onlyPoints/?status=payment_fail`
        );
      }
    } catch (e) {
      dispatch(updateLoader(false));
      const uuid = uuidv4();
      if (e.response && e.response.data) {
        Sentry.captureMessage(`JobProcessId failure : Hotels: ${uuid} - ${JSON.stringify(e.response.data)}`);
      } else {
        Sentry.captureMessage(`JobProcessId failure : Hotels: ${uuid} - No Error mensage with correct structure`);
      }
      setShowErrorModal(true);
      setErrorMessage(e.response.data.message)
      console.error(`Error al intentar procesar el pago: ${uuid}`);
    }
  };

  const formDispatch = data => {
    if (activeStep < Steps.payment) {
      if (activeStep === Steps.paxData) {
        handleParsePax(data);
      } else {
        handleParseBillingData(data);
      }
      setActiveStep(activeStep + 1);
    }
    scrollToTop();
  };

  const handleParseBillingData = data => {
    setBillingData({
      personType: 1,
      firstName: data.firstName,
      lastName: data.lastName,
      documentType: Number(data.docType),
      document: data.document,
      country: {
        code: data.country,
        name: returnCountryName(data.country)
      },
      cityName: data.city,
      stateName: data.state,
      email: data.email,
      phoneData: {
        phoneType: 1,
        countryCode: data.countryCode,
        phoneNumber: data.phone
      },
      address: data.address
    });
  };

  const handleParsePax = (data: any) => {
    const { passengers } = data;
    setValue('firstName', passengers[titularIndex].firstName);
    setValue('lastName', passengers[titularIndex].lastName);
    setValue('docType', 1);
    setValue('nationality', 'PRY');
    setValue('countryCode', '595');
    setValue('personType', 1);
    setValue('country', 'PY');
    setValue('city', 'Asunción');
    setValue('state', 'Asunción');

    setPaxData(
      passengers.map(item => ({
        firstName: item.firstName,
        lastName: item.lastName,
      }))
    );
  };


  const roomsWithChildren: number[] = rooms
    .map((e, index) => (e.occupancy.children > 0 ? index : -1))
    .filter((index) => index !== -1);

  function splitHoldersBySubgroupsWithChildren(paxData: PaxData[], roomsWithChildren: number[]): PaxData[] {
    const holdersWithChildren: PaxData[] = [];
    for (const index of roomsWithChildren) {
      holdersWithChildren.push(paxData[index]);
    }
    return holdersWithChildren;
  }

  function splitHoldersBySubgroupsWithoutChildren(paxData: PaxData[], roomsWithChildren: number[]): PaxData[] {
    const holdersWithoutChildren: PaxData[] = [];

    for (let index = 0; index < paxData.length; index++) {
      if (!roomsWithChildren.includes(index)) {
        holdersWithoutChildren.push(paxData[index]);
      }
    }
    return holdersWithoutChildren;
  }

  useEffect(() => {
    setResultWithChildren(splitHoldersBySubgroupsWithChildren(paxData, roomsWithChildren));
    setResultWithoutChildren(splitHoldersBySubgroupsWithoutChildren(paxData, roomsWithChildren));
  }, [paxData]);


  const toggleErrorModal = () => setShowErrorModal(!showErrorModal);
  const handleGoBack = () => {
    history.goBack()
    setShowErrorModal(false);
  };
  const handleNextStep = () =>
    formRef.current.dispatchEvent(new Event('submit', { cancelable: true }));

  const handlePreviousStep = useCallback(() => {
    if (activeStep > Steps.paxData) {
      setActiveStep(activeStep - 1);
      scrollToTop();
    }
  }, [activeStep, setActiveStep]);

  const props = {
    activeStep,
    fields: fields,
    onNextStep: handleNextStep,
    onPreviousStep: handlePreviousStep,
    onRequest: handleRequest,
    titularIndex,
    checkinTime,
    checkoutTime,
    checkinDate,
    checkoutDate,
    totalPrice,
    pricePerNight,
    rooms,
    policy,
    peopleByRoom,
    onSelectTitular: (val: number) => setTitularIndex(val)
  };
  return (
    <FormProvider {...methods}>
      <Form onSubmit={methods.handleSubmit(formDispatch)} ref={formRef}>
        <Breakpoint small down>
          <HotelCheckoutMobile {...props} />
        </Breakpoint>
        <Breakpoint medium up>
          <HotelCheckoutDesktop {...props} />
        </Breakpoint>
      </Form>
      {/* 
      // @ts-ignore */}
      <Modal
        show={showErrorModal}
        onClickOverlay={toggleErrorModal}
        noCard
        modalWidth={1000}>
        <AirCheckError close={toggleErrorModal} message={errorMessage} handleGoBack={handleGoBack} />
      </Modal>
    </FormProvider>
  );
};

