import slugify from 'slugify';
import { push } from 'connected-react-router';
import API from '../../Services';
import ACTION_TYPES from './actionTypes';
import { limits, types } from './constants';
import { getTotalPeopleRoom, validateSearch } from './helpers';
import hotelRequestToUrl from '../../Utils/hotelRequestToUrl';
import { routes } from 'Constants';
import { selectAvailability } from 'ReduxConfig/checkout';
import { selectAvailabilityCorpo } from '../../ReduxConfig/checkoutCorpo';

export const setHotelDestination = location => dispatch => {
  dispatch({
    type: ACTION_TYPES.HOTELS_SET_DESTINATION,
    payload: { location, pending: false, openMenu: false }
  });
};

export const setCheckIn = date => dispatch => {
  dispatch({
    type: ACTION_TYPES.HOTELS_SET_CHECKIN,
    payload: date
  });
};

export const setCheckOut = date => dispatch => {
  dispatch({
    type: ACTION_TYPES.HOTELS_SET_CHECKOUT,
    payload: date
  });
};

export const addRoom = () => (dispatch, getState) => {
  const { roomInformation } = getState().hotels.search;
  if (roomInformation.length < limits.roomAmmount.max)
    dispatch({
      type: ACTION_TYPES.HOTELS_ADD_ROOM
    });
};

export const removeRoom = () => (dispatch, getState) => {
  const { roomInformation } = getState().hotels.search;
  if (roomInformation.length > limits.roomAmmount.min)
    dispatch({
      type: ACTION_TYPES.HOTELS_REMOVE_ROOM
    });
};

export const setAdults = (index, adultsCount) => dispatch => {
  dispatch({
    type: ACTION_TYPES.HOTELS_CHANGE_ADULTS,
    index,
    payload: adultsCount
  });
};

export const setChildren = (index, children) => dispatch => {
  dispatch({
    type: ACTION_TYPES.HOTELS_CHANGE_CHILDREN,
    index,
    payload: children
  });
};

export const setAllowPets = value => dispatch => {
  dispatch({
    type: ACTION_TYPES.HOTELS_SET_ALLOW_PETS,
    payload: value
  });
};

export const toggleMap = () => dispatch => {
  dispatch({
    type: ACTION_TYPES.HOTELS_TOGGLE_MAP
  });
};

export const getSuggestions = value => async dispatch => {
  dispatch({
    type: ACTION_TYPES.HOTELS_SUGGESTIONS_REQUEST,
    payload: { pending: true, value }
  });

  try {
    const { data } = await API.hotels.suggestions(value);
    return dispatch({
      type: ACTION_TYPES.HOTELS_SUGGESTIONS_SUCCESS,
      payload: {
        predictions: data.predictions || [],
        pending: false,
        openMenu: true,
        value
      }
    });
  } catch (error) {
    dispatch({
      type: ACTION_TYPES.HOTELS_SUGGESTIONS_FAILURE,
      payload: { pending: false, openMenu: false, predictions: [], value }
    });
    // eslint-disable-next-line no-console
    console.error('Error fetching hotels suggestions:', error);
  }
};

export const closeSuggestionsMenu = () => dispatch => {
  dispatch({
    type: ACTION_TYPES.HOTELS_SUGGESTIONS_CLOSE_MENU,
    payload: { openMenu: false }
  });
};

export const onTogglePets = () => (dispatch, getState) => {
  const { allowedPets } = getState().hotels.search;
  dispatch(setAllowPets(!allowedPets));
};

export const onChangeAdults = (index, type) => (dispatch, getState) => {
  const { roomInformation } = getState().hotels.search;
  const totalPeople = getTotalPeopleRoom(roomInformation[index]);

  switch (type) {
    case types.INCREMENT:
      if (totalPeople < limits.peoplePerRoom.max)
        dispatch(setAdults(index, roomInformation[index].adultsCount + 1));
      break;
    case types.DECREMENT:
      if (roomInformation[index].adultsCount > limits.adultsCount.min)
        dispatch(setAdults(index, roomInformation[index].adultsCount - 1));
      break;
    default:
      break;
  }
};

export const onChangeChildren = (index, type) => (dispatch, getState) => {
  const { roomInformation } = getState().hotels.search;
  const totalPeople = getTotalPeopleRoom(roomInformation[index]);

  switch (type) {
    case types.INCREMENT:
      if (totalPeople < limits.peoplePerRoom.max) {
        dispatch(
          setChildren(index, [...roomInformation[index].kidsAges, undefined])
        );
      }
      break;
    case types.DECREMENT:
      dispatch(
        setChildren(index, roomInformation[index].kidsAges.slice(0, -1))
      );
      break;
    default:
      break;
  }
};

export const onChangeChildrenAge = (index, childrenIndex) => value => (
  dispatch,
  getState
) => {
  const { roomInformation } = getState().hotels.search;
  const newKidsAges = [...roomInformation[index].kidsAges];
  newKidsAges[childrenIndex] = value.id;
  dispatch(setChildren(index, newKidsAges));
};

export const getPaxHotelActions = dispatch => ({
  addRoom: () => dispatch(addRoom()),
  removeRoom: () => dispatch(removeRoom()),
  onChangeAdults: (index, type) => () => dispatch(onChangeAdults(index, type)),
  onChangeChildren: (index, type) => () =>
    dispatch(onChangeChildren(index, type)),
  onChangeChildrenAge: (index, childrenIndex) => value =>
    dispatch(onChangeChildrenAge(index, childrenIndex)(value)),
  onTogglePets: () => dispatch(onTogglePets())
});

export const doValidation = () => (dispatch, getState) => {
  const search = getState().hotels.search;
  dispatch({
    type: ACTION_TYPES.HOTELS_VALIDATION_REQUEST
  });
  const { isValid, ...validations } = validateSearch(search);

  if (isValid) {
    dispatch({
      type: ACTION_TYPES.HOTELS_VALIDATION_SUCCESS
    });
    const destinationName = slugify(
      search.location.description.split(',')[0]
    ).toLowerCase();
    const requestObject = hotelRequestToUrl(search);
    dispatch(
      push({
        pathname: routes.hotelSearch.replace(':destination', destinationName),
        search: `?${requestObject}`
      })
    );
  } else {
    return dispatch({
      type: ACTION_TYPES.HOTELS_VALIDATION_FAILURE,
      payload: { isValid, validations }
    });
  }
};

const hotelSearchRequestSuccess = data => ({
  type: ACTION_TYPES.HOTELS_SEARCH_SUCCESS,
  payload: {
    ...data
  }
});

const hotelDetailsSuccess = data => ({
  type: ACTION_TYPES.HOTELS_PROPERTY_SUCCESS,
  payload: {
    ...data
  }
});

export const hotelSearchRequest = request => dispatch => {
  dispatch({
    type: ACTION_TYPES.HOTELS_SEARCH_REQUEST
  });
  API.hotels
    .search(request)
    .then(function (response) {
      const { data } = response;
      if (data.payload.length) {
        return dispatch(hotelSearchRequestSuccess(data));
      }
      return dispatch({
        type: ACTION_TYPES.HOTELS_SEARCH_FAILURE,
        payload: {
          statusCode: 404,
          messages: 'No encontramos resultados para tu búsqueda'
        }
      });
    })
    .catch(function (error) {
      dispatch({
        type: ACTION_TYPES.HOTELS_SEARCH_FAILURE,
        payload: {
          statusCode: parseInt(error.message.match(/\d+/)[0]),
          messages: 'El alojamiento no tiene disponibilidad para esas fechas'
        }
      });
      // eslint-disable-next-line no-console
      console.error('Error availability reducer hotels:', error);
    });
};

export const checkRate = (payload, isCorpo) => async dispatch => {
  dispatch({
    type: ACTION_TYPES.HOTELS_CHECKRATE_REQUEST
  });

  try {
    const { data } = await API.hotels.checkRates(
      payload.hotelInformation.checkRate
    );
    if (data.available) {
      const newPayload = { ...payload };
      if (data.newPrice) {
        newPayload.hotelInformation.rateChanged = true;
        newPayload.hotelInformation.packagePrice = data.newPrice;
      }
      if (data.newLocalPrice) {
        newPayload.hotelInformation.localPrice = data.newLocalPrice;
      }
      if (data.newRateComments) {
        newPayload.hotelInformation.rooms = newPayload.hotelInformation.rooms.map(
          room => {
            const match = data.newRateComments.find(
              comment => comment.rateKey === room.rateKey
            );
            if (match) {
              return { ...room, rateComments: match.newRateComments };
            }
          }
        );
      }
      if (data.rateCommentDetails) {
        const detailsWithoutRooms = newPayload.hotelInformation.details.slice(
          0,
          -newPayload.hotelInformation.rooms.length
        );
        const newDetails = [...detailsWithoutRooms, ...data.rateCommentDetails];
        newPayload.hotelInformation.details = newDetails;
      }
      dispatch(selectAvailability(newPayload));
      return dispatch(push(routes.hotelCheckout));

    }
    throw Error('Not available');
  } catch (error) {
    dispatch({
      type: ACTION_TYPES.HOTELS_CHECKRATE_FAILURE,
      payload: payload.hotelInformation.id
    });
  }
};

export const clearCheckRateError = () => dispatch =>
  dispatch({
    type: ACTION_TYPES.HOTELS_CHECKRATE_CLEAN_ERROR
  });

export const hotelPropertyRequest = request => dispatch => {
  dispatch({
    type: ACTION_TYPES.HOTELS_PROPERTY_REQUEST
  });
  API.hotels
    .hotelDetails(request)
    .then(function (response) {
      const { data } = response;
      dispatch(hotelDetailsSuccess(data));
    })
    .catch(function (error) {
      dispatch({
        type: ACTION_TYPES.HOTELS_PROPERTY_FAILURE,
        payload: {
          statusCode: parseInt(error.message.match(/\d+/)[0]),
          messages: 'El alojamiento no tiene disponibilidad para esas fechas'
        }
      });
    });
};

export const cleanError = () => dispatch => {
  dispatch({
    type: ACTION_TYPES.HOTELS_CLEAN_ERROR
  });
};

export const setDetailMode = value => dispatch => {
  dispatch({
    type: ACTION_TYPES.HOTELS_SET_DETAIL_MODE,
    payload: value
  });
};

export const resetHotelsEngine = () => dispatch => {
  dispatch({
    type: ACTION_TYPES.HOTELS_RESET_ENGINE
  });
};
