/**
 *
 * GloboToggle
 *
 */

import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import cs from 'classnames';

const GloboToggle = ({ className, active, onClick }) => (
  <div className={cs(className)} onClick={onClick}>
    <div className={cs('mv-globo-toggle_inner', { active: active })} />
  </div>
);

GloboToggle.propTypes = {
  className: PropTypes.string.isRequired,
  active: PropTypes.bool,
  onClick: PropTypes.func
};

export default styled(GloboToggle)`
  position: relative;
  display: inline-block;
  width: 28px;
  height: 28px;
  background-color: #c1c6c4;
  border-radius: 50%;
  cursor: pointer;

  .mv-globo-toggle_inner {
    width: 22px;
    height: 22px;
    border-radius: 50%;
    left: 50%;
    margin-left: -11px;
    top: 50%;
    margin-top: -11px;
    position: absolute;
    opacity: 0;
    background-image: linear-gradient(to bottom, #fb8400, #ff9901);
    transition: 0.2s;

    &.active {
      opacity: 1;
    }
  }
`;
