/**
 *
 * NewPackageSearch
 *
 */

import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import useBreakpoints from 'Utils/hooks/useBreakpoints';
import {
  loadFilters,
  onResetFilters,
  onUpdateFilters,
  packagesRequest,
  setActiveStep
} from '../../actions';
import { resetToEngine } from 'ReduxConfig/availability';
import { packagesWhenType } from 'Constants';
import qs from 'query-string';

import DesktopPackageSearch from './DesktopPackageSearch';
import MobilePackageSearch from './MobilePackageSearch';
import { filterKeys, orderByOptions } from 'Features/packages/constants';

const selector = ({ packages, router, availability, appConfiguration }) => ({
  engine: availability.engine,
  queryString: router.location.search,
  departures: packages.departures,
  where: packages.searcher.where,
  when: packages.searcher.when,
  how: packages.searcher.how,
  departureOptions: appConfiguration?.packagesConfig?.packageDepartures || [],
  nearAirportCode: appConfiguration?.localize?.nearAirportCode || null,
  orderByName: packages.filterData.orderByName,
  orderBy: packages.filterData[filterKeys.ORDER_BY],
  region: packages.filterData[filterKeys.REGION],
  city: packages.filterData[filterKeys.CITY],
  departure: packages.filterData[filterKeys.DEPARTURE],
  stateWhen: packages.filterData[filterKeys.WHEN],
  stateHow: packages.filterData[filterKeys.HOW],
  text: packages.filterData[filterKeys.TEXT],
  quantity: packages.filtered.length,
  selectedTabId: packages.activeStep,
  orderByOptions
});

export const ConnectedComponent = props => {
  const state = useSelector(selector);
  const dispatch = useDispatch();
  const [selectedDepartureOption, setSelectedDepartureOption] = useState(null);
  const {
    engine,
    region,
    stateWhen,
    stateHow,
    queryString,
    departureOptions,
    nearAirportCode,
    departure
  } = state;
  const getOriginalDeparture = qs.parse(queryString);

  const actions = {
    getPackages: val => dispatch(packagesRequest(val)),
    updateFilters: (val, key) => {
      dispatch(onUpdateFilters(val, key, queryString));
    },
    setEngine: () => {
      dispatch(resetToEngine('paquetes'));
    },
    resetFilters: () => {
      dispatch(onResetFilters());
    },
    handleSelectTab: tabId => () => {
      dispatch(setActiveStep(tabId));
    },
    handleFilterByText: text => {
      actions.updateFilters(filterKeys.TEXT, text);
    },
    handleSelectRegion: objRegion => () => {
      if (region === null) {
        actions.updateFilters(filterKeys.REGION, objRegion);
      } else {
        actions.updateFilters(
          filterKeys.REGION,
          region.id === objRegion.id ? null : objRegion
        );
      }
    },
    handleSelectSeason: objSeason => () => {
      if (stateWhen === null || stateWhen.option === null) {
        actions.updateFilters(filterKeys.WHEN, {
          type: packagesWhenType.SEASON,
          option: objSeason
        });
      } else {
        if (stateWhen.option.id === objSeason.id) {
          actions.updateFilters(filterKeys.WHEN, null);
        } else {
          actions.updateFilters(filterKeys.WHEN, {
            type: packagesWhenType.SEASON,
            option: objSeason
          });
        }
      }
    },
    handleSelectMonth: objMonth => () => {
      if (stateWhen === null || stateWhen.option === null) {
        actions.updateFilters(filterKeys.WHEN, {
          type: packagesWhenType.MONTH,
          option: objMonth
        });
      } else {
        if (stateWhen.option.id === objMonth.id) {
          actions.updateFilters(filterKeys.WHEN, null);
        } else {
          actions.updateFilters(filterKeys.WHEN, {
            type: packagesWhenType.MONTH,
            option: objMonth
          });
        }
      }
    },
    handleSelectHow: objHow => () => {
      if (stateHow === null) {
        actions.updateFilters(filterKeys.HOW, objHow);
      } else {
        actions.updateFilters(
          filterKeys.HOW,
          stateHow.id === objHow.id ? null : objHow
        );
      }
    },
    handleUpdateOrderBy: val => () => {
      actions.updateFilters(filterKeys.ORDER_BY, val);
    },
    cleanHow: () => {
      actions.updateFilters(filterKeys.HOW, null);
    },
    cleanWhen: () => {
      actions.updateFilters(filterKeys.WHEN, null);
    },
    cleanRegion: () => {
      actions.updateFilters(filterKeys.REGION, null);
    },
    loadFilters: () => {
      dispatch(loadFilters(queryString));
    }
  };

  useEffect(() => {
    if (engine && engine !== 'paquetes') {
      actions.setEngine();
    }
  }, [engine]);

  const handleSelectDeparture = option => {
    actions.updateFilters(filterKeys.DEPARTURE, option.code);
  };

  const getData = async option => {
    await actions.getPackages(option);
    actions.loadFilters();
  };

  useMemo(() => {
    departure && getData(departure);
  }, [departure]);

  useMemo(() => {
    if (departureOptions.length > 0) {
      const originalDeparture =
        getOriginalDeparture[filterKeys.DEPARTURE] || 'TODOS';
      const getQueryAndFindDeparture =
        originalDeparture === null
          ? departureOptions[0]
          : departureOptions.find(
              departure => departure.code === originalDeparture
            ) || departureOptions[0];
      handleSelectDeparture(getQueryAndFindDeparture);
      departureOptions.length > 0 &&
        setSelectedDepartureOption(getQueryAndFindDeparture);
    }
    return () => {
      actions.resetFilters();
    };
  }, [departureOptions, nearAirportCode]);

  const { largeDown } = useBreakpoints();
  return largeDown ? (
    <MobilePackageSearch
      actions={actions}
      selectedDepartureOption={selectedDepartureOption}
      handleSelectDeparture={handleSelectDeparture}
      {...props}
      {...state}
    />
  ) : (
    <DesktopPackageSearch
      actions={actions}
      selectedDepartureOption={selectedDepartureOption}
      handleSelectDeparture={handleSelectDeparture}
      {...props}
      {...state}
    />
  );
};

export default ConnectedComponent;
