import { packageSteps } from 'Constants';

export const orderByOptions = {
  NOMBRE: 'Nombre',
  DURACION: 'Duración',
  PRECIO: 'Precio'
};

export const filterKeys = {
  WHEN: 'cuando',
  HOW: 'como',
  REGION: 'donde',
  ORDER_BY: 'orden',
  TEXT: 'texto',
  CITY: 'ciudad',
  DEPARTURE: 'origen'
};

export const initialFilterData = {
  orderByName: false,
  [filterKeys.ORDER_BY]: orderByOptions.PRECIO,
  [filterKeys.REGION]: null,
  [filterKeys.CITY]: null,
  [filterKeys.WHEN]: null,
  [filterKeys.HOW]: null,
  [filterKeys.TEXT]: '',
  [filterKeys.DEPARTURE]: null
};

export const initialState = {
  status: 'unused',
  activeStep: packageSteps[0].id,
  departures: [],
  searcher: {
    where: [],
    when: {
      months: [],
      seasons: []
    },
    how: []
  },
  sorts: [],
  results: [],
  filterData: initialFilterData,
  filtered: [],
  upSelling: [],
  loading: false,
  firstName: '',
  showModal: false,
  packageItemData: null,
  form: {}
};
