/* eslint-disable react/prop-types */
/* eslint-disable react/jsx-boolean-value */
import React from 'react';
import PropTypes from 'prop-types';
import vuelo from 'Assets/packageIcons/vuelos.svg';
import hotel from 'Assets/packageIcons/hotel.svg';
import auto from 'Assets/packageIcons/auto.svg';
import ticket from 'Assets/packageIcons/tickets.svg';
import equipaje from 'Assets/packageIcons/equipaje.svg';
import traslado from 'Assets/packageIcons/traslados.svg';
import allinclusive from 'Assets/packageIcons/all-inclusive.svg';
import asistencia from 'Assets/packageIcons/seguro.svg';
import circuito from 'Assets/packageIcons/citcuito.svg';
import tour from 'Assets/packageIcons/tour.svg';
import algunacomida from 'Assets/packageIcons/algunas-comidas.svg';
import desayuno from 'Assets/packageIcons/desayuno.svg';
import crucero from 'Assets/packageIcons/crucero.svg';
import ceremonia from 'Assets/packageIcons/ceremonia.svg';
import ferry from 'Assets/packageIcons/ferry.svg';
import mediapension from 'Assets/packageIcons/media-pension.svg';

// import { getFormatMoney } from 'Utils';

// TODO: solucionar el tema de Duracion y Fechas

import { DesktopPackageAvailabilityCard } from './PackageAvailabilityCard';
//import Price from 'Components/Price';

const iconMappings = {
  'mv-hotel': hotel,
  'mv-flights': vuelo,
  'mv-transport': traslado,
  'mv-activities': ticket,
  suitcase: equipaje,
  'mv-cars': auto,
  'mv-cocktail': allinclusive,
  'mv-assistance': asistencia,
  'mv-circuit': circuito,
  'mv-photo-camara': tour,
  'mv-family-restaurant': algunacomida,
  'mv-breakfast': desayuno,
  'mv-crucero': crucero, //no existe en mv el icon aun
  'mv-marriage': ceremonia,
  'mv-dinner-high': mediapension,
  'mv-ferry': ferry //no existe en mv el icon aun
};
const defaultIcon = allinclusive;

const PackageAvailabilityCard = ({
  className,
  currency,
  price,
  title,
  image,
  icons,
  duration,
  lowerLocalPrice,
  actions,
  orderBy,
  orderByOptions,
  //travelOn,
  url,
  basePrice,
  rating
  // eslint-disable-next-line arrow-body-style
}) => {
  return (
    <>
      <DesktopPackageAvailabilityCard
        price={price}
        className={className}
        currency={currency}
        title={title}
        image={image}
        icons={icons}
        duration={duration}
        lowerLocalPrice={lowerLocalPrice}
        url={url}
        actions={actions}
        orderBy={orderBy}
        orderByOptions={orderByOptions}
        basePrice={basePrice}
        rating={rating}
        iconMappings={iconMappings}
        defaultIcon={defaultIcon}
      />
    </>
  );
};

PackageAvailabilityCard.propTypes = {
  className: PropTypes.string,
  currency: PropTypes.string.isRequired,
  price: PropTypes.number.isRequired,
  title: PropTypes.string.isRequired,
  image: PropTypes.string.isRequired,
  duration: PropTypes.string,
  travelOn: PropTypes.string,
  icons: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      icon: PropTypes.string.isRequired
    })
  ),
  lowerLocalPrice: PropTypes.object,
  color: PropTypes.string.isRequired,
  index: PropTypes.number.isRequired,
  url: PropTypes.string.isRequired,
  basePrice: PropTypes.number.isRequired,
  rating: PropTypes.shape({
    name: PropTypes.string,
    tagColor: PropTypes.string
  })
};

export default PackageAvailabilityCard;
