/* eslint-disable react/prop-types */
import React from 'react';
import cs from 'classnames';
import LazyImg from 'Components/LazyImg';
import useBreakpoints from 'Utils/hooks/useBreakpoints';
import logoItau from 'Assets/logo_naranja.svg';
import { useSelector } from 'react-redux';
import {
  CardContainer,
  Currency,
  Base,
  CurrencyHolder,
  IconsHolder,
  Price,
  PriceHolder,
  PriceTag,
  PromoPriceTag,
  ServiceIcon,
  Content,
  ServiceItem,
  ServiceText,
  ViewMore,
  BaseType,
  From,
  ContentButtonSee,
  YellowBoxText
} from './PackageAvailabilityCard.styles';
import { Link } from 'react-router-dom';
import { getBasePriceString } from 'Features/packages/helpers';

export const DesktopPackageAvailabilityCard = ({
  // eslint-disable-next-line react/prop-types
  currency,
  basePrice,
  price,
  title,
  image,
  icons,
  url,
  actions,
  orderBy,
  orderByOptions,
  iconMappings,
  defaultIcon
}) => {
  const {
    selectedItauPoints,
    itauPoints: { USD }
  } = useSelector(state => state.appConfiguration);
  const { largeDown, mediumDown } = useBreakpoints();
  const layout = cs({
    'one-column-container': mediumDown,
    'two-column-container': largeDown && !mediumDown,
    'three-column-container': !largeDown && !mediumDown
  });
  const costCurrencyInPoints = USD;
  const priceInPoints = Math.ceil(price / costCurrencyInPoints);
  const moneyInPoints = selectedItauPoints * costCurrencyInPoints;
  const moneyToComplete = price - moneyInPoints;
  const pointsToRender =
    selectedItauPoints <= priceInPoints
      ? selectedItauPoints.toLocaleString('en').replaceAll(',', '.')
      : priceInPoints.toLocaleString('en').replaceAll(',', '.');
  // const [basePriceFirst, basePriceSecond] = getBasePriceString(basePrice);

  return (
    <Base className={cs(layout)}>
      <Link to={url}>
        <CardContainer>
          <LazyImg src={image} alt={title} className="img" height={258} />
          {title && <YellowBoxText>{title}</YellowBoxText>}
          <PriceHolder>
            <PriceTag>
              <Content>
                <From>Desde</From>
                <CurrencyHolder>
                  <Currency>{currency}</Currency> <Price>{price}</Price>
                </CurrencyHolder>
              </Content>
              <BaseType>
                {getBasePriceString(basePrice)[0]}{' '}
                {getBasePriceString(basePrice)[1]}
              </BaseType>
            </PriceTag>
            <PromoPriceTag>
              <span>{pointsToRender} pts</span>
              <img src={logoItau} alt="itau" />
              <span>
                + USD {moneyToComplete < 0 ? 0 : Math.ceil(moneyToComplete)}
              </span>
            </PromoPriceTag>
          </PriceHolder>
        </CardContainer>
        <IconsHolder>
          {icons &&
            icons.map((icon, index) => (
              <>
                <ServiceItem key={index}>
                  <ServiceIcon>
                    <img
                      src={iconMappings[icon.icon] || defaultIcon}
                      alt={icon.name}
                    />
                  </ServiceIcon>
                  <ServiceText>{icon.name}</ServiceText>
                </ServiceItem>
              </>
            ))}
        </IconsHolder>
        <ContentButtonSee>
          <ViewMore>Ver más</ViewMore>
        </ContentButtonSee>
        {/* <div className="package-availability-card-container">
          <div className="package-availability-card-image">
            <LazyImg src={image} alt={title} className="img" height={259} />
            {rating ? (
              <div className="tag-holder">
                <Text context="white" weight="semi-bold">
                  {rating && rating.name}
                </Text>
              </div>
            ) : (
              ''
            )}
            <div className="icons-holder">
              {icons &&
                icons.map((icon, index) => (
                  <Icon key={index} name={icon.icon} color="white" size={20} />
                ))}
            </div>
          </div>
          <div className="title-holder">
            <Text paragraph weight="semi-bold" context="secondary" size="large">
              {title}
            </Text>
          </div>

          <div className="description-holder">
            <div>
              <Text size="small" paragraph>
                {duration}
              </Text>
              
              {icons ? (
                <Text size="small" paragraph weight="semi-bold">
                  Incluye:
                </Text>
              ) : null}
              {icons &&
                icons.map((icon, index) => (
                  <Text key={index} size="small" paragraph>
                    {' '}
                    *{icon.name}{' '}
                  </Text>
                ))}
            </div>
            <div className="prices-holder">
              <Price currency={currency} price={price} />
              {lowerLocalPrice && (
                <Text
                  context="important"
                  alignment="right"
                  paragraph
                  size="small">
                  {getFormatMoney(
                    lowerLocalPrice.currency,
                    lowerLocalPrice.amount
                  )}
                </Text>
              )}
              <Text context="important" alignment="right" size="small">
                {basePriceFirst}
              </Text>
              <Text
                weight="semi-bold"
                context="important"
                alignment="right"
                size="small">
                {basePriceSecond}
              </Text>
            </div>
          </div>
        </div> */}
      </Link>
    </Base>
  );
};
