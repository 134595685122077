import React, { useEffect, useState } from 'react';
import styled from 'styled-components';

import { ReactComponent as FlechaArriba } from './FlechaArriba.svg';
import { ReactComponent as Itau } from './itauMobile.svg';

import { Steps } from '../../types';
import { Stepper } from '../Stepper';
import { Title } from 'Containers/AirCheckout/styles';
import { PaxFields } from '../PaxFields';
import { BillingFields } from '../BillingFields';
import { PaymentStep } from '../PaymentStep';
import { useSelector } from 'react-redux';
import { useFormContext } from 'react-hook-form';
import {
  ButtonsHolder,
  Container,
  DetailItau,
  DetailLocal,
  DetailPerse,
  DetailsHolder,
  DetalleButton,
  LocalRow1,
  LocalRow2,
  LocalRow3,
  NavButton,
  OrangeHeader,
  StickyBottom
} from './styles';
import { SideDetails } from '../SideDetails';

interface Props {
  activeStep: Steps;
  fields: Record<'id', string>[];
  onRequest: ({
    selectedInterventajasPoints,
    selectedMembershipPoints,
    moneyToComplete,
    pointsToRender
  }) => void;
  onPreviousStep: () => void;
  onNextStep: () => void;
  titularIndex: number;
  onSelectTitular: (number) => void
  checkinTime: string;
  checkoutTime: string;
  checkinDate: string;
  checkoutDate: string;
  rooms: any[];
  policy: any[];
  totalPrice: number;
  pricePerNight: number;
  peopleByRoom: any;
}

export const HotelCheckoutMobile = ({
  activeStep,
  fields,
  onRequest,
  onNextStep,
  onPreviousStep,
  checkinTime,
  checkoutTime,
  checkinDate,
  checkoutDate,
  titularIndex,
  rooms,
  policy,
  totalPrice,
  pricePerNight,
  peopleByRoom,
  onSelectTitular,
}: Props) => {
  const {
    selectedItauPoints,
    itauPoints: { PYG }
  } = useSelector(
    // @ts-ignore
    state => state.appConfiguration
  );

  const [showDetalle, setShowDetalle] = useState(false);
  const toggleShowDetalle = () => setShowDetalle(prevState => !prevState);

  const costCurrencyInPoints = PYG;
  const priceInPoints = totalPrice * costCurrencyInPoints;
  const moneyInPoints = selectedItauPoints / costCurrencyInPoints;
  const moneyToComplete =
    Math.ceil((totalPrice - moneyInPoints) * 100) / 100;
  const pointsToRender =
    selectedItauPoints <= priceInPoints
      ? selectedItauPoints.toLocaleString('en').replaceAll(',', '.')
      : priceInPoints.toLocaleString('en').replaceAll(',', '.');

  const { setValue } = useFormContext();

  useEffect(() => {
    for (let i = 0; i < fields.length; i++) {
      setValue(`passengers.${i}.docType`, 1);
      setValue(`passengers.${i}.nationality`, 'PRY');
    }
  }, [setValue, fields]);

  const handleCheckTitular = (val: number) => () => {
    onSelectTitular(val);
  }

  return (
    <Container>
      <OrangeHeader>COMPLETÁ TUS DATOS Y FINALIZÁ TU COMPRA</OrangeHeader>
      <Stepper activeStep={activeStep} />

      {activeStep === Steps.paxData && (
        <>
          <Title>Pasajeros</Title>
          {fields.map((field, index) => (
            <PaxFields key={field.id} index={index} isTitular={index === titularIndex} onCheckTitular={handleCheckTitular(index)} />
          ))}

          {/* <PaxFields title="Joven 1" /> */}
        </>
      )}

      {activeStep === Steps.billingData && (
        <>
          <Title>Datos de facturación</Title>
          <BillingFields />
        </>
      )}

      {activeStep === Steps.payment && <PaymentStep onRequest={onRequest} />}

      <StickyBottom>
        <DetalleButton onClick={toggleShowDetalle}>
          Detalle <FlechaArriba />
        </DetalleButton>

        <DetailPerse show={showDetalle}>
          <SideDetails
            checkinTime={checkinTime}
            checkoutTime={checkoutTime}
            checkinDate={checkinDate}
            checkoutDate={checkoutDate}
            totalPrice={totalPrice}
            pricePerNight={pricePerNight}
            peopleByRoom={peopleByRoom}
            rooms={rooms}
            policy={policy}
          />
        </DetailPerse>

        <DetailsHolder>
          <DetailLocal>
            <LocalRow1>Precio total final</LocalRow1>
            <LocalRow2>
              PYG {totalPrice}
            </LocalRow2>
          </DetailLocal>

          <DetailItau>
            <div>
              ó <span>{pointsToRender}</span> puntos <Itau />
            </div>
            <div>
              +{' '}
              <span>
                PYG {moneyToComplete}
              </span>
            </div>
          </DetailItau>
        </DetailsHolder>

        <ButtonsHolder>
          {activeStep !== Steps.paxData ? (
            <NavButton onClick={onPreviousStep}>Atrás</NavButton>
          ) : (
            <div />
          )}

          {activeStep !== Steps.payment ? (
            <NavButton onClick={onNextStep}>Siguiente</NavButton>
          ) : (
            <div />
          )}
        </ButtonsHolder>
      </StickyBottom>
    </Container>
  );
};
