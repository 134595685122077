/**
 *
 * TermsAndConditions
 *
 */

// Vendors
import React from 'react';

// Components
import Text from '../Text';

const TermsAndConditions = () => (
  <div style={{ margin: '10px' }}>
    <Text paragraph alignment="justify">
      Los términos y condiciones (en adelante denominados “T&C”)
      aquí establecidos son aplicables al uso del sitio web viajes.tiendanaranja.com.py
      administrada por Real Consulting S.A., bajo el nombre comercial Tienda Naranja Viajes , (conjuntamente la página web o cualquier aplicación denominado “Sitio Web”)
      y a cualquier relación que el Cliente tenga con viajes.tiendanaranja.com.py,
      ya sea en forma telefónica o presencial. Los términos “nosotros”, “nos”, “nuestro”, “Tienda Naranja Viajes”,
      y “viajes.tiendanaranja.com.py” hacen referencia a Real Consulting S.A.,
      una sociedad de la República del Paraguay, registrada ante el Ministerio de Turismo mediante la Resolución No. 1263/2019,
      con domicilio legal en O'leary 966 c/Manduvirá, Asunción Los términos “Cliente” “tu” u otros hacen referencia a quien pretende recibir servicios turísticos de Tienda Naranja Viajes.
    </Text>

    <br />

    <Text paragraph alignment="justify">
      Los términos y condiciones (en adelante denominados “T&C”) aquí establecidos
      son aplicables al uso del sitio web, Lea los T&C atentamente, ya que contienen información relacionada
      con sus obligaciones, derechos y limitaciones sobre estos derechos, así como también una sección relacionada
      con las leyes aplicables y la jurisdicción en caso de disputas. En caso de no aceptar estos Términos,
      evita usar el Sitio Web.
    </Text>

    <br />

    <Text paragraph alignment="justify">
      Al registrarse en el Sitio Web, aceptas expresamente los T&C y declaras haber leído y comprendido la
      totalidad de todos sus puntos. Al contratar servicios a través de Tienda Naranja Viajes, garantizas que:
      (i) eres mayor de edad, (ii) tienes capacidad para celebrar contratos y obligarte, (ii) solo utilizarás el Sitio Web para reservar o
      contratar servicios para ti y/o para la/s persona/s para quien tengas autorización de actuar.
    </Text>

    <br />

    <Text paragraph alignment="justify">
      Asimismo, debes leer nuestra Política de Privacidad, la cual rige también el uso del Sitio Web y establece las
      condiciones en las que Tienda Naranja Viajes puede procesar cualquier información personal que recopila sobre ti o que nos proporciones.
      La Política de Privacidad se puede consultar aquí. Te recomendamos que imprimas una copia de estos T&C y
      de la Política de Privacidad y las conserves.
    </Text>

    <br />

    <Text paragraph alignment="justify">
      Tienda Naranja Viajes, no es propietario de, ni administra los servicios o productos de viaje que aparecen en el Sitio Web,
      como vuelos, alojamiento, autos en alquiler, o seguros (los "Productos de Viaje").
      Los Productos de Viaje son propiedad de, son administrados o son puestos a disposición por terceros (el “Proveedor” o los “Proveedores”)
      de forma directa (p. ej., una aerolínea) o por agencias mayoristas o receptivos.
    </Text>

    <br />
    <Text paragraph alignment="justify"><strong>Reservas hechas a través del Sitio Web.</strong></Text>
    <br />
    <Text paragraph alignment="justify">
      Las reservas de Productos de Viaje que hagas a través del Sitio Web, en nuestras oficinas comerciales o a través de las
      líneas telefónicas, se efectúan con el Proveedor mencionado y Tienda Naranja Viajes solo actúa como	intermediario.
    </Text>

    <br />

    <Text paragraph alignment="justify">
      En línea con lo anterior, Tienda Naranja Viajes  no será responsable, a modo de ejemplo y sin que implique limitación alguna si:
    </Text>

    <br />

    <ul>
      <li>
        <Text paragraph alignment="justify">
          Una vez emitidos los boletos aéreos, vouchers de estadías en los hoteles u otros, tu cambias las fechas, rutas, devuelves y/o anulas de forma total o parcial los pasajes, vouchers u otros. Dadas dichas situaciones, estarás sujeto a penalidades previstas por los Proveedores para cada caso. En relación con dichas penalidades, las que incluso pueden derivar en la pérdida total de la suma abonada, como Cliente reconoces y aceptas que no tendrás derecho al reembolso de las sumas que abonaste ni a compensación alguna en caso de que los pasajes, vouchers u otros no admitan devolución y la penalidad consista en la pérdida de la totalidad de lo abonado.
        </Text>
      </li>
      <li>
        <Text paragraph alignment="justify">
          No se logra que se posterguen o adelanten las fechas estipuladas en cada caso, así como los cambios de ruta, hotel, devoluciones y/o anulaciones, totales o parciales, solicitadas por el Cliente. Las mismas podrán realizarse siempre que sea admisible de acuerdo con las modalidades, condiciones y disponibilidad de los Proveedores, debiendo el Cliente abonar la correspondiente penalidad y eventual diferencia de tarifa determinadas por estas, sumándose asimismo los gastos administrativos de Tienda Naranja Viajes por la gestión, cuando fuera aplicable. En todo caso de reintegro, ya sea total o parcial, aceptas que Tienda Naranja VIajes retendrá las sumas correspondientes a gastos administrativos y comisión, no teniendo tú como Cliente derecho a reclamación alguna.
        </Text>
      </li>
      <li>
        <Text paragraph alignment="justify">
          Desistes por cualquier causa de los servicios, ni por los servicios que no fueran tomados por este en el tiempo y forma convenidos, ni por las consecuencias que de ello emanen, como ser la pérdida de los pasajes, vouchers u otros y de la totalidad de lo abonado sin derecho a devolución ni compensación de clase alguna, u otros eventuales daños y/o perjuicios, entre otras.
        </Text>
      </li>
      <li>
        <Text paragraph alignment="justify">
          Se diera una situación de deterioro, pérdida, extravío, hurto o robo ni cualquier otra cosa que suceda respecto del equipaje y demás efectos personales del Cliente, ni respecto de la persona del Cliente.
        </Text>
      </li>
    </ul>



    <Text paragraph alignment="justify">
      Específicamente, dado que muchas compañías aéreas prohíben eludir normas como la compra de vuelos o la compra de vuelos con ciudad,
      declaras conocer que esto puede llevar a cabo acciones como la cancelación de los vuelos, la denegación de
      embarque, la revocación de los beneficios de inclusión en programas de viajero frecuente y otros beneficios,
      cargos adicionales a la tarjeta de crédito del comprador, cargos adicionales en el aeropuerto, o facturación
      a futuro si se detecta el ejercicio de prácticas prohibidas. Estas sanciones son impuestas por los Proveedores en virtud
      de la actitud del Cliente, no teniendo Tienda Naranja Viajes intervención alguna.
    </Text>


    <br />

    <Text paragraph alignment="justify"> <strong>Propiedad intelectual</strong></Text>

    <br />

    <Text paragraph alignment="justify">
      Nosotros, junto a nuestros socios corporativos, los Proveedores y otros licenciatarios, somos los propietarios de
      todo el texto, imágenes, software, marcas comerciales, marcas de servicio y cualquier otro material contenido
      en el Sitio Web. No está permitido copiar ni divulgar el contenido, excepto para uso personal y no comercial.
      Todos los avisos de copyright, marcas comerciales y otros derechos de propiedad incluidos en el Sitio Web
      deberán aparecer en todas las copias que imprimas. El resto de los productos, servicios o denominaciones que
      aparecen en el Sitio Web pertenecen a los respectivos terceros y solo pueden mencionarse en el Sitio Web
      con fines identificativos. Para obtener información más completa sobre dichas denominaciones y su registro,
      ponte en contacto con los terceros correspondientes. El uso de y el acceso al Sitio Web no te otorga ninguna
      licencia ni derecho de uso de cualquiera de las marcas incluidas en la misma.
    </Text>
    <br />
    <Text paragraph alignment="justify">
      En lo que respecta a las fotografías o imágenes de los hoteles, aviones, destinos y otros, Tienda Naranja Viajes no se
      responsabiliza por las mismas, sino que simplemente exhibe las proporcionadas por los propios Proveedores,
      a los meros efectos ilustrativos, situación que conoces y aceptas no teniendo nada que reclamar al respecto.
    </Text>

    <br />
    <Text paragraph alignment="justify"> <strong>Uso de Nuestra web</strong></Text>
    <br />
    <Text paragraph alignment="justify">
      Solo puedes utilizar y registrarte como usuario del Sitio Web y adquirir los Productos de Viaje que comercializamos
      si eres mayor de edad y puedes celebrar contratos legales. Si decides registrarte como usuario y reservar de
      manera que como resultado crees una cuenta de Tienda Naranja VIajes, serás responsable de mantener en secreto
      tus contraseñas, así como los datos de inicio de sesión y de tu cuenta. Serás responsable de todo el uso
      que hagan desde el Sitio Web tú o quienquiera que use tu contraseña e información de inicio de sesión
      (con o sin tu permiso) y de cualquiera a quien hayas permitido acceder a tus itinerarios de viaje.
      Toda la información que como Cliente nos remitas deberá ser precisa y actualizada.
      Si alguna información cambia, debes actualizarla de inmediato, no debiendo Tienda Naranja Viajes estar
      obligado a conocer el cambio antes de la actualización. Si tienes alguna razón para pensar que tu cuenta
      ya no está segura (por ejemplo: por pérdida, robo de tus dispositivos móviles o divulgación o uso no autorizado
      de tu información, la computadora o el dispositivo móvil empleados para acceder al Sitio Web),
      debes cambiar de inmediato la información personal afectada.
    </Text>
    <br />
    <Text paragraph alignment="justify">
      Si decides recibir mensajes u otro tipo de comunicaciones del Sitio Web de forma directa en tu dispositivo móvil, será tu responsabilidad
      actualizar tu número de teléfono, y asumir cualquier cargo incurrido por recibir dichos mensajes.
      No asumimos responsabilidad alguna por la información enviada a un dispositivo asociado con la información
      desactualizada de tu número de teléfono o por el uso de una aplicación móvil desactualizada.
      Si instalas cualquier software o activas cualquier servicio que almacene información del Sitio Web en cualquier
      dispositivo móvil o computadora, será tu responsabilidad, antes de transferir o deshacerte del dispositivo,
      de borrar tu información o deshabilitar el acceso a dicho software o servicio para evitar de esa manera el
      acceso sin autorización a tu información o tu cuenta.
    </Text>
    <br />
    <Text paragraph alignment="justify">
      El Sitio Web solo puede usarse para realizar búsquedas de ofertas de viaje legítimas, y está prohibido usarla
      para realizar reservaciones falsas, fraudulentas o especulativas, así como reservaciones en anticipación de
      demanda. Al hacer uso del Sitio Web, aceptas cumplir con las leyes aplicables a Paraguay, incluso las leyes
      en materia de exportación de datos técnicos.
    </Text>
    <br />
    <Text paragraph alignment="justify">
      Como usuario del Sitio Web, eres responsable de todo el contenido (texto, fotos, reseñas, enlaces, etc.) que
      compartes. Debes garantizar que dispones de todos los derechos sobre cualquier contenido que publiques en el
      Sitio Web y que el contenido que publiques en el Sitio Web no infringe derechos de terceros. En especial,
      garantizas que no crearás ni compartirás ningún contenido:
    </Text>
    <ul>
      <li>
        <Text paragraph alignment="justify">
          que sea publicidad encubierta con la apariencia de una crítica;
        </Text>
      </li>
      <li>
        <Text paragraph alignment="justify">
          que sea deliberadamente falso;
        </Text>
      </li>
      <li>
        <Text paragraph alignment="justify">
          que sea inmoral, pornográfico u ofensivo en cualquier otro modo;
        </Text>
      </li>
      <li>
        <Text paragraph alignment="justify">
          que infrinja los derechos de terceros, en especial derechos de propiedad intelectual o industrial;
        </Text>
      </li>
      <li>
        <Text paragraph alignment="justify">
          que infrinja en cualquier modo la legislación vigente o constituya una infracción penal;
        </Text>
      </li>
      <li>
        <Text paragraph alignment="justify">
          que contenga virus u otros programas informáticos que puedan dañar el software o el hardware, o que puedan perjudicar el uso de los ordenadores;
        </Text>
      </li>
      <li>
        <Text paragraph alignment="justify">
          que sea una encuesta o carta en cadena;
        </Text>
      </li>
      <li>
        <Text paragraph alignment="justify">
          que tenga como objetivo recopilar o utilizar datos personales de otros usuarios, sobre todo con fines comerciales.
        </Text>
      </li>
    </ul>

    <Text paragraph alignment="justify">
      <i>
        Si la actividad que realizas en el Sitio Web nos muestre indicios de fraude, abuso o actividad sospechosa,
        podremos denegarte los servicios y cerrarte cualquier cuenta que tuvieras asociada a Tienda Naranja Viajes.
        Si hubieras realizado cualquier actividad fraudulenta, Tienda Naranja Viajes se reserva el derecho de tomar
        cualquier medida legal necesaria y podrías ser responsable de los daños causados a Tienda Naranja Viajes.

      </i>
    </Text>
    <br />

    <Text paragraph alignment="justify"> <strong>Exención de responsabilidad sobre la garantía</strong></Text>
    <br />
    <Text paragraph alignment="justify">
      Nuestro Sitio Web, y en las oficinas comerciales todo el contenido y los servicios disponibles se ofrecen tal
      y como están y según disponibilidad. Parte de nuestro contenido se genera de forma automática, por lo que se pueden producir errores.
      Por lo general, los resultados de búsqueda son cuantiosos y exactos, pero las búsquedas no son exhaustivas ni
      muestran todos los Proveedores y las ofertas disponibles en el mercado. Por lo tanto, no siempre mostramos el
      precio más bajo disponible. Tienda Naranja Viajes se exonera de forma explícita de cualquier responsabilidad
      y no presta garantía alguna, explícita o implícita, incluyendo, entre otras, garantías implícitas sobre la
      comerciabilidad, idoneidad para un objetivo en particular, título, no incumplimiento, y seguridad y precisión,
      así como cualquier garantía que pueda surgir del uso o comercio, acuerdo o transcurso de ejecución.
    </Text>
    <br />
    <Text paragraph alignment="justify"> <strong>Limitación de responsabilidad</strong></Text>
    <br />
    <Text paragraph alignment="justify">
      Nosotros (junto con nuestros responsables, directores, empleados, representantes, accionistas, asociados y
      proveedores), al igual que Tienda Naranja en los mismos términos, de acuerdo con el marco legal, no nos hacemos
      responsables de (a) cualquier pérdida o daño, o virus que pueden infectar tu equipo u otra propiedad
      como resultado del acceso al Sitio Web, la descarga de contenido de la misma, o el uso del mismo;
      (b) cualquier lesión, muerte, pérdida, reclamación, causa de fuerza mayor, accidente, retraso o cualquier
      daño directo, especial, ejemplar, punitivo, indirecto, fortuito o importante de cualquier
      tipo (incluyendo, entre otras, la pérdida de beneficios o ahorros) que se derive de un contrato, agravio,
      responsabilidad objetiva o cualquier otra, que surjan o que estén de alguna forma conectados con: (i) el
      uso del Sitio Web o de nuestro contenido; (ii) cualquier fallo o retraso (incluida, entre otros, el uso o
      la imposibilidad de usar algún componente del Sitio Web para acceder a reservaciones o compras);
      (iii) la ejecución o incumplimiento nuestro o de alguno de los Proveedores, incluso en el supuesto
      de que se nos comunique de la posibilidad de que tengan lugar dichos daños a dichos terceros o a
      cualquier otro (dentro de los límites admitidos por la normativa).
    </Text>
    <br />

    <Text paragraph alignment='justify'>
      <strong>Ahora es tu turno, ¡protégenos como lo hacemos nosotros!</strong>
    </Text>
    <br />
    <Text paragraph alignment='justify'>
      Según lo estipulado en los T&C, te comprometes a proteger, indemnizar y eximir de responsabilidad a cada
      uno de los responsables, directores, empleados y agentes de Tienda Naranja VIajes de cualquier reclamo,
      causa de demanda, responsabilidad, gasto, pérdida o exigencia incluyendo, entre otros, los gastos legales
      y contables que pudieran derivarse, o que estén de alguna manera conectados con tu incumplimiento de estos
      T&C o los acuerdos que forman parte de estos T&C por referencia, la infracción de las leyes aplicables y
      el uso o acceso al Sitio Web o la propiedad intelectual.
    </Text>
    <br />

    <Text paragraph alignment="justify"> <strong>Enlaces</strong></Text>
    <br />
    <Text paragraph alignment="justify">
      Nuestro Sitio Web puede contener vínculos a otras páginas web que no dependen de nosotros, que no controlamos
      y de las que no somos responsables. Proporcionamos estos vínculos a modo de referencia y para tu comodidad,
      pero no avalamos el contenido de las Otras Webs ni aceptamos ninguna responsabilidad por ellas ni por los daños
      o pérdidas que pueda ocasionarte el uso de las mismas. Debes consultar los términos de uso,
      políticas de privacidad y otras normas publicadas en las Otras Webs antes de usarlas.
    </Text>
    <br />

    <Text paragraph alignment="justify"> <strong>Requisitos generales</strong></Text>
    <br />
    <Text paragraph alignment='justify'>
      Nos reservamos el derecho de modificar el Sitio Web y estos T&C en cualquier momento. Tú eres responsable de
      mantenerte al tanto de las actualizaciones realizadas a estos T&C que nosotros publicaremos actualizados en el
      Sitio Web. Si usas el Sitio Web significa que aceptas todos los cambios realizados a los T&C, y todo cambio
      sustituirá a las versiones anteriores. A menos que se indique lo contrario, todos los cambios realizados a
      estos T&C serán aplicables a todos los Clientes, incluyendo aquellos que se hayan registrado antes de la
      fecha en la que los cambios entren en vigor. Asimismo, nos reservamos el derecho de rescindir el acuerdo
      surgido luego de aceptar los T&C y en virtud de estos T&C en cualquier momento y mediante notificación por
      escrito (incluso por correo electrónico) y/o si eres un usuario registrado, mediante la anulación de tu
      cuenta y el acceso a la misma.
    </Text>
    <br />
    <Text paragraph alignment='justify'>
      Ningún contenido presente en estos T&C hace que se considere a cualquiera de las partes como el agente o
      representante de la otra, o a ambas partes como asociados o socios para cualquier fin. No podrás ceder,
      delegar o transferir tus derechos u obligaciones, según se definen en estos T&C. Podemos ceder nuestros
      derechos y deberes, según se definen en estos T&C, sin que dicha cesión sea considerada un cambio a los T&C y
      sin necesidad de notificarte de manera previa, siempre y cuando tus derechos, según se definen en estos T&C,
      no se vean afectados.
    </Text>

    <Text paragraph alignment='justify'>
      Si tú o cualquier otro usuario incumplen estos T&C y por alguna razón no tomamos medidas,
      no significa que estemos renunciando a nuestro derecho de actuar con respecto a incumplimientos futuros o
      similares. Si un juez o autoridad competente considera que alguno de estos T&C no es exigible o no es válido,
      estos T&C seguirán en vigor en la máxima extensión permitida por la ley aplicable, mientras que los otros T&C
      seguirán siendo válidos y serán exigibles. Estos T&C, junto con los acuerdos que forman parte de estos T&C
      por referencia, forman el acuerdo completo entre tú y nosotros en relación con el uso del Sitio Web, y
      sustituye cualquier entendimiento o acuerdo anterior (ya sea oral o escrito) relativo al uso del Sitio Web.
    </Text>

    <br />

    <Text paragraph alignment='justify'>
      De acuerdo con el marco legal, las leyes de la República del Paraguay, más allá de sus normas de conflicto
      de leyes, rigen estos T&C, así como tú y nuestro cumplimiento de los mismos y cualquier disputa o demanda
      que surja de o esté vinculada con ellos o su contenido (incluyendo reclamaciones extracontractuales o
      demandas). Si emprendes acciones legales relacionadas con el uso del Sitio Web, de estos T&C o de
      nuestros servicios, aceptas presentar dicha acción en los juzgados de Asunción, Paraguay.
    </Text>

    <br />

    <Text paragraph alignment='justify'>
      <strong>¡Acercate! ¡tus comentarios nos importan!</strong>
    </Text>
    <br />
    <Text paragraph alignment='justify'>
      Te alentamos a que compartas tus comentarios y preguntas con nosotros aquí, haremos lo posible por responderte.
      Ten en cuenta que no asumimos ninguna responsabilidad por la revisión de ideas para nuestra empresa no
      solicitadas (como ideas sobre productos o publicidad) y no asumiremos ninguna responsabilidad como resultado
      de cualquier similitud entre esas ideas y cualquier material que pueda aparecer en los futuros productos o
      servicios de Tienda Naranja VIajes . Además, recuerda que eres responsable de cualquier material que envíes,
      incluyendo su fiabilidad, originalidad y el respeto a los derechos de propiedad intelectual. No divulgues
      secretos comerciales u otro tipo de información confidencial en tus mensajes. Todos los derechos de los
      materiales que recibamos pasarán a ser propiedad exclusiva de Tienda Naranja Viajes . Asimismo, al enviarnos
      tus comentarios nos otorgas una licencia irrevocable, perpetua, no exclusiva, transferible, completamente
      pagada y a nivel mundial (con derecho a sublicenciar) para usar, copiar, modificar, realizar en público,
      mostrar en público, editar, traducir, suscribir, sindicar, volver a publicar, extraer
      (en su totalidad o en parte) y distribuir los comentarios que nos envíes con cualquier motivo, como
      empresarial, comercial, de marketing, de publicidad y otros.
    </Text>
    <br />

    <Text paragraph alignment='justify'>
      <strong>ATENCIÓN: ¡Cuida tu documentación! </strong>
    </Text>
    <br />
    <Text paragraph alignment='justify'>
      La constancia de la emisión de los pasajes y/u órdenes de servicios ("Vouchers") te los entregaremos vía
      mensajes de WhatsApp y/o correo electrónico, al número de contacto y al correo que nos indiques en
      oportunidad de la compra o registro en el Sitio Web. Si así quisieras, podremos también entregarte los
      Vouchers en nuestras oficinas. Las constancias enviadas vía mensajes de WhatsApp o por correo electrónico
      tendrán idéntica validez a las físicas entregadas en los locales.
      Por otro lado, la factura correspondiente a los servicios turísticos que adquieras emitida por Tienda Naranja
      Viajes también será entregada vía email.
    </Text>
    <Text paragraph alignment='justify'>
      Tu documentación personal es siempre y en todo caso tu responsabilidad individual. Es tu responsabilidad
      exclusiva contar con la documentación requerida por el país de destino en concordancia con las exigencias de
      nacionalidad y sanitarias, así como por los Proveedores vigente y en buen estado. A vía de ejemplo, pero sin
      que implique limitación alguna, serás responsable de: tener pasaporte vigente y con el tiempo de vigencia
      requerido por la compañía área o el país de ingreso, visas, certificados de vacunación, permisos en caso de
      viajar con mejores, seguros y todo otro tipo de documentación.
    </Text>
    <Text paragraph alignment='justify'>
      Es también tu exclusiva responsabilidad informarse sobre los requisitos que exigen las autoridades migratorias,
      aduaneras y sanitarias de los países de destino, y contar con la documentación personal que exijan las
      mencionadas autoridades. Tienda Naranja VIajes no es responsable por los inconvenientes, daños y/o perjuicios
      que sufras si careces de los documentos necesarios ya sea al salir de o al entrar a cualquier país.
      Consecuentemente, no tendrás derecho a recibir ningún tipo de reembolso, compensación ni indemnización en caso
      de que se te deniegue el embarque o paso de frontera por no cumplir con las formalidades exigidas en el
      Paraguay o en los países de destino seleccionados. Si por algún motivo, Tienda Naranja Viajes te asiste en
      alguno de estos temas, ello no implicará de ninguna forma la transferencia de responsabilidad a Tienda Naranja VIajes
      ni que esta tenga o asuma responsabilidad de ninguna clase, la cual siempre será tuya en forma exclusiva.
    </Text>

    <br />

    <Text paragraph alignment='justify'>
      <strong>Si nuestro Sitio Web falla, ¡igual estamos para vos! </strong>
    </Text>
    <br />
    <Text paragraph alignment='justify'>
      Tienda Naranja VIajes no es responsable de los fallos que pudieran producirse en las comunicaciones informáticas
      o de otra clase, incluido el borrado, transmisión incompleta o retrasos, no comprometiéndose tampoco a que la
      red de transmisión o su Sitio Web esté operativo en todo momento. Tienda Naranja VIajes cuenta, además del
      Sitio Web, con locales a los que podés dirigirte en caso de fallas, y líneas telefónicas publicadas en el
      Sitio Web para atención telefónica.
    </Text>
    <br />
    <Text paragraph alignment='justify'>
      <strong>Precios y servicios no incluidos</strong>
    </Text>
    <br />

    <Text paragraph alignment='justify'>
      Al contratar un servicio turístico a través de Tienda Naranja Viajes, deberás pagar el equivalente al
      precio por el servicio turístico más impuestos y tasas (cuando apliquen).
    </Text>
    <br />
    <Text paragraph alignment='justify'>
      Sólo está incluido en el precio aquello que esté expresa y literalmente detallado en la documentación de los
      servicios adquiridos. Como principio general, las tasas e impuestos relativos están incluidos, no obstante,
      eventualmente pueden existir tributos que no estén incluidos en los precios, como ser tasas o impuestos
      específicos fijados por determinados organismos, estados o ciudades, respecto de los cuales Tienda Naranja Viajes
      no se hace responsable.
    </Text>
    <br />

    <Text paragraph alignment='justify'>
      No está incluido en los precios, salvo que en cada caso expresamente te lo indiquemos, dependiendo del servicio contratado:
    </Text>
    <Text paragraph alignment='justify'>
      <ol>
        <li>La obtención de documentación necesaria para viajar (pasaporte, visados, permisos, testimonios de partidas, certificados de vacunación, seguro de viaje, entre otros);</li>
        <li>Los extras en hoteles (comidas y bebidas de toda clase, lavandería, comunicaciones, entre otros);</li>
        <li>Las propinas, gastos e intereses de operaciones financieras;</li>
        <li>Las excursiones y visitas opcionales de cualquier índole;</li>
        <li>La alimentación y gastos extras.</li>
      </ol>
    </Text>
    <Text paragraph alignment='justify'>
      Tienda Naranja VIajes no indemnizará por los daños y perjuicios producidos por cancelaciones de servicios contratados, demoras en las salidas o llegadas de los medios de transporte, hechos, razones o causas previstas e imprevistas, entre otros, ni ningún otro concepto.
    </Text>
    <br />
    <Text paragraph alignment='justify'>
      <strong>Contacto</strong>
    </Text>
    <br />
    <Text paragraph alignment='justify'>
      Si tenes consultas sobre estos T&C, las prácticas de Tienda Naranja Viajes, o las transacciones que puede
      realizar a través del Sitio Web, podes contactarnos por correo electrónico a la dirección a <strong>contacto@tiendanaranja.com.py</strong>
    </Text>

    <Text paragraph alignment='justify'>
      Aceptación del Cliente: A través del clickeo en la casilla de sí, declaras conocer y aceptar en su totalidad
      los T&C de la contratación, declarando también conocer que es válido y vinculante y habiendo sido debidamente instruido,
      no teniendo nada que reclamar respecto de estos.
    </Text>
  </div>
);

export default TermsAndConditions;
