export enum ItauMembershipProgram {
  INTERVENTAJAS = 'INTERVENTAJAS',
  MEMBERSHIP = 'MEMBERSHIP'
}

export enum ItauActionTypes {
  Itau_Update_Values = 'Itau_Update_Values'
}

export interface ClientPoints {
  program: ItauMembershipProgram;
  points: number;
}

export interface State {
  aliasClient: string | null;
  pointsClient: ClientPoints[];
}
const initialState = {
  aliasClient: null,
  pointsClient: []
};

export default (state = initialState, action) => {
  switch (action.type) {
    case ItauActionTypes.Itau_Update_Values:
      return action.payload;

    default:
      return state;
  }
};

export const updateItauValues = (payload: State) => ({
  type: ItauActionTypes.Itau_Update_Values,
  payload
});
