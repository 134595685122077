import React, { useState } from 'react';
import styled from 'styled-components';
import { useSelector } from 'react-redux';

import { routes } from 'Constants';
import TermsAndConditions from 'Components/TermsAndConditions';
import ModalPoliticsAndPrivacy from 'Components/ModalPoliticsAndPrivacy';
import Modal from 'Components/Modal';
import bancard from './Bancard.png';
import qr from '../Desktop/qr.png'

import { ReactComponent as Instagram } from './Instagram.svg';
import { ReactComponent as Facebook } from './Facebook.svg';
import { ReactComponent as Linkedin } from './Linkedin.svg';
import { ReactComponent as Youtube } from './Youtube.svg';
import { ReactComponent as QR } from './QR.svg';
import { ReactComponent as PCI } from './PCI.svg';
import { ReactComponent as Contacto } from './Contacto.svg';
import { ReactComponent as Logo } from 'Assets/tienda-naranja-viajes-logo-blanco.svg';
import { ReactComponent as Mevuelo } from './mevuelo.svg';
import { BaseAndCondition } from 'Components/BaseAndCondition';

const Texto = styled.div`
  font-family: 'Itau Display Pro';
  font-style: normal;
  font-weight: 400;
  font-size: 22px;
  line-height: 30px;
  display: flex;
  align-items: center;
  text-align: center;
  color: #282828;
`;

const ProyectoHolder = styled.div`
  padding: 80px 20px 80px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 32px;
`;

const LogoHolder = styled.div`
  display: flex;
  flex-direction: row-reverse;
  margin-top: 30px;
`;

const BottomHeader = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;

  a {
    font-family: 'Itau Display Pro';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    display: flex;
    align-items: center;
    text-align: center;
    color: #282828;
    &:hover {
      text-decoration: none;
    }
  }
  span {
    cursor: pointer;
    font-family: 'Itau Display Pro';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    display: flex;
    align-items: center;
    text-align: center;
    color: #282828;
    &:hover {
      text-decoration: none;
    }
  }
`;

const Footer = styled.footer`
  margin-top: 80px;
`;

const OrgangeHolder = styled.div`
  background: #fb8400;
  display: flex;
  flex-direction: column;
  gap: 40px;
  padding: 20px;
`;

const Redes = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  gap: 8px;
  width: 260px;
  height: 71px;
`;

const Header = styled.div`
  font-family: 'Itau Display Pro';
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 28px;
  display: flex;
  align-items: center;
  text-align: center;
  color: #282828;
`;

const RedesIconsHolder = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 0;
  gap: 10px;
  color: white;
`;

const Registrada = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;
const QRText = styled.div`
  font-family: 'Itau Display Pro';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  display: flex;
  align-items: center;
  color: #282828;
`;

const Row = styled.div`
  display: flex;
  gap: 10px;
`;
const ChecksHolder = styled.div`
  gap: 16px;
  display: flex;
  align-items: center;
  color: white;
  img {
  }
  svg {
    background: #fb8400;
  }
`;

const AyudaHolder = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  align-items: flex-start;
`;

const CentroDeAyuda = styled.a`
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 10px;
  gap: 10px;
  background: transparent;
  border: 1px solid #ffffff;
  border-radius: 5px;

  font-family: 'Itau Display Pro';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  display: flex;
  align-items: center;
  text-align: center;
  color: #ffffff;

  &:hover {
    outline: 0;
    text-decoration: none;
    color: #ffffff;
  }

  &:focus {
    outline: 0;
  }
`;

const Divider = styled.div`
  height: 1px;
  width: 100%;
  background-color: white;
  margin-top: 60px;
  margin-bottom: 12px;
`;

const FooterMobile = () => {
  const [showPaP, setshowPaP] = useState(false);
  const [showTaC, setShowTaC] = useState(false);
  const [showBase,setShowBase]= useState(false);

  const onShowPaP = () => setshowPaP(old => !old);
  const onShowTaC = () => setShowTaC(old => !old);
  const onShowBase = () => setShowBase(old => !old);

  const centerHelp = () => {
    window.open('http://wa.me/595984729300', '_blank');
  };
  const linkYoutube = () => {
    window.open(
      'https://www.youtube.com/channel/UCSAYA_yCX5qNODOThi1LlSQ',
      '_blank'
    );
  };
  const linkFacebook = () => {
    window.open('https://www.facebook.com/TiendaNaranjaViajesPY', '_blank');
  };
  const linkInstagram = () => {
    window.open('https://www.instagram.com/tiendanaranjaviajespy/', '_blank');
  };
  const linkLinkedin = () => {
    window.open('https://www.linkedin.com/company/mevuelo', '_blank');
  };

  const {
    router
    // @ts-ignore
  } = useSelector(({ router }) => ({ router }));
  if (router.location.pathname.indexOf('Checkout') >= 0) {
    return null;
  }
  return (
    <Footer>
      <OrgangeHolder>
        <Redes>
          <Header>Seguinos en redes</Header>
          <RedesIconsHolder>
            <Instagram onClick={linkInstagram} />
            <Facebook onClick={linkFacebook} />
            <Linkedin onClick={linkLinkedin} />
            <Youtube onClick={linkYoutube} />
          </RedesIconsHolder>
        </Redes>

        <Registrada>
          <Header>Agencia de viajes registrada</Header>
          <Row>
            <img src={qr} style={{width: 60}} alt="registrado" />
            <QRText>
              Real Consulting S.A. - mevuelo.com
              <br />
              Resolución Registur N° 1263/2019
            </QRText>
          </Row>
          <ChecksHolder>
            <PCI />
            <img src={bancard} alt="Bancard" />
          </ChecksHolder>
        </Registrada>

        <AyudaHolder>
          <Header>¿Necesitás ayuda?</Header>
          <CentroDeAyuda
            style={{color:'white'}}
            onClick={centerHelp}
          >
            Contactá con Nosotros
          </CentroDeAyuda>
        </AyudaHolder>
        <Divider />

        <BottomHeader>
          <span onClick={onShowBase}>Bases y condiciones</span>
          <span onClick={onShowTaC}>Términos de uso</span>
          <span onClick={onShowPaP}>Política de privacidad</span>
          {/* 
            // TODO: agregarlo cuando tengamos el link
            <a href="#">Sumate al equipo</a> 
          */}

          <LogoHolder>
            <Logo />
          </LogoHolder>
        </BottomHeader>
      </OrgangeHolder>
      <ProyectoHolder>
        <Texto>powered by</Texto>
        <Mevuelo />
      </ProyectoHolder>
      {/* 
      // @ts-ignore */}
      <Modal
        show={showTaC}
        className="tac-modal"
        closeButton={{ onClick: onShowTaC }}
        header="TÉRMINOS y CONDICIONES"
        onClickOverlay={onShowTaC}
        size="large"
        headerCentered>
        <TermsAndConditions />
      </Modal>
      {/* 
      // @ts-ignore */}
      <ModalPoliticsAndPrivacy showPaP={showPaP} onShowPaP={onShowPaP} />
     {/* 
      // @ts-ignore */}
      <Modal
        show={showBase}
        className="tac-modal"
        closeButton={{ onClick: onShowBase }}
        header="BASES y CONDICIONES"
        onClickOverlay={onShowBase}
        size="large"
        headerCentered>
        <BaseAndCondition />
      </Modal>
    </Footer>
  );
};

export default FooterMobile;
