import React from 'react';

import { getAirLineLogo, getCabinName, isoDateToFullDate } from 'Utils';

import {
  Scroller,
  Titulo,
  SeparadorEscala,
  SegmentCard,
  RowSpaceBetween,
  DateBox,
  TimeBox,
  AirportBox,
  DetailsBox,
  DateTimeHolder,
  CardEquipaje,
  BuyButton,
  ModalCard,
  Holder,
  EquipajeItemBox,
  EquipajeTitle,
  EquipajeItemTextHolder,
  DurationBox,
  FlightNumber,
  AirLine,
  DetalleEquipaje
} from './itinierary-details-styles';

import { excludeAirlineBaggage } from 'Constants';

import { ReactComponent as Close } from './close.svg';
import { ReactComponent as Avion } from './plane.svg';
import { ReactComponent as Mochila } from './mochila.svg';
import { ReactComponent as Carryon } from './carryon.svg';
import { ReactComponent as Suitcase} from './suitcase.svg';

export const AirItineraryContent = ({
  baggegeGo,
  baggegeBack,
  ida,
  idaSegments,
  showMochilaIda,
  showCarryOnIda,
  vuelta,
  vueltaSegments,
  showMochilaVuelta,
  showCarryOnVuelta,
  scroller = false
}) => {
  return(
    <Scroller scroller={scroller}>
      <Titulo>IDA</Titulo>
  
      {idaSegments.map((segment, index) => (
        <React.Fragment key={index}>
          {index > 0 && (
            <SeparadorEscala>
              Espera de {ida.scales[index - 1].duration.totalHours}h{' '}
              {ida.scales[index - 1].duration.totalMinutes}min en{' '}
              {ida.scales[index - 1].airport.city.name}{' '}
              {ida.scales[index - 1].changeInfo.changeOfPlane &&
                '(cambio de avión)'}{' '}
              {ida.scales[index - 1].changeInfo.changeOfAirport &&
                '(cambio de aeropuerto)'}
            </SeparadorEscala>
          )}
          <SegmentCard>
            <RowSpaceBetween>
              <AirLine>
                <img
                  src={getAirLineLogo(segment.flightInformation.airline.code)}
                  alt={segment.flightInformation.airline.name}
                />
                <span>{segment.flightInformation.airline.name}</span>
              </AirLine>
              <FlightNumber>
                Vuelo {segment.flightInformation.completeFlightNumber}
                {segment.flightInformation.flightNumber}
                <br />
                Clase: {getCabinName(segment.cabin.code)}
              </FlightNumber>
            </RowSpaceBetween>
            <DetailsBox>
              <AirportBox>
                <DateTimeHolder>
                  <DateBox>
                    {isoDateToFullDate(segment.departureData.flightDate)}
                  </DateBox>
                  <TimeBox>{segment.departureData.flightTime} hs.</TimeBox>
                </DateTimeHolder>
                <DateBox>
                  {segment.departureData.airport.name} (
                  {segment.departureData.airport.code})
                </DateBox>
              </AirportBox>
  
              <Avion />
  
              <AirportBox>
                <DateTimeHolder>
                  <DateBox>
                    {isoDateToFullDate(segment.arrivalData.flightDate)}
                  </DateBox>
                  <TimeBox>{segment.arrivalData.flightTime} hs.</TimeBox>
                </DateTimeHolder>
                <DateBox>
                  {segment.arrivalData.airport.name} (
                  {segment.arrivalData.airport.code})
                </DateBox>
              </AirportBox>
  
              <DurationBox>
                <DateTimeHolder>
                  <DateBox>Duración de vuelo</DateBox>
                  <TimeBox>
                    {segment.duration.totalHours} hs.{' '}
                    {segment.duration.totalMinutes} mins
                  </TimeBox>
                  <DateBox>Cabina: {segment.cabin.code}</DateBox>
                </DateTimeHolder>
              </DurationBox>
            </DetailsBox>
          </SegmentCard>
        </React.Fragment>
      ))}
  
      {(showCarryOnIda || showMochilaIda) && (
        <CardEquipaje>
          <span>Equipaje</span>
          <DetalleEquipaje>
            {showMochilaIda && (
              <EquipajeItemBox>
                <Suitcase />
                <EquipajeItemTextHolder>
                  <EquipajeTitle>Incluye equipaje de cabina</EquipajeTitle>
                  <div>Debe caber en el compartimiento superior del avión.</div>
                </EquipajeItemTextHolder>
              </EquipajeItemBox>
            )}
  
            {showCarryOnIda && (
              <EquipajeItemBox>
                <Carryon />
                <EquipajeItemTextHolder>
                  <EquipajeTitle>Incluye equipaje para despachar</EquipajeTitle>
                  <div>
                    {baggegeGo} {baggegeGo <= 1 ? 'valija' : 'valijas'} por
                    adulto
                    <br />
                    Se despacha en el aeropuerto durante el Check-in.
                  </div>
                </EquipajeItemTextHolder>
              </EquipajeItemBox>
            )}
          </DetalleEquipaje>
        </CardEquipaje>
      )}
  
      {(!showCarryOnIda && !showMochilaIda) && (
            <CardEquipaje>
              <span>Equipaje</span>
              <DetalleEquipaje>
                  <EquipajeItemBox>
                    <Mochila />
                    <EquipajeItemTextHolder>
                      <EquipajeTitle>Incluye equipaje personal</EquipajeTitle>
                      <div>Debe caber bajo el asiento delantero.</div>
                    </EquipajeItemTextHolder>
                  </EquipajeItemBox>
              </DetalleEquipaje>
            </CardEquipaje>
          )}
      
  
      <SeparadorEscala>
        Duración total de ida {ida.duration.totalHours}h{' '}
        {ida.duration.totalMinutes}min
      </SeparadorEscala>
  
      {vueltaSegments && (
        <>
          <Titulo>VUELTA</Titulo>
          {vueltaSegments.map((segment, index) => (
            <React.Fragment key={index}>
              {index > 0 && (
                <SeparadorEscala>
                  Espera de {vuelta.scales[index - 1].duration.totalHours}h{' '}
                  {vuelta.scales[index - 1].duration.totalMinutes}min en{' '}
                  {vuelta.scales[index - 1].airport.city.name}{' '}
                  {vuelta.scales[index - 1].changeInfo.changeOfPlane &&
                    '(cambio de avión)'}{' '}
                  {vuelta.scales[index - 1].changeInfo.changeOfAirport &&
                    '(cambio de aeropuerto)'}
                </SeparadorEscala>
              )}
              <SegmentCard>
                <RowSpaceBetween>
                  <AirLine>
                    <img
                      src={getAirLineLogo(segment.flightInformation.airline.code)}
                      alt={segment.flightInformation.airline.name}
                    />
                    <span>{segment.flightInformation.airline.name}</span>
                  </AirLine>
                  <FlightNumber>
                    Vuelo {segment.flightInformation.completeFlightNumber}
                    {segment.flightInformation.flightNumber}
                    <br />
                    Clase: {getCabinName(segment.cabin.code)}
                  </FlightNumber>
                </RowSpaceBetween>
                <DetailsBox>
                  <AirportBox>
                    <DateTimeHolder>
                      <DateBox>
                        {isoDateToFullDate(segment.departureData.flightDate)}
                      </DateBox>
                      <TimeBox>{segment.departureData.flightTime} hs.</TimeBox>
                    </DateTimeHolder>
                    <DateBox>
                      {segment.departureData.airport.name} (
                      {segment.departureData.airport.code})
                    </DateBox>
                  </AirportBox>
  
                  <Avion className="rotated" />
  
                  <AirportBox>
                    <DateTimeHolder>
                      <DateBox>
                        {isoDateToFullDate(segment.arrivalData.flightDate)}
                      </DateBox>
                      <TimeBox>{segment.arrivalData.flightTime} hs.</TimeBox>
                    </DateTimeHolder>
                    <DateBox>
                      {segment.arrivalData.airport.name} (
                      {segment.arrivalData.airport.code})
                    </DateBox>
                  </AirportBox>
  
                  <DurationBox>
                    <DateTimeHolder>
                      <DateBox>Duración de vuelo</DateBox>
                      <TimeBox>
                        {segment.duration.totalHours} hs.{' '}
                        {segment.duration.totalMinutes} mins
                      </TimeBox>
                      <DateBox>Cabina: {segment.cabin.code}</DateBox>
                    </DateTimeHolder>
                  </DurationBox>
                </DetailsBox>
              </SegmentCard>
            </React.Fragment>
          ))}
          <SeparadorEscala>
            Duración total de vuelta {vuelta.duration.totalHours}h{' '}
            {vuelta.duration.totalMinutes}min
          </SeparadorEscala>
  
          {(showCarryOnVuelta || showMochilaVuelta) && (
            <CardEquipaje>
              <span>Equipaje</span>
              <DetalleEquipaje>
                {showMochilaVuelta && (
                  <EquipajeItemBox>
                    <Suitcase />
                    <EquipajeItemTextHolder>
                      <EquipajeTitle>Incluye equipaje de cabina</EquipajeTitle>
                      <div>
                        Debe caber en el compartimiento superior del avión.
                      </div>
                    </EquipajeItemTextHolder>
                  </EquipajeItemBox>
                )}
  
                {showCarryOnVuelta && (
                  <EquipajeItemBox>
                    <Carryon />
                    <EquipajeItemTextHolder>
                      <EquipajeTitle>
                        Incluye equipaje para despachar
                      </EquipajeTitle>
                      <div>
                        {baggegeBack} {baggegeBack <= 1 ? 'valija' : 'valijas'}{' '}
                        por adulto
                        <br />
                        Se despacha en el aeropuerto durante el Check-in.
                      </div>
                    </EquipajeItemTextHolder>
                  </EquipajeItemBox>
                )}
              </DetalleEquipaje>
            </CardEquipaje>
          )}
              {(!showCarryOnVuelta && !showMochilaVuelta) && (
            <CardEquipaje>
              <span>Equipaje</span>
              <DetalleEquipaje>
                  <EquipajeItemBox>
                    <Mochila />
                    <EquipajeItemTextHolder>
                      <EquipajeTitle>Incluye equipaje personal</EquipajeTitle>
                      <div>Debe caber bajo el asiento delantero.</div>
                    </EquipajeItemTextHolder>
                  </EquipajeItemBox>
              </DetalleEquipaje>
            </CardEquipaje>
          )}
        </>
      )}
    </Scroller>
  );
}

export const ItineraryDetails = ({ onClose, generalSegment, onBuy }) => {
  const handleClick = e => {
    e.stopPropagation();
  };

  const baggegeGo = generalSegment[0][0].baggage.total;
  const baggegeBack = generalSegment[0][1] && generalSegment[0][1].baggage.total;
  const ida = generalSegment[0][0];
  const idaSegments = ida.segments;
  const showMochilaIda = !(
    ida.baggage.total === 0 &&
    excludeAirlineBaggage.includes(ida.airLines[0].code)
  );
  const showCarryOnIda = !(ida.baggage.total === 0);
  const vuelta = generalSegment[1] ? generalSegment[1][0] : null;
  const vueltaSegments = vuelta ? vuelta.segments : null;
  const showMochilaVuelta = vuelta
    ? !(
        vuelta.baggage.total === 0 &&
        excludeAirlineBaggage.includes(vuelta.airLines[0].code)
      )
    : null;
  const showCarryOnVuelta = vuelta ? !(vuelta.baggage.total === 0) : null;
  return (
    <Holder onClick={handleClick}>
      <ModalCard>
        <div
          style={{
            display: 'flex',
            flexDirection: 'row-reverse',
            width: '100%',
            paddingRight: 20
          }}>
          <Close onClick={onClose} />
        </div>
        <AirItineraryContent
          baggegeGo={baggegeGo}
          baggegeBack={baggegeBack}
          ida={ida}
          idaSegments={idaSegments}
          showMochilaIda={showMochilaIda}
          showCarryOnIda={showCarryOnIda}
          vuelta={vuelta}
          vueltaSegments={vueltaSegments}
          showMochilaVuelta={showMochilaVuelta}
          showCarryOnVuelta={showCarryOnVuelta}
          scroller
        />
        <BuyButton onClick={onBuy}>Comprar</BuyButton>
      </ModalCard>
    </Holder>
  );
};
