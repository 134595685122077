import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import cs from 'classnames';
import * as COLORS from 'Constants/colors';
import Select from 'Components/Select';
import Input from 'Components/Input';
import TextArea from 'Components/TextArea';
import Button from 'Components/Button';
import SelectDate from 'Components/SelectDate';
import SelectPeople from 'Components/SelectPeople';
import { formKeys } from './index';

const ProvisionalTwoColumns = ({
  className,
  date,
  errors = {},
  onChange,
  assistancePrefers,
  listPhoneCodes,
  prefers,
  name,
  email,
  phoneCode,
  phone,
  query,
  onSubmit,
  touched = {},
  title
}) => (
  <div className={cs(className)}>
    <InputLabel context="important" weight="semi-bold" size="xlarge">
      {title || 'Contactá a un vendedor'}
    </InputLabel>
    <div className="content-body">
      <div className="form-row">
        <div className="form-label">
          <InputLabel weight="semi-bold">Fecha de viaje:</InputLabel>
          <SelectDate
            className="input"
            onChange={onChange(formKeys.date)}
            selectedValue={date}
            error={{
              message: !touched.date && 'Aca va tu fecha ideal',
              color: 'info'
            }}
          />
        </div>
        <div className="form-label">
          <InputLabel weight="semi-bold">Pasajeros:</InputLabel>
          <SelectPeople
            className="input"
            onChange={onChange(formKeys.people)}
            error={{
              message: errors.people
                ? 'La edad de los menores es requerida'
                : undefined,
              color: 'warning'
            }}
          />
        </div>
      </div>
      <div className="form-row">
        <div className="form-label">
          <InputLabel weight="semi-bold">Via de contacto:</InputLabel>
          <Select
            className="input"
            placeholder="¿Por qué medio te contactamos?"
            onSelect={onChange(formKeys.prefers)}
            selectedValue={prefers && prefers.id}
            items={assistancePrefers}
            message={errors.prefers}
            context={errors.prefers && 'warning'}
          />
        </div>
        <div className="form-label">
          <InputLabel weight="semi-bold">Nombre:</InputLabel>
          <Input
            placeholder="Ingresá tu nombre"
            className="input"
            onChange={onChange(formKeys.name)}
            value={name}
            message={errors.name}
            context={errors.name && 'warning'}
          />
        </div>
      </div>
      <div className="form-row">
        <div className="form-label">
          <InputLabel weight="semi-bold">Correo electrónico:</InputLabel>
          <Input
            placeholder="Ej: hola@mevuelo.com"
            className="input"
            onChange={onChange(formKeys.email)}
            value={email}
            message={errors.email}
            context={errors.email && 'warning'}
          />
        </div>
        <div className="form-label">
          <InputLabel weight="semi-bold">Teléfono:</InputLabel>
          <div className="form-row">
            <div className="column">
              <Select
                className="input"
                items={listPhoneCodes}
                onSelect={onChange(formKeys.phoneCode)}
                selectedValue={phoneCode && phoneCode.id}
                message={errors.phoneCode}
                context={errors.phoneCode && 'warning'}
              />
            </div>
            <div className="column">
              <Input
                placeholder="Número de teléfono"
                className="input"
                onChange={onChange(formKeys.phone)}
                value={phone}
                message={errors.phone}
                context={errors.phone && 'warning'}
              />
            </div>
          </div>
        </div>
      </div>
      <div className="form-row" />
      <TextArea
        className="input"
        placeholder="¿Algún comentario?"
        value={query}
        onChange={onChange(formKeys.query)}
        message={errors.query}
        context={errors.query && 'warning'}
      />
      <Button className="input" onClick={onSubmit}>
        Envianos tu consulta
      </Button>
    </div>
  </div>
);

ProvisionalTwoColumns.propTypes = {
  className: PropTypes.string,
  date: PropTypes.string,
  people: PropTypes.shape({
    adultsCount: PropTypes.number.isRequired,
    childrenQty: PropTypes.number.isRequired
  }),
  prefers: PropTypes.shape({
    id: PropTypes.number.isRequired
  }),
  query: PropTypes.string,
  email: PropTypes.string,
  phone: PropTypes.string,
  phoneCode: PropTypes.shape({
    id: PropTypes.string.isRequired
  }),
  onChange: PropTypes.func.isRequired,
  assistancePrefers: PropTypes.any,
  listPhoneCodes: PropTypes.any,
  name: PropTypes.string,
  onSubmit: PropTypes.func.isRequired,
  errors: PropTypes.object,
  touched: PropTypes.object,
  title: PropTypes.node
};

const StyledProvisionalTwoColumns = styled(ProvisionalTwoColumns)`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: ${({ theme }) => theme.colors.white};
  border-radius: 14px;
  box-shadow: ${({ theme }) => theme.shadows.three};
  padding: 5px;

  .content-header {
    display: flex;
    align-items: center;
  }

  .column {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-right: ${({ theme }) => theme.spacing.one};
    width: 100%;
  }

  .form-row {
    display: flex;
    justify-content: space-between;
    flex-wrap: nowrap;
    margin-left: 0;

    .form-label {
      display: flex;
      flex-direction: column;
      width: 100%;
      margin: ${({ theme }) => theme.spacing.one};
    }
  }

  .input {
    margin-bottom: ${({ theme }) => theme.spacing.four};
    width: 100%;
  }

  .content-body {
    display: flex;
    align-content: center;
    flex-direction: column;
    padding: ${({ theme }) => theme.spacing.four};
    width: 100%;
  }

  .separator {
    width: 100%;
    border-top: 1px solid ${COLORS.lightGrey};
  }
`;

const InputLabel = styled.span`
  font-family: Itau Display Pro;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: left;
`;
export default StyledProvisionalTwoColumns;
