/**
 *
 * MobileHotelDetails
 *
 */

import React, { Fragment, useState, useMemo, useRef } from 'react';
import { useHistory } from 'react-router';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import cs from 'classnames';
import Slider from 'react-slick';

import Text from 'Components/Text';
import Icon from 'Components/Icon';
import StarsFilter from 'Components/StarsFilter';
import Button from 'Components/Button';
import GMap from 'Components/GMap';
import MobileHotelRooms from '../MobileHotelRooms';
import MobileNavBar from 'Components/MobileNavBar';
import MobileNavBarPrice from 'Components/MobileNavBarPrice';
import Modal from 'Components/Modal';
import HotelServices from '../HotelServices';
import HotelsGoogleComments from '../HotelsGoogleComments';
import { ReactComponent as HotelIcon } from 'Assets/icons/mv-hotel-timeout.svg';
import { ReactComponent as Clock } from './clock.svg';
import { ReactComponent as ButtonPrevious } from './Botón.svg';
import * as COLORS from 'Constants/colors';
import { getFormatPrice } from 'Utils';
import ErrorPortal from 'Components/ErrorPortal';
import Badge from 'Components/Badge';

// I'm duplicating because I'm not sure how reusable is this
const shouldShowMore = descriptions => {
  let chars = 0;

  descriptions.forEach(d => {
    if (d.paragraph && d.title) {
      chars += d.paragraph.length + d.title.length;
    }
  });

  return chars > 350;
};

const sliderSettings = {
  infinite: false,
  dots: true,
  slidesToShow: 1,
  speed: 500,
  lazyLoad: false
};

const MobileHotelDetails = ({
  className,
  images,
  name,
  starRating,
  keyRating,
  textRating,
  checkIn,
  checkOut,
  descriptions,
  initialCenter,
  ratePackagesRooms,
  handleClickReservation,
  facilities,
  address,
  reviews,
  closeError,
  checkRateError
}) => {
  const [showServices, setShowServices] = useState(false);
  const [showMoreDetails, setShowMoreDetails] = useState(false);
  const [selected, setSelected] = useState(ratePackagesRooms[0].packages[0]);
  const sectionRef = useRef(null);
  const history = useHistory();

  const hasMoreDetails = useMemo(() => shouldShowMore(descriptions), [
    descriptions
  ]);

  const { facilitiesIcons } = facilities;

  const toggleServices = () => setShowServices(!showServices);
  const onShowMoreDetails = force => () => {
    setShowMoreDetails(force);

    if (!force && sectionRef) {
      const el =
        sectionRef.current.getBoundingClientRect().top + window.scrollY;

      window.scroll({
        top: el,
        behavior: 'smooth'
      });
    }
  };

  return (
    <>
      <ErrorPortal
        show={checkRateError}
        onClose={closeError}
        buttonLabel="Volver al Listado"
        icon={<HotelIcon />}
        message="Por favor, volvé al listado a buscar tu hotel."
        title="Lo sentimos, el hotel no nos ha devuelto disponibilidad para sus habitaciones."
      />
      <Modal show={showServices} transparent whiteBackground>
        <HotelServices
          images={images}
          facilitiesGroup={facilities.facilitiesGroup}
          name={name}
          close={toggleServices}
          mobile
        />
      </Modal>
      <div className={cs(className)}>
        <div className="content-img-and-button">
          <div className="button-previous">
            <ButtonPrevious
              onClick={() => {
                history.goBack();
              }}
            />
          </div>
          <Slider {...sliderSettings} className="content-carousel-image">
            {images.map((image, index) => (
              <img
                src={image.imageUrl}
                alt={image.description}
                key={index}
                className="slider-img"
              />
            ))}
          </Slider>
        </div>
        <div className="hotel-header">
          <Title weight="semi-bold" size="large">
            {name}
          </Title>
          {starRating && <StarsFilter justDisplay initialValue={starRating} />}
          {keyRating && (
            <StarsFilter
              justDisplay
              initialValue={keyRating}
              isKey={keyRating}
            />
          )}
          {textRating && (
            <Badge variation="yellow" inverted>
              {textRating}
            </Badge>
          )}
          <div className="location ">
            <Icon name="mv-location-2" color="important" size={20} />
            <Address>{address}</Address>
          </div>
        </div>
        <div className="separator" />
        <div className="services">
          <div>
            <Title weight="semi-bold">Servicios</Title>
          </div>
          <div className="mobile-facilities-holder">
            <div className="content-icons">
              <div className="icon">
                <Icon
                  name="mv-car-parking"
                  size={24}
                  color={facilitiesIcons.parking ? 'important' : 'default'}>
                  <p>Parking</p>
                </Icon>
                <Icon name="mv-swimming-pool" size={24} color="important">
                  {' '}
                  <p>Pileta</p>
                </Icon>
                <Icon size={24} color="important" name="mv-breakfast">
                  <p>Desayuno</p>
                </Icon>
              </div>
            </div>
            <div className="content-icons">
              <div className="icon">
                <Icon
                  name="mv-wifi"
                  size={24}
                  color={facilitiesIcons.wifi ? 'important' : 'default'}>
                  {' '}
                  <p>Wifi</p>
                </Icon>
                <Icon name="mv-aircon" size={24} color="important">
                  <p>Aire acondiconado</p>
                </Icon>
                <Icon name="mv-gym" size={24} color="important">
                  <p>Gimnasio</p>
                </Icon>
              </div>
            </div>
          </div>
        </div>

        <div className="separator" />
        <div className="column margin-horizontal">
          <Title weight="semi-bold">Horarios</Title>
          <div className="content-check-in">
            <Check context="important" weight="semi-bold">
              <Clock /> Check In:&nbsp;
            </Check>
            <CheckText>a partir de las {checkIn} hs</CheckText>
          </div>
          <div className="content-check-out">
            <Check context="important" weight="semi-bold">
              <Clock /> Check out:&nbsp;
            </Check>
            <CheckText>hasta las {checkOut} hs</CheckText>
          </div>
        </div>

        <div className="section-header"></div>
        <div className="column margin-horizontal hotel-info">
          <Title weight="semi-bold">Información</Title>
          <Text
            paragraph
            className={cs('hotel-descr', { 'show-more': showMoreDetails })}>
            {descriptions.map((desc, index) => (
              <p key={index}>
                <b>{desc.title}</b>
                <br />
                {desc.paragraph}
                <br />
                <br />
              </p>
            ))}
          </Text>

          {hasMoreDetails && (
            <Button
              size="xs"
              onClick={onShowMoreDetails(!showMoreDetails)}
              className="mt-2">
              {showMoreDetails ? 'Ver menos' : 'Ver más'}
            </Button>
          )}
        </div>
        <div className="section-header">
          <div className="text-icon container-rates">
            <Rates weight="semi-bold">Tarifas y habitaciones</Rates>
            <p className="text-rates">
              Elige una opción dentro de la habitación que corresponda
            </p>
          </div>
        </div>

        <MobileHotelRooms
          rooms={ratePackagesRooms}
          onChange={setSelected}
          selected={selected}
        />

        {reviews && (
          <>
            <div className="section-header">
              <div className="text-icon">
                <ValuationTitle weight="semi-bold">
                  Valoración de nuestros viajeros
                </ValuationTitle>
              </div>
            </div>
            <HotelsGoogleComments reviews={reviews}></HotelsGoogleComments>
          </>
        )}
        <div className="section-header">
          <div className="text-icon content-rate">
            <Icon name="mv-location-1" size={20} color="secondary" />
            <Text weight="semi-bold">Mapa</Text>
          </div>
        </div>

        <div className="separator" />

        <GMap initialCenter={initialCenter} markerName={name} />

        <MobileNavBar>
          <Button left iconLeft context="purple" disabled>
            <Icon name="mv-arrow-left" color="white" size={24} />
          </Button>

          {selected && (
            <>
              <MobileNavBarPrice
                topText="Precio Total"
                middleText={getFormatPrice(
                  selected.packagePrice.finalPrice,
                  selected.packagePrice.currency
                )}
                bottomText={`${getFormatPrice(
                  selected.packagePrice.finalPricePerNight,
                  selected.packagePrice.currency
                )} por noche`}
              />
              <Button
                right
                context="purple"
                onClick={() => handleClickReservation(selected)}>
                Continuar
              </Button>
            </>
          )}
        </MobileNavBar>
      </div>
    </>
  );
};

MobileHotelDetails.propTypes = {
  className: PropTypes.string.isRequired,
  images: PropTypes.array,
  name: PropTypes.string,
  starRating: PropTypes.number,
  keyRating: PropTypes.number,
  textRating: PropTypes.string,
  checkIn: PropTypes.string,
  checkOut: PropTypes.string,
  descriptions: PropTypes.array,
  initialCenter: PropTypes.object,
  ratePackagesRooms: PropTypes.array,
  handleClickReservation: PropTypes.func,
  facilities: PropTypes.array,
  address: PropTypes.string,
  reviews: PropTypes.shape({
    amountReviews: PropTypes.number,
    rating: PropTypes.number,
    ratingText: PropTypes.string,
    results: PropTypes.arrayOf(
      PropTypes.shape({
        author_name: PropTypes.string,
        author_url: PropTypes.string,
        id: PropTypes.string,
        language: PropTypes.string,
        profile_photo_url: PropTypes.string,
        rating: PropTypes.number,
        ratingText: PropTypes.string,
        relative_time_description: PropTypes.string,
        text: PropTypes.string,
        time: PropTypes.number
      })
    )
  }).isRequired,
  closeError: PropTypes.func,
  checkRateError: PropTypes.string,
  accommodationCode: PropTypes.string
};

export default styled(MobileHotelDetails)`
  display: block;
  background-color: ${COLORS.white};
  overflow: hidden;

  .content-img-and-button {
    position: relative;
  }
  .button-previous {
    position: absolute;
    top: 15px;
    left: 10px;
    z-index: 1;
    fill: #fff;
    filter: drop-shadow(0px 4px 8px rgba(0, 0, 0, 0.25));
  }
  .content-button-see {
    display: flex;
    justify-content: center;
  }
  .services {
    margin-left: 20px;
    margin-right: 20px;
    padding: 20px, 0, 20px, 0;
    width: 320px;

    .mobile-facilities-holder {
      display: flex;
      justify-content: space-around;
      flex-direction: row;
    }
    .content-icons {
      display: flex;
      flex-direction: column;
    }
    .icon {
      display: flex;
      flex-direction: column;
      align-items: start;
      margin: 15px 11px;
    }
    .icon p {
      font-family: Itau Display Pro;
      font-size: 14px;
      font-weight: 400;
      letter-spacing: 0;
      margin: 0 0 0 5px;
      text-align: left;
      color: #282828;
    }
    .icon span {
      flex-direction: row;
    }
  }
  .content-addres {
    margin-top: 20px;
  }
  .hotel-header {
    padding: 5px 20px;
    margin-top: 20px;

    .location {
      display: flex;
      margin-bottom: 5px;
      margin-top: 20px;

      .icon {
        margin-right: 5px;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
  }
  svg {
    fill: none;
  }
  .column {
    display: flex;
    flex-direction: column;

    &.align-center {
      align-items: center;
    }
  }

  .margin-horizontal {
    margin-left: 20px;
    margin-right: 20px;
    .content-check-in {
      padding: 22px 0 22px 30px;
    }
    .content-check-out {
      padding-left: 30px;
    }
  }

  .slick-dots {
    bottom: 20px;
  }

  .slider-img {
    max-height: 230px;
    object-fit: cover;
  }

  .slick-dots li button::before {
    font-family: Montserrat, serif;
    content: '–';
    font-weight: 900;
    font-size: 35px;
    color: ${COLORS.lightGrey};
    opacity: 0.7;
  }

  .slick-dots li:nth-child(n + 7) {
    display: none;
  }

  .slick-dots li.slick-active button::before {
    color: ${COLORS.white};
    opacity: 1;
  }

  .section-header {
    margin: 10px 14px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .text-icon {
      display: flex;

      .icon {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-right: 5px;
      }
    }
  }

  .hotel-info {
    margin-top: 25px;
    .hotel-descr {
      top: 106px;
      transition: all 1s ease-in-out;
      overflow: hidden;
      font-family: Itau Display Pro;
      font-size: 14px;
      font-weight: 400;
      line-height: 22px;
      letter-spacing: 0em;
      text-align: left;
      color: #282828;

      &.show-more {
        display: block;
        max-height: 8000px;
      }
    }
  }

  .separator {
    height: 1px;
    background-color: #28282840;
    margin-top: 12px;
    margin-bottom: 12px;
    width: 100%;
  }

  .container-rates {
    display: flex;
    flex-direction: column;
  }
  .text-rates {
    color: #fbb702;
    font-family: Itau Display Pro;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px;
    width: 320px;
  }
`;

const Title = styled.span`
  font-family: Itau Display Pro;
  font-size: 16px;
  font-weight: 700;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: left;
  color: #282828;
`;

const Check = styled.span`
  font-family: Itau Display Pro;
  font-size: 14px;
  font-weight: 700;
  line-height: 22px;
  letter-spacing: 0em;
  text-align: left;
  color: #fb8400;
`;

const CheckText = styled.span`
  font-family: Itau Display Pro;
  font-size: 14px;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: 0em;
  text-align: left;
`;

const Address = styled.span`
  font-family: Itau Display Pro;
  font-size: 14px;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: 0em;
  text-align: left;
  margin-left: 10px;
  color: #282828;
`;

const Rates = styled.span`
  color: #282828;
  font-family: Itau Display Pro;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px;
`;

const ValuationTitle = styled.h2`
  color: #282828;
  font-family: Itau Display Pro;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px;
`;
