/**
 *
 * StarsFilter
 *
 */

import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import cs from 'classnames';
import activeStartImg from '../../Assets/images/shape-871.png';
import greyStarImg from '../../Assets/images/shape-871-copy-4.png';
import { ReactComponent as Key } from 'Assets/icons/mv-key.svg';

const StarsFilter = ({
  className,
  justDisplay,
  onSelect,
  initialValue,
  isKey
}) => {
  React.useEffect(() => {
    setSelectedIndex(initialValue);
  }, [initialValue]);

  const [selectedIndex, setSelectedIndex] = React.useState(initialValue);
  const handleselectItem = index => () => {
    if (!justDisplay) {
      setSelectedIndex(index + 1);
      onSelect && onSelect(index + 1);
    }
  };

  const valoracionItems = ['+0', '2', '3', '4', '5'];
  return (
    <div className={cs(className, { 'just-display': justDisplay })}>
      {valoracionItems.map((item, index) =>
        isKey ? (
          <StyledKey key="index" active={index < selectedIndex} />
        ) : (
          <div
            key={index}
            className={cs('star-item', { active: index < selectedIndex })}
            onClick={handleselectItem(index)}>
            {!justDisplay ? item : ''}
          </div>
        )
      )}
    </div>
  );
};

StarsFilter.propTypes = {
  className: PropTypes.string.isRequired,
  justDisplay: PropTypes.bool,
  onSelect: PropTypes.func,
  initialValue: PropTypes.number,
  isKey: PropTypes.bool
};

const StyledKey = styled(Key)`
  width: 35px;
  height: 34px;
  display: none;
  ${({ active }) =>
    active &&
    css`
      display: block;
      fill: #e8c53f;
    `}
`;

export default styled(StarsFilter)`
  display: flex;
  justify-content: space-around;
  gap: 8px;
  max-width: 181px;

  .star-item {
    background-image: url(${greyStarImg});
    width: 35px;
    height: 34px;
    background-size: contain;
    font-family: Montserrat, serif;
    font-size: 12px;
    font-weight: 600;
    font-style: normal;
    font-stretch: normal;
    letter-spacing: 0.1px;
    color: #feffff;
    text-align: center;
    line-height: 34px;
    cursor: pointer;

    &.active {
      background-image: url(${activeStartImg});
    }
  }

  &.just-display {
    justify-content: flex-start !important;

    .star-item {
      width: 23px !important;
      height: 22px !important;
      background-repeat: no-repeat;
    }
  }
`;
