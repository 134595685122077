/**
 *
 * MobileHotelSearch
 *
 */

import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import cs from 'classnames';
import MobileHotelDestinationCard from '../MobileHotelDestinationCard';
import MobileDateSelector from 'Components/MobileDateSelector';
import MobilePaxDetailHotelCard from '../MobilePaxDetailHotelCard';
import Toggle from 'Components/Toggle';

const MobileHotelSearch = ({
  className,
  actions,
  checkIn,
  checkOut,
  isEmpty,
  showMap
}) => (
  <div className={cs(className)}>
    <MobileHotelDestinationCard />
    <MobileDateSelector
      stateless
      arrivalDate={checkOut}
      departureDate={checkIn}
      setArrivalDate={actions.setCheckOut}
      setDepartureDate={actions.setCheckIn}
      isEmpty={isEmpty}
    />
    <MobilePaxDetailHotelCard />

    {/* TODO
        <div className="center margin">
          <Checkbox onClick={handleKnowMore} checked={state.knowMore}>
            Conocer más de este destino
          </Checkbox>
        </div>
      */}

    <Toggle
      size="medium"
      context="important"
      rightLabel="Ver en mapa"
      status={showMap}
      onToggle={actions.toggleMap}
    />

    <SearchButton onClick={actions.doSearch}>Buscar</SearchButton>
  </div>
);

MobileHotelSearch.propTypes = {
  className: PropTypes.string.isRequired,
  checkIn: PropTypes.string,
  checkOut: PropTypes.string,
  actions: PropTypes.shape({
    setCheckIn: PropTypes.func.isRequired,
    setCheckOut: PropTypes.func.isRequired,
    doSearch: PropTypes.func.isRequired,
    toggleMap: PropTypes.func.isRequired
  }),
  isEmpty: PropTypes.bool,
  showMap: PropTypes.bool
};

const SearchButton = styled.div`
  display: flex;
  cursor: pointer;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 10px 30px;
  gap: 10px;
  width: 100%;
  height: 40px;
  background: #fb8400;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.25);
  border-radius: 12px;
  font-family: 'Itau Display Pro';
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 28px;
  text-align: center;
  color: #ffffff;
`;

export default styled(MobileHotelSearch)`
  display: flex;
  flex-direction: column;
  gap: 10px;
  background: #ffffff;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
  padding: 25px;
`;
