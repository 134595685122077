import React, { useEffect, useState } from 'react';

import { Stepper } from '../Stepper';
import { ContentCard } from '../Common';
import { PaymentStep } from '../PaymentStep';
import { BillingFields } from '../BillingFields';
import { PaxFields } from '../PaxFields';
import { SideDetails } from '../SideDetails';

import { ReactComponent as Chevron } from '../../assets/chevron.svg';
import {
  Holder,
  ContentHeader,
  Title,
  Navigation,
  NavButton
} from '../../styles';
import { Steps } from '../../types';

interface Props {
  activeStep: Steps;
  fields: Record<'id', string>[];
  onRequest: ({
    selectedInterventajasPoints,
    selectedMembershipPoints,
    moneyToComplete,
    pointsToRender
  }) => void;
  onPreviousStep: () => void;
  onNextStep: () => void;
  titularIndex: number;
  onSelectTitular: (number) => void
  checkinTime: string;
  checkoutTime: string;
  checkinDate: string;
  checkoutDate: string;
  rooms: any[];
  policy: any[];
  totalPrice: number;
  pricePerNight: number;
  peopleByRoom: any;
}

export const HotelCheckoutDesktop = ({
  activeStep,
  fields,
  onRequest,
  onNextStep,
  onPreviousStep,
  checkinTime,
  checkoutTime,
  checkinDate,
  checkoutDate,
  titularIndex,
  rooms,
  policy,
  totalPrice,
  pricePerNight,
  onSelectTitular,
  peopleByRoom,
  // selectedSegment,
  // localPrice
}: Props) => {
  const handleCheckTitular = (val: number) => () => {
    onSelectTitular(val);
  }

  return (
    <div className="container">
      <Holder>
        <div>
          <ContentCard>
            <ContentHeader>
              COMPLETÁ TUS DATOS Y FINALIZÁ TU COMPRA
            </ContentHeader>
            <Stepper activeStep={activeStep} />

            {activeStep === Steps.paxData && (
              <>
                <Title>¿Quién es el titular de la reserva?</Title>
                {fields.map((field, index) => (
                  <PaxFields key={field.id} index={index} isTitular={index === titularIndex} onCheckTitular={handleCheckTitular(index)} />
                ))}
              </>
            )}

            {activeStep === Steps.billingData && (
              <>
                <Title>Datos de facturación</Title>
                <BillingFields />
              </>
            )}

            {activeStep === Steps.payment && (
              <PaymentStep onRequest={onRequest} />
            )}

            <Navigation>
              {activeStep !== Steps.paxData ? (
                <NavButton onClick={onPreviousStep}>
                  <Chevron className="rotated" />
                </NavButton>
              ) : (
                <div />
              )}

              {activeStep !== Steps.payment ? (
                <NavButton onClick={onNextStep}>
                  <Chevron />
                </NavButton>
              ) : (
                <div />
              )}
            </Navigation>
          </ContentCard>
        </div>
        <SideDetails
          checkinTime={checkinTime}
          checkoutTime={checkoutTime}
          checkinDate={checkinDate}
          checkoutDate={checkoutDate}
          totalPrice={totalPrice}
          pricePerNight={pricePerNight}
          rooms={rooms}
          policy={policy}
          peopleByRoom={peopleByRoom}
        />
      </Holder>
    </div>
  );
};
