import styled from 'styled-components';
import * as COLORS from 'Constants/colors';
import { breakpoints } from 'Constants';

export default MobilePackageSearch => styled(MobilePackageSearch)`
  display: block;
  min-height: 20px;
  max-width: 1140px;
  width: calc(100% - 40px);
  margin: 0 auto;
  border-radius: 10px;
  overflow: hidden;
  background: #FFFFFF;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.25);

  .select-departure-box {
    height: 40px;
    width: 100%;
  }
  .button-search{
    margin-top:30px;
  }
  .search-input_holder {
    display: flex;
    width: 100%;
    gap: 8px;
    flex-direction: column;
    align-items: flex-start;
    padding: ${({ theme }) => theme.spacing.two};
    position: relative;
    label {
      margin: 0;
      white-space: nowrap;
      margin-right: 8px;
    }
  }
  .content-inputs-search{
    margin: 10px 20px;
    
  }
  .package-search_top-card {
    background-color: ${COLORS.white};
    overflow: hidden;

    .package-search-tabs_holder {
      display: flex;

      .package-search-tabs_item {
        transition: 0.2s;
        cursor: pointer;
        height: 46px;
        flex: 1;
        display: flex;
        justify-content: center;
        align-items: center;
        font-family: 'Itau Display Pro';
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
        background-color: #FAF2E3;
        color: #282828;

        &.active {
          background-color: #FB8400;
          color: white;
          /* box-shadow: ${COLORS.shadows.three}; */
        }
      }
    }

    .package-search-options_holder {
      min-height: 270px;
      /* box-shadow: ${COLORS.shadows.three}; */
      display: flex;
      flex-direction: column;
      justify-content: center;
      padding: 10px 0;

      .icons-list {
        display: flex;

        &.wrap {
          flex-wrap: wrap;
        }
      }
    }
  }

  .order-by_container {
    padding: ${({ theme }) => theme.spacing.two};
    .order-by_holder {
      height: 30px;
      margin: 16px;
      background: ${COLORS.white};
      
      border-radius: 14px;
      display: flex;

      .order-by_button {
        flex: 1;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;

        &.active {
          background-color: ${COLORS.important};
          border-radius: 14px;
        }
      }
    }
  }

  @media screen and (max-width: 1140px) {
    .package-search_top-card {
      .package-search-options_holder {
        max-height: 218px;

        .icons-list_holder {
          overflow-y: auto;
          margin: 0 5px;
          display: block;

          .icons-list {
            display: flex;

            & > * {
              margin: auto;
            }

            &.wrap {
              flex-wrap: nowrap;
            }
          }
        }
      }
    }
  }

  @media screen and (max-width: ${breakpoints.medium}px) {
    margin-top: 220px;
  }
`;

export const Label = styled.div`
  font-family: 'Itau Display Pro';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: rgba(40, 40, 40, 0.5);
`;
